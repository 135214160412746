/** @format */

import React, {
	createContext,
	useReducer,
	useState,
	useRef,
	useEffect,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import {
	BUY_PLAN,
	CHANGE_PASSWORD,
	CREATE_LIST,
	DELETE_LIST,
	DOWNLOAD_LEADS,
	EDIT_LIST_NAME,
	FILTER_CONTACTS,
	FORGET_PASSWORD,
	GET_ALL_CONTACTS,
	GET_ALL_LIST,
	GET_ALL_LIST_CONTACTS,
	GET_ALL_TEAM_MEMBERS,
	GET_CREDITS,
	GET_MEMBER,
	GET_UNLOCKED_LEADS,
	GET_ALL_CATEGORIES,
	GET_CATEGORY,
	CREATE_CATEGORY,
	UPDATE_CATEGORY,
	DELETE_CATEGORY,
	INVITE_TEAM_MEMBER,
	LOGIN_USER,
	RESEND_EMAIL,
	RESET_PASSWORD,
	SIGNUP_USER,
	TEAM_SIGNUP,
	UNLOCK_FIELD,
	GET_SUBSCRIPTION,
	CREATE_SUBSCRIPTION,
	GET_SUBSCRIPTION_FEATURES,
	UPDATE_SUBSCRIPTION,
	GET_PROFILE,
	EDIT_PROFILE,
	REQUEST_CREDITS,
	GET_MEMBER_CREDITS_REQUEST,
	GET_COMPANY_CREDITS_REQUEST,
	DELETE_SUBSCRIPTION,
	HOME_PAGE_SHOW,
	GET_ACTIVITY_LOGS,
	GET_ALL_SUBSCRIPTION_PLAN,
	GET_ACTIVE_CATEGORIES,
	UPLOAD_LEAD_CSV,
	LOG_OUT,
	GET_LEAD_BY_CATEGORY,
	GET_COMPANY_BY_TYPE,
	ADD_ITEM_TO_LIST,
	QUICK_SEARCH,
	GET_COMPANY_ACTIVITY_LOGS_ADMIN,
	GET_COMPANY_MEMBERS_ADMIN,
	GET_COMPANY_INFO_ADMIN,
	GET_TRANSACTIONS,
	GET_COMPANY_TRANSACTIONS_ADMIN,
	GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN,
	EDIT_COMPANY_INFO_ADMIN,
	GET_COMPANY_CREDITS_ADMIN,
	INVITE_COMPANY_MEMBER_ADMIN,
	CREATE_AND_SAVE_ITEM_TO_LIST,
	GET_DOWLOAD_LEADS,
	GET_DOWNLOAD_QUEUE,
	GET_ONE_DOWLOAD_LIST,
	SAVE_SEARCH_FILTER,
	GET_SAVE_SEARCH,
	DELETE_SAVE_SEARCH,
	GET_EXCLUSION_LIST,
	UPLOAD_EXCLUSION_LEADS,
	GET_EXCLUSION_LEADS,
	DELETE_EXCLUSION,
	CREATE_COMPANY_ADMIN,
	GET_INVOICES,
	ACCEPT_MEMBER_CREDIT_REQUEST,
	DENY_MEMBER_CREDIT_REQUEST,
	DELETE_INVITE,
	DELETE_MEMBER,
	ASSIGN_CREDITS,
	REVOKE_CREDITS,
	GET_INVOICES_ADMIN,
	GET_CURRENT_PLAN,
	BLOCK_UNBLOCK_MEMBER,
	GET_COMPANY_CREDITS_REQUEST_ADMIN,
	ASSIGN_CREDITS_TO_COMPANY,
	BUY_MORE_CREDITS,
	POST_POTENTIAL_CUSTOMERS,
	GET_POTENTIAL_CUSTOMERS,
	PAY_INVOICE,
	MARK_AS_PAID_INVOICE,
	GET_DASHBOARD_DATA,
	GET_ALL_COMPANY,
	BLOCK_UNBLOCK_COMPANY,
	GET_COMPANY_SUBSCRIPTION_HISTORY,
	VERIFY_COMPANY_EMAIL,
	CHANGE_COMPANY_SUBSCRIPTION,
	CREATE_SUB_ADMIN,
	GET_ALL_SUB_ADMIN,
	DELETE_SUB_ADMIN,
	GET_ADMIN_TABS,
	EDIT_SUB_ADMIN,
	GET_SUB_ADMIN,
	SUSPEND_UNSUSPEND_COMPANY,
	DOWNLOAD_LEADS_SPECIFIC,
	DELETE_INVITE_ADMIN,
	ASSIGN_USERS_TO_COMPANY,
	CANCEL_SUBSCRIPTION,
	GET_UNPAID_INVOICE_NOTIFICATION,
	EXTEND_FREE_TRIAL,
	GET_MEMBER_ACTIVITY_LOG,
	FILTER_CONTACTS_STATS,
	UNLOCK_MY_LIST_FIELD,
	FILTER_CONTACTS_STATS2,
} from './Types';
import {
	getTransactionsReducer,
	getCompanyTransactionsReducer,
} from './Reducer/TransactionReducer';
import { getDashboardData, loginUser, logout } from './APIs';
import {
	changePassword,
	handleSignup,
	newPassword,
	handleTeamSignup,
} from './Reducer/SignupReducer';
import {
	createSubAdminReducer,
	deleteSubAdminReducer,
	editSubAdminReducer,
	getAdminTabsReducer,
	getAllSubAdminReducer,
	getSubAdminReducer,
} from './Reducer/SubAdminReducer';
import { handleEmail, handleForgetPassword } from './Reducer/EmailReducer';
import {
	downloadLeadsReducer,
	downloadSpecificLeadsReducer,
	getAllContacts,
	getAllListContacts,
	unlockField,
	unlockMyList,
} from './Reducer/ContactsReducre';
import {
	getCompaniesByTypeReducer,
	getCompanyActivityLogReducer,
	getCompanyInfoReducer,
	getCompanyMembersReducer,
	getCompanyInfoAnotherFormatReducer,
	editCompanyInfoReducer,
	createCompanyByAdminReducer,
	assignCreditsToCompanyReducer,
	getAllCompaniesReducer,
	blockAndUnblockCompanyReducer,
	verifyCompanyEmailReducer,
	suspendAndUnsuspendCompanyReducer,
	assignUsersToCompanyReducer,
	extendFreeTrialReducer,
} from './Reducer/CompanyReducer';
import {
	getActivityLogsMemberReducer,
	getActivityLogsReducer,
} from './Reducer/ActivityLogReducer';
import {
	filterAllContacts,
	filterAllContactsStats,
	filterAllContactsStatsAvailable,
	filterAllContactsStatsAvailable2,
	getQuickSearch,
} from './Reducer/ConactsFilterReducer';
import {
	assignCreditToMemberReducer,
	blockAndUnblockReducer,
	deleteInviteAdminReducer,
	deleteInviteReducer,
	deleteMemberReducer,
	getCompanyCreditsReducer,
	getInvitedMember,
	getMembers,
	inviteCompanyMemberReducer,
	inviteMember,
	revokeCreditFromMemberReducer,
} from './Reducer/TeamMembersReducer';
import {
	buyPLan,
	getAllSubscriptionReducer,
	postSubscriptionReducer,
	getSubscriptionReducer,
	getSubscriptionFeaturesReducer,
	updateSubscriptionReducer,
	deleteSubscriptionReducer,
	getCurrentPlanReducer,
	getCompanySubscriptionHistoryReducer,
	changeCompanySubscriptionReducer,
	cancelSubscriptionReducer,
	showOnHomePageReducer,
} from './Reducer/SubscriptionReducer';
import {
	getAllCategoriesReducer,
	getCategoryReducer,
	postCategoryReducer,
	updateCategoryReducer,
	deleteCategoryReducer,
} from './Reducer/CategoryReducer';
import { NotificationManager } from 'react-notifications';
import { getUnlockedLeadsReducer } from './Reducer/MylistReducer';
import {
	addItemToList,
	createAndSaveItemToListReducer,
	createList,
	deleteList,
	editList,
	getList,
} from './Reducer/ListReducer';
import {
	acceptMemberCreditRequestReducer,
	buyMoreCreditsReducer,
	denyMemberCreditRequestReducer,
	getCompanyMembersCreditRequestAdminReducer,
	getCredits,
	requestCredits,
	requestLog,
	requestLogCom,
} from './Reducer/CreditsReducer';
import { editProfile, getProfile } from './Reducer/ProfileReducer';
import {
	getActiveCategoriesReducer,
	uploadLeadCsvReducer,
	getLeadByCategoryReducer,
} from './Reducer/LeadReducer';
import useAuth from '../../hooks/useAuth';
import {
	getDownloadLeadsReducer,
	getOneDownloadListReducer,
	getDownloadQueueReducer,
} from './Reducer/DowloadLeadsReducer';
import {
	deleteSavedSearchReducer,
	getSaveSearchReducer,
	saveSearchFilterReducer,
} from './Reducer/SaveSearchReducer';
import {
	deleteExclusionReducer,
	getAllExclusionListReducer,
	getExclusionLeadsReducer,
	uploadExclusionLeadsCSVReducer,
} from './Reducer/ExclusionLeadsReducer';
import {
	getCompanyInvoicesAdminReducer,
	getInvoicesReducer,
	markAsPaidInvoiceReducer,
	payInvoiceReducer,
} from './Reducer/CompanyInvoicesReducer';
import {
	getPotentialCustomerReducer,
	postPotentialCustomerReducer,
} from './Reducer/PotentialCustomerReducer';
import { getDashboardDataReducer } from './Reducer/DashBoardReducer';
import {
	getNotificationReducer,
	getUnPaidInvoiceNotificationReducer,
} from './Reducer/NotificationReducer';
import { PHYSICIAN_FILTER } from '../../pages/ContactSerch/filterCompo/filterTypes';

export const AuthContext = createContext();

const AuthContextComponent = ({ children }) => {
	let navigate = useNavigate();
	const { auth } = useAuth();
	const location = useLocation();
	const [value, setValue] = useState([0, 99]);
	//contact search page
	const anyFilterFlag = useRef(false);
	const [typesOfFilter, setTypesOfFilter] = useState(PHYSICIAN_FILTER);
	const [allContacts, setAllContacts] = useState([]);
	const [numOfRecordsFound, setNumOfRecordsFound] = useState(0);
	const [firstTimeApiCall, setFirstTimeApiCall] = useState(false);
	const [geoInput, setGeoInput] = React.useState('');
	const [firstTimeFilter, setFirstTimeFilter] = useState({
		filterCount: 0,
		isFilterCall: false,
	});
	const [numOfRecordsFoundLoading, setNumOfRecordsFoundLoading] =
		useState(false);
	const [networkInput, setNetworkInput] = useState('');
	const [selectedLeads, setSelectedLeads] = useState([]);
	const [searchTerm, setsearchTerm] = useState('');
	const [page, setPage] = React.useState(1);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	const [specialityInputValue, setSpecialityInputValue] = React.useState('');
	const [ownerInput, setOwnerInput] = React.useState('');
	const [specialityInputValue1, setSpecialityInputValue1] = React.useState('');
	const [specialityInputValue2, setSpecialityInputValue2] = React.useState('');
	const [typeInputValue, setTypeInputValue] = React.useState('');
	const [facilityInput, setFacilityInput] = useState('');
	const [countryInput, setCountryInput] = React.useState('');
	const [stateInput, setStateInput] = React.useState('');
	const [cityInput, setCityInput] = React.useState('');
	const [zipInput, setZipInput] = React.useState('');
	const [titleInput, setTitleInput] = useState('');
	const [availableLoading, setAvailableLoading] = useState(false);
	const [sortColumn, setSortColumn] = useState({
		field: '', //name of column(acc. to schema) to be sorted
		direction: '', // "0" acc and "1" desc
	});
	const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
	const [sortField, setSortField] = useState({
		contact_name_sort: null,
		address_sort: null,
		city_sort: null,
		state_sort: null,
		country_sort: null,
		zipcode_sort: null,
		speciality_sort: null,
		specialityType_sort: null,
		specialityGroup_sort: null,
		gender_sort: null,
		title_sort: null,
		hospitalType_sort: null,
		firmType_sort: null,
		levelID_sort: null,
		ownership_sort: null,
		geographic_sort: null,
		experience_sort: null,
		licenseState_sort: null,
		npi_sort: null,
		degree_sort: null,
		hospitalName_sort: null,
		department_sort: null,
		webaddress_sort: null,
		bedrange_sort: null,
	});
	const [filter, setFilter] = useState({
		//not adding typeOfFilter here but in reducer function
		contact_name: [],
		address: [],
		city: [],
		state: [],
		country: [],
		zipcode: [],
		speciality: [],
		specialityType: [],
		specialityGroup: [],
		gender: [],
		title: [],
		hospitalType: [],
		firmType: [],
		levelID: [],
		ownership: [],
		geographic: [],
		experience: [],
		licenseState: [],
		npi: [],
		//new
		degree: [],
		hospitalName: [],
		department: [],
		webaddress: [],
		bedrange: [],

		officetype: [],

		tabletitle: [],
		tablehospitalName: [],
		tablehospitalType: [],
		tabledepartment: [],
		tablecity: [],
		tablestate: [],
		tablespeciality: [],
		tablespecialityGroup: [],
		tablespecialityType: [],
		tablewebaddress: [],
		tablefirmType: [],
		tableownership: [],
		tablebedrange: [],
		tabledegree: [],
		tablenpi: [],
	});

	//Various options
	const [credits, setCredits] = useState(null);
	const [profile, setProfile] = useState();
	const [currentPlan, setCurrentPlan] = useState({});
	const [typeOfUser, setTypeOfUser] = useState('');
	const [subscriptionFeatures, setSubscriptionFeatures] = useState([]);
	const [adminTabs, setAdminTabs] = useState([]);
	const [subAdminDetails, setSubAdminDetails] = useState();
	const [upgradeNowModal, setUpgradeNowModal] = useState(false);
	const [notification, setNotification] = useState();
	const [levelInput, setLevelInput] = useState('');
	const [selectedVal, setSelectedVal] = useState('');
	//dashboard
	const [licenseInput, setLicenseInput] = useState('');
	const [dashboardData, setDashboardData] = useState({});
	const [isDashboardLoading, setIsDashBoardLoading] = useState(false);
	const [availableStats, setAvailableStats] = useState('');
	const [year, setYear] = useState();
	const [unlockLoading, setUnlockLoading] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState('');

	//login
	const handleLogin = async (
		values,
		resetForm,
		setIsLoading,
		handleCaptchaChange
	) => {
		if (auth) {
			window.location.reload();
		}
		setIsLoading(true);
		try {
			const res = await loginUser(values);
			if (res.data.message === 'Human verification failed') {
				window.location.reload();
			}
			if (res.data.message === 'Login Successful!') {
				NotificationManager.success('Login Successful!');
				const days = 30;
				Cookies.set('token', res.data.access_token, {
					expires: days,
				});
				// let from = location.state?.from?.pathname || "/home";
				let from = '/contactsearch';
				//let { id, role } = jwt_decode(res.data.access_token);
				// if (role === 'ADMIN') from = '/dashboard';
				// if (role === 'SUB_ADMIN') from = '/profile';
				navigate(from, { replace: true });
			}
		} catch (e) {
			handleCaptchaChange();
			setIsLoading(false);
			//  NotificationManager.error(e.response.data || "Something Went Wrong!");;
		}
	};

	//log out
	const logoutHandler = async (setIsLoading) => {
		setIsLoading(true);
		try {
			const res = await logout();
			if (res.data) {
				Cookies.remove('token');
				window.location.reload();
			}
		} catch (e) {
			console.log(e);
			//("some error occured");
		} finally {
			setIsLoading(false);
		}
	};

	const getAvailableLeads = () => {
		filterAllContactsStatsAvailable2(
			filter,
			typesOfFilter,
			setAvailableLoading,
			setAvailableStats,
			setFirstTimeApiCall,
			setFirstTimeFilter
		);
	};
	// reducer function
	function reducer(state, action) {
		switch (action.type) {
			case LOGIN_USER:
				handleLogin(
					action.payload,
					action.resetForm,
					action.setIsLoading,
					action.handleCaptchaChange
				);
				break;
			case SIGNUP_USER:
				handleSignup(
					action.payload,
					action.resetForm,
					action.setIsLoading,
					navigate
				);
				break;
			case RESEND_EMAIL:
				handleEmail(action.payload, action.setIsLoading);
				break;
			// to send email
			case FORGET_PASSWORD:
				handleForgetPassword(action.payload, action.setIsLoading);
				break;
			// to change password
			case CHANGE_PASSWORD:
				changePassword(action.payload, action.resetForm, action.setIsLoading);
				break;
			//
			case RESET_PASSWORD:
				newPassword(action.payload, action.setIsLoading);
				break;
			case GET_MEMBER:
				getInvitedMember(
					action.payload,
					action.upDateState,
					action.setIsMember
				);
				break;
			// case GET_ALL_CONTACTS:
			//   getAllContacts(
			//     action.page,
			//     action.limit,
			//     action.setAllContacts,
			//     setIsLoadingDataTable,
			//     setNumOfRecordsFound
			//   );
			//   break;
			case FILTER_CONTACTS:
				filterAllContacts(
					filter,
					typesOfFilter,
					page,
					rowsPerPage,
					setAllContacts,
					setIsLoadingDataTable,
					sortColumn,
					setSortField,
					sortField
				);
			case FILTER_CONTACTS_STATS:
				filterAllContactsStats(
					filter,
					typesOfFilter,
					setNumOfRecordsFoundLoading,
					setNumOfRecordsFound,
					setFirstTimeApiCall,
					firstTimeApiCall,
					setFirstTimeFilter,
					firstTimeFilter
				);
			case FILTER_CONTACTS_STATS2:
				filterAllContactsStatsAvailable(
					filter,
					typesOfFilter,
					setAvailableLoading,
					setAvailableStats,
					setFirstTimeApiCall,
					firstTimeApiCall,
					setFirstTimeFilter,
					firstTimeFilter
				);
				break;
			case GET_ALL_LIST_CONTACTS:
				getAllListContacts(
					action.folder_id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_ALL_TEAM_MEMBERS:
				getMembers(action.updateState, action.setIsLoading, action.setallData);
				break;
			case INVITE_TEAM_MEMBER:
				inviteMember(
					action.payload,
					action.updateState,
					action.resetForm,
					action.setIsLoading
				);
				break;
			case GET_ALL_SUBSCRIPTION_PLAN:
				getAllSubscriptionReducer(
					action.id,
					action.flag,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_SUBSCRIPTION:
				getSubscriptionReducer(
					action.payload,
					action.updateState,
					action.setSelectedOption,
					action.setIsLoading
				);
				break;
			case CREATE_SUBSCRIPTION:
				postSubscriptionReducer(
					action.payload,
					action.updateState,
					action.setRowLoading,
					action.setmodel,
					action.setIsLoading
				);
				break;
			case GET_SUBSCRIPTION_FEATURES:
				getSubscriptionFeaturesReducer(setSubscriptionFeatures);
				break;
			case UPDATE_SUBSCRIPTION:
				updateSubscriptionReducer(
					action.payload,
					action.values,
					action.updateState,
					action.setRowLoading,
					action.setmodel,
					action.setIsLoading
				);
				break;
			case DELETE_SUBSCRIPTION:
				deleteSubscriptionReducer(
					action.payload,
					action.updateState,
					action.setIsLoading
				);
			case HOME_PAGE_SHOW:
				showOnHomePageReducer(
					action.payload,
					action.status,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_ALL_CATEGORIES:
				getAllCategoriesReducer(action.updateState, action.setIsLoading);
				break;
			case GET_CATEGORY:
				getCategoryReducer(
					action.payload,
					action.updateState,
					action.setIsModelLoading
				);
				break;
			case UPDATE_CATEGORY:
				updateCategoryReducer(
					action.payload,
					action.values,
					action.updateState,
					action.setModel,
					action.setIsModelLoading,
					action.setIsLoading
				);
				break;
			case CREATE_CATEGORY:
				postCategoryReducer(
					action.payload,
					action.updateState,
					action.setModel,
					action.setIsModelLoading,
					action.setIsLoading
				);
				break;
			case DELETE_CATEGORY:
				deleteCategoryReducer(
					action.payload,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_ACTIVITY_LOGS:
				getActivityLogsReducer(
					action.updateState,
					action.setIsLoading,
					action.page,
					action.limit,
					action.date
				);
				break;
			case GET_MEMBER_ACTIVITY_LOG:
				getActivityLogsMemberReducer(
					action.updateState,
					action.setIsLoading,
					action.id,
					action.page,
					action.limit
				);
				break;
			case GET_ACTIVE_CATEGORIES:
				getActiveCategoriesReducer(action.updateState, action.setIsLoading);
				break;
			case UPLOAD_LEAD_CSV:
				uploadLeadCsvReducer(action.payload, action.setIsLoading);
				break;
			case GET_LEAD_BY_CATEGORY:
				getLeadByCategoryReducer(
					action.searchParams,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_COMPANY_BY_TYPE:
				getCompaniesByTypeReducer(
					action.searchParams,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_COMPANY_ACTIVITY_LOGS_ADMIN:
				getCompanyActivityLogReducer(
					action.searchParams,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_COMPANY_INFO_ADMIN:
				getCompanyInfoReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_COMPANY_MEMBERS_ADMIN:
				getCompanyMembersReducer(
					action.id,
					action.updateState,
					action.setIsLoading,
					action.setallData
				);
				break;
			case GET_TRANSACTIONS:
				getTransactionsReducer(
					action.updateState,
					action.setIsLoading,
					action.setallData
				);
				break;
			case GET_COMPANY_TRANSACTIONS_ADMIN:
				getCompanyTransactionsReducer(
					action.id,
					action.updateState,
					action.setIsLoading,
					action.setallData
				);
				break;
			case GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN:
				getCompanyInfoAnotherFormatReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case EDIT_COMPANY_INFO_ADMIN:
				editCompanyInfoReducer(
					action.id,
					action.values,
					action.setIsLoading,
					action.setmodel
				);
				break;
			case BUY_PLAN:
				buyPLan(action.payload, navigate, action.setIsLoading);
				break;
			case UNLOCK_FIELD:
				unlockField(
					action.payload,
					action.clickedOn,
					action.setLock,
					getCredits,
					setCredits,
					typesOfFilter,
					setUnlockLoading,
					getAvailableLeads
				);
				break;
			case UNLOCK_MY_LIST_FIELD:
				unlockMyList(
					action.payload,
					action.clickedOn,
					action.setLock,
					getCredits,
					setCredits,
					typesOfFilter
				);
				break;
			case DOWNLOAD_LEADS:
				downloadLeadsReducer(
					action.payload,
					action.setIsLoading,
					action.setDownload,
					getCredits,
					setCredits,
					typesOfFilter,
					getAvailableLeads,
					credits
				);
				break;
			case DOWNLOAD_LEADS_SPECIFIC:
				downloadSpecificLeadsReducer(
					filter,
					typesOfFilter,
					action.numberOfLeads,
					action.verifyAll,
					action.setIsLoading,
					action.setDownload,
					action.getAllContacts,
					action.fileName,
					action.setFileName,
					action.verified,
					getCredits,
					setCredits,
					getAvailableLeads,
					credits
				);
				break;
			case GET_UNLOCKED_LEADS:
				getUnlockedLeadsReducer(
					action.updateState,
					action.setIsLoading,
					action.dataType
				);
				break;
			case CREATE_LIST:
				createList(action.payload, action.setIsLoading, action.cb);
				break;
			case GET_ALL_LIST:
				getList(action.updateState, action.setIsLoading, action.dataType);
				break;
			case ADD_ITEM_TO_LIST:
				addItemToList(
					action.payload,
					action.folder_id,
					action.setIsLoading,
					action.closeModal,
					typesOfFilter
				);
				break;
			case QUICK_SEARCH:
				getQuickSearch(
					action.payload,
					action.page,
					action.limit,
					setAllContacts,
					setIsLoadingDataTable,
					setNumOfRecordsFound
				);
				break;
			case GET_DOWLOAD_LEADS:
				getDownloadLeadsReducer(
					action.updateState,
					action.setIsLoading,
					action.dataType
				);
				break;
			case GET_DOWNLOAD_QUEUE:
				getDownloadQueueReducer(
					action.updateState,
					action.setIsLoading,
					action.dataType
				);
				break;
			case DELETE_LIST:
				deleteList(action.payload, action.setIsLoading, action.cb);
				break;
			case EDIT_LIST_NAME:
				editList(action.payload, action.setIsLoading, action.cb);
				break;
			case GET_CREDITS:
				getCredits(setCredits, setTypeOfUser);
				break;
			case TEAM_SIGNUP:
				handleTeamSignup(
					action.payload,
					action.resetForm,
					action.setIsLoading,
					navigate
				);
				break;
			case GET_PROFILE:
				getProfile(setProfile);
				break;
			case EDIT_PROFILE:
				editProfile(
					action.payload,
					action.setIsLoading,
					action.setIsEdit,
					setProfile
				);
				break;
			case GET_SAVE_SEARCH:
				getSaveSearchReducer(
					action.updateState,
					action.setIsLoading,
					action.dataType
				);
				break;
			case DELETE_SAVE_SEARCH:
				deleteSavedSearchReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case SAVE_SEARCH_FILTER:
				saveSearchFilterReducer(
					action.values,
					action.setIsLoading,
					action.closeModal
				);
				break;
			case REQUEST_CREDITS:
				requestCredits(
					action.payload,
					action.resetForm,
					action.setIsLoading,
					action.cb
				);
				break;
			case GET_COMPANY_CREDITS_ADMIN:
				getCompanyCreditsReducer(action.id, action.updateState);
				break;
			case INVITE_COMPANY_MEMBER_ADMIN:
				inviteCompanyMemberReducer(
					action.id,
					action.updateState,
					action.values,
					action.resetForm,
					action.setIsLoading
				);
				break;
			case GET_MEMBER_CREDITS_REQUEST:
				requestLog(action.updateState, action.setIsLoading);
				break;
			case GET_COMPANY_CREDITS_REQUEST:
				requestLogCom(action.updateState, action.setIsLoading);
				break;
			case CREATE_AND_SAVE_ITEM_TO_LIST:
				createAndSaveItemToListReducer(
					action.values,
					action.setIsLoading,
					action.closeModal,
					typesOfFilter
				);
				break;
			case GET_ONE_DOWLOAD_LIST:
				getOneDownloadListReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_EXCLUSION_LIST:
				getAllExclusionListReducer(action.updateState, action.setIsLoading);
				break;
			case UPLOAD_EXCLUSION_LEADS:
				uploadExclusionLeadsCSVReducer(
					action.values,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_EXCLUSION_LEADS:
				getExclusionLeadsReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case DELETE_EXCLUSION:
				deleteExclusionReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case CREATE_COMPANY_ADMIN:
				createCompanyByAdminReducer(
					action.values,
					action.closeModal,
					action.setIsLoading
				);
				break;
			case ACCEPT_MEMBER_CREDIT_REQUEST:
				acceptMemberCreditRequestReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case DENY_MEMBER_CREDIT_REQUEST:
				denyMemberCreditRequestReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case DELETE_INVITE:
				deleteInviteReducer(action.id, action.updateState, action.setIsLoading);
				break;
			case DELETE_INVITE_ADMIN:
				deleteInviteAdminReducer(
					action.companyId,
					action.inviteId,
					action.updateState,
					action.setallData
				);
				break;
			case DELETE_MEMBER:
				deleteMemberReducer(action.id, action.updateState, action.setIsLoading);
				break;
			case GET_INVOICES:
				getInvoicesReducer(
					action.updateState,
					action.setIsLoading,
					action.setallData
				);
				break;
			case GET_INVOICES_ADMIN:
				getCompanyInvoicesAdminReducer(
					action.id,
					action.updateState,
					action.setIsLoading,
					action.setallData
				);
				break;
			case ASSIGN_CREDITS:
				assignCreditToMemberReducer(
					action.id,
					action.value,
					action.updateState,
					action.setIsLoading,
					action.close
				);
				break;
			case REVOKE_CREDITS:
				revokeCreditFromMemberReducer(
					action.id,
					action.value,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_CURRENT_PLAN:
				getCurrentPlanReducer(setCurrentPlan, setUpgradeNowModal);
				break;
			case BLOCK_UNBLOCK_MEMBER:
				blockAndUnblockReducer(action.id, action.updateState);
				break;
			case GET_COMPANY_CREDITS_REQUEST_ADMIN:
				getCompanyMembersCreditRequestAdminReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case ASSIGN_CREDITS_TO_COMPANY:
				assignCreditsToCompanyReducer(
					action.values,
					action.closeModal,
					action.setIsLoading
				);
				break;
			case ASSIGN_USERS_TO_COMPANY:
				assignUsersToCompanyReducer(
					action.values,
					action.closeModal,
					action.setIsLoading
				);
				break;
			case BUY_MORE_CREDITS:
				buyMoreCreditsReducer(action.values, action.setIsLoading);
				break;
			case POST_POTENTIAL_CUSTOMERS:
				postPotentialCustomerReducer(action.email);
				break;
			case GET_POTENTIAL_CUSTOMERS:
				getPotentialCustomerReducer(action.updateState, action.setIsLoading);
				break;
			case PAY_INVOICE:
				payInvoiceReducer(action.values, action.setIsLoading);
				break;
			case MARK_AS_PAID_INVOICE:
				markAsPaidInvoiceReducer(
					action.values,
					action.setIsLoading,
					action.updateState,
					action.setallData,
					action.closeModal
				);
				break;
			case GET_DASHBOARD_DATA:
				getDashboardDataReducer(year, setDashboardData, setIsDashBoardLoading);
				break;
			case GET_ALL_COMPANY:
				getAllCompaniesReducer(action.updateState, action.setIsLoading);
				break;
			case BLOCK_UNBLOCK_COMPANY:
				blockAndUnblockCompanyReducer(action.id, action.updateState);
				break;
			case SUSPEND_UNSUSPEND_COMPANY:
				suspendAndUnsuspendCompanyReducer(action.id, action.updateState);
				break;
			case VERIFY_COMPANY_EMAIL:
				verifyCompanyEmailReducer(action.id, action.updateState);
				break;
			case CHANGE_COMPANY_SUBSCRIPTION:
				changeCompanySubscriptionReducer(
					action.id,
					action.sub_id,
					action.isAnnual
				);
				break;
			case GET_COMPANY_SUBSCRIPTION_HISTORY:
				getCompanySubscriptionHistoryReducer(
					action.id,
					action.updateState,
					action.setIsLoading
				);
				break;
			case CREATE_SUB_ADMIN:
				createSubAdminReducer(
					action.values,
					action.setIsModal,
					action.updateState,
					action.setIsLoading
				);
				break;
			case GET_ALL_SUB_ADMIN:
				getAllSubAdminReducer(action.updateState, action.setIsLoading);
				break;
			case DELETE_SUB_ADMIN:
				deleteSubAdminReducer(action.id, action.updateState);
				break;
			case GET_ADMIN_TABS:
				getAdminTabsReducer(setAdminTabs);
				break;
			case EDIT_SUB_ADMIN:
				editSubAdminReducer(
					action.id,
					action.values,
					action.setIsModal,
					action.updateState,
					action.setIsLoading
				);
				break;
			case CANCEL_SUBSCRIPTION:
				cancelSubscriptionReducer(
					action.values,
					action.callback,
					action.setIsLoading,
					logoutHandler
				);
				break;
			case GET_SUB_ADMIN:
				getSubAdminReducer(action.id, setSubAdminDetails);
				break;
			case GET_UNPAID_INVOICE_NOTIFICATION:
				getUnPaidInvoiceNotificationReducer(setNotification);
				break;
			case EXTEND_FREE_TRIAL:
				extendFreeTrialReducer(
					action.id,
					action.date,
					action.updateState,
					action.setIsLoading,
					action.closeModal
				);
				break;
			case LOG_OUT:
				logoutHandler(action.setIsLoading);
				break;
		}
	}

	const initialValues = {};
	const [state, dispatch] = useReducer(reducer, initialValues);

	return (
		<>
			<AuthContext.Provider
				value={{
					dispatch,
					profile,
					notification,
					dashboardData,
					year,
					anyFilterFlag,
					setYear,
					numOfRecordsFound,
					filter,
					setNumOfRecordsFound,
					setFilter,
					setSortField,
					sortField,
					allContacts,
					setAllContacts,
					selectedLeads,
					setSelectedLeads,
					searchTerm,
					setsearchTerm,
					credits,
					typeOfUser,
					subscriptionFeatures,
					isLoadingDataTable,
					currentPlan,
					adminTabs,
					subAdminDetails,
					setCurrentPlan,
					upgradeNowModal,
					setUpgradeNowModal,
					page,
					setPage,
					rowsPerPage,
					setRowsPerPage,
					typesOfFilter,
					setTypesOfFilter,
					isDashboardLoading,
					sortColumn,
					setSortColumn,
					numOfRecordsFoundLoading,
					firstTimeApiCall,
					setFirstTimeApiCall,
					setFirstTimeFilter,
					firstTimeFilter,
					unlockLoading,
					setValue,
					value,

					setSpecialityInputValue,
					setSpecialityInputValue1,
					setSpecialityInputValue2,
					setTypeInputValue,
					setCountryInput,
					setStateInput,
					setCityInput,
					setZipInput,
					setLicenseInput,
					setTitleInput,
					setLevelInput,
					setFacilityInput,
					setNetworkInput,
					setOwnerInput,
					setGeoInput,
					specialityInputValue,
					specialityInputValue1,
					specialityInputValue2,
					typeInputValue,
					countryInput,
					stateInput,
					cityInput,
					zipInput,
					licenseInput,
					titleInput,
					levelInput,
					facilityInput,
					networkInput,
					ownerInput,
					geoInput,
					availableStats,
					availableLoading,
					setSelectedFilter,
					selectedFilter,
					selectedVal,
					setSelectedVal,
					setCredits,
				}}
			>
				{children}
			</AuthContext.Provider>
		</>
	);
};

export default AuthContextComponent;
