import React, { useContext, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { OWNERSHIP } from "../filterTypes";
import Select from "react-select";
import { MainContext } from "../../../../componants/filterContext/FilterContext";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { FILTER_CONTACTS } from "../../../../componants/Context/Types";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import debounce from "debounce-promise";
import { useState } from "react";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function OwnerShip() {
  const [expanded, setExpanded] = React.useState(false);
  const [ownerOptions, setOwnerOptions] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const {
    globalSelectFilter,
    setGlobalSelectFilter,
    globalFilterKeyToApi,
    globalFilterKeyTofilterKey,
  } = useContext(MainContext);

  const {
    setFilter,
    filter,
    setFirstTimeFilter,
    setFirstTimeApiCall,
    setSortField,
    dispatch,
    anyFilterFlag,
    ownerInput,
    setOwnerInput,
    selectedFilter,
  } = useContext(AuthContext);

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setIsOpen(newExpanded);
    setOwnerOptions(ownerOptions?.filter((item) => item?.label?.length !== 0));
  };

  const loadOptions = debounce((searchValue) => {
    const getData = async () => {
      const data = await globalFilterKeyToApi[OWNERSHIP](searchValue);
      const options = data.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setOwnerOptions(options);
      return options;
    };
    return getData();
  }, 1000);

  const handleChangeMultiSelect = (selectedOption) => {
    const formatedOption = selectedOption.map((item) => item.value);
    setGlobalSelectFilter((prev) => {
      return { ...prev, [expanded]: selectedOption };
    });
    setFirstTimeFilter({
      filterCount: 0,
      isFilterCall: false,
    });
    setSortField({});
    setFilter((prev) => {
      return {
        ...prev,
        [globalFilterKeyTofilterKey[expanded]]: formatedOption,
      };
    });
  };

  const keyDownHandler = (e) => {
    console.log(e);
    if (e.code === "Enter") {
      setFirstTimeApiCall(false);
      setFirstTimeFilter({
        filterCount: 0,
        isFilterCall: false,
      });
      anyFilterFlag.current = true;
      dispatch({
        type: FILTER_CONTACTS,
      });
    }
  };

  useEffect(() => {
    if (selectedFilter === "Ownership") {
      setIsOpen(true);
      setExpanded(OWNERSHIP);
    }
  }, [selectedFilter]);

  useEffect(() => {
    loadOptions("");
  }, []);
  return (
    <div>
      <Accordion
        expanded={expanded === OWNERSHIP}
        onChange={handleChangeAccordian(OWNERSHIP)}
      >
        <AccordionSummary>
          <Typography className="f-16">OwnerShip</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            isMulti
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            menuIsOpen={isOpen}
            createOptionPosition="first"
            isClearable={false}
            closeMenuOnSelect={false}
            isSearchable={true}
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
              placeholder: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
            }}
            value={globalSelectFilter[OWNERSHIP]}
            // loadOptions={loadOptions}
            defaultOptions={ownerOptions}
            options={ownerOptions}
            onChange={handleChangeMultiSelect}
            onKeyDown={keyDownHandler}
            onInputChange={(value, action) => {
              if (action.action === "input-change") {
                loadOptions(value);
              }
            }}
            // defaultOptions={true}
            // options={ownerOptions}
            // onInputChange={(value, action) => {
            //   if (action.action === "input-change") setOwnerInput(value);
            // }}
            // inputValue={ownerInput}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
