/** @format */

import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import {
	DOWNLOAD_LEADS,
	DOWNLOAD_LEADS_SPECIFIC,
	FILTER_CONTACTS,
} from '../Context/Types';
import { AuthContext } from '../Context/AuthContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { NotificationManager } from 'react-notifications';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useAuth from '../../hooks/useAuth';
import ConfirmDownloadModal from './ConfirmDownloadModal';

export const DownloadPopUpModel = ({ setDownload }) => {
	const {
		dispatch,
		numOfRecordsFound,
		selectedLeads,
		setFirstTimeApiCall,
		setFirstTimeFilter,
		credits,
		setCredits,
		anyFilterFlag,
		totalDownload,
		setTotalDownload,
		availableStats,
	} = useContext(AuthContext);
	const [value, setValue] = React.useState('specific');
	const [isVerify, setIsVerify] = useState(false);
	const [numOfLeads, setNumOfLeads] = useState(50);
	const [fileName, setFileName] = useState('');
	const { auth } = useAuth();
	const role = auth?.role;
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [nameError, setNameError] = useState(false);
	const plan = localStorage.getItem('isDaily');
	const totalAvailableCredits = Number(credits);
	const [errorMsg, setErrorMsg] = useState('');
	const [isModalShow, setIsModalShow] = useState(false);
	const [isModalShow2, setIsModalShow2] = useState(false);

	const handleDownload = () => {
		setIsModalShow(false);
		setCredits(
			Number(credits) - Number(numOfLeads) < 0
				? 0
				: Number(credits) - Number(numOfLeads)
		);
		// setTotalDownload(
		// 	Number(totalDownload) - Number(numOfLeads) < 0
		// 		? 0
		// 		: Number(totalDownload) - Number(numOfLeads)
		// );
		dispatch({
			type: DOWNLOAD_LEADS_SPECIFIC,
			numberOfLeads: numOfLeads,
			verifyAll: isVerify === false ? false : true,
			setIsLoading: setIsLoading,
			auth,
			setDownload,
			getAllContacts,
			fileName,
			setFileName,
			verified: isVerify === 'noVer' ? false : true,
		});
	};

	const handleDownload2 = () => {
		setIsModalShow2(false);
		setCredits(
			Number(credits) - Number(availableStats) < 0
				? 0
				: Number(credits) - Number(availableStats)
		);
		// setTotalDownload(
		// 	Number(totalDownload) - Number(availableStats) < 0
		// 		? 0
		// 		: Number(totalDownload) - Number(availableStats)
		// );
		dispatch({
			type: DOWNLOAD_LEADS_SPECIFIC,
			numberOfLeads:
				auth?.role === 'admin' || auth?.role === 'subadmin'
					? numOfRecordsFound
					: plan === 'true'
					? totalDownload
					: credits,
			verifyAll: isVerify === false ? false : true,
			setIsLoading: setIsLoading,
			auth,
			setDownload,
			getAllContacts,
			fileName,
			setFileName,
			verified: isVerify === 'noVer' ? false : true,
		});
	};

	const handleClick = () => {
		if (fileName.length > 0) {
			setNameError(false);
			if (isError) return;
			if (
				value === 'selected' &&
				(!selectedLeads || selectedLeads.length === 0)
			) {
				NotificationManager.info('Atleast select one lead ');
				return;
			}
			if (
				value === 'specific' &&
				+numOfLeads > +credits &&
				role !== 'admin' &&
				role !== 'subadmin'
			) {
				NotificationManager.error('Not Enough Credits!');
				return;
			}
			if (
				value === 'selected' &&
				+numOfLeads > +credits &&
				role !== 'admin' &&
				role !== 'subadmin'
			) {
				NotificationManager.error('Not Enough Credits!');
				return;
			}
			if (value === 'selected') {
				setCredits(
					Number(credits) - Number(selectedLeads?.length) < 0
						? 0
						: Number(credits) - Number(selectedLeads?.length)
				);
				// setTotalDownload(
				// 	Number(totalDownload) - Number(selectedLeads?.length) < 0
				// 		? 0
				// 		: Number(totalDownload) - Number(selectedLeads?.length)
				// );
				dispatch({
					type: DOWNLOAD_LEADS,
					payload: {
						leads_ids: selectedLeads,
						verifyAll: isVerify === false ? false : true,
						downloadName: fileName,
						isVerify: isVerify === 'noVer' ? false : true,
					},
					setIsLoading: setIsLoading,
					auth,
					setDownload,
					UnlockFilled: () => {},
				});
			} else if (value === 'specific') {
				handleDownload();
			} else if (value === 'all') {
				if (
					(plan === 'true' && Number(totalDownload) > Number(availableStats)) ||
					auth?.role === 'admin' ||
					auth?.role === 'subadmin'
				) {
					handleDownload2();
				} else if (
					(plan !== 'true' && Number(credits) > Number(availableStats)) ||
					auth?.role === 'admin' ||
					auth?.role === 'subadmin'
				) {
					handleDownload2();
				} else {
					NotificationManager.error(
						`Not Enough Credits to download ${availableStats} contacts!`
					);
				}
			}
		} else {
			setNameError(true);
		}
	};
	const handleChange = (e) => {
		const val = e.target.value;
		setNumOfLeads(val);
		if (val <= 0 || val > numOfRecordsFound) {
			setIsError(true);
			setErrorMsg('Enter valid number of contacts');
			return;
		} else if (
			Number(val) > Number(totalAvailableCredits) &&
			role !== 'admin' &&
			role !== 'subadmin'
		) {
			setIsError(true);
			setErrorMsg("Don't have enough credits");
			return;
		} else {
			setIsError(false);
		}
	};
	const handleChange2 = (value) => {
		const val = value;
		setNumOfLeads(val);
		if (val <= 0 || val > numOfRecordsFound) {
			setIsError(true);
			setErrorMsg('Enter valid number of contacts');
			return;
		} else if (
			Number(val) > Number(totalAvailableCredits) &&
			role !== 'admin' &&
			role !== 'subadmin'
		) {
			setIsError(true);
			setErrorMsg("Don't have enough credits");
			return;
		} else {
			setIsError(false);
		}
	};

	const getAllContacts = () => {
		setFirstTimeApiCall(false);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		dispatch({
			type: FILTER_CONTACTS,
		});
		anyFilterFlag.current = true;
	};

	console.log(numOfRecordsFound, totalAvailableCredits);
	return (
		<div>
			<div
				class='bg-blue-100 border-blue-400 text-sm text-blue-600 rounded-lg px-2 py-2 mt-3'
				role='alert'
			>
				<div class='flex gap-2'>
					<svg
						class='flex-shrink-0 w-3 h-3 mt-1'
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						stroke-width='2'
						stroke-linecap='round'
						stroke-linejoin='round'
					>
						<circle cx='12' cy='12' r='10'></circle>
						<path d='M12 16v-4'></path>
						<path d='M12 8h.01'></path>
					</svg>

					<p class='text-xs text-start font-medium'>
						Real-time email verification can take <strong>3 to 18</strong>{' '}
						minutes depending upon numbers of records selected.
					</p>
				</div>
			</div>

			<div class='grid space-y-3 mt-4'>
				<div class='relative flex items-start'>
					<div class='flex items-center h-5'>
						<input
							id='hs-radio-specific'
							name='hs-radio-with-description'
							type='radio'
							value='specific'
							defaultChecked
							onChange={(e) => {
								setValue(e.target.value);
							}}
							class='border-gray-200 cursor-pointer rounded-full text-blue-600 focus:ring-blue-500'
							aria-describedby='hs-radio-delete-description'
						/>
					</div>
					<label for='hs-radio-specific' class='ms-3'>
						<span class='cursor-pointer block text-xs text-start font-semibold text-gray-800 dark:text-gray-300'>
							Enter Number of Contacts
						</span>
						<input
							type='number'
							value={numOfLeads}
							onChange={handleChange}
							className={`border focus:outline-none rounded-lg mt-1 py-1point5 px-2 text-xs ${
								isError
									? 'border-red-600'
									: 'border-gray-200 focus:!border-blue-500'
							} `}
						/>
						{isError && (
							<p className='text-start text-2xs mt-point5 text-red-600 font-medium'>
								{errorMsg}
							</p>
						)}
						<div className='flex gap-1 items-center'>
							<div
								onClick={() => handleChange2(100)}
								className='bg-gray-200 chip rounded-sm p-1 text-2xs mt-1 font-medium cursor-pointer'
							>
								100
							</div>
							<div
								onClick={() => handleChange2(250)}
								className='bg-gray-200 chip rounded-sm p-1 text-2xs mt-1 font-medium cursor-pointer'
							>
								250
							</div>
							<div
								onClick={() => handleChange2(500)}
								className='bg-gray-200 chip rounded-sm p-1 text-2xs mt-1 font-medium cursor-pointer'
							>
								500
							</div>
							<div
								onClick={() => handleChange2(1000)}
								className='bg-gray-200 chip rounded-sm p-1 text-2xs mt-1 font-medium cursor-pointer'
							>
								1000
							</div>
							<div
								onClick={() => handleChange2(2000)}
								className='bg-gray-200 chip rounded-sm p-1 text-2xs mt-1 font-medium cursor-pointer'
							>
								2000
							</div>
						</div>
					</label>
				</div>

				<div class='relative flex items-start mt-2'>
					<div class='flex items-center h-5'>
						<input
							id='hs-radio-select'
							name='hs-radio-with-description'
							type='radio'
							value='selected'
							onChange={(e) => {
								setValue(e.target.value);
							}}
							class='border-gray-200 cursor-pointer rounded-full text-blue-600 focus:ring-blue-500'
							aria-describedby='hs-radio-archive-description'
						/>
					</div>
					<label for='hs-radio-select' class='ms-3'>
						<span class='block cursor-pointer text-xs text-start font-semibold text-gray-800 dark:text-gray-300'>
							Only Selected Contacts
						</span>
						<div className='flex gap-1 items-center'>
							{selectedLeads.length > 0 ? (
								selectedLeads.length > totalAvailableCredits ? (
									<div className='bg-red-100 text-red-600 rounded-sm p-1 text-2xs mt-1 font-medium'>
										Selected {selectedLeads.length} Contacts
									</div>
								) : (
									<div className='bg-blue-100 text-blue-600 rounded-sm p-1 text-2xs mt-1 font-medium'>
										Selected {selectedLeads.length} Contacts
									</div>
								)
							) : (
								<div className='bg-red-100 text-red-600 rounded-sm p-1 text-2xs mt-1 font-medium'>
									Selected None
								</div>
							)}
						</div>
					</label>
				</div>

				<div class='relative flex items-start mt-2'>
					<div class='flex items-center h-5'>
						<input
							id='hs-radio-all'
							name='hs-radio-with-description'
							type='radio'
							value='all'
							onChange={(e) => {
								setValue(e.target.value);
							}}
							class='border-gray-200 cursor-pointer rounded-full text-blue-600 focus:ring-blue-500'
							aria-describedby='hs-radio-archive-description'
						/>
					</div>
					<label for='hs-radio-all' class='ms-3'>
						<span class='block cursor-pointer text-xs text-start font-semibold text-gray-800 dark:text-gray-300'>
							All Contacts
						</span>

						<div className='flex gap-1 items-center'>
							{role === 'admin' || role === 'subadmin' ? (
								<div className='bg-blue-100 text-blue-600 rounded-sm p-1 text-2xs mt-1 font-medium'>
									{numOfRecordsFound?.toLocaleString('en-US')} Contacts
								</div>
							) : numOfRecordsFound < totalAvailableCredits ? (
								<div className='bg-blue-100 text-blue-600 rounded-sm p-1 text-2xs mt-1 font-medium'>
									{numOfRecordsFound?.toLocaleString('en-US')} Contacts
								</div>
							) : (
								<div className='bg-red-100 text-red-600 rounded-sm p-1 text-2xs mt-1 font-medium'>
									{numOfRecordsFound?.toLocaleString('en-US')} - Not Enough
									Credits
								</div>
							)}
						</div>
					</label>
				</div>
			</div>

			<div className='mt-4'>
				<h2 class='block text-xs text-start font-semibold text-gray-800 dark:text-gray-300'>
					Choose Verification Type:
				</h2>
				<ul class='flex mt-1'>
					<li class='inline-flex items-center gap-x-2.5 py-1point5 px-2 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white'>
						<div
							onClick={() => setIsVerify(false)}
							class='relative flex gap-2 items-center w-full cursor-pointer'
						>
							<div class='flex items-center'>
								<input
									id='hs-radio-1'
									name='hs-horizontal-list-group-item-radio'
									type='radio'
									defaultChecked
									checked={isVerify === false}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
								/>
							</div>
							<p
								for='hs-radio-1'
								class='mr-2 text-xs text-gray-800 dark:text-gray-500'
							>
								Valid + Accept All
							</p>
						</div>
					</li>

					<li class='inline-flex items-center gap-x-2.5 py-1point5 px-2 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white'>
						<div
							onClick={() => setIsVerify(true)}
							class='relative flex gap-2 items-center w-full cursor-pointer'
						>
							<div class='flex items-center'>
								<input
									id='hs-horizontal-list-group-item-radio-3'
									name='hs-horizontal-list-group-item-radio'
									type='radio'
									checked={isVerify === true}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
								/>
							</div>
							<p
								for='hs-horizontal-list-group-item-radio-3'
								class='mr-2 text-xs text-gray-800 dark:text-gray-500'
							>
								Valid Only
							</p>
						</div>
					</li>

					{role === 'admin' && (
						<li class='inline-flex items-center gap-x-2.5 py-1point5 px-2 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white'>
							<div
								onClick={() => setIsVerify('noVer')}
								class='relative flex gap-2 items-center w-full cursor-pointer'
							>
								<div class='flex items-center'>
									<input
										id='hs-horizontal-list-group-item-radio-3'
										name='hs-horizontal-list-group-item-radio'
										type='radio'
										checked={isVerify === 'noVer'}
										class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
									/>
								</div>
								<p
									for='hs-horizontal-list-group-item-radio-3'
									class='mr-2 text-xs text-gray-800 dark:text-gray-500'
								>
									NV
								</p>
							</div>
						</li>
					)}
				</ul>
			</div>

			<div className='mt-4'>
				<h2 class='block text-xs text-start font-semibold text-gray-800 dark:text-gray-300'>
					Give a name for download file:
				</h2>
				<input
					type='text'
					value={fileName}
					onChange={(e) => setFileName(e.target.value)}
					placeholder='Try "My Download 101"'
					className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
				/>
			</div>

			<div className='mt-8'>
				<button
					disabled={!isVerify && !value}
					onClick={() => {
						handleClick();
					}}
					className='w-full rounded-lg bg-blue-600 hover:bg-blue-700 text-white focus:outline-none border-none text-sm font-semibold py-2 disabled:pointer-event-none disabled:opacity:50'
				>
					{isLoading ? (
						<CircularProgress sx={{ color: 'white' }} size={12} />
					) : (
						<span>Start Download</span>
					)}
				</button>
				<button
					onClick={() => setDownload(false)}
					className='mt-1 w-full rounded-lg bg-gray-200 hover:bg-gray-300 text-gray-800 focus:outline-none border-none text-sm font-semibold py-2'
				>
					Cancel
				</button>
			</div>
		</div>
	);
};
