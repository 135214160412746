import React from "react";
import PopUpModel from "./PopUpModel";
import { Button, CircularProgress } from "@mui/material";

const ConfirmDownloadModal = ({ open, title, close, handleDownload }) => {

    return (
        <PopUpModel open={open} title={title} close={close}>
            <div>
                <p className="f-16"
                    style={{
                        color: "#707070",
                    }}>Real-Time Email verification for your selected records can take 3 to 18 Minutes. You will get an email once completed. </p>
                <h6 className="f-16"
                    style={{
                        color: "#707070",
                    }}>Click ok to proceed.</h6>

                <div style={{ display: "flex", alignSelf: "center", alignItems: "center", justifyContent: "center" }}>
                    <Button
                        variant="contained"
                        className="m-2 p-2 mx-auto header_button"
                        style={{ boxShadow: "none", backgroundColor: "#1891eb", width: "38%" }}
                        onClick={handleDownload}
                    >
                        OK
                    </Button>
                    <Button
                        variant="outlined"
                        className="m-2 p-2 mx-auto header_button"
                        style={{ boxShadow: "none", backgroundColor: "white", borderColor: "#1891eb", width: "38%" }}
                        onClick={close}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </PopUpModel>
    );
};

export default ConfirmDownloadModal;
