import React from "react";
// import thankyou from "../../../assests/image/thankyou.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import { Button, CircularProgress } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
  buySubscription,
  postSubscriptionPlan,
} from "../../../componants/Context/APIs";
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import { CircularProgress } from "@mui/material";
// import { AuthContext } from "../../../componants/Context/AuthContext";
// import { RESEND_EMAIL } from "../../../componants/Context/Types";p

const Thankyoupage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("planId") || "";
  const isAnnual = searchParams.get("isAnnual") || "";
  const token = searchParams.get("token") || "";
  const activation = searchParams.get("activation") || "";
  const status = searchParams.get("status") || "";
  const createOrder = async () => {
    const accessToken = Cookies.get("token");
    if (!accessToken) return;
    const payload = {
      isAnnual: isAnnual === "false" ? false : true,
      subscription_id: planId,
      payment_gateway: "STRIPE",
    };
    try {
      const res = await buySubscription(payload);
      if (res.data) {
        window.location = res.data.link;
      }
    } catch (e) {
      console.log(e);
      window.location = "/";
      NotificationManager.error(e.response.data || "Something Went Wrong!");
    }
  };

  useEffect(() => {
    if (token && planId) {
      Cookies.set("token", token, {
        expires: 30,
      });
      setTimeout(() => {
        createOrder();
      }, 1000);
    }
  }, []);

  return (
    <div className=" m-5">
      <div className="shadow-lg m-5">
        {!token && !planId ? (
          <>
            <h6
              className="text-start px-4 pt-4 text-primary pointer"
              onClick={() => {
                navigate("/profile");
              }}
            >
              <ArrowBackIosIcon style={{ width: "20px", height: "20px" }} />
              Back
            </h6>
            <div className="px-5 mx-5 ">
              <h3 className="mb-4">Thank You.</h3>
              {status === "success" ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Warstwa_1"
                    x="0px"
                    y="0px"
                    width="264px"
                    height="200px"
                    viewBox="0 0 264 264"
                  >
                    <path
                      fill="#B4CCD9"
                      d="M132.5,0C59.321,0,0,59.321,0,132.5S59.321,265,132.5,265S265,205.679,265,132.5S205.679,0,132.5,0z   M132.5,254.919c-67.611,0-122.418-54.808-122.418-122.419c0-67.61,54.807-122.418,122.418-122.418S254.919,64.89,254.919,132.5  C254.919,200.111,200.111,254.919,132.5,254.919z"
                    />
                    <path
                      fill="#2EAF2C"
                      d="M105.359,191.006c-4.131,0-8.26-1.584-11.405-4.723l-28.445-28.45c-6.297-6.301-6.297-16.512,0-22.813  c6.301-6.29,16.508-6.29,22.808,0l17.43,17.438l73.952-69.028c6.515-6.073,16.72-5.716,22.793,0.783  c6.081,6.514,5.728,16.721-0.785,22.799l-85.341,79.654C113.26,189.561,109.309,191.006,105.359,191.006z"
                    />
                  </svg>
                  <h3 className="px-5 mx-5 my-5 ">
                    Thank you, Your Payment Is Accepted.
                  </h3>
                  <div
                    onClick={() => {
                      navigate("/profile", { state: { title: "Invoice" } });
                    }}
                    className="m-auto d-flex flex-row w-50 justify-content-around pointer blue_text p-4"
                  >
                    View Invoice
                  </div>
                </>
              ) : (
                <>
                  {status === "failed" && (
                    <>
                      <svg
                        width="253px"
                        height="253px"
                        viewBox="-2.64 -2.64 29.28 29.28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        stroke=""
                        transform="rotate(0)"
                        stroke-width="0.00024000000000000003"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0">
                          <rect
                            x="-2.64"
                            y="-2.64"
                            width="29.28"
                            height="29.28"
                            rx="14.64"
                            fill="#f0f3f4"
                            strokewidth="0"
                          ></rect>
                        </g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.9498 8.46447C17.3404 8.07394 17.3404 7.44078 16.9498 7.05025C16.5593 6.65973 15.9261 6.65973 15.5356 7.05025L12.0001 10.5858L8.46455 7.05025C8.07402 6.65973 7.44086 6.65973 7.05033 7.05025C6.65981 7.44078 6.65981 8.07394 7.05033 8.46447L10.5859 12L7.05033 15.5355C6.65981 15.9261 6.65981 16.5592 7.05033 16.9497C7.44086 17.3403 8.07402 17.3403 8.46455 16.9497L12.0001 13.4142L15.5356 16.9497C15.9261 17.3403 16.5593 17.3403 16.9498 16.9497C17.3404 16.5592 17.3404 15.9261 16.9498 15.5355L13.4143 12L16.9498 8.46447Z"
                            fill="#ec5555"
                          ></path>{" "}
                        </g>
                      </svg>
                      <h3 className="px-5 mx-5 my-5 mb-5 text-danger py-5 ">
                        Thank you, Your Payment is Failed.
                      </h3>
                    </>
                  )}
                </>
              )}
              {activation === "successful" && (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Warstwa_1"
                    x="0px"
                    y="0px"
                    width="264px"
                    height="200px"
                    viewBox="0 0 264 264"
                  >
                    <path
                      fill="#B4CCD9"
                      d="M132.5,0C59.321,0,0,59.321,0,132.5S59.321,265,132.5,265S265,205.679,265,132.5S205.679,0,132.5,0z   M132.5,254.919c-67.611,0-122.418-54.808-122.418-122.419c0-67.61,54.807-122.418,122.418-122.418S254.919,64.89,254.919,132.5  C254.919,200.111,200.111,254.919,132.5,254.919z"
                    />
                    <path
                      fill="#2EAF2C"
                      d="M105.359,191.006c-4.131,0-8.26-1.584-11.405-4.723l-28.445-28.45c-6.297-6.301-6.297-16.512,0-22.813  c6.301-6.29,16.508-6.29,22.808,0l17.43,17.438l73.952-69.028c6.515-6.073,16.72-5.716,22.793,0.783  c6.081,6.514,5.728,16.721-0.785,22.799l-85.341,79.654C113.26,189.561,109.309,191.006,105.359,191.006z"
                    />
                  </svg>
                  <h3 className="px-5 mx-5 my-5 ">
                    Thank you, Your Account is Activated.
                  </h3>
                  <div
                    onClick={() => {
                      navigate("/", { state: { title: "" } });
                    }}
                    className="m-auto d-flex flex-row w-50 justify-content-around pointer blue_text p-4"
                  >
                    Go To login page
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "60vh" }}
          >
            <h6>Redirected to Payment Page , Please Wait For a moments...</h6>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Thankyoupage;
