/** @format */
import { useContext, useEffect, useState } from 'react';
import Navbar from '../../Elements/Navbar/Navbar';
import Invoices from '../../componants/comman/Invoice';
import Transactions from '../../componants/comman/Transactions';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../componants/Context/AuthContext';
import CancelSubModel from '../../componants/comman/CancelSubModel';

const BillingScreen = () => {
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState('Invoices');
	const query = new URLSearchParams(useLocation().search);
	const active = query.get('active');
	const { profile } = useContext(AuthContext);
	const [consumePercentage, setConsumePercentage] = useState(0);
	const [days, setDays] = useState('Expires in 14 days');
	const [cancelModel, setCancelModel] = useState(false);

	useEffect(() => {
		if (active) {
			setActiveTab(active);
		}
	}, [active]);

	useEffect(() => {
		var per = (
			((profile?.totalCredits - profile?.availCredits) /
				profile?.totalCredits) *
			100
		).toFixed(1);
		setConsumePercentage(per);
	}, [profile]);

	useEffect(() => {
		const targetDate = new Date(profile?.plan?.subscription_end_date);
		const today = new Date();

		targetDate.setHours(0, 0, 0, 0);
		today.setHours(0, 0, 0, 0);

		const timeDiff = targetDate - today;
		const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

		if (dayDiff > 1) {
			if (profile?.plan.stripe_subscription_id) {
				setDays(`Renews in ${dayDiff} days`);
			} else {
				setDays(`Expires in ${dayDiff} days`);
			}
		} else if (dayDiff === 1) {
			if (profile?.plan.stripe_subscription_id) {
				setDays(`Renews in 1 day`);
			} else {
				setDays(`Expires in 1 day`);
			}
		} else if (dayDiff === 0) {
			if (profile?.plan.stripe_subscription_id) {
				setDays(`Renews Today`);
			} else {
				setDays(`Expires Today`);
			}
		} else {
			if (profile?.plan.stripe_subscription_id) {
				setDays(`Renews soon`);
			} else {
				setDays(`Expired`);
			}
		}
	}, [profile]);

	console.log(profile);

	return (
		<div className='relative min-h-screen pb-10'>
			<div className='fixed top-0 z-10 w-full'>
				<Navbar />
			</div>

			<div className='mx-20 mt-20 text-left'>
				<h1 className='text-slate-800 font-semibold text-xl'>
					Billing & Usage
				</h1>
				<div className='bg-white rounded-xl border border-gray-300 mt-3'>
					<div className='flex justify-between px-4 py-2.5 items-center gap-4'>
						{profile ? (
							<div>
								<p className='text-xs text-gray-600 font-medium'>Plan</p>
								<h3 className='text-lg text-gray-800 font-semibold'>
									{profile?.plan?.subscription_type}
								</h3>
							</div>
						) : (
							<div>
								<div className='h-2 w-10 bg-gray-300 rounded-full animate-pulse'></div>
								<div className='h-6 mt-2 w-20 bg-gray-300 rounded-lg animate-pulse'></div>
							</div>
						)}
						{profile ? (
							<div>
								<p className='text-xs text-gray-600 font-medium'>Credits</p>
								<h3 className='text-lg text-gray-800 font-semibold'>
									{profile?.plan?.credits}{' '}
									<span className='text-xs font-medium'>
										{profile?.plan?.isAnnual === false ||
										profile?.plan?.validity === 30
											? 'per month'
											: 'per year'}
									</span>
								</h3>
							</div>
						) : (
							<div>
								<div className='h-2 w-10 bg-gray-300 rounded-full animate-pulse'></div>
								<div className='h-6 mt-2 w-20 bg-gray-300 rounded-lg animate-pulse'></div>
							</div>
						)}
						{profile ? (
							<div>
								<p className='text-xs text-gray-600 font-medium'>Amount</p>
								<h3 className='text-lg text-gray-800 font-semibold'>
									${profile?.plan?.subscription_amount}{' '}
									<span className='text-xs font-medium'>
										{profile?.plan?.isAnnual === false ||
										profile?.plan?.validity === 30
											? 'per month'
											: 'per year'}
									</span>
								</h3>
							</div>
						) : (
							<div>
								<div className='h-2 w-10 bg-gray-300 rounded-full animate-pulse'></div>
								<div className='h-6 mt-2 w-20 bg-gray-300 rounded-lg animate-pulse'></div>
							</div>
						)}
						{profile ? (
							<div>
								<p className='text-xs text-gray-600 font-medium'>
									{profile?.plan?.stripe_subscription_id
										? 'Renews Date'
										: 'Expiry Date'}
								</p>
								<h3 className='text-lg text-gray-800 font-semibold'>
									{new Date(
										profile?.plan?.subscription_end_date
									).toLocaleDateString('en-GB', {
										day: '2-digit',
										month: 'short',
										year: 'numeric',
									})}
								</h3>
							</div>
						) : (
							<div>
								<div className='h-2 w-10 bg-gray-300 rounded-full animate-pulse'></div>
								<div className='h-6 mt-2 w-20 bg-gray-300 rounded-lg animate-pulse'></div>
							</div>
						)}
						<div className='w-1/3 text-right'>
							<div className='inline-block'>
								{profile ? (
									<div
										className={`flex items-center gap-2 border font-semibold text-xs py-1.5 px-2 rounded-md ${
											profile?.plan?.isCancelled
												? '!border-red-300 text-red-700'
												: profile?.plan?.subscription_amount_status
												? '!border-slate-200 text-gray-800'
												: '!border-red-300 text-red-700'
										}`}
									>
										{profile?.plan?.isCancelled ? (
											<>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													stroke-width='2'
													stroke-linecap='round'
													stroke-linejoin='round'
													class='size-3.5'
												>
													<circle cx='12' cy='12' r='10' />
													<path d='m4.9 4.9 14.2 14.2' />
												</svg>
												Subscription Cancelled
											</>
										) : profile?.plan?.subscription_amount_status ? (
											profile?.plan?.stripe_subscription_id ? (
												<>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='size-3.5'
													>
														<path d='m17 2 4 4-4 4' />
														<path d='M3 11v-1a4 4 0 0 1 4-4h14' />
														<path d='m7 22-4-4 4-4' />
														<path d='M21 13v1a4 4 0 0 1-4 4H3' />
													</svg>
													{days}
												</>
											) : (
												<>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
														class='size-3.5'
													>
														<path d='M8 2v4' />
														<path d='M16 2v4' />
														<rect width='18' height='18' x='3' y='4' rx='2' />
														<path d='M3 10h18' />
														<path d='M8 14h.01' />
														<path d='M12 14h.01' />
														<path d='M16 14h.01' />
														<path d='M8 18h.01' />
														<path d='M12 18h.01' />
														<path d='M16 18h.01' />
													</svg>
													{days}
												</>
											)
										) : (
											<>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													stroke-width='2'
													stroke-linecap='round'
													stroke-linejoin='round'
													class='size-3.5'
												>
													<path d='M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z' />
													<path d='m4.243 5.21 14.39 12.472' />
												</svg>
												Inactive Plan
											</>
										)}
									</div>
								) : (
									<div className='w-28 h-5 bg-gray-300 rounded-lg'></div>
								)}
							</div>
							{!profile ? (
								<div className='flex justify-end gap-4 items-center mt-2'>
									<div className='h-6 w-32 animate-pulse bg-gray-300 rounded-lg'></div>
									<div className='h-6 w-32 animate-pulse bg-gray-300 rounded-lg'></div>
								</div>
							) : (
								!profile?.plan?.isCancelled &&
								!profile?.plan?.isExpired && (
									<div className='flex justify-end gap-4 items-center mt-2'>
										{profile?.plan?.subscription_type !== 'Free Trail' && (
											<button
												onClick={() => setCancelModel(true)}
												className='focus:outline-none border-none text-ms text-gray-800 hover:text-slate-900 font-medium'
											>
												Cancel Subscription
											</button>
										)}
										<button
											onClick={() => navigate('/plans')}
											className='focus:outline-none border-none text-ms text-blue-600 hover:text-blue-500 font-medium flex items-center gap-1'
										>
											Upgrade Plan
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
												class='size-4'
											>
												<path d='M7 7h10v10' />
												<path d='M7 17 17 7' />
											</svg>
										</button>
									</div>
								)
							)}
						</div>
					</div>
					<div className='mx-4 h-[1px] bg-gray-300'></div>
					<div className='mt-4 flex justify-between items-start'>
						<div className='w-full ml-4'>
							<div className='flex justify-between items-center mt-2'>
								<h4 className='text-sm font-medium text-gray-800'>
									Credits Usage
								</h4>
								{profile ? (
									<p className='text-xs text-gray-600 font-medium'>
										{profile?.plan?.subscription_amount_status
											? profile?.plan?.stripe_subscription_id
												? 'New credits added on 31 Dec, 2024'
												: 'To use the remaining credits, the plan needs to be active'
											: 'Clear the unpaid invoice to use your credits'}
									</p>
								) : (
									<div className='animate-pulse bg-gray-300 h-4 w-60 rounded-full'></div>
								)}
							</div>
							<div
								className='mt-3 flex w-full h-2 bg-gray-200 rounded-full overflow-hidden'
								role='progressbar'
								aria-valuenow='25'
								aria-valuemin='0'
								aria-valuemax='100'
							>
								<div
									className='flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500'
									style={{ width: `${consumePercentage}%` }}
								></div>
							</div>
							<div className='mt-2 w-full flex justify-between items-center'>
								{profile ? (
									<p className='text-xs font-medium text-gray-800'>
										{profile?.totalCredits - profile?.availCredits} (
										{consumePercentage}
										%)
									</p>
								) : (
									<div className='animate-pulse bg-gray-300 h-3 w-10 rounded-full'></div>
								)}
								{profile ? (
									<p className='text-xs font-medium text-gray-800'>
										{profile?.totalCredits}
									</p>
								) : (
									<div className='animate-pulse bg-gray-300 h-3 w-14 rounded-full'></div>
								)}
							</div>
						</div>
						<div class='relative w-52 h-28 ml-20 mr-4'>
							<svg
								class='w-full rotate-180'
								viewBox='0 0 36 36'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle
									cx='18'
									cy='18'
									r='16'
									fill='none'
									class='stroke-current text-blue-100'
									stroke-width='3'
									stroke-dasharray='50 100'
									stroke-linecap='round'
								></circle>

								<circle
									cx='18'
									cy='18'
									r='16'
									fill='none'
									class='stroke-current text-blue-600'
									stroke-width='1'
									stroke-dasharray={`${
										profile
											? ((profile?.AssMem /
													(profile?.AssMem + profile?.UnassMem)) *
													100) /
											  2
											: 0
									} 100`}
									stroke-linecap='round'
								></circle>
							</svg>

							<div class='absolute top-9 start-1/2 transform -translate-x-1/2 text-center'>
								{profile ? (
									<span class='text-2xl font-bold text-blue-600'>
										{profile?.AssMem}/{profile?.AssMem + profile?.UnassMem}
									</span>
								) : (
									<span class='text-2xl font-bold text-blue-600'>0/0</span>
								)}
								<span class='text-xs text-gray-600 block font-medium'>
									Users
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='mx-20 flex justify-center items-center mt-6'>
				<div class='w-full border-b !border-gray-300'>
					<nav
						class='-mb-0.5 flex justify-start gap-x-6'
						aria-label='Tabs'
						role='tablist'
						aria-orientation='horizontal'
					>
						{/* <button
							type='button'
							className={`py-2 px-2 font-medium inline-flex items-center gap-x-2 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none active ${
								activeTab === 'Overview'
									? 'border-blue-600 text-gray-900'
									: 'border-transparent text-gray-600 hover:text-gray-700'
							}`}
							id='horizontal-alignment-item-1'
							aria-selected='true'
							data-hs-tab='#horizontal-alignment-1'
							aria-controls='horizontal-alignment-1'
							role='tab'
							onClick={() => setActiveTab('Overview')}
						>
							Overview
						</button> */}
						<button
							type='button'
							className={`py-2 px-2 font-medium inline-flex items-center gap-x-2 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none active ${
								activeTab === 'Invoices'
									? 'border-blue-600 text-gray-900'
									: 'border-transparent text-gray-600 hover:text-gray-700'
							}`}
							id='horizontal-alignment-item-1'
							aria-selected='true'
							data-hs-tab='#horizontal-alignment-1'
							aria-controls='horizontal-alignment-1'
							role='tab'
							onClick={() => setActiveTab('Invoices')}
						>
							Invoices
						</button>
						<button
							type='button'
							className={`py-2 px-2 font-medium inline-flex items-center gap-x-2 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none active ${
								activeTab === 'Transactions'
									? 'border-blue-600 text-gray-900'
									: 'border-transparent text-gray-600 hover:text-gray-700'
							}`}
							id='horizontal-alignment-item-2'
							aria-selected='false'
							data-hs-tab='#horizontal-alignment-2'
							aria-controls='horizontal-alignment-2'
							role='tab'
							onClick={() => setActiveTab('Transactions')}
						>
							Payment History
						</button>
					</nav>
				</div>
			</div>

			<div className='mt-6 mx-20 bg-white rounded-lg'>
				{'Invoices' === activeTab && <Invoices />}
				{'Transactions' === activeTab && <Transactions />}
			</div>

			<CancelSubModel
				open={cancelModel}
				close={() => setCancelModel(false)}
				title={'Cancel Subscription'}
			/>
		</div>
	);
};

export default BillingScreen;
