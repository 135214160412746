import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import img from "../../../assests/image/Forgotpassword.jpg";
import { useContext, useState } from "react";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "../../../componants/Context/AuthContext";
import {
  FORGET_PASSWORD,
  RESET_PASSWORD,
} from "../../../componants/Context/Types";
import { useLocation, useParams } from "react-router-dom";

const ResetPassword = () => {
  const { dispatch } = useContext(AuthContext);
  const [checkeditprofile, setcheckeditprofile] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const initialValues = {
    password: "",
    confirm_password: "",
    reset_id: id,
  };

  const validate = Yup.object().shape({
    password: Yup.string()
      .required("Please Enter your password")
      .test(
        "len",
        "Must have atleast 6 Characters",
        (val) => val?.toString().length >= 6
      ),
    confirm_password: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const handleSubmit = (values) => {
    dispatch({
      type: RESET_PASSWORD,
      payload: values,
      setIsLoading: setIsLoading,
    });
  };
  return (
    <>
      <div className="login-page">
        <div className="img-content">
          <img src={img} className="login-img" />
        </div>
        <div className="sighnUp-content">
          <h2>Forgot Password</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
              // alert("Form is validated! Submitting the form...");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
            }) => (
              <Form>
                <div className="login-input">
                  <TextField
                    id="outlined-basic"
                    label="Password"
                    value={values.password}
                    variant="outlined"
                    type="password"
                    name="password"
                    className="w-75 mb-3 mt-3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* error validated by Yup */}
                  <div className="error-container mb-2">
                    <div className="error">
                      {errors["password"] &&
                        touched["password"] &&
                        errors["password"]}
                    </div>
                  </div>
                  <TextField
                    id="outlined-basic"
                    label="Confirm Password"
                    value={values.confirm_password}
                    variant="outlined"
                    type="text"
                    name="confirm_password"
                    className="w-75 mb-3 mt-3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* error validated by Yup */}
                  <div className="error-container mb-2">
                    <div className="error">
                      {errors["confirm_password"] &&
                        touched["confirm_password"] &&
                        errors["confirm_password"]}
                    </div>
                  </div>
                </div>

                <div>
                  <button className="login-btn mb-2" type="submit">
                    {isLoading ? (
                      <CircularProgress sx={{ color: "white" }} size={30} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
