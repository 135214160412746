/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import { MainContext } from '../../../../componants/filterContext/FilterContext';
import { EMAIL_TYPE } from '../filterTypes';

export default function EmailType(props) {
	const { setFilter, setSortField, selectedVal, setSelectedVal } =
		useContext(AuthContext);
	const { globalFilterKeyTofilterKey } = useContext(MainContext);

	const handleChange = (event) => {
		setSelectedVal(event.target.value);
		setSortField({});
		if (event.target.value === '') {
			setFilter((prev) => {
				return {
					...prev,
					[globalFilterKeyTofilterKey[EMAIL_TYPE]]: [],
				};
			});
		} else {
			setFilter((prev) => {
				return {
					...prev,
					[globalFilterKeyTofilterKey[EMAIL_TYPE]]: [event.target.value],
				};
			});
		}
	};

	useEffect(() => {
		setFilter((prev) => {
			return {
				...prev,
				[globalFilterKeyTofilterKey[EMAIL_TYPE]]: [],
			};
		});
	}, []);

	return (
		<div className='d-flex p-3 flex-column justify-content-start align-items-start'>
			<div className='d-flex align-items-center mb-2'>
				<label className='mb-0' style={{ cursor: 'pointer' }} htmlFor='all'>
					<input
						onChange={(e) => handleChange(e)}
						checked={selectedVal === ''}
						className='me-2'
						value=''
						type='radio'
						name='emailType'
						id='all'
					/>
					All
				</label>
			</div>
			<div className='d-flex align-items-center mb-2'>
				<label className='mb-0' style={{ cursor: 'pointer' }} htmlFor='isp'>
					<input
						onChange={(e) => handleChange(e)}
						className='me-2'
						checked={selectedVal === 'Personal Email'}
						value='Personal Email'
						type='radio'
						name='emailType'
						id='isp'
					/>
					Personal Email
				</label>
			</div>
			<div className='d-flex align-items-center'>
				<label className='mb-0' style={{ cursor: 'pointer' }} htmlFor='b2b'>
					<input
						onChange={(e) => handleChange(e)}
						className='me-2'
						checked={selectedVal === 'B2B'}
						value='B2B'
						type='radio'
						name='emailType'
						id='b2b'
					/>
					B2B
				</label>
			</div>
		</div>
	);
}
