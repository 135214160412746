/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../componants/Context/AuthContext';
import {
	EDIT_COMPANY_INFO_ADMIN,
	GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN,
} from '../../../componants/Context/Types';

const EditUser = (props) => {
	const [info, setInfo] = useState({
		name: '',
		email: '',
		company_name: '',
		mobile: '',
		password: '',
		is_internal_user: false,
		is_file_enhancer_user: false,
		upload_limit: 5000,
	});

	const [isLoading, setIsLoading] = useState(false);
	const { dispatch } = useContext(AuthContext);

	useEffect(() => {
		dispatch({
			type: GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN,
			id: props.selectedCompanyByAdmin,
			updateState: setInfo,
			setIsLoading: setIsLoading,
		});
	}, []);

	const handleSubmit = () => {
		if (!info.password) {
			delete info.password;
		}
		dispatch({
			type: EDIT_COMPANY_INFO_ADMIN,
			id: props.selectedCompanyByAdmin,
			values: info,
			updateState: setInfo,
			setIsLoading: setIsLoading,
			setmodel: props.setmodel,
		});
	};

	return (
		<>
			<div className='mt-4'>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Name:
					</h2>
					<input
						type='text'
						value={info?.name}
						onChange={(e) => setInfo({ ...info, name: e.target.value })}
						placeholder='Enter name'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Company Name:
					</h2>
					<input
						type='text'
						value={info?.company_name}
						onChange={(e) => setInfo({ ...info, company_name: e.target.value })}
						placeholder='Enter company name'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Email:
					</h2>
					<input
						type='text'
						value={info?.email}
						onChange={(e) => setInfo({ ...info, email: e.target.value })}
						placeholder='Enter email'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Mobile:
					</h2>
					<input
						type='text'
						value={info?.mobile}
						onChange={(e) => setInfo({ ...info, mobile: e.target.value })}
						placeholder='Enter mobile number'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Password:
					</h2>
					<input
						type='text'
						value={info?.password}
						onChange={(e) => setInfo({ ...info, password: e.target.value })}
						placeholder='Enter password'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
					<p className='mt-0.5 ml-1 text-blue-600 text-left text-2xs font-medium'>
						Enter password to change existing one
					</p>
				</div>
				<div className='mt-2'>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Access:
					</h2>
					<div className='mt-2 flex flex-wrap gap-x-1 gap-y-2 items-center'>
						<div
							className={`rounded-full ${
								info.is_internal_user
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() =>
									setInfo({ ...info, is_internal_user: !info.is_internal_user })
								}
								className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
							>
								<div className='custom-checkbox-filter'>
									<input
										type='checkbox'
										className='mt-0.5'
										checked={info.is_internal_user}
									/>
									<div className='check-mark'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											class='w-2.5 h-2.5'
											viewBox='0 0 20 20'
											fill='currentColor'
											stroke='currentColor'
											stroke-width='1'
										>
											<path
												fill-rule='evenodd'
												d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
												clip-rule='evenodd'
											></path>
										</svg>
									</div>
								</div>
								Email Verification
							</h3>
						</div>
						<div
							className={`rounded-full ${
								info.is_file_enhancer_user
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() =>
									setInfo({
										...info,
										is_file_enhancer_user: !info.is_file_enhancer_user,
									})
								}
								className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
							>
								<div className='custom-checkbox-filter'>
									<input
										type='checkbox'
										className='mt-0.5'
										checked={info.is_file_enhancer_user}
									/>
									<div className='check-mark'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											class='w-2.5 h-2.5'
											viewBox='0 0 20 20'
											fill='currentColor'
											stroke='currentColor'
											stroke-width='1'
										>
											<path
												fill-rule='evenodd'
												d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
												clip-rule='evenodd'
											></path>
										</svg>
									</div>
								</div>
								Email Finder
							</h3>
						</div>
					</div>
				</div>
			</div>

			{/* <div className='px-4'>
				{userdetails?.is_internal_user && (
					<TextField
						disabled={!isEdit}
						id='outlined-basic'
						value={userdetails?.upload_limit}
						size='small'
						className='user-input-fild'
						InputLabelProps={{ shrink: true }}
						name='File Verification Upload Limit'
						label='File Verification Upload Limit'
						variant='outlined'
						onChange={(e) => {
							setuserdetails((prev) => {
								return { ...prev, upload_limit: e.target.value };
							});
						}}
					/>
				)}
			</div> */}

			<button
				onClick={handleSubmit}
				className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
			>
				{isLoading ? (
					<CircularProgress sx={{ color: 'white' }} size={14} />
				) : (
					'Submit'
				)}
			</button>
		</>
	);
};

export default EditUser;
