import React, { useContext } from "react";
import { AuthContext } from "../../../componants/Context/AuthContext";
import { FILTER_CONTACTS } from "../../../componants/Context/Types";
import { MainContext } from "../../../componants/filterContext/FilterContext";

const SelectedBox = ({ value, index, filterKey, globalFilterKey }) => {
  const {
    dispatch,
    setFilter,
    filter,
    setAllContacts,
    page,
    rowsPerPage,
    setFirstTimeApiCall,
    setFirstTimeFilter,
    firstTimeFilter,
    setSortField,
  } = useContext(AuthContext);
  const { setGlobalSelectFilter, globalSelectFilter } = useContext(MainContext);

  const handleRemoveItem = (index, filterKey, globalFilterKey) => {
    setFirstTimeApiCall(false);
    setFirstTimeFilter({
      filterCount: 0,
      isFilterCall: false,
    });
    setSortField({});
    setFilter((prev) => {
      return {
        ...prev,
        [filterKey]: filter[filterKey].filter((item, i) => i !== index),
      };
    });
    setGlobalSelectFilter((prev) => {
      return {
        ...prev,
        [globalFilterKey]: globalSelectFilter[globalFilterKey].filter(
          (item, i) => i !== index
        ),
      };
    });
    dispatch({
      type: FILTER_CONTACTS,
      setAllContacts: setAllContacts,
      page: page,
      limit: rowsPerPage,
    });
  };

  return (
    <>
      <div class="selected-option-wrapper">
        <div class="selected-option-value">
          {typeof value === "object"
            ? `${value[0]}-${value[1]}`
            : (value === "Director-Level" && "VP-Level") ||
              (value === "VP-Level" && "Director-Level") ||
              value}
        </div>
        <div
          role="button"
          class="selected-option-cross"
          onClick={() => {
            handleRemoveItem(index, filterKey, globalFilterKey);
          }}
        >
          <svg
            height="14"
            width="14"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            class="css-tj5bde-Svg"
          >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        </div>
      </div>
    </>
  );
};

export default SelectedBox;
