import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { Table } from "antd";
import { GET_POTENTIAL_CUSTOMERS } from "../../../../componants/Context/Types";

const columns = [
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Created On",
    dataIndex: "createdAt",
  },
];

const Customers = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count,setCount]=useState(0);
  const { dispatch } = useContext(AuthContext);
  useEffect(() => {
    dispatch({
      type: GET_POTENTIAL_CUSTOMERS,
      updateState: {setRows,setCount},
      setIsLoading: setIsLoading,
    });
  }, []);
  return (
    <div>
      <div>
        <h4 className="p-2 profile-header f-14">Customers Emails</h4>
      </div>
      <div className="m-1  f-16 ">Total Count: {count} </div>
      <div className="m-2">
        <Table columns={columns} dataSource={rows} loading={isLoading} />
      </div>
    </div>
  );
};

export default Customers;
