import React, { useState } from 'react';
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tippy from '@tippyjs/react';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect } from 'react';
import { downloadCSV } from "download-csv";
import FacebookCircularProgress from '../../componants/comman/FacebookCircularProgress';
import { checkInternalStatus, getCompanyInternalFileVerificationApi, internalDownloadFile, verificationCompletedAPi } from '../../componants/Context/APIs';
import { Pagination, Stack } from '@mui/material';

const header = [
    { key: "fullname", label: "FullName" },
    { key: "email", label: "Email" },
    { key: "title", label: "Title" }
]

const CompanyFileVerification = ({ selectedCompanyByAdmin, setVerData, verData, setVerLoader, verLoader, setPages, pages, setPage, page, totalCount, setTotalCount }) => {

    const [total1, setTotal1] = useState(0);
    const [total2, setTotal2] = useState(0);
    const [total3, setTotal3] = useState(0);
    const [total4, setTotal4] = useState(0);
    function secondsToMinutes(seconds) {
        return Number(seconds) / 60;
      }

    const runBounceAPi = async (data) => {
        const body = {
            sys_filename: data?.sys_filename,
            mvfileid: data?.mvfileid
        }
        try {
            const res = await checkInternalStatus(body);
            setVerData((prev) => [{ ...data, percent: res?.data?.data?.percent, timeLeft: secondsToMinutes(res?.data?.data?.estimated_time_sec) }, ...prev])
        } catch (error) {
            
        }
    }

    const handleChange = (e, value) => {
        setPage(value)
    }

    const getFileVerification = async () => {
        setVerLoader(true);
        try {
            const res = await getCompanyInternalFileVerificationApi("", "", selectedCompanyByAdmin, page);
            setVerData([]);
            let allData = [];
            res?.data?.final[0]?.forEach((item, index) => {
                setVerLoader(true);
                if(item?.status !== "Verified") {
                    runBounceAPi(item);
                }else {
                    allData.push({...item, percent: 100, timeLeft: 0});
                    setVerData(allData);
                }
            });
            setTotalCount(res?.data?.totalCount[0]);
            setPages(res?.data?.pages);
            setVerLoader(false);
        } catch (error) {
            setVerLoader(false);
            
        }
    }
    const downloadFileHandler = async (name, type, fileName) => {
        try {
            const res = await internalDownloadFile({
                sys_filename: name,
                mvstatus: type
            });
            const data = res?.data[0] || [];
            let sheetData = [];
            data?.forEach((item) => {
                sheetData.push({
                    fullname: item?.fullname,
                    email: item?.email,
                    title: item?.title
                })
            });
            downloadCSV(sheetData, header, fileName)
        } catch (error) {
            
        }
    }

    const getFileVerification2 = async () => {
        try {
            const res = await getCompanyInternalFileVerificationApi("", "", selectedCompanyByAdmin, page);
            let data = []
            res?.data?.final[0]?.forEach((item) => {
                data.push({
                    ...item,
                    percent: 100,
                    timeLeft: 0
                })
            });
            setTotalCount(res?.data?.totalCount[0]);
            setPages(res?.data?.pages);
            setVerData(data);
            setVerLoader(false);
        } catch (error) {
            
        }
    }

    const completeHandler = async (body) => {
        try {
            const res = await verificationCompletedAPi(body);
            getFileVerification2();
        } catch (error) {
            
        }
    }
    const checkStatus = async (data, index) => {
        const body = {
            sys_filename: data?.sys_filename,
            mvfileid: data?.mvfileid
        }
        try {
            const res = await checkInternalStatus(body);
            if (res?.data?.data?.percent === 100 || res?.data?.data?.percent === "100") {
                completeHandler(body);
            }else {
                let arrData = verData;
                arrData[index]["timeLeft"] = secondsToMinutes(res?.data?.data?.estimated_time_sec);
                arrData[index]["percent"] = res?.data?.data?.percent;
                setVerData(arrData);
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        if(selectedCompanyByAdmin) {
            setVerData([])
            getFileVerification();
        }
    }, [selectedCompanyByAdmin, page])
    useEffect(() => {
        const t1 = "invalid";
        const t2 = "valid";
        const t3 = "catch_all";
        const t4 = "Total";
        if (totalCount.length > 0) {

            for (var i = 0; i < totalCount.length; i++) {
                if (totalCount[i]?.mvstatus === t1) {
                    setTotal1(totalCount[i]?.Count || 0)
                }
                if (totalCount[i]?.mvstatus === t2) {
                    setTotal2(totalCount[i]?.Count || 0)
                }
                if (totalCount[i]?.mvstatus === t3) {
                    setTotal3(totalCount[i]?.Count || 0)
                }
                if (totalCount[i]?.mvstatus === t4) {
                    setTotal4(totalCount[i]?.Count || 0)
                }
            }
        }
    }, [totalCount])


    useEffect(() => {
        let interval;
        if (verData?.length > 0) {
            interval = setInterval(() => {
                setVerLoader(false);
                verData.forEach((item, index) => {
                    if(item?.status && item?.status !== "Verified") {
                        checkStatus(item, index);
                    }
                })
            }, 30000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [verData]);

    return (
        <div>
            {
                verLoader ? (
                    <div className="d-flex justify-content-between flex-row">
                        <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
                            <FacebookCircularProgress />
                        </div>
                    </div>
                ) : (
                    <TableContainer style={{ overflowY: "auto", maxHeight: "60vh" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead style={{ position: "sticky", top: "0" }}>
                                <TableRow className="bg-light">
                                    <TableCell className="fw-bold" align="center">
                                        File Name
                                    </TableCell>
                                    <TableCell className="fw-bold" align="center">
                                        Uploaded By
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Creation Date
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Count
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Valid Only
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Catch-All
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Invalid
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Status
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Progress
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Time Left
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    verData && verData?.length > 0 && verData.map((row) => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                    className="p-2"
                                                >
                                                    {row.filename}
                                                </TableCell>
                                                <TableCell align="center" className="p-2">
                                                    {row.uploadby}
                                                </TableCell>
                                                <TableCell align="center" className="p-2">
                                                    {new Date(row.created_at).toUTCString()}
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.totalCount}
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.validCount}
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.catchAllCount}
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.invalidCount}
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.status}
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.percent} / 100
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.timeLeft}
                                                </TableCell>

                                                <TableCell align="center" className="p-2 dropdown-team ">
                                                    <Tippy
                                                        content={
                                                            <div className="TippyAction bg-light pointer">
                                                                <div
                                                                    onClick={() => downloadFileHandler(row?.sys_filename, "valid", row?.filename)}
                                                                    className=" p-2 pointer hover-dropdown"
                                                                >
                                                                    Valid Only
                                                                </div>
                                                                <div
                                                                    onClick={() => downloadFileHandler(row?.sys_filename, "catch_all", row?.filename)}
                                                                    className=" p-2 pointer hover-dropdown"
                                                                >
                                                                    Catch-All
                                                                </div>
                                                                <div
                                                                    onClick={() => downloadFileHandler(row?.sys_filename, "invalid", row?.filename)}
                                                                    className=" p-2 pointer hover-dropdown"
                                                                >
                                                                    Invalid
                                                                </div>
                                                                <div
                                                                    onClick={() => downloadFileHandler(row?.sys_filename, "all", row?.filename)}
                                                                    className=" p-2 pointer hover-dropdown"
                                                                >
                                                                    All
                                                                </div>
                                                            </div>
                                                        }
                                                        placement="bottom-end"
                                                        arrow={false}
                                                        offset={[15, 1]}
                                                        trigger="mouseenter"
                                                        interactive={true}
                                                    >
                                                        <MoreVertIcon></MoreVertIcon>
                                                    </Tippy>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                <TableRow
                                    style={{ backgroundColor: "lightgray" }}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        className="p-2"
                                    >
                                        Total
                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className={`p-2`}>
                                        {total4}
                                    </TableCell>
                                    <TableCell align="center" className={`p-2`}>
                                        {total2}
                                    </TableCell>
                                    <TableCell align="center" className={`p-2`}>
                                        {total3}
                                    </TableCell>
                                    <TableCell align="center" className={`p-2`}>
                                        {total1}
                                    </TableCell>

                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Stack spacing={2} className="mt-3">
                            <Pagination
                                count={pages}
                                page={page}
                                onChange={handleChange}
                            />
                        </Stack>
                    </TableContainer>
                )
            }

        </div>
    )
}

export default CompanyFileVerification