import React, { useState, useEffect, useContext } from "react";
import MainChart from "../../../componants/comman/Chart/MainChart";
import MainSubChart from "../../../componants/comman/Chart/MainSubChart";
import DownloadChart from "../../../componants/comman/Chart/DownloadChart";
import Filter from "../Filter";
import { AuthContext } from "../../../componants/Context/AuthContext";
import FacebookCircularProgress from "../../../componants/comman/FacebookCircularProgress";

const numToMonth = (num) => {
  switch (num) {
    case 1:
      return "Jan";

    case 2:
      return "Feb";

    case 3:
      return "Mar";

    case 4:
      return "Apr";

    case 5:
      return "May";

    case 6:
      return "Jun";

    case 7:
      return "Jul";

    case 8:
      return "Aug";

    case 9:
      return "Sep";

    case 10:
      return "Oct";

    case 11:
      return "Nov";

    case 12:
      return "Dec";
  }
};

const DashBoard = () => {
  const { dashboardData, year, setYear, isDashboardLoading } =
    useContext(AuthContext);
  const [filterToggle, setfilterToggle] = useState(true);
  const [salesTime, setSalesTime] = useState([]);
  const [usersTime, setUsersTime] = useState([]);

  function getMonthName(monthNumber) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr',
      'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'
    ];
  
    return months[monthNumber - 1];
  }
  function getLast12Months() {
    const months = [];
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const year = currentDate.getFullYear();
      const month = getMonthName(currentDate.getMonth() + 1);      
      months.push(`${String(month).padStart(2, '0')}-${year.toString().slice(-2)}`);
      currentDate.setMonth(currentDate.getMonth() - 1);
    }
    return months
  }


  const handleChangeYear = () => {
    const salesTimeData = dashboardData?.salesArray
      ?.map((item, i, arr) => {
        return {
          month_name: getLast12Months()[i],
          month_num: arr.length - i + 1,
          TotalSales: item.earning,
          SubscriptionSales: item.subscription,
          CreditsSales: item.extra_credit,
        };
      });
    const usersTimeData = dashboardData?.usersArray
      ?.map((item, i, arr) => {
        return {
          month_name: getLast12Months()[i],
          month_num: arr.length - i + 1,
          FreeUsers: item.free,
          PremiumUsers: item.prem,
          TotalUsers: item.total,
        };
      });
    setUsersTime(usersTimeData);
    setSalesTime(salesTimeData);
  };

  useEffect(() => {
    handleChangeYear();
  }, [year,dashboardData]);

  const handleClickFilter = () => {
    return setfilterToggle(!filterToggle);
  };
  return (
    // <Filter hideShow={filterToggle} hideShowClick={handleClickFilter}>
    <>
      {isDashboardLoading ? (
        <div className="d-flex justify-content-between flex-row">
          <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
            <FacebookCircularProgress />
          </div>
        </div>
      ) : (
        <div className="p-3 mt-3">
          <MainChart usersTime={usersTime} />
          <div className="w-100 d-flex flex-row">
            <div className="w-50 p-5">
              <MainSubChart salesTime={salesTime} />
            </div>
            <div className="w-50 p-5">
              <DownloadChart usersTime={usersTime} />
            </div>
          </div>
        </div>
      )}
    </>
    // </Filter>
  );
};
export default DashBoard;
