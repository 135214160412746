import React, { useContext, useEffect, useState } from "react";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Button } from "@mui/material";
import Tippy from "@tippyjs/react";
import PopUpModel from "./PopUpModel";
import AddCustomPackage from "../../pages/ContactSerch/AuthModule/AddCustomPackage";
import moment from "moment";
import {
  CHANGE_COMPANY_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTION_PLAN,
  GET_COMPANY_SUBSCRIPTION_HISTORY,
  GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN,
} from "../Context/Types";
import { AuthContext } from "../Context/AuthContext";
import { CircularProgress } from "@mui/material";
import FacebookCircularProgress from "./FacebookCircularProgress";

const SubscriptionTableCompanyView = (props) => {
  const { dispatch } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addPackageModal, setAddPackageModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const [toggleHistoryFlag, setToggleHistoryFlag] = useState(false);
  const [currentSubInfo, setCurrentSubInfo] = useState();

  const getAllSubscriptions = () => {
    dispatch({
      type: GET_ALL_SUBSCRIPTION_PLAN,
      id: props.selectedCompanyByAdmin,
      flag: "",
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };
  const getSubInfo = () => {
    dispatch({
      type: GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN,
      id: props.selectedCompanyByAdmin,
      updateState: setCurrentSubInfo,
      setIsLoading: () => {},
    });
  };

  useEffect(() => {
    setSelectedOption(
      selectedItem?.features?.map((item) => {
        return {
          value: item._id,
          label: item.description,
        };
      })
    );
  }, [selectedItem]);

  useEffect(() => {
    getAllSubscriptions();
    getSubInfo();
  }, []);

  const handleDelete = (id) => {
    dispatch({
      type: DELETE_SUBSCRIPTION,
      payload: id,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };
  const getSubscriptionHistory = () => {
    dispatch({
      type: GET_COMPANY_SUBSCRIPTION_HISTORY,
      id: props.selectedCompanyByAdmin,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };
  const handleChangeSubscription = (id, type) => {
    dispatch({
      type: CHANGE_COMPANY_SUBSCRIPTION,
      id: props.selectedCompanyByAdmin,
      sub_id: id,
      isAnnual: type,
    });
  };
  return (
    <div>
      <div>
        <h4 className="pr-3  d-flex justify-content-between">
          <div className="p-2 profile-header">Subscription</div>
          <Button
            className="mt-2"
            variant="contained"
            onClick={() => {
              if (!toggleHistoryFlag) {
                getSubscriptionHistory();
              }
              if (toggleHistoryFlag) {
                getAllSubscriptions();
                getSubInfo();
              }
              setToggleHistoryFlag((prev) => !prev);
            }}
          >
            {toggleHistoryFlag ? "Back" : "Subscription History"}
          </Button>
          <Button
            className="mt-2"
            variant="contained"
            onClick={() => {
              setAddPackageModal(true);
            }}
          >
            Add Customize Package
          </Button>
        </h4>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-between flex-row">
          <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
            <FacebookCircularProgress />
          </div>
        </div>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {toggleHistoryFlag ? (
              <>
                <TableHead>
                  <TableRow className="bg-light">
                    <TableCell align="left">Package Name</TableCell>
                    <TableCell align="center">Created Date</TableCell>
                    <TableCell align="center">End Date</TableCell>
                    <TableCell align="center">Credits</TableCell>
                    <TableCell align="center">Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length ? (
                    rows.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          className="p-2"
                        >
                          {row.subscription_type}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {moment(row.subscription_startedAt).format(
                            "MMMM Do YYYY"
                          )}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {moment(row.subscription_endedAt).format(
                            "MMMM Do YYYY"
                          )}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {row?.subscription_credits || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {row.subscription_validity} Days
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <div>No Records Found</div>
                  )}
                </TableBody>
              </>
            ) : (
              <>
                <TableHead>
                  <TableRow className="bg-light">
                    <TableCell align="left">Package Type</TableCell>
                    <TableCell align="left">Package Name</TableCell>
                    <TableCell align="center">Created Date</TableCell>
                    <TableCell align="center">Updated Date</TableCell>
                    <TableCell align="center">Team Members</TableCell>
                    <TableCell align="center">Monthly Price</TableCell>
                    <TableCell align="center">Monthly Credits</TableCell>
                    <TableCell align="center">Annual Price</TableCell>
                    <TableCell align="center">Annual Credits</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <CircularProgress />
                  ) : rows.length ? (
                    rows.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          background: `${
                            currentSubInfo?.plan?.subscription_type ==
                              row.title &&
                            currentSubInfo?.plan?.isCancelled === false &&
                            "#FFFF00"
                          }`,
                        }}
                      >
                        <TableCell align="left" className="p-2">
                          {row.type}
                        </TableCell>
                        <TableCell align="left" className="p-2">
                          {row.title}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {row.createdAt}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {row.updatedAt}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {row.no_of_user}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          $ {row.monthly_amount}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {row.monthly_credits}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          $ {row.annually_amount}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          {row.annually_credits}
                        </TableCell>
                        <TableCell align="center" className="p-2">
                          <Tippy
                            content={
                              <div className="TippyAction bg-white">
                                {/*  <div
                                   className=" p-2 pointer hover-dropdown"
                                   onClick={() => {
                                     setAddPackageModal(true);
                                     setSelectedItem(row);
                                   }}
                                 >
                                   Edit
                                 </div> */}
                                {currentSubInfo?.plan?.subscription_type ===
                                  row.title &&
                                row.type === "COMMON" &&
                                currentSubInfo?.plan?.isCancelled === false ? (
                                  <div className=" p-2 hover-dropdown">
                                    {"No Options"}
                                  </div>
                                ) : null}
                                {row.type !== "COMMON" ? (
                                  <div
                                    className=" p-2 pointer hover-dropdown"
                                    onClick={() => handleDelete(row._id)}
                                  >
                                    Disable
                                  </div>
                                ) : null}
                                {currentSubInfo?.plan?.subscription_type !==
                                  row.title ||
                                currentSubInfo?.plan?.isCancelled === true ? (
                                  <div
                                    className=" p-2 pointer hover-dropdown"
                                    onClick={() =>
                                      handleChangeSubscription(row._id, false)
                                    }
                                  >
                                    {"Assign Subscription(Monthly)"}
                                  </div>
                                ) : (
                                  ""
                                )}
                                {currentSubInfo?.plan?.subscription_type !==
                                  row.title ||
                                currentSubInfo?.plan?.isCancelled === true ? (
                                  <div
                                    className=" p-2 pointer hover-dropdown"
                                    onClick={() =>
                                      handleChangeSubscription(row._id, true)
                                    }
                                  >
                                    {"Assign Subscription(Annually)"}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            }
                            placement="bottom-end"
                            arrow={false}
                            offset={[15, 1]}
                            trigger="focusin"
                            interactive={true}

                            // hideOnClick={true}
                          >
                            <MoreVertIcon className="pointer"></MoreVertIcon>
                          </Tippy>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <div>No Records Found</div>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      )}
      <PopUpModel
        open={addPackageModal}
        title={"Customize Package"}
        close={() => {
          setAddPackageModal(false);
        }}
        bodyClass={"AddCompany"}
      >
        <AddCustomPackage
          selectedCompanyByAdmin={props.selectedCompanyByAdmin}
          selectedItem={selectedItem}
          selectedOption={selectedOption}
          setmodel={setAddPackageModal}
          setSelectedItem={setSelectedItem}
          setSelectedOption={setSelectedOption}
          setRows={setRows}
        />
      </PopUpModel>
    </div>
  );
};

export default SubscriptionTableCompanyView;
