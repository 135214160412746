/** @format */

import React, { useState, useEffect, useContext } from 'react';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Button, Menu } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
// import { Tooltip } from "antd";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Download from '@mui/icons-material/Download';
import Pagination from '@mui/material/Pagination';
import Tippy from '@tippyjs/react';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../Context/AuthContext';
import TableSkeltonLoader from './TableSkeltonLoader/TableSkeltonLoader';
import DynamicTable from '../../Elements/Misc/DTable';
import PaginationRow from '../../Elements/Misc/PaginationRow';
import {
	GET_COMPANY_TRANSACTIONS_ADMIN,
	GET_TRANSACTIONS,
} from '../Context/Types';
import Tooltip from '@mui/material/Tooltip';

const Transactions = (props) => {
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const { dispatch } = useContext(AuthContext);
	const [showFilter, setshowFilter] = useState(false);
	const [allData, setallData] = useState([]);
	const columns = [
		{ title: 'Transaction ID' },
		{ title: 'Name' },
		{ title: 'Transaction Date' },
		{ title: 'Amount' },
		{ title: 'Card Used' },
		{ title: 'Status' },
	];

	useEffect(() => {
		if (props.selectedCompanyByAdmin) {
			dispatch({
				type: GET_COMPANY_TRANSACTIONS_ADMIN,
				id: props.selectedCompanyByAdmin,
				updateState: setRows,
				setallData: setallData,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_TRANSACTIONS,
				updateState: setRows,
				setallData: setallData,
				setIsLoading: setIsLoading,
			});
		}
	}, []);

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'Transaction ID') {
			return row?.txnId;
		} else if (col.title === 'Status') {
			return row?.subscription_amount_status === true ? (
				<div>
					<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-teal-200 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500'>
						<svg
							class='shrink-0 size-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'></path>
							<path d='m9 12 2 2 4-4'></path>
						</svg>
						Successful
					</span>
				</div>
			) : (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-red-200 text-red-700 rounded-full dark:bg-red-500/10 dark:text-red-500'>
						<svg
							class='shrink-0 size-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path d='m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z'></path>
							<path d='M12 9v4'></path>
							<path d='M12 17h.01'></path>
						</svg>
						Failed
					</span>
				</div>
			);
		} else if (col.title === 'Name') {
			return row?.subscription_type;
		} else if (col.title === 'Transaction Date') {
			return row?.createdAt;
		} else if (col.title === 'Amount') {
			return `$${row?.subscription_amount}`;
		} else if (col.title === 'Card Used') {
			return row?.card_info;
		}

		return row[col.title];
	};

	return (
		<div>
			{isLoading ? (
				<div
					className='relative'
					style={{ overflow: 'hidden', marginTop: '1rem' }}
				>
					<TableSkeltonLoader countRow={8} />
				</div>
			) : (
				<>
					<DynamicTable
						columns={columns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				</>
			)}
			{!isLoading && (
				<PaginationRow
					totalResults={rows?.length}
					page={page}
					setPage={setPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
				/>
			)}
		</div>
	);
};

export default Transactions;
