import React, { useContext, useEffect, useState } from "react";
import SignIn_img from "../../../../assests/image/SignIn.jpg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import { GET_MEMBER, TEAM_SIGNUP } from "../../../../componants/Context/Types";
import { AuthContext } from "../../../../componants/Context/AuthContext";

const label = {
  inputProps: { "aria-label": "Checkbox demo" },
  name: "acceptTerms",
};

const TeamSignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const id = new URLSearchParams(location.search).get("invite_id");

  const [memberId, setMemberId] = useState(id);
  const [memberDetails, setMemberDetails] = useState({
    name: "",
    email: "",
    password: "",
    companyName: "",
    acceptTerms: false,
  });

  useEffect(() => {
    dispatch({
      type: GET_MEMBER,
      payload: memberId,
      upDateState: setMemberDetails,
      setIsMember: setIsMember,
    });
  }, [memberId]);
  const input = [
    {
      label: "Name",
      name: "name",
      type: "text",
    },
    {
      label: "email",
      name: "email",
      type: "email",
    },
    {
      label: "password",
      name: "password",
      type: "password",
    },
    {
      label: "Company Name",
      name: "companyName",
      type: "text",
    },
  ];

  const initialValues = memberDetails;

  const validate = Yup.object().shape({
    name: Yup.string().required("This is required"),
    // email: Yup.string()
    //   .email("This is invalid email")
    //   .required("This is required")
    //   .matches(/^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/,"Must Be a Official Company Email"),
    password: Yup.string()
    .required("Please Enter your password")
    .test(
      "len",
      "Must atleast 8 Characters",
      (val) => val?.toString().length >= 8
    ),
    companyName: Yup.string().required("This is required"),
    acceptTerms: Yup.bool().oneOf([true], "This is required"),
  });

  const handleSubmit = (values, resetForm) => {
    delete values.name;
    delete values.email;
    delete values.companyName;
    delete values.acceptTerms;
    values.invite_id = memberId;
    dispatch({
      type: TEAM_SIGNUP,
      payload: values,
      resetForm: resetForm,
      setIsLoading: setIsLoading,
    });
  };

  return isMember ? (
    <div className="login-page">
      <div className="img-content">
        <img src={SignIn_img} className="login-img" />
      </div>
      <div className="sighnUp-content">
        <h2 className="mb-0 mt-2">Team Sign Up</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
            // alert("Form is validated! Submitting the form...");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
          }) => (
            <Form>
              <div className="login-input">
                {input.map((item, index) => {
                  return (
                    <>
                      <TextField
                        id="outlined-basic"
                        name={item.name}
                        label={item.label}
                        variant="outlined"
                        type={item.type}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        value={values[`${item.name}`]}
                        disabled={item.name !== "password"}
                        // margin="normal"
                        className="w-75 mb-3 mt-3"
                      />
                      {/* error validated by Yup */}
                      <div className="error-container">
                        <div className="error">
                          {errors[`${item.name}`] &&
                            touched[`${item.name}`] &&
                            errors[`${item.name}`]}
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="mb-1 d-flex align-items-center justify-content-start w-75">
                  <div>
                    <Checkbox
                      {...label}
                      onChange={handleChange}
                      className="pl-0"
                    />{" "}
                    I agree to the{" "}
                    <a href="" className="login-link">
                      term and conditions
                    </a>{" "}
                    <div className="error-container">
                      <div className="error">
                        {errors["acceptTerms"] &&
                          touched["acceptTerms"] &&
                          errors["acceptTerms"]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button className="login-btn mb-1" type="submit">
                  {isLoading ? (
                    <CircularProgress sx={{ fontSize: 10, color: "white" }} />
                  ) : (
                    "Signup"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div>
          Already Have an Account?{" "}
          <span
            className="login-link"
            onClick={() => {
              navigate("/");
            }}
          >
            Sign in here...
          </span>
        </div>
      </div>
    </div>
  ) : (
    <h1>Loading...</h1>
  );
};
export default TeamSignUp;
