import React, { useContext, useEffect, useState } from "react";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Button } from "@mui/material";
import Tippy from "@tippyjs/react";
import PopUpModel from "./PopUpModel";
import AddPackage from "../../pages/ContactSerch/AuthModule/AddPackage";
import {
  DELETE_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTION_PLAN,
  HOME_PAGE_SHOW,
} from "../Context/Types";
import { AuthContext } from "../Context/AuthContext";
import { CircularProgress } from "@mui/material";
import FacebookCircularProgress from "./FacebookCircularProgress";

const SubscripttionTable = () => {
  const { dispatch } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [model, setmodel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    dispatch({
      type: GET_ALL_SUBSCRIPTION_PLAN,
      id: "",
      flag: "COMMON",
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  }, []);

  const handleDelete = (id) => {
    dispatch({
      type: DELETE_SUBSCRIPTION,
      payload: id,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };
  const handleHomePageShow = (row, status) => {
    dispatch({
      type: HOME_PAGE_SHOW,
      payload: row,
      status: status,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };

  return (
    <div>
      <div>
        <h4 className="pr-3  d-flex justify-content-between">
          <div className="p-2 profile-header">Subscription</div>
          <Button
            className="mt-2"
            variant="contained"
            onClick={() => {
              setmodel(true);
            }}
          >
            Add Package
          </Button>
        </h4>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-between flex-row">
          <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
            <FacebookCircularProgress />
          </div>
        </div>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="bg-light">
                <TableCell align="left">Package Name</TableCell>
                <TableCell align="center">Created Date</TableCell>
                <TableCell align="center">Updated Date</TableCell>
                <TableCell align="center">Team Members</TableCell>
                <TableCell align="center">Monthly Price</TableCell>
                <TableCell align="center">Monthly Credits</TableCell>
                <TableCell align="center">Annual Price</TableCell>
                <TableCell align="center">Annual Credits</TableCell>
                <TableCell align="center">Action</TableCell>
                <TableCell align="center">Show On Website</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      className="p-2"
                    >
                      {row.title}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      {row.createdAt}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      {row.updatedAt}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      {row.no_of_user}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      $ {row.monthly_amount}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      {row.monthly_credits}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      $ {row.annually_amount}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      {row.annually_credits}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      <Tippy
                        content={
                          <div className="TippyAction bg-white  ">
                            <div
                              className=" p-2 pointer hover-dropdown"
                              onClick={() => {
                                setmodel(true);
                                setSelectedItem(row._id);
                              }}
                            >
                              Edit
                            </div>
                            <div
                              className=" p-2 pointer hover-dropdown"
                              onClick={() => handleDelete(row._id)}
                            >
                              Disable
                            </div>
                          </div>
                        }
                        placement="bottom-end"
                        arrow={false}
                        offset={[15, 1]}
                        trigger="focusin"
                        interactive={true}

                        // hideOnClick={true}
                      >
                        <MoreVertIcon className="pointer"></MoreVertIcon>
                      </Tippy>
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      <div className="d-flex justify-content-between">
                        {/* <input
                          type="radio"
                          disabled={row.homepage}
                          onClick={() => handleHomePageShow(row, true)}
                          defaultChecked={row.homepage}
                        />
                        Yes
                        <input
                          type="radio"
                          disabled={!row.homepage}
                          onClick={() => handleHomePageShow(row, false)}
                          defaultChecked={!row.homepage}
                        />
                        No */}
                        <select
                          onChange={(e) =>
                            handleHomePageShow(row, e.target.value)
                          }
                        >
                          <option
                            selected={row.homepage}
                            disabled={row.homepage}
                            value={true}
                          >
                            Yes
                          </option>
                          <option
                            selected={!row.homepage}
                            disabled={!row.homepage}
                            value={false}
                          >
                            No
                          </option>
                        </select>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <div>No Records Found</div>
              )}
            </TableBody>
            <PopUpModel
              open={model}
              title={"Add Package"}
              close={() => {
                setmodel(false);
              }}
              bodyClass={"AddCompany"}
            >
              <AddPackage
                close={() => {
                  setmodel(false);
                }}
                id={selectedItem}
                setId={setSelectedItem}
                setmodel={setmodel}
                updateState={setRows}
                setRowLoading={setIsLoading}
              />
            </PopUpModel>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default SubscripttionTable;
