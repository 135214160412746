import React, { useContext, useEffect, useState } from "react";
import PopUpModel from "../../../../componants/comman/PopUpModel";
import TextField from "@mui/material/TextField";
import { Alert, Button, CircularProgress } from "@mui/material";
import { Checkbox } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import {
  GET_COMPANY_CREDITS_ADMIN,
  INVITE_COMPANY_MEMBER_ADMIN,
  INVITE_TEAM_MEMBER,
} from "../../../../componants/Context/Types";
import { NotificationManager } from "react-notifications";

const paymentGateway = [
  // { value: "PAYPAL", label: "PayPal" },
  { value: "STRIPE", label: "Stripe" },
];

const Addteam = (props) => {
  const { dispatch, credits, profile } = useContext(AuthContext);
  const [selectedCompanyCredits, setSelectedCompanyCredits] = useState();
  const [selectedGateway, setSelectedGateway] = useState("STRIPE");

  const [isLoading, setIsLoading] = useState({
    value: false,
    message: "",
  });
  const initialValues = {
    name: "",
    email: "",
    credits: "",
  };
  const validate = Yup.object().shape({
    name: Yup.string().required("This is required"),
    email: Yup.string()
      .email("This is invalid email")
      .required("This is required"),
    // .matches(
    //   /^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/,
    //   "Must Be a Official Company Email"
    // ),
    credits: Yup.number()
      .typeError("Must be a number")
      .required("This is required")
      .max(
        props.selectedCompanyByAdmin ? selectedCompanyCredits : credits,
        "Not Enough Credits"
      )
      .min(1, "Atleast 1 credit should be assign"),
  });
  useEffect(() => {
    if (props.selectedCompanyByAdmin) {
      dispatch({
        type: GET_COMPANY_CREDITS_ADMIN,
        id: props.selectedCompanyByAdmin,
        updateState: setSelectedCompanyCredits,
      });
    }
  }, [props.selectedCompanyByAdmin]);
  const handleSubmit = (values, resetForm) => {
    if (props.selectedCompanyByAdmin) {
      dispatch({
        type: INVITE_COMPANY_MEMBER_ADMIN,
        id: props.selectedCompanyByAdmin,
        updateState: props.setRows,
        values: values,
        resetForm: resetForm,
        setIsLoading: setIsLoading,
      });
    } else {
      if (!selectedGateway) {
        //("Choose Payment Gateway!");
        return;
      }
      dispatch({
        type: INVITE_TEAM_MEMBER,
        payload: { ...values, payment_gateway: selectedGateway },
        updateState: props.setRows,
        resetForm: resetForm,
        setIsLoading: setIsLoading,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validate}
      onSubmit={(values, { resetForm }) => {
        //   console.log(values);
        handleSubmit(values, resetForm);
        // alert("Form is validated! Submitting the form...");
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
      }) => (
        <PopUpModel
          open={props.open}
          close={() => {
            props.close();
          }}
          title={"Add Team Member"}
        >
          <Alert
            severity={isLoading?.severity}
            onClose={() => {
              setIsLoading(false);
            }}
            className={`invite-member-alert ${
              !isLoading?.severity ? "hidden" : ""
            }`}
          >
            {isLoading?.message}
          </Alert>
          <Form>
            <div className="mb-2 blue_text">
              {props.selectedCompanyByAdmin
                ? null
                : `$${profile?.plan?.cost_per_user} per Member`}
            </div>
            <div className="w-100 d-flex flex-column addteam-wrapper">
              <div>
                <TextField
                  id="outlined-basic"
                  size="small"
                  className="user-input-fild w-100"
                  label="Enter Name"
                  variant="outlined"
                  name="name"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-container">
                  <div className="error">
                    {errors.name && touched.name && errors.name}
                  </div>
                </div>
              </div>
              <div>
                <TextField
                  id="outlined-basic"
                  size="small"
                  className="user-input-fild w-100"
                  label="Enter Email"
                  variant="outlined"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-container">
                  <div className="error">
                    {errors.email && touched.email && errors.email}
                  </div>
                </div>
              </div>
              <div>
                <TextField
                  id="outlined-basic"
                  size="small"
                  className="user-input-fild w-100"
                  label="No. of Credits"
                  variant="outlined"
                  name="credits"
                  value={values.credits}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="error-container">
                  <div className="error">
                    {errors.credits && touched.credits && errors.credits}
                  </div>
                </div>
                <h6 className="pl-2 m-1 credit-color">
                  {props.selectedCompanyByAdmin
                    ? selectedCompanyCredits
                    : credits}{" "}
                  credits remainings.
                </h6>
              </div>
              {props.selectedCompanyByAdmin ? null : (
                <div className="blue_text mb-2">
                  <small>Payment Gateway:</small>
                  <div>
                    {paymentGateway.map((item) => {
                      return (
                        <span key={item.value}>
                          <Checkbox
                            key={item.value}
                            onChange={(e) => {
                              setSelectedGateway(e.target.value);
                            }}
                            checked={item.value === selectedGateway}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="m-0 d-flex justify-content-start">
              <Button variant="contained" className="w-100 p-2" type="submit">
                {isLoading.value ? (
                  <CircularProgress
                    sx={{ fontSize: "0.2rem", color: "white" }}
                  />
                ) : (
                  "Invite"
                )}
              </Button>
            </div>
          </Form>
        </PopUpModel>
      )}
    </Formik>
  );
};
export default Addteam;
