import React, { useState } from "react";
import { Button } from "@mui/material";
import UserInfoDetails from "./UserInfoDetail";
import PopUpModel from "../../../../componants/comman/PopUpModel";
import EditUser from "./EditUser";
import AssignCreditsToCompanyModel from "../../../../componants/comman/AssignCreditsToCompanyModel";
import AssignUsersToCompanyModel from "../../../../componants/comman/AssignUsersToCompanyModel";
import ExtendFreeTrialModel from "../../../../componants/comman/ExtendFreeTrialModel";

const UserInfo = (props) => {
  const [model, setmodel] = useState(false);
  const [info, setInfo] = useState();
  const [assignCreditModal, setAssignCreditModal] = useState(false);
  const [assignUserModal, setAssignUserModal] = useState(false);
  const [extendFreeTrialModal, setExtendFreeTrialModal] = useState(false);
  return (
    <div className="">
      <div className="btn-class">
        <h4 className="pr-3 d-flex justify-content-between">
          <div className="p-2 profile-header f-14">User Info</div>
          <Button
            variant={"contained"}
            className="mt-2 button-custom"
            onClick={() => setmodel(true)}
          >
            Edit User
          </Button>
          {/* <Button
            variant={"contained"}
            className="mt-2 button-custom"
            onClick={() => setAssignUserModal(true)}
          >
            Assign Users
          </Button> */}
          {info?.plan?.subscription_type === "Free Trial" ? (
            <Button
              variant={"contained"}
              className="mt-2 button-custom"
              onClick={() => setExtendFreeTrialModal(true)}
            >
              Extend Free Trial
            </Button>
          ) : null}
          <Button
            variant={"contained"}
            className="mt-2 button-custom"
            onClick={() => setAssignCreditModal(true)}
          >
            Assign Credits
          </Button>
        </h4>
      </div>
      <UserInfoDetails
        setInfo={setInfo}
        info={info}
        selectedCompanyByAdmin={props.selectedCompanyByAdmin}
      />

      <PopUpModel
        open={model}
        title={"Edit Detail"}
        close={() => {
          setmodel(false);
        }}
        bodyClass={"AddCompany"}
      >
        <EditUser
          selectedCompanyByAdmin={props.selectedCompanyByAdmin}
          setmodel={setmodel}
        />
      </PopUpModel>

      <AssignCreditsToCompanyModel
        close={() => {
          setAssignCreditModal(false);
        }}
        open={assignCreditModal}
        title={"Assign credits"}
        selectedCompanyByAdmin={props.selectedCompanyByAdmin}
      />

      <ExtendFreeTrialModel
        open={extendFreeTrialModal}
        title={"Extend Free Trial"}
        selectedCompanyByAdmin={props.selectedCompanyByAdmin}
        info={info}
        updateState={setInfo}
        close={() => {
          setExtendFreeTrialModal(false);
        }}
      />

      {/* <AssignUsersToCompanyModel
        open={assignUserModal}
        title={"Assign users"}
        selectedCompanyByAdmin={props.selectedCompanyByAdmin}
        close={() => {
          setAssignUserModal(false);
        }}
      /> */}
    </div>
  );
};

export default UserInfo;
