import React, { useEffect, useState } from 'react';
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { getCompanyBounceApi } from '../../componants/Context/APIs';
import FacebookCircularProgress from '../../componants/comman/FacebookCircularProgress';
import { Pagination, Stack } from '@mui/material';


const CompanyBounceRepository = ({selectedCompanyByAdmin}) => {

    const [loading, setLoading] =  useState(false);
    const [data, setData] = useState([]);
    const [total1, setTotal1] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);

    const getBounceRepository = async() => {
        setLoading(true);
        try {
            const res  = await getCompanyBounceApi(selectedCompanyByAdmin, page);
            setData(res?.data?.data[0] || []);
            setTotal1(res?.data?.totalCount[0][0]?.TotalCount || 0);
            setPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            
        }
    }

    const handleChange = (e, value) => {
        setPage(value)
    }
    useEffect(() => {
        if(selectedCompanyByAdmin) {
            getBounceRepository();
        }
    }, [selectedCompanyByAdmin, page])
  return (
    <div>
        {
            loading ? (
                <div className="d-flex justify-content-between flex-row">
                <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
                  <FacebookCircularProgress />
                </div>
              </div>
            ) : (
                <TableContainer style={{ overflowY: "auto", maxHeight: "60vh" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead style={{ position: "sticky", top: "0" }}>
                        <TableRow className="bg-light">
                            <TableCell className="fw-bold" align="center">
                                File Name
                            </TableCell>
                            <TableCell className="fw-bold" align="center">
                                Uploaded By
                            </TableCell>
                            <TableCell align="center" className="fw-bold">
                                Creation Date
                            </TableCell>
                            <TableCell align="center" className="fw-bold">
                                Count
                            </TableCell>
                            <TableCell align="center" className="fw-bold">
                                Status
                            </TableCell>
                            {/* <TableCell align="center" className="fw-bold">
                                Valid Only
                            </TableCell>
                            <TableCell align="center" className="fw-bold">
                                Catch-All
                            </TableCell>
                            <TableCell align="center" className="fw-bold">
                                Invalid
                            </TableCell> */}
                            {/* <TableCell align="center" className="fw-bold">
                                Action
                            </TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data && data.length > 0 && data.map((row) => {
                                return (
                                    <TableRow
                                        key={row.id}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            className="p-2"
                                        >
                                            {row.filename}
                                        </TableCell>
                                        <TableCell align="center" className="p-2">
                                            {row.uploadby}
                                        </TableCell>
                                        <TableCell align="center" className="p-2">
                                            {new Date(row.created_at).toUTCString()}
                                        </TableCell>
                                        <TableCell align="center" className={`p-2`}>
                                            {row?.RepeatCount}
                                        </TableCell>
                                        <TableCell align="center" className={`p-2`}>
                                            {row?.status || "-"}
                                        </TableCell>
                                        {/* <TableCell align="center" className={`p-2`}>
                                            {row?.valid}
                                        </TableCell>
                                        <TableCell align="center" className={`p-2`}>
                                            {row?.catchAll}
                                        </TableCell>
                                        <TableCell align="center" className={`p-2`}>
                                            {row?.invalid}
                                        </TableCell> */}
                                        
                                        {/* <TableCell align="center" className="p-2 dropdown-team ">
                                            <Tippy
                                                content={
                                                        <div className="TippyAction bg-light pointer">
                                                            <div
                                                                className=" p-2 pointer hover-dropdown"
                                                            >
                                                                Valid Only
                                                            </div>
                                                            <div
                                                                className=" p-2 pointer hover-dropdown"
                                                            >
                                                                Catch-All
                                                            </div>
                                                            <div
                                                                className=" p-2 pointer hover-dropdown"
                                                            >
                                                                Invalid
                                                            </div>
                                                            <div
                                                                className=" p-2 pointer hover-dropdown"
                                                            >
                                                                All
                                                            </div>
                                                        </div>
                                                }
                                                placement="bottom-end"
                                                arrow={false}
                                                offset={[15, 1]}
                                                trigger="mouseenter"
                                                interactive={true}
                                            >
                                                <MoreVertIcon></MoreVertIcon>
                                            </Tippy>
                                        </TableCell> */}
                                    </TableRow>
                                );
                            })}

<TableRow
                                    style={{ backgroundColor: "lightgray" }}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        className="p-2"
                                    >
                                        Total
                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className={`p-2`}>
                                        {total1}
                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                </TableRow>
                    </TableBody>
                </Table>
                <Stack spacing={2} className="mt-3">
                            <Pagination
                                count={pages}
                                page={page}
                                onChange={handleChange}
                            />
                        </Stack>
            </TableContainer>
            )
        }

</div>
  )
}

export default CompanyBounceRepository