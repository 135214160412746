/** @format */

import React, { useContext } from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import UnderContruction from './comman/UnderContruction';
import AuthContext from './Context/AuthContext';
import ProtectedRoutes from './Context/ProtectRoutes';
import Congratulations from '../pages/ContactSerch/AuthModule/Congratulations';
import ContactSearchManagement from '../pages/ContactSerch/ContactSearch/ContactSearch';
import ProfileManagement from '../pages/ContactSerch/ProfileTab/ProfileIndex';
import MyList from '../pages/ContactSerch/MyList/MyList';
import PasswordChange from '../pages/ContactSerch/AuthModule/PasswordChange';
import VerifyEmailPage from '../pages/ContactSerch/AuthModule/VerifyEmailPage';
import Company from '../pages/ContactSerch/Admin/Companies/Company';
import FlageButton from '../componants/comman/FlageButton';
import Categories from '../pages/ContactSerch/Admin/Companies/Categories';
import Leads from '../pages/ContactSerch/Admin/Companies/Leads';
import ResetPassword from '../pages/ContactSerch/AuthModule/ResetPassword';
import PlansScreen from '../screens/Plans/Plans';
import SignInPage from '../pages/ContactSerch/AuthModule/SignInPage.';
import SignUpPage from '../pages/ContactSerch/AuthModule/SignUpPage';
import TeamSignUp from '../pages/ContactSerch/AuthModule/TeamMember/TeamSignUp';
import Layout from '../helper/Layout';
import useAuth from '../hooks/useAuth';
import DashBoard from '../pages/ContactSerch/DashBoard/DashBoard';
import HeaderFilterTopBarWrapper from '../helper/MainWrapper';
import Thankyoupage from '../pages/ContactSerch/AuthModule/ThankYouPage';
import Failed from '../pages/ContactSerch/AuthModule/Failed';
import Home from '../pages/ContactSerch/DashBoard/Home';
import MyLists from '../screens/Lists/MyLists';
import Notifications from '../pages/ContactSerch/Miscellaneous/Notifications';
import FilterContext from './filterContext/FilterContext';
import TermsConditions from '../pages/ContactSerch/AuthModule/TermsConditions';
import ContactSearch from '../screens/ContactSearch/ContactSearch';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import NotFound from '../pages/ContactSerch/NotFound/NotFound';
import { NProvider } from '../Elements/Navbar/NavbarContext';
import BillingScreen from '../screens/Billing/Billing';

// const ROLES={ADMIN:"admin",COMPANY:"company",MEMBER:"user",SUB_ADMIN:"subadmin"};
// BACKEND:FRONTEND

const UserNavigate = () => {
	const [searchParams] = useSearchParams();
	const planId = searchParams.get('planId') || '';
	const type = searchParams.get('type') || '';
	const token = Cookies.get('token');
	useEffect(() => {
		if (type && planId && token?.length > 20) {
			window.location =
				'/thankyou?planId=' +
				planId +
				`&isAnnual=${type === 'year' ? 'true' : 'false'}` +
				'&token=' +
				token;
		}
	}, []);
	return <></>;
};

const DocDBI = () => {
	const { auth } = useAuth();

	React.useEffect(() => {
		const handleContextmenu = (e) => {
			e.preventDefault();
		};
		document.addEventListener('contextmenu', handleContextmenu);
		return function cleanup() {
			document.removeEventListener('contextmenu', handleContextmenu);
		};
	}, []);

	return (
		<>
			<BrowserRouter>
				<UserNavigate />
				<FilterContext>
					<NProvider>
						<AuthContext>
							<Routes>
								<Route path='/' element={<Layout />}>
									{/* Public Routes */}
									<Route
										path='/'
										element={
											auth ? <Navigate to='/contactsearch' /> : <SignInPage />
										}
									/>
									<Route
										path='/signUp'
										element={
											auth ? <Navigate to='/contactsearch' /> : <SignUpPage />
										}
									/>
									<Route
										path='/email'
										element={
											auth ? <Navigate to='/profile' /> : <VerifyEmailPage />
										}
									/>
									<Route
										path='/forgotpassword'
										element={
											auth ? <Navigate to='/profile' /> : <PasswordChange />
										}
									/>
									<Route
										path='/terms-conditions'
										element={<TermsConditions />}
									/>
									<Route
										path='/resetpassword/:id'
										element={<ResetPassword />}
									/>
									<Route
										path='/teamSignUp'
										element={auth ? <Navigate to='/profile' /> : <TeamSignUp />}
									/>
									<Route path='/thankyou' element={<Thankyoupage />} />
									{/* Private Routes */}
									<Route element={<ProtectedRoutes />}>
										<Route
											path='/dashboard'
											element={HeaderFilterTopBarWrapper(<DashBoard />)}
										/>

										<Route
											path='/leadssearch'
											element={HeaderFilterTopBarWrapper(
												<ContactSearchManagement />
											)}
										/>
										<Route path='/contactsearch' element={<ContactSearch />} />
										<Route path='/mylist' element={<MyLists />} />
										<Route path='/billing' element={<BillingScreen />} />
										<Route path='/plans' element={<PlansScreen />} />
										<Route
											path='/notifications'
											element={HeaderFilterTopBarWrapper(<Notifications />)}
										/>
										<Route
											path='/failed'
											element={HeaderFilterTopBarWrapper(<Failed />)}
										/>
										<Route
											path='/mylists'
											element={HeaderFilterTopBarWrapper(<MyList />)}
										/>
										<Route path='/congrats' element={<Congratulations />} />
										<Route
											path='/lookup'
											element={HeaderFilterTopBarWrapper(<UnderContruction />)}
										/>
										<Route
											path='/lookup'
											element={HeaderFilterTopBarWrapper(<UnderContruction />)}
										/>
										<Route
											path='/profile'
											element={HeaderFilterTopBarWrapper(<ProfileManagement />)}
										/>
										<Route
											path='/subscription'
											element={HeaderFilterTopBarWrapper(<UnderContruction />)}
										/>
										<Route
											path='/company'
											element={HeaderFilterTopBarWrapper(<Company />)}
										/>
										<Route
											path='/flageButton'
											element={HeaderFilterTopBarWrapper(<FlageButton />)}
										/>
										<Route
											path='/categories'
											element={HeaderFilterTopBarWrapper(<Categories />)}
										/>
										<Route
											path='/leads'
											element={HeaderFilterTopBarWrapper(<Leads />)}
										/>
										<Route
											path='*'
											element={HeaderFilterTopBarWrapper(<NotFound />)}
										/>
									</Route>
								</Route>
							</Routes>
						</AuthContext>
					</NProvider>
				</FilterContext>
			</BrowserRouter>
		</>
	);
};
export default DocDBI;
