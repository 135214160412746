/** @format */

import React, { Component, useContext, useState } from 'react';
import FactoryIcon from '@mui/icons-material/Factory';
import ReplayIcon from '@mui/icons-material/Replay';
import WorkIcon from '@mui/icons-material/Work';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import BadgeIcon from '@mui/icons-material/Badge';
import LinkIcon from '@mui/icons-material/Link';
import Drawer from 'react-modern-drawer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import 'react-modern-drawer/dist/index.css';
import UnderContruction from '../../componants/comman/UnderContruction';
import { Card } from '@mui/material';
import { Tooltip } from 'react-tippy';
import IndustryTitleLocationType from './filterCompo/IndustryTitleLocationType';
import SalesAndEmployee from './filterCompo/SalesAndEmployee';
import CompanyUrl from './filterCompo/CompanyUrl';
import Technologies from './filterCompo/Technologies';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import Button from '@mui/material/Button';
import CommanPopUp from './filterCompo/CommanPopUp';
import Tippy from '@tippyjs/react';
import { FilterListOutlined } from '@mui/icons-material';
import { MainContext } from '../../componants/filterContext/FilterContext';
import MultiSelect from './filterCompo/MultiSelect';
import GenderSelect from './filterCompo/GenderSelect';
import { EXECUTIVE_FILTER, PHYSICIAN_FILTER } from './filterCompo/filterTypes';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TitleIcon from '@mui/icons-material/Title';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import WcIcon from '@mui/icons-material/Wc';
import EightteenMpIcon from '@mui/icons-material/EightteenMp';
import PinIcon from '@mui/icons-material/Pin';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ApprovalIcon from '@mui/icons-material/Approval';
import ExplicitIcon from '@mui/icons-material/Explicit';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import OutboundIcon from '@mui/icons-material/Outbound';
import EmailIcon from '@mui/icons-material/Email';
import MediationIcon from '@mui/icons-material/Mediation';
import PersonIcon from '@mui/icons-material/Person';
import MedicationIcon from '@mui/icons-material/Medication';
import LandscapeIcon from '@mui/icons-material/Landscape';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AuthContext } from '../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../componants/Context/Types';
import useAuth from '../../hooks/useAuth';
import person from '../../assests/image/business.png';

const temp = [
	{
		icon: <AddLocationIcon />,
		title: 'Location',

		model: <IndustryTitleLocationType type='location' />,
	},
	{
		icon: <WorkIcon fontSize='f-18' />,
		title: 'Speciality',
		model: <IndustryTitleLocationType type='speciality' />,
	},
];

const executiveFilter = [
	{
		icon: <TitleIcon fontSize='f-18' />,
		title: 'Title',
		displayName: 'Job Title',
		sorting: 'title',
		model: <IndustryTitleLocationType type='title' compName='Job Title' />,
	},
	{
		icon: <WorkIcon fontSize='f-18' />,
		title: 'Level ID',
		sorting: 'levelID',
		displayName: 'Management Level',
		model: (
			<IndustryTitleLocationType type='levelID' compName='Management Level' />
		),
	},
	{
		icon: <MediationIcon fontSize='f-18' />,
		title: 'Firm Type',
		sorting: 'firmType',
		displayName: 'Network',
		model: <IndustryTitleLocationType type='firmType' compName='Network' />,
	},
	{
		icon: <AddLocationIcon fontSize='f-18' />,
		title: 'Location',
		sorting: 'location',
		displayName: 'Location',
		model: <IndustryTitleLocationType type='location' compName='Location' />,
	},
	{
		icon: <MergeTypeIcon fontSize='f-18' />,
		title: 'Hospital Type',
		sorting: 'hospitalType',
		displayName: 'Facility Type',
		model: (
			<IndustryTitleLocationType type='hospitalType' compName='Facility Type' />
		),
	},

	{
		icon: <PersonIcon fontSize='f-18' />,
		title: 'Ownership',
		sorting: 'ownership',
		displayName: 'Ownership',
		model: <IndustryTitleLocationType type='ownership' compName='Ownership' />,
	},

	{
		icon: <LandscapeIcon fontSize='f-18' />,
		title: 'Geographic Classification',
		sorting: 'geographic',
		displayName: 'Geographic Classification',
		model: (
			<IndustryTitleLocationType
				type='geographicClassification'
				compName='Geographic Classification'
			/>
		),
	},
];
const physicianFilter = [
	{
		icon: <StarHalfIcon fontSize='f-18' />,
		title: 'Speciality',
		displayName: 'Speciality',
		sorting: 'specialityGroup',
		model: (
			<IndustryTitleLocationType type='speciality' compName='Speciality' />
		),
	},

	{
		icon: <PersonIcon fontSize='f-18' />,
		title: 'Speciality Type',
		displayName: 'Speciality Type',
		sorting: 'specialityType',
		displayName: 'HCP Type',
		model: (
			<IndustryTitleLocationType
				type='specialityType'
				compName='Professional Type'
			/>
		),
	},

	{
		icon: <AddLocationIcon fontSize='f-18' />,
		title: 'Location',
		displayName: 'Location',
		sorting: 'location',
		model: <IndustryTitleLocationType type='location' compName='Location' />,
	},

	// {
	//   icon: <TitleIcon fontSize="small" />,
	//   title: "Title",
	//   model: <IndustryTitleLocationType type="title" compName="Title" />,
	// },
	{
		icon: <DocumentScannerIcon fontSize='f-18' />,
		title: 'Licensed State',
		sorting: 'licenseState',
		displayName: 'Licensed State',
		model: (
			<IndustryTitleLocationType
				type='licensedState'
				compName='Licensed State'
			/>
		),
	},
	{
		icon: <ExplicitIcon fontSize='f-18' />,
		title: 'Experience',
		sorting: 'experience',
		displayName: 'Experience',
		model: (
			<IndustryTitleLocationType type='experience' compName='Experience' />
		),
	},

	{
		icon: <WcIcon fontSize='f-18' />,
		title: 'Gender',
		sorting: 'gender',
		displayName: 'Gender',
		model: <IndustryTitleLocationType type='gender' compName='Gender' />,
	},

	{
		icon: <EightteenMpIcon fontSize='f-18' />,
		title: 'NPI Number',
		sorting: 'npi',
		displayName: 'NPI Match',
		model: <IndustryTitleLocationType type='npiNumber' compName='NPI Match' />,
	},
	{
		icon: <OutboundIcon fontSize='f-18' />,
		title: 'Office Based',
		displayName: 'Office Based',
		model: (
			<IndustryTitleLocationType type='officetype' compName='Office Based' />
		),
	},
	{
		icon: <EmailIcon fontSize='f-18' />,
		title: 'Email Type',
		displayName: 'Email Type',
		model: <IndustryTitleLocationType type='emailtype' compName='Email Type' />,
	},
	// {
	//   icon: <MergeTypeIcon fontSize="small" />,
	//   title: "Facility Type",
	//   model: (
	//     <IndustryTitleLocationType
	//       type="facilityType"
	//       compName="Facility Type"
	//     />
	//   ),
	// },
	// {
	//   icon: <VpnKeyIcon fontSize="small" />,
	//   title: "Credential",
	//   model: (
	//     <IndustryTitleLocationType
	//       type="credential"
	//       compName="Credential"
	//     />
	//   ),
	// },
	// {
	//   icon: <ApprovalIcon fontSize="small" />,
	//   title: "Provider/Facility",
	//   model: (
	//     <IndustryTitleLocationType
	//       type="providerFacility"
	//       compName="Provider/Facility"
	//     />
	//   ),
	// },
	// {
	//   icon: <OutboundIcon fontSize="small" />,
	//   title: "Exclusion",
	//   model: (
	//     <IndustryTitleLocationType
	//       type="exclusions"
	//       compName="Exclusion"
	//     />
	//   ),
	// },
];

const firstTooltip = [
	'Location',
	'Speciality',
	'Type',
	'Title',
	'Hospital Type',
	'Firm Type',
	'Ownership',
	'Level ID',
	'Geographic Classification',
	'Gender',
	'Licensed State',
	'Experience',
	'NPI Number',
	'Speciality Type',

	'Revenue',
	'Employees',
	'URL',
	'Technologies',
	'Provider/Facility',
	'Credential',
	'Facility Type',
	'Other',
	'Exclusions',

	'Office Based',
	'Email Type',
];

const Filter = (props) => {
	const [filterData, setFilterList] = useState(physicianFilter);
	const { auth } = useAuth();

	// const {}=useContext(MainContext);
	const {
		anyFilterFlag,
		typesOfFilter,
		setTypesOfFilter,
		profile,
		dispatch,
		filter,
		setSortField,
		setFilter,
		setAllContacts,
		setFilterListData,
		setSelectedLeads,
		setFirstTimeApiCall,
		setFirstTimeFilter,
		setPage,
		setValue,
		setSpecialityInputValue,
		setSpecialityInputValue1,
		setSpecialityInputValue2,
		setTypeInputValue,
		setCountryInput,
		setStateInput,
		setCityInput,
		setZipInput,
		setLicenseInput,
		setTitleInput,
		setLevelInput,
		setFacilityInput,
		setNetworkInput,
		setOwnerInput,
		setGeoInput,
		setSelectedFilter,
		selectedFilter,
		setSelectedVal,
	} = useContext(AuthContext);
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		filterKeyToGlobalFilterKey,
	} = useContext(MainContext);
	const openSubModel = (title) => {
		if (selectedFilter == title) {
			setSelectedFilter('');
		} else {
			setSelectedFilter(title);
		}
	};

	const handleFilterSubmit = () => {
		setSelectedFilter('');
		setPage(1);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		dispatch({
			type: FILTER_CONTACTS,
		});
		anyFilterFlag.current = true;
	};

	const handleClearSearch = () => {
		setSpecialityInputValue(''),
			setSpecialityInputValue1(''),
			setSpecialityInputValue2(''),
			setTypeInputValue(''),
			setCountryInput(''),
			setStateInput(''),
			setCityInput(''),
			setZipInput(''),
			setLicenseInput(''),
			setTitleInput(''),
			setLevelInput(''),
			setFacilityInput(''),
			setNetworkInput(''),
			setOwnerInput(''),
			setGeoInput(''),
			setSelectedVal('');
		setFirstTimeApiCall(false);
		setPage(1);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setValue([0, 99]);
		setSortField({});
		setSortField({
			contact_name_sort: null,
			address_sort: null,
			city_sort: null,
			state_sort: null,
			country_sort: null,
			zipcode_sort: null,
			speciality_sort: null,
			specialityType_sort: null,
			specialityGroup_sort: null,
			gender_sort: null,
			title_sort: null,
			hospitalType_sort: null,
			firmType_sort: null,
			levelID_sort: null,
			ownership_sort: null,
			geographic_sort: null,
			experience_sort: null,
			licenseState_sort: null,
			npi_sort: null,
			degree_sort: null,
			hospitalName_sort: null,
			department_sort: null,
			webaddress_sort: null,
			bedrange_sort: null,
		});
		setFilter({
			contact_name: [],

			tabletitle: [],
			tablehospitalName: [],
			tablehospitalType: [],
			tabledepartment: [],
			tablecity: [],
			tablestate: [],
			tablespeciality: [],
			tablespecialityGroup: [],
			tablespecialityType: [],
			tablewebaddress: [],
			tablefirmType: [],
			tableownership: [],
			tablebedrange: [],
			tabledegree: [],
			tablenpi: [],

			address: [],
			city: [],
			state: [],
			country: [],
			zipcode: [],
			speciality: [],
			specialityType: [],
			specialityGroup: [],
			gender: [],
			title: [],
			hospitalType: [],
			firmType: [],
			levelID: [],
			ownership: [],
			geographic: [],
			experience: [],
			licenseState: [],
			npi: [],
			//new
			degree: [],
			hospitalName: [],
			department: [],
			webaddress: [],
			bedrange: [],

			officetype: [],
			emailtype: [],
		});
		let emptyGlobalSelectFilter = {};
		for (const [key, value] of Object.entries(globalSelectFilter)) {
			emptyGlobalSelectFilter[key] = [];
		}
		setGlobalSelectFilter(emptyGlobalSelectFilter);
		setAllContacts([]);
		setSelectedLeads([]);
		anyFilterFlag.current = false;
		// setTypesOfFilter(PHYSICIAN_FILTER);
		// dispatch({
		//   type: FILTER_CONTACTS,
		//   setAllContacts: setAllContacts,
		//   page: page,
		//   limit: rowsPerPage,
		// });
	};

	return (
		<>
			<div className='contact-search d-flex'>
				<div
					style={{
						zIndex: 1,
						height: 'calc(100% - 63px)',
						position: 'fixed',
						top: '63px',
					}}
				>
					<div
						direction='left-end'
						className={`${
							props.hideShow ? 'hide-side' : 'sidebar-wrapper'
						} bla bla bla `}
						style={{ height: '100%' }}
						// enableOverlay="false"
					>
						{!props.hideShow ? (
							<div
								className='d-flex justify-content-center'
								style={{
									height: '40px',
									paddingTop: '26px',
									marginBottom: '20px',
								}}
							>
								<h6
									className='text-bold f-16'
									style={{
										fontWeight: 500,
										width: '100%',
									}}
								>
									Search Criteria
								</h6>
							</div>
						) : (
							<div className='d-flex'>
								<Button
									variant='contained'
									// style={{ width: `${!props.hideShow ? "220px" : ""}` }}
									className={`header-menu top-bar-button bg-white f-14 btn-height`}
									onClick={(e) => {
										props.hideShowClick();
										e.stopPropagation();
									}}
								>
									<NavigateNextIcon className='blue_text f-16' />
								</Button>
							</div>
						)}
						{!props.hideShow ? (
							<div className='mb-3'>
								<Button
									size='small'
									variant={
										typesOfFilter === EXECUTIVE_FILTER
											? 'contained'
											: 'outlined'
									}
									className={
										typesOfFilter === EXECUTIVE_FILTER
											? 'common-css f-14 btn-height'
											: 'f-14 btn-height'
									}
									onClick={() => {
										if (typesOfFilter !== EXECUTIVE_FILTER) {
											setTypesOfFilter(EXECUTIVE_FILTER);
											anyFilterFlag.current = false;
											setFilterList(executiveFilter);
											setFirstTimeApiCall(false);
											// this.setState({ filter: executiveFilter });
										}
									}}
								>
									<small>Executives</small>
								</Button>
								&nbsp;
								<Button
									size='small'
									className={
										typesOfFilter === PHYSICIAN_FILTER
											? 'common-css f-14 btn-height'
											: 'f-14 btn-height'
									}
									variant={
										typesOfFilter === PHYSICIAN_FILTER
											? 'contained'
											: 'outlined'
									}
									onClick={() => {
										if (typesOfFilter !== PHYSICIAN_FILTER) {
											setTypesOfFilter(PHYSICIAN_FILTER);
											anyFilterFlag.current = false;
											setFilterList(physicianFilter);
											setFirstTimeApiCall(false);

											// this.setState({ filter: physicianFilter });
										}
									}}
								>
									<small>Physicians</small>
								</Button>
							</div>
						) : (
							''
						)}
						<div
							style={{
								background: 'white',
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'column',
								height: 'calc(100% - 60px)',
							}}
						>
							<div
								className='hideScrollBar'
								id={selectedFilter ? 'filter-scroller' : 'filter-scroller'}
								style={{
									overflow: 'hidden',

									marginBottom: '10px',
								}}
							>
								{filterData &&
									filterData?.map((data, i) => {
										return (
											<Tippy
												content={
													<CommanPopUp
													// DoneTitle={() => {
													//   this.setState({ selectedFilter: "" });
													// }}
													>
														{data.model}
													</CommanPopUp>
												}
												visible={
													data.title == selectedFilter &&
													firstTooltip.includes(data.title)
														? true
														: false
												}
												// hideOnClick={true}
												onClickOutside={() => {
													setSelectedFilter('');
												}}
												interactive={true}
												className={`Tippy ${data.class}`}
												placement='right-start'
												// trigger='click'
												offset={[0, 10]}
												// hideOnClick="toggle"
												key={i}
											>
												<div className='responsive_filter_button'>
													<div
														className={`responsive_filter_button d-flex flex-row drop-down-menu p-1 me-1 mt-0 pt-2 drop-down ml-0 ${
															data.title == selectedFilter && 'active-filter'
														}`}
														onClick={() => {
															if (props.hideShow) {
																props.hideShowClick();
															}
															openSubModel(data.title);
														}}
														style={{
															color: '#B1B5C0',
															marginLeft: '0 !important',
														}}
													>
														<div className='p-1 mr-3 ml-2'>{data.icon}</div>

														<div
															style={{
																marginLeft: '0 !important',
																fontWeight:
																	filter[data?.sorting]?.length > 0 ? 500 : 400,
															}}
															className={`f-14 text-start ${
																props.hideShow && 'd-none'
															}`}
														>
															{data.displayName}
														</div>
														<ChevronRightIcon className={`ml-auto`} />
													</div>
													{!firstTooltip.includes(data.title) && (
														<div className='ml-4 mr-2 text-start drop-down-list'>
															{selectedFilter == data.title && !props.hideShow
																? data.model
																: ''}
														</div>
													)}
												</div>
											</Tippy>
										);
									})}
							</div>
							<div
								style={{
									height: 'fit-content',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'end',
								}}
							>
								<div className='filter-button-wrapper'>
									<Button
										size='small'
										style={{
											width: '85%',
											backgroundColor: '#1891EB',
										}}
										className='header_button shadow-none f-14'
										variant={'contained'}
										onClick={handleFilterSubmit}
									>
										{!props.hideShow ? (
											<>
												<SearchIcon fontSize='f-16' className='me-2' />
												<span>Search</span>
											</>
										) : (
											<SearchIcon fontSize='f-14' />
										)}
									</Button>
									<div className='my-2'>
										<Button
											variant='outlined'
											color='error'
											size='small'
											style={{
												width: '85%',
											}}
											className='header_button shadow-none f-14'
											onClick={handleClearSearch}
										>
											{!props.hideShow ? (
												<>
													<ClearIcon fontSize='f-16' className='me-2' />
													<span>Clear Search</span>
												</>
											) : (
												<ClearIcon fontSize='f-14' />
											)}
										</Button>
									</div>
								</div>

								<div
									style={{
										height: '1px',
										backgroundColor: '#1891EB',
										margin: '0 10px',
									}}
								></div>

								<div
									className='d-flex justify-content-start'
									style={{ display: 'block', color: '#B1B5C0' }}
								>
									<Button
										variant='contained'
										style={{
											float: 'right',
											fontWeight: '400',
											color: `#B1B5C0`,
										}}
										onClick={() => {
											props.navigate('/profile');
										}}
										className={`header-menu top-bar-button p-0 m-0 bg-white text-start responsive_filter_button f-14`}
									>
										<div className='p-1 mr-3 ml-2'>
											<PersonIcon
												fontSize='f-16'
												style={{ color: '#B1B5C0' }}
											/>
										</div>
										{!props.hideShow && (
											<>
												<span style={{ color: '#B1B5C0' }}>Profile</span>
												<ChevronRightIcon
													fontSize='f-16'
													className={`ml-auto`}
													style={{ color: '#B1B5C0' }}
												/>
											</>
										)}
									</Button>
								</div>

								<div className='d-flex justify-content-start'>
									<Button
										variant='contained'
										style={{ float: 'right' }}
										className={`header-menu top-bar-button p-0 m-0 bg-white  ps-3 f-14 btn-height`}
										onClick={(e) => {
											props.hideShowClick();
											e.stopPropagation();
										}}
									>
										{!props.hideShow ? (
											<>
												<span className='f-14' style={{ color: '#B1B5C0' }}>
													Close Sidebar
												</span>
												<ChevronLeftIcon
													fontSize='f-14'
													className={`ml-auto`}
													style={{ color: '#B1B5C0' }}
												/>
											</>
										) : (
											''
										)}
									</Button>
								</div>
								<div className='d-flex justify-content-start'>
									<Button
										variant='contained'
										style={{ float: 'right' }}
										className={`header-menu top-bar-button p-0 m-0 bg-white  ps-3 f-14 btn-height`}
										onClick={(e) => {
											props.hideShowClick();
											e.stopPropagation();
										}}
									>
										{!props.hideShow ? (
											<>
												<span className='f-14' style={{ color: '#B1B5C0' }}>
													Close Sidebar
												</span>
												<ChevronLeftIcon
													fontSize='f-16'
													className={`ml-auto`}
													style={{ color: '#B1B5C0' }}
												/>
											</>
										) : (
											<ChevronRightIcon
												fontSize='f-16'
												className={`ml-auto`}
												style={{ color: '#B1B5C0' }}
											/>
										)}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='main-wrapper'>{props.children}</div>
			</div>
		</>
	);
};

export default Filter;
