import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import PopUpModel from "../comman/PopUpModel";
import { Avatar, List, message } from "antd";
import { AuthContext } from "../Context/AuthContext";
import { GET_MEMBER_ACTIVITY_LOG } from "../Context/Types";

const ActivityLogPopUp = (props) => {
  const { dispatch } = useContext(AuthContext);
  const [activityLog, setActivityLog] = useState([]);
  const [isActivityLoading, setIsActivityLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const limit = 4;
  //   console.log(props.selectedTeamMember);

  const handleChange = (pageNum) => {
    setPage(pageNum);
    dispatch({
      type: GET_MEMBER_ACTIVITY_LOG,
      updateState: { setRows: setActivityLog, setCount: setTotal },
      setIsLoading: setIsActivityLoading,
      id: props?.selectedTeamMember?._id,
      page: pageNum,
      limit: limit,
    });
  };

  useEffect(() => {
    dispatch({
      type: GET_MEMBER_ACTIVITY_LOG,
      updateState: { setRows: setActivityLog, setCount: setTotal },
      setIsLoading: setIsActivityLoading,
      id: props.selectedTeamMember?._id,
      page: page,
      limit: limit,
    });
  }, []);

  return (
    // <div className="border box_shadow card">
    <List
      pagination={{
        onChange: handleChange,
        pageSize: limit,
        total: total,
        current: page,
      }}
      loading={isActivityLoading}
      itemLayout="horizontal"
      dataSource={activityLog}
      renderItem={(item) => (
        <List.Item className="p-3 text-start">
          <List.Item.Meta
            style={{ color: "black !important" }}
            title={`${item?.member?.name} - ${item?.heading}`}
            description={<div className="f-14">{item?.message}</div>}
          />
          <div div className="text-muted f-12">
            {moment(item?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </div>
        </List.Item>
      )}
    />
    // </div>
  );
};

export default ActivityLogPopUp;
