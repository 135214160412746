import React, { useContext, useState } from "react";
// import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Radio from '@mui/material/Radio';
import { Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Select from "react-select";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { UPLOAD_LEAD_CSV } from "../../../../componants/Context/Types";

const UploadLead = (props) => {
  const options = props.categories;
  const [selectedOption, setSelectedOption] = useState(null);
  const [csvFile, setCsvFile] = useState();
  const [filename, setFilename] = useState("");
  const formData = new FormData();
  const { dispatch } = useContext(AuthContext);
  const handleOnChange = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);
    setCsvFile(file);
  };
  const handleFileUpload = (e) => {
    if (csvFile && selectedOption) {
      formData.append("file", csvFile);
      formData.append("categoryId", selectedOption.value);
      dispatch({
        type: UPLOAD_LEAD_CSV,
        payload: formData,
        setIsLoading: props.setIsLoading,
      });
      props.close();
    }
  };

  return (
    <div>
      <div className=" mb-3">
        <div>
          <Button
            component="label"
            variant="outlined"
            startIcon={<UploadFileIcon />}
            sx={{ marginRight: "1rem" }}
          >
            Upload CSV
            <input type="file" accept=".csv" hidden onChange={handleOnChange} />
          </Button>
          <span>{filename ? filename : ""}</span>
          <Select
            onChange={setSelectedOption}
            options={options}
            className="user-input-fild w-100 mt-4"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            placeholder="Choose Category"
            required
          />
          {/* <TextField id="outlined-basic" size="small" className="user-input-fild w-100 mt-4" label="Name"  variant="outlined"/>
            <TextField id="outlined-basic" size="small" className="user-input-fild w-100 mt-4" name="Email" label="Email" variant="outlined" />
            <TextField id="outlined-basic" size="small" className="user-input-fild w-100 mt-4" name="WebSite" label="WebSite" variant="outlined" />
            <FormControl className='mt-3'>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                         >
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                            <FormControlLabel value="male" control={<Radio />} label="Male" />                            
                        </RadioGroup>
            </FormControl> */}
        </div>
      </div>
      <div className="m-0 d-flex justify-content-end">
        <Button
          variant="contained"
          className=" p-2 mr-0"
          onClick={handleFileUpload}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default UploadLead;
