import React from "react";
import DoneWithUpload from "./DoneWithUpload";
import Select from "react-select";
import CommanPopUp from "./CommanPopUp";

const Technologies = () => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  return (
    <>
      
    </>
  );
};

export default Technologies;
