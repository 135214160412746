/** @format */

import React, { useContext, useEffect, useState } from 'react';
import img from '../../../assests/image/Login.png';
import logo from '../../../assests/image/companylogo.png';
import TextField from '@mui/material/TextField';
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../../componants/Context/AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { LOGIN_USER } from '../../../componants/Context/Types';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useBrowser from '../../../hooks/useBrowser';
import useArea from '../../../hooks/useArea';

const SignInPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);
	const [Type, setType] = useState(false);
	const { dispatch } = useContext(AuthContext);
	const { browserName } = useBrowser();
	const { area } = useArea();
	let captchaRef;

	const navigate = useNavigate();
	const initialValues = {
		email: '',
		password: '',
		remember_me: false,
	};
	const validate = Yup.object().shape({
		email: Yup.string()
			.email('This is invalid email')
			.required('This is required'),
		// .matches(
		//   /^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/,
		//   "Must Be a Official Company Email"
		// ),
		password: Yup.string()
			.required('Please Enter your password')
			.test(
				'len',
				'Must have atleast 6 Characters',
				(val) => val?.toString().length >= 6
			),
	});
	//form submit
	const handleSubmit = (values, resetForm) => {
		// alert("Inside Submit");
		const withCaptcha = {
			...values,
			captcha_token: captchaToken,
			browserType: browserName,
			location: area.city ? area.city : '',
		};
		dispatch({
			type: LOGIN_USER,
			payload: withCaptcha,
			resetForm: resetForm,
			setIsLoading: setIsLoading,
			handleCaptchaChange: handleCaptchaChange,
		});
	};
	const onChangeCaptcha = (value) => {
		setIsCaptchaVerified(true);
		setCaptchaToken(value);
	};
	const handleCaptchaChange = () => {
		// alert("CAPTCHA RESET");
		if (isCaptchaVerified) {
			captchaRef.reset();
			setIsCaptchaVerified(false);
		}
	};

	return (
		<>
			<div className='login-page'>
				<div className='img-content'>
					<img
						src={img}
						className='login-img pointer'
						onClick={() => navigate('/')}
					/>
				</div>
				<div className='sighnUp-content'>
					<img src={logo} className='logo-img mx-auto' />
					<h2 className='text-bold'>Sign in</h2>
					<Formik
						initialValues={initialValues}
						validationSchema={validate}
						onSubmit={(values, { resetForm }) => {
							// alert("Form is validated! Submitting the form...");
							handleSubmit(values, resetForm);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleSubmit,
							setFieldValue,
							handleBlur,
						}) => (
							<form onSubmit={handleSubmit}>
								<div className='login-input position-relative'>
									<TextField
										label='Email'
										autoComplete='off'
										value={values.email}
										helperText={errors.email && touched.email && errors.email}
										error={errors.email && touched.email && errors.email}
										variant='outlined'
										type='email'
										name='email'
										onChange={(e) => {
											handleChange(e);
											handleCaptchaChange();
										}}
										onBlur={handleBlur}
										className='mb-3 mt-3 custom-width'
									/>
									<TextField
										label='Password'
										autoComplete='off'
										value={values.password}
										helperText={
											errors.password && touched.password && errors.password
										}
										error={
											errors.password && touched.password && errors.password
										}
										variant='outlined'
										type={`${Type == true ? 'text' : 'password'}`}
										InputProps={{
											endAdornment: (
												<InputAdornment position='start'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={() => {
															setType(!Type);
														}}
														edge='end'
													>
														{Type ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											),
										}}
										onChange={(e) => {
											handleChange(e);
											handleCaptchaChange();
										}}
										onBlur={handleBlur}
										name='password'
										className='mb-3 custom-width'
									/>
									<ReCAPTCHA
										ref={(r) => {
											if (r) {
												return (captchaRef = r);
											}
										}}
										sitekey='6LeT9UwhAAAAAHIV4xebP9-8WibYs5iufcHiYGW8'
										onChange={onChangeCaptcha}
									/>

									<div className='mb-3 d-flex justify-content-center w-75'>
										{/* <div>
                      <Checkbox
                        {...label}
                        name="remember_me"
                        value={values.remember_me}
                        onChange={handleChange}
                        className="pl-0"
                      />{" "}
                      Remember Me
                    </div> */}
										<div>
											<Link
												to='/forgotpassword'
												className='login-link'
												// onClick={() => forgetPassword(values.email)}
											>
												Forgot Password?
											</Link>
										</div>
									</div>
								</div>
								<Button
									className='login-btn mb-2'
									type='submit'
									variant='contained'
									disabled={!isCaptchaVerified || isLoading}
								>
									{' '}
									{isLoading ? (
										<CircularProgress sx={{ fontSize: 10, color: 'white' }} />
									) : (
										'Sign in'
									)}
								</Button>
								<div>
									Don't have any account?{' '}
									<span
										className='login-link'
										onClick={() => {
											navigate('/signUp');
										}}
									>
										Sign up here...
									</span>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
};
export default SignInPage;
