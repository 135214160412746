/** @format */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PopUpModel from '../../../../componants/comman/PopUpModel';
import { CheckoutForm } from './CheckoutForm';
import subscription from '../../../../Data/subscription.json';
import {
	BUY_PLAN,
	GET_ALL_SUBSCRIPTION_PLAN,
} from '../../../../componants/Context/Types';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import { Checkbox } from 'antd';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import BuyMoreCreditPopUpModel from '../../../../componants/comman/BuyMoreCreditPopUpModel';
import FacebookCircularProgress from '../../../../componants/comman/FacebookCircularProgress';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import CancelSubModel from '../../../../componants/comman/CancelSubModel';
import ContactSupport from '../../../../componants/comman/ContactSupport';
const paymentGateway = [
	// { value: "PAYPAL", label: "PayPal" },
	{ value: 'STRIPE', label: 'Stripe' },
];

const VerifyManagement = (props) => {
	const { dispatch, currentPlan, typeOfUser } = useContext(AuthContext);
	const isExpired =
		currentPlan?.subscription_end_date < new Date().toISOString();
	// const isExpired =
	//   currentPlan?.subscription_end_date < new Date().toISOString();
	const [tab, settab] = useState(0);
	const [CheckOutModel, setCheckOutModel] = useState(false);
	const [cancelModel, setCancelModel] = useState(false);
	const [upgradebtn, setupgradebtn] = useState(false);
	const [contactSupportModel, setContactSupportModel] = useState(false);
	const [isContainerLoading, setIsContainerLoading] = useState(false);
	const [allPlans, setAllPlans] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [buyCreditModal, setBuyCreditModal] = useState(false);
	const [selectedGateway, setSelectedGateway] = useState('STRIPE');
	const [showDropDown, setshowDropDown] = useState(false);
	const [selectedplan, setselectedplan] = useState('ann');

	// const handlemodel = () => {
	//   setCheckOutModel("");
	// };

	const list = useMemo(() => {
		const res = allPlans?.map((item) => {
			return { title: item.title, id: item._id };
		});
		return res;
	}, [allPlans]);

	// const setModelCheckOut = (tab) => {
	//   setModelData(subscription.subscription[tab]);
	//   setCheckOutModel(true);
	// };

	const handleUpgrade = (sub_id) => {
		if (!selectedGateway) {
			//("Choose a Payment Gateway.");
			return;
		}
		dispatch({
			type: BUY_PLAN,
			payload: {
				subscription_id: sub_id,
				payment_gateway: selectedGateway,
				isAnnual: selectedplan === 'ann' ? true : false,
			},
			setIsLoading: setIsLoading,
		});
	};

	const getSubscription = () => {
		if (props.selectedCompanyByAdmin) {
			// all subscription for particular company(to show in Super Admin) is handled by id
			dispatch({
				type: GET_ALL_SUBSCRIPTION_PLAN,
				id: props.selectedCompanyByAdmin,
				flag: '',
				updateState: setAllPlans,
				setIsLoading: setIsContainerLoading,
			});
		} else {
			// all subscription for particular company(companyAdmin) is handled in backned
			dispatch({
				type: GET_ALL_SUBSCRIPTION_PLAN,
				id: '',
				flag: '',
				updateState: setAllPlans,
				setIsLoading: setIsContainerLoading,
			});
		}
	};

	useEffect(() => {
		getSubscription();
		if (currentPlan?.isAnnual) {
			setselectedplan('ann');
		}
		if (
			!currentPlan?.isAnnual &&
			currentPlan?.subscription_type !== 'Free Trial'
		) {
			setselectedplan('mth');
		}
	}, []);

	return (
		<div>
			{isContainerLoading ? (
				<div className='d-flex justify-content-between flex-row'>
					<div className='mx-auto bg-white w-5/6 min-h-5 p-4'>
						<FacebookCircularProgress />
					</div>
				</div>
			) : (
				<>
					<div className='d-flex flex-row justify-content-around  upgrade-color   subscripton-header'>
						{list?.map((data, index) => {
							return (
								<>
									<div
										variant='contained'
										className={`
                    ${tab == index && 'selected-tab'}
                    p-2 pointer subscription-hover

                    `}
										onClick={() => {
											settab(index);
										}}
									>
										<div color='secondary'>
											<div className='mx-1'>{data.title}</div>{' '}
										</div>
									</div>
								</>
							);
						})}
					</div>
					<hr className='m-0'></hr>

					{allPlans ? (
						<div className='d-flex subscripton-content '>
							<div className='d-flex w-50 flex-column p-4 justify-content-between'>
								<div className='d-flex flex-column'>
									<div className='d-flex justify-content-between'>
										<h2 className='d-flex mb-1 text-bold '>
											$
											{selectedplan === 'ann'
												? allPlans[tab]?.annually_amount?.toLocaleString()
												: selectedplan === 'mth'
												? allPlans[tab]?.monthly_amount?.toLocaleString()
												: null}
											{!isExpired && currentPlan?.isAnnual ? (
												<div
													id='dropdownMenuButton1'
													data-bs-toggle='dropdown'
													aria-expanded='false'
													variant='outlined'
													size='small'
													className='border-0 m-0 mt-2 p-0 text-bold d-flex blue_text'
												>
													<small
														style={{ fontSize: '16px' }}
														className='d-flex align-items-start text-bold '
													>
														/ annual
													</small>
												</div>
											) : (
												!isExpired &&
												currentPlan?.subscription_type !== 'Free Trial' && (
													<div
														id='dropdownMenuButton1'
														data-bs-toggle='dropdown'
														aria-expanded='false'
														variant='outlined'
														size='small'
														className='border-0 m-0 mt-2 p-0 text-bold d-flex blue_text'
													>
														<small
															style={{ fontSize: '16px' }}
															className='d-flex align-items-start text-bold '
														>
															/ month
														</small>
													</div>
												)
											)}
											{!currentPlan?.isAnnual &&
												currentPlan?.subscription_type === 'Free Trial' && (
													<div class='dropdown mt-2'>
														<div
															id='dropdownMenuButton1'
															data-bs-toggle='dropdown'
															aria-expanded='false'
															variant='outlined'
															size='small'
															className='border-0 m-0 mt-2 p-0 text-bold d-flex blue_text pointer'
															onClick={() => setshowDropDown(!showDropDown)}
														>
															<small
																style={{ fontSize: '16px' }}
																className='d-flex align-items-start text-bold '
															>
																/{selectedplan === 'ann' ? 'annual' : 'month'}
															</small>

															{!showDropDown ? (
																<ExpandMoreTwoToneIcon fontSize='small' />
															) : (
																<ExpandLessTwoToneIcon fontSize='small' />
															)}
														</div>
														<ul
															class='dropdown-menu'
															aria-labelledby='dropdownMenuButton1'
														>
															<li>
																<div
																	onClick={() => {
																		setselectedplan('ann');
																		setshowDropDown(!showDropDown);
																	}}
																	className={`${
																		selectedGateway === 'ann' && 'blue_text'
																	} dropdown-item  pointer`}
																>
																	Annualy
																</div>
															</li>
															<li>
																<div
																	onClick={() => {
																		setselectedplan('mth');
																		setshowDropDown(!showDropDown);
																	}}
																	className={`${
																		selectedGateway === 'mth' && 'blue_text'
																	} dropdown-item  pointer`}
																>
																	Monthly
																</div>
															</li>
														</ul>
													</div>
												)}
											{isExpired && (
												<div class='dropdown mt-2'>
													<div
														id='dropdownMenuButton1'
														data-bs-toggle='dropdown'
														aria-expanded='false'
														variant='outlined'
														size='small'
														className='border-0 m-0 mt-2 p-0 text-bold d-flex blue_text pointer'
														onClick={() => setshowDropDown(!showDropDown)}
													>
														<small
															style={{ fontSize: '16px' }}
															className='d-flex align-items-start text-bold '
														>
															/{selectedplan === 'ann' ? 'annual' : 'month'}
														</small>

														{!showDropDown ? (
															<ExpandMoreTwoToneIcon fontSize='small' />
														) : (
															<ExpandLessTwoToneIcon fontSize='small' />
														)}
													</div>
													<ul
														class='dropdown-menu'
														aria-labelledby='dropdownMenuButton1'
													>
														<li>
															<div
																onClick={() => {
																	setselectedplan('ann');
																	setshowDropDown(!showDropDown);
																}}
																className={`${
																	selectedGateway === 'ann' && 'blue_text'
																} dropdown-item  pointer`}
															>
																Annualy
															</div>
														</li>
														<li>
															<div
																onClick={() => {
																	setselectedplan('mth');
																	setshowDropDown(!showDropDown);
																}}
																className={`${
																	selectedGateway === 'mth' && 'blue_text'
																} dropdown-item  pointer`}
															>
																Monthly
															</div>
														</li>
													</ul>
												</div>
											)}
										</h2>
										{tab == 'essentials' && (
											<p className='popular'>Most Popular</p>
										)}
									</div>
									<h6 className='text-black'>
										Credits:{' '}
										<span className='text-black text-bold'>
											{selectedplan === 'ann'
												? allPlans[tab]?.annually_credits
												: selectedplan === 'mth'
												? allPlans[tab]?.monthly_credits
												: null}
										</span>
									</h6>
									<h6 className='blue_text'>
										<span className=' text-black'>
											Users:{' '}
											<span className=' text-black text-bold'>
												{allPlans[tab]?.no_of_user}
											</span>
										</span>
									</h6>
									<h6 className='blue_text'>
										<span className=' text-black'>
											Cost per Credit:{' '}
											<span className=' text-black text-bold'>
												{' '}
												${allPlans[tab]?.cost_per_credit}
											</span>
										</span>
									</h6>
									<h6 className='blue_text'>
										<span className=' text-black'>
											Cost per User:{' '}
											<span className=' text-black text-bold'>
												{' '}
												${allPlans[tab]?.cost_per_user}
											</span>
										</span>
									</h6>
									<h6 className='text-secondary'>
										{/* {subscription.subscription[tab].additional_Credits} */}
									</h6>
									<div className='text-bold'>Description:</div>
									<div className=''>{allPlans[tab]?.desc}</div>
								</div>
								{/* <div className="d-flex flex-column mt-5"></div> */}
								{/* <div className="subscription-upgrade">
                <p className=" d-flex justify-content-between upgrade-p">
                  <div className=" d-flex justify-content-between ">
                    <input
                      type="radio"
                      id="html"
                      name="Plan"
                      value="Monthly"
                      className="m-1 upgrade-radio pointer"
                      onClick={() => {
                        setupgradebtn(true);
                      }}
                    />
                    <h6 className="radio-size mx-1">Monthly</h6>
                  </div>

                  <div className=" d-flex justify-content-between ">
                    <input
                      type="radio"
                      id="html"
                      name="Plan"
                      value="Annualy"
                      className="m-1 upgrade-radio pointer"
                      onClick={() => {
                        setupgradebtn(true);
                      }}
                    />
                    <h6 className="radio-size mx-1">Annualy</h6>
                  </div>
                </p>
              </div> */}
								<div className=''>
									<span className='text-bold'>Payment Gateway:</span>
									<div>
										{paymentGateway?.map((item) => {
											return (
												<span key={item.value}>
													<Checkbox
														key={item.value}
														onChange={(e) => {
															setSelectedGateway(e.target.value);
														}}
														checked={item?.value === selectedGateway}
														value={item?.value}
													>
														{item?.label}
													</Checkbox>
												</span>
											);
										})}
									</div>
								</div>
								<BuyMoreCreditPopUpModel
									close={() => {
										setBuyCreditModal(false);
									}}
									open={buyCreditModal}
									title={'Buy credits'}
								/>
								{!isExpired && typeOfUser !== 'FREE' ? (
									<h6
										className='user-edit-profile mt-2 mb-0 pb-0  pointer buy-more-credit'
										onClick={() => {
											setBuyCreditModal(true);
										}}
									>
										Buy more Credits!!
									</h6>
								) : null}
								<div className='mb-0 d-flex justify-content-center w-100 '>
									<Button
										variant='contained'
										style={{ boxShadow: 'none' }}
										color={!isExpired ? 'error' : 'primary'}
										className={`upgrade w-100 mt-2 `}
										onClick={() => {
											if (
												allPlans[tab]?.title ===
													currentPlan?.subscription_type &&
												!isExpired
											) {
												setCancelModel(true);
											} else {
												handleUpgrade(allPlans[tab]?._id);
											}
											// setCheckOutModel(true);
										}}
									>
										{isLoading ? (
											<CircularProgress sx={{ color: 'white' }} size={25} />
										) : allPlans[tab]?.title ===
												currentPlan?.subscription_type && !isExpired ? (
											'Cancel'
										) : !isExpired ? (
											'Upgrade'
										) : (
											'Buy'
										)}
									</Button>
								</div>
								<p className='f-12 mt-2'>
									For Enterprise Plan{' '}
									<span
										className='credit-color pointer'
										onClick={() => setContactSupportModel(true)}
									>
										Click Here!
									</span>
								</p>
								<ContactSupport
									close={() => {
										setContactSupportModel(false);
									}}
									open={contactSupportModel}
									title={'Support!'}
								/>
								{/* <PopUpModel
                  open={CheckOutModel}
                  close={handlemodel}
                  title={"Billing Information"}
                  className="checkout-model-width"
                >
                  <CheckoutForm modelData={allPlans[tab]} />
                </PopUpModel> */}
								<CancelSubModel
									open={cancelModel}
									close={() => setCancelModel(false)}
									title={'Cancel Subscription'}
								/>
							</div>
							<div className='w-50 p-4 mb-0 pb-0  bg-light'>
								<h4 className='text-bold'>Features:</h4>
								<div className='mb-0 pb-0'>
									{allPlans[tab]?.features?.map((item) => {
										return (
											<h6 className='f-16'>
												<AddTaskIcon className='fill-blue mr-3' />
												{item?.description}
											</h6>
										);
									})}
								</div>
							</div>
						</div>
					) : null}
				</>
			)}
		</div>
	);
};

export default VerifyManagement;
