import React from "react";
import Container from "@mui/material/Container";

const TermsConditions = () => {
  return (
    <Container maxWidth="m">
      <div className="nested-list-ordered mx-5">
        <h2>Terms and Conditions</h2>
        <i>Last updated October 30, 2022</i>
        <ol>
          <li>
            <b>Acceptance.</b>
            <ol>
              <li>
                <b>Scope</b> These terms of use are entered into by and between
                You and HealthDBI, LLC (“HealthDBI”, “we”, or “us”). The
                following terms and conditions, together with any other
                documents expressly incorporated by reference (collectively, the
                “Terms of Use”), govern (i) Your access to and use of
                www.HealthDBI.com and the content and functionality offered on
                or through www.HealthDBI.com (collectively, the “Website”), and
                (ii) Your use of HealthDBI’s proprietary, hosted service data
                base product (the “Services”) and the all associated
                documentation (if any) (the “Documentation” and, together with
                the Services, the “Product”). The effective date of these Terms
                of Use as to your use of the Website, Services, or the Product
                is the date that you first accept or agree to these Terms of
                Use.
              </li>
              <li>
                <b>Eligibility</b> THIS WEBSITE, SERVICES, AND THE PRODUCT ARE
                OFFERED AND AVAILABLE TO USERS WHO ARE 18 YEARS OF AGE OR OLDER.
                IF YOU ARE UNDER AGE 18 YOU MUST HAVE YOUR PARENT OR LEGAL
                GUARDIAN’S PERMISSION TO USE THE SERVICES, AS WELL AS HAVE HIM
                OR HER READ AND AGREE TO THESE TERMS. BY USING THE WEBSITE,
                SERVICES, AND/OR THE PRODUCT, YOU REPRESENT, ACKNOWLEDGE, AND
                AGREE THAT YOU ARE AT LEAST 18 YEARS OF AGE AND YOU AGREE TO
                THESE TERMS OF USE. IF YOU DO NOT MEET THESE REQUIREMENTS, YOU
                MUST NOT ACCESS OR USE THE WEBSITE, SERVICES, AND/OR THE
                PRODUCT. IF YOU ARE ENTERING INTO THESE TERMS OF USE ON BEHALF
                OF A COMPANY, BUSINESS, OR OTHER LEGAL ENTITY, YOU REPRESENT
                THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THESE TERMS
                OF USE, IN WHICH CASE THE TERM “YOU” SHALL REFER TO SUCH ENTITY.
                IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH
                THESE TERMS OF USE, YOU MUST NOT ACCEPT THESE TERMS OF USE AND
                MAY NOT USE ANY OF THE SERVICES, THE PRODUCT, AND/OR THE
                WEBSITE. AS A COMPANY, BUSINESS, OR OTHER LEGAL ENTITY, YOU
                AGREE TO THESE TERMS OF USE ON BEHALF OF YOUR ENTITY AND ALL
                AUTHORIZED USERS UNDER YOUR ACCOUNT, WHETHER INTERNAL OR
                EXTERNAL, AND YOU ARE RESPONSIBLE FOR SUCH AUTHORIZED USERS AND
                THEIR COMPLIANCE WITH THESE TERMS OF USE. YOU ARE RESPONSIBLE
                FOR ENSURING THAT ALL OF YOUR AUTHORIZED USERS, WHETHER INTERNAL
                OR EXTERNAL, UNDERSTAND THESE TERMS OF USE AND AGREE TO ABIDE BY
                THESE TERMS OF USE. ANY BREACH OF THESE TERMS OF USE BY ANY OF
                YOUR AUTHORIZED USERS WILL BE DEEMED A BREACH BY YOU. IF YOU ARE
                AN ACCOUNT ADMINISTRATOR FOR A COMPANY, BUSINESS, OR OTHER LEGAL
                ENTITY, YOU AGREE THAT YOU HAVE ALL NECESSARY RIGHTS TO PROVIDE
                US WITH ANY PERSONAL DATA, IF ANY, OF YOUR AUTHORIZED USERS
                UNDER YOUR ACCOUNT.
              </li>
              <li>
                <b>Binding</b> READ THESE TERMS OF USE CAREFULLY BEFORE USING
                OUR WEBSITE, THE SERVICES, OR THE PRODUCT. BY USING THE WEBSITE,
                SERVICES, OR THE PRODUCT, INDICATING YOUR AGREEMENT TO THESE
                TERMS OF USE, OR CLICKING THE “I AGREE TO THE TERMS OF USE”
                CHECKBOX, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE
                TERMS OF USE AND OUR PRIVACY POLICY AND WHERE APPLICABLE THE
                STANDARD CONTRACTUAL CLAUSES (“SCC Agreement”), WHICH ARE
                INCORPORATED HEREIN BY REFERENCE AND AVAILABLE AT{" "}
                <a href="https://healthdbi.com/">Here</a> for transfers from the
                United Kingdom, the European Union and Switzerland. IF YOU DO
                NOT AGREE TO THESE TERMS OF USE OR THE PRIVACY POLICY, DO NOT
                CLICK THE “I AGREE TO THE TERMS OF USE” CHECKBOX AND DO NOT
                ACCESS OR USE THE WEBSITE, SERVICES, OR THE PRODUCT OR ANY
                INFORMATION CONTAINED THEREON. BY USING THE WEBSITE, SERVICES,
                AND/OR PRODUCT OR BY CLICKING THE “I AGREE TO THE TERMS OF USE”
                CHECKBOX, YOU SHALL BE DEEMED TO BE AGREEING TO ABIDE BY EACH OF
                THE TERMS SET FORTH BELOW. WE MAY MAKE CHANGES TO THE CONTENT
                AND SERVICES OFFERED ON THIS WEBSITE AT ANY TIME. WE CAN CHANGE
                THESE TERMS OF USE AT ANY TIME BY POSTING UPDATED TERMS OF USE
                ON THE WEBSITE AND BY SENDING YOU AND/OR YOUR AUTHORIZED USERS
                AN EMAIL NOTICE OF THE CHANGES. IF ANY MODIFICATION IS
                UNACCEPTABLE TO YOU, YOU SHALL CEASE USING THE WEBSITE,
                SERVICES, AND/OR THE PRODUCT. IF YOU DO NOT CEASE USING THE
                WEBSITE, SERVICES, AND/OR THE PRODUCT, YOU WILL BE CONCLUSIVELY
                DEEMED TO HAVE ACCEPTED THE CHANGE.
              </li>
              <b>
                IMPORTANT: PLEASE REVIEW THE MUTUAL ARBITRATION AGREEMENT SET
                FORTH BELOW CAREFULLY, AS IT WILL REQUIRE YOU TO RESOLVE
                DISPUTES WITH HealthDBI ON AN INDIVIDUAL BASIS (WAIVING YOUR
                RIGHT TO A CLASS ACTION) THROUGH FINAL AND BINDING ARBITRATION.
                BY ENTERING THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU
                HAVE READ AND UNDERSTAND ALL OF THE TERMS OF THIS MUTUAL
                ARBITRATION AGREEMENT AND HAVE TAKEN THE TIME TO CONSIDER THE
                CONSEQUENCES OF THIS IMPORTANT DECISION.
              </b>
            </ol>
          </li>
          <li>
            <b>Website Terms</b>
            <ol>
              <li>
                <b>Ownership</b> The Website and all of its content, features,
                and functionality (including, but not limited to, all
                information, software text, displays, images, video, and audio,
                and the design and arrangement thereof) are owned by HealthDBI,
                its licensors, or other providers of such material and are all
                protected by copyright, trademark, patent, trade secret, and
                other intellectual property or proprietary rights laws. Except
                as otherwise required or limited by applicable law, any
                reproduction, distribution, modification, retransmission, or
                publication of any copyrighted material is strictly prohibited
                without the express written consent of the copyright owner.
                HealthDBI, its logo, and all related names, logos, product and
                service names, designs, and slogans are trademarks of HealthDBI
                or its affiliates or licensors. You must not use such marks
                without the prior written permission of the HealthDBI. All other
                names, logos, product and service names, designs, and slogans on
                this Website are the trademarks of their respective owners.
              </li>
              <li>
                <b>Use of Website</b> These Terms of Use allow You to use the
                Website for Your personal, non-commercial use only. You must not
                reproduce, distribute, modify, create derivative works of,
                publicly display, publicly perform, republish, download, store,
                transmit or otherwise exploit any of the material on our
                Website; provided that You may print or download one copy of
                such materials for Your own personal, non-commercial use and not
                for further reproduction, publication, or distribution. You must
                not delete or alter any copyright, trademark, or other
                proprietary rights notices from copies of materials from the
                Website. If You print, copy, modify, download, or otherwise use
                or provide any other person with access to any part of the
                Website in breach of the Terms of Use, Your right to use the
                Website will stop immediately and You must, at our option,
                return or destroy any copies of the materials You have made. No
                right, title, or interest in or to the Website or any content on
                the Website is transferred to You, and all rights not expressly
                granted are reserved by HealthDBI. Any use of the Website not
                expressly permitted by these Terms of Use is a breach of these
                Terms of Use and may violate copyright, trademark, and other
                laws.
              </li>
              <li>
                <b>Jurisdiction</b> HealthDBI is based in the United States. We
                make no claims that the Services, Product, or the Website or any
                of their content is accessible or appropriate outside of the
                United States. Access to the Website, Product, or the Services
                may not be legal by certain persons or in certain countries. If
                You access the Website, Product, or the Services from outside
                the United States, You do so on Your own initiative and are
                responsible for compliance with local laws.
              </li>
              <li>
                <b>Changes</b> We reserve the right to withdraw or amend the
                Website, the terms and conditions upon which You may be able to
                access the Website, and any services, features, or material we
                provide on the Website, in our sole discretion and without
                providing notice to You. We will not be liable if, for any
                reason, all or any part of the Website is unavailable at any
                time or for any period. From time to time, we may restrict
                access to some parts of the Website, or the entire Website, to
                users for any reason at all. HealthDBI may add new services,
                features, or material for a fee and may add or amend fees for
                existing services, features, or material, at any time in our
                sole discretion. If You choose to purchase the additional
                services, features, functionality, content, or material, You
                agree to the pricing and payment terms that may be displayed
                when You make such purchase, as we may update them from time to
                time.
              </li>
              <li>
                <b>Access Credentials </b> You may need a username and password
                to login into the Website and to use certain functions and
                portions within the Website, including the Product and Services.
                You must treat such information as confidential, and You must
                not disclose it to any other person or entity. You also
                acknowledge that Your account is personal to You and agree not
                to provide any other person with access to this Website or
                portions of it using Your user name, password, or other security
                information. You agree to notify us immediately of any
                unauthorized access to or use of Your user name or password or
                any other breach of security.
              </li>
              <li>
                <b>Prohibitions</b>You may use the Website only for lawful
                purposes and in accordance with these Terms of Use. You agree
                not to:
                <ol>
                  <li>
                    use the Website, Product, or Services in any way that
                    violates any applicable federal, state, local, or
                    international law or regulation;
                  </li>
                  <li>
                    use the Website, Product, or Services to transmit, or
                    procure the sending of, any advertising or promotional
                    material, including any “junk mail”, “chain letter”, “spam”,
                    or any other similar solicitation;
                  </li>
                  <li>
                    use the Website, Product, or Services to engage in any other
                    conduct that restricts or inhibits anyone’s use or enjoyment
                    of the Website, Product, or Services, or which, as
                    determined by us in our sole discretion, may harm HealthDBI
                    or users of the Website, Product, or Services or expose them
                    to liability;
                  </li>
                  <li>
                    use the Website, Product, or Services in any manner that
                    could disable, overburden, damage, or impair the Website or
                    interfere with any other party’s use of the Website,
                    Product, or Services, including their ability to engage in
                    real time activities through the Website, Product, or
                    Services;
                  </li>
                  <li>
                    use any robot, spider, or other automatic device, process,
                    or means to access the Website, Product, or Services for any
                    purpose, including monitoring or copying any of the material
                    on the Website;
                  </li>
                  <li>
                    use any manual process to monitor or copy any of the
                    material on the Website, Product, or Services or for any
                    other unauthorized purpose without our prior written
                    consent;
                  </li>
                  <li>
                    use any device, software, or routine that interferes with
                    the proper working of the Website, Product, or Services;
                  </li>
                  <li>
                    introduce any viruses, Trojan horses, worms, logic bombs, or
                    other material that is malicious or technologically harmful;
                  </li>
                  <li>
                    attempt to gain unauthorized access to, interfere with,
                    damage, or disrupt any parts of the Website, the server on
                    which the Website is stored, or any server, computer, or
                    database connected to the Website;
                    <ol>
                      <li>
                        contains any material that is deceptive, misleading,
                        defamatory, obscene, indecent, abusive, offensive,
                        harassing, violent, hateful, inflammatory, or otherwise
                        objectionable;
                      </li>
                      <li>
                        promotes sexually explicit or pornographic material,
                        violence, or discrimination based on race, sex,
                        religion, nationality, disability, sexual orientation,
                        or age;
                      </li>
                      <li>
                        infringes any patent, trademark, trade secret,
                        copyright, or other intellectual property or other
                        rights of any other person;
                      </li>
                      <li>
                        violates the legal rights (including the rights of
                        publicity and privacy) of others or contain any material
                        that could give rise to any civil or criminal liability
                        under applicable laws or regulations or that otherwise
                        may be in conflict with these Terms of Use and our
                        Privacy Policy;
                      </li>
                      <li>
                        promotes any illegal activity, or advocate, promote, or
                        assist any unlawful act;
                      </li>
                      <li>
                        causes annoyance, inconvenience, or needless anxiety or
                        be likely to upset, embarrass, alarm, or annoy any other
                        person; or
                      </li>
                      <li>
                        impersonates any person, or misrepresent Your identity
                        or affiliation with any person or organization.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <b>Third Party Materials</b>
            Information and other content provided by third party licensors and
            suppliers to HealthDBI (“Third Party Content”) is, in each case, the
            copyrighted and/or trademarked work of the owner of such Third Party
            Content. You acknowledge and agree that You have no right to
            download, cache, reproduce, modify, display, edit, alter or enhance
            any of the Third Party Content in any manner unless You have
            permission from the owner of the Third Party Content. The Website
            contains links to other sites and resources provided by third
            parties and such links are provided for Your convenience only. We
            have no control over the contents of those websites or resources,
            and accept no responsibility for them or for any loss or damage that
            may arise from Your use of them. If You decide to access any of the
            third party websites linked to the Website, You do so entirely at
            Your own risk and subject to the terms and conditions of use for
            such websites.
          </li>
          <li>
            <b>The Services, Product, and Data</b>
            <ol>
              <li>
                <b>License</b> All rights, title and interest in and to the
                Product and any improved, updated, modified or additional parts
                thereof, shall at all times remain the property of HealthDBI or
                its licensors. Nothing herein shall give or be deemed to give
                You any right, title or interest in or to the same except as
                expressly provided in this Agreement. HealthDBI reserves all
                rights not expressly granted herein. Subject to and conditioned
                on Your and Your Authorized Users’ compliance with the terms and
                conditions of these Terms of Use, We hereby grant You a
                non-exclusive, non-transferable right during the applicable
                Subscription Term (as defined below) (i) to access and use the
                Product, (ii) to use all associated Documentation in connection
                with such authorized use of the Services, and (iii) to make one
                copy of the Documentation solely for archival and backup
                purposes; in each case, solely for use by You and/or Your
                Authorized Users in accordance with the terms and conditions
                herein. Such use is limited to Your internal business use. The
                total number of Authorized Users will not exceed the number
                provided under the Service Plan (as defined below) purchased,
                except as expressly agreed to in writing by the parties and
                subject to any appropriate adjustment of the Fees payable
                hereunder. For purposes of these Terms of Use, “Authorized
                Users” means Your employees, consultants, contractors, and
                agents (a) who are authorized by You to access and use the
                Product under the rights granted to You pursuant to these Terms
                of Use; and (b) for whom access to the Product has been
                purchased.
              </li>
              <li>
                <b>Subscription Term; Renewal</b> A list of the service plans
                and pricing schedules (“Subscription Plan(s)”) that HealthDBI
                offers is available at www.HealthDBI.com/pricing. The initial
                term of each Subscription Plan shall be for such period that is
                listed in the purchase confirmation, receipt, and/or the invoice
                You receive from Us (the “Initial Subscription Term”). Your
                Subscription Plan will automatically renew at the end of the
                Initial Subscription Term for successive terms equal in duration
                to the Initial Subscription Term (each a “Renewal Subscription
                Term”, and, together with the Initial Subscription Term, the
                “Subscription Term”) unless You cancel Your Subscription Plan at
                least sixty (60) days in advance of the renewal date.
              </li>
              <li>
                <b>Restrictions</b> Except as expressly permitted in these Terms
                of Use, You shall not, and shall not permit any third party to:
                (i) copy, reverse engineer, reverse assemble, or otherwise
                attempt to discover the source code of all or any portion of the
                Product; (ii) reproduce, modify, translate or create derivative
                works of all or any portion of the Product; (iii) assist any
                third party to gain access, license, sublicense, resell
                distribute, assign, transfer or use the Product; (iv) remove or
                destroy any proprietary notices contained on or in the Product
                or any copies thereof; or (v) publish or disclose the results of
                any benchmarking of the Product, or use such results for Your
                own competing services development activities, without the prior
                written permission of HealthDBI.
              </li>
              <li>
                <b>Fees</b> All of our Subscription Plans are billed in advance
                on a monthly or annual basis. You agree to pay all fees,
                charges, and taxes to be paid by You in connection with Your
                purchased Subscription Plan for the duration of the Subscription
                Term (the “Fees”). All payments made are non-refundable and
                non-transferable. We reserve the right to change our pricing
                terms at any time. If we do so, we will let You know by posting
                the new pricing terms on the Website or through other
                communications. We also reserve the right to change other terms
                of our Subscription Plans at any time, including changing the
                features that can be accessed through a particular Subscription
                Plan. To purchase a Subscription Plan, You must provide us with
                current, complete, accurate and authorized credit card or other
                approved payment method information. You agree to promptly
                notify us of any changes to the provided payment method while
                Your subscription remains in effect. We subscribe to our payment
                processors’ account update service. This means that even if your
                credit card expires or changes numbers, depending on the terms
                of your credit card agreement with your credit card provider,
                our payment processors may receive updated card information and
                will be able to charge your credit card using the updated
                information. You authorize us to automatically charge the
                provided payment method for the selected Subscription Plan. We
                will charge You for the Initial Subscription Term at the time of
                purchase or shortly after purchase. We will charge You for
                renewals of Your Subscription Plan on a recurring basis, at the
                beginning of each Renewal Subscription Term, until such time
                that You cancel pursuant to Section 3.2. You understand that
                failure to pay any Fees or other charges may result in the
                suspension or cancellation of Your Subscription Plan. Fees are
                non-refundable.
              </li>
              <li>
                <b>Upgrades/Downgrades</b> Should You downgrade Your
                Subscription Plan, the new Fees for such downgraded Subscription
                Plan will take effect at the beginning of the following Renewal
                Subscription Term and no pro-rated refund of any prepaid Fees
                will be issued to You. Should You upgrade Your Subscription
                Plan, the new Fees for such upgraded Subscription Plan will take
                immediate effect and You will be charged in full for the
                upgraded Subscription Plan.
              </li>
              <li>
                <b>Data Provisions</b> Except as expressly permitted in these
                Terms of Use, You shall not, and shall not permit any third
                party to: (i) print, download, export, or make copies of Data
                (as defined below) that is made available to You through the
                Services; or (ii) use the Product, Services, or Data, in whole
                or in part, to advertise, sell, or exchange any products or
                services related to illegal or illicit activities, including,
                without limitation, payday loan services, sex products or
                services, drug products or services, pornographic materials,
                weapons, or involving credit repair services. The Data is seeded
                to detect any unauthorized use or duplication thereof, and You
                agree not to remove any seeds from the Data. For purposes of
                these Terms of Use, “Data” means the information or other data
                made available by HealthDBI to You through the Services,
                including, without limitation, the personal information and/or
                records of individuals and their company affiliation that are
                contained on HealthDBI’s database and made available to You
                through the Services. To the extent that Your use of the
                Services or Products requires us to enter into one or more of
                the SCC Agreements with You, such as without limitation when you
                access or download or print one copy of the materials provided
                by HealthDBI as permitted hereunder from within a jurisidiction
                that is not considered adequate as per the General Data
                Protection Regulation (“GDPR”) or the UK General Data Protection
                Regulation (“UK GDPR”) or equivalent data protection laws in the
                United Kingdom, You agree to be, and are hereby, bound by the
                applicable SCC Agreements available for your review{" "}
                <a href="https://healthdbi.com/">here</a> for transfers from the
                United Kingdom, the European Union and Switzerland and to
                implement and maintain appropriate technical and organizational
                measures as set forth in the GDPR or UK GDPR, as applicable.
              </li>
              <li>
                <b>Suspension of Service</b> We may, directly or indirectly,
                suspend, terminate, or otherwise deny Your or any Authorized
                User’s access to or use of all or any part of the Services or
                the Product, without incurring any resulting obligation or
                liability, if: (a) We receive a judicial or other governmental
                demand or order, subpoena, or law enforcement request that
                expressly or by reasonable implication requires us to do so; or
                (b) We believe, in our good faith discretion, that: (i) You or
                any Authorized User has failed to comply with any term of these
                Terms of Use, or accessed or used the Services or the Product
                beyond the scope of the rights granted or for a purpose not
                authorized under these Terms of Use or in any manner that does
                not comply with any instruction or requirement set forth in the
                Documentation; (ii) You or any Authorized User is, has been, or
                is likely to be involved in any fraudulent, misleading, or
                unlawful activities; or (iii) these Terms of Use expire or are
                terminated. This Section is not intended to limit any of our
                other rights or remedies, whether at law, in equity, or under
                these Terms of Use.
              </li>
              <li>
                <b>Feedback</b> You agree to make reasonable efforts to provide
                HealthDBI with oral feedback and/or written feedback related to
                Your use of the Product, including, but not limited to, a report
                of any errors which You discover in the Services or related
                Documentation. Such reports, and any other materials,
                information, ideas, concepts, feedback and know-how provided by
                You to HealthDBI concerning the Product and any information
                reported automatically through the Product to HealthDBI
                (“Feedback”) will be the property of HealthDBI. You agree to
                assign, and hereby assign, all right, title and interest
                worldwide in the Feedback, and the related intellectual property
                rights, to HealthDBI and agree to assist HealthDBI, at
                HealthDBI’s expense, in perfecting and enforcing such rights.
              </li>
              <li>
                <b>License to Branding Elements</b> You hereby grant to
                HealthDBI a non-exclusive, royalty-free, irrevocable, and
                worldwide license to use Your trademarks, service marks, trade
                dress, trade names, brand names, logos, corporate names, domain
                names, and other branding elements on or in connection with
                HealthDBI’s promotional, advertising, and marketing materials in
                all formats and media, including, without limitation, on lists
                of HealthDBI’s current or former customers and on advertisements
                for HealthDBI and/or the Product, Services, and/or the Website.
              </li>
            </ol>
          </li>
          <li>
            <b>Term and Termination</b>
            <ol>
              <li>
                <b>Term</b> Subject to earlier termination as provided below,
                the term of these Terms of Use will commence on Your acceptance
                of these Terms of Use and will continue for as long as the
                Product is being provided to You under these Terms of Use.
              </li>
              <li>
                <b>Termination</b> You have the right to terminate Your account
                at any time by sending a cancellation request to
                <a href="mailto:Team@HealthDBI.com">Team@HealthDBI.com</a>. You
                may cancel Your Subscription Plan through Your account at any
                time and for any reason. However, all Fees paid by You hereunder
                are non-refundable. If You cancel Your Subscription Plan prior
                to the end of the applicable Subscription Term, You will no
                longer be able to continue accessing or using the Service and/or
                the Product at the end of such Subscription Term and such
                termination will be effective at the start of the next billing
                or Renewal Subscription Term. HealthDBI may terminate Your
                account and these Terms of Use at any time by providing sixty
                (60) days prior notice to the administrative email address
                associated with Your account. In addition to any other remedies
                HealthDBI may have, HealthDBI may also terminate these Terms of
                Use upon ten (10) days’ notice (or two (2) days in the case of
                nonpayment), if You breach any of the terms or conditions of
                these Terms of Use. You acknowledge that HealthDBI reserves the
                right to terminate accounts that are inactive for an extended
                period of time and the right to modify or discontinue,
                temporarily or permanently, the Services or the Product. Upon
                the expiration or earlier termination of these Terms of Use,
                Your rights to use the Product and the Data shall terminate, You
                shall cease use of the Product and the Data, and You shall,
                within seven (7) days of the date of expiration or earlier
                termination, destroy all Data that is stored on Your information
                technology systems or that is otherwise within Your possession
                or control and will confirm in writing that You have complied
                with these obligations.
              </li>
              <li>
                <b>Survival</b> All sections of these Terms of Use which by
                their nature should survive termination will survive
                termination, including, without limitation, accrued rights to
                payment, confidentiality obligations, warranty disclaimers, and
                limitations of liability.
              </li>
            </ol>
          </li>
          <li>
            <b>Compliance with Laws</b>
            <ol>
              <li>
                <b>Obligations</b> You must comply with all applicable federal,
                state and local laws, rules, regulations, and ordinances
                relating to telemarketing, data privacy, consumer protection,
                and any others laws that may relate to Your use of the Services,
                Product, and Data, including, without limitation, the CAN-SPAM
                Act, the Telephone Consumer Protection Act, the General Data
                Protection Regulation of the European Union, and the California
                Consumer Privacy Act. You shall not, and shall not permit any
                third party to, license, sell, rent, lease, transfer, assign,
                distribute to any third party, or commercially exploit the Data,
                in whole or in part, or use the Data in connection with credit
                granting, credit monitoring, account review, collections,
                insurance underwriting, employment or any other purpose covered
                by the Fair Credit Reporting Act (“FCRA”), interpretations of
                the FCRA by the Federal Trade Commission, or by similar federal
                and state statutes.
              </li>
              <li>
                <b>Indemnification</b> You agree that You will indemnify, defend
                and hold HealthDBI harmless against any losses resulting from
                any third party claim against HealthDBI arising out of or in
                connection with any violation of any applicable law by You.
              </li>
            </ol>
          </li>
          <li>
            <b>Disclaimers; Limitation of Liability</b>
            <ol>
              <li>
                <b>Non-Reliance</b> The Data and the information presented on or
                through the Website, the Services, or the Product is made
                available solely for general information purposes. We do not
                warrant the accuracy, completeness, or usefulness of this Data
                or information. Any reliance You place on such Data information
                is strictly at Your own risk. We disclaim all liability and
                responsibility arising from any reliance placed on such Data or
                information by You or any other visitor to the Website or
                Product user. We do not represent or warrant that You will
                obtain any minimum number of leads as a result of use of the
                Product or Data.
              </li>
              <li>
                <b>Disclaimer</b> EXCEPT AS EXPRESSLY SET FORTH HEREIN,
                HealthDBI, FOR ITSELF AND ITS LICENSORS, MAKES NO EXPRESS,
                IMPLIED OR STATUTORY REPRESENTATIONS, WARRANTIES, OR GUARANTEES
                IN CONNECTION WITH THE WEBSITE, THE SERVICES, THE PRODUCT, OR
                ANY THIRD PARTY CONTENT, RELATING TO THE QUALITY, SUITABILITY,
                TRUTH, ACCURACY OR COMPLETENESS OF ANY INFORMATION, CONTENT, OR
                OTHER MATERIAL CONTAINED OR PRESENTED ON THE WEBSITE, THE
                PRODUCT, THE SERVICES OR THE DATA. UNLESS OTHERWISE EXPLICITLY
                STATED, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
                WEBSITE, THE SERVICES, THE PRODUCT, THE DATA, THE THIRD PARTY
                CONTENT, AND ANY OTHER INFORMATION, CONTENT OR MATERIAL
                CONTAINED OR PRESENTED ON THE WEBSITE IS PROVIDED TO YOU ON AN
                “AS IS,” “AS AVAILABLE” AND “WHERE-IS” BASIS WITH NO WARRANTY OF
                IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. HealthDBI
                DOES NOT PROVIDE ANY WARRANTIES AGAINST VIRUSES, SPYWARE OR
                MALWARE THAT MAY BE INSTALLED ON YOUR COMPUTER
              </li>
              <li>
                <b>Exclusion of Damages</b> TO THE FULLEST EXTENT PROVIDED BY
                LAW, IN NO EVENT WILL HealthDBI, ITS AFFILIATES, OR THEIR
                LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
                DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
                THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
                INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY
                CONTENT ON THE WEBSITE, THE SERVICES, THE PRODUCT OR DATA,
                INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
                OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
              </li>
              <li>
                <b>Aggregate Liability</b> TO THE FULLEST EXTENT PROVIDED BY
                LAW, IN NO EVENT WILL THE AGGREGATE LIABILITY OF HealthDBI, ITS
                AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
                AGENTS, OFFICERS, OR DIRECTORS ARISING OUT OF OR RELATED TO
                THESE TERMS OF USE, WHETHER ARISING UNDER OR RELATED TO BREACH
                OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR
                ANY OTHER LEGAL OR EQUITABLE THEORY, EXCEED THE GREATER OF ONE
                HUNDRED DOLLARS ($100.00) AND THE TOTAL AMOUNTS PAID BY YOU TO
                US UNDER THESE TERMS OF USE IN THE TWELVE (12) MONTH PERIOD
                PRECEDING THE EVENT GIVING RISE TO THE CLAIM. THE FOREGOING
                LIMITATIONS APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL
                PURPOSE
              </li>
            </ol>
          </li>
          <li>
            <b>Indemnification</b>
            <ol>
              <li>
                <b>Indemnification by You</b>
                You agree to defend, indemnify, and hold harmless HealthDBI, its
                affiliates, licensors, and service providers, and its and their
                respective officers, directors, employees, contractors, agents,
                licensors, suppliers, successors, and assigns from and against
                any claims, liabilities, damages, judgments, awards, losses,
                costs, expenses, or fees (including reasonable attorneys’ fees)
                arising out of or relating to: (i) Your violation of these Terms
                of Use or Your use of the Product or the Data, including, but
                not limited to any use of the Product’s content, services, and
                products other than as expressly authorized in these Terms of
                Use or Your use of any information obtained from the Product;
                (ii) allegation of facts that, if true, would constitute Your
                breach of any of Your representations, warranties, covenants, or
                obligations under this Agreement; (iii) gross negligence or
                willful misconduct by You or any Authorized User; (iv) any
                violation of any applicable law by You; (v) access to or use of
                the Product by You or any Authorized User in combination with
                any hardware, system, software, network, or other materials or
                service not provided by HealthDBI or specified for Your use in
                the Documentation; or (vi) modification of the Product other
                than by or on behalf of HealthDBI or with HealthDBI’s prior
                written approval.
              </li>
            </ol>
          </li>
          <li>
            <b>Dispute Resolution.</b>
            <ol>
              <b>
                PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT
                YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN
                COURT. THIS SECTION 8, INCLUDING THE PROVISIONS ON BINDING
                ARBITRATION AND CLASS ACTION WAIVER, SHALL SURVIVE ANY
                TERMINATION OR EXPIRATION OF THIS AGREEMENT.
              </b>
              <li>
                <b>Governing Law</b> All matters relating to the Website, the
                Services, the Product, Data and these Terms of Use and any
                dispute or claim arising therefrom or related thereto shall be
                governed by and construed in accordance with the laws of the
                State of California without giving effect to any choice or
                conflict of law provision or rule.
              </li>
              <li>
                <b>Federal Arbitration Act</b> Certain portions of this Section
                are deemed to be a “written agreement to arbitrate” pursuant to
                the Federal Arbitration Act (“FAA”). You and HealthDBI expressly
                agree and intend that this Section satisfies the “writing”
                requirement of the FAA. This Section can only be amended by
                mutual agreement.
              </li>
              <li>
                <b>Binding Arbitration</b> YOU AND HealthDBI AGREE THAT ANY
                DISPUTE, CONTROVERSY, OR CLAIM ARISING OUT OF, OR RELATING TO
                YOUR USE OF THE SERVICES, PRODUCT, AND/OR DATA UNDER THIS
                AGREEMENT SHALL BE RESOLVED ONLY BY FINAL AND BINDING
                ARBITRATION, except that (1) You may assert claims in small
                claims court within the scope of that court’s jurisdiction; and
                (2) this agreement to arbitrate does not limit Your or
                HealthDBI’s right to seek injunctive or other equitable relief
                in state or federal court in Los Angeles County, California to
                prevent the actual or threatened infringement, misappropriation
                or violation of a party’s copyrights, trademarks, trade secrets,
                patents, or other intellectual property rights. To begin an
                arbitration proceeding, You must send a letter requesting
                arbitration and describing Your claim to HealthDBI at 340 S
                Lemon Ave, Ste 1022, Walnut, CA 91789. The arbitration will be
                conducted by JAMS in accordance with the JAMS Streamlined
                Arbitration Procedure Rules in effect at the time the
                arbitration is initiated, excluding any rules or procedures
                governing or permitting class actions. Payment of all filing,
                administration and arbitrator fees will be governed by JAMS’
                rules. You can obtain JAMS’ procedures, rules, and fee
                information as follows: JAMS: 800.352.5267 and
                http://www.jamsadr.com. The arbitration will be conducted in the
                English language; the location of such arbitration shall be in
                Los Angeles County, California. Each party will bear its own
                costs in the arbitration. The arbitrator will have full power
                and authority to determine issues of arbitrability and to
                interpret or construe the provisions of the agreement documents
                and to fashion appropriate remedies (including temporary,
                preliminary, interim, or permanent injunctive relief); provided
                that the arbitrator will not have any right or authority: (i) in
                excess of the authority that a court having jurisdiction over
                the parties and the dispute would have absent this arbitration
                agreement; or (ii) to award damages in excess of the types and
                limitation of damages found in the Agreement. Judgment upon the
                award may be entered in any court of competent jurisdiction.
              </li>
              <li>
                <b>Class Action Waiver</b> YOU AND HealthDBI EACH AGREE THAT ANY
                DISPUTE RESOLUTION PROCEEDING WILL BE CONDUCTED ONLY ON AN
                INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR
                REPRESENTATIVE ACTION. You and HealthDBI each agree that such
                proceeding shall take solely by means of judicial reference
                pursuant to California Code of Civil Procedure Section 638
              </li>
            </ol>
          </li>
          <li>
            <b>General</b>
            <p>
              If You violate these Terms of Use, HealthDBI may terminate and/or
              suspend Your access to this Website without notice. HealthDBI
              prefers to advise You of Your inappropriate behavior and to
              recommend any necessary corrective action. However, certain
              violations of these Terms of Use, as determined by HealthDBI, may
              result in immediate termination. If any of these Terms of Use is
              found to be inconsistent with applicable law, then such term shall
              be interpreted to reflect the intentions of the parties, and no
              other terms will be modified. HealthDBI’s failure to enforce any
              of these Terms of Use is not a waiver of such term. These Terms of
              Use are the entire agreement between You and HealthDBI and
              supersede all prior or contemporaneous negotiations, discussions
              or agreements between You and HealthDBI about the Website and/or
              the Product. You shall not assign or otherwise transfer any of
              Your rights, or delegate or otherwise transfer any of Your
              obligations or performance under these Terms of Use without our
              prior written consent.
            </p>
            <p>
              By using the Website and/or the Services, You consent to receiving
              electronic communications from us. These electronic communications
              may include, but are not limited to, notices about the applicable
              Services, Your Subscription Plan, Fees, and transactional or other
              information concerning or related to the Website and/or the
              Services. These electronic communications are part of your
              relationship with Us and You receive them as part of your use of
              the Website and/or the Services. You agree that any notices,
              agreements, disclosures or other communications that we send You
              electronically will satisfy any legal communication requirements,
              including that such communications be in writing.
            </p>
            <p>
              In no event will We be liable or responsible to You, or be deemed
              to have defaulted under or breached these Terms of Use, for any
              failure or delay in fulfilling or performing any term of these
              Terms of Use, when and to the extent such failure or delay is
              caused by any circumstances beyond our reasonable control (a
              “Force Majeure Event”), including acts of God, flood, fire,
              earthquake or explosion, war, terrorism, invasion, riot or other
              civil unrest, embargoes or blockades in effect on or after the
              date of these Terms of Use, national or regional emergency,
              strikes, labor stoppages or slowdowns or other industrial
              disturbances, passage of law or any action taken by a governmental
              or public authority, including imposing an embargo, export or
              import restriction, quota, or other restriction or prohibition or
              any complete or partial government shutdown, or national or
              regional shortage of adequate power or telecommunications or
              transportation. We may terminate this Agreement if a Force Majeure
              Event continues substantially uninterrupted for a period of sixty
              (60) days or more.
            </p>
            <p>
              If You have any questions, comments, or concerns regarding these
              Terms of Use, please contact us at{" "}
              <a href="mailto:Team@HealthDBI.com">Team@HealthDBI.com</a>.
            </p>
          </li>
          <li>
            <b>Notice for California Users</b>
            <p>
              If you are a California resident, you may have these Terms of Use
              mailed to you electronically by sending a letter to
              <a href="mailto:Team@HealthDBI.com">Team@HealthDBI.com</a> with
              your email address and a request for the Terms of Use and any
              linked terms. In addition, under California Civil Code Section
              1789.3, you may contact the Complaint Assistance Unit of the
              Division of Consumer Service(s) of the California Department of
              Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202,
              Sacramento, California 95834, or by telephone at 1-800-952-5210 in
              order to resolve a complaint regarding the Products or to receive
              further information regarding use of the Products.
            </p>
          </li>
        </ol>
      </div>
    </Container>
  );
};

export default TermsConditions;
