import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { REVOKE_CREDITS } from "../Context/Types";
import { NotificationManager } from "react-notifications";

const RevokeCredit = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [credits, setCredits] = useState();
  const { dispatch } = useContext(AuthContext);
  const handleSubmit = () => {
    if (!credits) {
      NotificationManager.error("Enter number of Credits");
      return;
    }
    if (credits <= 0) {
      NotificationManager.error("Credits should be greater than 0");
      return;
    }

    dispatch({
      type: REVOKE_CREDITS,
      id: props.selectedTeamMember._id,
      value: credits,
      updateState: props.setRows,
      setIsLoading: setIsLoading,
    });
  };
  return (
    <div>
      <div className=" w-100 d-flex flex-column mylist-Update">
        <div>
          <TextField
            id="outlined-basic"
            size="small"
            className="user-input-fild w-100"
            label="Assign Credit"
            name="Assign Credit"
            value={credits}
            variant="outlined"
            onChange={(e) => setCredits(e.target.value)}
          />
        </div>
      </div>
      <div class="m-0 d-flex justify-content-end">
        <Button
          variant="contained"
          className="m-2 p-2 mr-0"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress sx={{ fontSize: "0.2rem", color: "white" }} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </div>
  );
};

export default RevokeCredit;
