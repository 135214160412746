/** @format */

import { Button } from '@mui/material';
import React, { memo, useContext, useState } from 'react';
import { MyListTable } from '../MyList/MyListTable';

import Downloads from './MyListCompo/Downloads';
import CSVs from './MyListCompo/CSVs';
import SaveSearch from './MyListCompo/SaveSearch';
import Unlocks from './MyListCompo/Unlocks';
import DownloadQueues from './MyListCompo/DownloadQueue';

const MyList = () => {
	// const [model, setmodel] = useState("");
	const [titlebtn, settitlebtn] = useState('');
	const [filterToggle, setfilterToggle] = useState(true);

	const handleClickFilter = () => {
		return setfilterToggle(!filterToggle);
	};
	// const handlemodel = () => {
	//     setmodel("");
	// }
	return (
		// <Filter hideShow={filterToggle} hideShowClick={handleClickFilter}>

		<div className='home_wrapper_new'>
			<div className='d-flex justify-content-end  pb-0 pt-0  hadbar-btn table-wrapper'>
				<Button
					variant={`${titlebtn == 'download' ? 'contained' : 'outlined'}`}
					className='mx-0 flex-1 button-highlight word-break f-16'
					onClick={() => {
						settitlebtn('download');
					}}
				>
					Downloads
				</Button>
				<Button
					variant={`${titlebtn == 'download queue' ? 'contained' : 'outlined'}`}
					className='mx-0 flex-1 button-highlight word-break f-14'
					onClick={() => {
						settitlebtn('download queue');
					}}
				>
					Download Queues
				</Button>
				<Button
					variant={`${titlebtn == 'csvs' ? 'contained' : 'outlined'}`}
					className='mx-0 flex-1 button-highlight f-16'
					onClick={() => {
						settitlebtn('csvs');
					}}
				>
					Upload CSVs
				</Button>
				<Button
					variant={`${titlebtn == 'savesearch' ? 'contained' : 'outlined'}`}
					className='mx-0 flex-1 button-highlight f-16'
					onClick={() => {
						settitlebtn('savesearch');
					}}
				>
					Save Search
				</Button>
				<Button
					variant={`${titlebtn == 'unlocks' ? 'contained' : 'outlined'}`}
					className='mx-0 flex-1 button-highlight f-16'
					onClick={() => {
						settitlebtn('unlocks');
					}}
				>
					Unlocks
				</Button>

				<Button
					variant={`${titlebtn == '' ? 'contained' : 'outlined'}`}
					className='mx-0 button-highlight f-16'
					onClick={() => {
						settitlebtn('');
					}}
				>
					List
				</Button>
			</div>
			<div className='table-wrapper  card'>
				{'download' == titlebtn && <Downloads />}
				{'download queue' == titlebtn && <DownloadQueues />}
				{'csvs' == titlebtn && <CSVs />}
				{'savesearch' == titlebtn && <SaveSearch />}
				{'unlocks' == titlebtn && <Unlocks />}
				{'' == titlebtn && <MyListTable />}
			</div>
		</div>
		// </Filter>
	);
};

export default memo(MyList);
