/** @format */

import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import {
	DOWNLOAD_LEADS,
	DOWNLOAD_LEADS_SPECIFIC,
	FILTER_CONTACTS,
} from '../Context/Types';
import { AuthContext } from '../Context/AuthContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { NotificationManager } from 'react-notifications';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useAuth from '../../hooks/useAuth';
import ConfirmDownloadModal from './ConfirmDownloadModal';
export const DownloadPopUpModel = ({ setDownload }) => {
	const {
		dispatch,
		numOfRecordsFound,
		selectedLeads,
		setFirstTimeApiCall,
		credits,
		setFirstTimeFilter,
		anyFilterFlag,
	} = useContext(AuthContext);
	const { auth } = useAuth();
	const role = auth?.role;
	const [value, setValue] = React.useState('selected');
	const [isVerify, setIsVerify] = React.useState('false');
	const [numOfLeads, setNumOfLeads] = useState(1);
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [fileName, setFileName] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [isModalShow, setIsModalShow] = useState(false);

	const handleDownload = () => {
		setIsModalShow(false);
		dispatch({
			type: DOWNLOAD_LEADS_SPECIFIC,
			numberOfLeads: numOfLeads,
			verifyAll: isVerify === 'false' ? false : true,
			setIsLoading: setIsLoading,
			setDownload,
			getAllContacts,
			fileName,
			setFileName,
			verified: isVerify === 'noVer' ? false : true,
		});
	};
	const handleClick = () => {
		if (fileName.length > 0) {
			setNameError(false);
			let payload;
			if (isError) return;
			if (
				value === 'selected' &&
				(!selectedLeads || selectedLeads.length === 0)
			) {
				NotificationManager.error('Atleast select one lead ');
				return;
			}
			if (
				value === 'specific' &&
				+numOfLeads > +credits &&
				role !== 'admin' &&
				role !== 'subadmin'
			) {
				NotificationManager.error('Not Enough Credits!');
				return;
			}
			if (
				value === 'selected' &&
				+numOfLeads > +credits &&
				role !== 'admin' &&
				role !== 'subadmin'
			) {
				NotificationManager.error('Not Enough Credits!');
				return;
			}
			if (value === 'selected') {
				dispatch({
					type: DOWNLOAD_LEADS,
					payload: {
						leads_ids: selectedLeads,
						verifyAll: isVerify === 'false' ? false : true,
						downloadName: fileName,
						isVerify: isVerify === 'noVer' ? false : true,
					},
					setIsLoading: setIsLoading,
					setDownload,
				});
			} else if (value === 'specific') {
				setIsModalShow(true);
				setDownload(false);
			} else if (value === 'all') {
				dispatch({
					type: DOWNLOAD_LEADS_SPECIFIC,
					numberOfLeads:
						auth?.role === 'admin' || auth?.role === 'subadmin' ? 25 : credits,
					verifyAll: isVerify === 'false' ? false : true,
					setIsLoading: setIsLoading,
					setDownload,
					getAllContacts,
					fileName,
					setFileName,
					verified: isVerify === 'noVer' ? false : true,
				});
			}
		} else {
			setNameError(true);
		}
	};
	const handleChange = (e) => {
		const val = e.target.value;
		setNumOfLeads(val);
		if (val <= 0 || val > numOfRecordsFound) {
			setIsError(true);
			setErrorMsg('Enter valid number of contacts');
			return;
		} else if (
			Number(credits) < Number(val) &&
			role !== 'admin' &&
			role !== 'subadmin'
		) {
			setIsError(true);
			setErrorMsg("Don't have enough credits!");
			return;
		} else {
			setIsError(false);
		}
	};

	const getAllContacts = () => {
		setFirstTimeApiCall(false);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		dispatch({
			type: FILTER_CONTACTS,
		});
		anyFilterFlag.current = true;
	};

	return (
		<div className='p-3 font-small'>
			<div className=' mb-3'>
				<div>
					<FormControl>
						<FormLabel
							id='demo-controlled-radio-buttons-group'
							style={{ color: '#1976d2' }}
						>
							Select option for download
						</FormLabel>
						<RadioGroup
							aria-labelledby='demo-controlled-radio-buttons-group'
							name='controlled-radio-buttons-group'
							value={value}
							defaultValue='selected'
							onChange={(e) => {
								setValue(e.target.value);
							}}
						>
							<FormControlLabel
								value='specific'
								componentsProps={{ typography: { variant: 'small' } }}
								control={<Radio size='small' />}
								label='Enter # of Contacts'
							/>
							{value === 'specific' ? (
								<TextField
									fullWidth
									error={isError}
									value={numOfLeads}
									onChange={handleChange}
									helperText={isError ? errorMsg : ' '}
									type='number'
									id='outlined-basic'
									size='small'
									className='user-input-fild w-100'
									label='Number of Contacts'
									variant='outlined'
								/>
							) : (
								''
							)}
							<FormControlLabel
								value='selected'
								componentsProps={{ typography: { variant: 'small' } }}
								control={<Radio size='small' />}
								label='Only Selected Contacts'
							/>
							<FormControlLabel
								componentsProps={{ typography: { variant: 'small' } }}
								value='all'
								control={<Radio />}
								label='All Contacts'
							/>
						</RadioGroup>
					</FormControl>
				</div>
				<div>
					<FormControl>
						<FormLabel
							id='demo-controlled-radio-buttons-group-temp'
							style={{ color: '#1891EB' }}
						>
							Select type of verification
						</FormLabel>
						<RadioGroup
							row
							aria-labelledby='demo-controlled-radio-buttons-group-temp'
							name='controlled-radio-buttons-group'
							value={isVerify}
							defaultValue='false'
							onChange={(e) => {
								setIsVerify(e.target.value);
							}}
						>
							<FormControlLabel
								value='true'
								control={<Radio size='small' />}
								componentsProps={{ typography: { variant: 'small' } }}
								label='Only Valid Emails'
							/>
							<FormControlLabel
								value='false'
								componentsProps={{ typography: { variant: 'small' } }}
								control={<Radio size='small' />}
								label='Valid + Accept All'
							/>
						</RadioGroup>

						{(role === 'admin' || role === 'subadmin') && (
							<RadioGroup
								row
								aria-labelledby='demo-controlled-radio-buttons-group-temp'
								name='controlled-radio-buttons-group'
								value={isVerify}
								onChange={(e) => {
									setIsVerify(e.target.value);
								}}
							>
								<FormControlLabel
									value='noVer'
									componentsProps={{ typography: { variant: 'small' } }}
									control={<Radio size='small' />}
									label='No Verification'
								/>
							</RadioGroup>
						)}
					</FormControl>
				</div>
				<TextField
					fullWidth
					value={fileName}
					error={nameError}
					onChange={(e) => setFileName(e.target.value)}
					type='text'
					id='outlined-basic'
					size='small'
					className='user-input-fild w-100 f-14'
					label='Name of File'
					variant='outlined'
				/>
			</div>
			<div className='m-0'>
				<Button
					disabled={!isVerify && !value}
					variant='contained'
					style={{
						height: '38px',
						boxShadow: 'none',
						backgroundColor: '#1891EB',
					}}
					size='small'
					endIcon={<ArrowDownwardIcon size='small' />}
					className='d-flex justify-content-center m-auto '
					onClick={() => {
						handleClick();
					}}
				>
					{isLoading ? (
						<CircularProgress sx={{ color: 'white' }} size={15} />
					) : (
						<small>Download</small>
					)}
				</Button>
			</div>
			<ConfirmDownloadModal
				open={isModalShow}
				close={() => {
					setIsModalShow(false);
				}}
				title='Download Confirmation'
				handleDownload={handleDownload}
			/>
		</div>
	);
};
