import React, { useContext, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import {
  LOCATION_CITY,
  LOCATION_COUNTRY,
  LOCATION_STATE,
  LOCATION_ZIP_CODE,
  SPECIALITY_SPECIALITY_TYPE,
  TITLE,
} from "../filterTypes";
import Select from "react-select";
import { MainContext } from "../../../../componants/filterContext/FilterContext";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { TITLEABB } from "../../../../Data/TitleAbb";
import { FILTER_CONTACTS } from "../../../../componants/Context/Types";
import debounce from "debounce-promise";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Title() {
  const [expanded, setExpanded] = React.useState(false);
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);



  const {
    globalSelectFilter,
    setGlobalSelectFilter,
    globalFilterKeyToApi,
    globalFilterKeyTofilterKey,
  } = useContext(MainContext);

  const {
    setFilter,
    filter,
    setFirstTimeFilter,
    setFirstTimeApiCall,
    setSortField,
    dispatch,
    anyFilterFlag,
    titleInput,
    setTitleInput,
    selectedFilter,
  } = useContext(AuthContext);

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setIsOpen(newExpanded)
    setJobTitleOptions(
      jobTitleOptions?.filter((item) => item?.label?.length !== 0)
    );
  };

  const loadOptions = debounce((searchValue) => {
    const getData = async () => {
      const abb = TITLEABB[searchValue.toUpperCase()];
      const data = await globalFilterKeyToApi[TITLE](abb || searchValue);
      const options = data.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setJobTitleOptions(options);
      return options;
    };
    return getData();
  }, 1000);

  const handleChangeMultiSelect = (selectedOption) => {
    const formatedOption = selectedOption.map((item) => item.value);
    setGlobalSelectFilter((prev) => {
      return { ...prev, [expanded]: selectedOption };
    });
    setFirstTimeFilter({
      filterCount: 0,
      isFilterCall: false,
    });
    setSortField({});
    setFirstTimeApiCall(false);
    setFilter((prev) => {
      return {
        ...prev,
        [globalFilterKeyTofilterKey[expanded]]: formatedOption,
      };
    });
  };

      const keyDownHandler = (e) => {
        console.log(e);
        if (e.code === "Enter") {
          setFirstTimeApiCall(false);
          setFirstTimeFilter({
            filterCount: 0,
            isFilterCall: false,
          });
          anyFilterFlag.current = true;
          dispatch({
            type: FILTER_CONTACTS,
          });
        }
      };

          useEffect(() => {
            loadOptions("");
          }, []);

            useEffect(() => {
              console.log(selectedFilter);
              if (selectedFilter === "Title") {
                setIsOpen(true);
                setExpanded(TITLE);
              }
            }, [selectedFilter]);
  return (
    <div>
      <Accordion
        expanded={expanded === TITLE}
        onChange={handleChangeAccordian(TITLE)}
      >
        <AccordionSummary>
          <Typography className="f-16">Job Title</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            isMulti
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            menuIsOpen={isOpen}
            createOptionPosition="first"
            isClearable={false}
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
              placeholder: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
            }}
            closeMenuOnSelect={false}
            isSearchable={true}
            value={globalSelectFilter[TITLE]}
            // loadOptions={loadOptions}
            onChange={handleChangeMultiSelect}
            onKeyDown={keyDownHandler}
            defaultOptions={jobTitleOptions}
            options={jobTitleOptions}
            onInputChange={(value, action) => {
              if (action.action === "input-change") {
                loadOptions(value);
              }
            }}
            // options={jobTitleOptions}
            // defaultOptions={true}
            // onInputChange={(value, action) => {
            //   if (action.action === "input-change") setTitleInput(value);
            // }}
            // inputValue={titleInput}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
