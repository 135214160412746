import NotificationManager from "react-notifications/lib/NotificationManager";
import { saveSearchFilter, getSaveSearch, deleteSavedSearch } from "../APIs";
import moment from "moment";

export const saveSearchFilterReducer = async (
  values,
  setIsLoading,
  closeModal
) => {
  setIsLoading(true);
  try {
    const res = await saveSearchFilter(values);
    NotificationManager.success(res.data);
    closeModal();
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getSaveSearchReducer = async (
  updateState,
  setIsLoading,
  dataType
) => {
  setIsLoading(true);
  try {
    const res = await getSaveSearch(dataType);
    const formatedData = res.data.map((item) => {
      return {
        ...item,
        createdAt: moment(item.createdAt).format("MMMM Do YYYY"),
      };
    });
    updateState(formatedData);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const deleteSavedSearchReducer = async (
  id,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await deleteSavedSearch(id);
    NotificationManager.success(res.data);
    getSaveSearchReducer(updateState, setIsLoading);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
