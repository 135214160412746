import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import img from "../../../assests/image/Forgotpassword.jpg";
import { useContext, useState } from "react";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "../../../componants/Context/AuthContext";
import { FORGET_PASSWORD } from "../../../componants/Context/Types";

const PasswordChange = () => {
  const { dispatch } = useContext(AuthContext);
  const [checkeditprofile, setcheckeditprofile] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
  };
  const validate = Yup.object().shape({
    email: Yup.string()
      .email("This is invalid email")
      .required("This is required"),
    //   .matches(/^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/,"Must Be a Official Company Email"),
  });
  const handleSubmit = (values) => {
    dispatch({
      type: FORGET_PASSWORD,
      payload: values,
      setIsLoading: setIsLoading,
    });
  };
  return (
    <>
      <div className="login-page">
        <div className="img-content">
          <img src={img} className="login-img" />
        </div>
        <div className="sighnUp-content">
          <h2>Forgot Password</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
              // alert("Form is validated! Submitting the form...");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
            }) => (
              <Form>
                <div className="login-input">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    value={values.email}
                    variant="outlined"
                    type="text"
                    name="email"
                    className="w-75 mb-3 mt-3"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* error validated by Yup */}
                  <div className="error-container mb-2">
                    <div className="error">
                      {errors["email"] && touched["email"] && errors["email"]}
                    </div>
                  </div>
                </div>

                <div>
                  <button className="login-btn mb-2" type="submit">
                    {isLoading ? (
                      <CircularProgress sx={{ color: "white" }} size={30} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default PasswordChange;
