import React, { useContext, useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import { Button } from "@mui/material";
import FlageButton from "../../../../componants/comman/FlageButton";
import PopUpModel from "../../../../componants/comman/PopUpModel";
import TextField from "@mui/material/TextField";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { GET_COMPANY_BY_TYPE } from "../../../../componants/Context/Types";
import CircularProgress from "@mui/material/CircularProgress";

const CompanyTable = (props) => {
  const { dispatch } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch({
      type: GET_COMPANY_BY_TYPE,
      searchParams: { type: props.currCompanyType.value },
      updateState: {setRows},
      setIsLoading: setIsLoading,
    });
  }, [props.currCompanyType]);
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className="bg-light">
            <TableCell align="center">Company Name</TableCell>
            <TableCell align="center">User Name</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Mobile</TableCell>
            <TableCell align="center">Subscription Type</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <CircularProgress />
          ) : (
            rows.map((row) => (
              <TableRow
                key={row?._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" className="p-2 ">
                  {row.company_name}
                </TableCell>
                <TableCell align="center" className="p-2">
                  {row.name}
                </TableCell>
                <TableCell align="center" className="p-2">
                  {row.email}
                </TableCell>
                <TableCell align="center" className="p-2">
                  {row.mobile}
                </TableCell>
                <TableCell
                  align="center"
                  className="p-2 d-flex justify-content-center"
                >
                  {
                    <FlageButton
                      color={""}
                      title={props.currCompanyType?.value}
                    />
                  }
                </TableCell>
                {/* <TableCell align="center" className="p-2">
                {row.UserID}
              </TableCell> */}
                {/* <TableCell
                align="center"
                className="p-2 d-flex justify-content-center"
              >
                {
                  <FlageButton
                    color={row.Status == "active" ? "green" : "red"}
                    title={row.Status}
                  />
                }
              </TableCell> */}
                <TableCell align="center" className="p-2 icon-fill">
                  {/* <EditIcon className='pointer edit-fill mx-2'onClick={ ()=>{openModel(row)}}/> */}
                  {/* <Tippy
                content={
                  <div className='TippyAction bg-light '>
                    <div className=' p-2 pointer'>Are you sure you want to Delete.</div>
                    <div>
                      <Button variant="contained" className='m-2 bg-success' onClick={()=>{//("Deleted")}}>Yes</Button>
                      <Button variant="contained" className='m-2 bg-danger'>No</Button>
                    </div>
                  </div>
                }
                  placement='bottom-end'
                  arrow={false}
                  offset={[15,1]}
                  trigger='click'
                  interactive={ true}
                  className='confirmation-model'
              >
              <DeleteIcon className='pointer delete-fill'/></Tippy> */}
                  <RemoveRedEyeIcon
                    onClick={() => {
                      props.setflag("company");
                      props.setTitle("User info");
                      props.setSelectedCompanyByAdmin(row?._id)
                    }}
                    className="eye-Icon pointer mx-2"
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      {/* <PopUpModel
        open={List}
        title={"Edit Details"}
        close={() => {
          setList(false);
        }}
      >
        <div className=" w-100 d-flex flex-column mylist-Update">
          <div>
            <TextField
              id="outlined-basic"
              size="small"
              value={List.User}
              className="user-input-fild w-100"
              label="Name"
              name="ListName"
              variant="outlined"
              onChange={updatevalue}
            />
          </div>
        </div>
        <div className="m-0 d-flex justify-content-end">
          <Button
            variant="contained"
            className="m-2 p-2 mr-0"
            onClick={() => {
              setList(false);
              NotificationManager.success("Data Updated");
            }}
          >
            Save
          </Button>
        </div>
      </PopUpModel> */}
      {/* <EditCompany open={List} close={()=>{setList(false)}} title={"Edit Company Details"} buttonname={"Submit"} />  */}
    </TableContainer>
  );
};

export default CompanyTable;
