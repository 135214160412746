import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { CREATE_SUB_ADMIN, EDIT_SUB_ADMIN } from "../Context/Types";
import Select from "react-select";
import _ from "lodash";
import { NotificationManager } from "react-notifications";

const AddSubAdminModel = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    props?.selectedRow?.access_tabs || []
  );
  const { dispatch, adminTabs } = useContext(AuthContext);
  const [info, setInfo] = useState({
    name: props.selectedRow?.name,
    email: props.selectedRow?.email,
    password: "",
  });

  useEffect(() => {
    return () => {
      props.setSelectedRow(null);
    };
  }, []);

  const handleSubmit = () => {
    // if (info.password) {
    //   //("Enter number of Credits");
    //   return;
    // }
    // if (credits <= 0) {
    //   //("Credits should be greater than 0");
    //   return;
    // }
    if (props.selectedRow) {
      let values = {
        ...info,
        access_tabs: selectedOption.map((item) => item.value),
      };
      if (!info.password) {
        values = _.omit(values, ["password"]);
      }
      dispatch({
        type: EDIT_SUB_ADMIN,
        id: props.selectedRow._id,
        values: values,
        setIsModal: props.setIsModal,
        updateState: props.updateState,
        setIsLoading: setIsLoading,
      });
    } else {
      dispatch({
        type: CREATE_SUB_ADMIN,
        values: {
          ...info,
          access_tabs: selectedOption.map((item) => item.value),
        },
        setIsModal: props.setIsModal,
        updateState: props.updateState,
        setIsLoading: setIsLoading,
      });
    }
  };
  return (
    <div>
      <div className=" w-100 d-flex flex-column mylist-Update">
        <div>
          <TextField
            id="outlined-basic"
            size="small"
            className="user-input-fild w-35 m-2"
            label="Name"
            name="Name"
            value={info.name}
            variant="outlined"
            onChange={(e) =>
              setInfo((prev) => {
                return { ...prev, name: e.target.value };
              })
            }
          />
          <TextField
            id="outlined-basic"
            size="small"
            className="user-input-fild w-35 m-2"
            label="Email"
            name="Email"
            value={info.email}
            variant="outlined"
            onChange={(e) =>
              setInfo((prev) => {
                return { ...prev, email: e.target.value };
              })
            }
          />
          <TextField
            id="outlined-basic"
            size="small"
            className="user-input-fild w-35 m-2"
            label="Password"
            name="Password"
            helperText={
              props.selectedRow ? "Enter password to change existing one" : ""
            }
            value={info.password}
            variant="outlined"
            onChange={(e) =>
              setInfo((prev) => {
                return { ...prev, password: e.target.value };
              })
            }
          />
          <Select
            onChange={setSelectedOption}
            options={adminTabs}
            className="basic-multi-select w-100 m-2"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            isMulti
            defaultValue={selectedOption}
            placeholder="Tabs"
            required
          />
        </div>
      </div>
      <div className="m-0 d-flex justify-content-end">
        <Button
          variant="contained"
          className="m-2 p-2 mr-0"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress sx={{ fontSize: "0.2rem", color: "white" }} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddSubAdminModel;
