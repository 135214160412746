import { getTransactions, getCompanyTransactions } from "../APIs";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";

export const getTransactionsReducer = async (
  updateState,
  setIsLoading,
  setallData
) => {
  setIsLoading(true);
  try {
    const res = await getTransactions();
    const formatedData = res.data.map((item) => {
      return {
        ...item,
        subscription_end_date: moment(item.subscription_end_date).format(
          "MMMM Do YYYY"
        ),
        createdAt: moment(item.createdAt).format("MMMM Do YYYY"),
        subscription_amount: `$${item.subscription_amount}`,
      };
    });
    updateState(formatedData);
    setallData(formatedData);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getCompanyTransactionsReducer = async (
  id,
  updateState,
  setIsLoading,
  setallData
) => {
  setIsLoading(true);
  try {
    const res = await getCompanyTransactions(id);
    const formatedData = res.data.map((item) => {
      return {
        ...item,
        subscription_end_date: moment(item.subscription_end_date).format(
          "MMMM Do YYYY"
        ),
        createdAt: moment(item.createdAt).format("MMMM Do YYYY"),
        subscription_amount: `$${item.subscription_amount}`,
      };
    });
    updateState(formatedData);
    setallData(formatedData);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
