/** @format */
import { useState, useContext, useEffect } from 'react';
import Navbar from '../../Elements/Navbar/Navbar';
import {
	BUY_PLAN,
	GET_ALL_SUBSCRIPTION_PLAN,
} from '../../componants/Context/Types';
import { AuthContext } from '../../componants/Context/AuthContext';

const Plans = () => {
	const [planType, setPlanType] = useState(true);
	const { dispatch, currentPlan, typeOfUser } = useContext(AuthContext);
	const isExpired =
		currentPlan?.subscription_end_date < new Date().toISOString();
	// const isExpired =
	//   currentPlan?.subscription_end_date < new Date().toISOString();
	const [tab, settab] = useState(0);
	const [CheckOutModel, setCheckOutModel] = useState(false);
	const [cancelModel, setCancelModel] = useState(false);
	const [upgradebtn, setupgradebtn] = useState(false);
	const [contactSupportModel, setContactSupportModel] = useState(false);
	const [isContainerLoading, setIsContainerLoading] = useState(false);
	const [allPlans, setAllPlans] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [buyCreditModal, setBuyCreditModal] = useState(false);
	const [selectedGateway, setSelectedGateway] = useState('STRIPE');
	const [showDropDown, setshowDropDown] = useState(false);
	const [selectedplan, setselectedplan] = useState('ann');
	const [save, setSave] = useState(0);
	const [mainFeature, setMainFeature] = useState([
		'Physician Search Only',
		'Executive & Physician Search',
		'Create up to 5 Lists',
		'Create Lists Unlimited',
		'Real-Time Email Verification',
	]);

	useEffect(() => {
		if (planType) {
			setselectedplan('ann');
		} else {
			setselectedplan('mth');
		}
	}, [planType]);

	const handleUpgrade = (sub_id) => {
		dispatch({
			type: BUY_PLAN,
			payload: {
				subscription_id: sub_id,
				payment_gateway: 'STRIPE',
				isAnnual: selectedplan === 'ann' ? true : false,
			},
			setIsLoading: setIsLoading,
		});
	};

	const getSubscription = () => {
		// if (props.selectedCompanyByAdmin) {
		// 	dispatch({
		// 		type: GET_ALL_SUBSCRIPTION_PLAN,
		// 		id: props.selectedCompanyByAdmin,
		// 		flag: '',
		// 		updateState: setAllPlans,
		// 		setIsLoading: setIsContainerLoading,
		// 	});
		// } else {
		dispatch({
			type: GET_ALL_SUBSCRIPTION_PLAN,
			id: '',
			flag: '',
			updateState: setAllPlans,
			setIsLoading: setIsContainerLoading,
		});
	};

	useEffect(() => {
		getSubscription();
		if (currentPlan?.isAnnual) {
			setselectedplan('ann');
		}
		if (
			!currentPlan?.isAnnual &&
			currentPlan?.subscription_type !== 'Free Trial'
		) {
			setselectedplan('mth');
		}
	}, []);

	useEffect(() => {
		if (allPlans?.length > 0) {
			let totalDiscountPercentage = 0;
			let numberOfPlans = allPlans.length;

			for (const plan of allPlans) {
				const monthlyTotal = plan.monthly_amount * 12;
				const annualAmount = plan.annually_amount;
				const discount = ((monthlyTotal - annualAmount) / monthlyTotal) * 100;
				totalDiscountPercentage += discount;
			}

			setSave(Number(totalDiscountPercentage / numberOfPlans).toFixed(0));
		}
	}, [allPlans]);

	const openMailClient = () => {
		window.location.href = 'mailto:team@healthdbi.com';
	};

	return (
		<div className='relative min-h-screen pb-10'>
			<div className='fixed top-0 z-10 w-full'>
				<Navbar />
			</div>
			<div class='max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto mt-8 text-center'>
				<div class='max-w-2xl mx-auto text-center mb-10 lg:mb-14'>
					<h2 class='text-xl font-bold md:text-3xl md:leading-tight'>
						Plans & Pricing
					</h2>
					<p class='mt-1 text-gray-600 text-sm font-medium'>
						Choose an plan that's right for you.
					</p>
				</div>

				<div class='flex justify-center items-center'>
					<label class='min-w-14 text-sm text-gray-700 me-3'>Monthly</label>

					<label class='switch'>
						<input
							type='checkbox'
							checked={planType}
							onChange={() => setPlanType(!planType)}
						/>
						<span class='slider round'></span>
					</label>

					<label class='relative min-w-14 text-sm text-gray-700 ms-3'>
						Annual
						<span class='absolute -top-10 start-auto -end-28'>
							<span class='flex items-center'>
								<svg
									class='w-14 h-8 -me-6'
									width='45'
									height='25'
									viewBox='0 0 45 25'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M43.2951 3.47877C43.8357 3.59191 44.3656 3.24541 44.4788 2.70484C44.5919 2.16427 44.2454 1.63433 43.7049 1.52119L43.2951 3.47877ZM4.63031 24.4936C4.90293 24.9739 5.51329 25.1423 5.99361 24.8697L13.8208 20.4272C14.3011 20.1546 14.4695 19.5443 14.1969 19.0639C13.9242 18.5836 13.3139 18.4152 12.8336 18.6879L5.87608 22.6367L1.92723 15.6792C1.65462 15.1989 1.04426 15.0305 0.563943 15.3031C0.0836291 15.5757 -0.0847477 16.1861 0.187863 16.6664L4.63031 24.4936ZM43.7049 1.52119C32.7389 -0.77401 23.9595 0.99522 17.3905 5.28788C10.8356 9.57127 6.58742 16.2977 4.53601 23.7341L6.46399 24.2659C8.41258 17.2023 12.4144 10.9287 18.4845 6.96211C24.5405 3.00476 32.7611 1.27399 43.2951 3.47877L43.7049 1.52119Z'
										fill='currentColor'
										class='fill-gray-400'
									/>
								</svg>
								<span class='mt-3 inline-block whitespace-nowrap text-[11px] leading-5 font-semibold tracking-wide uppercase bg-blue-600 text-white rounded-full py-1 px-2.5'>
									Save up to {save}%
								</span>
							</span>
						</span>
					</label>
				</div>

				<div class='mt-12 mx-auto grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-center gap-6 items-center'>
					{allPlans?.length > 0 &&
						allPlans
							.sort((a, b) => a.annually_amount - b.annually_amount)
							.map((plan, i) => (
								<div
									key={i}
									className={`flex flex-col bg-white text-center rounded-xl p-8 ${
										allPlans.length > 1 && i === 1
											? 'border-2 border-blue-600'
											: ' border border-gray-200'
									}`}
								>
									{allPlans.length > 1 && i === 1 && (
										<p class='mb-3'>
											<span class='inline-flex items-center gap-1.5 py-1.5 px-3 rounded-lg text-xs uppercase font-semibold bg-blue-100 text-blue-600'>
												Most popular
											</span>
										</p>
									)}
									<h4 class='font-medium text-lg text-gray-800'>
										{plan?.title}
									</h4>
									<span class='!mt-5 font-bold text-5xl text-gray-800'>
										<span class='font-bold text-3xl -me-2'>$ </span>{' '}
										{planType ? plan?.annually_amount : plan?.monthly_amount}
									</span>
									<p class='mt-2 text-xs text-gray-600'>{plan.desc}</p>

									<ul class='mt-7 space-y-2.5 text-sm'>
										<li class='flex gap-x-2'>
											<svg
												class='shrink-0 mt-0.5 size-4 text-blue-600'
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
											>
												<polyline points='20 6 9 17 4 12' />
											</svg>
											<span class='text-gray-800'>
												{planType
													? plan.annually_credits
													: plan.monthly_credits}{' '}
												credits + (${plan.cost_per_credit} / credit)
											</span>
										</li>

										<li class='flex gap-x-2'>
											<svg
												class='shrink-0 mt-0.5 size-4 text-blue-600'
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
											>
												<polyline points='20 6 9 17 4 12' />
											</svg>
											<span class='text-gray-800'>
												{plan.no_of_user === 0 ? 1 : plan.no_of_user} users{' '}
												{plan.no_of_user > 1 &&
													`($${plan.cost_per_user} / user)`}
											</span>
										</li>

										{mainFeature
											.filter((item) =>
												plan.features.some((feature) =>
													feature.description.includes(item)
												)
											)
											.map((item, i) => (
												<li key={i} class='flex gap-x-2'>
													<svg
														class='shrink-0 mt-0.5 size-4 text-blue-600'
														xmlns='http://www.w3.org/2000/svg'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'
													>
														<polyline points='20 6 9 17 4 12' />
													</svg>
													<span class='text-gray-800'>{item}</span>
												</li>
											))}
									</ul>

									<button
										disabled={currentPlan.subscription_type === plan.title}
										onClick={() => handleUpgrade(plan?._id)}
										className={`!no-underline mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg shadow-sm disabled:opacity-50 disabled:pointer-events-none focus:outline-none ${
											i === 1
												? 'border-none bg-blue-600 text-white hover:bg-blue-700'
												: 'border border-gray-200 bg-white text-gray-800 hover:!bg-gray-50'
										}`}
									>
										{currentPlan.subscription_type === plan.title
											? 'Current Plan'
											: 'Buy Now'}
									</button>
								</div>
							))}
					<div
						className={`flex flex-col bg-white text-center rounded-xl p-8 border border-gray-200`}
					>
						<h4 class='font-medium text-lg text-gray-800'>Custom</h4>
						<span class='!mt-5 font-bold text-3xl text-gray-800'>
							Contact us
						</span>
						<p class='mt-2 text-xs text-gray-600'>
							A custom plan is offering personalized features and flexibility to
							align with unique goals and challenges.
						</p>
						<ul class='mt-7 space-y-2.5 text-sm'>
							<li class='flex gap-x-2'>
								<svg
									class='shrink-0 mt-0.5 size-4 text-blue-600'
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								>
									<polyline points='20 6 9 17 4 12' />
								</svg>
								<span class='text-gray-800'>Unlimited credits</span>
							</li>

							<li class='flex gap-x-2'>
								<svg
									class='shrink-0 mt-0.5 size-4 text-blue-600'
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								>
									<polyline points='20 6 9 17 4 12' />
								</svg>
								<span class='text-gray-800'>Unlimited users</span>
							</li>
							<li class='flex gap-x-2'>
								<svg
									class='shrink-0 mt-0.5 size-4 text-blue-600'
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								>
									<polyline points='20 6 9 17 4 12' />
								</svg>
								<span class='text-gray-800'>Customizable all features</span>
							</li>
						</ul>
						<p
							className={`cursor-pointer !no-underline mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg shadow-sm disabled:opacity-50 disabled:pointer-events-none focus:outline-none border border-gray-200 bg-white text-gray-800 hover:!bg-gray-50`}
							onClick={() =>
								window.open(
									'https://mail.google.com/mail/?view=cm&fs=1&to=team@healthdbi.com&su=Want%20to%20buy%20Enterprise%20Plan&body=Hi%2C%0D%0AI%20am%20blah%20blah%20and%20I%20want%20to%20buy%20an%20enterprise%20plan%20',
									'_blank'
								)
							}
						>
							Contact
						</p>
					</div>
				</div>

				{/* <div class='mt-20 lg:mt-32'>
					<div class='lg:text-center mb-10 lg:mb-20'>
						<h3 class='text-2xl font-semibold'>Compare plans</h3>
					</div>

					<div class='block'>
						<table class='w-full h-px'>
							<caption class='sr-only'>Pricing plan comparison</caption>
							<thead class='sticky top-16 inset-x-0 bg-white'>
								<tr>
									<th
										class='py-4 ps-6 pe-6 text-sm font-medium text-gray-800 text-start'
										scope='col'
									>
										<span class='sr-only'>Feature by</span>
										<span class=''>Plans</span>
									</th>

									{allPlans.length > 0 &&
										allPlans.map((plan, i) => (
											<th
												key={i}
												class='w-1/4 py-4 px-6 text-sm leading-6 font-medium text-gray-800 text-center'
												scope='col'
											>
												{plan.title}
											</th>
										))}
								</tr>
							</thead>
							<tbody class='border-t border-gray-200 divide-y divide-gray-200'>
								<tr>
									<th
										class='py-3 ps-6 bg-gray-50 font-bold text-gray-800 text-start'
										colspan='5'
										scope='colgroup'
									>
										Financial data
									</th>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Open/High/Low/Close
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Price-volume difference indicator
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Not included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-3 ps-6 bg-gray-50 font-bold text-gray-800 text-start'
										colspan='5'
										scope='colgroup'
									>
										On-chain data
									</th>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Network growth
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Average token age consumed
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Not included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Exchange flow
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Not included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Total ERC20 exchange funds flow
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Not included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Transaction volume
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Total circulation (beta)
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Not included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Velocity of tokens (beta)
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Not included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										ETH gas used
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Not included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-3 ps-6 bg-gray-50 font-bold text-gray-800 text-start'
										colspan='5'
										scope='colgroup'
									>
										Social data
									</th>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Dev activity
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Topic search
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Relative social dominance
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>

								<tr>
									<th
										class='py-5 ps-6 pe-6 text-sm font-normal text-gray-600 text-start whitespace-nowrap'
										scope='row'
									>
										Relative social dominance
									</th>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Free</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Startup</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-gray-400'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<path d='M5 12h14' />
										</svg>

										<span class='sr-only'>Included in Team</span>
									</td>

									<td class='py-5 px-6'>
										<svg
											class='mx-auto shrink-0 size-5 text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12' />
										</svg>

										<span class='sr-only'>Included in Enterprise</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default Plans;
