import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import LeadsTable from "./LeadsTable";
import PopUpModel from "../../../../componants/comman/PopUpModel";
import UploadLead from "./UploadLead";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { GET_ACTIVE_CATEGORIES } from "../../../../componants/Context/Types";
import { CircularProgress } from "@mui/material";
import FacebookCircularProgress from "../../../../componants/comman/FacebookCircularProgress";

const Leads = () => {
  const [currCategory, setCurrCategory] = useState();
  const [model, setmodel] = useState(false);
  const [categories, setCategories] = useState([]);
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: GET_ACTIVE_CATEGORIES,
      updateState: setCategories,
      setIsLoading: setIsLoading,
    });
  }, []);

  return (
    <div>
      <div className="btn-class">
        <h4 className="pr-3 d-flex justify-content-between">
          <div className="p-2 profile-header f-14">Leads</div>
          <Button
            variant={"contained"}
            className="mt-2"
            onClick={() => {
              setmodel(true);
            }}
          >
            Upload Leads
          </Button>
        </h4>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-between flex-row">
          <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
            <FacebookCircularProgress />
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-end  pb-0 pt-0">
            <div className="d-flex justify-content-end  pb-0 pt-0  hadbar-btn table-wrapper">
              {categories.map((item) => {
                return (
                  <Button
                    key={item?.value}
                    variant={`${
                      currCategory === item?.value ? "contained" : "outlined"
                    }`}
                    className="mx-0 flex-1 button-highlight"
                    onClick={() => {
                      setCurrCategory(item?.value);
                    }}
                  >
                    {item?.label}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="table-wrapper  card">
            <LeadsTable currCategory={currCategory} />
          </div>
        </>
      )}
      <PopUpModel
        open={model}
        title={"Upload Leads"}
        close={() => {
          setmodel(false);
        }}
      >
        <UploadLead
          close={() => {
            setmodel(false);
          }}
          categories={categories}
          setIsLoading={setIsLoading}
        />
      </PopUpModel>
    </div>
  );
};

export default Leads;
