import moment from "moment";
import {
  getAllCategories,
  getCategory,
  updateCategory,
  postCategory,
  deleteCategory,
} from "../APIs";
import { NotificationManager } from "react-notifications";

export const getAllCategoriesReducer = async (updateState, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await getAllCategories();
    const formatedData = res.data.data.map((item) => {
      return {
        name: item.name,
        updatedAt: moment(item.updatedAt).format("DD/MM/YYYY"),
        createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
        status: item.status,
        _id: item._id,
      };
    });
    updateState(formatedData);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const getCategoryReducer = async (
  id,
  updateState,
  setIsModelLoading
) => {
  setIsModelLoading(true);
  try {
    const res = await getCategory(id);
    const item = res.data.data;
    updateState.setCategoryName(item.name);
    updateState.setSelectedOption({
      value: `${item.status ? true : false}`,
      label: `${item.status ? "Active" : "InActive"}`,
    });
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsModelLoading(false);
  }
};

export const postCategoryReducer = async (
  values,
  updateState,
  setModel,
  setIsModelLoading,
  setIsLoading
) => {
  setIsModelLoading(true);
  try {
    const res = await postCategory(values);
    if (res.data.message === "CATEGORY CREATED SUCCESSFULLY") {
      NotificationManager.success(res.data.message);
      setModel(false);
      getAllCategoriesReducer(updateState, setIsLoading);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsModelLoading(false);
  }
};
export const updateCategoryReducer = async (
  id,
  values,
  updateState,
  setModel,
  setIsModelLoading,
  setIsLoading
) => {
  setIsModelLoading(true);
  try {
    const res = await updateCategory(id, values);
    if (res.data.message === "CATEGORY UPDATED SUCCESSFULLY") {
      NotificationManager.success(res.data.message);
      setModel(false);
      getAllCategoriesReducer(updateState, setIsLoading);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsModelLoading(false);
  }
};
export const deleteCategoryReducer = async (id, upDateState, setIsLoading) => {
  try {
    const res = await deleteCategory(id);
    if (res.data.message === "CATEGORY DELETED SUCCESSFULLY") {
      NotificationManager.success(res.data.message);
      getAllCategoriesReducer(upDateState, setIsLoading);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
