import React, { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useReactToPrint } from "react-to-print";
import { Button } from "@mui/material";

const InvoiceTable = ({ selectedInvoice }) => {
  const ref = useRef();
  const handleDownload = useReactToPrint({
    content: () => ref.current,
  });

  return (
    <div>
      <div>
        <Button
          variant="contained"
          className="mb-3 button-custom"
          onClick={handleDownload}
        >
          Download
        </Button>
      </div>
      <div ref={ref} className=" w-100 d-flex flex-column mylist-Update">
        <div className="d-flex justify-content-between Invoice-box border">
          <div className="Invoice-font">{selectedInvoice.createdAt}</div>
          <div className="d-flex Invoice-font">
            Status:
            <div className="Invoice-font">
              &nbsp;{selectedInvoice.status === true ? "Paid" : "UnPaid"}
            </div>
          </div>
        </div>
        <div className="border p-3">
          <div className="d-flex justify-content-between mb-4">
            <div className="invoice-copmponent border p-2">
              <div>From:</div>
              <div className="Invoice-font">{selectedInvoice.from.name}</div>
              <div>447 Broadway, 2nd floor, #713 NewYork, NY 10013, USA</div>
              <div>team@healthdbi.com</div>
              {/* <div>Mobile: {selectedInvoice.from.mobile}</div> */}
            </div>
            <div className="invoice-copmponent border p-2">
              <div>To:</div>
              <div className="Invoice-font">{selectedInvoice.company.name}</div>
              {/* <div>Address: {selectedInvoice.company.address}</div> */}
              <div>Email: {selectedInvoice.company.email}</div>
              <div>Mobile: {selectedInvoice.company.mobile}</div>
            </div>
          </div>
          <TableContainer className="border rounded-0">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Validity</TableCell>
                  <TableCell align="left">Credits</TableCell>
                  <TableCell align="left">Max Members</TableCell>
                  <TableCell align="left">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {rows.map((row) => ( */}
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    {selectedInvoice.item.subscription_type}
                  </TableCell>
                  <TableCell align="left">
                    {selectedInvoice.item.subscription_description}
                  </TableCell>
                  <TableCell align="left">
                    {selectedInvoice.item.subscription_validity} days
                  </TableCell>
                  <TableCell align="left">
                    {selectedInvoice.item.subscription_credits}
                  </TableCell>
                  <TableCell align="left">
                    {selectedInvoice.item.subscription_max_members}
                  </TableCell>
                  <TableCell align="left">{selectedInvoice.amount}</TableCell>
                </TableRow>
                {/* ))} */}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="border p-2  mt-3">
            <div className=" justify-content-end">
              <div className="d-flex justify-content-between Invoice-bill invoice-billing">
                <div className="mr-5 billing-font">Subtotal</div>
                <div className="justify-content-start">
                  {selectedInvoice.amount}
                </div>
              </div>
              <div className="d-flex justify-content-between Invoice-bill invoice-billing">
                <div className="mr-5 billing-font">Discount (0%)</div>
                <div>$0</div>
              </div>
              <div className="d-flex justify-content-between Invoice-bill invoice-billing">
                <div className="mr-5 billing-font">VAT (0%)</div>
                <div>$0</div>
              </div>
              <div className="d-flex justify-content-between Invoice-bill invoice-billing">
                <div className="mr-5 billing-font">Total</div>
                <div className="billing-font">{selectedInvoice.amount}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
