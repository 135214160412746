/** @format */
import React, { useContext, useEffect, useState } from 'react';
import {
	SPECIALITY_SPECIALITY,
	SPECIALITY_SPECIALITY_GROUP1,
} from '../../pages/ContactSerch/filterCompo/filterTypes';
import { MainContext } from '../../componants/filterContext/FilterContext';
import { AuthContext } from '../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../componants/Context/Types';
import debounce from 'debounce-promise';
import Dropdown from '../Misc/Dropdown';
import FilterBadges from './FilterBadges';
import { CircularProgress } from '@mui/material';
import { getSpecialityMasterAPI } from '../../pages/ContactSerch/filterCompo/filterAPI';

const SpecialityFilter = () => {
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		dispatch,
		setFilter,
		filter,
		setSortField,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		anyFilterFlag,
	} = useContext(AuthContext);

	const [masterOptions, setMasterOptions] = useState([]);
	const [specialityOptions, setSpecialityOptions] = useState([]);
	const [groupOptions, setGroupOptions] = useState([]);
	const [firstRender, setFirstRender] = useState(false);
	const [firstTimeRun, setFirstTimeRun] = useState(false);
	const [isMasterSearch, setIsMasterSearch] = useState(false);
	const [isSpecialitySearch, setIsSpecialitySearch] = useState(false);
	const [isGroupSearch, setIsGroupSearch] = useState(false);

	const getSpeciality = debounce((searchValue) => {
		setIsSpecialitySearch(true);
		const getData = async () => {
			let search = '';
			if (searchValue) {
				search = searchValue;
			}
			const data = await globalFilterKeyToApi[SPECIALITY_SPECIALITY](
				filter[globalFilterKeyTofilterKey[SPECIALITY_SPECIALITY_GROUP1]],
				search
			);
			const options = data.map((item) => {
				return {
					value: item.Specialty,
					label: item.Specialty,
				};
			});
			options.unshift({ label: 'Select All', value: 'Select All' });
			setSpecialityOptions(options);
			setIsSpecialitySearch(false);
			return options;
		};
		getData();
	}, 1000);

	const getSpecialityMaster = debounce((searchValue) => {
		setIsMasterSearch(true);
		const getData = async () => {
			let search = '';
			if (searchValue) {
				search = searchValue;
			}
			const data = await getSpecialityMasterAPI(search);
			const uniqueGroups = new Set();
			const options = data
				.filter((item) => item.SpecialtyGroup1)
				.map((item) => {
					uniqueGroups.add(item.SpecialtyGroup1);
					return {
						value: `${item.SpecialtyGroup1}__${item.Specialty}`,
						label: `${item.SpecialtyGroup1} - ${item.Specialty}`,
					};
				});
			const uniqueGroupOptions = Array.from(uniqueGroups).map((group) => ({
				value: group,
				label: group,
			}));

			setMasterOptions([...uniqueGroupOptions, ...options]);
			setIsMasterSearch(false);
			return [...uniqueGroupOptions, ...options];
		};
		getData();
	}, 300);

	const getGroup = debounce((searchValue) => {
		setIsGroupSearch(true);
		const getData = async () => {
			let search = '';
			if (searchValue) {
				search = searchValue;
			}
			const data = await globalFilterKeyToApi[SPECIALITY_SPECIALITY_GROUP1](
				search
			);
			const options = data.map((item) => {
				return {
					value: item,
					label: item,
				};
			});
			setGroupOptions(options);
			setIsGroupSearch(false);
			return options;
		};
		getData();
	}, 300);

	const handleSelect = (field, selectedOption) => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		if (field === 'master') {
			const [StyGroup, Sty] = selectedOption.value.split('__');
			setFilter((prev) => {
				if (!prev?.specialityGroup || prev?.specialityGroup?.length === 0) {
					return { ...prev, specialityGroup: [StyGroup] };
				} else {
					const index = prev.specialityGroup?.findIndex((item) => {
						if (typeof item === 'string') {
							return item === StyGroup;
						} else {
							return item.value === StyGroup;
						}
					});
					if (index === -1) {
						return {
							...prev,
							specialityGroup: [...prev.specialityGroup, StyGroup],
						};
					} else {
						return { ...prev };
					}
				}
			});
			setGlobalSelectFilter((prev) => {
				if (!prev?.specialityGroup || prev?.specialityGroup?.length === 0) {
					return { ...prev, specialityGroup: [StyGroup] };
				} else {
					const index = prev.specialityGroup?.findIndex((item) => {
						if (typeof item === 'string') {
							return item === StyGroup;
						} else {
							return item.value === StyGroup;
						}
					});
					if (index === -1) {
						return {
							...prev,
							specialityGroup: [...prev.specialityGroup, StyGroup],
						};
					} else {
						return { ...prev };
					}
				}
			});
			if (Sty) {
				setFilter((prev) => {
					if (!prev?.speciality || prev?.speciality?.length === 0) {
						return { ...prev, speciality: [Sty] };
					} else {
						const index = prev.speciality?.findIndex((item) => {
							if (typeof item === 'string') {
								return item === Sty;
							} else {
								return item.value === Sty;
							}
						});
						if (index === -1) {
							return {
								...prev,
								speciality: [...prev.speciality, Sty],
							};
						} else {
							return { ...prev };
						}
					}
				});
				setGlobalSelectFilter((prev) => {
					if (!prev?.speciality || prev?.speciality?.length === 0) {
						return { ...prev, speciality: [Sty] };
					} else {
						const index = prev.speciality?.findIndex((item) => {
							if (typeof item === 'string') {
								return item === Sty;
							} else {
								return item.value === Sty;
							}
						});
						if (index === -1) {
							return {
								...prev,
								speciality: [...prev.speciality, Sty],
							};
						} else {
							return { ...prev };
						}
					}
				});
			}
		} else {
			if (selectedOption.value === 'Select All') {
				var allOptions = specialityOptions
					.filter((obj) => obj.value !== 'Select All')
					.map((obj) => obj.value);

				console.log(allOptions);
				setGlobalSelectFilter((prev) => {
					return { ...prev, [field]: allOptions };
				});
				setFilter((prev) => {
					return { ...prev, [field]: allOptions };
				});
			} else {
				setGlobalSelectFilter((prev) => {
					if (!prev[field] || prev[field]?.length === 0) {
						return { ...prev, [field]: [selectedOption.value] };
					} else {
						const index = prev[field]?.findIndex((item) => {
							if (typeof item === 'string') {
								return item === selectedOption.value;
							} else {
								return item.value === selectedOption.value;
							}
						});

						if (index !== -1) {
							const updatedArray = [...prev[field]];
							updatedArray.splice(index, 1);
							return { ...prev, [field]: updatedArray };
						} else {
							return {
								...prev,
								[field]: [...prev[field], selectedOption.value],
							};
						}
					}
				});
				setFilter((prev) => {
					if (!prev[field] || prev[field]?.length === 0) {
						return { ...prev, [field]: [selectedOption.value] };
					} else {
						const index = prev[field]?.findIndex((item) => {
							if (typeof item === 'string') {
								return item === selectedOption.value;
							} else {
								return item.value === selectedOption.value;
							}
						});
						if (index !== -1) {
							const updatedArray = [...prev[field]];
							updatedArray.splice(index, 1);
							return { ...prev, [field]: updatedArray };
						} else {
							return {
								...prev,
								[field]: [...prev[field], selectedOption.value],
							};
						}
					}
				});
			}
		}

		setSortField({});
	};

	const handleRemove = (field, value) => {
		setFilter((prev) => {
			const index = prev[field]?.findIndex((item) => {
				if (typeof item === 'string') {
					return item === value;
				} else {
					return item.value === value;
				}
			});

			if (index !== -1) {
				const updatedArray = [...prev[field]];
				updatedArray.splice(index, 1);
				return { ...prev, [field]: updatedArray };
			} else {
				return { ...prev };
			}
		});
		setGlobalSelectFilter((prev) => {
			const index = prev[field]?.findIndex((item) => {
				if (typeof item === 'string') {
					return item === value;
				} else {
					return item.value === value;
				}
			});
			if (index !== -1) {
				const updatedArray = [...prev[field]];
				updatedArray.splice(index, 1);
				return { ...prev, [field]: updatedArray };
			} else {
				return { ...prev };
			}
		});
	};

	useEffect(() => {
		getGroup();
		getSpecialityMaster();
	}, []);

	useEffect(() => {
		if (filter?.specialityGroup?.length > 0) {
			getSpeciality();
		}
	}, [filter?.specialityGroup]);

	useEffect(() => {
		setFirstTimeRun(false);
	}, [filter, globalSelectFilter]);

	return (
		<div>
			<div className='px-2 pb-4 pt-1'>
				<h3 className='text-start pb-1 text-gray-900 font-medium text-2xs'>
					Master Search (Speciality Group - Speciality)
				</h3>
				<Dropdown
					options={masterOptions}
					defaultLabel={'Search...'}
					onSelect={handleSelect}
					field='master'
					disabled={false}
					title={'Speciality Group - Speciality:'}
					handleSearchOption={getSpecialityMaster}
					searchLoading={isMasterSearch}
					filter={filter}
					noIcon={true}
				/>
			</div>
			<div id='step5' className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>
					Speciality Group
				</h1>
				{filter?.specialityGroup?.length > 0 && (
					<FilterBadges
						options={filter.specialityGroup}
						field='specialityGroup'
						handleRemove={handleRemove}
						optionLength={4}
					/>
				)}
				<div className='w-full my-2 rounded-lg'>
					<h3 className='pt-2 text-start px-2 text-gray-900 font-medium text-2xs'>
						Most Searched Speciality Group
					</h3>
					<div className='px-2 pt-1 flex flex-wrap gap-1 items-center'>
						<div
							onClick={() =>
								handleSelect('specialityGroup', {
									value: 'Allergy & Immunology',
									label: 'Allergy & Immunology',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.specialityGroup?.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'Allergy & Immunology';
									} else {
										return e.value === 'Allergy & Immunology';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Allergy & Immunology
						</div>
						<div
							onClick={() =>
								handleSelect('specialityGroup', {
									value: 'Cardiology',
									label: 'Cardiology',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.specialityGroup?.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'Cardiology';
									} else {
										return e.value === 'Cardiology';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Cardiology
						</div>
						<div
							onClick={() =>
								handleSelect('specialityGroup', {
									value: 'Dermatology',
									label: 'Dermatology',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.specialityGroup?.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'Dermatology';
									} else {
										return e.value === 'Dermatology';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Dermatology
						</div>
						<div
							onClick={() =>
								handleSelect('specialityGroup', {
									value: 'Neurology',
									label: 'Neurology',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.specialityGroup?.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'Neurology';
									} else {
										return e.value === 'Neurology';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Neurology
						</div>
					</div>
					<h3 className='pt-3 text-start px-2 text-gray-700 font-medium text-2xs'>
						Or choose from the list
					</h3>
					<div className='px-2 pb-2 pt-1'>
						<Dropdown
							options={groupOptions}
							defaultLabel={'Search Speciality Group'}
							onSelect={handleSelect}
							field='specialityGroup'
							disabled={false}
							title={'Speciality Group:'}
							handleSearchOption={getGroup}
							searchLoading={isGroupSearch}
							filter={filter}
						/>
					</div>
				</div>
			</div>

			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>

			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Speciality</h1>
				{filter?.speciality?.length > 0 && (
					<FilterBadges
						options={filter.speciality}
						field='speciality'
						handleRemove={handleRemove}
						optionLength={3}
					/>
				)}
				<div className='w-full my-2 rounded-lg'>
					<h3 className='pt-2 text-start px-2 text-gray-900 font-medium text-2xs'>
						Most Searched Specialities
					</h3>
					<div className='px-2 pt-1 flex flex-wrap gap-1 items-center'>
						<div
							onClick={() =>
								handleSelect('speciality', {
									value: 'Allergist & Immunologist',
									label: 'Allergist & Immunologist',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.speciality?.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'Allergist & Immunologist';
									} else {
										return e.label === 'Allergist & Immunologist';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Allergist & Immunologist
						</div>
						<div
							onClick={() =>
								handleSelect('speciality', {
									value: 'Nuclear Cardiologist',
									label: 'Nuclear Cardiologist',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.speciality?.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'Nuclear Cardiologist';
									} else {
										return e.label === 'Nuclear Cardiologist';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Nuclear Cardiologist
						</div>
						<div
							onClick={() =>
								handleSelect('speciality', {
									value: 'Dermatologist',
									label: 'Dermatologist',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.speciality?.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'Dermatologist';
									} else {
										return e.label === 'Dermatologist';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Dermatologist
						</div>
						<div
							onClick={() =>
								handleSelect('speciality', {
									value: 'Neurologist',
									label: 'Neurologist',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.speciality?.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'Neurologist';
									} else {
										return e.label === 'Neurologist';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Neurologist
						</div>
					</div>
					<h3 className='pt-3 text-start px-2 text-gray-700 font-medium text-2xs'>
						Or choose from the list
					</h3>
					<div className='px-2 pb-2 pt-1'>
						{filter?.specialityGroup?.length > 0 ? (
							<Dropdown
								options={specialityOptions}
								defaultLabel={'Search Speciality'}
								onSelect={handleSelect}
								field='speciality'
								disabled={false}
								title={'Specialities:'}
								handleSearchOption={getSpeciality}
								searchLoading={isSpecialitySearch}
								filter={filter}
								openAbove={true}
							/>
						) : (
							<p className='text-red-600 text-2xs font-medium text-start'>
								Select Speciality Group First
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpecialityFilter;
