import { Button, CircularProgress, tooltipClasses } from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import upgrade from "../../assests/image/upgrade.svg";
import credit from "../../assests/image/credit.svg";
import {
  GET_CREDITS,
  GET_CURRENT_PLAN,
  QUICK_SEARCH,
  GET_SUBSCRIPTION_FEATURES,
  GET_DASHBOARD_DATA,
  GET_ADMIN_TABS,
  GET_SUB_ADMIN,
  GET_PROFILE,
  GET_UNPAID_INVOICE_NOTIFICATION,
} from "../Context/Types";
import useAuth from "../../hooks/useAuth";
import logo from "../../assests/image/companylogo.png";
import BuyMoreCreditPopUpModel from "./BuyMoreCreditPopUpModel";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import UpgradeNow from "./UpgradeNow";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PersonIcon from "@mui/icons-material/Person";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import styled from "@emotion/styled";
import WarningIcon from "@mui/icons-material/Warning";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const HeaderBar = () => {
  const {
    dispatch,
    searchTerm,
    setsearchTerm,
    setfilteredRows,
    credits,
    typeOfUser,
    upgradeNowModal,
    setUpgradeNowModal,
    notification,
  } = useContext(AuthContext);
  const { auth } = useAuth();
  const [serchTerm, setserchTerm] = useState("");
  const [buyCreditModal, setBuyCreditModal] = useState(false);

  // const [suggestions, setsuggestions] = useState([]);
  const ref = useRef();
  const navigate = useNavigate();

  // useEffect(() => {
  //   let ary = ["hello", "hi", "contacts", "mylist", "verify", "avin", "gaurav"];
  //   let filtered = ary.filter((d) => d.includes(serchTerm));
  //   setsuggestions(filtered);
  // }, [serchTerm]);

  const handleSearch = () => {
    setsearchTerm(ref.current.value); // beacsue casuing rendering which slowing the input value updation
    // console.log(ref.current.value);
    if (ref.current.value !== "") {
      setfilteredRows([]);
      dispatch({
        type: QUICK_SEARCH,
        payload: ref.current.value,
        page: 1,
        limit: 100,
      });
    }
  };
  useEffect(() => {
    if (auth.role === "subadmin") {
      dispatch({ type: GET_SUB_ADMIN, id: auth.id });
    }
    if (auth.role === "user") {
      dispatch({ type: GET_CREDITS });
    }
    if (auth.role === "company") {
      dispatch({ type: GET_CURRENT_PLAN });
      dispatch({ type: GET_CREDITS });
      dispatch({ type: GET_UNPAID_INVOICE_NOTIFICATION });
    }
    if (auth.role === "admin" || auth.role === "subadmin") {
      dispatch({ type: GET_DASHBOARD_DATA });
      dispatch({ type: GET_SUBSCRIPTION_FEATURES });
      dispatch({ type: GET_ADMIN_TABS });
    }
    dispatch({ type: GET_PROFILE });
  }, []);

  const [profile, setprofile] = useState(false);
  const [role, setRole] = useState(auth.role);
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    if (role === "company") setRole("user");
    if (role === "subadmin") setRole("admin");
  }, []);

  const user = [
    // {
    //   icon: <HomeIcon fontSize="f-16" className="ms-1" />,
    //   title: "Home",
    //   link: "/home",
    // },
    {
      icon: <LocalPhoneIcon fontSize="f-16" className="ms-1" />,
      title: "Contact Search",
      link: "/contactsearch",
    },
    {
      icon: <FormatListBulletedIcon fontSize="f-16" className="ms-1" />,
      title: "My List",
      link: "/mylist",
    },
    // {
    //   icon: <PersonIcon fontSize="small" />,
    //   title: "Profile",
    //   link: "/profile",
    // },
    // { icon: <SearchIcon />, title: "Look up", link: "/lookup" },
    // { icon: <CheckCircleOutlineIcon />, title: "Verify", link: "/verify" },
    // { icon: <PersonIcon />, title: "Profile", link: "/profile" },
  ];

  const admin = [
    {
      icon: <AccountBalanceIcon fontSize="f-16" />,
      title: "DashBoard",
      link: "/dashboard",
    },
    {
      icon: <PersonSearchIcon fontSize="f-16" />,
      title: "Contact Search",
      link: "/contactsearch",
    },
    {
      icon: <FormatListBulletedIcon fontSize="f-16" />,
      title: "My List",
      link: "/mylist",
    },
    // { icon: <FormatListBulletedIcon />, title: "My List", link: "/mylist" },
    // { icon: <SearchIcon />, title: "Verify", link: "/verify" },
    // { icon: <CheckCircleOutlineIcon />, title: "My List", link: "/verify" },
    {
      icon: <PersonIcon fontSize="f-16" />,
      title: "Profile",
      link: "/profile",
    },
  ];
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      // fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <div
      className="w-100 bg-white"
      style={{
        height: "63px",
        position: "fixed",
        zIndex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* box_shadow to above div */}
      <div className="d-flex flex-row justify-content-between align-items-center my-1 w-100 px-4">
        {/* <big style={{ color: "white", fontSize: "25px" }}>HelthDBI</big> */}
        <div className="d-flex align-items-left justify-content-left header_imageContainer">
          <img
            src={logo}
            alt="healthdbi"
            style={{ width: "auto", height: "50px" }}
            className="headerBar-logo pointer"
            onClick={() => {
              if (auth.role === "admin" || auth.role === "subadmin") {
                navigate("/dashboard");
              } else if (auth.role === "company" || auth.role === "user") {
                navigate("/contactsearch");
              }
            }}
          />
        </div>
        <div className={`top-bar-wrapper-contact-search`}>
          {(role == "admin" ? admin : user).map((data, i) => {
            return (
              <Button
                variant="none"
                size="small"
                className="header_button f-16 btn-height"
                style={{
                  backgroundColor: `${
                    window.location.pathname.includes(data.link)
                      ? "#1891EB"
                      : ""
                  }`,
                  borderRadius: `${
                    window.location.pathname.includes(data.link) ? "5px" : ""
                  }`,
                  color: `${
                    window.location.pathname.includes(data.link)
                      ? "#ffffff"
                      : "#4f4f4f"
                  }`,
                  fontWeight: `${
                    window.location.pathname.includes(data.link) ? "500" : "400"
                  }`,
                  width: "fit-content",
                }}
                onClick={() => {
                  setSelectedTab(data.title);
                  navigate(data.link, { replace: true });
                }}
                // startIcon={data.icon}
                key={i}
              >
                {data.title} {data.icon}
              </Button>
            );
            // }
          })}
        </div>
        {auth.role !== "admin" && auth.role !== "subadmin" && (
          <div
            style={{ width: "30%", flexDirection: "column" }}
            className="d-flex justify-content-end p-2"
          >
            <div className="d-flex justify-content-end">
              {/* <HtmlTooltip title={<div>{"some Text"}</div>}> */}
              <OverlayTrigger
                key={"credits"}
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom`} className="f-14">
                    Use Credits to unlock your leads
                  </Tooltip>
                }

              >
                <Button

                  variant="outlined"
                  style={{ marginRight: "10px" }}
                  className="py-1 px-2 shadow-none header_button f-16 btn-height"
                >
                  {credits === null ? (
                    <CircularProgress size={15} style={{ color: "#1891EB" }} />
                  ) : (
                    <>
                      {credits + " credits"}
                      <img src={credit} className="ms-3" alt="credit" />
                    </>
                  )}
                </Button>
              </OverlayTrigger>
              {/* </HtmlTooltip> */}
              {typeOfUser === "FREE" ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#1891EB",
                  }}
                  className="py-1 px-2 ml-1 shadow-none header_button f-16 btn-height"
                  onClick={() => {
                    navigate("/profile", { state: { title: "Subscription" } });
                  }}
                >
                  Upgrade Pro
                  <img src={upgrade} className="ms-3" alt="upgrade" />
                </Button>
              ) : typeOfUser === "PREMIUM" ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#1891EB",
                  }}
                  className="shadow-none header_button f-16 btn-height"
                  onClick={() => {
                    setBuyCreditModal(true);
                  }}
                >
                  Buy more Credits
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#1891EB",
                  }}
                  className="shadow-none header_button f-16 btn-height"
                >
                  <CircularProgress size={15} style={{ color: "white" }} />
                </Button>
              )}
            </div>
            {notification?.count > 0 ? (
              <div
                style={{
                  top: "50px",
                  fontSize: "xx-small",
                  color: "orangered",
                  fontWeight: "bold",
                  padding: "5px",
                }}
                onClick={() => {
                  navigate("/profile", { state: { title: "Invoice" } });
                }}
              >
                <WarningIcon
                  className="f-16"
                  style={{
                    marginRight: "-2px",
                    marginTop: "-3px",
                  }}
                />
                &nbsp;
                {`You have ${notification?.count} Unpaid Invoices`}
                {/* <span style={{ color: "orange" }} className="color-orange">
                Some Warning text.....
              </span> */}
              </div>
            ) : null}
          </div>
        )}
      </div>

      <BuyMoreCreditPopUpModel
        close={() => {
          setBuyCreditModal(false);
        }}
        open={buyCreditModal}
        title={"Buy Credits"}
      />

      <UpgradeNow
        close={() => {
          setUpgradeNowModal(false);
        }}
        open={upgradeNowModal}
        title={"Upgrade !!"}
      />
    </div>
  );
};

export default HeaderBar;
