import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { MainContext } from "../../../../componants/filterContext/FilterContext";
import { EXPERIENCE, OFFICE_TYPE } from "../filterTypes";


export default function OfficeType(props) {
    const {
        setFilter,
        setSortField,
        selectedVal,
        setSelectedVal
    } = useContext(AuthContext);
    const { globalFilterKeyTofilterKey } = useContext(MainContext);

    const handleChange = (event) => {
        setSelectedVal(event.target.value)
        setSortField({});
        if (event.target.value === "") {
            setFilter((prev) => {
                return {
                    ...prev,
                    [globalFilterKeyTofilterKey[OFFICE_TYPE]]: [],
                };
            });
        } else {
            setFilter((prev) => {
                return {
                    ...prev,
                    [globalFilterKeyTofilterKey[OFFICE_TYPE]]: [event.target.value],
                };
            });
        }
    };

    useEffect(() => {
        setFilter((prev) => {
            return {
                ...prev,
                [globalFilterKeyTofilterKey[OFFICE_TYPE]]: [],
            };
        });
    }, [])

    return (
        <div className="d-flex p-3 flex-column justify-content-start align-items-start">
            <div className="d-flex align-items-center mb-2">
                <label className="mb-0" style={{cursor: "pointer"}} htmlFor="all">
                    <input onChange={(e) => handleChange(e)} checked={selectedVal === ""} className="me-2" value="" type="radio" name="officeType" id="all" />
                    All
                </label>
            </div>
            <div className="d-flex align-items-center mb-2">
                
                <label className="mb-0" style={{cursor: "pointer"}} htmlFor="hospital">
                <input onChange={(e) => handleChange(e)} className="me-2" checked={selectedVal === "Hospital"} value="Hospital" type="radio" name="officeType" id="hospital" />
                Hospital
                </label>
            </div>
            <div className="d-flex align-items-center">
                
                <label className="mb-0" style={{cursor: "pointer"}} htmlFor="offices">
                <input onChange={(e) => handleChange(e)} className="me-2" checked={selectedVal === "Offices"} value="Offices" type="radio" name="officeType" id="offices" />
                Offices
                </label>
            </div>
        </div>
    );
}
