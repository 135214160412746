import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { GET_UNLOCKED_LEADS } from "../../../../componants/Context/Types";
import {
  EXECUTIVE_FILTER,
  PHYSICIAN_FILTER,
} from "../../filterCompo/filterTypes";
import { Table } from "antd";
import { Button } from "@mui/material";
import { downloadCSV } from "download-csv";
import {
  executiveColumns,
  executiveHeadersDownload,
  physicianColumns,
  physicianHeadersDownload,
} from "../../../../helper/schema";

const columns = [
  {
    title: "Name",
    dataIndex: "Name",
  },
  {
    title: "Speciality General",
    dataIndex: "Specialty_General",
  },
  {
    title: "PhoneNumber",
    dataIndex: "PhoneNumber",
  },
  {
    title: "Email",
    dataIndex: "EmailAddress",
  },
  {
    title: "Address",
    dataIndex: "Address1",
  },
];

const headers = [
  { label: "FirstName", key: "FirstName" },
  { label: "LastName", key: "LastName" },
  { label: "Specialty_General", key: "Specialty_General" },
  { label: "PhoneNumber", key: "PhoneNumber" },
  { label: "EmailAddress", key: "EmailAddress" },
  { label: "Address1", key: "Address1" },
  { label: "Clinic", key: "Clinic" },
  { label: "City", key: "City" },
  { label: "State", key: "State" },
  { label: "Country", key: "Country" },
];

const Unlocks = () => {
  const [rows, setRows] = useState([]);
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [typesOfFilter, setTypesOfFilter] = useState("");
  useEffect(() => {
    if (!typesOfFilter) return;
    let dataType;
    if (typesOfFilter === PHYSICIAN_FILTER) {
      dataType = 0;
    } else if (typesOfFilter === EXECUTIVE_FILTER) {
      dataType = 1;
    }
    dispatch({
      type: GET_UNLOCKED_LEADS,
      updateState: setRows,
      setIsLoading: setIsLoading,
      dataType: dataType,
    });
  }, [typesOfFilter]);
  const emailValidatedData = rows.map((item) => {
    if (!item?.debounceCode || item?.debounceCode === "1" || item?.debounceCode === "2")
      return item;
    return {
      ...item,
      EmailAddress: "Invalid Email",
    };
  });

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center m-2">
        <div>
          <Button
            style={{
              backgroundColor:
                typesOfFilter === EXECUTIVE_FILTER ? "#1891eb" : "",
              boxShadow: "none",
            }}
            variant={
              typesOfFilter === EXECUTIVE_FILTER ? "contained" : "outlined"
            }
            className=" f-16 btn-height"
            onClick={() => {
              setTypesOfFilter(EXECUTIVE_FILTER);
            }}
          >
            Executives
          </Button>
          &nbsp;
          <Button
            style={{
              backgroundColor:
                typesOfFilter === PHYSICIAN_FILTER ? "#1891eb" : "",
              boxShadow: "none",
            }}
            className=" f-16 btn-height"
            variant={
              typesOfFilter === PHYSICIAN_FILTER ? "contained" : "outlined"
            }
            onClick={() => {
              setTypesOfFilter(PHYSICIAN_FILTER);
            }}
          >
            Physicians
          </Button>
          <Button
            variant={"outlined"}
            className=" f-16 btn-height"
            style={{ marginLeft: "10px", outline: "none", border: "none" }}
          >
            <h4 className="blue_text fw-bold mt-2">
              {rows?.length > 0 && rows.length}
            </h4>
          </Button>
        </div>
        <Button
          variant={"contained"}
          style={{ backgroundColor: "#1891eb", boxShadow: "none" }}
          className="m-1 f-16 btn-height"
          onClick={() => {
            if (typesOfFilter === PHYSICIAN_FILTER) {
              downloadCSV(emailValidatedData, physicianHeadersDownload);
            } else if (typesOfFilter === EXECUTIVE_FILTER) {
              downloadCSV(emailValidatedData, executiveHeadersDownload);
            }
          }}
        >
          Export All leads to CSV
        </Button>
      </div>
      <Table
        scroll={{
          x: true,
        }}
        columns={
          typesOfFilter === EXECUTIVE_FILTER
            ? executiveColumns
            : typesOfFilter === PHYSICIAN_FILTER
            ? physicianColumns
            : []
        }
        dataSource={emailValidatedData}
        loading={isLoading}
      />
    </div>
  );
};

export default Unlocks;
