/** @format */

import React, { useState, useEffect, useContext } from 'react';
import Joyride, { STATUS, ACTIONS, EVENTS } from 'react-joyride';
import { onboardingComplete } from '../../componants/Context/APIs';
import NavbarContext from '../Navbar/NavbarContext';

const WelcomeTour = ({ startTour }) => {
	const { tourAgain, setTourAgain } = useContext(NavbarContext);
	const [runTour, setRunTour] = useState(false);
	const [tourCompleted, setTourCompleted] = useState(false);

	useEffect(() => {
		if (startTour) {
			setRunTour(true);
			setTourCompleted(false);
		}
	}, [startTour]);

	useEffect(() => {
		if (tourAgain) {
			setRunTour(true);
			setTourCompleted(false);
		}
	}, [tourAgain]);

	const handleTourEnd = async () => {
		setRunTour(false);
		setTourCompleted(true);
		setTourAgain(false);
		await onboardingComplete();
	};

	const steps = [
		{
			target: '#step1',
			title: 'Check Your Credits',
			content:
				'Use these to verify email addresses of your leads and contacts. Need more? You can buy extra credits anytime!',
		},
		{
			target: '#step2',
			title: 'Quick Search with Popular Criteria',
			content:
				'Pick from our most popular search options to find what you need fast.',
		},
		{
			target: '#step3',
			title: 'Save Your Favorite Searches',
			content:
				'Save your search criteria and selected filters for quick access next time. Once saved, you can easily apply them again with just a click, saving you time and effort.',
		},
		{
			target: '#step4',
			title: 'Filter Your Data',
			content:
				'Choose from tons of filters to fine-tune your search and find exactly what you are looking for.',
		},
		{
			target: '#step5',
			title: 'Choose Desired Options',
			content:
				'Speed up your search with commonly used options or you can select from the full list for precise results.',
		},
		{
			target: '#step6',
			title: 'Start Your Search',
			content:
				'Start your search after selecting your desired filters. It is that easy to find exactly what you are looking for!',
		},
		{
			target: '#step7',
			title: 'Results Overview',
			content:
				'The total count of records based on your search criteria, and another count that excludes results you have already downloaded.',
		},
		{
			target: '#step8',
			title: 'Take Control of Your Data',
			content:
				'Download what you need, check your download progress, create lists from selected records to stay organized.',
		},
		{
			target: '#step9',
			title: 'Explore Detailed Data',
			content:
				'Click on a data row to view all the contact details and explore more information about each record.',
		},
	];

	const handleJoyrideCallback = async (data) => {
		const { action, index, type, status } = data;

		if (type === EVENTS.STEP_AFTER && index === 1 && action === ACTIONS.CLOSE) {
			// Automatically move to the next step if step 2 is closed
			document.querySelector('#step2').click();
		}
		if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 1) {
			document.querySelector('#step2').click();
		}
		if (type === EVENTS.STEP_AFTER && index === 2 && action === ACTIONS.CLOSE) {
			// Automatically move to the next step if step 2 is closed
			document.querySelector('#specClick').click();
		}
		if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 2) {
			document.querySelector('#specClick').click();
		}
		if (type === EVENTS.STEP_AFTER && index === 5 && action === ACTIONS.CLOSE) {
			// Automatically move to the next step if step 2 is closed
			document.querySelector('#specClick').click();
		}
		if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 5) {
			document.querySelector('#specClick').click();
		}
		if (type === EVENTS.STEP_AFTER && index === 8 && action === ACTIONS.CLOSE) {
			// Automatically move to the next step if step 2 is closed
			document.querySelector('#openpro').click();
		}
		if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT && index === 8) {
			document.querySelector('#openpro').click();
		}

		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			// Save user has completed the tour to prevent showing the welcome modal again
			//localStorage.setItem('tourCompleted', 'true');
			await handleTourEnd();
		}
	};

	const customStyles = {
		options: {
			arrowColor: 'transparent',
			backgroundColor: '#fff',
			overlayColor: 'rgba(0, 0, 0, 0.5)',
			primaryColor: '#2f5eff',
			textColor: '#1f2937',
			width: 300,
			zIndex: 1000,
		},
		buttonClose: {
			display: 'none',
		},
		buttonNext: {
			backgroundColor: '#2f5eff',
			borderRadius: '5px',
			color: '#fff',
			fontSize: '13px',
			//fontFamily: 'Inter',
			fontWeight: '500',
			padding: '10px 16px',
		},
		buttonBack: {
			color: '#1f2937',
			marginRight: 10,
			fontSize: '13px',
			//fontFamily: 'Inter',
		},
		buttonSkip: {
			color: '#1f2937',
			fontSize: '12px',
			//fontFamily: 'Inter',
		},
		tooltipContent: {
			margin: '0',
			padding: '0',
			paddingTop: '5px',
			fontSize: '12px',
			color: '#1f2937',
			//fontFamily: 'Inter',
		},
		tooltipContainer: {
			textAlign: 'left',
		},
		tooltipTitle: {
			margin: '0',
			fontSize: '15px',
			fontWeight: '600',
			//fontFamily: 'Inter',
		},
	};

	return (
		<Joyride
			steps={steps}
			run={runTour}
			continuous={true}
			showProgress={true}
			showSkipButton={true}
			showBackButton={false}
			styles={customStyles}
			locale={{
				back: null,
			}}
			callback={handleJoyrideCallback}
		/>
	);
};

export default WelcomeTour;
