import React, { useEffect, useState } from 'react';
import "./InternalInside.css";
import Select from "react-select";
import { Button, CircularProgress } from '@mui/material';
import PopUpModel from '../../comman/PopUpModel';
import AddField from '../InternalComponent/AddField';
import { addInternalProjectFile } from '../../Context/APIs';
import { NotificationManager } from 'react-notifications';


const InsideProjectFile = ({ tableColumn, fileName, setScreenOpen, csvFile }) => {

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValues2, setSelectedValues2] = useState({});
  const [tableOptions, setTableOptions] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const formData = new FormData();
  const [headerName, setHeaderName] = useState({
    name: "",
    property: ""
  });
  const [allHeaders, setAllHeaders] = useState([
    {
      label: "Full Name",
      value: "fullname",
    },
    {
      label: "First Name",
      value: "firstname",
    },
    {
      label: "Last Name",
      value: "lastname",
    },
    {
      label: "Title",
      value: "title",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Email Status",
      value: "emailstatus",
    },
    {
      label: "Job Function",
      value: "jobfunction",
    },
    {
      label: "Management Level",
      value: "managementlevel",
    },
    {
      label: "Contact Linkedin",
      value: "contactlinkedin",
    },
    {
      label: "Company Linkedin",
      value: "companylinkedin",
    },
    {
      label: "City",
      value: "city",
    },
    {
      label: "Address1",
      value: "address1",
    },
    {
      label: "Address2",
      value: "address2",
    },
    {
      label: "State",
      value: "state",
    },
    {
      label: "Country",
      value: "country",
    },
    {
      label: "Zip",
      value: "zip",
    },
    {
      label: "Sic",
      value: "sic",
    },
    {
      label: "Company Name",
      value: "companyname",
    },
    {
      label: "Web Address",
      value: "webaddress",
    },
    {
      label: "Company Phone",
      value: "companyphone",
    },
    {
      label: "Contact Phone",
      value: "contactphone",
    },
    {
      label: "Company Fax",
      value: "companyfax",
    },
    {
      label: "Sic Description",
      value: "sicdescription",
    },
    {
      label: "Country",
      value: "county",
    },
    {
      label: "Naics Code",
      value: "naicscode",
    },
    {
      label: "Naics Description",
      value: "naicsdescription",
    },
    {
      label: "Linkedin Industry Name",
      value: "linkedinindustryname",
    },
    {
      label: "Revenue",
      value: "revenue",
    },
    {
      label: "Number Employees",
      value: "numberemployees",
    },
  ]);


  const handleSubmitField = () => {
    if(isError) {
      return;
    }
    if (headerName?.name.length > 0 && headerName?.property?.length > 0) {
      setAllHeaders((prev) => [...prev, { value: headerName.property, label: headerName.name, list: [] }]);
      setIsModal(false);
    }
  }

  const changeSelectHandler = (option, name) => {
    let array = tableOptions || [];
    const index = array.findIndex(obj => Object.keys(obj)[0] === name);
    if (index !== -1) {
      array[index][name] = option?.label;
    } else {
      const newObject = { [name]: option?.label };
      array.push(newObject);
    }
    setSelectedValues2(prev => ({
      ...prev,
      [name]: option.value
    }))
    setTableOptions(array);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newArray = tableOptions.filter(obj => Object.keys(obj).length !== 0);

    const convertedObject = {};

    newArray.forEach(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      convertedObject[key] = value;
    });

    formData.append("file", csvFile);
    formData.append("filename", String(fileName)?.split(".")[0]);
    formData.append("data", JSON.stringify(convertedObject));
    setLoader(true);
    try {
      const res = await addInternalProjectFile(formData);
      if (res) {
        NotificationManager.info(res?.data?.msg || "Uploaded Successfully");
      }
      setScreenOpen(null);
      setLoader(false);
    } catch (error) {
      setScreenOpen(null);
      setLoader(false);
    }
  }

  const removeItem = (index) => {
    let headersItems = [...allHeaders];
    headersItems.splice(index, 1);
    setAllHeaders(headersItems);
  }

  useEffect(() => {

    const array3 = tableColumn.map(item2 => {
      const matchingItem1 = allHeaders.find(item1 => new RegExp(`\\b${item2?.value}\\b`, "gi").test(String(item1.label).replace(/\s/g, '')));
      if (matchingItem1) {
        return { [matchingItem1.value]: item2.value };
      } else {
        return {};
      }
    });
    setTableOptions(array3)
  }, [tableColumn])

  useEffect(() => {
    const newArray = tableOptions.filter(obj => {
      const keys = Object.keys(obj);
      if (keys.length === 1 && obj[keys[0]]) {
        return true;
      }
      return false;
    }).map(obj => {
      const key = Object.keys(obj)[0];
      return {
        label: obj[key],
        value: key
      };
    });

    setSelectedValues(newArray);
  }, [tableOptions])

  return (
    <div>
      <div className='insideHeaderContainerItem'>
        <span></span>
        <h6 className="insideHeading">{`${fileName} (${tableColumn?.length} headers)`}</h6>
        <Button
          onClick={() => setIsModal(true)}
          variant="contained"
          className="m-2 p-2 mr-0 f-16"
          style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
        >
          Add Field
        </Button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='insideHeaderContainer'>
          {
            allHeaders?.map((item, index) => {
              return (
                <div className='insideHeaderItem'>
                  <div className='insideHeaderLabelFlex'>
                    <label htmlFor="" className='insideHeaderLabel'>{item?.label}</label>
                    {
                      index > 28 && (
                        <span onClick={() => removeItem(index)}>&#10060;</span>
                      )
                    }
                  </div>
                  <Select
                    value={selectedValues2[item.value]? {value: [selectedValues2[item.value]], label: [selectedValues2[item.value]]}:selectedValues.filter(opt => new RegExp(`\\b${item?.value}\\b`, "gi").test(String(opt.value).replace(/\s/g, '')))[0] || []}
                    required={index > 31 ? false : true} isMulti={false} isSearchable={true}
                    closeMenuOnSelect={true}
                    onChange={(option) => changeSelectHandler(option, item?.value)}
                    options={tableColumn || []} />
                </div>
              )
            })
          }

        </div>
        <Button
          type='submit'
          variant="contained"
          className="m-2 p-2 mr-0 f-16 mt-3"
          style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
        >
          {
            loader ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Submit"
          }
        </Button>
      </form>
      <PopUpModel
        open={isModal}
        title={"Add Field"}
        close={() => {
          setIsModal(false);
        }}
      >
        <AddField setHeaderName={setHeaderName} headerName={headerName} handleSubmit={handleSubmitField} isError={isError} setIsError={setIsError} />
      </PopUpModel>
    </div>
  )
}

export default InsideProjectFile