import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import _ from "lodash";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button, InputAdornment } from "@mui/material";
import { AuthContext } from "../../../componants/Context/AuthContext";
import {
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from "../../../componants/Context/Types";
import { CircularProgress } from "@mui/material";

const AddCustomPackage = (props) => {
  const [info, setInfo] = useState({
    title: props?.selectedItem?.title,
    desc: props?.selectedItem?.desc,
    cost_per_user: props?.selectedItem?.cost_per_user,
    no_of_user: props?.selectedItem?.no_of_user,
    monthly_amount: props?.selectedItem?.monthly_amount,
    monthly_credits: props?.selectedItem?.monthly_credits,
    annually_amount: props?.selectedItem?.annually_amount,
    annually_credits: props?.selectedItem?.annually_credits,
    cost_per_credit: props?.selectedItem?.cost_per_credit,
  });
  const { dispatch, subscriptionFeatures } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props?.selectedOption);

  const handleSubmit = () => {
    let values = {
      ...info,
      no_of_user: +info.no_of_user,
      monthly_amount: +info.monthly_amount,
      monthly_credits: +info.monthly_credits,
      annually_amount: +info.annually_amount,
      annually_credits: +info.annually_credits,
      cost_per_user: +info.cost_per_user,
      features: selectedOption.map((item) => item.value),
      cost_per_credit: +info.cost_per_credit,
      type: "INDIVIDUAL",
      company_id: props.selectedCompanyByAdmin,
    };
    if (props.selectedItem !== null) {
      values = _.omit(values, [
        "_id",
        "createdAt",
        "updatedAt",
        "__v",
        "stripe_product_id",
        "stripe_price_id",
        "stripe_annual_price_id",
        "stripe_month_price_id",
        "stripe_cpc_price_id",
        "stripe_product_id",
        "stripe_cpc_product_id",
      ]);
      dispatch({
        type: UPDATE_SUBSCRIPTION,
        payload: props.selectedItem._id,
        values: values,
        updateState: props.setRows,
        setRowLoading: () => {},
        setmodel: props.setmodel,
        setIsLoading: setIsLoading,
      });
    } else {
      dispatch({
        type: CREATE_SUBSCRIPTION,
        payload: values,
        updateState: () => {},
        setRowLoading: () => {},
        setmodel: props.setmodel,
        setIsLoading: setIsLoading,
      });
    }
  };

  useEffect(() => {
    return () => {
      props.setSelectedOption([]);
      props.setSelectedItem([]);
    };
  }, []);

  return (
    <div>
      <div>
        <div className="d-grid justify-content-center m-2">
          <TextField
            InputLabelProps={{ shrink: true }}
            required
            id="outlined-basic"
            value={info?.title}
            size="small"
            className="Add-package m-2"
            name="Title"
            label="Title"
            variant="outlined"
            onChange={(e) => {
              setInfo((prev) => {
                return { ...prev, title: e.target.value };
              });
            }}
          />
          <div className="d-flex justify-content-between">
            <Select
              onChange={setSelectedOption}
              options={subscriptionFeatures}
              className="basic-multi-select w-100 m-2"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              isMulti
              placeholder="Features"
              defaultValue={selectedOption}
              required
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              required
              id="outlined-basic"
              value={info?.cost_per_user}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              size="small"
              className="w-50 m-2"
              name=""
              label="Cost per User"
              variant="outlined"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, cost_per_user: e.target.value };
                });
              }}
            />
          </div>

          <div className="d-flex justify-content-between">
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              value={info?.no_of_user?.toString()}
              size="small"
              className="w-50 m-2"
              name="Number of Users Allowed"
              label="Number of Users Allowed"
              variant="outlined"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, no_of_user: e.target.value };
                });
              }}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              required
              id="outlined-basic"
              value={info?.monthly_amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              size="small"
              className="w-50 m-2"
              name="Monthly Amount"
              label="Monthly Amount"
              variant="outlined"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, monthly_amount: e.target.value };
                });
              }}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              required
              id="outlined-basic"
              value={info?.monthly_credits}
              size="small"
              className="w-50 m-2"
              name="Monthly Credits"
              label="Monthly Credits"
              variant="outlined"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, monthly_credits: e.target.value };
                });
              }}
            />
          </div>
          <div className="d-flex justify-content-between">
            <TextField
              InputLabelProps={{ shrink: true }}
              required
              id="outlined-basic"
              value={info?.annually_amount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              size="small"
              className="w-50 m-2"
              name="Annual Amount"
              label="Annual Amount"
              variant="outlined"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, annually_amount: e.target.value };
                });
              }}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              required
              id="outlined-basic"
              value={info?.annually_credits}
              size="small"
              className="w-50 m-2"
              name="Annual Credits"
              label="Annual Credits"
              variant="outlined"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, annually_credits: e.target.value };
                });
              }}
            />

            <TextField
              required
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              value={info?.cost_per_credit}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              size="small"
              className="w-50 m-2"
              name="Cost per Credit"
              label="Cost per Credit"
              variant="outlined"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, cost_per_credit: e.target.value };
                });
              }}
            />
          </div>
          <TextareaAutosize
            required
            className="Add-package m-2"
            aria-label="minimum height"
            minRows={3}
            value={info?.desc}
            placeholder="Description"
            onChange={(e) => {
              setInfo((prev) => {
                return { ...prev, desc: e.target.value };
              });
            }}
          />
          <div className="d-flex justify-content-end ">
            <Button variant="contained" className=" m-2" onClick={handleSubmit}>
              {isLoading ? (
                <CircularProgress sx={{ fontSize: "0.2rem", color: "white" }} />
              ) : (
                "Add"
              )}
            </Button>
            <Button
              variant="contained"
              className="bg-danger m-2"
              onClick={() => props.setmodel(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCustomPackage;
