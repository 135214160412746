import { NotificationManager } from "react-notifications";
import moment from "moment";
import {
  getCompaniesByType,
  getCompanyInfo,
  getCompanyActivityLog,
  getCompanyMembers,
  editCompanyInfo,
  createCompanyByAdmin,
  assignCreditsToCompany,
  getAllCompanies,
  blockAndUnblockCompany,
  verifyCompanyEmail,
  suspendAndUnsuspendCompany,
  assignUsersToCompany,
  extendFreeTrial,
} from "../APIs";
export const getCompaniesByTypeReducer = async (
  searchParams,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await getCompaniesByType(
      searchParams.type
      // searchParams.page,
      // searchParams.limit
    );
    const formatedData = res.data.companies.map((item) => {
      return {
        _id: item._id,
        company_name: item.company_name,
        name: item.name,
        email: item.email,
        mobile: item.mobile,
      };
    });
    updateState.setRows(formatedData);
    //   updateState.setPagination({
    //     current: searchParams.page,
    //     pageSize: searchParams.limit,
    //     total:res.data.count,
    //   });
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getAllCompaniesReducer = async (updateState, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await getAllCompanies();

    // res.data.map((item) => {
    //   if (item.plan !== null && item.plan?.subscription_end_date) {
    //     item.plan.subscription_end_date = moment(
    //       item.plan?.subscription_end_date
    //     ).format("DD/MM/YYYY");
    //   }
    //   item.createdAt = moment(item.createdAt).format("DD/MM/YYYY");
    //   return item;
    // });
    updateState(res.data);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getCompanyMembersReducer = async (
  id,
  updateState,
  setIsLoading,
  setallData
) => {
  setIsLoading(true);
  try {
    const res = await getCompanyMembers(id);
    updateState(res.data);
    setallData(res.data);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getCompanyInfoReducer = async (id, updateState, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await getCompanyInfo(id);
    const formatedData = [];
    formatedData.push({ label: "User Name", value: res.data.name });
    formatedData.push({ label: "Company Name", value: res.data.company_name });
    formatedData.push({ label: "Login IP", value: res.data.login_ip });
    formatedData.push({ label: "Email", value: res.data.email });
    formatedData.push({
      label: "Subscription Name",
      value: res.data.subscription_name,
    });
    formatedData.push({ label: "Available Credits", value: res.data.credits });
    formatedData.push({ label: "Team Members", value: res.data.members });
    formatedData.push({
      label: "Subscription Start",
      value: moment(res.data.subscription_start).format(
        "dddd, MMMM Do YYYY, h:mm:ss a"
      ),
    });
    formatedData.push({
      label: "Subscription Expiry",
      value: moment(res.data.subscription_expiry).format(
        "dddd, MMMM Do YYYY, h:mm:ss a"
      ),
    });

    updateState(formatedData);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getCompanyInfoAnotherFormatReducer = async (
  id,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await getCompanyInfo(id);
    // const formatedData = {
    //   name: res.data.name,
    //   email: res.data.email,
    //   company_name: res.data.company_name,
    //   mobile: res.data.mobile,
    //   subscription_name:res.data.subscription_name,
    // };
    updateState(res.data);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const getCompanyActivityLogReducer = async (
  searchParams,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await getCompanyActivityLog(
      searchParams.id,
      searchParams.page,
      searchParams.limit
    );
    const formatedData = res.data.logs.map((item) => {
      return {
        ...item,
      };
    });
    updateState.setRows(formatedData);
    updateState.setCount(res.data.count);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const editCompanyInfoReducer = async (
  id,
  values,
  setIsLoading,
  setmodel
) => {
  setIsLoading(true);
  try {
    const res = await editCompanyInfo(id, values);
    if (res.data) {
      NotificationManager.success(res.data);
      setmodel(false);
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};

export const createCompanyByAdminReducer = async (
  values,
  closeModal,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await createCompanyByAdmin(values);
    if (res.data) {
      NotificationManager.success(res.data);
      closeModal();
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};

export const assignCreditsToCompanyReducer = async (
  values,
  closeModal,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await assignCreditsToCompany(values);
    // console.log(res.data);
    // console.log(res.data.message);
    if (res.data) {
      NotificationManager.success(res.data.message);
      closeModal();
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};
export const assignUsersToCompanyReducer = async (
  values,
  closeModal,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await assignUsersToCompany(values);
    if (res.data) {
      NotificationManager.success(res.data.message);
      closeModal();
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
    console.log(e);
  } finally {
    setIsLoading(false);
  }
};

export const blockAndUnblockCompanyReducer = async (id, updateState) => {
  try {
    const res = await blockAndUnblockCompany(id);
    if (res.data) {
      NotificationManager.success(res.data);
      getAllCompaniesReducer(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
export const suspendAndUnsuspendCompanyReducer = async (id, updateState) => {
  try {
    const res = await suspendAndUnsuspendCompany(id);
    if (res.data) {
      NotificationManager.success(res.data);
      getAllCompaniesReducer(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
export const verifyCompanyEmailReducer = async (id, updateState) => {
  try {
    const res = await verifyCompanyEmail(id);
    if (res.data) {
      NotificationManager.success(res.data);
      getAllCompaniesReducer(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};

export const extendFreeTrialReducer = async (
  id,
  date,
  updateState,
  setIsLoading,
  closeModal
) => {
  setIsLoading(true);
  try {
    const res = await extendFreeTrial(id, date);
    if (res.data) {
      NotificationManager.success(res.data);
      getCompanyInfoAnotherFormatReducer(id, updateState, () => {});
      closeModal();
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
