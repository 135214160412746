/** @format */

import React, { useContext, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {
	LOCATION_CITY,
	LOCATION_COUNTRY,
	LOCATION_STATE,
	LOCATION_ZIP_CODE,
} from '../filterTypes';
import CreatableSelect from 'react-select/creatable';
import debounce from 'debounce-promise';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { MainContext } from '../../../../componants/filterContext/FilterContext';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import { States } from '../../../../Data/states';
import { FILTER_CONTACTS } from '../../../../componants/Context/Types';

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Location() {
	const [expanded, setExpanded] = React.useState(false);
	const [cityOptions, setCityOptions] = React.useState([]);

	const [isOpen, setIsOpen] = React.useState(false);
	const [isOpen2, setIsOpen2] = React.useState(false);
	const [isOpen3, setIsOpen3] = React.useState(false);
	const [isOpen4, setIsOpen4] = React.useState(false);

	const [stateOptions, setStateOptions] = React.useState([]);
	const [zipcodeOptions, setZipcodeOptions] = React.useState([]);
	const [countryOptions, setCountryOptions] = React.useState([]);

	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		setFilter,
		filter,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		setSortField,
		dispatch,
		anyFilterFlag,
		zipInput,
		setZipInput,
		setCountryInput,
	} = useContext(AuthContext);

	const handleChangeAccordian = (panel) => (event, newExpanded) => {
		if (panel === 'location country') {
			setIsOpen(newExpanded);
		}
		if (panel === 'location state') {
			setIsOpen2(newExpanded);
		}
		if (panel === 'location city') {
			setIsOpen3(newExpanded);
		}

		console.log(panel);
		setExpanded(newExpanded ? panel : false);
		setStateOptions(stateOptions?.filter((item) => item?.label?.length !== 0));
		setZipcodeOptions(
			zipcodeOptions?.filter((item) => item?.label?.length !== 0)
		);
		setCityOptions(cityOptions?.filter((item) => item?.label?.length !== 0));
		setCountryOptions(
			countryOptions?.filter((item) => item?.label?.length !== 0)
		);
	};

	const loadCountryOptions = (searchValue) => {
		const getData = async () => {
			const data = await globalFilterKeyToApi[LOCATION_COUNTRY](searchValue);
			const options = data.map((item) => {
				return {
					value: item.name,
					label: item.name,
				};
			});
			setCountryOptions(options);
		};
		getData();
	};

	const loadCityOptions = debounce((searchValue) => {
		const getData = async () => {
			const data = await globalFilterKeyToApi[LOCATION_CITY](
				filter[globalFilterKeyTofilterKey[LOCATION_STATE]],
				searchValue
			);
			const options = data.map((item) => {
				return {
					value: item.City,
					label: item.City,
				};
			});
			setCityOptions(options);
			return options;
		};
		return getData();
	}, 1000);

	const loadStateOptions = debounce((searchValue) => {
		const getData = async () => {
			const data = await globalFilterKeyToApi[LOCATION_STATE](searchValue);
			const labels = Object.values(States);
			const options = data.map((item) => {
				return {
					value: item,
					label: States[item] || item,
				};
			});
			setStateOptions(options);
			return options;
		};
		return getData();
	}, 1000);

	useEffect(() => {
		if (filter[globalFilterKeyTofilterKey[LOCATION_STATE]].length === 0) return;
		loadCityOptions('');
	}, [filter[globalFilterKeyTofilterKey[LOCATION_STATE]]]);

	const loadZipOptions = debounce((searchValue) => {
		const getData = async () => {
			const data = await globalFilterKeyToApi[LOCATION_ZIP_CODE](searchValue);
			const options = data.map((item) => {
				return {
					value: item.name,
					label: item.name,
				};
			});
			setZipcodeOptions(options);
			return options;
		};
		return getData();
	}, 1000);

	const handleChangeMultiSelect = (selectedOption) => {
		console.log(selectedOption);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		const formatedOption = selectedOption.map((item) => item.value);
		setGlobalSelectFilter((prev) => {
			return { ...prev, [expanded]: selectedOption };
		});
		setSortField({});
		setFilter((prev) => {
			return {
				...prev,
				[globalFilterKeyTofilterKey[expanded]]: formatedOption,
			};
		});
	};

	const keyDownHandler = (e, type) => {
		console.log(e);
		if (e.code === 'Enter') {
			if (type === 'one') {
				setExpanded(LOCATION_STATE);
			}
			if (type === 'two') {
				setExpanded(LOCATION_CITY);
			}
			setFirstTimeApiCall(false);
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			anyFilterFlag.current = true;
			dispatch({
				type: FILTER_CONTACTS,
			});
		}
	};

	useEffect(() => {
		loadStateOptions('');
		// loadZipOptions("");
		loadCountryOptions('');
	}, []);
	return (
		<div>
			<Accordion
				expanded={expanded === LOCATION_COUNTRY}
				onChange={handleChangeAccordian(LOCATION_COUNTRY)}
			>
				<AccordionSummary>
					<Typography className='f-16'>By Country</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Select
						isMulti
						onMenuOpen={() => setIsOpen(true)}
						onMenuClose={() => setIsOpen(false)}
						menuIsOpen={isOpen}
						createOptionPosition='first'
						isClearable={false}
						isSearchable={true}
						styles={{
							option: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
							placeholder: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
						}}
						closeMenuOnSelect={false}
						defaultOptions={true}
						value={globalSelectFilter[LOCATION_COUNTRY]}
						// loadOptions={loadCountryOptions}
						onChange={handleChangeMultiSelect}
						onKeyDown={(e) => keyDownHandler(e, 'one')}
						options={countryOptions}
						onInputChange={(value, action) => {
							if (action.action === 'input-change') setCountryInput(value);
						}}
						// inputValue={countryInput}
					/>
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={expanded === LOCATION_STATE}
				onChange={handleChangeAccordian(LOCATION_STATE)}
			>
				<AccordionSummary>
					<Typography className='f-16'>By State</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Select
						isMulti
						onMenuOpen={() => setIsOpen2(true)}
						onMenuClose={() => setIsOpen2(false)}
						menuIsOpen={isOpen2}
						createOptionPosition='first'
						isClearable={false}
						closeMenuOnSelect={false}
						styles={{
							option: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
							placeholder: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
						}}
						value={globalSelectFilter[LOCATION_STATE]}
						// loadOptions={loadStateOptions}
						onChange={handleChangeMultiSelect}
						defaultOptions={stateOptions}
						options={stateOptions}
						onKeyDown={(e) => keyDownHandler(e, 'two')}
						onInputChange={(value, action) => {
							if (action.action === 'input-change') {
								loadStateOptions(value);
							}
						}}
						// onChange={handleChangeSingleSelect}
						// options={stateOptions}
						// onInputChange={(value, action) => {
						//   if (action.action === "input-change") setStateInput(value);
						// }}
						// inputValue={stateInput}
					/>
				</AccordionDetails>
			</Accordion>
			{filter[globalFilterKeyTofilterKey[LOCATION_STATE]].length ===
			0 ? null : (
				<Accordion
					expanded={expanded === LOCATION_CITY}
					onChange={handleChangeAccordian(LOCATION_CITY)}
				>
					<AccordionSummary>
						<Typography className='f-16'>By City</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Select
							isMulti
							onMenuOpen={() => setIsOpen3(true)}
							onMenuClose={() => setIsOpen3(false)}
							menuIsOpen={isOpen3}
							closeMenuOnSelect={false}
							createOptionPosition='first'
							isClearable={false}
							isSearchable={true}
							styles={{
								option: (provided, state) => ({
									...provided,
									textAlign: 'left',
								}),
								placeholder: (provided, state) => ({
									...provided,
									textAlign: 'left',
								}),
							}}
							value={globalSelectFilter[LOCATION_CITY]}
							onChange={handleChangeMultiSelect}
							onKeyDown={(e) => keyDownHandler(e, 'three')}
							defaultOptions={cityOptions}
							options={cityOptions}
							onInputChange={(value, action) => {
								if (action.action === 'input-change') {
									loadCityOptions(value);
								}
							}}
							// options={cityOptions}
							// onInputChange={(value, action) => {
							//   if (action.action === "input-change") setCityInput(value);
							// }}
							// inputValue={cityInput}
						/>
					</AccordionDetails>
				</Accordion>
			)}
			<Accordion
				expanded={expanded === LOCATION_ZIP_CODE}
				onChange={handleChangeAccordian(LOCATION_ZIP_CODE)}
			>
				<AccordionSummary>
					<Typography className='f-16'>By ZipCode</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Select
						isMulti
						onMenuOpen={() => setIsOpen4(true)}
						onMenuClose={() => setIsOpen4(false)}
						menuIsOpen={isOpen4}
						closeMenuOnSelect={false}
						createOptionPosition='first'
						isClearable={false}
						isSearchable={true}
						defaultOptions={true}
						styles={{
							option: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
							placeholder: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
						}}
						value={globalSelectFilter[LOCATION_ZIP_CODE]}
						// loadOptions={loadZipOptions}
						onChange={handleChangeMultiSelect}
						onKeyDown={(e) => keyDownHandler(e, 'four')}
						options={zipcodeOptions}
						onInputChange={(value, action) => {
							if (action.action === 'input-change') setZipInput(value);
						}}
						// inputValue={zipInput}
					/>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
