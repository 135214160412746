/** @format */

import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assests/image/companylogo.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {
	POST_POTENTIAL_CUSTOMERS,
	SIGNUP_USER,
} from '../../componants/Context/Types';
import { getSubscriptionPlan } from '../../componants/Context/APIs';
import { AuthContext } from '../../componants/Context/AuthContext';
import Info from './Info';
import { NotificationManager } from 'react-notifications';

const SignUpScreen = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const emailParam = searchParams.get('email') || '';
	const planId = searchParams.get('planId') || '';
	const planType = searchParams.get('type') || '';
	const location = useLocation();
	const { dispatch } = useContext(AuthContext);
	const [planDetails, setPlanDetails] = useState([]);
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);
	const fpr = searchParams.get('fpr') || '';
	let captchaRef;

	const [initialValues, setInitialValues] = useState({
		name: location?.state?.name || '',
		email: location?.state?.email || emailParam || '',
		password: location?.state?.password || '',
		mobile: location?.state?.mobile || '',
		companyName: location?.state?.companyName || '',
		acceptTerms: false,
	});

	useEffect(() => {
		setInitialValues((prevValues) => ({
			...prevValues,
			name: location?.state?.name || '',
			email: location?.state?.email || emailParam || '',
			password: location?.state?.password || '',
			mobile: location?.state?.mobile || '',
			companyName: location?.state?.companyName || '',
		}));
	}, [location]);

	const onChangeCaptcha = (value) => {
		setIsCaptchaVerified(true);
		setCaptchaToken(value);
	};

	const handleSubmit = (values, resetForm) => {
		if (initialValues?.acceptTerms === false) {
			NotificationManager.error(
				'Please read and accept the terms & conditions.'
			);
			return;
		}
		if (initialValues?.name === '' || initialValues.companyName === '') {
			NotificationManager.error('Please fill all the mandatory details.');
			return;
		}
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(initialValues?.email)) {
			NotificationManager.error('Invalid email format.');
			return;
		}
		if (initialValues?.password?.length < 6) {
			NotificationManager.error('Password must be at least 6 characters long');
			return;
		}
		let formatedValues = {
			...initialValues,
			planId: planId,
			isAnnual: planType === 'year' ? true : false,
		};
		const isPlanId =
			!planId && !planId.length > 0 && delete formatedValues['planId'];
		const isPlanType =
			!planType && !planType.length > 0 && delete formatedValues['isAnnual'];

		let withCaptcha = {
			...formatedValues,
			captcha_token: captchaToken,
			confirm_password: formatedValues.password,
			company_name: formatedValues.companyName,
		};

		let data = {
			...withCaptcha,
			fpr: fpr || '',
			mobile: withCaptcha?.mobile ? String(withCaptcha.mobile) : '',
		};

		dispatch({
			type: SIGNUP_USER,
			payload: data,
			resetForm: resetForm,
			setIsLoading: setIsLoading,
		});
	};

	useEffect(() => {
		if (emailParam && emailParam.length > 0) {
			dispatch({
				type: POST_POTENTIAL_CUSTOMERS,
				email: emailParam,
			});
		}
		if (planId && planType) {
			const fetchPlan = async () => {
				const res = await getSubscriptionPlan(planId);
				setPlanDetails(res?.data?.data);
			};
			fetchPlan();
		}
	}, []);

	return (
		<>
			<div className='min-h-screen w-full grid grid-cols-2 lg:grid-cols-3 bg-white text-left'>
				<div
					class='max-h-screen max-w-[36rem] mx-auto flex flex-col size-full bg-white col-span-2 overflow-auto'
					style={{ scrollbarWidth: 'none' }}
				>
					<header class='mb-auto z-50 w-full py-4'>
						<nav class='px-4 sm:px-6 lg:px-8'>
							<img src={logo} alt='HealthDBi' className='h-12 w-auto' />
						</nav>
					</header>

					<main id='content'>
						<div class='py-6 px-4 sm:px-6 lg:px-8'>
							<h1 class='block text-xl font-bold text-gray-800'>
								Create your HealthDBi account
							</h1>
							<p class='mt-0.5 text-gray-600 text-sm'>
								Get Physicians, HCPs, and Nurses Emails with 96% accuracy
							</p>

							{planDetails?.title && (
								<div className='mt-4 flex justify-start gap-2 bg-white shadow-sm border !border-slate-300 rounded-md p-2'>
									<div className='bg-blue-600 rounded-md flex justify-center items-center p-2'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='size-4 text-white'
										>
											<path d='M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8' />
											<path d='M21 3v5h-5' />
											<path d='M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16' />
											<path d='M8 16H3v5' />
										</svg>
									</div>
									<div>
										<p className='text-sm font-medium'>
											{planDetails?.title &&
												'Subscription: ' +
													planDetails?.title +
													` ($${
														planType === 'year'
															? planDetails?.annually_amount
															: planDetails?.monthly_amount
													})`}
										</p>
										<p className='text-xs font-medium'>
											{planType === 'year' ? 'Yearly' : 'Monthly'}
										</p>
									</div>
								</div>
							)}
							<div className='mt-4'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Name:
								</h2>
								<input
									type='text'
									value={initialValues?.name}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											name: e.target.value,
										})
									}
									placeholder='Enter your full name'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Email:
								</h2>
								<input
									type='text'
									value={initialValues?.email}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											email: e.target.value,
										})
									}
									placeholder='Enter your email'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Company Name:
								</h2>
								<input
									type='text'
									value={initialValues?.companyName}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											companyName: e.target.value,
										})
									}
									placeholder='Enter your company name'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3 relative'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Password:
								</h2>
								<input
									type='text'
									value={initialValues?.password}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											password: e.target.value,
										})
									}
									placeholder='Enter your password'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Mobile (Optional):
								</h2>
								<input
									type='text'
									value={initialValues?.mobile}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											mobile: e.target.value,
										})
									}
									placeholder='Enter your mobile number'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3'>
								<ReCAPTCHA
									ref={(r) => {
										if (r) {
											return (captchaRef = r);
										}
									}}
									sitekey='6LeT9UwhAAAAAHIV4xebP9-8WibYs5iufcHiYGW8'
									onChange={onChangeCaptcha}
								/>
							</div>
							<div className='mt-3 flex gap-2 items-center'>
								<div className='custom-checkbox'>
									<input
										type='checkbox'
										checked={initialValues?.acceptTerms}
										onChange={(e) => {
											setInitialValues({
												...initialValues,
												acceptTerms: e.target.checked,
											});
										}}
									/>
									<div class='check'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											class='w-3 h-3 mb-1'
											viewBox='0 0 20 20'
											fill='currentColor'
											stroke='currentColor'
											stroke-width='1'
										>
											<path
												fill-rule='evenodd'
												d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
												clip-rule='evenodd'
											></path>
										</svg>
									</div>
								</div>
								<p class='text-sm text-gray-700'>
									I agree to the{' '}
									<span
										onClick={() => {
											navigate('/terms-conditions');
										}}
										className='text-blue-600 cursor-pointer hover:underline hover:text-blue-700'
									>
										Terms & Conditions.
									</span>
								</p>
							</div>

							<button
								onClick={handleSubmit}
								class='mt-4 w-full !py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none'
							>
								{isLoading ? (
									<div
										class='animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full'
										role='status'
										aria-label='loading'
									>
										<span class='sr-only'>Loading...</span>
									</div>
								) : (
									'Signup'
								)}
							</button>
						</div>
					</main>
					<footer class='mt-auto text-left pb-5'>
						<div class='max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8'>
							<p class='text-sm text-gray-700'>
								Already have an account?{' '}
								<span
									onClick={() => {
										navigate('/login');
									}}
									className='text-blue-600 cursor-pointer hover:underline hover:text-blue-700'
								>
									Sign in here
								</span>
							</p>
						</div>
					</footer>
				</div>
				<Info />
			</div>
		</>
	);
};
export default SignUpScreen;
