/** @format */
import { useEffect, useState, useRef, useContext } from 'react';
import TableSkeltonLoader from '../../../componants/comman/TableSkeltonLoader/TableSkeltonLoader';
import DynamicTable from '../../../Elements/Misc/DTable';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import {
	addEnhancerFile,
	checkInternalStatus,
	getCommonInternalFileVerificationApi,
	getInternalDays,
	getInternalFileVerification,
	internalDownloadFile,
	verificationCompletedAPi,
} from '../../../componants/Context/APIs';
import { downloadCSV } from 'download-csv';
import OverlayModal from '../../../Elements/Misc/Modal';
import * as XLSX from 'xlsx';
import InsideFileVerification from '../../../componants/Internal/InternalIndside/InsideFileVerification';
import { AuthContext } from '../../../componants/Context/AuthContext';
import PopUpModel from '../../../componants/comman/PopUpModel';
import UpdateDaysModal from '../../../componants/Internal/InternalComponent/UpdateDays';
import useAuth from '../../../hooks/useAuth';

const header = [
	{ key: 'fullname', title: 'FullName', label: 'FullName' },
	{ key: 'email', title: 'Email', label: 'Email' },
	{ key: 'title', title: 'Title', label: 'Title' },

	{ key: 'firstname', title: 'First Name', label: 'First Name' },
	{ key: 'lastname', title: 'Last Name', label: 'Last Name' },
	{ key: 'companyname', title: 'Company', label: 'Company' },
	{ key: 'level', title: 'Seniority', label: 'Seniority' },
	{ key: 'department', title: 'Departments', label: 'Departments' },
	{ key: 'contactphone', title: 'First Phone', label: 'First Phone' },
	{ key: 'mobilephone', title: 'Mobile Phone', label: 'Mobile Phone' },
	{ key: 'companyphone', title: 'Corporate Phone', label: 'Corporate Phone' },
	{ key: 'directdail', title: 'Work Direct Phone', label: 'Work Direct Phone' },
	{ key: 'employee', title: '# Employees', label: '# Employees' },
	{ key: 'industry', title: 'Industry', label: 'Industry' },
	{ key: 'keywords', title: 'Keywords', label: 'Keywords' },
	{ key: 'webaddress', title: 'Website', label: 'Website' },
	{
		key: 'people_linkedin_url',
		title: 'Person Linkedin Url',
		label: 'Person Linkedin Url',
	},
	{
		key: 'company_linkedin_url',
		title: 'Company Linkedin Url',
		label: 'Company Linkedin Url',
	},
	{ key: 'facebook_url', title: 'Facebook Url', label: 'Facebook Url' },
	{ key: 'twitter_url', title: 'Twitter Url', label: 'Twitter Url' },
	{ key: 'address', title: 'Address', label: 'Address' },
	{ key: 'city', title: 'City', label: 'City' },
	{ key: 'state', title: 'State', label: 'State' },
	{ key: 'country', title: 'Country', label: 'Country' },
	{
		key: 'company_address',
		title: 'Company Address',
		label: 'Company Address',
	},
	{ key: 'company_city', title: 'Company City', label: 'Company City' },
	{ key: 'company_state', title: 'Company State', label: 'Company State' },
	{
		key: 'company_country',
		title: 'Company Country',
		label: 'Company Country',
	},
	{ key: 'technologies', title: 'Technologies', label: 'Technologies' },
	{ key: 'revenue', title: 'Annual Revenue', label: 'Annual Revenue' },
	{ key: 'seodescription', title: 'SEO Description', label: 'SEO Description' },
	{ key: 'emailstatus', title: 'Status', label: 'Status' },
];

const EmailVerifier = () => {
	const uploadRef = useRef(null);
	const { profile } = useContext(AuthContext);
	const { auth } = useAuth();
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [total, setTotal] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const [days, setDays] = useState(0);
	const [fileName, setFileName] = useState('');
	const [loader, setLoader] = useState(false);
	const [csvFile, setCsvFile] = useState(null);
	const [fileCol, setFileCol] = useState([]);
	const [progress, setProgress] = useState(0);
	const [fileSize, setFileSize] = useState(0);
	const [fileKey, setFileKey] = useState(0);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const [data, setData] = useState([]);
	const [adminSelection, setAdminSelection] = useState('All');
	const [downloadOption, setDownloadOption] = useState({
		status: false,
		sys_filename: '',
		filename: '',
	});

	const handleRefresh = () => {
		getFileVerification();
	};

	const processData = (dataString) => {
		const dataStringLines = dataString.split(/\r\n|\n/);
		const headers = dataStringLines[0].split(
			/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
		);

		const list = [];
		for (let i = 1; i < dataStringLines.length; i++) {
			const row = dataStringLines[i].split(
				/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
			);
			if (headers && row.length == headers.length) {
				const obj = {};
				for (let j = 0; j < headers.length; j++) {
					let d = row[j];
					if (d.length > 0) {
						if (d[0] == '"') d = d.substring(1, d.length - 1);
						if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
					}
					if (headers[j]) {
						obj[headers[j]] = d;
					}
				}
				if (Object.values(obj).filter((x) => x).length > 0) {
					list.push(obj);
				}
			}
		}
		const columns = headers.map((c) => ({
			label: c,
			value: c,
		}));

		setData(list);
		setFileCol(columns);
		setLoader(false);
	};

	const uploadButtonHandler = (e) => {
		const file = e.target.files[0];
		if (!file) return;

		setLoader(true);
		setCsvFile(file);
		setFileName(file.name);
		setFileSize((file.size / 1024).toFixed(2));

		const reader = new FileReader();
		reader.onprogress = (event) => {
			if (event.lengthComputable) {
				const percentLoaded = Math.round((event.loaded / event.total) * 100);
				setProgress(percentLoaded);
			}
		};

		reader.onload = (evt) => {
			const arrayBuffer = evt.target.result;
			const data = new Uint8Array(arrayBuffer);
			const wb = XLSX.read(data, { type: 'array' });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const csvData = XLSX.utils.sheet_to_csv(ws, { header: 1 });
			processData(csvData);
		};

		reader.readAsArrayBuffer(file);
	};

	// Handle drag and drop events
	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const file = e.dataTransfer.files[0];
		if (file) {
			uploadButtonHandler({ target: { files: [file] } });
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const columns = [
		{ title: 'File Name' },
		{ title: 'Uploaded By' },
		{ title: 'Date' },
		{ title: 'Data Count' },
		{ title: 'Valid Only' },
		{ title: 'Catch-All' },
		{ title: 'Invalid' },
		{ title: 'Status' },
		{ title: 'Progress' },
		{ title: 'Time Left (Est.)' },
		{ title: 'Action' },
	];

	const [total1, setTotal1] = useState(0);
	const [total2, setTotal2] = useState(0);
	const [total3, setTotal3] = useState(0);
	const [total4, setTotal4] = useState(0);

	function secondsToMinutes(seconds) {
		return Number(seconds) / 60;
	}

	const getDays = async () => {
		try {
			const res = await getInternalDays();
			setDays(res?.data?.data[0][0]?.days || 0);
		} catch (error) {}
	};

	useEffect(() => {
		if (auth.role === 'admin') {
			getDays();
		}
	}, []);

	const getFileVerification = async () => {
		setIsLoading(true);
		try {
			let res;
			if (adminSelection === 'All' && auth.role === 'admin') {
				const resp = await getCommonInternalFileVerificationApi(
					'',
					'',
					page,
					rowsPerPage
				);
				res = resp.data;
			} else {
				const resp = await getInternalFileVerification(
					'',
					'',
					page,
					rowsPerPage
				);
				res = resp.data;
			}

			setRows([]);
			let allData = [];
			res?.final[0]?.forEach((item, index) => {
				if (item?.status !== 'Verified' && item?.status !== 'Failed') {
					runBounceAPi(item);
				} else {
					allData.push({ ...item, percent: 100, timeLeft: 0 });
					setRows(allData);
				}
			});
			setTotal(res?.totalCount[0]);
			setTotalCount(res?.totalPages);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const downloadFileHandler = async (name, type, fileName) => {
		try {
			const res = await internalDownloadFile({
				sys_filename: name,
				mvstatus: type,
			});

			const data = res?.data[0] || [];
			let sheetData = [];
			data?.forEach((item) => {
				sheetData.push(item);
			});
			const filteredHeaders = header.filter((header) =>
				Object.keys(sheetData[0]).includes(header.key)
			);
			downloadCSV(sheetData, filteredHeaders, fileName);
		} catch (error) {}
	};

	const runBounceAPi = async (data) => {
		const body = {
			sys_filename: data?.sys_filename,
			mvfileid: data?.mvfileid,
		};
		try {
			const res = await checkInternalStatus(body);
			setRows((prev) => [
				{
					...data,
					percent: res?.data?.data?.percent,
					timeLeft: secondsToMinutes(res?.data?.data?.estimated_time_sec),
				},
				...prev,
			]);
		} catch (error) {}
	};

	const completeHandler = async (body) => {
		try {
			await verificationCompletedAPi(body);
			getFileVerification();
		} catch (error) {}
	};

	const checkStatus = async (data, index) => {
		const body = {
			sys_filename: data?.sys_filename,
			mvfileid: data?.mvfileid,
		};
		try {
			const res = await checkInternalStatus(body);
			if (
				res?.data?.data?.percent === 100 ||
				res?.data?.data?.percent === '100'
			) {
				completeHandler(body);
			} else {
				let arrData = rows;
				arrData[index]['timeLeft'] = secondsToMinutes(
					res?.data?.data?.estimated_time_sec
				);
				arrData[index]['percent'] = res?.data?.data?.percent;
				setRows(arrData);
			}
		} catch (error) {}
	};

	useEffect(() => {
		setRows([]);
		getFileVerification();
	}, [page, rowsPerPage, adminSelection]);

	useEffect(() => {
		const t1 = 'invalid';
		const t2 = 'valid';
		const t3 = 'catch_all';
		const t4 = 'Total';
		if (total?.length > 0) {
			for (var i = 0; i < total.length; i++) {
				if (total[i]?.mvstatus === t1) {
					setTotal1(total[i]?.Count || 0);
				}
				if (total[i]?.mvstatus === t2) {
					setTotal2(total[i]?.Count || 0);
				}
				if (total[i]?.mvstatus === t3) {
					setTotal3(total[i]?.Count || 0);
				}
				if (total[i]?.mvstatus === t4) {
					setTotal4(total[i]?.Count || 0);
				}
			}
		}
	}, [total]);

	useEffect(() => {
		let interval;
		if (rows?.length > 0) {
			interval = setInterval(() => {
				setIsLoading(false);
				rows.forEach((item, index) => {
					if (item?.status && item?.status !== 'Verified') {
						checkStatus(item, index);
					}
				});
			}, 30000);
		}
		return () => {
			clearInterval(interval);
		};
	}, [rows]);

	const openDownloadOption = (sys_filename, fileName) => {
		setDownloadOption({
			status: true,
			sys_filename: sys_filename,
			filename: fileName,
		});
	};

	const renderCell = (row, col) => {
		if (col.title === 'File Name') {
			return row?.filename;
		} else if (col.title === 'Uploaded By') {
			return row?.uploadby;
		} else if (col.title === 'Status') {
			return row?.status === 'Under Verification' ? (
				<div>
					<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-blue-100 text-blue-600 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3 animate-spin'
						>
							<path d='M12 2v4' />
							<path d='m16.2 7.8 2.9-2.9' />
							<path d='M18 12h4' />
							<path d='m16.2 16.2 2.9 2.9' />
							<path d='M12 18v4' />
							<path d='m4.9 19.1 2.9-2.9' />
							<path d='M2 12h4' />
							<path d='m4.9 4.9 2.9 2.9' />
						</svg>
						Verifing
					</span>
				</div>
			) : row?.status === 'Verified' ? (
				<div>
					<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-teal-200 text-teal-800 rounded-full'>
						<svg
							class='shrink-0 size-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'></path>
							<path d='m9 12 2 2 4-4'></path>
						</svg>
						Completed
					</span>
				</div>
			) : (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-red-200 text-red-700 rounded-full'>
						<svg
							class='shrink-0 size-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path d='m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z'></path>
							<path d='M12 9v4'></path>
							<path d='M12 17h.01'></path>
						</svg>
						Failed
					</span>
				</div>
			);
		} else if (col.title === 'Name') {
			return row?.subscription_type;
		} else if (col.title === 'Date') {
			return new Date(row?.created_at).toLocaleString('en-US', {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				hour12: true,
			});
		} else if (col.title === 'Data Count') {
			return row?.totalCount;
		} else if (col.title === 'Valid Only') {
			return row?.validCount;
		} else if (col.title === 'Catch-All') {
			return row?.catchAllCount;
		} else if (col.title === 'Invalid') {
			return row?.invalidCount;
		} else if (col.title === 'Progress') {
			return row?.percent?.toFixed(2) + '%';
		} else if (col.title === 'Time Left (Est.)') {
			return row?.timeLeft.toFixed(2) + ' mins';
		} else if (col.title === 'Action') {
			return (
				<button
					onClick={() => openDownloadOption(row?.sys_filename, row?.filename)}
					disabled={row?.status !== 'Verified'}
					className='cursor-pointer flex items-center gap-1 text-blue-600 hover:underline focus:outline-none border-none disabled:opacity-50 disabled:pointer-events-none'
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						stroke-width='2'
						stroke-linecap='round'
						stroke-linejoin='round'
						class='size-4'
					>
						<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
						<polyline points='7 10 12 15 17 10' />
						<line x1='12' x2='12' y1='15' y2='3' />
					</svg>
				</button>
			);
		}

		return row[col.title];
	};

	const [modalOpen, setModalOpen] = useState(false);

	const openModal = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setTimeout(() => {
			setModalOpen(false);
		}, 200);
	};

	const totalRow = () => {
		return (
			<tr className='text-xs font-medium border-b !border-gray-300 bg-slate-100'>
				<td className='text-start px-4 py-2'>Total</td>
				<td colSpan={'2'} className='text-start px-4 py-2'></td>
				<td className='text-start px-4 py-2'>{total4}</td>
				<td className='text-start px-4 py-2'>{total2}</td>
				<td className='text-start px-4 py-2'>{total3}</td>
				<td className='text-start px-4 py-2'>{total1}</td>
				<td colSpan={'4'} className='text-start px-4 py-2'></td>
			</tr>
		);
	};

	return (
		<>
			<div>
				<div
					className={`flex flex-wrap items-center gap-4 ${
						auth?.role === 'admin' ? 'justify-between' : 'justify-end'
					}`}
				>
					{auth?.role === 'admin' && (
						<div className='flex items-center gap-2 mx-4 pt-3'>
							<p className='text-xs font-semibold text-gray-800'>
								Choose Type:
							</p>
							<div className='flex flex-wrap gap-x-2 gap-y-2 items-center px-2'>
								<div
									className={`rounded-full ${
										adminSelection === 'All'
											? 'border-check-active'
											: 'border-check-light'
									}`}
								>
									<h3
										onClick={() => setAdminSelection('All')}
										className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
									>
										<input
											name={'adminallnull'}
											type='radio'
											checked={adminSelection === 'All'}
											defaultChecked
											class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
										/>
										All Data
									</h3>
								</div>
								<div
									className={`rounded-full ${
										adminSelection === 'Admin'
											? 'border-check-active'
											: 'border-check-light'
									}`}
								>
									<h3
										onClick={() => setAdminSelection('Admin')}
										className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
									>
										<input
											name={'adminadmin1'}
											type='radio'
											checked={adminSelection === 'Admin'}
											class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
										/>
										Admin Data
									</h3>
								</div>
							</div>
						</div>
					)}
					<div className='flex justify-end items-center gap-2 px-4 pt-3'>
						{auth?.role === 'admin' && (
							<button
								className='focus:outline-none border-none bg-emerald-600 hover:bg-emerald-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2'
								onClick={() => setIsModal(true)}
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-4'
								>
									<path d='M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z' />
									<circle cx='12' cy='12' r='3' />
								</svg>
								Update Days
							</button>
						)}
						<button
							className='focus:outline-none border-none bg-purple-600 hover:bg-purple-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2'
							onClick={handleRefresh}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4'
							>
								<path d='M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8' />
								<path d='M21 3v5h-5' />
								<path d='M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16' />
								<path d='M8 16H3v5' />
							</svg>
							Refresh Data
						</button>
						<button
							className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2'
							onClick={openModal}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4 h-4'
							>
								<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
								<polyline points='17 8 12 3 7 8' />
								<line x1='12' x2='12' y1='3' y2='15' />
							</svg>
							Upload File
						</button>
					</div>
				</div>
				{isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={8} />
					</div>
				) : (
					<>
						<DynamicTable
							columns={columns}
							data={rows}
							renderCell={renderCell}
							total={totalRow}
						/>
					</>
				)}
				{!isLoading && (
					<PaginationRow
						totalResults={totalCount ? totalCount : 0}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				)}
			</div>
			<OverlayModal isOpen={modalOpen} onClose={closeModal}>
				<div className='w-[90vw] sm:w-[80vw] md:w-[60vw] lg:w-[45vw] transition-all ease-in-out duration-500'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>Upload File</h3>
						<div
							onClick={closeModal}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>

					{/* File Upload Trigger */}
					{!fileName && (
						<div
							className='mt-3 cursor-pointer p-4 flex justify-center bg-blue-100 border-2 border-dashed !border-blue-500 rounded-xl'
							data-hs-file-upload-trigger=''
							onDrop={handleDrop}
							onDragOver={handleDragOver}
						>
							<div className='text-center'>
								<span className='inline-flex justify-center items-center size-16 bg-blue-200 text-gray-800 rounded-full'>
									<svg
										className='shrink-0 size-6'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									>
										<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path>
										<polyline points='17 8 12 3 7 8'></polyline>
										<line x1='12' x2='12' y1='3' y2='15'></line>
									</svg>
								</span>

								<div className='mt-4 flex flex-wrap justify-center text-sm leading-6 text-gray-600'>
									<span className='pe-1 font-medium text-gray-800'>
										Drop your file here or
									</span>
									<span
										onClick={() => uploadRef.current?.click()}
										className='bg-transparent font-semibold text-blue-600 hover:!text-blue-500 rounded-lg decoration-2 hover:underline focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2'
									>
										browse
										<input
											ref={uploadRef}
											disabled={loader}
											key={fileKey}
											type='file'
											accept='.csv'
											hidden
											onChange={(e) => uploadButtonHandler(e)}
										/>
									</span>
								</div>

								<p className='mt-1 text-xs text-gray-700'>
									Supported Format: CSV | Max Limit:{' '}
									{profile?.upload_limit || 0}
								</p>
							</div>
						</div>
					)}

					{/* Preview Section */}
					{fileName && (
						<div className='mt-3 space-y-2 empty:mt-0 p-3 bg-white border border-solid border-gray-300 rounded-xl'>
							<div className='mb-1 flex justify-between items-center'>
								<div className='flex items-center gap-x-3'>
									<span
										className='size-10 flex justify-center items-center border border-gray-200 text-gray-700 rounded-lg'
										data-hs-file-upload-file-icon=''
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='lucide lucide-file-spreadsheet'
										>
											<path d='M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z' />
											<path d='M14 2v4a2 2 0 0 0 2 2h4' />
											<path d='M8 13h2' />
											<path d='M14 13h2' />
											<path d='M8 17h2' />
											<path d='M14 17h2' />
										</svg>
									</span>
									<div className='text-left'>
										<p className='text-sm font-medium text-gray-800'>
											<span
												className='truncate inline-block max-w-[300px] align-bottom'
												data-hs-file-upload-file-name=''
											>
												{fileName}
											</span>
										</p>
										<p
											className='text-xs text-gray-700'
											data-hs-file-upload-file-size=''
										>
											{fileSize} KB - {fileCol?.length} Headers
										</p>
									</div>
								</div>
								<div className='flex items-center gap-x-2'>
									<button
										type='button'
										className='text-gray-500 hover:text-gray-800 focus:outline-none focus:text-gray-800'
										onClick={() => {
											setFileName('');
											setCsvFile(null);
											setFileCol([]);
											setFileSize(0);
											setProgress(0);
											setFileKey((prev) => prev + 1);
										}}
									>
										<svg
											className='shrink-0 size-4'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										>
											<path d='M3 6h18'></path>
											<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6'></path>
											<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2'></path>
											<line x1='10' x2='10' y1='11' y2='17'></line>
											<line x1='14' x2='14' y1='11' y2='17'></line>
										</svg>
									</button>
								</div>
							</div>

							{/* Progress Bar */}
							<div className='flex items-center gap-x-3 whitespace-nowrap'>
								<div
									className='flex w-full h-2 bg-gray-200 rounded-full overflow-hidden'
									role='progressbar'
									aria-valuenow={progress}
									aria-valuemin='0'
									aria-valuemax='100'
									data-hs-file-upload-progress-bar=''
								>
									<div
										className={`flex flex-col justify-center rounded-full overflow-hidden ${
											progress === 100 ? 'bg-green-500' : 'bg-blue-600'
										} text-xs text-white text-center whitespace-nowrap transition-all duration-500`}
										style={{ width: `${progress}%` }}
										data-hs-file-upload-progress-bar-pane=''
									></div>
								</div>
								<div className='w-10 text-end'>
									<span className='text-sm text-gray-800'>
										<span data-hs-file-upload-progress-bar-value=''>
											{progress}
										</span>
										%
									</span>
								</div>
							</div>
						</div>
					)}

					{fileName && (
						<InsideFileVerification
							tableData={data}
							tableColumn={fileCol}
							fileName={fileName}
							setScreenOpen={'File Verification'}
							limit={profile?.upload_limit || 0}
						/>
					)}
				</div>
			</OverlayModal>
			<PopUpModel
				open={isModal}
				title={'Update Days'}
				close={() => {
					setIsModal(false);
				}}
			>
				<UpdateDaysModal
					days={days}
					setDays={setDays}
					setIsModal={setIsModal}
				/>
			</PopUpModel>
			{downloadOption?.status && (
				<div className='fixed inset-0 z-50 flex justify-center items-start'>
					<div
						className='absolute inset-0 bg-slate-900 opacity-60'
						onClick={() =>
							setDownloadOption({
								status: false,
								sys_filename: '',
								filename: '',
							})
						}
					></div>
					<div
						className={`w-auto translate-y-40 transform border-none transition-transform duration-200 bg-white rounded-lg shadow-lg`}
					>
						<ul class='max-w-xs flex flex-col cursor-pointer'>
							<li
								onClick={() =>
									downloadFileHandler(
										downloadOption.sys_filename,
										'all',
										downloadOption.filename
									)
								}
								class='inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 hover:!bg-slate-100 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg'
							>
								All Data
							</li>
							<li
								onClick={() =>
									downloadFileHandler(
										downloadOption.sys_filename,
										'valid',
										downloadOption.filename
									)
								}
								class='inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 hover:!bg-slate-100 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg'
							>
								Valid Only
							</li>
							<li
								onClick={() =>
									downloadFileHandler(
										downloadOption.sys_filename,
										'catch_all',
										downloadOption.filename
									)
								}
								class='inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 hover:!bg-slate-100 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg'
							>
								Catch-All
							</li>
							<li
								onClick={() =>
									downloadFileHandler(
										downloadOption.sys_filename,
										'invalid',
										downloadOption.filename
									)
								}
								class='inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border border-gray-200 text-gray-800 hover:!bg-slate-100 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg'
							>
								Invalid
							</li>
						</ul>
					</div>
				</div>
			)}
		</>
	);
};

export default EmailVerifier;
