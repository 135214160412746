import React from 'react'
import CommanPopUp from './CommanPopUp'
import DoneWithUpload from './DoneWithUpload'

const CompanyUrl = () => {
  return (
    <>
        
        </>
  )
}

export default CompanyUrl