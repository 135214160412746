import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { ASSIGN_CREDITS, CANCEL_SUBSCRIPTION } from "../Context/Types";
import { NotificationManager } from "react-notifications";
import Radio from "@mui/material/Radio";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PopUpModel from "./PopUpModel";

const reasonOptions = [
  "Pricing",
  "Data Quality",
  "Platform Utility",
  "Found a better vendor",
  "Other",
];

const CancelSubModel = (props) => {
  const [value, setValue] = React.useState();
  const [otherReason, setOtherReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);

  const handleSubmit = () => {
    if (!value || !otherReason) {
      NotificationManager.error("Please select a reason");
      return;
    }
    dispatch({
      type: CANCEL_SUBSCRIPTION,
      values: {
        reason: value === "Other" ? otherReason : value,
      },
      callback: props.close,
      setIsLoading: setIsLoading,
    });
  };

  return (
    <PopUpModel open={props.open} title={props.title} close={props.close}>
      <div className=" w-100 d-flex flex-column mylist-Update">
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Reason for Cancellation
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={(event) => setValue(event.target.value)}
          >
            {reasonOptions.map((item) => {
              return (
                <FormControlLabel
                  value={item}
                  control={<Radio />}
                  label={item}
                />
              );
            })}
            {value === "Other" && (
              <TextareaAutosize
                InputLabelProps={{ shrink: true }}
                required
                className="Add-package m-1"
                minRows={3}
                placeholder="Enter Reason"
                style={{ width: 300 }}
                value={otherReason}
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
              />
            )}
          </RadioGroup>
        </FormControl>
      </div>
      <div className="m-0 d-flex justify-content-end">
        <Button
          variant="contained"
          className="m-2 p-2 mr-0"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress sx={{ fontSize: "0.2rem", color: "white" }} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </PopUpModel>
  );
};

export default CancelSubModel;
