import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react';
import CommonFileVerification from './CommonFileVerfication';
import CommonCompany from './CommonCompany';
import CommonProject from './CommonProject';
import CommonBounce from './CommonBounce';
import { checkInternalStatus, getCommonInternalFileVerificationApi } from '../../componants/Context/APIs';

const CommonInternal = () => {
    const [titlebtn, settitlebtn] = useState("File Verification");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [verData, setVerData] = useState([]);
    const [verLoader, setVerLoader] = useState(false);
    const [counts, setCounts] = useState([]);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);

    function secondsToMinutes(seconds) {
        return Number(seconds) / 60;
    }
    const runBounceAPi = async (data) => {
        const body = {
            sys_filename: data?.sys_filename,
            mvfileid: data?.mvfileid
        }
        try {
            const res = await checkInternalStatus(body);
            setVerData((prev) => [{ ...data, percent: res?.data?.data?.percent, timeLeft: secondsToMinutes(res?.data?.data?.estimated_time_sec) }, ...prev])
        } catch (error) {
            
        }
    }
    const getFileVerification = async (from, to) => {
        try {
            setVerLoader(true);
            const res = await getCommonInternalFileVerificationApi(from, to, page);
            setVerData([]);
            let allData = [];
            res?.data?.final[0]?.forEach((item, index) => {
                setVerLoader(true);
                if(item?.status !== "Verified") {
                    runBounceAPi(item);
                }else {
                    allData.push({...item, percent: 100, timeLeft: 0});
                    setVerData(allData);
                }
            });
            setPages(res?.data?.pages);
            setCounts(res?.data?.totalCount[0] || []);
            setVerLoader(false);
        } catch (error) {
            setVerLoader(false);
        }
    }

    const onDateChange = (e) => {
        setFromDate(e.target.value)
    }
    const onDateChange2 = (e) => {
        setToDate(e.target.value)
    }

    useEffect(() => {
        if (fromDate && toDate) {
            getFileVerification(fromDate, toDate);
        }
    }, [fromDate, toDate])

    return (
        <div className='p-3'>
            <div className="d-flex justify-content-end  pb-0 pt-0  hadbar-btn header_internal mb-2 w-100" style={{ boxShadow: 'none' }}>
                <Button
                    variant={`${titlebtn == "File Verification" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight word-break f-16"
                    onClick={() => {
                        settitlebtn("File Verification");
                    }}
                >
                    File Verification
                </Button>
                <Button
                    variant={`${titlebtn == "Company Profile" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        settitlebtn("Company Profile");
                    }}
                >
                    Company Profile
                </Button>
                <Button
                    variant={`${titlebtn == "Project File" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        settitlebtn("Project File");
                    }}
                >
                    Project File
                </Button>
                <Button
                    variant={`${titlebtn == "Bounce Repository" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        settitlebtn("Bounce Repository");
                    }}
                >
                    Bounce Repository
                </Button>
            </div>
            <div className='d-flex justify-content-end align-items-center mb-2'>
                {
                    titlebtn === "File Verification" && (
                        <div className='internalDateContainer'>
                            <span className='me-2 f-16'>From:</span>
                            <input className='internalDatePicker me-2' type="date" name="" id="" onChange={onDateChange} />
                            <span className='me-2 f-16'>To:</span>
                            <input className='internalDatePicker' type="date" name="" id="" onChange={onDateChange2} />
                        </div>
                    )
                }

            </div>
            {
                titlebtn === "File Verification" && <CommonFileVerification setVerData={setVerData} verData={verData} setVerLoader={setVerLoader} verLoader={verLoader} counts={counts} setCounts={setCounts} setPages={setPages} pages={pages} setPage={setPage} page={page} />
            }
            {
                titlebtn === "Company Profile" && <CommonCompany />
            }
            {
                titlebtn === "Project File" && <CommonProject />
            }
            {
                titlebtn === "Bounce Repository" && <CommonBounce />
            }
        </div>
    )
}

export default CommonInternal;