import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import _ from "lodash";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Button, InputAdornment } from "@mui/material";
import { AuthContext } from "../../../componants/Context/AuthContext";
import {
  CREATE_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from "../../../componants/Context/Types";
import { CircularProgress } from "@mui/material";

const AddPackage = (props) => {
  const [info, setInfo] = useState({
    title: "",
    desc: "",
    monthly_amount: "",
    monthly_credits: "",
    annually_amount: "",
    annually_credits: "",
    cost_per_user: "",
    // validity_days: "",
    no_of_user: "",
    // amount: "",
    // credits: "",
    cost_per_credit: "",
  });
  const { dispatch, subscriptionFeatures } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);

  // const Input = styled('input')({
  //     display: 'none',
  //   });
  useEffect(() => {
    if (props.id !== null) {
      dispatch({
        type: GET_SUBSCRIPTION,
        payload: props.id,
        updateState: setInfo,
        setSelectedOption: setSelectedOption,
        setIsLoading: setIsLoading,
      });
    }
    return () => {
      props.setId(null);
    };
  }, [props.id]);

  const handleSubmit = () => {
    let values = {
      ...info,
      // validity_days: +info.validity_days,
      no_of_user: +info.no_of_user,
      // amount: +info.amount,
      // credits: +info.credits,
      monthly_amount: +info.monthly_amount,
      monthly_credits: +info.monthly_credits,
      annually_amount: +info.annually_amount,
      annually_credits: +info.annually_credits,
      cost_per_credit: +info.cost_per_credit,
      cost_per_user: +info.cost_per_user,
      features: selectedOption.map((item) => item.value),
      type: "COMMON",
    };
    if (props.id !== null) {
      values = _.omit(values, [
        "_id",
        "createdAt",
        "updatedAt",
        "__v",
        "stripe_product_id",
        "stripe_price_id",
        "stripe_annual_price_id",
        "stripe_month_price_id",
        "stripe_cpc_price_id",
        "stripe_product_id",
        "stripe_cpc_product_id",
      ]);
      dispatch({
        type: UPDATE_SUBSCRIPTION,
        payload: props.id,
        values: values,
        updateState: props.updateState,
        setRowLoading: props.setRowLoading,
        setmodel: props.setmodel,
        setIsLoading: setIsLoading,
      });
    } else {
      dispatch({
        type: CREATE_SUBSCRIPTION,
        payload: values,
        updateState: props.updateState,
        setRowLoading: props.setRowLoading,
        setmodel: props.setmodel,
        setIsLoading: setIsLoading,
      });
    }
  };

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          <div className="d-grid justify-content-center m-2">
            <TextField
              InputLabelProps={{ shrink: true }}
              required
              id="outlined-basic"
              value={info?.title}
              size="small"
              className="Add-package m-2"
              name="Title"
              label="Title"
              variant="outlined"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, title: e.target.value };
                });
              }}
            />
            <div className="d-flex justify-content-between">
              <Select
                onChange={setSelectedOption}
                options={subscriptionFeatures}
                className="basic-multi-select w-100 m-2"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                isMulti
                placeholder="Features"
                defaultValue={selectedOption}
                required
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                id="outlined-basic"
                value={info?.cost_per_user}
                size="small"
                className="w-50 m-2"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                name=""
                label="Cost per User"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, cost_per_user: e.target.value };
                  });
                }}
              />
            </div>

            <div className="d-flex justify-content-between">
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                id="outlined-basic"
                value={info?.no_of_user.toString()}
                size="small"
                className="w-50 m-2"
                name="Number of Users Allowed"
                label="Number of Users Allowed"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, no_of_user: e.target.value };
                  });
                }}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                id="outlined-basic"
                value={info?.monthly_amount}
                size="small"
                className="w-50 m-2"
                name="Monthly Amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                label="Monthly Amount"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, monthly_amount: e.target.value };
                  });
                }}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                id="outlined-basic"
                value={info?.monthly_credits}
                size="small"
                className="w-50 m-2"
                name="Monthly Credits"
                label="Monthly Credits"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, monthly_credits: e.target.value };
                  });
                }}
              />
            </div>
            <div className="d-flex justify-content-between">
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                id="outlined-basic"
                value={info?.annually_amount}
                size="small"
                className="w-50 m-2"
                name="Annual Amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                label="Annual Amount"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, annually_amount: e.target.value };
                  });
                }}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                id="outlined-basic"
                value={info?.annually_credits}
                size="small"
                className="w-50 m-2"
                name="Annual Credits"
                label="Annual Credits"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, annually_credits: e.target.value };
                  });
                }}
              />

              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                required
                id="outlined-basic"
                value={info?.cost_per_credit}
                size="small"
                className="w-50 m-2"
                name="Cost per Credit"
                label="Cost per Credit"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, cost_per_credit: e.target.value };
                  });
                }}
              />
            </div>
            {/* <div className="d-flex justify-content-between">
              <TextField
                required
                id="outlined-basic"
                value={info?.stripe_product_id}
                size="small"
                className="w-50 m-2"
                name="Stripe Product Id"
                label="Stripe Product Id"
                variant="outlined"
                disabled={props.id ? true : false}
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, stripe_product_id: e.target.value };
                  });
                }}
              />
              <TextField
                required
                id="outlined-basic"
                value={info?.stripe_price_id}
                size="small"
                className="w-50 m-2"
                name="Stripe Price Id"
                label="Stripe Price Id"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, stripe_price_id: e.target.value };
                  });
                }}
              />
            </div> */}
            <TextareaAutosize
              InputLabelProps={{ shrink: true }}
              required
              className="Add-package m-2"
              aria-label="minimum height"
              minRows={3}
              value={info?.desc}
              placeholder="Description"
              onChange={(e) => {
                setInfo((prev) => {
                  return { ...prev, desc: e.target.value };
                });
              }}
            />
            <div className="d-flex justify-content-end ">
              <Button
                variant="contained"
                className=" m-2"
                onClick={handleSubmit}
              >
                Add
              </Button>
              <Button
                variant="contained"
                className="bg-danger m-2"
                onClick={() => props.setmodel(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPackage;
