import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { BUY_MORE_CREDITS } from "../Context/Types";
import { NotificationManager } from "react-notifications";
import PopUpModel from "./PopUpModel";
import Stack from "@mui/material/Stack";
import { Checkbox } from "antd";

const paymentGateway = [
  // { value: "PAYPAL", label: "PayPal" },
  { value: "STRIPE", label: "Stripe" },
];

const BuyMoreCreditPopUpModel = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [credits, setCredits] = useState("");
  const { dispatch, currentPlan } = useContext(AuthContext);
  const [selectedGateway, setSelectedGateway] = useState("STRIPE");

  const handleSubmit = () => {
    if (!credits) {
      //("Enter number of Credits");
      return;
    }
    if (credits <= 0) {
      //("Credits should be greater than zero");
      return;
    }
    if (credits % 100 !== 0) {
      //("Credits should be multiple of 100");
      setCredits(credits - (credits % 100));
      return;
    }
    if (!selectedGateway) {
      //("Choose a Payment Gateway.");
      return;
    }
    dispatch({
      type: BUY_MORE_CREDITS,
      values: {
        credit_count: credits,
        payment_gateway: selectedGateway,
      },
      setIsLoading: setIsLoading,
    });
  };
  return (
    <PopUpModel open={props.open} title={props.title} close={props.close}>
      <div>
        <div className=" w-100 d-flex flex-column mylist-Update">
          <div className="blue_text f-14">Enter # of Credits</div>
          <div>
            <TextField
              id="outlined-basic"
              size="small"
              inputProps={{ step: 100 }}
              type="number"
              className="user-input-fild w-100 f-14"
              label="Multiples of Hundreds"
              name="Multiples of Hundreds"
              value={credits}
              variant="outlined"
              onChange={(e) => {
                let num = e.target.value;
                setCredits(+num);
              }}
            />

            <Stack direction="row" sx={{ mt: 1 }} spacing={2}>
              <Button
                variant={"outlined"}
                size="small"
                onClick={() => {
                  setCredits(100);
                }}
                className="f-14"
              >
                100 Credits
              </Button>
              <Button
                variant={"outlined"}
                size="small"
                onClick={() => {
                  setCredits(1000);
                }}
                className="f-14"
              >
                1000 Credits
              </Button>
              <Button
                variant={"outlined"}
                size="small"
                onClick={() => {
                  setCredits(10000);
                }}
                className="f-14"
              >
                10,000 Credits
              </Button>
            </Stack>
            <small className="credit-color f-14">
              ${currentPlan?.cost_per_credit} USD Per Credit
            </small>
          </div>
        </div>
        <div className="blue_text">
          <small>Payment Gateway:</small>
          <div>
            {paymentGateway.map((item) => {
              return (
                <span key={item.value}>
                  <Checkbox
                    key={item.value}
                    onChange={(e) => {
                      setSelectedGateway(e.target.value);
                    }}
                    checked={item.value === selectedGateway}
                    value={item.value}
                  >
                    {item.label}
                  </Checkbox>
                </span>
              );
            })}
          </div>
        </div>
        <div class="m-0 d-flex justify-content-center">
          <Button
            variant="contained"
            className="mt-2 pt-2 mr-0 f-14"
            onClick={handleSubmit}
            size="small"
          >
            {isLoading ? (
              <CircularProgress sx={{ fontSize: "0.1rem", color: "white" }} />
            ) : (
              `Buy now $${currentPlan.cost_per_credit * credits}USD`
            )}
          </Button>
        </div>
      </div>
    </PopUpModel>
  );
};

export default BuyMoreCreditPopUpModel;
