import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AuthContext } from "../Context/AuthContext";
import { MainContext } from "../filterContext/FilterContext";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d8d8d8",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 3,
  },
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

export default function RangeSlider(props) {
  const [value, setValue] = useState(props.defaultValue);
  const { setFilter } = useContext(AuthContext);
  const { globalFilterKeyTofilterKey } = useContext(MainContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFilter(prev =>{
      return {
        ...prev,
        [globalFilterKeyTofilterKey[props.title]]:[newValue],
      }
    })
  };

  return (
    <div className="d-flex justify-content-center mt-4">
      <Box sx={{ width: 320 }}>
        <AirbnbSlider
          {...props}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          components={{ Thumb: AirbnbThumbComponent }}
        />
      </Box>
    </div>
  );
}
