import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { GET_LEAD_BY_CATEGORY } from "../../../../componants/Context/Types";

let column = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Speciality",
    dataIndex: "speciality",
  },
  {
    title: "Gender",
    dataIndex: "gender",
  },
];

const LeadsTable = ({ currCategory }) => {
  // const [model,setmodel] = useState(false)
  const { dispatch } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
  });

  useEffect(() => {
    if (currCategory) {
      dispatch({
        type: GET_LEAD_BY_CATEGORY,
        searchParams: {
          id: currCategory,
          page: pagination.current,
          limit: pagination.pageSize,
        },
        updateState: { setRows, setPagination },
        setIsLoading: setIsLoading,
      });
    }
  }, [currCategory]);

  const handleTableChange = (newPagination, filters, sorter) => {
    dispatch({
      type: GET_LEAD_BY_CATEGORY,
      searchParams: {
        id: currCategory,
        page: newPagination.current,
        limit: newPagination.pageSize,
      },
      updateState: { setRows, setPagination },
      setIsLoading: setIsLoading,
    });
  };

  return (
    <div className="">
      {currCategory ? "" : <p className="m-2">Select one of the Category</p>}
      {currCategory ? (
        <Table
          columns={column}
          dataSource={rows}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
        />
      ) : (
        ""
      )}

      {/* <PopUpModel 
      open={model}
      title={"Upload Leads"}
      close={()=>{setmodel(false)}}>

        <UploadLead close={()=>{setmodel(false)}}/>

      </PopUpModel> */}
    </div>
  );
};

export default LeadsTable;
