import { NotificationManager } from "react-notifications";
import {
  EXECUTIVE_FILTER,
  PHYSICIAN_FILTER,
} from "../../../pages/ContactSerch/filterCompo/filterTypes";
import {
  addItem,
  createListRequest,
  deleteListRequest,
  editListRequest,
  getAllList,
  createAndSaveItemToList,
} from "../APIs";

export const createList = async (value, setIsLoading, cb) => {
  setIsLoading((prev) => {
    return { ...prev, create: true };
  });
  try {
    const res = await createListRequest(value);
    if (res.data) {
      NotificationManager.success(res.data);
      cb();
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading((prev) => {
      return { ...prev, create: false };
    });
  }
};

export const getList = async (updateState, setIsLoading, dataType) => {
  setIsLoading(true);
  try {
    const res = await getAllList(dataType);
    if (res.data) {
      updateState(res.data);
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const addItemToList = async (
  values,
  folder_id,
  setIsLoading,
  closeModal,
  typesOfFilter
) => {
  let dataType;
  if (typesOfFilter === PHYSICIAN_FILTER) {
    dataType = "0";
  } else if (typesOfFilter === EXECUTIVE_FILTER) {
    dataType = "1";
  }

  setIsLoading(true);
  try {
    const res = await addItem(values, folder_id, dataType);
    if (res.data) {
      NotificationManager.success(res.data);
      closeModal();
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const deleteList = async (id, setIsLoading, cb) => {
  setIsLoading(true);
  try {
    const res = await deleteListRequest(id);
    if (res.data) {
      cb();
      NotificationManager.success("List Deleted..");
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const editList = async (values, setIsLoading, cb) => {
  setIsLoading(true);
  try {
    const res = await editListRequest(values);
    if (res.data) {
      NotificationManager.success(res.data);
      cb();
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const createAndSaveItemToListReducer = async (
  values,
  setIsLoading,
  closeModal,
  typesOfFilter
) => {
  setIsLoading(true);
  let dataType;
  if (typesOfFilter === PHYSICIAN_FILTER) {
    dataType = "0";
  } else if (typesOfFilter === EXECUTIVE_FILTER) {
    dataType = "1";
  }
  try {
    const res = await createAndSaveItemToList({
      ...values,
      dataType: dataType,
    });
    if (res.data) {
      NotificationManager.success(res.data);
      closeModal();
    }
  } catch (e) {
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
