/** @format */

import React, { useState, useEffect } from 'react';
import Location from './Location/Location';
import SpecialityType from './SpecialityType/SpecialityType';
import Title from './Title/Title';
import LicensedState from './LicensedState/LicensedState';
import Gender from './Gender/Gender';
import NpiNumber from './NPINumber/NpiNumber';
import HospitalType from './HospitalType/HospitalType';
import FirmType from './FirmType/FirmType';
import OwnerShip from './Ownership/Ownership';
import LevelID from './LevelID/LevelID';
import GeographicClassification from './GeographyClassification/GeographyClassification';
import Experience from './Experience/Experience';
import Speciality from './Speciality/Speciality';
import OfficeType from './OfficeType/OfficeType';
import EmailType from './EmailType/EmailType';

const IndustryTitleLocationType = (props) => {
	const [model, setmodel] = useState('');

	useEffect(() => {
		// const industry = [
		//   {
		//     title: "By Industry Category",
		//     compo: (
		//       <DropDownWithTreeCheckBox
		//         setDone={setDone}
		//         setContext={props.setfilterContext}
		//       />
		//     ),
		//   },
		//   {
		//     title: "Search by Industry Name",
		//     compo: <Searchbar setDone={setDone} />,
		//   },
		//   {
		//     title: "SIC Code",
		//     compo: (
		//       <DropdownCheckbox
		//         setDone={setDone}
		//         setContext={props.setfilterContext}
		//         title={"SIC Code"}
		//       />
		//     ),
		//   },
		//   {
		//     title: "NAICS Code",
		//     compo: (
		//       <DropdownCheckbox
		//         setDone={setDone}
		//         setContext={props.setfilterContext}
		//         title={"NAICS Code"}
		//       />
		//     ),
		//   },
		// ];
		const speciality = <Speciality />;
		const location = <Location />;
		const title = <Title />;
		const specialityType = <SpecialityType />;
		const licensedState = <LicensedState />;
		const gender = <Gender />;
		const npiNumber = <NpiNumber />;
		const hospitalType = <HospitalType />;
		const firmType = <FirmType />;
		const ownership = <OwnerShip />;
		const levelID = <LevelID />;
		const geographicClassification = <GeographicClassification />;
		const experience = <Experience />;
		const officetype = <OfficeType />;
		const emailtype = <EmailType />;

		// const exclusions = [
		//   // { title: "By Country" },
		//   // { title: "By Region" },
		//   { title: "By Keyword", compo: <MultiSelect /> },
		//   // { title: "By Metro Area" },
		//   { title: "List Upload", compo: <Uploadfile />, class: "upload" },
		//   {
		//     title: "Previous Download",
		//     compo: <MultiCheckBox />,
		//     class: "checkbox",
		//   },
		// ];

		// const type = [
		//   { title: "By Type", class: "sdsdsd", compo: <MultiSelect /> },
		//   { title: "By Fortune Ranking", compo: <MultiSelect /> },
		//   { title: "By Alexa Ranking", compo: <MultiSelect /> },
		// ];
		// const other = [
		//   { title: "Presence of Social Links" },
		//   { title: "Year Founded" },
		// ];
		// const exclusions = [
		//   { title: "Exclude by Keyword" },
		//   { title: "Exclude EU Contacts & Companies" },
		//   { title: "Upload a List" },
		//   { title: "Select From My List" },
		//   { title: "Exclude From Previous Downloads" },
		// ];

		if (props.type === 'location') setmodel(location);
		else if (props.type === 'specialityType') setmodel(specialityType);
		else if (props.type === 'title') setmodel(title);
		else if (props.type === 'gender') setmodel(gender);
		else if (props.type === 'licensedState') setmodel(licensedState);
		else if (props.type === 'npiNumber') setmodel(npiNumber);
		else if (props.type === 'hospitalType') setmodel(hospitalType);
		else if (props.type === 'firmType') setmodel(firmType);
		else if (props.type === 'ownership') setmodel(ownership);
		else if (props.type === 'levelID') setmodel(levelID);
		else if (props.type === 'geographicClassification')
			setmodel(geographicClassification);
		else if (props.type === 'experience') setmodel(experience);
		else if (props.type === 'speciality') setmodel(speciality);
		else if (props.type === 'officetype') setmodel(officetype);
		else if (props.type === 'emailtype') setmodel(emailtype);

		return () => {
			setmodel('');
		};
	}, [props.type]);

	// useEffect(() => {
	//   setcompName(props.compName);
	// }, [props.compName]);

	return (
		<>
			{/* <h5 className="text-left pb-1 text-bold">{compName}</h5> */}
			{model}
		</>
	);
};

export default IndustryTitleLocationType;
