import React, { useContext, useState } from "react";
import { DatePicker } from "antd";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { EXTEND_FREE_TRIAL } from "../Context/Types";
import { NotificationManager } from "react-notifications";
import PopUpModel from "./PopUpModel";
import moment from "moment";

const ExtendFreeTrialModel = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState();
  const { dispatch } = useContext(AuthContext);

  const disabledDate = (current) => {
    // Can not select days before endDate and currentDate
    const todayDate = moment().endOf("day");
    const subscriptionEndDate = moment(
      props?.info?.plan?.subscription_end_date
    ).endOf("day");

    return current && (current < todayDate || current < subscriptionEndDate);
  };

  const handleSubmit = () => {
    if (!date) {
      //("Enter a date");
      return;
    }

    dispatch({
      type: EXTEND_FREE_TRIAL,
      id: props.selectedCompanyByAdmin,
      date: date,
      updateState: props.updateState,
      setIsLoading: setIsLoading,
      closeModal: props.close,
    });
  };
  return (
    <PopUpModel open={props.open} title={props.title} close={props.close}>
      <div className=" w-100 d-flex flex-column mylist-Update">
        <div>
          <DatePicker
            format="YYYY-MM-DD"
            disabledDate={disabledDate}
            showTime={{
              defaultValue: moment(props?.info?.plan?.subscription_end_date),
            }}
            onChange={(date, dateString) => {
              setDate(moment(date).toISOString());
            }}
          />
        </div>
      </div>
      <div className="m-0 d-flex justify-content-end">
        <Button
          variant="contained"
          className="m-2 p-2 mr-0"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress sx={{ fontSize: "0.2rem", color: "white" }} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </PopUpModel>
  );
};

export default ExtendFreeTrialModel;
