import React, { useContext, useEffect, useState, useRef } from "react";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Tippy from "@tippyjs/react";
import Select from "react-select";
import PopUpModel from "../../../../componants/comman/PopUpModel";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  GET_ALL_CATEGORIES,
  UPDATE_CATEGORY,
  GET_CATEGORY,
} from "../../../../componants/Context/Types";
import { CircularProgress } from "@mui/material";
import FacebookCircularProgress from "../../../../componants/comman/FacebookCircularProgress";

const options = [
  { value: true, label: "Active" },
  { value: false, label: "InActive" },
];

const Categories = () => {
  const [model, setModel] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [rows, setRows] = useState([]);
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isModelLoading, setIsModelLoading] = useState(false);
  const instanceRef = useRef();
  useEffect(() => {
    dispatch({
      type: GET_ALL_CATEGORIES,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  }, []);

  const handleDelete = (id) => {
    dispatch({
      type: DELETE_CATEGORY,
      payload: id,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };

  const handleOpenModal = (id) => {
    setModel(true);
    if (id) {
      dispatch({
        type: GET_CATEGORY,
        payload: id,
        updateState: { setCategoryName, setSelectedOption },
        setIsModelLoading: setIsModelLoading,
      });
    }
    setSelectedItemId(null);
    setCategoryName("");
    setSelectedOption(null);
  };

  const handleUpdate = (id) => {
    let values = { name: categoryName, status: selectedOption.value };
    if (id) {
      dispatch({
        type: UPDATE_CATEGORY,
        payload: id,
        values: values,
        updateState: setRows,
        setModel: setModel,
        setIsModelLoading: setIsModelLoading,
        setIsLoading: setIsLoading,
      });
    } else {
      dispatch({
        type: CREATE_CATEGORY,
        payload: values,
        updateState: setRows,
        setModel: setModel,
        setIsModelLoading: setIsModelLoading,
        setIsLoading: setIsLoading,
      });
    }
    setSelectedItemId(null);
    setCategoryName("");
    setSelectedOption(null);
  };
  return (
    <TableContainer>
      <div>
        <div className="btn-class">
          <h4 className="pr-3 d-flex justify-content-between">
            <div className="p-2 profile-header f-14">Categories</div>
            <Button
              variant={"contained"}
              className="mt-2"
              onClick={() => {
                setModel(true);
              }}
            >
              Add Category
            </Button>
          </h4>
        </div>
        {/* <div className='text-end m-2'><Button variant={"contained"} className='m-1 flex-1' onClick={()=>{setModel(true)}}>Back</Button></div> */}
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-between flex-row">
          <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
            <FacebookCircularProgress />
          </div>
        </div>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="bg-light">
              {/* <TableCell align="center">Category ID</TableCell> */}
              <TableCell align="center">Category Name</TableCell>
              {/* <TableCell align="center">Contacts</TableCell>     */}
              <TableCell align="center">Created Date</TableCell>
              <TableCell align="center">Status</TableCell>
              {/* <TableCell align="center">UPLOAD DATE</TableCell>     */}
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length ? (
              rows.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* <TableCell align="center" className="p-2"  >{row._id}</TableCell> */}
                  <TableCell align="center" className="p-2">
                    {row.name}
                  </TableCell>
                  {/* <TableCell align="center" className="p-2"  >{row.Contacts}</TableCell> */}
                  <TableCell align="center" className="p-2">
                    {row.createdAt}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`p-2 ${
                      row.status === true ? "text-success" : "text-danger"
                    }`}
                  >
                    {row.status === true ? "Active" : "InActive"}
                  </TableCell>
                  {/* <TableCell align="center"  className="p-2">{row.UplaodDate}</TableCell> */}
                  <TableCell align="center" className="p-2 icon-fill">
                    <EditIcon
                      onClick={() => {
                        handleOpenModal(row._id);
                        setSelectedItemId(row._id);
                      }}
                      className="pointer edit-fill mx-2"
                    />
                    <Tippy
                      content={
                        <div className="TippyAction bg-light ">
                          <div className=" p-2 pointer">
                            Are you sure you want to Delete.
                          </div>
                          <div>
                            <Button
                              variant="contained"
                              className="m-2 bg-success"
                              onClick={() => {
                                handleDelete(row._id);
                                instanceRef.current.hide();
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              variant="contained"
                              className="m-2 bg-danger"
                              onClick={() => instanceRef.current.hide()}
                            >
                              No
                            </Button>
                          </div>
                        </div>
                      }
                      onMount={(instance) => (instanceRef.current = instance)}
                      placement="bottom-end"
                      animation="perspective"
                      trigger="click"
                      arrow={false}
                      offset={[15, 1]}
                      interactive={true}
                      className="confirmation-model"
                    >
                      <DeleteIcon className="pointer delete-fill" />
                    </Tippy>
                    {/* <RemoveRedEyeIcon onClick={changetable} className="eye-Icon pointer mx-2"/> */}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <div>No Records Found</div>
            )}
          </TableBody>
        </Table>
      )}
      <PopUpModel
        open={model}
        title={"Add Category"}
        close={() => {
          setModel(false);
        }}
      >
        {isModelLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className=" w-100 d-flex flex-column mylist-Update">
              <div>
                <TextField
                  id="outlined-basic"
                  size="small"
                  value={categoryName}
                  className="user-input-fild w-100"
                  label="Category Name"
                  name="Category Name"
                  variant="outlined"
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                <Select
                  onChange={setSelectedOption}
                  options={options}
                  className="user-input-fild w-100"
                  classNamePrefix="select"
                  placeholder="Status"
                  defaultValue={selectedOption}
                  required
                />
              </div>
            </div>
            <div className="m-0 d-flex justify-content-end">
              <Button
                variant="contained"
                className="m-2 p-2 mr-0"
                onClick={() => {
                  handleUpdate(selectedItemId);
                }}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </PopUpModel>
      {/* <AddPopUpModel
        open={model}
        close={() => {
          setModel(false);
        }}
        title={"Add Category"}
        buttonname={"Add Category"}
      /> */}
    </TableContainer>
  );
};

export default Categories;
