import React, { useState, useEffect } from "react";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Button } from "@mui/material";
import Tippy from "@tippyjs/react";
import moment from "moment";
import PopUpModel from "../comman/PopUpModel";

import { AuthContext } from "../Context/AuthContext";
import {
  BLOCK_UNBLOCK_MEMBER,
  DELETE_INVITE,
  DELETE_INVITE_ADMIN,
  DELETE_MEMBER,
  GET_ALL_TEAM_MEMBERS,
  GET_COMPANY_MEMBERS_ADMIN,
} from "../Context/Types";
import { useContext } from "react";
import UserInfoDetails from "../../pages/ContactSerch/Admin/Companies/UserInfoDetail";
import AsignCredit from "./AsignCredit";
import RevokeCredit from "./RevokeCredit";
import UpgradeNow from "./UpgradeNow";
import Addteam from "../../pages/ContactSerch/ProfileTab/ProfileComponants/AddTeam";
import FacebookCircularProgress from "./FacebookCircularProgress";
import TuneIcon from "@mui/icons-material/Tune";
import ActivityLogPopUp from "./ActivityLogPopUp";
const UserTAble = (props) => {
  const { dispatch, typeOfUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setallData] = useState([]);
  const [rows, setRows] = useState([]);
  const [isAssign, setIsAssign] = useState(false);
  const [Upgrade, setUpgrade] = useState(false);
  const [userDetail, setUserDetail] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isRevoke, setIsRevoke] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [activityLogModel, setActivityLogModel] = useState(false);

  useEffect(() => {
    if (props.selectedCompanyByAdmin) {
      dispatch({
        type: GET_COMPANY_MEMBERS_ADMIN,
        id: props.selectedCompanyByAdmin,
        updateState: setRows,
        setallData: setallData,
        setIsLoading: setIsLoading,
      });
    } else {
      dispatch({
        type: GET_ALL_TEAM_MEMBERS,
        updateState: setRows,
        setallData: setallData,
        setIsLoading: setIsLoading,
      });
    }
  }, []);

  const deleteMember = (id) => {
    dispatch({
      type: DELETE_MEMBER,
      id: id,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };
  const deleteInvite = (id) => {
    if (props.selectedCompanyByAdmin) {
      dispatch({
        type: DELETE_INVITE_ADMIN,
        companyId: props.selectedCompanyByAdmin,
        inviteId: id,
        updateState: setRows,
        setallData: setallData,
      });
    } else {
      dispatch({
        type: DELETE_INVITE,
        id: id,
        updateState: setRows,
        setIsLoading: setIsLoading,
      });
    }
  };
  const handleBlockAndUnBlock = (id) => {
    dispatch({
      type: BLOCK_UNBLOCK_MEMBER,
      id: id,
      updateState: setRows,
    });
  };
  return (
    <div>
      <div>
        <h4 className="pr-3 d-flex justify-content-between">
          <div className="p-2 profile-header f-14">Team Members</div>
          <div className="d-flex">
            <div class="dropdown mt-3">
              <Button
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                variant="outlined"
                size="small"
                className="border-0"
              >
                Filters
                <TuneIcon className="mx-1" fontSize="small" />
              </Button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <div
                    class="dropdown-item bg-white pointer"
                    onClick={() => {
                      let filterData = [...allData];
                      filterData.sort((a, b) => {
                        let x = a.name.toUpperCase();
                        let y = b.name.toUpperCase();
                        return x === y ? 0 : x > y ? 1 : -1;
                      });

                      setRows(filterData);
                    }}
                  >
                    A-Z
                  </div>
                </li>
                <li>
                  <div
                    class="dropdown-item bg-white pointer"
                    onClick={() => {
                      let filterData = [...allData];
                      filterData.sort((a, b) => {
                        let x = a.name.toUpperCase();
                        let y = b.name.toUpperCase();
                        return x === y ? 0 : x > y ? 1 : -1;
                      });
                      filterData.reverse();

                      setRows(filterData);
                    }}
                  >
                    Z-A
                  </div>
                </li>
                <li>
                  <div
                    class="dropdown-item bg-white pointer"
                    onClick={() => {
                      let filterData = [...allData];
                      filterData.sort((a, b) => {
                        return parseFloat(a.credits) - parseFloat(b.credits);
                      });

                      setRows(filterData);
                    }}
                  >
                    Low-High
                  </div>
                </li>
                <li>
                  <div
                    class="dropdown-item bg-white pointer"
                    onClick={() => {
                      let filterData = [...allData];
                      filterData.sort((a, b) => {
                        return parseFloat(b.credits) - parseFloat(a.credits);
                      });

                      setRows(filterData);
                    }}
                  >
                    High-Low
                  </div>
                </li>

                <li>
                  <div
                    class="dropdown-item bg-white pointer"
                    onClick={() => {
                      let filterData = allData.filter(
                        (itm) => itm.status === true
                      );
                      setRows(filterData);
                    }}
                  >
                    Accepted
                  </div>
                </li>
                <li>
                  <div
                    class="dropdown-item bg-white pointer"
                    onClick={() => {
                      let filterData = allData.filter(
                        (itm) => itm.status === false
                      );
                      setRows(filterData);
                    }}
                  >
                    Pending
                  </div>
                </li>
              </ul>
            </div>
            <Button
              variant="contained"
              style={{ boxShadow: "none", backgroundColor: "#1891eb" }}
              className="mt-2 button-custom"
              onClick={() => {
                if (typeOfUser === "FREE") {
                } else {
                  setIsAdd(true);
                }
              }}
            >
              <AddRoundedIcon />
              &nbsp;Add
            </Button>
          </div>
        </h4>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-between flex-row">
          <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
            <FacebookCircularProgress />
          </div>
        </div>
      ) : rows.length === 0 ? (
        <div align="center" className="p-4">
          No Team Member Added
        </div>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="bg-light">
                <TableCell className="fw-bold" align="center">
                  Name
                </TableCell>
                <TableCell className="fw-bold" align="center">
                  Email
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Avbl.Credits
                </TableCell>
                {/* <TableCell align="center" className="fw-bold">
                Expiration
              </TableCell> */}
                <TableCell align="center" className="fw-bold">
                  Invitation Status
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Login IP
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Block Status
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Date of Creation
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typeOfUser === "FREE" ? (
                <div
                  className="text-primary pointer p-3"
                  onClick={() => {
                    props.setTitle("subscription");
                  }}
                >
                  Upgrade your Membership to add Team Member!
                </div>
              ) : (
                rows.map((row) => {
                  return (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className="p-2"
                        onClick={() => {
                          // setUserDetail(true);
                          setSelectedTeamMember(row);
                          setActivityLogModel(true);
                        }}
                      >
                        <a href="#"> {row.name}</a>
                      </TableCell>
                      <TableCell align="center" className="p-2">
                        {row.email}
                      </TableCell>
                      <TableCell align="center" className="p-2">
                        {row.credits}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`p-2
                      ${row.status === true && "text-success"}
                      ${row.status == false && "text-warning"}
                      `}
                      >
                        {row.status === true ? "ACCEPTED" : "PENDING"}
                      </TableCell>
                      <TableCell align="center" className={`p-2`}>
                        {row?.login_ip}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`p-2
                      ${row.blocked === true ? "text-danger" : "text-success"}
                      `}
                      >
                        {row.blocked === true ? "True" : "False"}
                      </TableCell>
                      <TableCell align="center" className="p-2">
                        {moment(row?.createdAt).format("MMMM Do YYYY")}
                      </TableCell>
                      <TableCell align="center" className="p-2 dropdown-team ">
                        <Tippy
                          content={
                            row.status === true ? (
                              <div className="TippyAction bg-light pointer">
                                {/* <div className=" p-2 pointer hover-dropdown ">
                              Edit
                            </div>
                            <div className=" p-2 pointer hover-dropdown">
                              Suspend
                            </div>
                            <div className=" p-2 pointer hover-dropdown">
                              Block
                            </div> */}
                                <div
                                  className=" p-2 pointer hover-dropdown"
                                  onClick={() => {
                                    deleteMember(row._id);
                                  }}
                                >
                                  Delete Member
                                </div>
                                <div
                                  className=" p-2 pointer hover-dropdown"
                                  onClick={() => {
                                    handleBlockAndUnBlock(row._id);
                                  }}
                                >
                                  {row.blocked === true ? "UnBlock" : "Block"}
                                </div>
                                {/* <div
                              className=" p-2 pointer hover-dropdown"
                              onClick={() => {
                                setUpgrade(true);
                              }}
                            >
                              Activate/Deactivate
                            </div> */}
                                <div
                                  className=" p-2 pointer hover-dropdown"
                                  onClick={() => {
                                    setIsAssign(true);
                                    setSelectedTeamMember(row);
                                  }}
                                >
                                  Assign credits
                                </div>
                                <div
                                  className=" p-2 pointer hover-dropdown"
                                  onClick={() => {
                                    setIsRevoke(true);
                                    setSelectedTeamMember(row);
                                  }}
                                >
                                  Revoke credits
                                </div>
                              </div>
                            ) : (
                              <div className="TippyAction bg-light pointer">
                                <div
                                  className=" p-2 pointer hover-dropdown"
                                  onClick={() => {
                                    deleteInvite(row._id);
                                  }}
                                >
                                  Delete Invite
                                </div>
                              </div>
                            )
                          }
                          placement="bottom-end"
                          arrow={false}
                          offset={[15, 1]}
                          trigger="mouseenter"
                          interactive={true}
                          // hideOnClick={true}
                        >
                          <MoreVertIcon></MoreVertIcon>
                        </Tippy>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <PopUpModel
        open={userDetail}
        title={"User Details"}
        close={() => {
          setUserDetail(false);
        }}
        bodyClass={"Invoice-model"}
      >
        <UserInfoDetails />
      </PopUpModel>
      <PopUpModel
        open={isAssign}
        title={"Assign credit"}
        close={() => {
          setIsAssign(false);
        }}
      >
        <AsignCredit
          close={() => {
            setIsAssign(false);
          }}
          selectedTeamMember={selectedTeamMember}
          setRows={setRows}
        />
      </PopUpModel>
      <PopUpModel
        open={isRevoke}
        title={"Revoke credit"}
        close={() => {
          setIsRevoke(false);
        }}
      >
        <RevokeCredit
          selectedTeamMember={selectedTeamMember}
          setRows={setRows}
          close={() => {
            setIsRevoke(false);
          }}
        />
      </PopUpModel>
      <PopUpModel
        open={Upgrade}
        close={() => {
          setUpgrade(false);
        }}
        bodyClass={"Invoice-model"}
      >
        <UpgradeNow />
      </PopUpModel>

      <Addteam
        open={isAdd}
        setRows={setRows}
        close={() => {
          setIsAdd(false);
        }}
        selectedCompanyByAdmin={props.selectedCompanyByAdmin}
      ></Addteam>

      <PopUpModel
        open={activityLogModel}
        title={"Activity log"}
        close={() => {
          setActivityLogModel(false);
        }}
        bodyClass={"AddCompany"}
      >
        <ActivityLogPopUp selectedTeamMember={selectedTeamMember} />
      </PopUpModel>
    </div>
  );
};

export default UserTAble;
