import React, { useState, useEffect, useContext } from "react";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tippy from "@tippyjs/react";
import PopUpModel from "../../../../componants/comman/PopUpModel";
import {
  ACCEPT_MEMBER_CREDIT_REQUEST,
  DENY_MEMBER_CREDIT_REQUEST,
  GET_COMPANY_CREDITS_REQUEST,
  GET_COMPANY_CREDITS_REQUEST_ADMIN,
} from "../../../../componants/Context/Types";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import AsignCredit from "../../../../componants/comman/AsignCredit";
import { NotificationManager } from "react-notifications";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { Button } from "@mui/material";
import AddRounded from "@mui/icons-material/AddRounded";
import FacebookCircularProgress from "../../../../componants/comman/FacebookCircularProgress";

const CreditRequest = (props) => {
  const [model, setmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (props.selectedCompanyByAdmin) {
      dispatch({
        type: GET_COMPANY_CREDITS_REQUEST_ADMIN,
        id: props.selectedCompanyByAdmin,
        updateState: setRows,
        setIsLoading: setIsLoading,
      });
    } else {
      dispatch({
        type: GET_COMPANY_CREDITS_REQUEST,
        updateState: setRows,
        setIsLoading: setIsLoading,
      });
    }
  }, []);

  const handleApprove = (id) => {
    dispatch({
      type: ACCEPT_MEMBER_CREDIT_REQUEST,
      id: id,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };
  const handleDeny = (id) => {
    dispatch({
      type: DENY_MEMBER_CREDIT_REQUEST,
      id: id,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };

  return (
    <div>
      <div>
        <h4 class="pr-3 d-flex justify-content-between">
          <div class="p-2 profile-header f-14">Credit Request</div>
          {/* <Button
            variant="contained"
            className="mt-2 button-custom"
            onClick={() => {
              setmodel(true);
            }}
          >
            <AddRounded />
            &nbsp;Add
          </Button> */}
        </h4>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-between flex-row">
          <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
            <FacebookCircularProgress />
          </div>
        </div>
      ) : rows.length === 0 ? (
        <div align="center" className="p-4">
          No Credit Request Present.
        </div>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="bg-light">
                <TableCell className="fw-bold" align="center">
                  Member Name
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Requested Credits
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Status
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Requested Time
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Requested Date
                </TableCell>
                <TableCell align="center" className="fw-bold">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    key={row?._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" className="p-2">
                      {row?.member?.name}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      {row?.credits}
                    </TableCell>
                    {/* <TableCell align="center" className="p-2">
                      {row.expiration}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      className={`p-2 ${
                        row?.status == "APPROVED" && "text-success"
                      } ${row?.status == "PENDING" && "text-warning"} ${
                        row?.status == "DECLINED" && "text-danger"
                      }`}
                    >
                      {row?.status}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      {moment(row.updatedAt).format("hh:mm:ss a")}
                    </TableCell>
                    <TableCell align="center" className="p-2">
                      {row?.createdAt}
                    </TableCell>
                    <TableCell align="center" className="p-2 dropdown-team ">
                      {row?.status == "PENDING" ? (
                        <Tippy
                          content={
                            <div className="TippyAction bg-light pointer">
                              <div
                                className=" p-2 pointer hover-dropdown "
                                onClick={() => {
                                  handleApprove(row?._id);
                                }}
                              >
                                Approve
                              </div>
                              <div
                                className=" p-2 pointer hover-dropdown"
                                onClick={() => {
                                  handleDeny(row?._id);
                                }}
                              >
                                Deny
                              </div>
                            </div>
                          }
                          placement="bottom-end"
                          arrow={false}
                          offset={[15, 1]}
                          trigger="mouseenter"
                          interactive={true}
                          // hideOnClick={true}
                        >
                          <MoreVertIcon></MoreVertIcon>
                        </Tippy>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* <PopUpModel
        open={model}
        title={"Assign credit"}
        close={() => {
          setmodel(false);
        }}
      >
        <AsignCredit
          close={() => {
            setmodel(false);
          }}
        />
      </PopUpModel> */}
    </div>
  );
};

export default CreditRequest;
