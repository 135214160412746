import { Button, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'

const AddField = ({ setHeaderName, headerName, handleSubmit, isError, setIsError }) => {
    const regex = /[0-9\s!@#$%^&*()_+{}\[\]:;"'<>,.?\\/|\\-]+/;

    useEffect(() => {
        if(regex.test(String(headerName.property).toLowerCase())) {
            setIsError(true);
        }else {
            setIsError(false);
        }
    }, [headerName.property])
    return (
        <div>
            <TextField
                fullWidth
                value={headerName?.name}
                onChange={(e) => setHeaderName((prev) => {
                    return {
                        ...prev,
                        name: e.target.value
                    }
                })}
                type="text"
                id="outlined-basic"
                size="small"
                className="user-input-fild w-100 f-14 mb-2"
                label="Header Name"
                variant="outlined"
            />
            <TextField
                fullWidth
                error={isError}
                helperText={isError ? "There should be no space and special characters in header property" : ""}
                value={headerName?.property}
                onChange={(e) => setHeaderName((prev) => {
                    return {
                        ...prev,
                        property: e.target.value
                    }
                })}
                type="text"
                id="outlined-basic"
                size="small"
                className="user-input-fild w-100 f-14"
                label="Header Property"
                variant="outlined"
            />

            <div>
            <Button
                onClick={handleSubmit}
                variant="contained"
                className="m-2 p-2 mr-0 f-16"
                style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
            >
                Add
            </Button>
            </div>
        </div>
    )
}

export default AddField