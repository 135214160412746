/** @format */

import { useEffect, useRef, useState } from 'react';

const Pagination = ({
	rowsPerPageOptions,
	count,
	rowsPerPage,
	page,
	handlePageChange,
	onRowsPerPageChange,
	disable,
}) => {
	const dropdownRef = useRef(null);
	const [pages, setPages] = useState('1-25');
	const [dropdown, setDropdown] = useState(false);

	function getPageRange(pageNumber, itemsPerPage) {
		const startIndex = pageNumber * itemsPerPage + 1;
		const endIndex = (pageNumber + 1) * itemsPerPage;
		return [startIndex, endIndex];
	}

	useEffect(() => {
		const [startIndex, endIndex] = getPageRange(page, rowsPerPage);
		setPages(startIndex + '-' + endIndex);
	}, [page, rowsPerPage]);

	const handleClickOutside = (e) => {
		if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
			setDropdown(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div class='px-2 py-2 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-300'>
			<div className='flex gap-8 items-center'>
				<div className='flex gap-4 items-center'>
					<div
						onClick={() => page !== 0 && disable && handlePageChange(page - 1)}
						className={`bg-gray-300 rounded-lg p-2 ${
							page !== 0 && disable
								? 'text-gray-900 cursor-pointer hover:bg-gray-400'
								: 'text-gray-500'
						}`}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-3 h-3'
						>
							<path d='m15 18-6-6 6-6' />
						</svg>
					</div>
					<p class='text-xs text-gray-600 dark:text-gray-400'>
						<span class='font-semibold text-gray-800 dark:text-gray-200'>
							{pages}
						</span>{' '}
						of {count?.toLocaleString('en-US')}
					</p>
					<div
						onClick={() => disable && handlePageChange(page + 1)}
						className={`bg-gray-300 rounded-lg p-2 ${
							disable
								? 'text-gray-900 cursor-pointer hover:bg-gray-400'
								: 'text-gray-500'
						}`}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-3 h-3'
						>
							<path d='m9 18 6-6-6-6' />
						</svg>
					</div>
				</div>
				<div
					ref={dropdownRef}
					className='relative flex items-center gap-1 text-xs'
				>
					<p>Records per page:</p>
					<div
						onClick={() => setDropdown(!dropdown)}
						className='cursor-pointer border border-gray-300 p-2 font-medium rounded-lg text-2xs hover:bg-gray-200'
					>
						{rowsPerPage}
					</div>
					{dropdown && (
						<div
							class='absolute bottom-4 right-0 w-20 z-10 transition-[margin,opacity] duration-300 mb-1 bg-white shadow-md rounded-lg p-1 border'
							aria-labelledby='hs-small-pagination-dropdown'
						>
							{rowsPerPageOptions.map((op, i) => (
								<button
									key={i}
									type='button'
									onClick={() => onRowsPerPageChange(op)}
									class='w-full flex items-center gap-x-3.5 py-1point5 px-2 rounded-lg text-xs text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none'
								>
									{op}
									{op === rowsPerPage && (
										<svg
											class='w-3 h-3 ms-auto text-blue-600'
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										>
											<polyline points='20 6 9 17 4 12'></polyline>
										</svg>
									)}
								</button>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Pagination;
