/** @format */

import React, { useContext, useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import debounce from 'debounce-promise';
import { AuthContext } from '../Context/AuthContext';
import {
	ADD_ITEM_TO_LIST,
	CREATE_AND_SAVE_ITEM_TO_LIST,
} from '../Context/Types';
import { getAllList } from '../Context/APIs';
import {
	EXECUTIVE_FILTER,
	PHYSICIAN_FILTER,
} from '../../pages/ContactSerch/filterCompo/filterTypes';
import Dropdown from '../../Elements/Misc/Dropdown';

const AddPopUpModel = ({ setaddlistmodel, closeModal }) => {
	const { dispatch, selectedLeads, typesOfFilter } = useContext(AuthContext);
	const [listName, setListName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [selectedList, setSelectedList] = useState('');
	const [isCreate, setIsCreate] = useState(true);
	const [listOptions, setListOptions] = useState([]);
	const [listLoading, setListLoading] = useState(false);
	const [listObj, setListObj] = useState({ listName: [''] });

	const getListOption = debounce((searchValue) => {
		setListLoading(true);
		let dataType;
		if (typesOfFilter === PHYSICIAN_FILTER) {
			dataType = 0;
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			dataType = 1;
		}
		const getData = async () => {
			const data = await getAllList(dataType);
			var options = data.data.map((item) => {
				return {
					value: item._id,
					label: item.folder_name,
				};
			});

			if (searchValue && searchValue !== '') {
				options = options
					.filter((item) =>
						item.label.toLowerCase().includes(searchValue.toLowerCase())
					)
					.map((item) => {
						return {
							value: item.value,
							label: item.label,
						};
					});
				if (options.length === 0) {
					options = [{ value: 'No List Found', label: 'No List Found' }];
				}
			}

			setListOptions(options);
			setListLoading(false);
			return options;
		};
		getData();
	}, 300);

	const handleSelect = (field, selectedOption) => {
		if (selectedOption.label !== 'No List Found') {
			if (listName === selectedOption.label) {
				setSelectedList('');
				setListObj({ listName: [''] });
			} else {
				setSelectedList(selectedOption.label);
				setListObj({ listName: [selectedOption.label] });
			}
		}
	};

	useEffect(() => {
		getListOption();
	}, []);

	const handleSaveList = () => {
		if (selectedList && selectedLeads) {
			dispatch({
				type: ADD_ITEM_TO_LIST,
				payload: selectedLeads,
				folder_id: selectedList,
				setIsLoading: setIsLoading,
				closeModal: closeModal,
			});
		}
	};
	const handleCreateAndSaveList = () => {
		if (listName) {
			const values = {
				folder_name: listName,
				leads_ids: selectedLeads,
			};
			dispatch({
				type: CREATE_AND_SAVE_ITEM_TO_LIST,
				values: values,
				setIsLoading: setIsLoading,
				closeModal: closeModal,
			});
		}
	};

	let filterlist = listOptions.length > 0 ? listOptions : [];
	filterlist.slice(0, 4);

	return (
		<>
			{selectedLeads.length === 0 && (
				<div
					class='bg-red-50 border !border-red-300 text-sm text-red-600 rounded-lg px-2 py-2 mt-4'
					role='alert'
				>
					<div class='flex gap-2'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='flex-shrink-0 w-3 h-3 mt-0.5'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='m4.9 4.9 14.2 14.2' />
						</svg>

						<p class='text-xs text-start font-medium'>
							Please select a few records before proceeding.
						</p>
					</div>
				</div>
			)}
			<div className='mt-4'>
				<h2 class='block text-xs text-start font-semibold text-gray-800'>
					Choose Option:
				</h2>
				<ul class='flex mt-1'>
					<li class='inline-flex items-center gap-x-2.5 py-1.5 px-2 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg'>
						<div
							onClick={() => setIsCreate(true)}
							class='relative flex gap-2 items-center w-full cursor-pointer'
						>
							<div class='flex items-center'>
								<input
									id='hs-horizontal-list-group-item-radio-3'
									name='hs-horizontal-list-group-item-radio'
									type='radio'
									checked={isCreate === true}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
								/>
							</div>
							<p
								for='hs-horizontal-list-group-item-radio-3'
								class='mr-2 text-xs text-gray-800'
							>
								Create New List
							</p>
						</div>
					</li>
					<li class='inline-flex items-center gap-x-2.5 py-1.5 px-2 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg'>
						<div
							onClick={() => setIsCreate(false)}
							class='relative flex gap-2 items-center w-full cursor-pointer'
						>
							<div class='flex items-center'>
								<input
									id='hs-radio-1'
									name='hs-horizontal-list-group-item-radio'
									type='radio'
									defaultChecked
									checked={isCreate === false}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
								/>
							</div>
							<p for='hs-radio-1' class='mr-2 text-xs text-gray-800'>
								Add to Existing List
							</p>
						</div>
					</li>
				</ul>
			</div>
			<div className='mt-3'>
				{isCreate ? (
					<>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Give a name to your list:
						</h2>
						<input
							type='text'
							value={listName}
							disabled={selectedLeads.length === 0}
							onChange={(e) => setListName(e.target.value)}
							placeholder='Try "My Contact List"'
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs disabled:opacity-90'
						/>
					</>
				) : (
					<div className='w-full'>
						{listOptions.length > 0 ? (
							<>
								<h3 className='pt-2 text-start text-gray-900 !font-medium text-2xs'>
									Select from previously created lists
								</h3>
								<div className=' pt-1 flex flex-wrap gap-1 items-center'>
									{filterlist.map((file, i) => (
										<div
											key={i}
											onClick={() =>
												handleSelect('listName', {
													value: file.value,
													label: file.label,
												})
											}
											className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
												listObj?.listName.findIndex((e) => {
													if (typeof e === 'string') {
														return e === file.label;
													} else {
														return e.label === file.label;
													}
												}) !== -1
													? 'bg-blue-600 text-white hover:bg-blue-700'
													: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
											}`}
										>
											{file.label}
										</div>
									))}
								</div>
								<h3 className='pt-3 text-start text-gray-700 font-medium text-2xs'>
									Or choose from the list
								</h3>

								<div className='pb-2 pt-1'>
									<Dropdown
										options={listOptions}
										defaultLabel={'Search Your List'}
										onSelect={handleSelect}
										field='listName'
										disabled={false}
										title={'Your All Lists:'}
										handleSearchOption={getListOption}
										searchLoading={listLoading}
										filter={listObj}
										openAbove={true}
									/>
								</div>
							</>
						) : (
							<div
								class='bg-blue-50 border !border-blue-300 text-sm text-blue-600 rounded-lg px-2 py-2 mt-2'
								role='alert'
							>
								{listLoading ? (
									<div class='flex gap-2'>
										<CircularProgress sx={{ color: 'blue' }} size={12} />

										<p class='text-xs text-start font-medium'>
											Loading your lists.
										</p>
									</div>
								) : (
									<div class='flex gap-2'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='w-3 h-3 mt-0.5'
										>
											<path d='m2 2 20 20' />
											<path d='M8.35 2.69A10 10 0 0 1 21.3 15.65' />
											<path d='M19.08 19.08A10 10 0 1 1 4.92 4.92' />
										</svg>

										<p class='text-xs text-start font-medium'>
											You have no existing lists. Please create one first.
										</p>
									</div>
								)}
							</div>
						)}
						{selectedList && (
							<div className='mt-2 text-start text-xs font-medium'>
								Selected: {selectedList}
							</div>
						)}
					</div>
				)}
			</div>
			<div className='flex items-center justify-end gap-2 mt-3'>
				<button
					onClick={() => setaddlistmodel(false)}
					className='py-2 px-3 rounded-lg bg-transparent text-gray-800 text-xs font-medium focus:outline-none border-none'
				>
					Cancel
				</button>
				{isCreate ? (
					<button
						onClick={() => {
							handleCreateAndSaveList();
						}}
						disabled={!listName || selectedLeads.length === 0}
						className='py-2 px-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium focus:outline-none border-none disabled:pointer-events-none disabled:opacity-70'
					>
						{isLoading ? (
							<>
								<CircularProgress sx={{ color: 'white' }} size={10} /> Creating
							</>
						) : (
							'Create List'
						)}
					</button>
				) : (
					<button
						onClick={() => {
							handleSaveList();
						}}
						disabled={!selectedList || selectedLeads.length === 0}
						className='py-2 px-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium focus:outline-none border-none disabled:pointer-events-none disabled:opacity-70'
					>
						{isLoading ? (
							<>
								<CircularProgress sx={{ color: 'white' }} size={10} /> Saving
							</>
						) : (
							'Add & Save List'
						)}
					</button>
				)}
			</div>
		</>
	);
};

export default AddPopUpModel;
