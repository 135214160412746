import React, { useContext, useEffect, useState } from "react";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import SendRequest from "../../pages/ContactSerch/ProfileTab/ProfileComponants/SendRequest";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { GET_MEMBER_CREDITS_REQUEST } from "../Context/Types";
import moment from "moment";
import FacebookCircularProgress from "./FacebookCircularProgress";

const CreditRequestTable = () => {
  const [isModal, setIsModal] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRequestLogs = () => {
    dispatch({
      type: GET_MEMBER_CREDITS_REQUEST,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  };

  useEffect(() => {
    getRequestLogs();
  }, []);
  return (
    <div>
      <div>
        <h4 className="pr-3  d-flex justify-content-between">
          <div className="p-2 profile-header">Request</div>
          <Button
            className="mt-2"
            variant="contained"
            onClick={() => {
              setIsModal(true);
            }}
          >
            Request Credits
          </Button>
        </h4>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-between flex-row">
        <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
          <FacebookCircularProgress />
        </div>
      </div>
      ) : rows?.length == 0 ? (
        <div align="center" className="p-4">
          No Credit Request Present
        </div>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="bg-light">
                <TableCell align="left">No. of Credits</TableCell>
                <TableCell align="center">Date of Request</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className="p-2"
                  >
                    {row.credits}
                  </TableCell>
                  <TableCell align="center" className="p-2">
                    {moment(row.createdAt).format("MMMM Do YYYY")}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`p-2 ${
                      row.status == "APPROVED" && "text-success"
                    } ${row.status == "PENDING" && "text-warning"} ${
                      row.status == "DECLINED" && "text-danger"
                    }`}
                  >
                    {row.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <SendRequest
        open={isModal}
        close={() => {
          setIsModal(false);
        }}
        getRequestLogs={getRequestLogs}
      ></SendRequest>
    </div>
  );
};

export default CreditRequestTable;
