import React, { useContext, useState } from "react";
import email from "../../../assests/image/email.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../componants/Context/AuthContext";
import { RESEND_EMAIL } from "../../../componants/Context/Types";
const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className=" m-5">
      <div className="shadow-lg m-5 p-3">
        <div className=" d-flex flex-start">
          <Button
            variant="outlined"
            className="text-start "
            onClick={() => {
              navigate("/signUp", {
                state: {
                  name: location?.state?.name || "",
                  email: location?.state?.email || "",
                  password: location?.state?.password || "",
                  mobile: location?.state?.mobile || "",
                  companyName: location?.state?.companyName || "",
                },
              });
            }}
          >
            Edit Details
          </Button>
        </div>
        <div className="px-5 mx-5">
          <h3>Verify Your Email</h3>

          <h6 className="py-2">
            You will need to verify your email to complete the sign up process
          </h6>
          <img
            src={email}
            alt="as"
            width={"300"}
            height={"300"}
            className="p-3"
          />
          <h6 className="px-5 mx-5">
            An email hase been sent to {location?.state?.email} with the link to
            verify your account.If you haven't recieve the email after a few
            mins then please check your spam folder
          </h6>
          <div className="m-auto d-flex flex-row w-50 justify-content-around p-4">
            <Button
              variant="contained"
              className="button-wid"
              onClick={() => {
                dispatch({
                  type: RESEND_EMAIL,
                  payload: location?.state?.email,
                  setIsLoading: setIsLoading,
                });
              }}
            >
              {isLoading ? (
                <CircularProgress sx={{ fontSize: 10, color: "white" }} />
              ) : (
                "Resend Email"
              )}
            </Button>
            <Button
              variant="outlined"
              className="button-wid"
              onClick={() =>
                window.open(
                  "https://mail.google.com/mail/?view=cm&fs=1&to=team@healthdbi.com&su=Unable%20to%20receive%20Account%20Activation%20Mail&body=Hi%2C%0D%0AI%20am%20blah%20blah%20and%20I%20am%20unable%20to%20receive%20account%20activation%20mail",
                  "_blank"
                )
              }
              // onClick={() => window.location = "mailto:team@healthdbi.com?subject=Unable%20to%20receive%20Account%20Activation%20Mail&body=Hi%2C%0D%0AI%20am%20blah%20blah%20and%20I%20am%20unable%20to%20receive%20account%20activation%20mail"}
            >
              Contact Support
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
