/** @format */
import React, { useContext, useEffect, useState } from 'react';
import {
	OWNERSHIP,
	GEOGRAPHIC_LOCATION,
} from '../../pages/ContactSerch/filterCompo/filterTypes';
import { MainContext } from '../../componants/filterContext/FilterContext';
import { AuthContext } from '../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../componants/Context/Types';
import debounce from 'debounce-promise';
import Dropdown from '../Misc/Dropdown';
import FilterBadges from './FilterBadges';
import { CircularProgress } from '@mui/material';

const OwnershipFilter = () => {
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		dispatch,
		setFilter,
		filter,
		setSortField,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		anyFilterFlag,
	} = useContext(AuthContext);

	const [firstTimeRun, setFirstTimeRun] = useState(false);
	const [ownershipOptions, setOwnershipOptions] = useState([]);
	const [ownerLoading, setOwnerLoading] = useState(false);

	const handleSelect = (field, selectedOption) => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		setGlobalSelectFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});
		setFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});
				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});

		setSortField({});
	};

	const loadOptions = debounce((searchValue) => {
		setOwnerLoading(true);
		const getData = async () => {
			const data = await globalFilterKeyToApi[OWNERSHIP]('');
			const options = data.map((item) => {
				return {
					value: item.name,
					label: item.name,
				};
			});
			setOwnershipOptions(options);
			setOwnerLoading(false);
			return options;
		};
		return getData();
	}, 300);

	useEffect(() => {
		loadOptions();
	}, []);

	useEffect(() => {
		setFirstTimeRun(false);
	}, [filter, globalSelectFilter]);

	return (
		<div>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Ownership</h1>
				<div className='w-full my-2 px-1 rounded-lg'>
					{ownerLoading ? (
						<div className='px-1 text-start text-xs font-medium text-blue-600 flex gap-2 items-center'>
							<CircularProgress size={12} style={{ color: '#2f5eff' }} />
							Loading...
						</div>
					) : (
						<>
							<div class='middle-div'>Governmental</div>
							<div className='my-2 flex flex-wrap gap-x-1 gap-y-2 items-center'>
								{ownershipOptions.length > 0 &&
									ownershipOptions.map((level, i) => {
										if (level.label.includes('Governmental')) {
											return (
												<div
													key={i}
													className={`rounded-full ${
														filter?.ownership &&
														filter?.ownership?.findIndex((e) => {
															return e === level.label;
														}) !== -1
															? 'border-check-active'
															: 'border-check-light'
													}`}
												>
													<h3
														onClick={() => handleSelect('ownership', level)}
														className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
													>
														<div className='custom-checkbox-filter'>
															<input
																type='checkbox'
																className='mt-point5'
																checked={
																	filter?.ownership &&
																	filter?.ownership?.findIndex((e) => {
																		return e === level.label;
																	}) !== -1
																}
															/>
															<div className='check-mark'>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	class='w-2point5 h-2point5'
																	viewBox='0 0 20 20'
																	fill='currentColor'
																	stroke='currentColor'
																	stroke-width='1'
																>
																	<path
																		fill-rule='evenodd'
																		d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
																		clip-rule='evenodd'
																	></path>
																</svg>
															</div>
														</div>
														{level.label.replace('Governmental -', '')}
													</h3>
												</div>
											);
										}
									})}
							</div>
							<div class='middle-div'>Proprietary</div>
							<div className='my-2 flex flex-wrap gap-x-1 gap-y-2 items-center'>
								{ownershipOptions.length > 0 &&
									ownershipOptions.map((level, i) => {
										if (level.label.includes('Proprietary')) {
											return (
												<div
													key={i}
													className={`rounded-full ${
														filter?.ownership &&
														filter?.ownership?.findIndex((e) => {
															return e === level.label;
														}) !== -1
															? 'border-check-active'
															: 'border-check-light'
													}`}
												>
													<h3
														onClick={() => handleSelect('ownership', level)}
														className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
													>
														<div className='custom-checkbox-filter'>
															<input
																type='checkbox'
																className='mt-point5'
																checked={
																	filter?.ownership &&
																	filter?.ownership?.findIndex((e) => {
																		return e === level.label;
																	}) !== -1
																}
															/>
															<div className='check-mark'>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	class='w-2point5 h-2point5'
																	viewBox='0 0 20 20'
																	fill='currentColor'
																	stroke='currentColor'
																	stroke-width='1'
																>
																	<path
																		fill-rule='evenodd'
																		d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
																		clip-rule='evenodd'
																	></path>
																</svg>
															</div>
														</div>
														{level.label.replace('Proprietary - ', '')}
													</h3>
												</div>
											);
										}
									})}
							</div>
							<div class='middle-div'>Voluntary Nonprofit</div>
							<div className='my-2 flex flex-wrap gap-x-1 gap-y-2 items-center'>
								{ownershipOptions.length > 0 &&
									ownershipOptions.map((level, i) => {
										if (level.label.includes('Voluntary')) {
											return (
												<div
													key={i}
													className={`rounded-full ${
														filter?.ownership &&
														filter?.ownership?.findIndex((e) => {
															return e === level.label;
														}) !== -1
															? 'border-check-active'
															: 'border-check-light'
													}`}
												>
													<h3
														onClick={() => handleSelect('ownership', level)}
														className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
													>
														<div className='custom-checkbox-filter'>
															<input
																type='checkbox'
																className='mt-point5'
																checked={
																	filter?.ownership &&
																	filter?.ownership?.findIndex((e) => {
																		return e === level.label;
																	}) !== -1
																}
															/>
															<div className='check-mark'>
																<svg
																	xmlns='http://www.w3.org/2000/svg'
																	class='w-2point5 h-2point5'
																	viewBox='0 0 20 20'
																	fill='currentColor'
																	stroke='currentColor'
																	stroke-width='1'
																>
																	<path
																		fill-rule='evenodd'
																		d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
																		clip-rule='evenodd'
																	></path>
																</svg>
															</div>
														</div>
														{level.label.replace('Voluntary Nonprofit - ', '')}
													</h3>
												</div>
											);
										}
									})}
							</div>
						</>
					)}
				</div>
			</div>

			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>

			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>
					Geographic Classification
				</h1>
				<div className='w-full my-2 px-1 rounded-lg'>
					<div className='mt-3 flex flex-wrap gap-x-2 gap-y-2 items-center'>
						<div
							className={`rounded-full ${
								filter?.geographic &&
								filter?.geographic?.findIndex((e) => {
									return e === 'Rural';
								}) !== -1
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() =>
									handleSelect('geographic', {
										value: 'Rural',
										label: 'Rural',
									})
								}
								className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
							>
								<div className='custom-checkbox-filter'>
									<input
										type='checkbox'
										className='mt-point5'
										checked={
											filter?.geographic &&
											filter?.geographic?.findIndex((e) => {
												return e === 'Rural';
											}) !== -1
										}
									/>
									<div className='check-mark'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											class='w-2point5 h-2point5'
											viewBox='0 0 20 20'
											fill='currentColor'
											stroke='currentColor'
											stroke-width='1'
										>
											<path
												fill-rule='evenodd'
												d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
												clip-rule='evenodd'
											></path>
										</svg>
									</div>
								</div>
								Rural
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.geographic &&
								filter?.geographic?.findIndex((e) => {
									return e === 'Urban';
								}) !== -1
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() =>
									handleSelect('geographic', {
										value: 'Urban',
										label: 'Urban',
									})
								}
								className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
							>
								<div className='custom-checkbox-filter'>
									<input
										type='checkbox'
										className='mt-point5'
										checked={
											filter?.geographic &&
											filter?.geographic?.findIndex((e) => {
												return e === 'Urban';
											}) !== -1
										}
									/>
									<div className='check-mark'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											class='w-2point5 h-2point5'
											viewBox='0 0 20 20'
											fill='currentColor'
											stroke='currentColor'
											stroke-width='1'
										>
											<path
												fill-rule='evenodd'
												d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
												clip-rule='evenodd'
											></path>
										</svg>
									</div>
								</div>
								Urban
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OwnershipFilter;
