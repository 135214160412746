/** @format */

import React from 'react';
import Routes from './Routes';
import './sass/index.scss';

//import './tailwindcss/tailwind-all.css';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './App.scss';

function App() {
	return (
		<div className='App'>
			<NotificationContainer />
			<Routes />
		</div>
	);
}

export default App;
