/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../componants/Context/AuthContext';
import {
	CREATE_SUBSCRIPTION,
	GET_SUBSCRIPTION,
	UPDATE_SUBSCRIPTION,
} from '../../../componants/Context/Types';
import { CircularProgress } from '@mui/material';

const AddPackage = (props) => {
	const [info, setInfo] = useState({
		title: '',
		desc: '',
		monthly_amount: '',
		monthly_credits: '',
		annually_amount: '',
		annually_credits: '',
		cost_per_user: '',
		no_of_user: '',
		cost_per_credit: '',
	});
	const { dispatch, subscriptionFeatures } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedOption, setSelectedOption] = useState([]);

	useEffect(() => {
		if (props.id !== null) {
			dispatch({
				type: GET_SUBSCRIPTION,
				payload: props.id,
				updateState: setInfo,
				setSelectedOption: setSelectedOption,
				setIsLoading: setIsLoading,
			});
		}
		return () => {
			props.setId(null);
		};
	}, [props.id]);

	const handleSubmit = () => {
		let values = {
			...info,
			no_of_user: +info.no_of_user,
			monthly_amount: +info.monthly_amount,
			monthly_credits: +info.monthly_credits,
			annually_amount: +info.annually_amount,
			annually_credits: +info.annually_credits,
			cost_per_credit: +info.cost_per_credit,
			cost_per_user: +info.cost_per_user,
			features: selectedOption.map((item) => item.value),
			type: props?.selectedCompanyByAdmin ? 'COMMON' : 'INDIVIDUAL',
			company_id: props.selectedCompanyByAdmin,
		};
		if (props.id !== null) {
			dispatch({
				type: UPDATE_SUBSCRIPTION,
				payload: props.id,
				values: values,
				updateState: props.updateState,
				setRowLoading: props.setRowLoading,
				setmodel: props.setmodel,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: CREATE_SUBSCRIPTION,
				payload: values,
				updateState: props.updateState,
				setRowLoading: props.setRowLoading,
				setmodel: props.setmodel,
				setIsLoading: setIsLoading,
			});
		}
	};

	return (
		<div className='mt-4'>
			<div>
				<h2 class='block text-xs text-start font-semibold text-gray-800'>
					Title:
				</h2>
				<input
					type='text'
					value={info?.title}
					onChange={(e) => setInfo({ ...info, title: e.target.value })}
					placeholder='Enter plan name'
					required
					className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
				/>
			</div>
			<div className='mt-2'>
				<h2 class='block text-xs text-start font-semibold text-gray-800'>
					Description:
				</h2>
				<input
					type='text'
					value={info?.desc}
					onChange={(e) => setInfo({ ...info, desc: e.target.value })}
					placeholder='Write description'
					required
					className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
				/>
			</div>
			<div className='mt-3'>
				<h2 class='block text-xs text-start font-semibold text-gray-800'>
					Features:
				</h2>
				<div className='mt-2 flex flex-wrap gap-x-2 gap-y-3 items-center'>
					{subscriptionFeatures.length > 0 &&
						subscriptionFeatures.map((item, i) => (
							<div
								key={i}
								className={`rounded-full ${
									selectedOption.find((e) => e.value === item?.value)
										? 'border-check-active'
										: 'border-check-light'
								}`}
							>
								<h3
									onClick={() => {
										setSelectedOption((prevSelectedOption) => {
											if (
												prevSelectedOption.find((e) => e.value === item.value)
											) {
												return prevSelectedOption.filter(
													(option) => option.value !== item.value
												);
											} else {
												return [...prevSelectedOption, item];
											}
										});
									}}
									className='flex gap-1 cursor-pointer py-1 pl-1 pr-2 items-center text-start text-gray-900 font-semibold text-2xs'
								>
									<div className='custom-checkbox-filter'>
										<input
											type='checkbox'
											className='mt-0.5'
											checked={selectedOption.find(
												(e) => e.value === item?.value
											)}
										/>
										<div className='check-mark'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												class='w-2.5 h-2.5'
												viewBox='0 0 20 20'
												fill='currentColor'
												stroke='currentColor'
												stroke-width='1'
											>
												<path
													fill-rule='evenodd'
													d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
													clip-rule='evenodd'
												></path>
											</svg>
										</div>
									</div>
									{item.label}
								</h3>
							</div>
						))}
				</div>
			</div>
			<div className='grid grid-cols-3 gap-2 mt-3'>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Total Users Allowed:
					</h2>
					<input
						type='text'
						value={info?.no_of_user}
						onChange={(e) => setInfo({ ...info, no_of_user: e.target.value })}
						placeholder='Enter total users'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Cost per User:
					</h2>
					<input
						type='text'
						value={info?.cost_per_user}
						onChange={(e) =>
							setInfo({ ...info, cost_per_user: e.target.value })
						}
						placeholder='Enter cost per user'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Cost per Credit:
					</h2>
					<input
						type='text'
						value={info?.cost_per_credit}
						onChange={(e) =>
							setInfo({ ...info, cost_per_credit: e.target.value })
						}
						placeholder='Enter cost per user'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Monthly Credit:
					</h2>
					<input
						type='text'
						value={info?.monthly_credits}
						onChange={(e) =>
							setInfo({ ...info, monthly_credits: e.target.value })
						}
						placeholder='Enter monthly credits'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Annual Credit:
					</h2>
					<input
						type='text'
						value={info?.annually_credits}
						onChange={(e) =>
							setInfo({ ...info, annually_credits: e.target.value })
						}
						placeholder='Enter annual credits'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Monthly Price:
					</h2>
					<input
						type='text'
						value={info?.monthly_amount}
						onChange={(e) =>
							setInfo({ ...info, monthly_amount: e.target.value })
						}
						placeholder='Enter monthly price'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Annual Price:
					</h2>
					<input
						type='text'
						value={info?.annually_amount}
						onChange={(e) =>
							setInfo({ ...info, annually_amount: e.target.value })
						}
						placeholder='Enter annual price'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
			</div>
			<button
				disabled={false}
				onClick={handleSubmit}
				className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
			>
				Submit{' '}
				{isLoading && <CircularProgress sx={{ color: 'white' }} size={14} />}
			</button>
		</div>
	);
};

export default AddPackage;
