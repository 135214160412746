/** @format */

import React, { useContext, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import TextField from '@mui/material/TextField';
import { UPLOAD_EXCLUSION_LEADS } from '../../../../componants/Context/Types';

const UploadExclusionLeads = (props) => {
	const [csvFile, setCsvFile] = useState();
	const [filename, setFilename] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [exclusionName, setExclusionName] = useState('');
	const formData = new FormData();
	const { dispatch } = useContext(AuthContext);
	const handleOnChange = (e) => {
		if (!e.target.files) {
			return;
		}
		const file = e.target.files[0];
		const { name } = file;
		setFilename(name);
		setCsvFile(file);
	};
	const handleFileUpload = (e) => {
		if (csvFile && exclusionName) {
			formData.append('file', csvFile);
			formData.append('list_name', exclusionName);
			dispatch({
				type: UPLOAD_EXCLUSION_LEADS,
				values: formData,
				updateState: props.setRows,
				setIsLoading: setIsLoading,
			});
			props.close();
		}
	};
	return (
		<div>
			<div className='my-3'>
				<div>
					<form>
						<label for='small-file-input' class='sr-only cursor-pointer'>
							Choose file
						</label>
						<input
							type='file'
							name='small-file-input'
							id='small-file-input'
							accept='.csv'
							onChange={handleOnChange}
							class='block w-full border border-gray-200 rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4'
						/>
					</form>
					{/* <Button
						component='label'
						variant='outlined'
						className=' f-16 btn-height'
						startIcon={<UploadFileIcon fontSize='f-16' />}
						sx={{ marginRight: '1rem' }}
					>
						Choose File
						<input type='file' accept='.csv' hidden onChange={handleOnChange} />
					</Button> */}

					<div className='mt-4'>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Give a file name:
						</h2>
						<input
							type='text'
							value={exclusionName}
							onChange={(e) => {
								setExclusionName(e.target.value);
							}}
							placeholder='Try "My Exclusion 101"'
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>
				</div>
			</div>
			<div className='flex items-center justify-end gap-2 mt-3'>
				<button
					onClick={() => props.close()}
					className='py-2 px-3 rounded-lg bg-transparent text-gray-800 text-xs font-medium focus:outline-none border-none'
				>
					Cancel
				</button>
				<button
					disabled={!exclusionName || !csvFile}
					onClick={handleFileUpload}
					className='py-2 px-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium focus:outline-none border-none disabled:pointer-events-none disabled:opacity-70'
				>
					{isLoading ? (
						<>
							<CircularProgress sx={{ color: 'white' }} size={10} /> Uploading
						</>
					) : (
						'Upload'
					)}
				</button>
			</div>
		</div>
	);
};

export default UploadExclusionLeads;
