/** @format */

import React, { useContext, useEffect, useState, useRef } from 'react';
import TableSkeltonLoader from '../../../../componants/comman/TableSkeltonLoader/TableSkeltonLoader';

import { AuthContext } from '../../../../componants/Context/AuthContext';

import {
	DELETE_SAVE_SEARCH,
	GET_SAVE_SEARCH,
} from '../../../../componants/Context/Types';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
	EXECUTIVE_FILTER,
	PHYSICIAN_FILTER,
} from '../../filterCompo/filterTypes';
import DynamicTable from '../../../../Elements/Misc/DTable';
import PaginationRow from '../../../../Elements/Misc/PaginationRow';
import OverlayModal from '../../../../Elements/Misc/Modal';
import { editSavedSearch } from '../../../../componants/Context/APIs';

const SaveSearch = () => {
	const [rows, setRows] = useState([]);
	const { dispatch, setFilter, anyFilterFlag } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [typesOfFilter, setTypesOfFilter] = useState(PHYSICIAN_FILTER);
	const [page, setPage] = useState(1);
	const [selectedRow, setSelectedRow] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const instanceRef = useRef();
	let navigate = useNavigate();

	const handleDelete = (id) => {
		dispatch({
			type: DELETE_SAVE_SEARCH,
			id: id,
			updateState: setRows,
			setIsLoading: setDeleteLoading,
		});
	};

	const columns = [
		{
			title: 'Search Name',
		},
		{
			title: 'Date',
		},
		{
			title: 'Actions',
		},
	];

	useEffect(() => {
		let dataType;
		if (typesOfFilter === PHYSICIAN_FILTER) {
			dataType = 0;
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			dataType = 1;
		}
		dispatch({
			type: GET_SAVE_SEARCH,
			updateState: setRows,
			setIsLoading: setIsLoading,
			dataType: dataType,
		});
	}, [typesOfFilter, selectedRow]);

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'Search Name') {
			return row?.search_name;
		} else if (col.title === 'Date') {
			return row.createdAt;
		} else if (col.title === 'Actions') {
			return (
				<div className='flex items-center !gap-4'>
					<div
						onClick={() => {
							navigate('/contactsearch', { state: 'FROM_SAVE_SEARCH' });
							anyFilterFlag.current = true;
							setFilter(row.search_params);
						}}
						className='cursor-pointer flex items-center gap-1 text-blue-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M9.937 15.5A2 2 0 0 0 8.5 14.063l-6.135-1.582a.5.5 0 0 1 0-.962L8.5 9.936A2 2 0 0 0 9.937 8.5l1.582-6.135a.5.5 0 0 1 .963 0L14.063 8.5A2 2 0 0 0 15.5 9.937l6.135 1.581a.5.5 0 0 1 0 .964L15.5 14.063a2 2 0 0 0-1.437 1.437l-1.582 6.135a.5.5 0 0 1-.963 0z' />
							<path d='M20 3v4' />
							<path d='M22 5h-4' />
							<path d='M4 17v2' />
							<path d='M5 18H3' />
						</svg>
						Apply
					</div>
					<div
						onClick={() => openModal(row)}
						className='cursor-pointer flex items-center gap-1 text-orange-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z' />
							<path d='m15 5 4 4' />
						</svg>
						Rename
					</div>
					<div
						onClick={() => {
							setSelectedRow(row);
							handleDelete(row._id);
						}}
						className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
					>
						{deleteLoading && row._id === selectedRow._id ? (
							<CircularProgress sx={{ color: 'red' }} size={12} />
						) : (
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4 h-4'
							>
								<path d='M3 6h18' />
								<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
								<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
								<line x1='10' x2='10' y1='11' y2='17' />
								<line x1='14' x2='14' y1='11' y2='17' />
							</svg>
						)}
						Delete
					</div>
				</div>
			);
		}
		return row[col.title];
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [renameLoading, setRenameLoading] = useState(false);
	const [newName, setnewName] = useState('');

	const openModal = (row) => {
		setModalOpen(true);
		setSelectedRow(row);
	};

	const closeModal = () => {
		setTimeout(() => {
			setModalOpen(false);
			setSelectedRow(null);
		}, 200);
	};

	useEffect(() => {
		setnewName(selectedRow?.search_name);
	}, [selectedRow]);

	const handleRenameList = async () => {
		setRenameLoading(true);
		await editSavedSearch({
			search_id: selectedRow?._id,
			search_name: newName,
		});
		setRenameLoading(false);
		closeModal();
	};

	return (
		<div>
			<div className='flex items-center gap-2 mx-4 pt-3'>
				<p className='text-xs font-semibold text-gray-800'>Choose Type:</p>
				<div className='flex flex-wrap gap-x-2 gap-y-2 items-center px-2'>
					<div
						className={`rounded-full ${
							typesOfFilter === PHYSICIAN_FILTER
								? 'border-check-active'
								: 'border-check-light'
						}`}
					>
						<h3
							onClick={() => setTypesOfFilter(PHYSICIAN_FILTER)}
							className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
						>
							<input
								name={'companyradionull'}
								type='radio'
								checked={typesOfFilter === PHYSICIAN_FILTER}
								defaultChecked
								class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
							/>
							Physicians
						</h3>
					</div>
					<div
						className={`rounded-full ${
							typesOfFilter === EXECUTIVE_FILTER
								? 'border-check-active'
								: 'border-check-light'
						}`}
					>
						<h3
							onClick={() => setTypesOfFilter(EXECUTIVE_FILTER)}
							className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
						>
							<input
								name={'companyradio1'}
								type='radio'
								checked={typesOfFilter === EXECUTIVE_FILTER}
								class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
							/>
							Executives
						</h3>
					</div>
				</div>
			</div>
			{isLoading ? (
				<div
					className='relative'
					style={{ overflow: 'hidden', marginTop: '1rem' }}
				>
					<TableSkeltonLoader countRow={8} />
				</div>
			) : (
				<DynamicTable
					columns={columns}
					data={paginatedRows}
					renderCell={renderCell}
				/>
			)}
			{!isLoading && (
				<PaginationRow
					totalResults={rows?.length}
					page={page}
					setPage={setPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
				/>
			)}
			<OverlayModal isOpen={modalOpen} onClose={closeModal}>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Rename Search
						</h3>
						<div
							onClick={closeModal}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>

					<div className='mt-4'>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Give a new name:
						</h2>
						<input
							type='text'
							value={newName}
							onChange={(e) => setnewName(e.target.value)}
							placeholder='Rename...'
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>

					<div className='flex items-center justify-end gap-2 mt-3'>
						<button
							onClick={closeModal}
							className='py-2 px-3 rounded-lg bg-transparent text-gray-800 text-xs font-medium focus:outline-none border-none'
						>
							Cancel
						</button>
						<button
							onClick={() => {
								handleRenameList();
							}}
							disabled={!newName}
							className='py-2 px-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium focus:outline-none border-none disabled:pointer-events-none disabled:opacity-70'
						>
							{renameLoading ? (
								<>
									<CircularProgress sx={{ color: 'white' }} size={10} />{' '}
									Renaming
								</>
							) : (
								'Rename'
							)}
						</button>
					</div>
				</div>
			</OverlayModal>
		</div>
	);
};

export default SaveSearch;
