import React, { useContext, useRef } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { GENDER } from "../filterTypes";
import AsyncSelect from "react-select/async";
import { MainContext } from "../../../../componants/filterContext/FilterContext";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { FILTER_CONTACTS } from "../../../../componants/Context/Types";
import { useState } from "react";
import { useEffect } from "react";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Gender() {
  const [expanded, setExpanded] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    globalSelectFilter,
    setGlobalSelectFilter,
    globalFilterKeyToApi,
    globalFilterKeyTofilterKey,
  } = useContext(MainContext);

  const {
    setFilter,
    filter,
    setFirstTimeFilter,
    setFirstTimeApiCall,
    setSortField,
    dispatch,
    anyFilterFlag,
    selectedFilter,
  } = useContext(AuthContext);

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setIsOpen(newExpanded)
    setExpanded(newExpanded ? panel : false);
  };

  const loadOptions = (searchValue, callback) => {
    const getData = async () => {
      const data = await globalFilterKeyToApi[GENDER](searchValue);
      const options = data.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      callback(options);
    };
    getData();
  };

  const handleChangeSingleSelect = (selectedOption) => {
    const formatedOption = [selectedOption.value];
    setGlobalSelectFilter((prev) => {
      return { ...prev, [expanded]: [selectedOption] };
    });
    setFirstTimeFilter({
      filterCount: 0,
      isFilterCall: false,
    });
    setFirstTimeApiCall(false);
    setSortField({});

    setFilter((prev) => {
      return {
        ...prev,
        [globalFilterKeyTofilterKey[expanded]]: formatedOption,
      };
    });
  };

      const keyDownHandler = (e) => {
        console.log(e);
        if (e.code === "Enter") {
          setFirstTimeApiCall(false);
          setFirstTimeFilter({
            filterCount: 0,
            isFilterCall: false,
          });
          anyFilterFlag.current = true;
          dispatch({
            type: FILTER_CONTACTS,
          });
        }
      };

        useEffect(() => {
          if (selectedFilter === "Gender") {
            setIsOpen(true);
            setExpanded(GENDER);
          }
        }, [selectedFilter]);

  return (
    <div>
      <Accordion
        expanded={expanded === GENDER}
        onChange={handleChangeAccordian(GENDER)}
      >
        <AccordionSummary>
          <Typography className="f-16">Gender</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AsyncSelect
            isSearchable={true}
            defaultOptions={true}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            menuIsOpen={isOpen}
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
              placeholder: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
            }}
            value={globalSelectFilter[GENDER]}
            loadOptions={loadOptions}
            onChange={handleChangeSingleSelect}
            onKeyDown={keyDownHandler}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
