import React from 'react';
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className='notFound_container'>
        <div className='notFound_center'>
            <h3 className='notFound_heading'>404</h3>
            <div className='notFound_line'></div>
            <p className='notFound_para'>This page could not be found.</p>
        </div>
    </div>
  )
}

export default NotFound;