/** @format */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { CircularProgress, Pagination, Stack } from '@mui/material';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import {
	GET_ACTIVITY_LOGS,
	GET_COMPANY_ACTIVITY_LOGS_ADMIN,
} from '../../../../componants/Context/Types';
import moment from 'moment';
import FacebookCircularProgress from '../../../../componants/comman/FacebookCircularProgress';

const ActivityItem = ({ row }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleModal = () => {
		setIsOpen(!isOpen);
	};

	const modalStyle = {
		display: isOpen ? 'block' : 'none',
		position: 'fixed',
		zIndex: 1,
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		overflow: 'auto',
		backgroundColor: 'rgba(0,0,0,0.6)',
		padding: '50px',
	};

	const contentStyle = {
		backgroundColor: '#fefefe',
		margin: 'auto',
		padding: '20px',
		border: '1px solid #888',
		width: '80%',
		borderRadius: '8px',
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const parseQuery =
		row?.query && row?.query !== null ? JSON.parse(row?.query) : {};

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const formatType = (value) => {
		return value === '1' ? 'Executive' : 'Physician';
	};

	return (
		<>
			<ListItem alignItems='flex-start' className='Activity-List'>
				<>
					<ListItemAvatar className='Activity-avtar'>
						<Avatar alt={row?.person}>{row?.person?.[0]}</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={row?.heading}
						secondary={
							<React.Fragment>
								<Typography
									sx={{ display: 'inline' }}
									component='span'
									variant='body2'
									color='text.primary'
								>
									{row?.person} &nbsp;
								</Typography>
								{row?.message} &nbsp;
								{row?.query && row?.query !== null && (
									<Typography
										sx={{ display: 'inline' }}
										component='span'
										variant='body1'
										color='#1891EB'
										style={{ cursor: 'pointer', textDecoration: 'underline' }}
										onClick={toggleModal}
									>
										View Search
									</Typography>
								)}
								<div style={modalStyle}>
									<div style={contentStyle}>
										<span
											style={{
												float: 'right',
												cursor: 'pointer',
												fontSize: '24px',
											}}
											onClick={handleClose}
										>
											&times;
										</span>
										<h2 style={{ fontSize: '20px', color: '#000' }}>Filters</h2>
										<p style={{ marginTop: '10px' }}>
											<strong>Page:</strong> {parseQuery.page}{' '}
											<strong>Limit:</strong> {parseQuery.limit}
										</p>
										<ul>
											{Object.entries(parseQuery).map(([key, value]) => {
												if (Array.isArray(value) && value.length > 0) {
													return (
														<li key={key}>
															<strong>{capitalizeFirstLetter(key)}:</strong>{' '}
															{key === 'type'
																? formatType(value[0])
																: value.join(', ')}
														</li>
													);
												}
												return null;
											})}
										</ul>
									</div>
								</div>
							</React.Fragment>
						}
					/>
				</>
				<Typography
					sx={{ display: 'inline' }}
					component='span'
					variant='subtitle2'
					color='text.primary'
				>
					{moment(row.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
				</Typography>
			</ListItem>
			<Divider variant='inset' component='li' />
		</>
	);
};

const ActivityLog = (props) => {
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { dispatch } = useContext(AuthContext);
	const [page, setPage] = useState(1);
	const [count, setCount] = useState(10);
	const limit = 5;
	const handleChange = (event, value) => {
		setPage(value);
		if (props.selectedCompanyByAdmin) {
			dispatch({
				type: GET_COMPANY_ACTIVITY_LOGS_ADMIN,
				searchParams: {
					id: props.selectedCompanyByAdmin,
					page: page,
					limit: 5,
				},
				updateState: { setRows, setCount },
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_ACTIVITY_LOGS,
				updateState: { setRows, setCount },
				setIsLoading: setIsLoading,
				page: page + 1,
				limit: limit,
			});
		}
	};

	useEffect(() => {
		if (props.selectedCompanyByAdmin) {
			dispatch({
				type: GET_COMPANY_ACTIVITY_LOGS_ADMIN,
				searchParams: {
					id: props.selectedCompanyByAdmin,
					page: page,
					limit: limit,
				},
				updateState: { setRows, setCount },
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_ACTIVITY_LOGS,
				updateState: { setRows, setCount },
				setIsLoading: setIsLoading,
				page: page,
				limit: 5,
			});
		}
	}, [page]);
	return (
		<div>
			<div>
				<h4 className='p-2 profile-header f-14'>Activity Log</h4>
			</div>
			{isLoading ? (
				<div className='d-flex justify-content-between flex-row'>
					<div className='mx-auto bg-white w-5/6 min-h-5 p-4'>
						<FacebookCircularProgress />
					</div>
				</div>
			) : (
				<div className='m-2'>
					<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
						{rows.length ? (
							<>
								{rows.map((row, i) => {
									return <ActivityItem key={i} row={row} />;
								})}
								<Stack spacing={2} className='mt-3'>
									{/* <Typography>Page: {page}</Typography> */}
									<Pagination
										count={Math.floor(count / limit)}
										page={page}
										onChange={handleChange}
									/>
								</Stack>
							</>
						) : (
							<div className='p-2'>No Logs Found!</div>
						)}
					</List>
				</div>
			)}
		</div>
	);
};

export default ActivityLog;
