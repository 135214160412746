import React, { useContext, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { LEVEL_ID } from "../filterTypes";
import Select from "react-select";
import { MainContext } from "../../../../componants/filterContext/FilterContext";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { FILTER_CONTACTS } from "../../../../componants/Context/Types";
import CreatableSelect from "react-select/creatable";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function LevelID() {
  const [expanded, setExpanded] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [levelOptions, setLevelOptions] = useState([]);

  const {
    globalSelectFilter,
    setGlobalSelectFilter,
    globalFilterKeyToApi,
    globalFilterKeyTofilterKey,
  } = useContext(MainContext);

  const {
    setFilter,
    filter,
    setFirstTimeFilter,
    setFirstTimeApiCall,
    setSortField,
    dispatch,
    anyFilterFlag,
    levelInput,
    selectedFilter,
    setLevelInput
  } = useContext(AuthContext);

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setIsOpen(newExpanded)
    setLevelOptions(
      levelOptions?.filter((item) => item?.label?.length !== 0)
    );
  };

  const loadOptions = (searchValue) => {
    const getData = async () => {
      const data = await globalFilterKeyToApi[LEVEL_ID](searchValue);
      const options = data.map((item) => {
        return {
          value: item.name,
          label:
            (item.name === "Director-Level" && "VP-Level") ||
            (item.name === "VP-Level" && "Director-Level") ||
            item.name,
        };
      });
      setLevelOptions(options);
    };
    getData();
  };

  const handleChangeMultiSelect = (selectedOption) => {
    const formatedOption = selectedOption.map((item) => item.value);
    setGlobalSelectFilter((prev) => {
      return { ...prev, [expanded]: selectedOption };
    });
    setFirstTimeFilter({
      filterCount: 0,
      isFilterCall: false,
    });
    setSortField({});
    setFirstTimeApiCall(false);
    setFilter((prev) => {
      return {
        ...prev,
        [globalFilterKeyTofilterKey[expanded]]: formatedOption,
      };
    });
  };

      const keyDownHandler = (e) => {
        console.log(e);
        if (e.code === "Enter") {
          setFirstTimeApiCall(false);
          setFirstTimeFilter({
            filterCount: 0,
            isFilterCall: false,
          });
          anyFilterFlag.current = true;
          dispatch({
            type: FILTER_CONTACTS,
          });
        }
      };

          useEffect(() => {
            loadOptions("");
          }, []);

            useEffect(() => {
              
              if (selectedFilter === "Level ID") {
                setIsOpen(true);
                setExpanded(LEVEL_ID);
              }
            }, [selectedFilter]);
  return (
    <div>
      <Accordion
        expanded={expanded === LEVEL_ID}
        onChange={handleChangeAccordian(LEVEL_ID)}
      >
        <AccordionSummary>
          <Typography className="f-16">Management Level</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            isMulti
            createOptionPosition="first"
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            menuIsOpen={isOpen}
            isClearable={false}
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
              placeholder: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
            }}
            closeMenuOnSelect={false}
            isSearchable={true}
            defaultOptions={true}
            value={globalSelectFilter[LEVEL_ID]}
            // loadOptions={loadOptions}
            onChange={handleChangeMultiSelect}
            onKeyDown={keyDownHandler}
            onInputChange={(value, action) => {
              if (action.action === "input-change") setLevelInput(value);
            }}
            // inputValue={levelInput}
            options={levelOptions}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
