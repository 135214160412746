/** @format */

import { Button, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import EmailFinderCom from '../../../../componants/Internal/EmailFinder';
import * as XLSX from 'xlsx';
import InsideEmailFinder from '../../../../componants/Internal/InternalIndside/InsideEmailFinder';
import useAuth from '../../../../hooks/useAuth';
import {
	checkInternalStatus,
	getInternalDays,
	getInternalFileVerification,
	getMyEmailFinderAPI,
} from '../../../../componants/Context/APIs';
import FacebookCircularProgress from '../../../../componants/comman/FacebookCircularProgress';

const EmailFinder = ({ limit }) => {
	const { auth } = useAuth();
	const [titlebtn, settitlebtn] = useState('Email Finder');
	const [data, setData] = useState([]);
	const [columns, setColumns] = useState([]);
	const [screenOpen, setScreenOpen] = useState(null);
	const [fileName, setFileName] = useState('');
	const [loader, setLoader] = useState(false);
	const [pageLoader, setPageLoader] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const [days, setDays] = useState(0);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const [verData, setVerData] = useState([]);
	const [verLoader, setVerLoader] = useState(false);
	const [csvFile, setCsvFile] = useState(null);
	const [pages, setPages] = useState(0);
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState([]);

	const processData = (dataString) => {
		const dataStringLines = dataString.split(/\r\n|\n/);
		const headers = dataStringLines[0].split(
			/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
		);
		let col = [];
		headers?.forEach((item) => {
			if (item && String(item)?.length > 0) {
				col.push({
					label: item,
					value: item,
				});
			}
		});
		setColumns(col);
		setLoader(false);
		setScreenOpen('Email Finder');
	};

	const uploadButtonHandler = (e) => {
		setLoader(true);
		const file = e.target.files[0];
		setCsvFile(file);
		setFileName(file?.name || '');
		const reader = new FileReader();
		reader.onload = (evt) => {
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
			processData(data);
		};
		if (file) {
			reader.readAsBinaryString(file);
		}
	};

	// useEffect(() => {
	// 	setVerData([]);
	// 	getEmailFinder(page);
	// }, [page]);

	return (
		<div className='p-3'>
			{/* <div className="d-flex justify-content-end  pb-0 pt-0  hadbar-btn header_internal mb-2 w-100" style={{ boxShadow: 'none' }}>
                <Button
                    variant={`${titlebtn == "Email Finder" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight word-break f-16"
                    onClick={() => {
                        setScreenOpen(null);
                        settitlebtn("Email Finder");
                    }}
                >
                    Email Finder
                </Button>
                <Button
                    variant={`${titlebtn == "Company Profile" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        setScreenOpen(null);
                        settitlebtn("Company Profile");
                    }}
                >
                    Company Profile
                </Button>
                <Button
                    variant={`${titlebtn == "Project File" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        setScreenOpen(null);
                        settitlebtn("Project File");
                    }}
                >
                    Project File
                </Button>
                <Button
                    variant={`${titlebtn == "Bounce Repository" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        setScreenOpen(null);
                        settitlebtn("Bounce Repository");
                    }}
                >
                    Bounce Repository
                </Button>
            </div> */}

			{pageLoader ? (
				<div className='d-flex justify-content-between flex-row'>
					<div className='mx-auto bg-white w-5/6 min-h-5 p-4'>
						<FacebookCircularProgress />
					</div>
				</div>
			) : (
				<>
					{!screenOpen && (
						<div className='d-flex justify-content-between align-items-center'>
							{titlebtn !== 'Company Profile' &&
								titlebtn !== 'Project File' && (
									<Button
										component='label'
										variant='contained'
										className='m-2 p-2 mr-0 f-16'
										style={{
											boxShadow: 'none',
											backgroundColor: '#1891eb',
											height: '38px',
										}}
									>
										{loader ? (
											<CircularProgress sx={{ color: 'white' }} size={15} />
										) : (
											'Upload File'
										)}
										<input
											disabled={loader}
											type='file'
											accept='.csv'
											hidden
											onChange={(e) => uploadButtonHandler(e)}
										/>
									</Button>
								)}
							{/* {!screenOpen && titlebtn === 'Email Finder' && (
								<div className='internalDateContainer'>
									<span className='me-2 f-16'>From:</span>
									<input
										className='internalDatePicker me-2'
										type='date'
										name=''
										id=''
										onChange={onDateChange}
									/>
									<span className='me-2 f-16'>To:</span>
									<input
										className='internalDatePicker'
										type='date'
										name=''
										id=''
										onChange={onDateChange2}
									/>
								</div>
							)} */}
						</div>
					)}

					{screenOpen && screenOpen.length > 0 ? (
						<>
							{screenOpen === 'Email Finder' && (
								<InsideEmailFinder
									tableColumn={columns}
									fileName={fileName}
									setScreenOpen={setScreenOpen}
									csvFile={csvFile}
								/>
							)}
						</>
					) : (
						<>
							{titlebtn === 'Email Finder' && (
								<EmailFinderCom
									setVerData={setVerData}
									verData={verData}
									setVerLoader={setVerLoader}
									verLoader={verLoader}
									setPages={setPages}
									pages={pages}
									setPage={setPage}
									page={page}
									totalCount={totalCount}
									setTotalCount={setTotalCount}
								/>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default EmailFinder;
