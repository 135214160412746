/** @format */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../componants/Context/AuthContext';
import { Tooltip } from 'react-tooltip';
import CreditImg from '../../assests/image/CreditImg.png';
import CreditImg3 from '../../assests/image/CreditImg3.png';
import {
	GET_CREDITS,
	GET_CURRENT_PLAN,
	GET_SUBSCRIPTION_FEATURES,
	GET_DASHBOARD_DATA,
	GET_ADMIN_TABS,
	GET_SUB_ADMIN,
	GET_PROFILE,
	GET_UNPAID_INVOICE_NOTIFICATION,
	LOG_OUT,
	BUY_MORE_CREDITS,
	QUICK_SEARCH,
} from '../../componants/Context/Types';
import useAuth from '../../hooks/useAuth';
import logo from '../../assests/image/companylogo.png';
import OverlayModal from '../Misc/Modal';
import SingleRangeSlider from '../Misc/SingleRangeSlider';
import { CircularProgress } from '@mui/material';
import NavbarContext from './NavbarContext';

const HeaderBar = () => {
	const { isFullScreen, toggleFullScreen, setTourAgain } =
		useContext(NavbarContext);
	const { dispatch, credits, profile, currentPlan } = useContext(AuthContext);
	const { auth } = useAuth();
	const [isLogoutLoading, setIsLogoutLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [dropdown, setDropdown] = useState(false);
	const dropdownRef = useRef(null);
	const [modalOpen, setModalOpen] = useState(false);

	const navigate = useNavigate();

	const openModal = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setTimeout(() => {
			setModalOpen(false);
		}, 200);
	};

	useEffect(() => {
		if (auth?.role === 'subadmin') {
			dispatch({ type: GET_SUB_ADMIN, id: auth?.id });
		}
		if (auth?.role === 'user') {
			dispatch({ type: GET_CREDITS });
		}
		if (auth?.role === 'company') {
			dispatch({ type: GET_CURRENT_PLAN });
			dispatch({ type: GET_CREDITS });
			dispatch({ type: GET_UNPAID_INVOICE_NOTIFICATION });
		}
		if (auth?.role === 'admin' || auth?.role === 'subadmin') {
			dispatch({ type: GET_DASHBOARD_DATA });
			dispatch({ type: GET_SUBSCRIPTION_FEATURES });
			dispatch({ type: GET_ADMIN_TABS });
		}
		dispatch({ type: GET_PROFILE });
	}, []);
	const [role, setRole] = useState(auth?.role);

	useEffect(() => {
		if (role === 'company') setRole('user');
		if (role === 'subadmin') setRole('admin');
	}, []);

	// useEffect(() => {
	// 	getDownloads();
	// }, []);

	const [buycredits, setBuyCredits] = useState(100);
	const handleChange = (newvalue) => {
		setBuyCredits(newvalue);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdown(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	const generateInitials = (name) => {
		if (name) {
			const words = name.split(' ');
			const initials = words
				.map((word) => word.charAt(0))
				.join('')
				.toUpperCase();
			return initials;
		} else {
			return 'GB';
		}
	};

	const handleLogout = () => {
		dispatch({ type: LOG_OUT, setIsLoading: setIsLogoutLoading });
	};

	const handleBuyCredits = () => {
		dispatch({
			type: BUY_MORE_CREDITS,
			values: {
				credit_count: buycredits,
				payment_gateway: 'STRIPE',
			},
			setIsLoading: setIsLoading,
		});
	};

	return (
		<div ref={dropdownRef}>
			<div className='m-0 w-full bg-white relative border-b border-slate-300'>
				{/* box_shadow to above div */}
				<div className='flex flex-row justify-between items-center w-full pl-2 pr-4 py-1.5'>
					<img
						src={logo}
						alt='HealthDBi'
						className='cursor-pointer h-8 w-auto'
						onClick={() => navigate('/contactsearch')}
					/>

					<div className='flex gap-3 items-center'>
						<div className='flex items-center gap-2.5'>
							{isFullScreen ? (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
									onClick={toggleFullScreen}
									className='flex-shrink-0 w-4 h-4 cursor-pointer'
								>
									<path d='M8 3v3a2 2 0 0 1-2 2H3' />
									<path d='M21 8h-3a2 2 0 0 1-2-2V3' />
									<path d='M3 16h3a2 2 0 0 1 2 2v3' />
									<path d='M16 21v-3a2 2 0 0 1 2-2h3' />
								</svg>
							) : (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
									onClick={toggleFullScreen}
									className='flex-shrink-0 w-4 h-4 cursor-pointer'
								>
									<path d='M8 3H5a2 2 0 0 0-2 2v3' />
									<path d='M21 8V5a2 2 0 0 0-2-2h-3' />
									<path d='M3 16v3a2 2 0 0 0 2 2h3' />
									<path d='M16 21h3a2 2 0 0 0 2-2v-3' />
								</svg>
							)}
							{window.location.pathname === '/contactsearch' && (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									onClick={() => setTourAgain(true)}
									className='flex-shrink-0 w-4 h-4 cursor-pointer'
								>
									<path d='M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z' />
									<path d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3' />
									<line x1='12' x2='12.01' y1='17' y2='17' />
								</svg>
							)}
							{/* <svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								className='flex-shrink-0 w-4 h-4 cursor-pointer'
							>
								<path d='M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9' />
								<path d='M10.3 21a1.94 1.94 0 0 0 3.4 0' />
							</svg> */}
						</div>
						<div id='step1' className='flex gap-1 items-center'>
							{auth?.role !== 'admin' && auth?.role !== 'subamdin' && (
								<div
									id='my-tooltip-anchor'
									data-tooltip-delay-hide={500}
									className={`bg-blue-100 text-blue-600 p-1.5 flex justify-center items-center gap-1 cursor-pointer transition-all duration-300 ease-in ${
										credits === null ? 'animate-pulse' : ''
									}`}
								>
									<img src={CreditImg} className='h-5' alt='Creidts' />
									<h3 className='font-bold text-xs pl-0.5'>{credits}</h3>
									<h3 className='font-normal text-xs'>CREDITS</h3>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='flex-shrink-0 w-3 h-3 ml-1'
									>
										<circle cx='12' cy='12' r='10' />
										<path d='M12 16v-4' />
										<path d='M12 8h.01' />
									</svg>
								</div>
							)}
							<Tooltip
								anchorSelect='#my-tooltip-anchor'
								content={'Use credits to unlock your leads.'}
								place='bottom-end'
								style={{
									backgroundColor: '#2f5eff',
									color: 'white',
									fontSize: '12px',
								}}
							/>
							{auth?.role !== 'admin' && auth?.role !== 'subamdin' && (
								<div
									onClick={openModal}
									className={`bg-orange-200 rounded-sm text-orange-600 p-1.5 flex justify-center items-center gap-1 cursor-pointer transition-all duration-300 ease-in`}
								>
									<img src={CreditImg3} className='h-5' alt='Credits' />
								</div>
							)}
						</div>

						<div className='h-7 border-l border-gray-400'></div>

						<div
							onClick={() => setDropdown(!dropdown)}
							class='flex-shrink-0 group block'
						>
							<div class='flex items-center cursor-pointer'>
								<div class='flex justify-center items-center bg-blue-600 text-white text-xs font-medium w-7 h-7 rounded-full'>
									{generateInitials(profile?.name)}
								</div>
								<div class='ml-2'>
									<h3 class='font-semibold text-xs text-gray-800 text-left'>
										{profile?.name}
									</h3>
									<p class='text-2xs font-medium text-left text-gray-600 -mt-1'>
										{auth?.role === 'admin'
											? 'ADMIN'
											: auth?.role === 'subadmin'
											? 'SUBADMIN'
											: profile?.company_name}
									</p>
								</div>
							</div>
						</div>
						{/* {auth?.role !== 'admin' && auth?.role !== 'subadmin' && (
						<div
							style={{ width: 'fit-content', flexDirection: 'column' }}
							className='flex justify-content-end p-2'
						>
							<div className='d-flex justify-content-end'>
								{plan === 'true' ? (
									<>
										<OverlayTrigger
											key={'downloads'}
											placement='bottom'
											overlay={
												<Tooltip id={`tooltip-bottom`} className='f-14'>
													Today's Credits Left
												</Tooltip>
											}
										>
											<Button
												variant='outlined'
												style={{
													marginRight: '10px',
													backgroundColor: 'rgb(205 234 255)',
												}}
												className='py-1 px-2 shadow-none header_button f-14 btn-height'
											>
												{dwnLoading ? (
													<CircularProgress
														size={15}
														style={{ color: '#1891EB' }}
													/>
												) : (
													<>
														<span className='f-14'>{totalDownload}</span>
														<span className='px-1 f-14'>Credits</span>
														<img src={credit} className='ms-3' alt='credit' />
													</>
												)}
											</Button>
										</OverlayTrigger>
									</>
								) : (
									<OverlayTrigger
										key={'credits'}
										placement='bottom'
										overlay={
											<Tooltip className='f-14' id={`tooltip-bottom`}>
												Use Credits to unlock your leads
											</Tooltip>
										}
									>
										<Button
											variant='outlined'
											style={{ marginRight: '10px' }}
											className='py-1 px-2 shadow-none header_button f-14 btn-height'
										>
											{credits === null ? (
												<CircularProgress
													size={15}
													style={{ color: '#1891EB' }}
												/>
											) : (
												<>
													{credits + ' credits'}
													<img src={credit} className='ms-3' alt='credit' />
												</>
											)}
										</Button>
									</OverlayTrigger>
								)}

								
								{typeOfUser === 'FREE' ? (
									<Button
										variant='contained'
										style={{
											backgroundColor: '#1891EB',
										}}
										className='py-1 px-2 ml-1 shadow-none header_button f-14 btn-height'
										onClick={() => {
											navigate('/profile', {
												state: { title: 'Subscription' },
											});
										}}
									>
										Upgrade Pro
										<img src={upgrade} className='ms-3' alt='upgrade' />
									</Button>
								) : typeOfUser === 'PREMIUM' && plan === 'false' ? (
									<Button
										variant='contained'
										style={{
											backgroundColor: '#1891EB',
										}}
										className='shadow-none header_button f-14 btn-height'
										onClick={() => {
											setBuyCreditModal(true);
										}}
									>
										Buy more Credits
									</Button>
								) : (
									plan === 'false' &&
									typeOfUser && (
										<div className='d-flex'>
											<Button
												variant='contained'
												style={{
													backgroundColor: '#1891EB',
												}}
												className='shadow-none header_button f-14 btn-height'
											>
												<CircularProgress
													size={15}
													style={{ color: 'white' }}
												/>
											</Button>
										</div>
									)
								)}
							</div>
							{notification?.count > 0 ? (
								<div
									style={{
										top: '50px',
										fontSize: 'xx-small',
										color: 'orangered',
										fontWeight: 'bold',
										padding: '5px',
									}}
									onClick={() => {
										navigate('/profile', { state: { title: 'Invoice' } });
									}}
								>
									<WarningIcon
										className='f-16'
										style={{
											marginRight: '-2px',
											marginTop: '-3px',
										}}
									/>
									&nbsp;
									{`You have ${notification?.count} Unpaid Invoices`}
								</div>
							) : null}
						</div>
					)} */}
					</div>
				</div>

				<OverlayModal isOpen={modalOpen} onClose={closeModal}>
					<div className='flex gap-3 flex-wrap'>
						<div className='w-60 h-60 overflow-hidden relative rounded-lg flex justify-center items-center'>
							<img src={CreditImg} className='relative w-32 h-32 z-1' />
							<div className='w-96 h-96 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center starburst starburst--both animate-round'></div>
						</div>
						<div className='w-80 relative'>
							<div className='flex justify-between items-center gap-4'>
								<h3 className='text-sm font-semibold text-gray-800'>
									Buy Extra Credits
								</h3>
								<div
									onClick={closeModal}
									className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										class='w-3 h-3'
									>
										<path d='M18 6 6 18' />
										<path d='m6 6 12 12' />
									</svg>
								</div>
							</div>
							<div className='mt-4 flex justify-between items-center gap-4'>
								<h3 className='text-sm font-medium text-gray-800'>
									Credits: {buycredits}
								</h3>
								<h3 className='text-sm font-medium text-gray-800'>
									${currentPlan.cost_per_credit * buycredits}
								</h3>
							</div>
							<div className='mt-6'>
								<SingleRangeSlider
									min={100}
									max={10000}
									currentValue={buycredits}
									onChange={handleChange}
								/>
							</div>
							<div
								class='bg-blue-100 border-blue-400 text-sm text-blue-600 rounded-lg px-2 py-2 mt-14'
								role='alert'
							>
								<div class='flex gap-2'>
									<svg
										class='flex-shrink-0 w-3 h-3 mt-1'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									>
										<circle cx='12' cy='12' r='10'></circle>
										<path d='M12 16v-4'></path>
										<path d='M12 8h.01'></path>
									</svg>

									<p class='text-xs text-start font-medium'>
										You can use your purchased credits as long as your plan is
										active.
									</p>
								</div>
							</div>
							<button
								onClick={handleBuyCredits}
								className='absolute left-0 bottom-0 w-full rounded-lg outline-none focus:outline-none border-none text-white bg-blue-600 hover:bg-blue-700 py-2 px-4 text-sm font-medium'
							>
								{isLoading ? (
									<CircularProgress size={14} style={{ color: 'white' }} />
								) : (
									'Purchase'
								)}
							</button>
						</div>
					</div>
				</OverlayModal>

				{/* <UpgradeNow
				close={() => {
					setUpgradeNowModal(false);
				}}
				open={upgradeNowModal}
				title={'Upgrade !!'}
			/> */}
			</div>
			<div
				className={`${
					dropdown ? 'opacity-100' : 'opacity-0 hidden'
				} absolute right-4 transition-[opacity,margin] duration-500 bg-white shadow-lg rounded-lg py-1 px-2 mt-1`}
				aria-labelledby='hs-dropdown-with-icons'
				style={{ width: '8rem' }}
			>
				<div class='py-1 first:pt-0 last:pb-0'>
					<NavLink
						className='flex items-center hover:no-underline gap-x-2 py-1.5 px-2 rounded-lg text-xs font-medium text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-100'
						to='/profile'
						exact='true'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='flex-shrink-0 w-3.5 h-3.5'
						>
							<path d='M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2' />
							<circle cx='12' cy='7' r='4' />
						</svg>
						My Profile
					</NavLink>
					<NavLink
						className='flex items-center hover:no-underline gap-x-2 py-1.5 px-2 rounded-lg text-xs font-medium text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-100'
						to='/mylist'
						exact='true'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							strokeWidth='2'
							strokeLinecap='round'
							strokeLinejoin='round'
							className='flex-shrink-0 w-3.5 h-3.5'
						>
							<path d='m3 17 2 2 4-4' />
							<path d='m3 7 2 2 4-4' />
							<path d='M13 6h8' />
							<path d='M13 12h8' />
							<path d='M13 18h8' />
						</svg>
						My List
					</NavLink>

					{auth?.role === 'company' && (
						<NavLink
							className='flex items-center hover:no-underline gap-x-2 py-1.5 px-2 rounded-lg text-xs font-medium text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-100'
							to='/billing'
							exact='true'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3.5 h-3.5'
							>
								<rect width='20' height='14' x='2' y='5' rx='2' />
								<line x1='2' x2='22' y1='10' y2='10' />
							</svg>
							Billing
						</NavLink>
					)}

					{auth?.role === 'admin' && (
						<NavLink
							className='flex items-center hover:no-underline gap-x-2 py-1.5 px-2 rounded-lg text-xs font-medium text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-100'
							to='/dashboard'
							exact='true'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='flex-shrink-0 w-3.5 h-3.5'
							>
								<rect width='7' height='7' x='3' y='3' rx='1' />
								<rect width='7' height='7' x='14' y='3' rx='1' />
								<rect width='7' height='7' x='14' y='14' rx='1' />
								<rect width='7' height='7' x='3' y='14' rx='1' />
							</svg>
							Dashboard
						</NavLink>
					)}
				</div>
				<hr className='bg-gray-600 mx-1 my-1' />
				<div class='py-1 first:pt-0 last:pb-0'>
					<p
						class='flex items-center hover:no-underline cursor-pointer gap-x-2 py-1.5 px-2 rounded-lg text-xs font-medium text-gray-800 hover:text-red-600 hover:bg-red-100 focus:outline-none'
						onClick={handleLogout}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='flex-shrink-0 w-3.5 h-3.5'
						>
							<path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4' />
							<polyline points='16 17 21 12 16 7' />
							<line x1='21' x2='9' y1='12' y2='12' />
						</svg>
						Logout
					</p>
				</div>
			</div>
		</div>
	);
};

export default HeaderBar;
