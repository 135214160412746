import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const TopBar = (props) => {
  const [profile, setprofile] = useState(false);
  const { auth } = useAuth();
  const [role, setRole] = useState(auth.role);
  const [selectedTab, setSelectedTab] = useState("");
  useEffect(() => {
    if (role === "company") setRole("user");
    if (role === "subadmin") setRole("admin");
  }, []);

  const navigate = useNavigate();
  const user = [
    { icon: <HomeIcon />, title: "Home", link: "/home" },
    {
      icon: <PersonSearchIcon />,
      title: "Contact Search",
      link: "/contactsearch",
    },
    { icon: <FormatListBulletedIcon />, title: "My List", link: "/mylist" },
    // { icon: <SearchIcon />, title: "Look up", link: "/lookup" },
    // { icon: <CheckCircleOutlineIcon />, title: "Verify", link: "/verify" },
    // { icon: <PersonIcon />, title: "Profile", link: "/profile" },
  ];

  const admin = [
    { icon: <AccountBalanceIcon />, title: "DashBoard", link: "/dashboard" },
    // {
    //   icon: <PersonSearchIcon />,
    //   title: "Contact Search",
    //   link: "/contactsearch",
    // },
    // { icon: <FormatListBulletedIcon />, title: "My List", link: "/mylist" },
    // { icon: <SearchIcon />, title: "Verify", link: "/verify" },
    // { icon: <CheckCircleOutlineIcon />, title: "My List", link: "/verify" },
    // { icon: <PersonIcon />, title: "Profile", link: "/profile" },
  ];

  return (
    <>
      {!window.location.pathname.includes("/contactsearch") && <div className={`d-flex flex-row justify-content-end  top-bar-wrapper`}>
        {(role == "admin" ? admin : user).map((data, i) => {
          return (
            <Button
              variant="outlined"
              style={{
                borderBottom: `${window.location.pathname.includes(data.link) ?
                  "2px solid #1976d2" : ""
                  }`,
              }}
              onClick={() => {
                setSelectedTab(data.title);
                navigate(data.link, { replace: true });
              }}
              startIcon={data.icon}
              key={i}
            >
              {data.title}
            </Button>
          );
          // }
        })}
      </div>
      }</>

  );
};

export default TopBar;
