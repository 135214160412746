/** @format */
import React, { useContext, useEffect, useState, useRef } from 'react';
import {
	LICENSE_STATE,
	NPI_NUMBER,
} from '../../pages/ContactSerch/filterCompo/filterTypes';
import { MainContext } from '../../componants/filterContext/FilterContext';
import { AuthContext } from '../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../componants/Context/Types';
import debounce from 'debounce-promise';
import Dropdown from '../Misc/Dropdown';
import FilterBadges from './FilterBadges';
import { usePapaParse } from 'react-papaparse';
import NPI_SAMPLE_FILE from '../../assests/NPI_SAMPLE_FILE.csv';
import { CircularProgress } from '@mui/material';

const LicenseFilter = () => {
	const [licensedOptions, setLicensedOptions] = useState([]);
	const [isLicenseSearch, setIsLicenseSearch] = useState(false);

	const [filename, setFilename] = useState('');
	const [filekey, setFileKey] = useState(0);
	const fileReader = new FileReader();
	const { readString } = usePapaParse();

	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		setFilter,
		filter,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		setSortField,
		dispatch,
		anyFilterFlag,
		setlicenseStateInput,
		licenseStateInput,
	} = useContext(AuthContext);

	const [uploadedData, setUploadedData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [abmlist, setABM] = useState([]);
	const formData = new FormData();

	const [firstTimeRun, setFirstTimeRun] = useState(false);

	const getLicense = debounce((searchValue) => {
		const getData = async () => {
			setIsLicenseSearch(true);
			let search = '';
			if (searchValue) {
				search = searchValue;
			}
			const data = await globalFilterKeyToApi[LICENSE_STATE](search);
			const options = data.map((item) => {
				return {
					value: item.name,
					label: item.name,
				};
			});
			setLicensedOptions(options);
			setIsLicenseSearch(false);
		};
		getData();
	}, 300);

	useEffect(() => {
		getLicense();
	}, []);

	const handleSelect = (field, selectedOption) => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		setGlobalSelectFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});
		setFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});
				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});

		setSortField({});
	};

	const handleRemove = (field, value) => {
		if (field === 'npi') {
			setFilter((prev) => {
				return { ...prev, npi: [] };
			});
			setGlobalSelectFilter((prev) => {
				return { ...prev, npi: [] };
			});
		} else {
			setFilter((prev) => {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === value;
					} else {
						return item.value === value;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev };
				}
			});
			setGlobalSelectFilter((prev) => {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === value;
					} else {
						return item.value === value;
					}
				});
				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev };
				}
			});
		}
	};

	const handleCsvUpload = () => {
		if (selectedFile) {
			fileReader.onload = function (event) {
				const csvOutput = event.target.result;
				readString(csvOutput, {
					worker: true,
					complete: (results) => {
						let extractedData = [];
						results.data.forEach((item, i) => {
							if (item[0].length > 0) {
								extractedData.push(item[0]);
							}
						});
						setFilter((prev) => {
							return {
								...prev,
								[globalFilterKeyTofilterKey[NPI_NUMBER]]: extractedData,
							};
						});
					},
				});
			};
			fileReader.readAsText(selectedFile);
		}
	};

	useEffect(() => {
		if (uploadedData) {
			setSelectedFile(null);
		}
	}, [uploadedData]);

	useEffect(() => {
		setFirstTimeRun(false);
	}, [filter, globalSelectFilter]);

	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = NPI_SAMPLE_FILE;

		link.download = 'NPI_SAMPLE_FILE.csv';

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
	};

	const [selectedFile, setSelectedFile] = useState(null);
	const inputRef = useRef(null);

	const handleDrop = (event) => {
		event.preventDefault();
		const file = event.dataTransfer.files[0];
		handleFile(file);
	};

	const handleFileSelect = (event) => {
		event.preventDefault();
		const file = event.target.files[0];
		handleFile(file);
	};

	const handleFile = (file) => {
		if (file) {
			setSelectedFile(file);
			setFilename(file.name?.split('.')[0]);
		}
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	// const abmfilterlist =
	// 	abmlist.length > 0
	// 		? abmlist?.filter((item) => item.fileType === 'abm').slice(0, 3)
	// 		: [];

	return (
		<div>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>
					Licensed State
				</h1>
				{filter?.licenseState?.length > 0 && (
					<FilterBadges
						options={filter.licenseState}
						field='licenseState'
						handleRemove={handleRemove}
						optionLength={1}
					/>
				)}
				<div className='w-full my-2 rounded-lg'>
					<h3 className='pt-2 text-start px-2 text-gray-900 font-medium text-2xs'>
						Most Searched License States
					</h3>
					<div className='px-2 pt-1 flex flex-wrap gap-1 items-center'>
						<div
							onClick={() =>
								handleSelect('licenseState', {
									value: 'NY',
									label: 'NY',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.licenseState.findIndex((e) => {
									return e === 'NY';
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							NY
						</div>
						<div
							onClick={() =>
								handleSelect('licenseState', {
									value: 'CA',
									label: 'CA',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.licenseState.findIndex((e) => {
									return e === 'CA';
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							CA
						</div>
						<div
							onClick={() =>
								handleSelect('licenseState', {
									value: 'WA',
									label: 'WA',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.licenseState.findIndex((e) => {
									return e === 'WA';
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							WA
						</div>
						<div
							onClick={() =>
								handleSelect('licenseState', {
									value: 'TX',
									label: 'TX',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter?.licenseState.findIndex((e) => {
									return e === 'TX';
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							TX
						</div>
					</div>
					<h3 className='pt-3 text-start px-2 text-gray-700 font-medium text-2xs'>
						Or choose from the list
					</h3>
					<div className='px-2 pb-2 pt-1'>
						<Dropdown
							options={licensedOptions}
							defaultLabel={'Search License State'}
							onSelect={handleSelect}
							field='licenseState'
							disabled={false}
							title={'License States:'}
							handleSearchOption={getLicense}
							searchLoading={isLicenseSearch}
							filter={filter}
						/>
					</div>
				</div>
			</div>

			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>

			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>NPI Number</h1>
				{filter?.npi?.length > 0 && (
					<FilterBadges
						options={[`${filter.npi.length} NPI Numbers`]}
						field='npi'
						handleRemove={handleRemove}
						optionLength={4}
					/>
				)}
				<div className='w-full my-2 rounded-lg'>
					<h3 className='pt-3 text-start px-2 text-gray-700 font-medium text-2xs'>
						Upload the file
					</h3>
					<div
						onDrop={handleDrop}
						onDragOver={handleDragOver}
						className='mx-2 p-2 mt-1 uploadborder bg-blue-100 rounded-lg'
					>
						<h3 className='text-xs text-gray-900 font-medium my-1'>
							Drop your files here or{' '}
							<label
								for='abm-upload'
								className='text-blue-600 p-0 m-0 hover:underline cursor-pointer'
							>
								browse
							</label>
							<input
								id='abm-upload'
								type='file'
								key={filekey}
								accept='.csv'
								style={{ display: 'none' }}
								onChange={handleFileSelect}
							/>
						</h3>
						<p className='text-2xs text-gray-600 font-medium my-1'>
							Supported Format: CSV Only
						</p>
					</div>
					{!selectedFile && (
						<>
							<h3 className='pt-1 text-start px-2 text-gray-700 font-medium text-2xs'>
								<span className='text-blue-600'>*</span> Upload NPI Numbers in
								single column CSV with no headers.
							</h3>
							<h3 className='pt-1 text-start px-2 text-gray-700 font-medium text-2xs'>
								<span className='text-blue-600'>*</span> Max Limit 5000.{' '}
								<span
									className='anchor cursor-pointer'
									onClick={handleDownload}
								>
									(Download Sample File)
								</span>
							</h3>
						</>
					)}
					{selectedFile && (
						<div className='pt-3 px-2 flex justify-between items-start gap-2'>
							<h3 className='text-start text-gray-900 font-medium text-xs'>
								{filename}
							</h3>

							<div className='flex gap-2 items-center'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2.5'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='w-3 h-3 text-red-600 cursor-pointer'
									onClick={() => {
										setSelectedFile(null);
										setFileKey((prevKey) => prevKey + 1);
									}}
								>
									<path d='M3 6h18' />
									<path d='M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6' />
									<path d='M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2' />
									<line x1='10' x2='10' y1='11' y2='17' />
									<line x1='14' x2='14' y1='11' y2='17' />
								</svg>
								{isLoading ? (
									<CircularProgress size={10} style={{ color: '#2F5EFF' }} />
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										onClick={handleCsvUpload}
										class='w-3 h-3 text-green-700 cursor-pointer'
									>
										<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
										<polyline points='17 8 12 3 7 8' />
										<line x1='12' x2='12' y1='3' y2='15' />
									</svg>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default LicenseFilter;
