import React, { useContext, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { NotificationManager } from "react-notifications";
import PopUpModel from "./PopUpModel";
import { PAY_INVOICE } from "../Context/Types";

function PayInvoiceModel(props) {
  const { dispatch } = useContext(AuthContext);
  const [value, setValue] = React.useState("STRIPE");
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    if (!value === "offline") {
      //("Select a Payment Gateway!");
      return;
    }
    dispatch({
      type: PAY_INVOICE,
      values: {
        invoice_id: props?.selectedInvoice?._id,
        payment_gateway: value,
      },
      setIsLoading: setIsLoading,
    });
  };

  return (
    <PopUpModel open={props.open} title={props.title} close={props.close}>
      <div className="p-1">
        <div className=" mb-3">
          <div>
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                style={{ color: "#1976d2" }}
              >
                Select option for Payment
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              >
                <FormControlLabel
                  value="STRIPE"
                  componentsProps={{ typography: { variant: "small" } }}
                  control={<Radio size="small" />}
                  label="Stripe"
                />
                {/* <FormControlLabel
                  value="PAYPAL"
                  componentsProps={{ typography: { variant: "small" } }}
                  control={<Radio size="small" />}
                  label="PayPal"
                /> */}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="m-0">
          <Button
            variant="contained"
            className="d-flex justify-content-center m-auto "
            onClick={() => {
              handleClick();
            }}
          >
            {isLoading ? (
              <CircularProgress sx={{ style: "10px", color: "white" }} />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </PopUpModel>
  );
}
export default PayInvoiceModel;
