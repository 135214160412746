import React, { useState } from "react";

export const WidthIncreaseDec = ({ data }) => {
  const [width, setWidth] = useState({
    width: 0,
    pageX: 0,
  });
  return (
    data.headerName !== "Action" && (
      <div
        className={
          (data.headerName === "PhoneNumber" && "ml-2") ||
          (data.headerName === "EmailAddress" && "ml-2")
        }
        onDragCapture={(e) => {
          if (e.pageX > width.pageX) {
            setWidth({
              ...width,
              width: width.width + 4.5,
              pageX: e.pageX,
            });
          } else {
            setWidth({
              ...width,
              width: width.width - 4.5,
              pageX: e.pageX,
            });
          }
          if (e.pageX === 0) {
            setWidth({
              ...width,
              width: width.width - 4.5,
              pageX: 0,
            });
            if (width.width < 0) {
              setWidth({
                ...width,
                width: 0,
                pageX: 0,
              });
            }
            if (width.width > 0) {
              setWidth({
                ...width,
                width: width.width - 4.5,
                pageX: 0,
              });
              if (width.width === 0) {
                setWidth({
                  ...width,
                  width: width.width - 4.5,
                  pageX: 0,
                });
              } else {
                setWidth({
                  ...width,
                  width: width.width - 4.5,
                  pageX: 0,
                });
              }
            }
          }
        }}
        // onDragExitCapture={(e) => {
        //   if (e.pageX > width.pageX) {
        //     setWidth({
        //       ...width,
        //       width: width.width + 4.5,
        //       pageX: e.pageX,
        //     });
        //   } else {
        //     setWidth({
        //       ...width,
        //       width: width.width - 4.5,
        //       pageX: e.pageX,
        //     });
        //   }
        //   if (e.pageX === width.pageX) {
        //     setWidth({
        //       ...width,
        //       width: width.width - 4.5,
        //       pageX: 0,
        //     });
        //   }
        //   if (e.pageX === 0) {
        //     setWidth({
        //       ...width,
        //       width: width.width - 4.5,
        //       pageX: 0,
        //     });
        //     if (width.width < 0) {
        //       setWidth({
        //         ...width,
        //         width: 0,
        //         pageX: 0,
        //       });
        //     }
        //     if (width.width > 0) {
        //       setWidth({
        //         ...width,
        //         width: width.width - 4.5,
        //         pageX: 0,
        //       });
        //       if (width.width === 0) {
        //         setWidth({
        //           ...width,
        //           width: width.width - 4.5,
        //           pageX: 0,
        //         });
        //       }
        //       if (e.pageX > 0) {
        //         setWidth({
        //           ...width,
        //           width: width.width - 4.5,
        //           pageX: 0,
        //         });
        //       }
        //     }
        //   }
        // }}
        // onDragOverCapture={(e) => {
        //   if (e.pageX > width.pageX) {
        //     setWidth({
        //       ...width,
        //       width: width.width + 4.5,
        //       pageX: e.pageX,
        //     });
        //   } else {
        //     setWidth({
        //       ...width,
        //       width: width.width - 4.5,
        //       pageX: e.pageX,
        //     });
        //   }
        //   if (e.pageX === 0) {
        //     setWidth({
        //       ...width,
        //       width: width.width - 4.5,
        //       pageX: 0,
        //     });
        //     if (width.width < 0) {
        //       setWidth({
        //         ...width,
        //         width: 0,
        //         pageX: 0,
        //       });
        //     }
        //     if (width.width > 0) {
        //       setWidth({
        //         ...width,
        //         width: width.width - 4.5,
        //         pageX: 0,
        //       });
        //       if (width.width === 0) {
        //         setWidth({
        //           ...width,
        //           width: width.width - 4.5,
        //           pageX: 0,
        //         });
        //       }
        //     }
        //   }
        // }}
        style={{
          borderRight: "2px solid #ececec",
          cursor: "col-resize",
          padding: "0 3px",
          margin: "0px 2px",
          height: "30px",
          width: width.width,
        }}
      ></div>
    )
  );
};
