import { Card } from "@mui/material";
import React, { useContext } from "react";
import { Button } from "@mui/material";
import { AuthContext } from "../../../componants/Context/AuthContext";
import { FILTER_CONTACTS } from "../../../componants/Context/Types";

const CommanPopUp = (props) => {
  const { dispatch, page, rowsPerPage, setAllContacts,anyFilterFlag } =
    useContext(AuthContext);

  // const handleSubmit = () => {
  //   props.DoneTitle();
  //   dispatch({
  //     type: FILTER_CONTACTS,
  //     setAllContacts: setAllContacts,
  //     page: page,
  //     limit: rowsPerPage,
  //   });
  //   anyFilterFlag.current=true;
  // };

  return (
    <Card className={`common-pop-up-card text-right ${props.className}`}>
      {/* <div className={"comman-pop-up-wrapper p-3 height-card"}> */}
      {props.children}
      {/* </div> */}
      {/* <Button className="mt-2" size="small" variant="contained" onClick={handleSubmit}>
        <small>Done</small>
      </Button> */}
    </Card>
  );
};

export default CommanPopUp;
