import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import { ASSIGN_CREDITS, ASSIGN_CREDITS_TO_COMPANY } from "../Context/Types";
import { NotificationManager } from "react-notifications";
import PopUpModel from "./PopUpModel";

const AssignCreditsToCompanyModel = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [credits, setCredits] = useState();
  const { dispatch } = useContext(AuthContext);

  const handleSubmit = () => {
    if (!credits) {
      //("Enter number of Credits");
      return;
    }
    if (credits <= 0) {
      //("Credits should be greater than 0");
      return;
    }

    dispatch({
      type: ASSIGN_CREDITS_TO_COMPANY,

      values: {
        company_id: props.selectedCompanyByAdmin,
        credit_count: +credits,
      },
      closeModal: props.close,
      setIsLoading: setIsLoading,
    });
  };
  return (
    <PopUpModel open={props.open} title={props.title} close={props.close}>
      <div>
        <div className=" w-100 d-flex flex-column mylist-Update">
          <div>
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-100"
              label="Credits"
              name="Credits"
              value={credits}
              variant="outlined"
              onChange={(e) => setCredits(e.target.value)}
            />
          </div>
        </div>
        <div className="m-0 d-flex justify-content-end">
          <Button
            variant="contained"
            className="m-2 p-2 mr-0"
            onClick={handleSubmit}
          >
            {isLoading ? (
              <CircularProgress sx={{ fontSize: "0.2rem", color: "white" }} />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </div>
    </PopUpModel>
  );
};

export default AssignCreditsToCompanyModel;
