/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../componants/Context/AuthContext';
import { EXTEND_FREE_TRIAL } from '../../../componants/Context/Types';
import { NotificationManager } from 'react-notifications';

const ExtendFreeTrial = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [info, setInfo] = useState({});
	const [date, setDate] = useState();
	const { dispatch } = useContext(AuthContext);

	useEffect(() => {
		setDate(props?.selectedCompany?.plan?.subscription_end_date);
	}, [props.selectedCompany]);

	const handleSubmit = () => {
		if (!date) {
			NotificationManager.error('Please the select the date.');
			return;
		}

		dispatch({
			type: EXTEND_FREE_TRIAL,
			id: props.selectedCompanyByAdmin,
			date: date,
			updateState: setInfo,
			setIsLoading: setIsLoading,
			closeModal: props.close,
		});
	};
	return (
		<>
			<div className='mt-4'>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Subscription End Date:
					</h2>
					<input
						type='date'
						value={date}
						onChange={(e) => {
							setDate(e.target.value);
						}}
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
			</div>
			<button
				onClick={handleSubmit}
				className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
			>
				{isLoading ? (
					<CircularProgress sx={{ color: 'white' }} size={14} />
				) : (
					'Submit'
				)}
			</button>
		</>
	);
};

export default ExtendFreeTrial;
