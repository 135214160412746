import React, { useEffect, useState } from 'react';
import "./InternalInside.css";
import Select from "react-select";
import { Button, CircularProgress } from '@mui/material';
import PopUpModel from '../../comman/PopUpModel';
import AddField from '../InternalComponent/AddField';
import { addInternalCompanyProfileFile } from '../../Context/APIs';
import { NotificationManager } from 'react-notifications';

const InsideCompanyProfile = ({ tableColumn, fileName, setScreenOpen, csvFile }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValues2, setSelectedValues2] = useState({});
  const [tableOptions, setTableOptions] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loader, setLoader] = useState(false);
  const formData = new FormData();
  const [headerName, setHeaderName] = useState({
    name: "",
    property: ""
  });
  const [allHeaders, setAllHeaders] = useState([
    {
      label: "Company",
      value: "company",
    },
    {
      label: "Company Name",
      value: "companyname",
    },
    {
      label: "Account Stage",
      value: "accountstage",
    },
    {
      label: "Lists",
      value: "lists",
    },
    {
      label: "Employees",
      value: "employees",
    },
    {
      label: "Industry",
      value: "industry",
    },
    {
      label: "Account Owner",
      value: "accountowner",
    },
    {
      label: "Website",
      value: "website",

    },
    {
      label: "Company Linkedin Url",
      value: "linkedin",

    },
    {
      label: "Facebook Url",
      value: "facebook",

    },
    {
      label: "Twitter Url",
      value: "twitter",

    },
    {
      label: "Company Street",
      value: "companystreet",

    },
    {
      label: "Company City",
      value: "companycity",

    },
    {
      label: "Company State",
      value: "companystate",

    },
    {
      label: "Company Country",
      value: "companycountry",

    },
    {
      label: "Company Postal Code",
      value: "companypostalcode",

    },
    {
      label: "Company Address",
      value: "companyaddress",

    },
    {
      label: "Keywords",
      value: "keywords",

    },
    {
      label: "Company Phone",
      value: "companyphone",

    },
    {
      label: "Seo Description",
      value: "seodescription",

    },
    {
      label: "Technologies",
      value: "technologies",

    },
    {
      label: "Total Funding",
      value: "totalfunding",

    },
    {
      label: "Latest Funding",
      value: "latestfunding",

    },
    {
      label: "Latest Funding Amount",
      value: "latestfundingamount",

    },
    {
      label: "Last Raised At",
      value: "lastraisedat",

    },
    {
      label: "Annual Revenue",
      value: "annualrevenue",

    },
    {
      label: "Number Of Retail Locations",
      value: "numberofretaillocations",

    },
    {
      label: "Apollo Account ID",
      value: "apolloaccountid",

    },
    {
      label: "Sic Codes",
      value: "siccodes",

    },
    {
      label: "Short Description",
      value: "shortdescription",

    },
    {
      label: "Founded Year",
      value: "foundedyear",

    },
    {
      label: "Logo URL",
      value: "logourl",

    }
  ]);


  const handleSubmitField = () => {
    if(isError) {
      return;
    }
    if (headerName?.name.length > 0 && headerName?.property?.length > 0) {
      setAllHeaders((prev) => [...prev, { value: headerName.property, label: headerName.name }]);
      setIsModal(false);
    }
  }

  const changeSelectHandler = (option, name) => {
    let array = tableOptions || [];
    const index = array.findIndex(obj => Object.keys(obj)[0] === name);
    if (index !== -1) {
        array[index][name] = option?.label;
    } else {
        const newObject = { [name]: option?.label };
        array.push(newObject);
    }
    setSelectedValues2(prev => ({
      ...prev,
      [name] : option.value
    }))
    setTableOptions(array);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newArray = tableOptions.filter(obj => Object.keys(obj).length !== 0);

    const convertedObject = {};

    newArray.forEach(item => {
      const key = Object.keys(item)[0];
      const value = item[key];
      convertedObject[key] = value;
    });

    formData.append("file", csvFile);
    formData.append("filename", String(fileName)?.split(".")[0]);
    formData.append("data", JSON.stringify(convertedObject));
    setLoader(true);

    try {
      const res = await addInternalCompanyProfileFile(formData);

      if(res) {
        NotificationManager.info(res?.data?.msg || "Uploaded Successfully");
      }
      setScreenOpen(null);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setScreenOpen(null);
      
    }
  }

  const removeItem = (index) => {
    let headersItems = [...allHeaders];
    headersItems.splice(index, 1);
    setAllHeaders(headersItems);
  }

  function escapeRegExp(str) {
    return str ? str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : "";
  }

  useEffect(() => {

    const array3 = tableColumn.map(item2 => {
      const matchingItem1 = allHeaders.find(item1 => new RegExp(`\\b${(item2?.value)}\\b`, "gi").test(item1.label));
      if (matchingItem1) {
        return { [matchingItem1.value]: item2.value };
      } else {
        return {};
      }
    });
    setTableOptions(array3)
  }, [tableColumn])

  useEffect(() => {
    const newArray = tableOptions.filter(obj => {
      const keys = Object.keys(obj);
      if (keys.length === 1 && obj[keys[0]]) {
          return true;
      }
      return false;
  }).map(obj => {
      const key = Object.keys(obj)[0];
      return {
          label: obj[key],
          value: key
      };
  });

  setSelectedValues(newArray);
  }, [tableOptions])



  return (
    <div>
      <div className='insideHeaderContainerItem'>
        <span></span>
        <h6 className="insideHeading">{`${fileName} (${tableColumn?.length} headers)`}</h6>
        <Button
          onClick={() => setIsModal(true)}
          variant="contained"
          className="m-2 p-2 mr-0 f-16"
          style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
        >
          Add Field
        </Button>
      </div>

      <form onSubmit={handleSubmit}>

        <div className='insideHeaderContainer'>
          {
            allHeaders?.map((item, index) => {
              return (
                <div className='insideHeaderItem'>
                  <div className='insideHeaderLabelFlex'>
                    <label htmlFor="" className='insideHeaderLabel'>{item?.label}</label>
                    {
                      index > 32 && (
                        <span onClick={() => removeItem(index)}>&#10060;</span>
                      )
                    }
                  </div>
                  <Select
                    value={selectedValues2[item.value]? {value: [selectedValues2[item.value]], label: [selectedValues2[item.value]]}:selectedValues.filter(opt => new RegExp(`\\b${item?.value}\\b`, "gi").test(opt.value))[0] || []}
                    required={index > 31 ? false : true} isMulti={false} isSearchable={true}
                    closeMenuOnSelect={true}
                    onChange={(option) => changeSelectHandler(option, item?.value)}
                    options={tableColumn || []} />
                </div>
              )
            })
          }

        </div>
        <Button
          type='submit'
          variant="contained"
          className="m-2 p-2 mr-0 f-16 mt-3"
          style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
        >
          {
            loader ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Submit"
          }
        </Button>
      </form>

      <PopUpModel
        open={isModal}
        title={"Add Field"}
        close={() => {
          setIsModal(false);
        }}
      >
        <AddField setHeaderName={setHeaderName} headerName={headerName} handleSubmit={handleSubmitField} isError={isError} setIsError={setIsError} />
      </PopUpModel>
    </div>
  )
}

export default InsideCompanyProfile;