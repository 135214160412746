import {
  createSubAdmin,
  getAllSubAdmin,
  deleteSubAdmin,
  getAdminTabs,
  getSubAdmin,
  editSubAdmin,
} from "../APIs";
import { NotificationManager } from "react-notifications";
import moment from "moment";

export const getAllSubAdminReducer = async (updateState, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await getAllSubAdmin();
    if (res.data) {
      const formatedData = res.data.map((item) => {
        return {
          ...item,
          createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
          access_tabs: item.access_tabs.map((item) => {
            return {
              value: item._id,
              label: item.description,
            };
          }),
        };
      });
      updateState(formatedData);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getSubAdminReducer = async (id, updateState) => {
  try {
    const res = await getSubAdmin(id);
    if (res.data) {
      const formatedData = {
        ...res.data,
        createdAt: moment(res.data.createdAt).format("DD/MM/YYYY"),
        access_tabs: res.data.access_tabs.map((item) => item.description),
      };
      updateState(formatedData);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
export const editSubAdminReducer = async (
  id,
  values,
  setIsModal,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await editSubAdmin(id, values);
    if (res.data) {
      NotificationManager.success(res.data);
      setIsModal(false);
      getAllSubAdminReducer(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const createSubAdminReducer = async (
  values,
  setIsModal,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await createSubAdmin(values);
    if (res.data) {
      NotificationManager.success(res.data);
      setIsModal(false);
      getAllSubAdminReducer(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const deleteSubAdminReducer = async (id, updateState) => {
  try {
    const res = await deleteSubAdmin(id);
    if (res.data) {
      NotificationManager.success(res.data);
      getAllSubAdminReducer(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};

export const getAdminTabsReducer = async (updateState) => {
  try {
    const res = await getAdminTabs();
    if (res.data) {
      const formatedData = res.data.map((item) => {
        return {
          value: item._id,
          label: item.description,
        };
      });
      updateState(formatedData);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
