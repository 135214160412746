/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { Button } from '@mui/material';
import { Table } from 'antd';
import Tippy from '@tippyjs/react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { downloadCSV } from 'download-csv';
import CompanyTable from '../Companies/CompanyTable';
import AddCompany from './AddCompany';
import TextField from '@mui/material/TextField';
import PopUpModel from '../../../../componants/comman/PopUpModel';
import {
	BLOCK_UNBLOCK_COMPANY,
	GET_ALL_COMPANY,
	SUSPEND_UNSUSPEND_COMPANY,
	VERIFY_COMPANY_EMAIL,
} from '../../../../componants/Context/Types';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import { NotificationManager } from 'react-notifications';

const keys = [
	'name',
	'company_name',
	'email',
	'mobile',
	// "plan subscription_type",
	// "login_ip",
	'credits',
	// "plan subscription_end_date",
];

const headers = [
	{ label: 'Type', key: 'type' },
	{ label: 'Name', key: 'name' },
	{ label: 'Company Name', key: 'company_name' },
	{ label: 'Email', key: 'email' },
	{ label: 'Mobile', key: 'mobile' },
	{ label: 'SignUp Date', key: 'createdAt' },
	{ label: 'Subscription', key: 'plan.subscription_type' },
	{ label: 'Subscription Expiry', key: 'plan.subscription_end_date' },
];

const Company = (props) => {
	const { dispatch } = useContext(AuthContext);
	const [rows, setRows] = useState([]);
	const [model, setmodel] = useState(false);
	const [currCompanyType, setCurrCompanyType] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [exportData, setExportData] = useState([]);

	useEffect(() => {
		dispatch({
			type: GET_ALL_COMPANY,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	}, []);

	const search = (rows) => {
		return rows.filter((item) => {
			return keys.some((key) => {
				const splitArr = key.split(' ');
				if (splitArr[0] === 'plan')
					return item[splitArr[0]][splitArr[1]]
						?.toString()
						.toLowerCase()
						.includes(searchValue);
				else
					return item[splitArr[0]]
						?.toString()
						.toLowerCase()
						.includes(searchValue);
			});
		});
	};

	useEffect(() => {
		setExportData(search(rows));
	}, [searchValue, rows]);

	const handleBlockAndUnBlock = (id) => {
		dispatch({
			type: BLOCK_UNBLOCK_COMPANY,
			id: id,
			updateState: setRows,
		});
	};

	const handleSuspendAndUnSuspend = (id) => {
		dispatch({
			type: SUSPEND_UNSUSPEND_COMPANY,
			id: id,
			updateState: setRows,
		});
	};

	const verifyEmail = (id) => {
		dispatch({
			type: VERIFY_COMPANY_EMAIL,
			id: id,
			updateState: setRows,
		});
	};

	const columns = [
		{
			title: 'Type',
			render: (_, record) => (
				<p>
					{record?.plan
						? record?.plan?.subscription_type !== 'Free Trial' &&
						  !record?.plan?.isExpired
							? 'PREMIUM'
							: 'FREE'
						: 'FREE'}
				</p>
			),
			// filters: [
			// 	{
			// 		text: 'FREE',
			// 		value: 'free',
			// 	},
			// 	{
			// 		text: 'PREMIUM',
			// 		value: 'premium',
			// 	},
			// ],
			// onFilter: (value, record) => record?.plan?.startsWith(value),
		},
		{
			title: 'Name',

			render: (_, record) => (
				<p
					style={{
						color: '#0000EE',
						textDecoration: 'underline',
						cursor: 'pointer',
					}}
					onClick={() => {
						props.setflag('company');
						props.setTitle('User info');
						props.setSelectedCompanyByAdmin(record?._id);
						props.setSelectedCompanyUserName(record?.name);
					}}
				>
					{record?.name}
				</p>
			),
		},
		{
			title: 'Company',
			render: (_, record) => <p>{record?.company_name}</p>,
		},
		{
			title: 'Email',
			dataIndex: 'email',
		},
		{
			title: 'Verified',
			render: (_, record) => (
				<p>{record?.isEmailVerified === true ? <p>✅</p> : <p>❌</p>}</p>
			),
		},
		{
			title: 'Mobile',
			render: (_, record) => <p>{record?.mobile}</p>,
		},
		{
			title: 'SignUp',
			render: (_, record) => <p>{record?.createdAt}</p>,
		},
		{
			title: 'Credits',
			render: (_, record) => <p>{record?.credits}</p>,
		},
		{
			title: 'Plan',
			render: (_, record) => <p>{record?.plan?.subscription_type}</p>,
		},
		{
			title: 'Plan Status',
			render: (_, record) => (
				<p
					className={` ${
						record?.plan?.isExpired === true ? 'text-danger' : 'text-success'
					}`}
				>
					{record?.plan?.isExpired === true ? 'InActive' : 'Active'}
				</p>
			),
			// filters: [
			// 	{
			// 		text: 'Active',
			// 		value: false,
			// 	},
			// 	{
			// 		text: 'InActive',
			// 		value: true,
			// 	},
			// ],
			// onFilter: (value, record) => record?.plan?.isExpired == value,
		},
		{
			title: 'Plan Expiry',
			render: (_, record) => <p>{record?.plan?.subscription_end_date}</p>,
		},

		{
			title: 'Suspend',
			render: (_, record) => (
				<p
					className={` ${
						record?.suspended === true ? 'text-danger' : 'text-success'
					}`}
				>
					{record?.suspended === true ? 'True' : 'False'}
				</p>
			),

			// filters: [
			// 	{
			// 		text: 'True',
			// 		value: true,
			// 	},
			// 	{
			// 		text: 'False',
			// 		value: false,
			// 	},
			// ],
			// onFilter: (value, record) => record?.suspended === value,
		},
		{
			title: 'Block',
			render: (_, record) => (
				<p
					className={` ${
						record?.blocked === true ? 'text-danger' : 'text-success'
					}`}
				>
					{record?.blocked === true ? 'True' : 'False'}
				</p>
			),
			// filters: [
			// 	{
			// 		text: 'True',
			// 		value: true,
			// 	},
			// 	{
			// 		text: 'False',
			// 		value: false,
			// 	},
			// ],
			// onFilter: (value, record) => record?.blocked === value,
		},
		{
			title: 'Action',
			render: (_, record) => (
				<Tippy
					content={
						<div className='TippyAction bg-light pointer'>
							<div
								className=' p-1 pointer hover-dropdown'
								onClick={() => {
									handleSuspendAndUnSuspend(record._id);
								}}
							>
								{record?.suspended === true ? 'UnSuspend' : 'Suspend'}
							</div>
							<div
								className=' p-1 pointer hover-dropdown'
								onClick={() => {
									if (record.blocked === true) {
										//("Already Blocked!");
									} else {
										handleBlockAndUnBlock(record._id);
									}
								}}
							>
								{record?.blocked === true ? 'Blocked' : 'Block'}
							</div>
							{!record?.isEmailVerified ? (
								<div
									className='pointer p-1 hover-dropdown'
									onClick={() => {
										verifyEmail(record?._id);
									}}
								>
									Verify Email
								</div>
							) : (
								''
							)}
						</div>
					}
					placement='bottom-end'
					arrow={false}
					offset={[15, 1]}
					trigger='focusin'
					interactive={true}
				>
					<MoreVertIcon></MoreVertIcon>
				</Tippy>
			),
		},
	];
	return (
		<div>
			<div className='btn-class'>
				<h4 className='pr-3 d-flex justify-content-between align-items-center'>
					<div className='p-2 profile-header f-14'>Companies</div>
					<TextField
						id='outlined-basic'
						value={searchValue}
						size='small'
						name='Search'
						label='Search'
						variant='outlined'
						onChange={(e) => setSearchValue(e.target.value)}
					/>
					<Button
						variant={'contained'}
						size='small'
						onClick={() => {
							downloadCSV(exportData, headers);
						}}
					>
						Export To CSV
					</Button>
					<Button
						variant={'contained'}
						size='small'
						onClick={() => {
							setmodel(true);
						}}
					>
						Add Company
					</Button>
				</h4>
			</div>
			<div>
				<div className='m-1'>
					<Table
						columns={columns}
						dataSource={search(rows)}
						loading={isLoading}
						pagination={{
							pageSize: 10,
						}}
						scroll={{
							x: 3000,
							y: 400,
						}}
						onChange={(pagination, filters, sorter, extra) => {
							setExportData(extra.currentDataSource);
						}}
					/>
				</div>

				<PopUpModel
					open={model}
					title={'Add Company Details'}
					close={() => {
						setmodel(false);
					}}
					bodyClass={'AddCompany'}
				>
					<AddCompany
						open={model}
						close={() => {
							setmodel(false);
						}}
						title={'Add Company Details'}
						buttonname={'Submit'}
					/>
				</PopUpModel>
			</div>
		</div>
	);
};

export default Company;
