/** @format */

import React, { useState, useEffect, useContext } from 'react';
import {
	ACCEPT_MEMBER_CREDIT_REQUEST,
	DENY_MEMBER_CREDIT_REQUEST,
	GET_COMPANY_CREDITS_REQUEST,
	GET_COMPANY_CREDITS_REQUEST_ADMIN,
} from '../../../componants/Context/Types';
import { AuthContext } from '../../../componants/Context/AuthContext';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import TableSkeltonLoader from '../../../componants/comman/TableSkeltonLoader/TableSkeltonLoader';
import DynamicTable from '../../../Elements/Misc/DTable';

const CompanyCreditsRequest = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { dispatch } = useContext(AuthContext);

	useEffect(() => {
		if (props?.selectedCompanyByAdmin) {
			dispatch({
				type: GET_COMPANY_CREDITS_REQUEST_ADMIN,
				id: props?.selectedCompanyByAdmin,
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_COMPANY_CREDITS_REQUEST,
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		}
	}, []);

	const handleApprove = (id) => {
		dispatch({
			type: ACCEPT_MEMBER_CREDIT_REQUEST,
			id: id,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	};
	const handleDeny = (id) => {
		dispatch({
			type: DENY_MEMBER_CREDIT_REQUEST,
			id: id,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	};

	const columns = [
		{ title: 'Member' },
		{ title: 'Credits Requested' },
		{ title: 'Date' },
		{ title: 'Status' },
		{ title: 'Actions' },
	];

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'Member') {
			return (
				<>
					<p>{row?.member?.name}</p>
					<p>{row?.member?.email}</p>
				</>
			);
		} else if (col.title === 'Credits Requested') {
			return row.credits;
		} else if (col.title === 'Date') {
			return new Date(row?.updatedAt).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});
		} else if (col.title === 'Actions') {
			return (
				<>
					{row?.status === 'PENDING' && (
						<div className='flex items-center !gap-4'>
							<div
								onClick={() => {
									handleApprove(row?._id);
								}}
								className='cursor-pointer flex items-center gap-1 text-emerald-600 hover:underline'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-4'
								>
									<path d='M20 6 9 17l-5-5' />
								</svg>
								Approve
							</div>
							<div
								onClick={() => {
									handleDeny(row?._id);
								}}
								className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-4'
								>
									<path d='M18 6 6 18' />
									<path d='m6 6 12 12' />
								</svg>
								Deny
							</div>
						</div>
					)}
				</>
			);
		} else if (col.title === 'Status') {
			return row?.status === 'PENDING' ? (
				<div>
					<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-yellow-100 text-yellow-700 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3'
						>
							<path d='M10.1 2.18a9.93 9.93 0 0 1 3.8 0' />
							<path d='M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7' />
							<path d='M21.82 10.1a9.93 9.93 0 0 1 0 3.8' />
							<path d='M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69' />
							<path d='M13.9 21.82a9.94 9.94 0 0 1-3.8 0' />
							<path d='M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7' />
							<path d='M2.18 13.9a9.93 9.93 0 0 1 0-3.8' />
							<path d='M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69' />
							<circle cx='12' cy='12' r='1' />
						</svg>
						Pending
					</span>
				</div>
			) : row.status === 'DECLINED' ? (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-red-100 text-red-700 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3 rotate-45'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='M8 12h8' />
							<path d='M12 8v8' />
						</svg>
						Declined
					</span>
				</div>
			) : (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-emerald-100 text-emerald-700 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='m9 12 2 2 4-4' />
						</svg>
						Approved
					</span>
				</div>
			);
		}
		return row[col.title];
	};

	return (
		<>
			<div>
				{isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={3} />
					</div>
				) : (
					<DynamicTable
						columns={columns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)}
				{!isLoading && (
					<PaginationRow
						totalResults={rows?.length}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				)}
			</div>
		</>
	);
};

export default CompanyCreditsRequest;
