import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { getCompanyInternalFileVerificationApi } from '../../componants/Context/APIs';
import CompanyFileVerification from './CompanyFileVerification';
import CompanyCompanyProfile from './CompanyCompanyFile';
import CompanyProjectFile from './CompanyProjectFile';
import CompanyBounceRepository from './CompanyBounceFile';

const CompanyInternal = ({selectedCompanyByAdmin}) => {
    const [titlebtn, settitlebtn] = useState("File Verification");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [verData, setVerData] = useState([]);
    const [verLoader, setVerLoader] = useState(false);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState([]);



    const getFileVerification = async (from, to) => {
        try {
            setVerLoader(true);
            const res = await getCompanyInternalFileVerificationApi(from, to, selectedCompanyByAdmin, page);
            setVerData(res?.data?.final[0] || []);
            setTotalCount(res?.data?.totalCount[0]);
            setPages(res?.data?.pages);
            setVerLoader(false);
        } catch (error) {
            setVerLoader(false);
        }
    }

    const onDateChange = (e) => {
        setFromDate(e.target.value)
    }
    const onDateChange2 = (e) => {
        setToDate(e.target.value)
    }

    useEffect(() => {
        if (fromDate && toDate && selectedCompanyByAdmin) {
            getFileVerification(fromDate, toDate);
        }
    }, [fromDate, toDate, selectedCompanyByAdmin, page])

    return (
        <div className='p-3'>
            <div className="d-flex justify-content-end  pb-0 pt-0  hadbar-btn header_internal mb-2 w-100" style={{ boxShadow: 'none' }}>
                <Button
                    variant={`${titlebtn == "File Verification" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight word-break f-16"
                    onClick={() => {
                        settitlebtn("File Verification");
                    }}
                >
                    File Verification
                </Button>
                <Button
                    variant={`${titlebtn == "Company Profile" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        settitlebtn("Company Profile");
                    }}
                >
                    Company Profile
                </Button>
                <Button
                    variant={`${titlebtn == "Project File" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        settitlebtn("Project File");
                    }}
                >
                    Project File
                </Button>
                <Button
                    variant={`${titlebtn == "Bounce Repository" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        settitlebtn("Bounce Repository");
                    }}
                >
                    Bounce Repository
                </Button>
            </div>
            <div className='d-flex justify-content-end align-items-center mb-2'>
                {
                    titlebtn === "File Verification" && (
                        <div className='internalDateContainer'>
                            <span className='me-2 f-16'>From:</span>
                            <input className='internalDatePicker me-2' type="date" name="" id="" onChange={onDateChange} />
                            <span className='me-2 f-16'>To:</span>
                            <input className='internalDatePicker' type="date" name="" id="" onChange={onDateChange2} />
                        </div>
                    )
                }

            </div>
            {
                titlebtn === "File Verification" && <CompanyFileVerification selectedCompanyByAdmin={selectedCompanyByAdmin} setVerData={setVerData} verData={verData} setVerLoader={setVerLoader} verLoader={verLoader} setPages={setPages} pages={pages} setPage={setPage} page={page} totalCount={totalCount} setTotalCount={setTotalCount} />
            }
            {
                titlebtn === "Company Profile" && <CompanyCompanyProfile selectedCompanyByAdmin={selectedCompanyByAdmin} />
            }
            {
                titlebtn === "Project File" && <CompanyProjectFile selectedCompanyByAdmin={selectedCompanyByAdmin} />
            }
            {
                titlebtn === "Bounce Repository" && <CompanyBounceRepository selectedCompanyByAdmin={selectedCompanyByAdmin} />
            }
        </div>
    )
}

export default CompanyInternal;