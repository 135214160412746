import React from 'react';
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { getInternalProjectFile } from '../Context/APIs';
import { useState } from 'react';
import FacebookCircularProgress from '../comman/FacebookCircularProgress';
import { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Button, CircularProgress, Pagination, Stack } from '@mui/material';

const ProjectFile = ({ setScreenOpen, setFileName, setColumns, setCsvFile }) => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total1, setTotal1] = useState(0);
    const [loader, setLoader] = useState(false);

    const getProjectFileHandler = async () => {
        setLoading(true);
        try {
            const res = await getInternalProjectFile(page);
            setData(res?.data?.data[0] || []);
            setTotal1(res?.data?.totalCount[0][0]?.TotalCount || 0);
            setPages(res?.data?.pages);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            
        }
    }

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        let col = [];
        headers?.forEach((item) => {
            if (item && String(item)?.length > 0) {
                col.push({
                    label: item,
                    value: item,
                })
            }
        })
        setColumns(col);
        setLoader(false);
        setScreenOpen("Project File")

    }

    const uploadButtonHandler = (e) => {
        setLoader(true);
        const file = e.target.files[0];
        setCsvFile(file)
        setFileName(file?.name || "");
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        if (file) {
            reader.readAsBinaryString(file);
        }
    }


    const handleChange = (e, value) => {
        setPage(value)
    }

    useEffect(() => {
        getProjectFileHandler();
    }, [page])
    return (
        <div>
            <Button
                component="label"
                variant="contained"
                className="m-2 p-2 mr-0 f-16"
                style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
            >
                {
                    loader ? <CircularProgress sx={{ color: "white" }} size={15} />
                        : "Upload File"
                }
                <input
                    disabled={loader}
                    type="file"
                    accept='.csv'
                    hidden
                    onChange={(e) => uploadButtonHandler(e)}
                />
            </Button>
            {
                loading ? (
                    <div className="d-flex justify-content-between flex-row">
                        <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
                            <FacebookCircularProgress />
                        </div>
                    </div>
                ) : (
                    <TableContainer style={{ overflowY: "auto", maxHeight: "60vh" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead style={{ position: "sticky", top: "0" }}>
                                <TableRow className="bg-light">
                                    <TableCell className="fw-bold" align="center">
                                        File Name
                                    </TableCell>
                                    <TableCell className="fw-bold" align="center">
                                        Uploaded By
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Creation Date
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Count
                                    </TableCell>
                                    <TableCell align="center" className="fw-bold">
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data && data.length > 0 && data.map((row) => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                    className="p-2"
                                                >
                                                    {row.filename}
                                                </TableCell>
                                                <TableCell align="center" className="p-2">
                                                    {row.uploadby}
                                                </TableCell>
                                                <TableCell align="center" className="p-2">
                                                    {new Date(row.created_at).toUTCString()}
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.RepeatCount}
                                                </TableCell>
                                                <TableCell align="center" className={`p-2`}>
                                                    {row?.progress_status || "-"}
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })}
                                <TableRow
                                    style={{ backgroundColor: "lightgray" }}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        className="p-2"
                                    >
                                        Total
                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                    <TableCell align="center" className={`p-2`}>
                                        {total1}
                                    </TableCell>
                                    <TableCell align="center" className="p-2">

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        
                        <Stack spacing={2} className="mt-3">
                            <Pagination
                                count={pages}
                                page={page}
                                onChange={handleChange}
                            />
                        </Stack>
                    </TableContainer>
                )
            }

        </div>
    )
}

export default ProjectFile