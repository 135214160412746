import axios from "axios";
import React, { useContext, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { GET_CREDITS } from "../Context/Types";

const UnderContruction = () => {
  const { dispatch } = useContext(AuthContext);
  const location = new URLSearchParams(useLocation().search);
  useEffect(() => {
    if (location.get("status") === "fail") {
      //("Payment Failed");
    }
    if (location.get("status") === "success") {
      NotificationManager.success("Payment Successfull");
      dispatch({ type: GET_CREDITS });
    }
  }, []);
  return <div>Page Is Under Contruction </div>;
};

export default UnderContruction;
