import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import { NotificationManager } from "react-notifications";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import Select from "react-select";
import {
  CREATE_COMPANY_ADMIN,
  GET_ALL_SUBSCRIPTION_PLAN,
} from "../../../../componants/Context/Types";
import _ from "lodash";
const AddCompany = (props) => {
  const [info, setInfo] = useState({
    name: "",
    email: "",
    mobile: "",
    company_name: "",
    password: "",
  });
  const [subscriptions, setSubscriptions] = useState([]);
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  useEffect(() => {
    dispatch({
      type: GET_ALL_SUBSCRIPTION_PLAN,
      id: "",
      flag: "COMMON",
      updateState: setSubscriptions,
      setIsLoading: setIsLoading,
    });
  }, []);

  useEffect(() => {
    setSubscriptionOptions(
      subscriptions.map((item) => {
        return {
          value: item._id,
          label: item.title,
        };
      })
    );
  }, [subscriptions]);

  const handleSubmit = () => {
    if (!selectedType) {
      return;
    }
    for (const [key, value] of Object.entries(info)) {
      if (key === "mobile" || key == "company_name") continue;
      if (!value) {
        return;
      }
    }
    let values = { ...info, type: selectedType?.value };
    if (selectedType?.value === "PREMIUM") {
      if (!selectedSubscription) {
        //("Choose Subscription!");
        return;
      }
      values["subscription_id"] = selectedSubscription?.value;
    }
    dispatch({
      type: CREATE_COMPANY_ADMIN,
      values: values,
      closeModal: props.close,
      setIsLoading: setIsLoading,
    });
  };

  return (
    <>
      <div className=" d-flex flex-column justify-content-between ">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="d-flex flex-wrap justify-content-between ">
              <Select
                options={[
                  { value: "FREE", label: "FREE" },
                  { value: "PREMIUM", label: "PREMIUM" },
                  // { value: "personlized", label: "personlized" },
                ]}
                className="user-input-fild w-35 m-2 user-select-dropdown"
                value={selectedType}
                onChange={setSelectedType}
                placeholder="Type of Company"
              />
              {selectedType?.value === "PREMIUM" ? (
                <Select
                  options={subscriptionOptions}
                  value={selectedSubscription}
                  placeholder="Subscription"
                  className="user-input-fild w-35 m-2 user-select-dropdown"
                  onChange={setSelectedSubscription}
                />
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                size="small"
                className="user-input-fild w-35 m-2"
                label="UserName"
                name="name"
                value={info.name}
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, name: e.target.value };
                  });
                }}
              />
              <TextField
                id="outlined-basic"
                size="small"
                className="user-input-fild w-35 m-2"
                label="Email"
                name="Email"
                value={info.email}
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, email: e.target.value };
                  });
                }}
              />
              <TextField
                id="outlined-basic"
                size="small"
                className="user-input-fild w-35 m-2"
                label="Mobile"
                name="mobile"
                value={info.mobile}
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, mobile: e.target.value };
                  });
                }}
              />
              <TextField
                id="outlined-basic"
                size="small"
                className="user-input-fild w-35 m-2"
                label="Company Name"
                value={info.company_name}
                name="Company Name"
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, company_name: e.target.value };
                  });
                }}
              />
              <TextField
                id="outlined-basic"
                size="small"
                className="user-input-fild w-35 m-2"
                label="Password"
                name="password"
                value={info.password}
                variant="outlined"
                onChange={(e) => {
                  setInfo((prev) => {
                    return { ...prev, password: e.target.value };
                  });
                }}
              />
              {/* {userType === "personlized" && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="Credits"
              name="Credits"
              variant="outlined"
            />
          )}
          {userType === "personlized" && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="Credits Cost"
              name="Credits Cost"
              variant="outlined"
            />
          )}
          {userType === "personlized" && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="Cost"
              name="Cost"
              variant="outlined"
            />
          )}
          {userType === "personlized" && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="No. Of Team Members"
              name="No. Of Team Members"
              variant="outlined"
            />
          )}
          {userType === "personlized" && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="Cost of each Team Member"
              name="Cost of each Team Member"
              variant="outlined"
            />
          )} */}
              {/* {["premium", "personlized"].includes(userType) && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="Billing Cycle"
              name="Billing Cycle"
              variant="outlined"
            />
          )} */}
              {/* {["premium", "personlized"].includes(userType) && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="Subscription Package Cost"
              name="Subscription Package Cost"
              variant="outlined"
            />
          )}
          {userType === "personlized" && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="Total Payble Amount"
              name="Total Payble Amount"
              variant="outlined"
            />
          )}
          {userType === "personlized" && (
            <TextField
              id="outlined-basic"
              size="small"
              className="user-input-fild w-35 m-2"
              label="Discount"
              name="Discount"
              variant="outlined"
            />
          )} */}
            </div>

            {/* <div className="form-group mb-0  d-flex justify-content-around">
          <label className="radio-inline me-3 d-flex align-items-center">
            <input
              type="radio"
              name="optradio"
              style={{ width: "20px", height: "20px" }}
            />
            <span style={{ fontSize: "15px" }}>
              &nbsp; &nbsp; Download Invoice
            </span>
          </label>
          <label className="radio-inline me-3 d-flex align-items-center">
            <input
              type="radio"
              name="optradio"
              style={{ width: "20px", height: "20px" }}
            />
            <span style={{ fontSize: "15px" }}>
              {" "}
              &nbsp; &nbsp; Send Invoice
            </span>
          </label>
        </div> */}
            <div className="m-0 d-flex justify-content-between">
              <Button
                variant="contained"
                className="m-2 p-2 mr-0"
                onClick={handleSubmit}
              >
                {props.buttonname}
              </Button>
              <Button
                variant="outlined"
                className="m-2 p-2 mr-0"
                onClick={props.close}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddCompany;
