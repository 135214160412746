import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import failed from "../../../assests/image/failed.png";

const Failed = () => {
  const navigate = useNavigate();
  return (
    <div className=" m-5">
      <div className="shadow-lg m-5">
        <h6
          className="text-start px-4 pt-4 text-primary pointer"
          onClick={() => {
            navigate("/profile");
          }}
        >
          <ArrowBackIosIcon style={{ width: "20px", height: "20px" }} />
          Back
        </h6>
        <div className="px-5 mx-5 ">
          <h3 className="mb-4">Payment Failed</h3>
          <img
            src={failed}
            alt="as"
            width={"200"}
            height={"200"}
            className="p-3"
          />
          <h3 className="px-5 mx-5 my-5 ">
            Sorry, Your Payment Is Failed, Please Try Again.
          </h3>
          <div className="m-auto d-flex flex-row w-50 justify-content-around p-4"></div>
        </div>
      </div>
    </div>
  );
};

export default Failed;
