import {
  getInvitedMemberRequest,
  getTeamMembers,
  inviteTeamMembers,
  getCompanyCredits,
  inviteCompanyMember,
  deleteInvite,
  deleteMember,
  assignCreditToMember,
  revokeCreditFromMember,
  blockAndUnblock,
  deleteInviteAdmin,
} from "../APIs";
import { getCompanyMembersReducer } from "./CompanyReducer";
import NotificationManager from "react-notifications/lib/NotificationManager";

export const getMembers = async (updateState, setIsLoading, setallData) => {
  setIsLoading(true);
  try {
    const res = await getTeamMembers();
    updateState(res.data);
    setallData(res.data);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const inviteMember = async (
  values,
  updateState,
  resetForm,
  setIsLoading
) => {
  setIsLoading({
    value: true,
  });
  try {
    const res = await inviteTeamMembers(values);
    if (res.data) {
      resetForm({ values: "" });
      setIsLoading({
        value: false,
        message: res.data,
        severity: "success",
      });
      getMembers(updateState, () => {});
      window.location = res.data.link;
    }
  } catch (e) {
    console.log(e);
    setIsLoading({
      value: false,
      message: e.response.data,
      severity: "error",
    });
  } finally {
    setTimeout(() => {
      setIsLoading({
        value: false,
      });
    }, 5000);
  }
};

export const getInvitedMember = async (invite_id, upDateState, setIsMember) => {
  try {
    const res = await getInvitedMemberRequest(invite_id);
    setIsMember(true);
    upDateState({
      name: res.data.name,
      email: res.data.email,
      password: "",
      companyName: res.data.company_name,
      acceptTerms: false,
    });
    console.log(res);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};

export const getCompanyCreditsReducer = async (id, updateState) => {
  try {
    const res = await getCompanyCredits(id);
    if (res.data || res.data === 0) {
      updateState(res.data);
    }
    console.log(res);
  } catch (e) {
    console.log(e);
  }
};

export const inviteCompanyMemberReducer = async (
  id,
  updateState,
  values,
  resetForm,
  setIsLoading
) => {
  setIsLoading({
    value: true,
  });
  try {
    const res = await inviteCompanyMember(id, values);
    if (res.data) {
      resetForm({ values: "" });
      setIsLoading({
        value: false,
        message: res.data,
        severity: "success",
      });
      getCompanyMembersReducer(id, updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    setIsLoading({
      value: false,
      message: e.response.data,
      severity: "error",
    });
  } finally {
    setTimeout(() => {
      setIsLoading({
        value: false,
      });
    }, 5000);
  }
};

export const deleteInviteReducer = async (id, updateState, setIsLoading) => {
  try {
    const res = await deleteInvite(id);
    if (res.data) {
      NotificationManager.success(res.data);
      getMembers(updateState, setIsLoading);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
export const deleteInviteAdminReducer = async (
  companyId,
  inviteId,
  updateState,
  setallData
) => {
  try {
    const res = await deleteInviteAdmin(companyId, inviteId);
    if (res.data) {
      NotificationManager.success(res.data);
      getCompanyMembersReducer(companyId, updateState, () => {}, setallData);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
export const deleteMemberReducer = async (id, updateState, setIsLoading) => {
  try {
    const res = await deleteMember(id);
    if (res.data) {
      NotificationManager.success(res.data);
      getMembers(updateState, setIsLoading);
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
export const revokeCreditFromMemberReducer = async (
  id,
  value,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await revokeCreditFromMember(id, value);
    if (res.data) {
      NotificationManager.success(res.data);
      getMembers(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const assignCreditToMemberReducer = async (
  id,
  value,
  updateState,
  setIsLoading,
  close
) => {
  setIsLoading(true);
  try {
    const res = await assignCreditToMember(id, value);
    if (res.data) {
      setIsLoading(false);
      close(true);
      NotificationManager.success(res.data);
      getMembers(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const blockAndUnblockReducer = async (id, updateState) => {
  try {
    const res = await blockAndUnblock(id);
    if (res.data) {
      NotificationManager.success(res.data);
      getMembers(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  }
};
