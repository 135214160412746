/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

import {
	ASSIGN_CREDITS,
	ASSIGN_CREDITS_TO_COMPANY,
} from '../../../componants/Context/Types';
import { NotificationManager } from 'react-notifications';
import { AuthContext } from '../../../componants/Context/AuthContext';

const AssignCredit = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [credits, setCredits] = useState(0);
	const { dispatch } = useContext(AuthContext);

	const handleSubmit = () => {
		if (!Number(credits)) {
			NotificationManager.error('Enter number of Credits');
			return;
		}
		if (Number(credits) <= 0) {
			NotificationManager.error('Credits should be greater than 0');
			return;
		}

		if (props.selectedCompanyByAdmin) {
			dispatch({
				type: ASSIGN_CREDITS_TO_COMPANY,
				values: {
					company_id: props.selectedCompanyByAdmin,
					credit_count: +credits,
				},
				closeModal: props.close,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: ASSIGN_CREDITS,
				id: props.selectedTeamMember?._id,
				value: credits,
				updateState: props.setRows,
				setIsLoading: setIsLoading,
				close: setShow,
			});
		}
	};

	useEffect(() => {
		if (show) {
			props.close();
		}
	}, [show]);

	return (
		<div>
			<div className='mt-4'>
				<div>
					<h2 class='block text-xs text-start font-semibold text-gray-800'>
						Credits:
					</h2>
					<input
						type='text'
						value={credits}
						onChange={(e) => setCredits(e.target.value)}
						placeholder='Enter credits to assign'
						required
						className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
					/>
				</div>
			</div>

			<button
				className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
				onClick={handleSubmit}
				disabled={!credits || credits <= 0}
			>
				{isLoading ? (
					<CircularProgress sx={{ color: 'white' }} size={14} />
				) : (
					'Submit'
				)}
			</button>
		</div>
	);
};

export default AssignCredit;
