/** @format */

import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import {
	EDIT_COMPANY_INFO_ADMIN,
	GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN,
} from '../../../../componants/Context/Types';

const EditUser = (props) => {
	const [userdetails, setuserdetails] = useState({
		name: '',
		email: '',
		company_name: '',
		mobile: '',
		is_internal_user: false,
		is_file_enhancer_user: false,
		upload_limit: 0,
	});
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const { dispatch } = useContext(AuthContext);
	useEffect(() => {
		dispatch({
			type: GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN,
			id: props.selectedCompanyByAdmin,
			updateState: setuserdetails,
			setIsLoading: setIsLoading,
		});
	}, []);

	const handleSubmit = () => {
		if (isEdit) {
			const editedValues = {
				name: userdetails.name,
				mobile: userdetails?.mobile?.length
					? userdetails?.mobile?.toString()
					: '',
				company_name: userdetails.company_name,
				is_internal_user: userdetails?.is_internal_user,
				is_file_enhancer_user: userdetails?.is_file_enhancer_user,
				upload_limit: Number(userdetails?.upload_limit),
			};
			if (password) {
				editedValues.password = password;
			}
			dispatch({
				type: EDIT_COMPANY_INFO_ADMIN,
				id: props.selectedCompanyByAdmin,
				values: editedValues,
				updateState: props.setRows,
				setIsLoading: setIsLoading,
				setmodel: props.setmodel,
			});
		}
	};

	return (
		<div className=' d-flex flex-column justify-content-between user-details'>
			{isLoading ? (
				<CircularProgress />
			) : (
				<>
					<div className='user-dash-header p-4 pb-2'>
						<div>
							<div className='user-name'>{userdetails.name}</div>
							<span className='user-mail-color'>
								{userdetails.company_name}
							</span>
							<div>
								<a
									className='user-edit-profile pointer text-primary'
									onClick={() => {
										setIsEdit((prev) => !prev);
									}}
								>
									{isEdit ? 'Cancel' : 'Edit'}
								</a>
							</div>
						</div>
					</div>

					<div className='user-input px-4'>
						<TextField
							required
							disabled={!isEdit}
							id='outlined-basic'
							value={userdetails.name}
							InputLabelProps={{ shrink: true }}
							size='small'
							className='user-input-fild'
							name='UserName'
							label='UserName'
							variant='outlined'
							onChange={(e) => {
								setuserdetails((prev) => {
									return { ...prev, name: e.target.value };
								});
							}}
						/>
						<TextField
							required
							disabled
							id='outlined-basic'
							InputLabelProps={{ shrink: true }}
							value={userdetails.email}
							size='small'
							className='user-input-fild'
							name='Email'
							label='Email'
							variant='outlined'
						/>
						<TextField
							disabled={!isEdit}
							id='outlined-basic'
							value={userdetails.company_name}
							InputLabelProps={{ shrink: true }}
							size='small'
							className='user-input-fild'
							name='CompanyName'
							label='Company Name'
							variant='outlined'
							onChange={(e) => {
								setuserdetails((prev) => {
									return { ...prev, company_name: e.target.value };
								});
							}}
						/>
						<TextField
							required
							disabled={!isEdit}
							id='outlined-basic'
							value={userdetails.mobile}
							InputLabelProps={{ shrink: true }}
							size='small'
							className='user-input-fild'
							name='Mobile'
							label='Mobile'
							variant='outlined'
							onChange={(e) => {
								setuserdetails((prev) => {
									return { ...prev, mobile: e.target.value };
								});
							}}
						/>
						<TextField
							disabled={!isEdit}
							id='outlined-basic'
							value={password}
							size='small'
							className='user-input-fild'
							InputLabelProps={{ shrink: true }}
							name='New Password'
							label='New Password'
							variant='outlined'
							onChange={(e) => {
								setPassword(e.target.value);
							}}
						/>
					</div>

					<div className='px-4 my-4 d-flex align-items-center'>
						<span className='user-mail-color me-4 '>Internal User</span>
						<label class='editUser_switch'>
							<input
								type='checkbox'
								disabled={!isEdit}
								checked={userdetails?.is_internal_user}
								onChange={(e) => {
									setuserdetails((prev) => {
										return { ...prev, is_internal_user: e.target.checked };
									});
								}}
							/>
							<span class='editUser_slider editUser_round'></span>
						</label>
					</div>

					<div className='px-4'>
						{userdetails?.is_internal_user && (
							<TextField
								disabled={!isEdit}
								id='outlined-basic'
								value={userdetails?.upload_limit}
								size='small'
								className='user-input-fild'
								InputLabelProps={{ shrink: true }}
								name='File Verification Upload Limit'
								label='File Verification Upload Limit'
								variant='outlined'
								onChange={(e) => {
									setuserdetails((prev) => {
										return { ...prev, upload_limit: e.target.value };
									});
								}}
							/>
						)}
					</div>

					<div className='px-4 my-4 d-flex align-items-center'>
						<span className='user-mail-color me-4 '>Email Finder User</span>
						<label class='editUser_switch'>
							<input
								type='checkbox'
								disabled={!isEdit}
								checked={userdetails?.is_file_enhancer_user}
								onChange={(e) => {
									setuserdetails((prev) => {
										return { ...prev, is_file_enhancer_user: e.target.checked };
									});
								}}
							/>
							<span class='editUser_slider editUser_round'></span>
						</label>
					</div>

					<div className='user-dash-footer my-3 px-4'>
						<button className='user-footer-btn' onClick={handleSubmit}>
							Save
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default EditUser;
