import React, { useContext, useEffect, useMemo, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { CircularProgress, Pagination, Stack } from "@mui/material";
// import { AuthContext } from "../../../../componants/Context/AuthContext";
import moment from "moment";
import { GET_UNPAID_INVOICE_NOTIFICATION } from "../../../componants/Context/Types";
import { AuthContext } from "../../../componants/Context/AuthContext";

const ActivityItem = ({ row }) => {
  return (
    <>
      <ListItem alignItems="flex-start" className="Activity-List">
        <>
          <ListItemAvatar className="Activity-avtar">
            <Avatar alt={row?.person}>{row?.person?.[0]}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={row?.heading}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {row?.person} &nbsp;
                </Typography>
                {row?.message}
              </React.Fragment>
            }
          />
        </>
        <Typography
          sx={{ display: "inline" }}
          component="span"
          variant="subtitle2"
          color="text.primary"
        >
          {moment(row.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        </Typography>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

const sampleData=[{"heading":"Subscription","person":"You","message":"Bought Silver Package subscription using Stripe.","createdAt":"2022-08-30T12:54:35.880Z"},{"heading":"Subscription will expire soon..","person":"You","message":"Your plan for Common All will expire in 0 days and 11 hours !","createdAt":"2022-08-26T12:58:21.163Z"},{"heading":"Subscription will expire soon..","person":"You","message":"Your plan for Common All will expire in 0 days and 11 hours !","createdAt":"2022-08-26T12:57:15.665Z"},{"heading":"Subscription will expire soon..","person":"You","message":"Your plan for Common All will expire in 0 days and 11 hours !","createdAt":"2022-08-26T12:56:15.271Z"},{"heading":"Subscription will expire soon..","person":"You","message":"Your plan for Common All will expire in 0 days and 11 hours !","createdAt":"2022-08-26T12:55:51.379Z"}];

const Notifications = (props) => {
  const [rows, setRows] = useState(sampleData);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const limit = 5;
  const handleChange = (event, value) => {
    // setPage(value);
    // if (props.selectedCompanyByAdmin) {
    //   dispatch({
    //     type: GET_COMPANY_ACTIVITY_LOGS_ADMIN,
    //     searchParams: {
    //       id: props.selectedCompanyByAdmin,
    //       page: page,
    //       limit: 5,
    //     },
    //     updateState: { setRows, setCount },
    //     setIsLoading: setIsLoading,
    //   });
    // } else {
    //   dispatch({
    //     type: GET_ACTIVITY_LOGS,
    //     updateState: { setRows, setCount },
    //     setIsLoading: setIsLoading,
    //     page: page + 1,
    //     limit: limit,
    //   });
    // }
  };

  useEffect(() => {
    // if (props.selectedCompanyByAdmin) {
    //   dispatch({
    //     type: GET_COMPANY_ACTIVITY_LOGS_ADMIN,
    //     searchParams: {
    //       id: props.selectedCompanyByAdmin,
    //       page: page,
    //       limit: limit,
    //     },
    //     updateState: { setRows, setCount },
    //     setIsLoading: setIsLoading,
    //   });
    // } else {
    //   dispatch({
    //     type: GET_ACTIVITY_LOGS,
    //     updateState: { setRows, setCount },
    //     setIsLoading: setIsLoading,
    //     page: page,
    //     limit: 5,
    //   });
    // }
 
  }, [page]);
  return (
    <div>
      <div>
        <h4 className="p-2 profile-header f-14">Notifications</h4>
      </div>
      <div className="m-2">
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {isLoading ? (
            <CircularProgress />
          ) : rows.length ? (
            <>
              {rows.map((row, i) => {
                return <ActivityItem key={i} row={row} />;
              })}
              <Stack spacing={2} className="mt-3">
                {/* <Typography>Page: {page}</Typography> */}
                <Pagination
                  count={Math.ceil(count / limit)}
                  page={page}
                  onChange={handleChange}
                />
              </Stack>
            </>
          ) : (
            <div>No Notifications Found!</div>
          )}
        </List>
      </div>
    </div>
  );
};

export default Notifications;
