import React, { useContext, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Searchbar from "../comman/Searchbar";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import down from "../../assests/image/down.svg";
import { createResizableColumn } from "../../helper/tableResizer";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableSkeltonLoader from "./TableSkeltonLoader/TableSkeltonLoader";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PopUpModel from "./PopUpModel";
import { Card } from "react-bootstrap";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { AuthContext } from "../Context/AuthContext";
import SouthIcon from "@mui/icons-material/South";
import {
  DOWNLOAD_LEADS,
  FILTER_CONTACTS,
  FILTER_CONTACTS_STATS,
  GET_ALL_CONTACTS,
  UNLOCK_FIELD,
} from "../Context/Types";
import { Button, CircularProgress } from "@mui/material";
import FacebookCircularProgress from "./FacebookCircularProgress";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { NotificationManager } from "react-notifications";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { WidthIncreaseDec } from "./WidthIncreaseDec";
import ReCAPTCHA from "react-google-recaptcha";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100px",
  maxWidth: "140px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const DataTable = (props) => {
  const {
    dispatch,
    filter,
    setFilter,
    setSortField,
    sortField,
    setAllContacts,
    searchTerm,
    selectedLeads,
    setSelectedLeads,
    allContacts,
    setfilteredRows,
    isLoadingDataTable,
    numOfRecordsFound,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortColumn,
    setSortColumn,
    setFirstTimeFilter,
    setFirstTimeApiCall,
    unlockLoading,
  } = useContext(AuthContext);

  let searchValue = "";
  const [downloading, setDownloading] = useState(false);
  const [downloadId, setDownloadId] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const [toggle, setToggle] = useState({
    fieldFilter: "",
    headerName: "",
    field: "",
  });
  let captchaRef;
  const [width, setWidth] = useState({
    width: 0,
    pageX: 0,
    headerName: "",
  });
  const [lock, setLock] = useState([]);
  const [selectall, setselectall] = useState([]);
  const [download, setDownload] = useState();

  const handleChangePage = (event, newPage) => {
    setIsCaptchaVerified(false);
    setCaptchaToken(null);
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleSearchChange = (e) => {
    searchValue = e;
  };

  const handleFilter = (key) => {
    setFilter((prev) => {
      console.log({ ...prev, [key.filterField]: [searchValue] });
      return { ...prev, [key.filterField]: [searchValue] };
    });
    dispatch({
      type: FILTER_CONTACTS,
    });
    setFirstTimeFilter({
      filterCount: 0,
      isFilterCall: false,
    });
    setFirstTimeApiCall(false);
    // dispatch({
    //   type: FILTER_CONTACTS_STATS,
    // });
    setToggle({
      fieldFilter: "",
      headerName: "",
      field: "",
    });
  };

  const selectCheckbox = (data, checked) => {
    if (data === "all") {
      if (checked) {
        setselectall(allContacts.map((d) => d._id));
        setSelectedLeads(allContacts.map((d) => d._id));
      } else {
        setselectall([]);
        setSelectedLeads([]);
      }
      return;
    }
    if (selectall.includes(data)) {
      if (!checked) {
        setselectall(selectall.filter((d) => d !== data));
        setSelectedLeads(selectall.filter((d) => d !== data));
      }
      return;
    }
    setselectall((prev) => [...prev, data]);
    setSelectedLeads((prev) => [...prev, data]); //global state to have selected leads
  };

  const sortRow = (asc, desc, toggle) => {
    let row = [...allContacts];
    if (asc) {
      console.log(toggle.field);
      let fieldName;
      if (toggle.field === "FullName") {
        fieldName = "contact_name_sort";
      }
      if (toggle.field === "Specialty") {
        fieldName = "specialty_sort";
      }
      if (toggle.field === "Hospital_Doctor") {
        fieldName = "hospital_doctor_sort";
      }
      if (toggle.field === "State") {
        fieldName = "state_sort";
      }
      if (toggle.field === "City") {
        fieldName = "city_sort";
      }
      if (toggle.field === "SpecialtyGroup1") {
        fieldName = "specialityGroup_sort";
      }
      if (toggle.field === "Title") {
        fieldName = "title_sort";
      }
      if (toggle.field === "Department") {
        fieldName = "department_sort";
      }
      if (toggle.field === "HospitalType") {
        fieldName = "hospitalType_sort";
      }
      if (toggle.field === "WebAddress") {
        fieldName = "webaddress_sort";
      }
      if (toggle.field === "BedsRange") {
        fieldName = "bedrange_sort";
      }
      if (toggle.field === "Ownership") {
        fieldName = "ownership_sort";
      }
      if (toggle.field === "FirmType") {
        fieldName = "firmType_sort";
      }
      setSortField({
        ...sortField,
        [fieldName]: 1,
      });
      dispatch({
        type: FILTER_CONTACTS,
      });
    }
    if (desc) {
      let fieldName;
      if (toggle.field === "FullName") {
        fieldName = "contact_name_sort";
      }
      if (toggle.field === "Specialty") {
        fieldName = "specialty_sort";
      }
      if (toggle.field === "Hospital_Doctor") {
        fieldName = "hospital_doctor_sort";
      }
      if (toggle.field === "State") {
        fieldName = "state_sort";
      }
      if (toggle.field === "City") {
        fieldName = "city_sort";
      }
      if (toggle.field === "SpecialtyGroup1") {
        fieldName = "specialityGroup_sort";
      }
      if (toggle.field === "Title") {
        fieldName = "title_sort";
      }
      if (toggle.field === "Department") {
        fieldName = "department_sort";
      }
      if (toggle.field === "HospitalType") {
        fieldName = "hospitalType_sort";
      }
      if (toggle.field === "WebAddress") {
        fieldName = "webaddress_sort";
      }
      if (toggle.field === "BedsRange") {
        fieldName = "bedrange_sort";
      }
      if (toggle.field === "Ownership") {
        fieldName = "ownership_sort";
      }
      if (toggle.field === "FirmType") {
        fieldName = "firmType_sort";
      }
      setSortField({
        ...sortField,
        [fieldName]: -1,
      });
      dispatch({
        type: FILTER_CONTACTS,
      });
    }
    setAllContacts(row);
  };

  const getAllContacts = () => {
    dispatch({
      type: FILTER_CONTACTS,
    });
  };

  useEffect(() => {
    if (!!allContacts || allContacts?.length > 0) {
      const table = document.getElementById("resizeMe");
      const cols = table?.querySelectorAll("th");
      if (table && cols) {
        []?.forEach?.call(cols, function (col) {
          const resizer = document?.createElement("div");
          resizer?.classList?.add("resizer");
          resizer.style.height = `${table?.offsetHeight}px`;
          col.appendChild(resizer);
          createResizableColumn(col, resizer);
        });
      }
    }
  }, [allContacts]);

  useEffect(() => {
    // alert(page);
    // if (page > 1) {
    getAllContacts();
    // getAllContactsStats();
    // }
    // return () => {
    //   setfilteredRows("");
    //   setAllContacts("");
    // };
  }, [rowsPerPage, page]);

  const [masterIdLoad, setMasterIdLoad] = useState("");

  const UnlockFilled = (_id, clickedOn) => {
    if(!unlockLoading) {
      setMasterIdLoad(_id);
      dispatch({
        type: UNLOCK_FIELD,
        payload: _id,
        clickedOn: clickedOn,
        setLock: setLock,
      });
    }
  };

  const onChangeCaptcha = (value) => {
    setIsCaptchaVerified(true);
    setCaptchaToken(value);
  };

  console.log(isCaptchaVerified, captchaToken, page);
  return (
    <>
      {isLoadingDataTable ? (
        <div
          className="table-wrapper"
          style={{ height: allContacts?.length > 0 ? "80vh" : "200px" }}
        >
          <TableSkeltonLoader countRow={4} />
        </div>
      ) : (
        <Card
          className="table-wrapper border-none"
          style={{ height: allContacts?.length > 0 ? "79vh" : "200px" }}
        >
          <TableContainer>
            <Table
              id="resizeMe"
              className="resizeMe tableResize"
              sx={{ minWidth: 700 }}
              aria-label="customized table contact_table "
            >
              <TableHead>
                <TableRow sx={{ height: "30px" }}>
                  <StyledTableCell
                    align="left"
                    className="table_header pointer mediaquery-table "
                    style={{
                      padding: "0",
                      alignItems: "center",
                      justifyContent: "left",
                      width: "3%",
                      borderBottom: "1px solid lightgray",
                    }}
                    sx={{
                      ".resizer": {
                        border: "none",
                      },
                    }}
                  >
                    <Checkbox
                      {...label}
                      checked={
                        selectall.length !== 0 &&
                        selectall.length === allContacts.length
                      }
                      onClick={(e) => {
                        selectCheckbox("all", e.target.checked);
                      }}
                      style={{
                        transform: "scale(0.7)",
                      }}
                      sx={{
                        color: "rgb(177, 181, 192);",
                      }}
                    />
                  </StyledTableCell>

                  {props.columns.map((data) => {
                    if (data.field === "_id" || data.hide === true) return null;
                    else
                      return (
                        <StyledTableCell
                          align={
                            data?.headerName === "Actions" ? "center" : "left"
                          }
                          sx={
                            data?.headerName === "Action" && {
                              ".resizer": {
                                border: "none",
                              },
                            }
                          }
                          key={data?.headerName}
                          className={` table-th pointer mediaquery-table 6`}
                        >
                          <div
                            className={`d-flex ${
                              data?.headerName === "Action"
                                ? "align-items-center justify-content-center"
                                : "justify-content-between"
                            } `}
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                fontWeight: "400 !important",
                                color: "#4b4b4b !important",
                              }}
                              onClick={() => {
                                if (
                                  data.headerName !== "Action" &&
                                  data.headerName !== "PhoneNumber" &&
                                  data.headerName !== "EmailAddress"
                                ) {
                                  setToggle({
                                    filterField: data.filterField,
                                    headerName: data.headerName,
                                    field: data.field,
                                  });
                                }
                              }}
                              className="d-flex align-items-center prevent-text f-16"
                            >
                              {data.headerName === "Action" ? (
                                <img src={down} alt="" />
                              ) : (
                                data.headerName
                              )}
                              {data.headerName !== "Action" &&
                                data.headerName !== "PhoneNumber" &&
                                data.headerName !== "EmailAddress" && (
                                  <KeyboardArrowDownIcon
                                    fontSize="small text-gray"
                                    className="ml-2 mr-2"
                                  />
                                )}
                            </div>
                          </div>
                        </StyledTableCell>
                      );
                  })}
                  <PopUpModel
                    open={!(toggle.headerName === "") ? true : false}
                    close={() => {
                      setToggle({
                        fieldFilter: "",
                        headerName: "",
                        field: "",
                      });
                    }}
                    title={`Search In "${toggle.headerName}"`}
                  >
                    <div className="d-flex justify-content-between contact-color mb-3">
                      <h6
                        className="contact-font-size pointer f-14"
                        style={{ color: "#1891eb" }}
                        onClick={() => {
                          sortRow(true, "", toggle);

                          setToggle({
                            fieldFilter: "",
                            headerName: "",
                            field: "",
                          });
                          setSortColumn({
                            field: toggle.field,
                            direction: "0",
                          });
                        }}
                      >
                        Sort A to Z
                      </h6>
                      <h6
                        className="contact-font-size pointer f-14"
                        onClick={() => {
                          sortRow("", true, toggle);
                          setToggle({
                            fieldFilter: "",
                            headerName: "",
                            field: "",
                          });
                          setSortColumn({
                            field: toggle.field,
                            direction: "1",
                          });
                        }}
                      >
                        Sort Z to A
                      </h6>
                    </div>
                    <p>
                      {!(toggle === "") && (
                        <Searchbar handleChange={handleSearchChange} />
                      )}
                    </p>
                    <div>
                      <h6 className="d-flex justify-content-between contact-color m-0 pt-2 align-items-center contact-font-size">
                        {/* Clear Filters */}
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleFilter(toggle);
                          }}
                          className="f-14 btn-height"
                          style={{
                            boxShadow: "none",
                            backgroundColor: "#1891eb",
                          }}
                        >
                          Done
                        </Button>
                      </h6>
                    </div>
                  </PopUpModel>
                </TableRow>
              </TableHead>
              <TableBody>
                {allContacts?.length > 0 ? (
                  allContacts?.map((row) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell
                        align="left"
                        className="table-th mediaquery-table "
                        style={{
                          maxWidth: "20px",
                          padding: "0",
                          borderBottom: "none",
                        }}
                      >
                        <Checkbox
                          {...label}
                          checked={
                            selectall.includes(row._id) ||
                            selectall.includes("all")
                          }
                          onChange={(e) => {
                            selectCheckbox(row._id, e.target.checked);
                          }}
                          style={{
                            transform: "scale(0.7)",
                          }}
                          sx={{
                            color: "rgb(177, 181, 192);",
                          }}
                        />
                      </StyledTableCell>
                      {props.columns.map((key) => {
                        if (key.field === "_id" || key.hide === true)
                          return null;
                        else if (key.field === "Action") {
                          return (
                            <StyledTableCell
                              key={key.field}
                              align="center"
                              style={{
                                width: "6%",
                                padding: "0 10px !important",
                                borderBottom: "none",
                              }}
                              className="table-th mediaquery-table f-14"
                            >
                              <div
                                onClick={() => {
                                  if(!downloading) {
                                    setDownloadId(row?._id);
                                    dispatch({
                                      type: DOWNLOAD_LEADS,
                                      payload: {
                                        leads_ids: [row._id],
                                        verifyAll: false,
                                        downloadName: `Single - ${new Date().toISOString()}`,
                                        isVerify: true
                                      },
                                      close: () => {},
                                      setIsLoading: setDownloading,
                                      setDownload,
                                    });
                                    UnlockFilled(row._id, "email");
                                  }
                                }}
                              >
                                {downloading && downloadId === row?._id ? (
                                  <CircularProgress
                                    size={15}
                                    style={{ color: "#1891eb" }}
                                  />
                                ) : (
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={down}
                                    alt=""
                                  />
                                )}
                              </div>
                            </StyledTableCell>
                          );
                        } else if (key.field === "EmailAddress") {
                          return (
                            <StyledTableCell
                              align="left"
                              key={key.field}
                              className="table-th mediaquery-table"
                              style={{
                                maxWidth: "fit-content",
                                padding: "0 10px !important",
                                borderBottom: "none",
                              }}
                            >
                              <span
                                onClick={() => {
                                  if (
                                    lock.filter((d) => d._id === row._id).length
                                  )
                                    return;
                                  if (!lock.some((d) => d._id === row._id)) {
                                    UnlockFilled(row._id, "email");
                                  }
                                }}
                                className="blue_text table-filedEmail"
                              >
                                <div className="list-threedots f-14">
                                  {lock.filter((d) => d._id === row._id)
                                    .length ? (
                                    !lock.filter((d) => d._id === row._id)[0]
                                      .debounceCode ||
                                    lock.filter((d) => d._id === row._id)[0]
                                      .debounceCode === "1" ||
                                    lock.filter((d) => d._id === row._id)[0]
                                      .debounceCode === "2" ? (
                                      <div className="f-14">
                                        <ContentCopyOutlinedIcon
                                          style={{
                                            transform: "scale(0.5)",
                                          }}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              lock.filter(
                                                (d) => d._id === row._id
                                              )[0].EmailAddress
                                            );
                                            NotificationManager.info("Copied!");
                                          }}
                                        />
                                        {
                                          lock.filter(
                                            (d) => d._id === row._id
                                          )[0].EmailAddress
                                        }
                                      </div>
                                    ) : unlockLoading &&
                                      String(masterIdLoad) ===
                                        String(row?._id) ? (
                                      <CircularProgress
                                        size={15}
                                        style={{ color: "#1891EB" }}
                                      />
                                    ) : (
                                      "Invalid Email"
                                    )
                                  ) : unlockLoading &&
                                    String(masterIdLoad) ===
                                      String(row?._id) ? (
                                    <CircularProgress
                                      size={15}
                                      style={{ color: "#1891EB" }}
                                    />
                                  ) : (
                                    "Verify Email"
                                  )}
                                </div>
                                {/* </HtmlTooltip> */}
                              </span>
                            </StyledTableCell>
                          );
                        } else if (key.field === "PhoneNumber") {
                          return (
                            <StyledTableCell
                              align="left"
                              className="table-th mediaquery-table "
                              key={key.field}
                              style={{
                                maxWidth: "fit-content",
                                padding: "0 10px !important",
                                borderBottom: "none",
                              }}
                            >
                              <span
                                onClick={() => {
                                  if (
                                    lock.filter((d) => d._id === row._id).length
                                  )
                                    return;
                                  if (!lock.some((d) => d._id === row._id)) {
                                    UnlockFilled(row._id, "phone");
                                  }
                                }}
                                className="blue_text table-filedPhone"
                              >
                                {/* <HtmlTooltip
                                  title={
                                    <div>
                                      {lock.filter((d) => d._id === row._id)
                                        .length
                                        ? lock.filter(
                                            (d) => d._id === row._id
                                          )[0].PhoneNumber
                                        : "Verify Phone"}
                                    </div>
                                  }
                                > */}
                                <div
                                  className="list-threedots f-14"
                                  style={{ cursor: "pointer" }}
                                >
                                  {lock.filter((d) => d._id === row._id)
                                    .length ? (
                                    <div>
                                      <ContentCopyOutlinedIcon
                                        style={{
                                          transform: "scale(0.5)",
                                        }}
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            lock.filter(
                                              (d) => d._id === row._id
                                            )[0].PhoneNumber
                                          );
                                          NotificationManager.info("Copied!");
                                        }}
                                      />
                                      {
                                        lock.filter((d) => d._id === row._id)[0]
                                          .PhoneNumber
                                      }
                                    </div>
                                  ) : unlockLoading &&
                                    String(masterIdLoad) ===
                                      String(row?._id) ? (
                                    <CircularProgress
                                      size={15}
                                      style={{ color: "#1891EB" }}
                                    />
                                  ) : (
                                    "Verify Phone"
                                  )}
                                </div>
                                {/* </HtmlTooltip> */}
                              </span>
                            </StyledTableCell>
                          );
                        } else if (
                          !row[key.field] ||
                          row[key.field] === "NULL"
                        ) {
                          return (
                            <StyledTableCell
                              key={key.field}
                              align="left"
                              className="table-th mediaquery-table"
                              style={{
                                padding: "0 10px !important",
                                borderBottom: "none",
                                width: key?.field === "Action" && "6%",
                              }}
                              sx={
                                key?.field === "Action" && {
                                  width: "6%",
                                }
                              }
                            >
                              {""}
                            </StyledTableCell>
                          );
                        } else
                          return (
                            <StyledTableCell
                              key={key.field}
                              align="left"
                              className="table-th mediaquery-table  f-14"
                              style={{
                                padding: "0 10px !important",
                                borderBottom: "none",
                                width: key?.field === "State" && "6%",
                              }}
                            >
                              <div className="d-flex align-items-center  f-14 me-2">
                                <HtmlTooltip
                                  title={
                                    <div className="f-14">{row[key.field]}</div>
                                  }
                                >
                                  <div className="list-threedots f-14">
                                    {row[key.field]}
                                  </div>
                                </HtmlTooltip>
                              </div>
                            </StyledTableCell>
                          );
                      })}
                    </StyledTableRow>
                  ))
                ) : (
                  <div className="no-data">
                    <div className="no-data-text">No data found</div>
                  </div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TableFooter className="d-flex justify-content-center table_pagination">
            {/* <TableRow> */}
            {isCaptchaVerified || Number(page) % 3 !== 0 ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                colSpan={8}
                count={numOfRecordsFound}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                className="f-14 border-0"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : (
              Number(page) % 3 === 0 && (
                <ReCAPTCHA
                  ref={(r) => {
                    if (r) {
                      return (captchaRef = r);
                    }
                  }}
                  sitekey="6LeT9UwhAAAAAHIV4xebP9-8WibYs5iufcHiYGW8"
                  onChange={onChangeCaptcha}
                />
              )
            )}
            {/* </TableRow> */}
          </TableFooter>
        </Card>
      )}
    </>
  );
};
export default DataTable;
