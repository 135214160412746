/** @format */

import { postPotentialCustomer, getPotentialCustomer } from '../APIs';
import moment from 'moment';
import NotificationManager from 'react-notifications/lib/NotificationManager';

export const postPotentialCustomerReducer = async (email) => {
	try {
		const res = await postPotentialCustomer(email);
		console.log(res);
	} catch (e) {
		console.log(e);
	}
};
export const getPotentialCustomerReducer = async (
	updateState,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await getPotentialCustomer();

		updateState.setRows(res.data.result.potentials);
		updateState.setCount(res.data.result.count);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
