/** @format */

import React from 'react';

const DynamicTable = ({ columns, data, renderCell }) => {
	return (
		<div className='flex flex-col mt-3'>
			<div className='overflow-x-auto' style={{ scrollbarWidth: 'none' }}>
				<div className='min-w-full inline-block align-middle'>
					<div className='overflow-hidden'>
						<table className='min-w-full'>
							<thead className='bg-gray-300 sticky top-0 z-1'>
								<tr className='text-xs'>
									{columns.map((col) => (
										<th
											key={col.title}
											scope='col'
											className='py-2 px-4 whitespace-nowrap text-start'
										>
											{col.title}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{data.map((row) => (
									<tr
										key={row.id}
										className='text-xs font-medium border-b border-gray-300 hover:bg-gray-100'
									>
										{columns.map((col) => (
											<td key={col.title} className='text-start px-4 py-2'>
												{renderCell(row, col)}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
						{data.length === 0 && (
							<div className='flex justify-center items-center h-[20vh]'>
								<div>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										className='w-6 h-6 mx-auto text-gray-600'
									>
										<path d='m2 2 20 20' />
										<path d='M8.35 2.69A10 10 0 0 1 21.3 15.65' />
										<path d='M19.08 19.08A10 10 0 1 1 4.92 4.92' />
									</svg>
									<h1 className='text-base font-semibold text-gray-800 mt-2'>
										No Data
									</h1>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DynamicTable;
