/** @format */
import React, { useContext, useEffect, useState } from 'react';
import {
	EXPERIENCE,
	GENDER,
} from '../../pages/ContactSerch/filterCompo/filterTypes';
import { MainContext } from '../../componants/filterContext/FilterContext';
import { AuthContext } from '../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../componants/Context/Types';
import debounce from 'debounce-promise';
import Dropdown from '../Misc/Dropdown';
import FilterBadges from './FilterBadges';
import { CircularProgress } from '@mui/material';
import MultiRangeSlider from '../Misc/MultiRangeSlider';

const ExperienceFilter = () => {
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		dispatch,
		setFilter,
		filter,
		setSortField,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		anyFilterFlag,
	} = useContext(AuthContext);

	const [firstTimeRun, setFirstTimeRun] = useState(false);

	const handleSelect = (field, selectedOption) => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		setGlobalSelectFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});
		setFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.value === selectedOption.value;
					}
				});
				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});

		setSortField({});
	};

	const handleChange = (newValue) => {
		if (newValue[0] === 0 && newValue[1] === 30) {
			setGlobalSelectFilter((prev) => {
				return { ...prev, experience: [] };
			});
			setFilter((prev) => {
				return {
					...prev,
					[globalFilterKeyTofilterKey[EXPERIENCE]]: [],
				};
			});
		} else {
			setGlobalSelectFilter((prev) => {
				return { ...prev, experience: [newValue] };
			});
			setFilter((prev) => {
				return {
					...prev,
					[globalFilterKeyTofilterKey[EXPERIENCE]]: [newValue],
				};
			});
		}
	};

	const genderHandler = (value) => {
		if (value !== 'M' && value !== 'F') {
			setGlobalSelectFilter((prev) => {
				return { ...prev, gender: [] };
			});
			setFilter((prev) => {
				return {
					...prev,
					gender: [],
				};
			});
		} else if (value === 'M') {
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			setGlobalSelectFilter((prev) => {
				return { ...prev, gender: ['M'] };
			});
			setSortField({});
			setFilter((prev) => {
				return {
					...prev,
					gender: ['M'],
				};
			});
		} else {
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			setGlobalSelectFilter((prev) => {
				return { ...prev, gender: ['F'] };
			});
			setSortField({});
			setFilter((prev) => {
				return {
					...prev,
					gender: ['F'],
				};
			});
		}
	};

	useEffect(() => {
		setFirstTimeRun(false);
	}, [filter, globalSelectFilter]);

	return (
		<div>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Experience</h1>
				<div className='my-4 w-full pb-4'>
					<MultiRangeSlider
						min={0}
						max={30}
						currentValue={filter?.experience}
						onChange={({ min, max }) => handleChange([min, max])}
					/>
				</div>
			</div>

			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>

			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Gender</h1>
				<div className='w-full my-2 rounded-lg'>
					<div className='flex flex-wrap gap-x-2 gap-y-2 items-center px-2'>
						<div
							className={`rounded-full ${
								filter?.gender && filter?.gender?.length === 0
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => genderHandler('All')}
								className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'gnradionull'}
									type='radio'
									checked={filter?.gender && filter?.gender?.length === 0}
									defaultChecked
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
								/>
								Both
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.gender &&
								filter?.gender?.length > 0 &&
								filter?.gender[0] === 'M'
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => genderHandler('M')}
								className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'gnradio1'}
									type='radio'
									checked={
										filter?.gender &&
										filter?.gender?.length > 0 &&
										filter?.gender[0] === 'M'
									}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
								/>
								Male
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.gender &&
								filter?.gender?.length > 0 &&
								filter?.gender[0] === 'F'
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => genderHandler('F')}
								className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'gnradio2'}
									type='radio'
									checked={
										filter?.gender &&
										filter?.gender?.length > 0 &&
										filter?.gender[0] === 'F'
									}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
								/>
								Female
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExperienceFilter;
