import React from "react";
import moneybag from "../../assests/image/moneybag.png";
import { Button } from "@mui/material";
import PopUpModel from "./PopUpModel";
import { useNavigate } from "react-router-dom";

const UpgradeNow = (props) => {
  const navigate = useNavigate();
  return (
    <PopUpModel open={props.open} title={props.title} close={props.close}>
      <div>
        <div className="text-center">
          <img src={moneybag} className="Upgrade-img" />
        </div>
        <div className="text-center mt-4">
          <div className="text-design">
            Whoops! Your Subscription is Expired!
          </div>
          {/* <div className='text-Upgrade'>Subscribe for $74/mth and Billed Anually</div> */}
        </div>
        <div className="text-center mt-4">
          <Button
            variant="contained"
            className="text-Upgrade-button"
            onClick={() => {
              navigate("/profile", { state: { title: "Subscription" } });
              props.close();
            }}
          >
            Upgrade Now!
          </Button>
        </div>
      </div>
    </PopUpModel>
  );
};

export default UpgradeNow;
