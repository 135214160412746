/** @format */

import {
	getDownloadLeads,
	getOneDownloadList,
	getDownloadQueue,
} from '../APIs';
import moment from 'moment';
import NotificationManager from 'react-notifications/lib/NotificationManager';

export const getDownloadLeadsReducer = async (
	updateState,
	setIsLoading,
	dataType
) => {
	setIsLoading(true);
	try {
		const res = await getDownloadLeads(dataType);
		const formatedData = res.data.map((item) => {
			return {
				...item,
				createdAt: moment(item.createdAt).format('MMMM Do YYYY'),
			};
		});
		updateState(formatedData);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
export const getOneDownloadListReducer = async (
	id,
	updateState,
	setIsLoading
) => {
	setIsLoading(true);
	try {
		const res = await getOneDownloadList(id);
		const formatedData = res.data.leads.map((item) => {
			return {
				...item,
				Name: item.FirstName + ' ' + item.LastName,
				emailstatus: item?.debounceCode === '1' ? 'valid' : 'catchall',
			};
		});
		updateState(formatedData);
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};

export const getDownloadQueueReducer = async (updateState, setIsLoading) => {
	setIsLoading(true);
	try {
		const res = await getDownloadQueue();
		// const formatedData = res?.data.map((item) => {
		// 	return {
		// 		...item,
		// 		createdAt: moment(item.createdAt).format('MMMM Do YYYY'),
		// 	};
		// });
		updateState(res.data.data);
	} catch (e) {
		NotificationManager.info(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoading(false);
	}
};
