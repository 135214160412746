/** @format */

import React, { useContext, useEffect, useState } from 'react';
import SignIn_img from '../../../assests/image/SignIn.jpg';
import logo from '../../../assests/image/companylogo.png';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { CircularProgress } from '@mui/material';
import {
	POST_POTENTIAL_CUSTOMERS,
	SIGNUP_USER,
} from '../../../componants/Context/Types';
import { getSubscriptionPlan } from '../../../componants/Context/APIs';
import { AuthContext } from '../../../componants/Context/AuthContext';
import _ from 'lodash';

const label = {
	inputProps: { 'aria-label': 'Checkbox demo' },
	name: 'acceptTerms',
};

const SignUpPage = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const emailParam = searchParams.get('email') || '';
	const planId = searchParams.get('planId') || '';
	const planType = searchParams.get('type') || '';
	const location = useLocation();
	const { dispatch } = useContext(AuthContext);
	const [planDetails, setPlanDetails] = useState([]);
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);
	const fpr = searchParams.get('fpr') || '';
	let captchaRef;

	const handleCaptchaChange = () => {
		if (isCaptchaVerified) {
			captchaRef.reset();
			setIsCaptchaVerified(false);
		}
	};

	const input = [
		{
			label: 'Name *',
			name: 'name',
			type: 'text',
		},
		{
			label: 'Email *',
			name: 'email',
			type: 'email',
		},
		{
			label: 'Password *',
			name: 'password',
			type: 'password',
		},
		{
			label: 'Company Name *',
			name: 'companyName',
			type: 'text',
		},
		{
			label: 'Mobile',
			name: 'mobile',
			type: 'text',
		},
		// {
		//   label: "Refferal Code",
		//   name: "refferalCode",
		//   type: "text",
		// },
	];

	const initialValues = {
		name: location?.state?.name || '',
		email: location?.state?.email || emailParam || '',
		password: location?.state?.password || '',
		mobile: location?.state?.mobile || '',
		companyName: location?.state?.companyName || '',
		// refferalCode: "",
		acceptTerms: false,
	};
	const onChangeCaptcha = (value) => {
		setIsCaptchaVerified(true);
		setCaptchaToken(value);
	};

	const validate = Yup.object().shape({
		name: Yup.string().required('This is required'),
		email: Yup.string()
			.email('This is invalid email')
			.required('This is required'),
		// .matches(/^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$/,"Must Be a Official Company Email"),
		password: Yup.string()
			.required('Please Enter your password')
			.test(
				'len',
				'Must have atleast 6 Characters',
				(val) => val?.toString().length >= 6
			),
		mobile: Yup.number().typeError('Please enter valid mobile number'),
		companyName: Yup.string().required('This is required'),
		acceptTerms: Yup.bool().oneOf([true], 'This is required'),
	});

	const handleSubmit = (values, resetForm) => {
		let formatedValues = {
			...values,
			planId: planId,
			isAnnual: planType === 'year' ? true : false,
		};
		const isPlanId =
			!planId && !planId.length > 0 && delete formatedValues['planId'];
		const isPlanType =
			!planType && !planType.length > 0 && delete formatedValues['isAnnual'];
		if (!values.mobile) {
			formatedValues = _.omit(values, 'mobile');
		}
		let withCaptcha = {
			...formatedValues,
			captcha_token: captchaToken,
			confirm_password: formatedValues.password,
			company_name: formatedValues.companyName,
		};

		withCaptcha = _.omit(withCaptcha, 'companyName');
		withCaptcha = _.omit(withCaptcha, 'acceptTerms');

		let data = {
			...withCaptcha,
			fpr: fpr || '',
			mobile: withCaptcha?.mobile ? String(withCaptcha.mobile) : '',
		};

		dispatch({
			type: SIGNUP_USER,
			payload: data,
			resetForm: resetForm,
			setIsLoading: setIsLoading,
		});
	};

	useEffect(() => {
		if (emailParam && emailParam.length > 0) {
			dispatch({
				type: POST_POTENTIAL_CUSTOMERS,
				email: emailParam,
			});
		}
		if (planId && planType) {
			const fetchPlan = async () => {
				const res = await getSubscriptionPlan(planId);
				setPlanDetails(res?.data?.data);
			};
			fetchPlan();
		}
	}, []);

	return (
		<div className='login-page'>
			<div className='img-content'>
				<img src={SignIn_img} className='login-img' />
			</div>
			<div className='sighnUp-content'>
				<img
					src={logo}
					className='logo-img pointer mx-auto'
					onClick={() => navigate('/')}
				/>
				<h2 className='mb-0 text-bold'>Sign Up</h2>
				{planDetails?.title && (
					<div className='d-flex justify-content-center'>
						<h5
							className='mb-0 my-2'
							style={{
								color: '#000',
								border: '1px solid #1976D2',
								padding: '5px 10px',
								borderRadius: '5px',
							}}
						>
							{planDetails?.title &&
								'Plan : ' +
									planDetails?.title +
									` ($${
										planType === 'year'
											? planDetails?.annually_amount
											: planDetails?.monthly_amount
									}) ${planType === 'year' ? 'Yearly' : 'Monthly'}`}
						</h5>
					</div>
				)}
				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={(values, { resetForm }) => {
						handleSubmit(values, resetForm);
						// alert("Form is validated! Submitting the form...");
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						setFieldValue,
						handleBlur,
					}) => (
						<Form>
							<div className='login-input'>
								{input.map((item, index) => {
									return (
										<>
											<TextField
												name={item.name}
												label={item.label}
												variant='outlined'
												type={item.type}
												helperText={
													errors[`${item.name}`] &&
													touched[`${item.name}`] &&
													errors[`${item.name}`]
												}
												error={
													errors[`${item.name}`] &&
													touched[`${item.name}`] &&
													errors[`${item.name}`]
												}
												onChange={(e) => {
													handleChange(e);
													handleCaptchaChange();
												}}
												onBlur={handleBlur}
												value={values[`${item.name}`]}
												// margin="normal"
												className='custom-width mb-3 mt-3'
											/>
											{/* error validated by Yup */}
											{/* <div className="error-container">
                        <div className="error">
                          {errors[`${item.name}`] &&
                            touched[`${item.name}`] &&
                            errors[`${item.name}`]}
                        </div>
                      </div> */}
										</>
									);
								})}
								<div>
									<ReCAPTCHA
										sitekey='6LeT9UwhAAAAAHIV4xebP9-8WibYs5iufcHiYGW8'
										onChange={onChangeCaptcha}
										ref={(r) => {
											if (r) {
												return (captchaRef = r);
											}
										}}
									/>
								</div>
								<div className='mb-1 d-flex align-items-center justify-content-center w-75'>
									<div>
										<Checkbox
											{...label}
											onChange={handleChange}
											className='pl-0'
										/>{' '}
										I agree to the{' '}
										<a
											href='/terms-conditions'
											target='_blank'
											className='login-link'
										>
											Term and conditions
										</a>{' '}
										<div className='error-container'>
											<div className='error'>
												{errors['acceptTerms'] &&
													touched['acceptTerms'] &&
													errors['acceptTerms']}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div>
								<Button
									className='login-btn mb-1'
									type='submit'
									variant='contained'
									disabled={!isCaptchaVerified || isLoading}
								>
									{isLoading ? (
										<CircularProgress sx={{ fontSize: 10, color: 'white' }} />
									) : (
										'Signup'
									)}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
				<div>
					Already Have an Account?{' '}
					<span
						className='login-link'
						onClick={() => {
							navigate('/');
						}}
					>
						Sign in here...
					</span>
				</div>
				<span
					className='login-link f-12 text-start'
					style={{
						textDecoration: 'underline',
						cursor: 'pointer',
					}}
					onClick={() =>
						window.open(
							"https://mail.google.com/mail/?view=cm&fs=1&to=team@healthdbi.com&su=Trouble%20in%20signing%20up%20on%20website&body='I%20am%20unable%20to%20create%20new%20account.'",
							'_blank'
						)
					}
				>
					Trouble in Signing Up?
				</span>
			</div>
		</div>
	);
};
export default SignUpPage;
