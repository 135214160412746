import { NotificationManager } from "react-notifications";
import { getActivityLogs, getActivityLogsMember } from "../APIs";

export const getActivityLogsReducer = async (
  updateState,
  setIsLoading,
  page,
  limit
) => {
  setIsLoading(true);
  try {
    const res = await getActivityLogs(page, limit);
    updateState.setRows(res.data.logs);
    updateState.setCount(res.data.count);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getActivityLogsMemberReducer = async (
  updateState,
  setIsLoading,
  id,
  page,
  limit
) => {
  setIsLoading(true);
  try {
    const res = await getActivityLogsMember(id, page, limit);
    updateState.setRows(res.data.logs);
    updateState.setCount(res.data.count);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
