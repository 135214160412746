import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import useAuth from "../../../../hooks/useAuth";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import {
  EDIT_PROFILE,
  GET_PROFILE,
  LOG_OUT,
} from "../../../../componants/Context/Types";
import { CircularProgress } from "@mui/material";
import Select from "react-select";
import BuyMoreCreditPopUpModel from "../../../../componants/comman/BuyMoreCreditPopUpModel";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

const options = [
  { value: "Department-1", label: "Department-1" },
  { value: "Department-2", label: "Department-2" },
  { value: "Department-3", label: "Department-3" },
];

const UserDetail = (props) => {
  const { auth } = useAuth();
  const { dispatch, currentPlan, typeOfUser, credits, profile } =
    useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userDepartment, setUserDepartment] = useState("");
  const [buyCreditModal, setBuyCreditModal] = useState(false);
  const [compoLoading, setCompoLoading] = useState(false);
  const [info, setInfo] = useState({
    name: "",
    company_name: "",
    mobile: "",
    type: "",
  });
  let validate;
  if (auth.role !== "company") {
    validate = Yup.object().shape({
      name: Yup.string().required("This is required"),
    });
  } else {
    validate = Yup.object().shape({
      name: Yup.string().required("This is required"),
      // mobile: Yup.number()
      //   .typeError("Please enter valid mobile number")
      //   .required("This is required")
      //   .test(
      //     "len",
      //     "Must be 10 digits",
      //     (val) => val?.toString().length === 10
      //   ),
    });
  }

  const [isEdit, setIsEdit] = useState(false);

  // const fetchDetails = () => {
  //     dispatch({
  //       type: GET_PROFILE,
  //       upDateState: setuserDetails,
  //       setCompoLoading: setCompoLoading,
  //       setSelectedOption: auth.role === "user"? setSelectedOption:() => {},
  //     });
  // };
  // useEffect(() => {
  //   fetchDetails();
  // }, []);

  useEffect(() => {
    setInfo({
      name: profile?.name,
      company_name: profile?.company_name,
      mobile: profile?.mobile,
      type: profile?.type,
    });
    if (auth.role === "user") {
      // setSelectedOption({
      //   value: profile?.department,
      //   label: profile?.department,
      // });
      setUserDepartment(profile?.department);
    }
  }, [profile]);

  const handleSubmit = (values) => {
    delete values.type;
    if (auth.role === "admin" || auth.role === "subadmin") {
      delete values.mobile;
      delete values.company_name;
    }
    if (auth.role === "company") {
      values.mobile = String(values.mobile);
    }

    if (auth.role === "user") {
      delete values.mobile;
      delete values.company_name;
      // values.department = selectedOption.value;
      values.department = userDepartment;
    }
    dispatch({
      type: EDIT_PROFILE,
      payload: values,
      setIsLoading: setIsLoading,
      setIsEdit: setIsEdit,
    });
  };

  function handleLogout() {
    dispatch({ type: LOG_OUT, setIsLoading: setIsLogoutLoading });
  }

  if (auth.role === "admin" || auth.role === "subadmin") {
    return (
      <div className=" d-flex flex-column justify-content-between user-details">
        <div className="d-flex justify-content-between flex-row">
          <h4 className="p-2 profile-header f-14">My Profile</h4>
        </div>
        <div>
          <Formik
            initialValues={info}
            enableReinitialize
            validationSchema={validate}
            onSubmit={(values) => {
              if (isEdit) handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
            }) => (
              <Form>
                <div className="p-4 pb-2 pt-2">
                  <div>
                    <div className="user-name">{info.name}</div>
                    <span className="user-mail-color">{info.email}</span>
                    <div>
                      <a
                        className="user-edit-profile pointer text-primary"
                        onClick={() => {
                          setIsEdit((prev) => !prev);
                        }}
                      >
                        {isEdit ? "Cancel" : "Edit profile"}
                      </a>
                    </div>
                  </div>

                  <div className="user-input px-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      disabled={!isEdit}
                      value={values?.name}
                      error={values?.name?.length == 0}
                      helperText={errors?.name}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      className="user-input-fild"
                      name="name"
                      label="UserName"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <TextField
                      id="outlined-basic"
                      value={profile?.email}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      className="user-input-fild"
                      name="email"
                      label="Email"
                      variant="outlined"
                    />
                  </div>

                  <div className="user-dash-footer px-4 ">
                    <button className="user-footer-btn" type="submit">
                      {isLoading ? (
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      className=" user-footer-btn mx-2 bg-danger"
                      type="button"
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      {isLogoutLoading ? (
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      ) : (
                        "Logout"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  } else if (auth.role === "company") {
    return (
      <div className=" d-flex flex-column justify-content-between user-details">
        <div className="d-flex justify-content-between flex-row">
          <h4 className="p-2 profile-header f-14">My Profile</h4>
        </div>
        <div>
          {isEdit ? (
            <Formik
              initialValues={info}
              enableReinitialize
              validationSchema={validate}
              onSubmit={(values) => {
                if (isEdit) handleSubmit(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                handleBlur,
              }) => (
                <Form>
                  <div className="user-input px-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      disabled={!isEdit}
                      value={values?.name}
                      error={values?.name?.length == 0}
                      helperText={errors.name}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      className="user-input-fild"
                      name="name"
                      label="UserName"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <TextField
                      id="outlined-basic"
                      value={profile?.email}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      className="user-input-fild"
                      name="email"
                      label="Email"
                      variant="outlined"
                      placeholder={values?.email}
                      required
                    />
                    <TextField
                      id="outlined-basic"
                      value={values?.company_name}
                      disabled={!isEdit}
                      size="small"
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      className="user-input-fild"
                      name="company_name"
                      label="Company Name"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-basic"
                      value={values?.mobile}
                      disabled={!isEdit}
                      size="small"
                      className="user-input-fild"
                      name="mobile"
                      label="Mobile"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      id="outlined-basic"
                      value={profile?.department}
                      disabled
                      size="small"
                      name="department"
                      label="Department"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  </div>
                  <div className="user-dash-footer px-4 ">
                    <button className="user-footer-btn" type="submit">
                      {isLoading ? (
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      className=" user-footer-btn mx-2 bg-danger"
                      type="button"
                      onClick={() => {
                        setIsEdit(false);
                      }}
                    >
                      {isLogoutLoading ? (
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      ) : (
                        "Cancel"
                      )}
                    </button>
                    {/* {auth.role === "company" ? (
                    <a
                      className="user-edit-profile text-primary pointer"
                      onClick={() => {
                        if (typeOfUser === "FREE") {
                          props.setTitle("subscription");
                        } else {
                          setBuyCreditModal(true);
                        }
                      }}
                    >
                      {typeOfUser === "FREE"
                        ? "Want to upgrade your Membership?"
                        : "Buy more Credits!!"}
                    </a>
                  ) : null} */}
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="row px-3">
              <div className="col-md-6">
                <div className="blue_text user-name d-flex">
                  Welcome Back&nbsp;
                  <div className="user-name text-bold">{info?.name}!</div>
                </div>

                <div
                  style={{ marginTop: "-5px" }}
                  className="user-mail-color px-1"
                >
                  {profile?.email}
                </div>

                <div
                  style={{ color: "gray" }}
                  className="px-1  user-mail-color"
                >
                  Department&nbsp;
                  <span className="blue_text text-bold">
                    {profile?.department?.toUpperCase()}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  onClick={() => setIsEdit(true)}
                  className="edit_profile_btn_profile blue_text text-500"
                >
                  Edit Profile
                  <ModeEditOutlineIcon style={{ fontSize: "14px" }} />
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-between ">
                <div className="profile_card profile_card1_div1 p-3 ">
                  <div className="d-flex">
                    <div className="profile_icon_div profile_card1_icon_div1 text-center pt-2 ">
                      <AttachMoneyIcon
                        fontSize="large"
                        style={{ color: "#00c67d" }}
                      />
                    </div>
                    <div className="heading">
                      <div className="profile_card_label blue_text">
                        Current Subscription
                      </div>
                      <div className="user-name text-bold blue_text">
                        {profile?.plan?.subscription_type}
                      </div>
                    </div>
                  </div>
                  <div className="px-1">
                    <div className="profile_card_label mt-2  text-gray">
                      Subscription Type
                    </div>
                    <div className="blue_text text-bold  ">
                      <small className="text-bold">
                        {profile?.plan?.isAnnual === true
                          ? "ANNUAL"
                          : "MONTHLY"}
                      </small>
                    </div>
                    <div className="profile_card_label mt-2  text-gray">
                      Subscription Cost
                    </div>
                    <div className="blue_text text-bold  ">
                      <small className="text-bold">
                        $ {profile?.plan?.subscription_amount}
                      </small>
                    </div>
                    <div className="profile_card_label mt-2  text-gray">
                      Renewal Date
                    </div>
                    <div className="blue_text text-bold  ">
                      <small className="text-bold">
                        {profile?.plan?.subscription_end_date &&
                          moment(profile?.plan?.subscription_end_date).format(
                            "DD-MMM-YYYY"
                          )}
                      </small>
                    </div>

                    <div
                      className="mt-2 text-bold  profile_card_btn  "
                      style={{ color: "#00c67d", cursor: "pointer" }}
                      onClick={() => props.setTitle("Subscription")}
                    >
                      <small>Upgrade Now</small>{" "}
                      <ArrowRightAltIcon
                        fontSize="small"
                        style={{ color: "#00c67d" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="profile_card profile_card1_div2 p-3 ">
                  <div className="d-flex">
                    <div className="profile_icon_div profile_card1_icon_div2 text-center pt-2 ">
                      <PeopleIcon
                        fontSize="large"
                        style={{ color: "#aeae33" }}
                      />
                    </div>
                    <div className="heading">
                      <div className="profile_card_label blue_text">
                        Licenses
                      </div>
                      <div className="user-name text-bold blue_text">
                        Team Member
                      </div>
                    </div>
                  </div>
                  <div className="px-1">
                    <div className="profile_card_label mt-2  text-gray">
                      Licenses Member
                    </div>
                    <div className="blue_text text-bold  ">
                      {profile?.plan?.max_members}
                    </div>
                    <div className="profile_card_label mt-2  text-gray">
                      Active Licenses
                    </div>
                    <div className="blue_text text-bold  ">
                      {profile?.AssMem}
                    </div>
                    <div className="profile_card_label mt-2  text-gray">
                      Unasigned Licenses
                    </div>
                    <div className="blue_text text-bold  ">
                      {profile?.UnassMem}
                    </div>

                    <div
                      className="mt-2 text-bold  profile_card_btn  "
                      style={{ color: "#aeae33", cursor: "pointer" }}
                      onClick={() => props.setTitle("Team Members")}
                    >
                      <small>Invite Member</small>
                      <ArrowRightAltIcon
                        fontSize="small"
                        style={{ color: "#aeae33" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="profile_card profile_card1_div3 p-3 ">
                  <div className="d-flex">
                    <div className="profile_icon_div profile_card1_icon_div3 text-center pt-2 ">
                      <CardGiftcardIcon
                        fontSize="large"
                        style={{ color: "#4d7cfe" }}
                      />
                    </div>
                    <div className="heading">
                      <div className="profile_card_label blue_text">
                        Based on Subscription
                      </div>
                      <div className="user-name text-bold blue_text">
                        Credits
                      </div>
                    </div>
                  </div>
                  <div className="px-1">
                    <div className="profile_card_label mt-2  text-gray">
                      Total Credits
                    </div>
                    <div className="blue_text text-bold  ">
                      {profile?.totalCredits?.toLocaleString()}
                    </div>
                    <div className="profile_card_label mt-2  text-gray">
                      Consumed Credits
                    </div>
                    <div className="blue_text text-bold  ">
                      {(
                        profile?.totalCredits - profile?.availCredits
                      )?.toLocaleString()}
                    </div>
                    <div className="profile_card_label mt-2  text-gray">
                      Available Credits
                    </div>
                    <div className="blue_text text-bold  ">
                      {profile?.availCredits?.toLocaleString()}
                    </div>

                    {profile?.type === "FREE USER" ? null : (
                      <div
                        onClick={() => setBuyCreditModal(true)}
                        className="mt-2 text-bold  profile_card_btn "
                        style={{ color: "#4d7cfe", cursor: "pointer" }}
                      >
                        <small>Buy More Credits</small>{" "}
                        <ArrowRightAltIcon
                          fontSize="small"
                          style={{ color: "#4d7cfe" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            onClick={handleLogout}
            className="mt-4 text-bold mx-4"
            style={{ color: "rgb(229,83,83)", cursor: "pointer" }}
          >
            <small>Log Out</small>
            <LogoutIcon fontSize="small" style={{ color: "rgb(229,83,83)" }} />
          </div>
        </div>

        <BuyMoreCreditPopUpModel
          close={() => {
            setBuyCreditModal(false);
          }}
          open={buyCreditModal}
          title={"Buy credits"}
        />
      </div>
    );
  } else if (auth.role === "user") {
    return (
      <div className=" d-flex flex-column justify-content-between user-details">
        <div className="d-flex justify-content-between flex-row">
          <h4 className="p-2 profile-header f-14">My Profile</h4>
        </div>
        <div>
          <Formik
            initialValues={info}
            enableReinitialize
            validationSchema={validate}
            onSubmit={(values) => {
              if (isEdit) handleSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
            }) => (
              <Form>
                <div className="p-4 pb-2 pt-2">
                  <div>
                    <div className="user-name">{info.name}</div>
                    <span className="user-mail-color">{info.email}</span>
                    <div>
                      <a
                        className="user-edit-profile pointer text-primary"
                        onClick={() => {
                          setIsEdit((prev) => !prev);
                        }}
                      >
                        {isEdit ? "Cancel" : "Edit profile"}
                      </a>
                    </div>
                  </div>

                  <div className="user-input px-4 mt-3">
                    <TextField
                      id="outlined-basic"
                      disabled={!isEdit}
                      value={values?.name}
                      error={values?.name?.length == 0}
                      helperText={errors?.name}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      className="user-input-fild"
                      name="name"
                      label="UserName"
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    <TextField
                      id="outlined-basic"
                      value={profile?.email}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      className="user-input-fild"
                      name="email"
                      label="Email"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-basic"
                      value={values?.company_name}
                      disabled
                      size="small"
                      onChange={handleChange}
                      InputLabelProps={{ shrink: true }}
                      className="user-input-fild"
                      name="company_name"
                      label="Company Name"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-basic"
                      value={userDepartment}
                      disabled={!isEdit}
                      size="small"
                      onChange={(e) => setUserDepartment(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      className="user-input-fild"
                      name="Department"
                      label="Department"
                      variant="outlined"
                    />
                    {/* <Select
                      onChange={setSelectedOption}
                      options={options}
                      isDisabled={!isEdit}
                      classNamePrefix="select"
                      placeholder="Department"
                      closeMenuOnSelect={true}
                      value={selectedOption}
                    /> */}
                  </div>
                  <div className="user-dash-footer px-4 ">
                    <button className="user-footer-btn" type="submit">
                      {isLoading ? (
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      ) : (
                        "Save"
                      )}
                    </button>
                    <button
                      className=" user-footer-btn mx-2 bg-danger"
                      type="button"
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      {isLogoutLoading ? (
                        <CircularProgress sx={{ color: "white" }} size={20} />
                      ) : (
                        "Logout"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
};
export default UserDetail;
