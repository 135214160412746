/** @format */

import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../../componants/Context/AuthContext';
import { GET_MEMBER_CREDITS_REQUEST } from '../../../componants/Context/Types';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import DynamicTable from '../../../Elements/Misc/DTable';
import TableSkeltonLoader from '../../../componants/comman/TableSkeltonLoader/TableSkeltonLoader';
import RequestCredits from './RequestCredits';

const MemberCreditsRequest = () => {
	const [isModal, setIsModal] = useState(false);
	const { dispatch } = useContext(AuthContext);
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const getRequestLogs = () => {
		dispatch({
			type: GET_MEMBER_CREDITS_REQUEST,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	};

	useEffect(() => {
		getRequestLogs();
	}, []);

	const columns = [
		{ title: 'Credits Requested' },
		{ title: 'Date' },
		{ title: 'Status' },
	];

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'Credits Requested') {
			return row?.credits;
		} else if (col.title === 'Date') {
			return new Date(row.createdAt).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});
		} else if (col.title === 'Status') {
			return row?.status === 'PENDING' ? (
				<div>
					<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-yellow-100 text-yellow-700 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3'
						>
							<path d='M10.1 2.18a9.93 9.93 0 0 1 3.8 0' />
							<path d='M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7' />
							<path d='M21.82 10.1a9.93 9.93 0 0 1 0 3.8' />
							<path d='M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69' />
							<path d='M13.9 21.82a9.94 9.94 0 0 1-3.8 0' />
							<path d='M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7' />
							<path d='M2.18 13.9a9.93 9.93 0 0 1 0-3.8' />
							<path d='M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69' />
							<circle cx='12' cy='12' r='1' />
						</svg>
						Pending
					</span>
				</div>
			) : row.status === 'DECLINED' ? (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-red-100 text-red-700 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3 rotate-45'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='M8 12h8' />
							<path d='M12 8v8' />
						</svg>
						Declined
					</span>
				</div>
			) : (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-emerald-100 text-emerald-700 rounded-full'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-3'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='m9 12 2 2 4-4' />
						</svg>
						Approved
					</span>
				</div>
			);
		}
		return row[col.title];
	};

	return (
		<>
			<div>
				<div className={`flex items-center px-4 pt-3 justify-end`}>
					<button
						className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2 disabled:opacity-60 disabled:pointer-events-none'
						onClick={() => {
							setIsModal(true);
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='size-4'
						>
							<circle cx='12' cy='12' r='10' />
							<path d='M8 12h8' />
							<path d='M12 8v8' />
						</svg>
						Add New Request
					</button>
				</div>
				{isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={3} />
					</div>
				) : (
					<DynamicTable
						columns={columns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)}
				{!isLoading && (
					<PaginationRow
						totalResults={rows?.length}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				)}
			</div>
			<RequestCredits
				open={isModal}
				close={() => {
					setIsModal(false);
				}}
				getRequestLogs={getRequestLogs}
			></RequestCredits>
		</>
	);
};

export default MemberCreditsRequest;
