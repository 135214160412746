/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Select from 'react-select';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CreatableSelect from 'react-select/creatable';
import debounce from 'debounce-promise';
import {
	LOCATION_CITY,
	LOCATION_COUNTRY,
	LOCATION_STATE,
	LOCATION_ZIP_CODE,
	SPECIALITY_SPECIALITY_TYPE,
} from '../filterTypes';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { MainContext } from '../../../../componants/filterContext/FilterContext';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../../../componants/Context/Types';

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function SpecialityType() {
	const [expanded, setExpanded] = React.useState(false);
	const [specialityTypeOptions, setSpecialityTypeOptions] = React.useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		setFilter,
		filter,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		setSortField,
		dispatch,
		anyFilterFlag,
		typeInputValue,
		setTypeInputValue,
		selectedFilter,
	} = useContext(AuthContext);

	const handleChangeAccordian = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
		setIsOpen(newExpanded);
		setSpecialityTypeOptions(
			specialityTypeOptions?.filter((item) => item?.label?.length !== 0)
		);
	};

	const loadOptions = debounce((searchValue, callback) => {
		const getData = async () => {
			const data = await globalFilterKeyToApi[SPECIALITY_SPECIALITY_TYPE](
				searchValue
			);
			const options = data.map((item) => {
				return {
					value: item.name,
					label: item.name,
				};
			});
			setSpecialityTypeOptions(options);
			return options;
		};
		return getData();
	}, 1000);

	const handleChangeMultiSelect = (selectedOption) => {
		const formatedOption = selectedOption.map((item) => item.value);
		setGlobalSelectFilter((prev) => {
			return { ...prev, [expanded]: selectedOption };
		});
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setSortField({});
		setFilter((prev) => {
			return {
				...prev,
				[globalFilterKeyTofilterKey[expanded]]: formatedOption,
			};
		});
	};

	const keyDownHandler = (e) => {
		console.log(e);
		if (e.code === 'Enter') {
			setFirstTimeApiCall(false);
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			anyFilterFlag.current = true;
			dispatch({
				type: FILTER_CONTACTS,
			});
		}
	};

	useEffect(() => {
		loadOptions('');
	}, []);

	useEffect(() => {
		console.log(selectedFilter);
		if (selectedFilter === 'Speciality Type') {
			setIsOpen(true);
			setExpanded(SPECIALITY_SPECIALITY_TYPE);
		}
	}, [selectedFilter]);

	return (
		<div>
			<Accordion
				expanded={expanded === SPECIALITY_SPECIALITY_TYPE}
				onChange={handleChangeAccordian(SPECIALITY_SPECIALITY_TYPE)}
			>
				<AccordionSummary>
					<Typography className='f-16'>HCP Type</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Select
						isMulti
						createOptionPosition='first'
						onMenuOpen={() => setIsOpen(true)}
						onMenuClose={() => setIsOpen(false)}
						menuIsOpen={isOpen}
						isClearable={false}
						closeMenuOnSelect={false}
						isSearchable={true}
						styles={{
							option: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
							placeholder: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
						}}
						value={globalSelectFilter[SPECIALITY_SPECIALITY_TYPE]}
						// loadOptions={loadOptions}
						defaultOptions={specialityTypeOptions}
						options={specialityTypeOptions}
						onChange={handleChangeMultiSelect}
						onKeyDown={keyDownHandler}
						onInputChange={(value, action) => {
							if (action.action === 'input-change') {
								loadOptions(value);
							}
						}}
						// options={specialityTypeOptions}
						// onInputChange={(value, action) => {
						//   if (action.action === "input-change") setTypeInputValue(value);
						// }}
						// inputValue={typeInputValue}
					/>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
