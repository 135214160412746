import React, { useEffect, useState } from 'react';
import "./InternalInside.css";
import Select from "react-select";
import { Button, CircularProgress } from '@mui/material';
import { addInternalFileVerificationFile } from '../../Context/APIs';
import { NotificationManager } from 'react-notifications';
import useAuth from '../../../hooks/useAuth';

const InsideFileVerification = ({ tableData, tableColumn, fileName, setScreenOpen, limit }) => {
  const {auth} = useAuth();
  const [tableOptions, setTableOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const [colArray, setColArray] = useState([])
  const [colArray2, setColArray2] = useState([])
  const [colArray3, setColArray3] = useState([])

  const [colArray4, setColArray4] = useState([])
  const [colArray5, setColArray5] = useState([])
  const [colArray6, setColArray6] = useState([])
  const [colArray7, setColArray7] = useState([])
  const [colArray8, setColArray8] = useState([])
  const [colArray9, setColArray9] = useState([])
  const [colArray10, setColArray10] = useState([])
  const [colArray11, setColArray11] = useState([])
  const [colArray12, setColArray12] = useState([])
  const [colArray13, setColArray13] = useState([])
  const [colArray14, setColArray14] = useState([])
  const [colArray15, setColArray15] = useState([])
  const [colArray16, setColArray16] = useState([])
  const [colArray17, setColArray17] = useState([])
  const [colArray18, setColArray18] = useState([])
  const [colArray19, setColArray19] = useState([])
  const [colArray20, setColArray20] = useState([])
  const [colArray21, setColArray21] = useState([])
  const [colArray22, setColArray22] = useState([])
  const [colArray23, setColArray23] = useState([])
  const [colArray24, setColArray24] = useState([])
  const [colArray25, setColArray25] = useState([])
  const [colArray26, setColArray26] = useState([])
  const [colArray27, setColArray27] = useState([])
  const [colArray28, setColArray28] = useState([])
  const [colArray29, setColArray29] = useState([])
  const [colArray30, setColArray30] = useState([])
  const [colArray31, setColArray31] = useState([])


  const [array, setArray] = useState([])
  const [array2, setArray2] = useState([])
  const [array3, setArray3] = useState([])

  const [array4, setArray4] = useState([])
  const [array5, setArray5] = useState([])
  const [array6, setArray6] = useState([])
  const [array7, setArray7] = useState([])
  const [array8, setArray8] = useState([])
  const [array9, setArray9] = useState([])
  const [array10, setArray10] = useState([])
  const [array11, setArray11] = useState([])
  const [array12, setArray12] = useState([])
  const [array13, setArray13] = useState([])
  const [array14, setArray14] = useState([])
  const [array15, setArray15] = useState([])
  const [array16, setArray16] = useState([])
  const [array17, setArray17] = useState([])
  const [array18, setArray18] = useState([])
  const [array19, setArray19] = useState([])
  const [array20, setArray20] = useState([])
  const [array21, setArray21] = useState([])
  const [array22, setArray22] = useState([])
  const [array23, setArray23] = useState([])
  const [array24, setArray24] = useState([])
  const [array25, setArray25] = useState([])
  const [array26, setArray26] = useState([])
  const [array27, setArray27] = useState([])
  const [array28, setArray28] = useState([])
  const [array29, setArray29] = useState([])
  const [array30, setArray30] = useState([])
  const [array31, setArray31] = useState([])


  const changeEmailHandler = (option, text) => {
    const headerF = option.value;
    if (text === "fullname") {
      setColArray(option)
      const outputArray = tableData.map(item => {
        return { fullname: item[headerF] || null };
      });
      setArray(outputArray);
    }
    if (text === "title") {
      setColArray2(option)
      const outputArray = tableData.map(item => {
        return { title: item[headerF] || null };
      });
      setArray2(outputArray);
    }
    if (text === "email") {
      setColArray3(option)
      const outputArray = tableData.map(item => {
        // if (!emailRegex.test(item[headerF])) {
        //   setIsError(true);
        //   return;
        // }
        setIsError(false);
        return { email: item[headerF] || null };
      });
      setArray3(outputArray);
    }

    if (text === "fname") {
      setColArray4(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { firstname: item[headerF] || null };
      });
      setArray4(outputArray);
    }
    if (text === "lname") {
      setColArray5(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { lastname: item[headerF] || null };
      });
      setArray5(outputArray);
    }
    if (text === "company") {
      setColArray6(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { companyname: item[headerF] || null };
      });
      setArray6(outputArray);
    }
    if (text === "seniority") {
      setColArray7(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { level: item[headerF] || null };
      });
      setArray7(outputArray);
    }
    if (text === "departments") {
      setColArray8(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { department: item[headerF] || null };
      });
      setArray8(outputArray);
    }
    if (text === "fphone") {
      setColArray9(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { contactphone: item[headerF] || null };
      });
      setArray9(outputArray);
    }
    if (text === "mphone") {
      setColArray10(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { mobilephone: item[headerF] || null };
      });
      setArray10(outputArray);
    }
    if (text === "cphone") {
      setColArray11(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { companyphone: item[headerF] || null };
      });
      setArray11(outputArray);
    }
    if (text === "wdphone") {
      setColArray12(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { directdail: item[headerF] || null };
      });
      setArray12(outputArray);
    }
    if (text === "employees") {
      setColArray13(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { employee: item[headerF] || null };
      });
      setArray13(outputArray);
    }
    if (text === "industry") {
      setColArray14(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { industry: item[headerF] || null };
      });
      setArray14(outputArray);
    }
    if (text === "keywords") {
      setColArray15(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { keywords: item[headerF] || null };
      });
      setArray15(outputArray);
    }
    if (text === "website") {
      setColArray16(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { webaddress: item[headerF] || null };
      });
      setArray16(outputArray);
    }
    if (text === "plinkedin") {
      setColArray17(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { people_linkedin_url: item[headerF] || null };
      });
      setArray17(outputArray);
    }
    if (text === "clinkedin") {
      setColArray18(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { company_linkedin_url: item[headerF] || null };
      });
      setArray18(outputArray);
    }
    if (text === "furl") {
      setColArray19(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { facebook_url: item[headerF] || null };
      });
      setArray19(outputArray);
    }
    if (text === "turl") {
      setColArray20(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { twitter_url: item[headerF] || null };
      });
      setArray20(outputArray);
    }
    if (text === "address") {
      setColArray21(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { address: item[headerF] || null };
      });
      setArray21(outputArray);
    }
    if (text === "city") {
      setColArray22(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { city: item[headerF] || null };
      });
      setArray22(outputArray);
    }
    if (text === "state") {
      setColArray23(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { state: item[headerF] || null };
      });
      setArray23(outputArray);
    }
    if (text === "country") {
      setColArray24(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { country: item[headerF] || null };
      });
      setArray24(outputArray);
    }
    if (text === "caddress") {
      setColArray25(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { company_address: item[headerF] || null };
      });
      setArray25(outputArray);
    }
    if (text === "ccity") {
      setColArray26(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { company_city: item[headerF] || null };
      });
      setArray26(outputArray);
    }
    if (text === "cstate") {
      setColArray27(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { company_state: item[headerF] || null };
      });
      setArray27(outputArray);
    }
    if (text === "ccountry") {
      setColArray28(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { company_country: item[headerF] || null };
      });
      setArray28(outputArray);
    }
    if (text === "technologies") {
      setColArray29(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { technologies: item[headerF] || null };
      });
      setArray29(outputArray);
    }
    if (text === "revenue") {
      setColArray30(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { revenue: item[headerF] || null };
      });
      setArray30(outputArray);
    }
    if (text === "seo") {
      setColArray31(option)
      const outputArray = tableData.map(item => {
        setIsError(false);
        return { seodescription: item[headerF] || null };
      });
      setArray31(outputArray);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (auth.role !== "admin" && limit < tableData?.length) {
      NotificationManager.info(`Upload limit exceeded - Please Upload below ${String(limit)} contacts`)
    } else {
      setLoader(true);
      const mergedArray = [];
      for (let i = 0; i < array.length; i++) {
        const mergedObject = { 
          ...array[i], 
          ...array2[i], 
          ...array3[i], 
          ...array4[i], 
          ...array5[i], 
          ...array6[i], 
          ...array7[i],
          ...array8[i],
          ...array9[i],
          ...array10[i],
          ...array11[i],
          ...array12[i],
          ...array13[i],
          ...array14[i],
          ...array15[i],
          ...array16[i],
          ...array17[i],
          ...array18[i],
          ...array19[i],
          ...array20[i],
          ...array21[i],
          ...array22[i],
          ...array23[i],
          ...array24[i],
          ...array25[i],
          ...array26[i],
          ...array27[i],
          ...array28[i],
          ...array29[i],
          ...array30[i],
          ...array31[i]
        };
        mergedArray.push(mergedObject);
      }
      try {
        const res = await addInternalFileVerificationFile({
          filename: String(fileName).split(".")[0],
          dataArray: mergedArray
        });
        if(res) {
          NotificationManager.info(res?.data?.msg || "Uploaded Successfully");
          setScreenOpen(null);
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        
      }
    }
  }


  useEffect(() => {
    tableColumn?.forEach((item) => {
      setTableOptions((prev) => [...prev, { value: item?.label, label: item?.label }]);
    });
    const searchString = /FullName|Full|fullname|full/;
    const searchString2 = /Title|title/;
    const searchString3 = /Email|email/;

    const searchString4 = /First Name|first name|First|first|name/;
    const searchString5 = /Last Name|last name|Last|last|name/;
    const searchString6 = /Company|company/;
    const searchString7 = /Seniority|seniority/;
    const searchString8 = /Departments|departments|Department|department/;
    const searchString9 = /First Phone|first phone/;
    const searchString10 = /Mobile Phone|mobile phone/;
    const searchString11 = /Corporate Phone|corporate phone|Corporate phone|corporate Phone/;
    const searchString12 = /Work Direct Phone|work direct phone|Work Phone|work phone/;
    const searchString13 = /# Employees|# employees|Employees|employees|Employee|# Employee/;
    const searchString14 = /Industry|industry/;
    const searchString15 = /Keywords|keywords|Keyword|keyword/;
    const searchString16 = /Website|website|Web Address|web address/;
    const searchString17 = /Person Linkedin Url|person linkedin url|Person Linkedin|person linkedin|Person LinkedIn Url|Person Linkedin URL|linkedin_url/;
    const searchString18 = /Company Linkedin Url|company linkedin url|Company Linkedin|company linkedin|Company LinkedIn Url|Company Linkedin URL|linkedin_url/;
    const searchString19 = /Facebook Url|facebook url|Facebook URL|Facebook|facebook|facebook_url/;
    const searchString20 = /Twitter Url|twitter url|Twitter URL|Twitter|twitter|twitter_url/;
    const searchString21 = /Address|address|Address1|address1/;
    const searchString22 = /City|city/;
    const searchString23 = /State|state/;
    const searchString24 = /Country|country/;
    const searchString25 = /Company Address|company address/;
    const searchString26 = /Company City|company city/;
    const searchString27 = /Company State|company state/;
    const searchString28 = /Company Country|company country/;
    const searchString29 = /Technologies|technologies|Technology|technology/;
    const searchString30 = /Annual Revenue|annual revenue|Revenue|revenue/;
    const searchString31 = /SEO Description|seo description|SEO description|seo Description|SEO/;
    const filteredArray = tableColumn.filter(item => searchString.test(item?.label));
    const filteredArray2 = tableColumn.filter(item => searchString2.test(item?.label));
    const filteredArray3 = tableColumn.filter(item => searchString3.test(item?.label));

    const filteredArray4 = tableColumn.filter(item => searchString4.test(item?.label));
    const filteredArray5 = tableColumn.filter(item => searchString5.test(item?.label));
    const filteredArray6 = tableColumn.filter(item => searchString6.test(item?.label));
    const filteredArray7 = tableColumn.filter(item => searchString7.test(item?.label));
    const filteredArray8 = tableColumn.filter(item => searchString8.test(item?.label));
    const filteredArray9 = tableColumn.filter(item => searchString9.test(item?.label));
    const filteredArray10 = tableColumn.filter(item => searchString10.test(item?.label));
    const filteredArray11 = tableColumn.filter(item => searchString11.test(item?.label));
    const filteredArray12 = tableColumn.filter(item => searchString12.test(item?.label));
    const filteredArray13 = tableColumn.filter(item => searchString13.test(item?.label));
    const filteredArray14 = tableColumn.filter(item => searchString14.test(item?.label));
    const filteredArray15 = tableColumn.filter(item => searchString15.test(item?.label));
    const filteredArray16 = tableColumn.filter(item => searchString16.test(item?.label));
    const filteredArray17 = tableColumn.filter(item => searchString17.test(item?.label));
    const filteredArray18 = tableColumn.filter(item => searchString18.test(item?.label));
    const filteredArray19 = tableColumn.filter(item => searchString19.test(item?.label));
    const filteredArray20 = tableColumn.filter(item => searchString20.test(item?.label));
    const filteredArray21 = tableColumn.filter(item => searchString21.test(item?.label));
    const filteredArray22 = tableColumn.filter(item => searchString22.test(item?.label));
    const filteredArray23 = tableColumn.filter(item => searchString23.test(item?.label));
    const filteredArray24 = tableColumn.filter(item => searchString24.test(item?.label));
    const filteredArray25 = tableColumn.filter(item => searchString25.test(item?.label));
    const filteredArray26 = tableColumn.filter(item => searchString26.test(item?.label));
    const filteredArray27 = tableColumn.filter(item => searchString27.test(item?.label));
    const filteredArray28 = tableColumn.filter(item => searchString28.test(item?.label));
    const filteredArray29 = tableColumn.filter(item => searchString29.test(item?.label));
    const filteredArray30 = tableColumn.filter(item => searchString30.test(item?.label));
    const filteredArray31 = tableColumn.filter(item => searchString31.test(item?.label));
    setColArray(filteredArray)
    setColArray2(filteredArray2)
    setColArray3(filteredArray3)

    setColArray4(filteredArray4)
    setColArray5(filteredArray5)
    setColArray6(filteredArray6)
    setColArray7(filteredArray7)
    setColArray8(filteredArray8)
    setColArray9(filteredArray9)
    setColArray10(filteredArray10)
    setColArray11(filteredArray11)
    setColArray12(filteredArray12)
    setColArray13(filteredArray13)
    setColArray14(filteredArray14)
    setColArray15(filteredArray15)
    setColArray16(filteredArray16)
    setColArray17(filteredArray17)
    setColArray18(filteredArray18)
    setColArray19(filteredArray19)
    setColArray20(filteredArray20)
    setColArray21(filteredArray21)
    setColArray22(filteredArray22)
    setColArray23(filteredArray23)
    setColArray24(filteredArray24)
    setColArray25(filteredArray25)
    setColArray26(filteredArray26)
    setColArray27(filteredArray27)
    setColArray28(filteredArray28)
    setColArray29(filteredArray29)
    setColArray30(filteredArray30)
    setColArray31(filteredArray31)
    const outputArray = tableData.map(item => {
      return { fullname: item[filteredArray[0]?.label] };
    });
    setArray(outputArray);

    const outputArray2 = tableData.map(item => {
      return { title: item[filteredArray2[0]?.label]};
    });
    setArray2(outputArray2);

    const outputArray3 = tableData.map(item => {
      // if (!emailRegex.test(item?.EmailAddress)) {
      //   setIsError(true);
      //   return;
      // }
      // setIsError(false);
      return { email: item?.[filteredArray3[0]?.label] };
    });
    setArray3(outputArray3);


    const outputArray4 = tableData.map(item => {
      return { firstname: item[filteredArray4[0]?.label] || null};
    });
    setArray4(outputArray4);
    const outputArray5 = tableData.map(item => {
      return { lastname: item[filteredArray5[0]?.label] || null};
    });
    setArray5(outputArray5);
    const outputArray6 = tableData.map(item => {
      return { companyname: item[filteredArray6[0]?.label] || null};
    });
    setArray6(outputArray6);
    const outputArray7 = tableData.map(item => {
      return { level: item[filteredArray7[0]?.label] || null};
    });
    setArray7(outputArray7);
    const outputArray8 = tableData.map(item => {
      return { department: item[filteredArray8[0]?.label] || null};
    });
    setArray8(outputArray8);
    const outputArray9 = tableData.map(item => {
      return { contactphone: item[filteredArray9[0]?.label] || null};
    });
    setArray9(outputArray9);
    const outputArray10 = tableData.map(item => {
      return { mobilephone: item[filteredArray10[0]?.label] || null};
    });
    setArray10(outputArray10);
    const outputArray11 = tableData.map(item => {
      return { companyphone: item[filteredArray11[0]?.label] || null};
    });
    setArray11(outputArray11);
    const outputArray12 = tableData.map(item => {
      return { directdail: item[filteredArray12[0]?.label] || null};
    });
    setArray12(outputArray12);
    const outputArray13 = tableData.map(item => {
      return { employee: item[filteredArray13[0]?.label] || null};
    });
    setArray13(outputArray13);
    const outputArray14 = tableData.map(item => {
      return { industry: item[filteredArray14[0]?.label] || null};
    });
    setArray14(outputArray14);
    const outputArray15 = tableData.map(item => {
      return { keywords: item[filteredArray15[0]?.label] || null};
    });
    setArray15(outputArray15);
    const outputArray16 = tableData.map(item => {
      return { webaddress: item[filteredArray16[0]?.label] || null};
    });
    setArray16(outputArray16);
    const outputArray17 = tableData.map(item => {
      return { people_linkedin_url: item[filteredArray17[0]?.label] || null};
    });
    setArray17(outputArray17);
    const outputArray18 = tableData.map(item => {
      return { company_linkedin_url: item[filteredArray18[0]?.label] || null};
    });
    setArray18(outputArray18);
    const outputArray19 = tableData.map(item => {
      return { facebook_url: item[filteredArray19[0]?.label] || null};
    });
    setArray19(outputArray19);
    const outputArray20 = tableData.map(item => {
      return { twitter_url: item[filteredArray20[0]?.label] || null};
    });
    setArray20(outputArray20);
    const outputArray21 = tableData.map(item => {
      return { address: item[filteredArray21[0]?.label] || null};
    });
    setArray21(outputArray21);
    const outputArray22 = tableData.map(item => {
      return { city: item[filteredArray22[0]?.label] || null};
    });
    setArray22(outputArray22);
    const outputArray23 = tableData.map(item => {
      return { state: item[filteredArray23[0]?.label] || null};
    });
    setArray23(outputArray23);
    const outputArray24 = tableData.map(item => {
      return { country: item[filteredArray24[0]?.label] || null};
    });
    setArray24(outputArray24);
    const outputArray25 = tableData.map(item => {
      return { company_address: item[filteredArray25[0]?.label] || null};
    });
    setArray25(outputArray25);
    const outputArray26 = tableData.map(item => {
      return { company_city: item[filteredArray26[0]?.label] || null};
    });
    setArray26(outputArray26);
    const outputArray27 = tableData.map(item => {
      return { company_state: item[filteredArray27[0]?.label] || null};
    });
    setArray27(outputArray27);
    const outputArray28 = tableData.map(item => {
      return { company_country: item[filteredArray28[0]?.label] || null};
    });
    setArray28(outputArray28);
    const outputArray29 = tableData.map(item => {
      return { technologies: item[filteredArray29[0]?.label] || null};
    });
    setArray29(outputArray29);
    const outputArray30 = tableData.map(item => {
      return { revenue: item[filteredArray30[0]?.label] || null};
    });
    setArray30(outputArray30);
    const outputArray31 = tableData.map(item => {
      return { seodescription: item[filteredArray31[0]?.label] || null};
    });
    setArray31(outputArray31);

  }, [tableColumn, tableData])
  return (
    <div>
      <h6 className="insideHeading">{`${fileName} (${tableOptions?.length} headers & ${tableData?.length} rows)`}</h6>
      <form onSubmit={handleSubmit}>
      <div className='insideHeaderContainer'>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Full Name<span style={{color: "red"}}>*</span></label>
          <Select required value={colArray[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "fullname")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Title<span style={{color: "red"}}>*</span></label>
          <Select required value={colArray2[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "title")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Email<span style={{color: "red"}}>*</span></label>
          <Select required value={colArray3[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "email")} options={tableOptions || []} />
        </div>


        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>First Name</label>
          <Select value={colArray4[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "fname")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Last Name</label>
          <Select value={colArray5[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "lname")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Company</label>
          <Select value={colArray6[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "company")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Seniority</label>
          <Select value={colArray7[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "seniority")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Departments</label>
          <Select value={colArray8[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "departments")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>First Phone</label>
          <Select value={colArray9[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "fphone")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Mobile Phone</label>
          <Select value={colArray10[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "mphone")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Corporate Phone</label>
          <Select value={colArray11[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "cphone")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Work Direct Phone</label>
          <Select value={colArray12[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "wdphone")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'># Employees</label>
          <Select value={colArray13[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "employees")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Industry</label>
          <Select value={colArray14[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "industry")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Keywords</label>
          <Select value={colArray15[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "keywords")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Website</label>
          <Select value={colArray16[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "website")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Person Linkedin Url</label>
          <Select value={colArray17[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "plinkedin")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Company Linkedin Url</label>
          <Select value={colArray18[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "clinkedin")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Facebook Url</label>
          <Select value={colArray19[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "furl")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Twitter Url</label>
          <Select value={colArray20[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "turl")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Address</label>
          <Select value={colArray21[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "address")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>City</label>
          <Select value={colArray22[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "city")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>State</label>
          <Select value={colArray23[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "state")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Country</label>
          <Select value={colArray24[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "country")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Company Address</label>
          <Select value={colArray25[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "caddress")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Company City</label>
          <Select value={colArray26[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "ccity")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Company State</label>
          <Select value={colArray27[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "cstate")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Company Country</label>
          <Select value={colArray28[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "ccountry")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Technologies</label>
          <Select value={colArray29[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "technologies")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>Annual Revenue</label>
          <Select value={colArray30[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "revenue")} options={tableOptions || []} />
        </div>
        <div className='insideHeaderItem'>
          <label htmlFor="" className='insideHeaderLabel'>SEO Description</label>
          <Select value={colArray31[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "seo")} options={tableOptions || []} />
        </div>
      </div>
      <Button
        type='submit'
        variant="contained"
        className="m-2 p-2 mr-0 f-16 mt-3"
        style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
      >
        {
          loader ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Submit"
        }
      </Button>
      </form>
    </div>
  )
}

export default InsideFileVerification