/** @format */

import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	useSearchParams,
} from 'react-router-dom';

import { NProvider } from './Elements/Navbar/NavbarContext';
import Layout from './helper/Layout';
import useAuth from './hooks/useAuth';
import AuthContext from './componants/Context/AuthContext';
import ProtectedRoutes from './componants/Context/ProtectRoutes';

import DashBoard from './pages/ContactSerch/DashBoard/DashBoard';
import FilterContext from './componants/filterContext/FilterContext';

import LoginScreen from './screens/Auth/Login';
import SignUpScreen from './screens/Auth/Singup';
import Verify from './screens/Auth/Verify';
import TermsConditions from './screens/Auth/Terms';
import TeamSignUp from './screens/Auth/TeamSignup';
import ForgotPassword from './screens/Auth/ForgotPassword';
import ResetPassword from './screens/Auth/ResetPassword';
import PlansScreen from './screens/Plans/Plans';
import MyLists from './screens/Lists/MyLists';
import ContactSearch from './screens/ContactSearch/ContactSearch';
import BillingScreen from './screens/Billing/Billing';
import MyTools from './screens/Tools/Tools';
import Profile from './screens/Profile/Profile';
import FailedScreen from './screens/Misc/Failed';
import SuccessScreen from './screens/Misc/Success';
import ScreenSizeAlert from './screens/Misc/ScreenSizeAlert';
import NotFound from './screens/Misc/NotFound';

const UserNavigate = () => {
	const [searchParams] = useSearchParams();
	const planId = searchParams.get('planId') || '';
	const type = searchParams.get('type') || '';
	const token = Cookies.get('token');
	useEffect(() => {
		if (type && planId && token?.length > 20) {
			window.location =
				'/thankyou?planId=' +
				planId +
				`&isAnnual=${type === 'year' ? 'true' : 'false'}` +
				'&token=' +
				token;
		}
	}, []);
	return <></>;
};

const AllRoutes = () => {
	const { auth } = useAuth();
	const [screenSize, setScreenSize] = useState(false);

	useEffect(() => {
		const handleContextmenu = (e) => {
			e.preventDefault();
		};
		document.addEventListener('contextmenu', handleContextmenu);
		return function cleanup() {
			document.removeEventListener('contextmenu', handleContextmenu);
		};
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setScreenSize(window.innerWidth < 1000);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const renderComponent = (DesktopComponent) => {
		return screenSize ? <ScreenSizeAlert /> : <DesktopComponent />;
	};

	return (
		<>
			<BrowserRouter>
				<UserNavigate />
				<FilterContext>
					<NProvider>
						<AuthContext>
							<Routes>
								<Route path='/' element={<Layout />}>
									<Route
										path='/'
										element={
											auth ? <Navigate to='/contactsearch' /> : <LoginScreen />
										}
									/>
									<Route
										path='/signUp'
										element={
											auth ? <Navigate to='/contactsearch' /> : <SignUpScreen />
										}
									/>
									<Route
										path='/email'
										element={auth ? <Navigate to='/profile' /> : <Verify />}
									/>
									<Route
										path='/forgotpassword'
										element={
											auth ? <Navigate to='/profile' /> : <ForgotPassword />
										}
									/>
									<Route
										path='/terms-conditions'
										element={<TermsConditions />}
									/>
									<Route
										path='/resetpassword/:id'
										element={<ResetPassword />}
									/>
									<Route
										path='/teamSignUp'
										element={auth ? <Navigate to='/profile' /> : <TeamSignUp />}
									/>
									<Route path='/thankyou' element={<SuccessScreen />} />
									<Route path='/failed' element={<FailedScreen />} />

									{/* Private Routes */}
									<Route element={<ProtectedRoutes />}>
										<Route path='/dashboard' element={<DashBoard />} />
										<Route
											path='/contactsearch'
											element={renderComponent(ContactSearch)}
										/>
										<Route path='/mylist' element={renderComponent(MyLists)} />
										<Route
											path='/billing'
											element={renderComponent(BillingScreen)}
										/>
										<Route
											path='/plans'
											element={renderComponent(PlansScreen)}
										/>
										<Route path='/tools' element={renderComponent(MyTools)} />
										<Route
											path='/myprofile'
											element={renderComponent(Profile)}
										/>
										<Route path='*' element={<NotFound />} />
									</Route>
								</Route>
							</Routes>
						</AuthContext>
					</NProvider>
				</FilterContext>
			</BrowserRouter>
		</>
	);
};

export default AllRoutes;
