import React, { useContext, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../Context/AuthContext";
import { CHANGE_PASSWORD } from "../Context/Types";
const ChangePassword = () => {
  const { dispatch } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    password: "",
    confirm_password: "",
    old_password: "",
  };

  const validate = Yup.object().shape({
    old_password: Yup.string()
      .required("Please Enter your password")
      .test(
        "len",
        "Must have atleast 6 Characters",
        (val) => val?.toString().length >= 6
      ),
    password: Yup.string()
      .required("Please Enter your password")
      .test(
        "len",
        "Must have atleast 6 Characters",
        (val) => val?.toString().length >= 6
      ),
    confirm_password: Yup.string()
      .required()
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const handleSubmit = (values, resetForm) => {
    dispatch({
      type: CHANGE_PASSWORD,
      payload: values,
      resetForm: resetForm,
      setIsLoading: setIsLoading,
    });
  };
  return (
    <div className="">
      <div>
        <h4 className="pr-3 d-flex justify-content-between">
          <div className="p-2 profile-header f-14">Change Password</div>
        </h4>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
        }) => (
          <Form>
            <div className="d-flex flex-column justify-content-between user-password">
              <TextField
                id="outlined-basic"
                value={values.old_password}
                size="small"
                className="user-input-fild mt-3 w-75 "
                name="old_password"
                label="Current Password"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="error-container">
                <div className="error">
                  {errors["old_password"] &&
                    touched["old_password"] &&
                    errors["old_password"]}
                </div>
              </div>

              <TextField
                id="outlined-basic"
                value={values.password}
                size="small"
                className="user-input-fild mt-3 w-75 "
                name="password"
                label="New Password"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="error-container">
                <div className="error">
                  {errors["password"] &&
                    touched["password"] &&
                    errors["password"]}
                </div>
              </div>

              <TextField
                id="outlined-basic"
                value={values.confirm_password}
                size="small"
                className="user-input-fild mt-3 w-75 "
                name="confirm_password"
                label="Confirm Password "
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="error-container">
                <div className="error">
                  {errors["confirm_password"] &&
                    touched["confirm_password"] &&
                    errors["confirm_password"]}
                </div>
              </div>
              <div>
                <Button variant="contained" className="px-5 mt-5" type="submit">
                  {isLoading ? (
                    <CircularProgress sx={{ fontSize: 10, color: "white" }} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
