/** @format */

import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import { downloadCSV } from 'download-csv';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {
	getCommonEnhancerFileApi,
	finderDownloadFile,
} from '../../componants/Context/APIs';
import FacebookCircularProgress from '../../componants/comman/FacebookCircularProgress';

const CommonEmailFinder = () => {
	const [verData, setVerData] = useState([]);
	const [verLoader, setVerLoader] = useState(false);
	const [totalCount, setTotalCount] = useState([]);
	const [pages, setPages] = useState(0);
	const [page, setPage] = useState(1);

	const getEmailFinder = async () => {
		setVerLoader(true);
		try {
			const res = await getCommonEnhancerFileApi(page);
			setVerData([]);
			let allData = [];
			res?.data?.data[0]?.forEach((item, index) => {
				setVerLoader(true);
				let percent = 100;
				let appended = 0;
				let timeLeft = 0;
				if (item?.party_counts) {
					var party_counts = JSON.parse(item?.party_counts);

					if (party_counts) {
						percent =
							((party_counts.failed +
								party_counts.not_found +
								party_counts.found_valid +
								party_counts.found_unknown) /
								party_counts.total) *
							100;

						appended = party_counts.found_valid + party_counts.found_unknown;

						timeLeft = (100 - percent) * 0.25;
					}
				}

				allData.push({
					...item,
					percent: percent,
					timeLeft: timeLeft,
					appended: appended,
				});
			});

			setVerData(allData);
			setTotalCount(res?.data?.totalCount[0]);
			setPages(res?.data?.pages);
			setVerLoader(false);
		} catch (error) {
			setVerLoader(false);
		}
	};

	const downloadFileHandler = async (name, fileName) => {
		try {
			const res = await finderDownloadFile({
				sys_filename: name,
			});
			const data = res?.data[0] || [];

			const headers = Object.keys(data[0]);
			downloadCSV(data, headers, fileName);
		} catch (error) {}
	};

	const handleChange = (e, value) => {
		setPage(value);
	};

	useEffect(() => {
		setVerData([]);
		getEmailFinder();
	}, [page]);

	return (
		<div className='p-3'>
			{verLoader ? (
				<div className='d-flex justify-content-between flex-row'>
					<div className='mx-auto bg-white w-5/6 min-h-5 p-4'>
						<FacebookCircularProgress />
					</div>
				</div>
			) : (
				<TableContainer style={{ overflowY: 'auto', maxHeight: '60vh' }}>
					<Table sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead style={{ position: 'sticky', top: '0' }}>
							<TableRow className='bg-light'>
								<TableCell className='fw-bold' align='center'>
									File Name
								</TableCell>
								<TableCell className='fw-bold' align='center'>
									Uploaded By
								</TableCell>
								<TableCell align='center' className='fw-bold'>
									Creation Date
								</TableCell>
								<TableCell align='center' className='fw-bold'>
									Count
								</TableCell>
								<TableCell align='center' className='fw-bold'>
									Appended
								</TableCell>
								<TableCell align='center' className='fw-bold'>
									Status
								</TableCell>
								<TableCell align='center' className='fw-bold'>
									Progress
								</TableCell>
								<TableCell align='center' className='fw-bold'>
									Time Left
								</TableCell>
								<TableCell align='center' className='fw-bold'>
									Action
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{verData &&
								verData?.length > 0 &&
								verData.map((row) => {
									return (
										<TableRow
											key={row.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell
												component='th'
												scope='row'
												align='center'
												className='p-2'
											>
												{row.filename}
											</TableCell>
											<TableCell align='center' className='p-2'>
												{row.uploadby}
											</TableCell>
											<TableCell align='center' className='p-2'>
												{new Date(row.created_at).toUTCString()}
											</TableCell>
											<TableCell align='center' className={`p-2`}>
												{row?.total_count}
											</TableCell>
											<TableCell align='center' className={`p-2`}>
												{row?.appended}
											</TableCell>
											<TableCell align='center' className={`p-2`}>
												{row?.progress_status}
											</TableCell>
											<TableCell align='center' className={`p-2`}>
												{row?.percent.toFixed(2)}%
											</TableCell>
											<TableCell align='center' className={`p-2`}>
												{row?.timeLeft.toFixed(2)} min
											</TableCell>

											<TableCell align='center' className='p-2 dropdown-team '>
												{row?.progress_status === 'Completed' && (
													<div
														onClick={() =>
															downloadFileHandler(
																row?.sys_filename,
																row?.filename
															)
														}
														style={{ color: 'blue', cursor: 'pointer' }}
													>
														Download
													</div>
												)}
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
					<Stack spacing={2} className='mt-3'>
						<Pagination count={pages} page={page} onChange={handleChange} />
					</Stack>
				</TableContainer>
			)}
		</div>
	);
};

export default CommonEmailFinder;
