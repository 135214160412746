/** @format */

import './App.scss';
import DocDBI from './componants/DocDBI';
import './sass/index.scss';
//import 'antd/dist/antd.css';
//import '@coreui/coreui/dist/css/coreui.min.css';
import 'react-notifications/lib/notifications.css';
import './tailwindcss/tailwind-all.css';
import { NotificationContainer } from 'react-notifications';
import React from 'react';

function App() {
	return (
		<div className='App'>
			<NotificationContainer />
			<DocDBI />
		</div>
	);
}

export default App;
