/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../componants/Context/AuthContext';
import { Table } from 'antd';
import { GET_POTENTIAL_CUSTOMERS } from '../../../componants/Context/Types';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import DynamicTable from '../../../Elements/Misc/DTable';
import TableSkeltonLoader from '../../../componants/comman/TableSkeltonLoader/TableSkeltonLoader';

const PendingSingups = () => {
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [count, setCount] = useState(0);
	const { dispatch } = useContext(AuthContext);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		dispatch({
			type: GET_POTENTIAL_CUSTOMERS,
			updateState: { setRows, setCount },
			setIsLoading: setIsLoading,
		});
	}, []);

	const columns = [
		{
			title: 'Name',
		},
		{
			title: 'Email',
		},
		{
			title: 'Company Name',
		},
		{
			title: 'Mobile',
		},
		{
			title: 'Creation Date',
		},
	];

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'Email') {
			return row?.email;
		} else if (col.title === 'Name') {
			return row?.name;
		} else if (col.title === 'Company Name') {
			return row?.company_name;
		} else if (col.title === 'Mobile') {
			return row?.mobile;
		} else if (col.title === 'Creation Date') {
			return new Date(row.createdAt).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});
		}
		return row[col.title];
	};

	return (
		<>
			<div>
				{isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={3} />
					</div>
				) : (
					<DynamicTable
						columns={columns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)}

				{!isLoading && (
					<PaginationRow
						totalResults={rows?.length}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				)}
			</div>
		</>
	);
};

export default PendingSingups;
