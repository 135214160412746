import React, { useContext, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { GEOGRAPHIC_LOCATION } from "../filterTypes";
import Select from "react-select";
import { MainContext } from "../../../../componants/filterContext/FilterContext";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { FILTER_CONTACTS } from "../../../../componants/Context/Types";
import CreatableSelect from "react-select/creatable";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function GeographicClassification() {
  const [expanded, setExpanded] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [geoOptions, setGeoOptions] = React.useState([]);

  const {
    globalSelectFilter,
    setGlobalSelectFilter,
    globalFilterKeyToApi,
    globalFilterKeyTofilterKey,
  } = useContext(MainContext);

  const {
    setFilter,
    filter,
    setFirstTimeFilter,
    setFirstTimeApiCall,
    setSortField,
    dispatch,
    anyFilterFlag,
    geoInput,
    setGeoInput,
    selectedFilter,
  } = useContext(AuthContext);

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setIsOpen(newExpanded);
    setGeoOptions(geoOptions?.filter((item) => item?.label?.length !== 0));
  };

  const loadOptions = (searchValue) => {
    const getData = async () => {
      const data = await globalFilterKeyToApi[GEOGRAPHIC_LOCATION](searchValue);
      const options = data.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setGeoOptions(options);
    };
    getData();
  };

  const handleChangeMultiSelect = (selectedOption) => {
    const formatedOption = selectedOption.map((item) => item.value);
    setGlobalSelectFilter((prev) => {
      return { ...prev, [expanded]: selectedOption };
    });
    setFirstTimeFilter({
      filterCount: 0,
      isFilterCall: false,
    });
    setSortField({});
    setFilter((prev) => {
      return {
        ...prev,
        [globalFilterKeyTofilterKey[expanded]]: formatedOption,
      };
    });
  };

  const keyDownHandler = (e) => {
    console.log(e);
    if (e.code === "Enter") {
      setFirstTimeApiCall(false);
      setFirstTimeFilter({
        filterCount: 0,
        isFilterCall: false,
      });
      anyFilterFlag.current = true;
      dispatch({
        type: FILTER_CONTACTS,
      });
    }
  };

  useEffect(() => {
    if (selectedFilter === "Geographic Classification") {
      setIsOpen(true);
      setExpanded(GEOGRAPHIC_LOCATION);
    }
  }, [selectedFilter]);

  useEffect(() => {
    loadOptions("");
  }, []);

  return (
    <div>
      <Accordion
        expanded={expanded === GEOGRAPHIC_LOCATION}
        onChange={handleChangeAccordian(GEOGRAPHIC_LOCATION)}
      >
        <AccordionSummary>
          <Typography className="f-16">Geographic Classification</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Select
            isMulti
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            menuIsOpen={isOpen}
            createOptionPosition="first"
            isClearable
            closeMenuOnSelect={false}
            isSearchable={true}
            styles={{
              option: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
              placeholder: (provided, state) => ({
                ...provided,
                textAlign: "left",
              }),
            }}
            defaultOptions={true}
            value={globalSelectFilter[GEOGRAPHIC_LOCATION]}
            // loadOptions={loadOptions}
            onChange={handleChangeMultiSelect}
            onKeyDown={keyDownHandler}
            options={geoOptions}
            onInputChange={(value, action) => {
              if (action.action === "input-change") setGeoInput(value);
            }}
            // inputValue={geoInput}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
