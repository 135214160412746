/** @format */
import { useEffect, useState } from 'react';
import Navbar from '../../Elements/Navbar/Navbar';
import Downloads from '../../pages/ContactSerch/MyList/MyListCompo/Downloads';
import CSVs from '../../pages/ContactSerch/MyList/MyListCompo/CSVs';
import SaveSearch from '../../pages/ContactSerch/MyList/MyListCompo/SaveSearch';
import { MyListTable } from '../../pages/ContactSerch/MyList/MyListTable';
import { useLocation } from 'react-router-dom';

const MyList = () => {
	const [titlebtn, settitlebtn] = useState('download');
	const query = new URLSearchParams(useLocation().search);
	const active = query.get('active');

	useEffect(() => {
		if (active) {
			settitlebtn(active);
		}
	}, [active]);

	return (
		<div className='relative min-h-screen pb-10'>
			<div className='fixed top-0 z-10 w-full'>
				<Navbar />
			</div>

			<div className='flex justify-center items-center mt-16'>
				<nav
					class='relative z-0 flex border rounded-xl overflow-hidden'
					aria-label='Tabs'
					role='tablist'
				>
					<button
						type='button'
						class={`whitespace-nowrap min-w-36 relative flex-1 bg-white first:border-s-0 border-s border-b-2 !py-3 px-4 text-xs font-medium overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
							titlebtn == 'download'
								? 'border-b-blue-600 text-gray-900'
								: 'text-gray-600 hover:text-gray-700'
						}`}
						onClick={() => settitlebtn('download')}
						id='bar-with-underline-item-1'
						data-hs-tab='#bar-with-underline-1'
						aria-controls='bar-with-underline-1'
						role='tab'
					>
						My Downloads
					</button>
					<button
						type='button'
						class={`whitespace-nowrap min-w-36 relative flex-1 bg-white first:border-s-0 border-s border-b-2 !py-3 px-4 text-xs font-medium overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
							titlebtn == 'csvs'
								? 'border-b-blue-600 text-gray-900'
								: 'text-gray-600 hover:text-gray-700'
						}`}
						id='bar-with-underline-item-2'
						onClick={() => settitlebtn('csvs')}
						data-hs-tab='#bar-with-underline-2'
						aria-controls='bar-with-underline-2'
						role='tab'
					>
						Exclusion Files
					</button>
					<button
						type='button'
						class={`whitespace-nowrap min-w-36 relative flex-1 bg-white first:border-s-0 border-s border-b-2 !py-3 px-4 text-xs font-medium overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
							titlebtn == 'search'
								? 'border-b-blue-600 text-gray-900'
								: 'text-gray-600 hover:text-gray-700'
						}`}
						id='bar-with-underline-item-3'
						data-hs-tab='#bar-with-underline-3'
						aria-controls='bar-with-underline-3'
						role='tab'
						onClick={() => settitlebtn('search')}
					>
						Save Searches
					</button>
					<button
						type='button'
						class={`whitespace-nowrap min-w-36 relative flex-1 bg-white first:border-s-0 border-s border-b-2 !py-3 px-4 text-xs font-medium overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
							titlebtn == 'list'
								? 'border-b-blue-600 text-gray-900'
								: 'text-gray-600 hover:text-gray-700'
						}`}
						id='bar-with-underline-item-4'
						data-hs-tab='#bar-with-underline-4'
						aria-controls='bar-with-underline-4'
						role='tab'
						onClick={() => settitlebtn('list')}
					>
						Lists
					</button>
				</nav>
			</div>

			<div className='mt-6 mx-20 bg-white rounded-lg'>
				{'download' === titlebtn && <Downloads />}
				{'csvs' === titlebtn && <CSVs />}
				{'search' === titlebtn && <SaveSearch />}
				{'list' === titlebtn && <MyListTable />}
			</div>
		</div>
	);
};

export default MyList;
