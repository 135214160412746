import React, { useEffect, useState } from 'react';
import "./InternalInside.css";
import Select from "react-select";
import { Button, CircularProgress } from '@mui/material';
import { addInternalBounceFile } from '../../Context/APIs';
import { NotificationManager } from 'react-notifications';

const InsideBounceRepository = ({ tableData, tableColumn, fileName, setScreenOpen }) => {

  const [tableOptions, setTableOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const [colArray, setColArray] = useState([])
  const [colArray2, setColArray2] = useState([])
  const [array, setArray] = useState([])
  const [array2, setArray2] = useState([])

  const changeEmailHandler = (option, text) => {
    const headerF = option.value;
    if (text === "email") {
      setColArray(option);
      const outputArray = tableData.map(item => {
        if (!emailRegex.test(item[headerF])) {
          setIsError(true);
          return;
        }
        setIsError(false);
        return { email: item[headerF] };
      });
      setArray(outputArray);
    }
    if (text === "ver") {
      setColArray2(option)
      const outputArray = tableData.map(item => {
        return { verification_date: item[headerF] };
      });
      setArray2(outputArray);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
      setLoader(true);
      const mergedArray = [];
      for (let i = 0; i < array.length; i++) {
        const mergedObject = { ...array[i], ...array2[i] };
        mergedArray.push(mergedObject);
      }
      try {
        const res = await addInternalBounceFile({
          filename: String(fileName).split(".")[0],
          dataArray: mergedArray
        });
        if(res) {
          NotificationManager.info(res?.data?.msg || "Uploaded Successfully");
          setScreenOpen(null);
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        
      }
  }

  


  useEffect(() => {
    tableColumn?.forEach((item) => {
      setTableOptions((prev) => [...prev, { value: item?.label, label: item?.label }])
    });

    const searchString = /Email|email|EmailAddress|emailAddress/;
    const dateFormatsRegex = /^(?:(?:0?[1-9]|1[0-2])\/(?:0?[1-9]|1\d|2[0-9]|3[01])\/(?:\d{4}))|(?:\d{4}-(?:0?[1-9]|1[0-2])-(?:0?[1-9]|[12]\d|3[01]))$/;
    const filteredArray = tableColumn.filter(item => searchString.test(item?.label));
    const filteredArray2 = tableColumn.filter(item => dateFormatsRegex.test(item?.label));
    setColArray(filteredArray)
    setColArray2(filteredArray2)
    const outputArray = tableData.map(item => {
      return { email: item[filteredArray[0]?.label] };
    });
    setArray(outputArray);
    const outputArray2 = tableData.map(item => {
      return { verification_date: item[filteredArray2[0]?.label] };
    });
    setArray2(outputArray2)
  }, [tableColumn])
  return (
    <div>
      <h6 className="insideHeading">{`${fileName} (${tableOptions?.length} headers & ${tableData?.length} rows)`}</h6>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className='insideHeaderContainer' style={{ justifyContent: "flex-start" }}>
          <div className='insideHeaderItem' style={{ marginRight: "10px" }}>
            <label htmlFor="" className='insideHeaderLabel'>Email</label>
            <Select required value={colArray[0]} defaultValue={tableOptions.filter(item => String(item.label).includes("Email"))[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "email")} options={tableOptions || []} />
          </div>
          <div className='insideHeaderItem'>
            <label htmlFor="" className='insideHeaderLabel'>Verification Date</label>
            <Select value={colArray2[0]} isMulti={false} isSearchable={true} closeMenuOnSelect={true} onChange={(option) => changeEmailHandler(option, "ver")} options={tableOptions || []} />
          </div>

        </div>
        <Button
          variant="contained"
          className="m-2 p-2 mr-0 f-16 mt-3"
          style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
          type='submit'
        >
          {
            loader ? <CircularProgress sx={{ color: "white" }} size={20} /> : "Submit"
          }
        </Button>
      </form>
    </div>
  )
}

export default InsideBounceRepository