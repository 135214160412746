/** @format */
import { useContext, useEffect, useState } from 'react';
import Navbar from '../../Elements/Navbar/Navbar';
import Banner from '../../assests/image/profile-banner.png';
import { AuthContext } from '../../componants/Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import EditProfile from './Component/EditProfile';
import TeamMembers from './Component/TeamMembers';
import MemberCreditsRequest from './Component/MemberCreditsRequest';
import CompanyCreditsRequest from './Component/CompanyCreditsRequest';
import ChangePassword from './Component/ChangePassword';
import ActivityLog from './Component/ActivityLog';
import Subadmin from './Component/Subadmin';
import PendingSingups from './Component/PendingSignups';
import Subscriptions from './Component/Subscriptions';
import Companies from './Component/Companies';
import OverlayModal from '../../Elements/Misc/Modal';
import AssignCredit from './Component/AssignCredits';
import ExtendFreeTrial from './Component/ExtendFreeTrial';
import EditUser from './Component/EditUser';
import Invoice from '../Billing/Component/Invoice';
import Transactions from '../Billing/Component/Transactions';

const getInitialsFromFullName = (fullName) => {
	if (!fullName) return '';

	const nameParts = fullName.split(' ');
	if (nameParts.length === 1) {
		return nameParts[0].charAt(0).toUpperCase();
	}

	const initials = nameParts[0].charAt(0) + nameParts[1].charAt(0);
	return initials.toUpperCase();
};

const Profile = () => {
	const { profile } = useContext(AuthContext);
	const { auth } = useAuth();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState('Team Members');
	const [modalOpen, setModalOpen] = useState(false);
	const [model, setmodel] = useState(false);
	const [assignCreditModal, setAssignCreditModal] = useState(false);
	const [extendFreeTrialModal, setExtendFreeTrialModal] = useState(false);
	const [selectedCompanyByAdmin, setSelectedCompanyByAdmin] = useState(null);
	const [selectedCompany, setSelectedCompany] = useState(null);

	const openModal = () => {
		setModalOpen(true);
	};

	const closeModal = () => {
		setTimeout(() => {
			setModalOpen(false);
		}, 200);
	};

	useEffect(() => {
		if (auth.role === 'user') {
			setActiveTab('Credit Requests');
		} else if (auth.role === 'admin') {
			if (selectedCompanyByAdmin) {
				setActiveTab('Subscriptions');
			} else {
				setActiveTab('Sub Admins');
			}
		} else if (auth.role === 'subadmin') {
			setActiveTab('Activity Logs');
		} else {
			setActiveTab('Team Members');
		}
	}, [auth.role, selectedCompanyByAdmin]);

	return (
		<>
			<div className='relative min-h-screen pb-10'>
				<div className='fixed top-0 z-10 w-full'>
					<Navbar />
				</div>
				<div className='mx-20 mt-16'>
					{selectedCompanyByAdmin && (
						<button
							onClick={() => setSelectedCompanyByAdmin(null)}
							className='py-1.5 pl-1 flex justify-center items-center gap-2 mb-2 text-sm font-medium text-blue-600 hover:text-blue-700 hover:underline rounded-md'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4'
							>
								<path d='m15 18-6-6 6-6' />
							</svg>
							Back
						</button>
					)}
					<div className='bg-white pt-2 px-2 rounded-lg border !border-slate-300 w-full'>
						<div className='relative'>
							<img src={Banner} alt='banner' className='rounded-lg w-full' />
							<div className='absolute -bottom-3 left-5 flex items-end gap-2'>
								<div
									style={{ width: '5rem' }}
									className='border-2 border-white bg-blue-600 w-20 px-4 h-20 rounded-full text-white text-lg font-semibold flex justify-center items-center'
								>
									{getInitialsFromFullName(
										selectedCompanyByAdmin
											? selectedCompany?.name
											: profile?.name
									)}
								</div>
								<div className='pb-2 flex gap-8 items-center justify-between'>
									<div>
										<h1 className='text-start text-base text-gray-800 font-semibold'>
											{selectedCompany ? selectedCompany?.name : profile?.name}
										</h1>
										<p className='text-start text-gray-600 text-xs font-medium'>
											{selectedCompany
												? selectedCompany?.email
												: profile?.email}
										</p>
									</div>
								</div>
							</div>
							<div className='mt-2.5 flex items-center justify-end gap-2'>
								<button
									onClick={() => navigate('/billing')}
									disabled={auth.role !== 'company'}
									className='flex gap-1 items-center focus:outline-none py-1.5 px-2 border !border-slate-200 hover:!border-slate-300 hover:bg-gray-50 bg-white rounded-md text-sm font-medium text-slate-800 disabled:pointer-events-none disabled:opacity-0'
								>
									Billing
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
										className='size-4'
									>
										<path d='M7 7h10v10' />
										<path d='M7 17 17 7' />
									</svg>
								</button>
								{selectedCompanyByAdmin &&
									selectedCompany?.plan?.subscription_type === 'Free Trial' && (
										<button
											onClick={() => setExtendFreeTrialModal(true)}
											className='flex gap-1 items-center focus:outline-none py-1.5 px-2 border !border-slate-200 hover:!border-slate-300 hover:bg-gray-50 bg-white rounded-md text-sm font-medium text-slate-800 disabled:pointer-events-none disabled:opacity-0'
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
												class='size-4'
											>
												<path d='M8 2v4' />
												<path d='M16 2v4' />
												<path d='M21 13V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8' />
												<path d='M3 10h18' />
												<path d='M16 19h6' />
												<path d='M19 16v6' />
											</svg>
											Extend Trial
										</button>
									)}
								{selectedCompanyByAdmin && (
									<button
										onClick={() => setAssignCreditModal(true)}
										className='flex gap-1 items-center focus:outline-none py-1.5 px-2 border !border-slate-200 hover:!border-slate-300 hover:bg-gray-50 bg-white rounded-md text-sm font-medium text-slate-800 disabled:pointer-events-none disabled:opacity-0'
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											class='size-4'
										>
											<path d='M12 8v8' />
											<path d='M2.7 10.3a2.41 2.41 0 0 0 0 3.41l7.59 7.59a2.41 2.41 0 0 0 3.41 0l7.59-7.59a2.41 2.41 0 0 0 0-3.41L13.7 2.71a2.41 2.41 0 0 0-3.41 0z' />
											<path d='M8 12h8' />
										</svg>
										Assign Credits
									</button>
								)}
								{selectedCompanyByAdmin ? (
									<button
										onClick={() => setmodel(true)}
										className='flex gap-1 items-center focus:outline-none py-1.5 px-2 border !border-slate-200 hover:!border-slate-300 hover:bg-gray-50 bg-white rounded-md text-sm font-medium text-slate-800 disabled:pointer-events-none disabled:opacity-0'
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											className='size-4'
										>
											<path d='M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z' />
											<path d='m15 5 4 4' />
										</svg>
										Edit User
									</button>
								) : (
									<button
										onClick={openModal}
										disabled={auth.role === 'user'}
										className='flex gap-1 items-center focus:outline-none py-1.5 px-2 border !border-slate-200 hover:!border-slate-300 hover:bg-gray-50 bg-white rounded-md text-sm font-medium text-slate-800 disabled:pointer-events-none disabled:opacity-0'
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											className='size-4'
										>
											<path d='M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z' />
											<path d='m15 5 4 4' />
										</svg>
										Edit Profile
									</button>
								)}
							</div>
						</div>
						<div className='mt-10 mx-6 text-left'>
							<div>
								<nav
									class='flex gap-x-3'
									aria-label='Tabs'
									role='tablist'
									aria-orientation='horizontal'
								>
									{(auth?.role === 'admin' ||
										(auth.role === 'subadmin' &&
											profile?.access_tabs?.includes('Sub Admins'))) &&
										!selectedCompanyByAdmin && (
											<button
												type='button'
												className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
													activeTab === 'Sub Admins'
														? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
														: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
												}`}
												id='tabs-with-icons-item-5'
												aria-selected='true'
												data-hs-tab='#tabs-with-icons-5'
												aria-controls='tabs-with-icons-5'
												role='tab'
												onClick={() => setActiveTab('Sub Admins')}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													stroke-width='2'
													stroke-linecap='round'
													stroke-linejoin='round'
													class='size-4'
												>
													<path d='M2 21a8 8 0 0 1 10.434-7.62' />
													<circle cx='10' cy='8' r='5' />
													<circle cx='18' cy='18' r='3' />
													<path d='m19.5 14.3-.4.9' />
													<path d='m16.9 20.8-.4.9' />
													<path d='m21.7 19.5-.9-.4' />
													<path d='m15.2 16.9-.9-.4' />
													<path d='m21.7 16.5-.9.4' />
													<path d='m15.2 19.1-.9.4' />
													<path d='m19.5 21.7-.4-.9' />
													<path d='m16.9 15.2-.4-.9' />
												</svg>
												Sub Admins
											</button>
										)}
									{(auth?.role === 'admin' ||
										(auth.role === 'subadmin' &&
											profile?.access_tabs?.includes('Companies'))) &&
										!selectedCompanyByAdmin && (
											<button
												type='button'
												className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
													activeTab === 'Companies'
														? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
														: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
												}`}
												id='tabs-with-icons-item-6'
												aria-selected='true'
												data-hs-tab='#tabs-with-icons-6'
												aria-controls='tabs-with-icons-6'
												role='tab'
												onClick={() => setActiveTab('Companies')}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													stroke-width='2'
													stroke-linecap='round'
													stroke-linejoin='round'
													class='size-4'
												>
													<path d='M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z' />
													<path d='M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2' />
													<path d='M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2' />
													<path d='M10 6h4' />
													<path d='M10 10h4' />
													<path d='M10 14h4' />
													<path d='M10 18h4' />
												</svg>
												Companies
											</button>
										)}
									{(auth?.role === 'admin' ||
										(auth.role === 'subadmin' &&
											profile?.access_tabs?.includes('Pending Signups'))) &&
										!selectedCompanyByAdmin && (
											<button
												type='button'
												className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
													activeTab === 'Pending Signups'
														? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
														: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
												}`}
												id='tabs-with-icons-item-7'
												aria-selected='true'
												data-hs-tab='#tabs-with-icons-7'
												aria-controls='tabs-with-icons-7'
												role='tab'
												onClick={() => setActiveTab('Pending Signups')}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													stroke-width='2'
													stroke-linecap='round'
													stroke-linejoin='round'
													class='size-4'
												>
													<circle cx='12' cy='12' r='10' />
													<path d='m4.9 4.9 14.2 14.2' />
												</svg>
												Pending Signups
											</button>
										)}
									{(auth?.role === 'admin' ||
										(auth.role === 'subadmin' &&
											profile?.access_tabs?.includes('Subscriptions'))) && (
										<button
											type='button'
											className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
												activeTab === 'Subscriptions'
													? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
													: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
											}`}
											id='tabs-with-icons-item-8'
											aria-selected='true'
											data-hs-tab='#tabs-with-icons-8'
											aria-controls='tabs-with-icons-8'
											role='tab'
											onClick={() => setActiveTab('Subscriptions')}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
												class='size-4'
											>
												<path d='M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8' />
												<path d='M3 3v5h5' />
												<path d='M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16' />
												<path d='M16 16h5v5' />
											</svg>
											Subscriptions
										</button>
									)}
									{(auth?.role === 'company' ||
										(selectedCompanyByAdmin &&
											(auth.role === 'admin' || auth.role === 'subadmin'))) && (
										<button
											type='button'
											className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
												activeTab === 'Team Members'
													? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
													: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
											}`}
											id='tabs-with-icons-item-1'
											aria-selected='true'
											data-hs-tab='#tabs-with-icons-1'
											aria-controls='tabs-with-icons-1'
											role='tab'
											onClick={() => setActiveTab('Team Members')}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
												className='size-4'
											>
												<path d='M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2' />
												<circle cx='9' cy='7' r='4' />
												<path d='M22 21v-2a4 4 0 0 0-3-3.87' />
												<path d='M16 3.13a4 4 0 0 1 0 7.75' />
											</svg>
											Team Members
										</button>
									)}
									{(auth?.role === 'company' ||
										auth?.role === 'user' ||
										((auth.role === 'admin' || auth.role === 'subadmin') &&
											selectedCompanyByAdmin)) && (
										<button
											type='button'
											className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
												activeTab === 'Credit Requests'
													? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
													: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
											}`}
											id='tabs-with-icons-item-2'
											aria-selected='false'
											data-hs-tab='#tabs-with-icons-2'
											aria-controls='tabs-with-icons-2'
											role='tab'
											onClick={() => setActiveTab('Credit Requests')}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
												className='size-4'
											>
												<circle cx='6' cy='6' r='3' />
												<path d='M6 9v12' />
												<path d='M13 6h3a2 2 0 0 1 2 2v3' />
												<path d='M18 15v6' />
												<path d='M21 18h-6' />
											</svg>
											Credit Requests
										</button>
									)}
									{(auth.role === 'admin' || auth.role === 'subadmin') &&
										selectedCompanyByAdmin && (
											<button
												type='button'
												className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
													activeTab === 'Invoice'
														? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
														: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
												}`}
												id='tabs-with-icons-item-9'
												aria-selected='true'
												data-hs-tab='#tabs-with-icons-9'
												aria-controls='tabs-with-icons-9'
												role='tab'
												onClick={() => setActiveTab('Invoice')}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													stroke-width='2'
													stroke-linecap='round'
													stroke-linejoin='round'
													class='size-4'
												>
													<path d='M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z' />
													<path d='M14 8H8' />
													<path d='M16 12H8' />
													<path d='M13 16H8' />
												</svg>
												Invoice
											</button>
										)}
									{(auth.role === 'admin' || auth.role === 'subadmin') &&
										selectedCompanyByAdmin && (
											<button
												type='button'
												className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
													activeTab === 'Transactions'
														? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
														: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
												}`}
												id='tabs-with-icons-item-10'
												aria-selected='true'
												data-hs-tab='#tabs-with-icons-10'
												aria-controls='tabs-with-icons-10'
												role='tab'
												onClick={() => setActiveTab('Transactions')}
											>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													stroke-width='2'
													stroke-linecap='round'
													stroke-linejoin='round'
													class='size-4'
												>
													<path d='M8 3 4 7l4 4' />
													<path d='M4 7h16' />
													<path d='m16 21 4-4-4-4' />
													<path d='M20 17H4' />
												</svg>
												Transactions
											</button>
										)}
									<button
										type='button'
										className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
											activeTab === 'Activity Logs'
												? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
												: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
										}`}
										id='tabs-with-icons-item-3'
										aria-selected='false'
										data-hs-tab='#tabs-with-icons-3'
										aria-controls='tabs-with-icons-3'
										role='tab'
										onClick={() => setActiveTab('Activity Logs')}
									>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
											className='size-4'
										>
											<path d='M22 12h-2.48a2 2 0 0 0-1.93 1.46l-2.35 8.36a.25.25 0 0 1-.48 0L9.24 2.18a.25.25 0 0 0-.48 0l-2.35 8.36A2 2 0 0 1 4.49 12H2' />
										</svg>
										Activity Logs
									</button>
									{!selectedCompanyByAdmin && (
										<button
											type='button'
											className={`py-2 px-1 inline-flex items-center gap-x-1.5 border-b-2 text-sm whitespace-nowrap focus:outline-none disabled:opacity-50 disabled:pointer-events-none ${
												activeTab === 'Change Password'
													? 'font-semibold hover:font-semibold text-blue-600 border-blue-600'
													: 'font-medium text-gray-600 border-transparent hover:text-gray-800'
											}`}
											id='tabs-with-icons-item-4'
											aria-selected='false'
											data-hs-tab='#tabs-with-icons-4'
											aria-controls='tabs-with-icons-4'
											role='tab'
											onClick={() => setActiveTab('Change Password')}
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
												stroke='currentColor'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
												className='size-4'
											>
												<path d='M2.586 17.414A2 2 0 0 0 2 18.828V21a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h1a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1h.172a2 2 0 0 0 1.414-.586l.814-.814a6.5 6.5 0 1 0-4-4z' />
												<circle cx='16.5' cy='7.5' r='.5' fill='currentColor' />
											</svg>
											Change Password
										</button>
									)}
								</nav>
							</div>
						</div>
					</div>
					<div className='mt-6 bg-white rounded-lg'>
						{activeTab === 'Team Members' &&
							(auth?.role === 'company' ||
								(selectedCompanyByAdmin &&
									(auth.role === 'admin' || auth.role === 'subadmin'))) && (
								<TeamMembers selectedCompanyByAdmin={selectedCompanyByAdmin} />
							)}
						{activeTab === 'Credit Requests' ? (
							auth.role === 'company' ||
							((auth.role === 'admin' || auth.role === 'subadmin') &&
								selectedCompanyByAdmin) ? (
								<CompanyCreditsRequest
									selectedCompanyByAdmin={selectedCompanyByAdmin}
								/>
							) : (
								<MemberCreditsRequest />
							)
						) : (
							<></>
						)}
						{activeTab === 'Activity Logs' && (
							<ActivityLog selectedCompanyByAdmin={selectedCompanyByAdmin} />
						)}
						{activeTab === 'Change Password' && <ChangePassword />}
						{activeTab === 'Invoice' &&
							(auth.role === 'admin' || auth.role === 'subadmin') && (
								<Invoice selectedCompanyByAdmin={selectedCompanyByAdmin} />
							)}
						{activeTab === 'Transactions' &&
							(auth.role === 'admin' || auth.role === 'subadmin') && (
								<Transactions selectedCompanyByAdmin={selectedCompanyByAdmin} />
							)}
						{activeTab === 'Sub Admins' &&
							(auth.role === 'admin' || auth.role === 'subadmin') && (
								<Subadmin />
							)}
						{activeTab === 'Pending Signups' &&
							(auth.role === 'admin' || auth.role === 'subadmin') && (
								<PendingSingups />
							)}
						{activeTab === 'Subscriptions' &&
							(auth.role === 'admin' || auth.role === 'subadmin') && (
								<Subscriptions
									selectedCompanyByAdmin={selectedCompanyByAdmin}
									selectedCompany={selectedCompany}
								/>
							)}
						{activeTab === 'Companies' &&
							(auth.role === 'admin' || auth.role === 'subadmin') && (
								<Companies
									setSelectedCompanyByAdmin={setSelectedCompanyByAdmin}
									setSelectedCompany={setSelectedCompany}
								/>
							)}
					</div>
				</div>
			</div>
			<EditProfile open={modalOpen} close={closeModal} />

			<OverlayModal
				isOpen={model}
				onClose={() => {
					setmodel(false);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Edit Profile
						</h3>
						<div
							onClick={() => {
								setmodel(false);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>

					<EditUser
						selectedCompanyByAdmin={selectedCompanyByAdmin}
						setmodel={setmodel}
					/>
				</div>
			</OverlayModal>

			<OverlayModal
				isOpen={assignCreditModal}
				onClose={() => {
					setAssignCreditModal(false);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Assign Credits
						</h3>
						<div
							onClick={() => {
								setAssignCreditModal(false);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<AssignCredit
						close={() => {
							setAssignCreditModal(false);
						}}
						selectedCompanyByAdmin={selectedCompanyByAdmin}
					/>
				</div>
			</OverlayModal>

			<OverlayModal
				isOpen={extendFreeTrialModal}
				onClose={() => {
					setExtendFreeTrialModal(false);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Extend Free Trial
						</h3>
						<div
							onClick={() => {
								setExtendFreeTrialModal(false);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<ExtendFreeTrial
						selectedCompanyByAdmin={selectedCompanyByAdmin}
						selectedCompany={selectedCompany}
						close={() => {
							setExtendFreeTrialModal(false);
						}}
					/>
				</div>
			</OverlayModal>
		</>
	);
};

export default Profile;
