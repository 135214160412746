/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../../../componants/Context/AuthContext';
import {
	GET_COMPANY_CREDITS_ADMIN,
	INVITE_COMPANY_MEMBER_ADMIN,
	INVITE_TEAM_MEMBER,
} from '../../../componants/Context/Types';
import { NotificationManager } from 'react-notifications';
import OverlayModal from '../../../Elements/Misc/Modal';

const AddTeam = (props) => {
	const { dispatch, credits, profile } = useContext(AuthContext);
	const [selectedCompanyCredits, setSelectedCompanyCredits] = useState();
	const [isLoading, setIsLoading] = useState({
		value: false,
		message: '',
	});

	const [initialValues, setInitialValues] = useState({
		name: '',
		email: '',
		credits: '',
	});

	useEffect(() => {
		if (props.selectedCompanyByAdmin) {
			dispatch({
				type: GET_COMPANY_CREDITS_ADMIN,
				id: props.selectedCompanyByAdmin,
				updateState: setSelectedCompanyCredits,
			});
		}
	}, [props.selectedCompanyByAdmin]);

	const handleSubmit = (values, resetForm) => {
		if (!initialValues.name || !initialValues.email || !initialValues.credits) {
			NotificationManager.error('Please fill all the details.');
			return;
		}
		if (props?.selectedCompanyByAdmin) {
			dispatch({
				type: INVITE_COMPANY_MEMBER_ADMIN,
				id: props?.selectedCompanyByAdmin,
				updateState: props.setRows,
				values: values,
				resetForm: resetForm,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: INVITE_TEAM_MEMBER,
				payload: { ...values, payment_gateway: 'STRIPE' },
				updateState: props.setRows,
				resetForm: resetForm,
				setIsLoading: setIsLoading,
			});
		}
	};

	return (
		<>
			<OverlayModal
				isOpen={props.open}
				onClose={() => {
					props.close();
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Add New Member
						</h3>
						<div
							onClick={() => {
								props.close();
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<div className='mt-4'>
						<div>
							<h2 class='block text-xs text-start font-semibold text-gray-800'>
								Name:
							</h2>
							<input
								type='text'
								value={initialValues.name}
								onChange={(e) =>
									setInitialValues({ ...initialValues, name: e.target.value })
								}
								placeholder='Enter name'
								required
								className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
							/>
						</div>
						<div className='mt-2'>
							<h2 class='block text-xs text-start font-semibold text-gray-800'>
								Email:
							</h2>
							<input
								type='text'
								value={initialValues.email}
								onChange={(e) =>
									setInitialValues({ ...initialValues, email: e.target.value })
								}
								placeholder='Enter email'
								required
								className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
							/>
						</div>
						<div className='mt-2'>
							<h2 class='block text-xs text-start font-semibold text-gray-800'>
								Credits:
							</h2>
							<input
								type='text'
								value={initialValues.credits}
								onChange={(e) =>
									setInitialValues({
										...initialValues,
										credits: e.target.value,
									})
								}
								placeholder='Enter credits'
								required
								className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
							/>
						</div>
					</div>
					<button
						className='mt-4 w-full rounded-md py-2 px-4 text-sm text-white bg-blue-600 hover:bg-blue-700 border-none focus:outline-none disabled:opacity-60 disabled:pointer-events-none'
						onClick={() => handleSubmit(initialValues)}
						disabled={!credits || credits <= 0}
					>
						{isLoading.value ? (
							<CircularProgress sx={{ color: 'white' }} size={14} />
						) : props?.selectedCompanyByAdmin ? (
							'Invite'
						) : (
							'Invite ' + `($${profile?.plan?.cost_per_user})`
						)}
					</button>
				</div>
			</OverlayModal>
		</>
	);
};
export default AddTeam;
