import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function PopUpModel(props) {
  const [modalShow, setModalShow] = useState(props.open || false);
  useEffect(() => {
    setModalShow(props.open)
  }, [props.open])

  const handleClose = () => { setModalShow(false); props.close() };

  return (
    <div className="modal position-relative">
      <Modal
        show={modalShow}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`mainModal ${props.className}`}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-black" id="contained-modal-title-vcenter">
            <h4 className="m-0 p-0 text-bold f-16">{props.title}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={props.bodyClass}>{props.children}</Modal.Body>
      </Modal>
    </div>
  );
}
export default PopUpModel;
