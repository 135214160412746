/** @format */
import React, { useContext, useEffect, useState } from 'react';
import {
	LOCATION_CITY,
	LOCATION_COUNTRY,
	LOCATION_STATE,
	LOCATION_ZIP_CODE,
} from '../../pages/ContactSerch/filterCompo/filterTypes';
import { MainContext } from '../../componants/filterContext/FilterContext';
import { AuthContext } from '../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../componants/Context/Types';
import debounce from 'debounce-promise';
import Dropdown from '../Misc/Dropdown';
import FilterBadges from './FilterBadges';
import { CircularProgress } from '@mui/material';
import { States } from '../../Data/states';

function toTitleCase(str) {
	return str.toLowerCase().replace(/\b\w/g, function (char) {
		return char.toUpperCase();
	});
}

const LocationFilter = () => {
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		dispatch,
		setFilter,
		filter,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		setSortField,
		anyFilterFlag,
		setSelectedRegion,
		selectedRegion,
	} = useContext(AuthContext);

	const [stateOptions, setStateOptions] = useState([]);
	const [cityOptions, setCityOptions] = useState([]);
	const [zipcodeOptions, setZipcodeOptions] = useState([]);
	const [firstTimeRun, setFirstTimeRun] = useState(false);
	const [firstRender, setFirstRender] = useState(false);
	const [isStateSearch, setIsStateSearch] = useState(false);
	const [isCitySearch, setIsCitySearch] = useState(false);

	const loadStateOptions = debounce((searchValue) => {
		const getData = async () => {
			setIsStateSearch(true);
			if (!searchValue) {
				searchValue = '';
			}
			const data = await globalFilterKeyToApi[LOCATION_STATE](searchValue);
			const options = data.map((item) => {
				return {
					value: item,
					label: States[item] || item,
				};
			});
			setStateOptions(options);
			setIsStateSearch(false);
		};
		getData();
	}, 300);

	const loadCityOptions = debounce((searchValue) => {
		setIsCitySearch(true);
		const getData = async () => {
			let search = '';
			if (searchValue) {
				search = searchValue;
			}
			const data = await globalFilterKeyToApi[LOCATION_CITY](
				filter[globalFilterKeyTofilterKey[LOCATION_STATE]],
				search
			);
			const options = data.map((item) => {
				return {
					value: item.City,
					label: item.City,
				};
			});
			setIsCitySearch(false);
			setCityOptions(options);
		};
		getData();
	}, 300);

	const handleSelect = (field, selectedOption) => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});

		console.log(selectedOption);

		setGlobalSelectFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.label === selectedOption.label;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});
		setFilter((prev) => {
			if (!prev[field] || prev[field]?.length === 0) {
				return { ...prev, [field]: [selectedOption.value] };
			} else {
				const index = prev[field]?.findIndex((item) => {
					if (typeof item === 'string') {
						return item === selectedOption.value;
					} else {
						return item.label === selectedOption.label;
					}
				});

				if (index !== -1) {
					const updatedArray = [...prev[field]];
					updatedArray.splice(index, 1);
					return { ...prev, [field]: updatedArray };
				} else {
					return { ...prev, [field]: [...prev[field], selectedOption.value] };
				}
			}
		});

		setSortField({});
	};

	const handleRemove = (field, value) => {
		setFilter((prev) => {
			const index = prev[field]?.findIndex((item) => {
				if (typeof item === 'string') {
					return item === value;
				} else {
					return item.label === value;
				}
			});
			console.log(index);
			if (index !== -1) {
				const updatedArray = [...prev[field]];
				updatedArray.splice(index, 1);
				return { ...prev, [field]: updatedArray };
			} else {
				return { ...prev };
			}
		});
		setGlobalSelectFilter((prev) => {
			const index = prev[field]?.findIndex((item) => {
				if (typeof item === 'string') {
					return item === value;
				} else {
					return item.label === value;
				}
			});
			if (index !== -1) {
				const updatedArray = [...prev[field]];
				updatedArray.splice(index, 1);
				return { ...prev, [field]: updatedArray };
			} else {
				return { ...prev };
			}
		});
	};

	const handleWrite = (value) => {
		value = value.filter((item) => item.trim() !== '');
		setGlobalSelectFilter((prev) => {
			return { ...prev, zipcode: value };
		});
		setFilter((prev) => {
			return { ...prev, zipcode: value };
		});
	};

	const countryHandler = (value) => {
		if (value !== 'USA') {
			setGlobalSelectFilter((prev) => {
				return { ...prev, country: [] };
			});
			setFilter((prev) => {
				return {
					...prev,
					country: [],
				};
			});
		} else {
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			setGlobalSelectFilter((prev) => {
				return { ...prev, country: ['USA'] };
			});
			setSortField({});
			setFilter((prev) => {
				return {
					...prev,
					country: ['USA'],
				};
			});
		}
	};

	useEffect(() => {
		loadStateOptions();
	}, []);

	useEffect(() => {
		setFirstTimeRun(false);
	}, [filter, globalSelectFilter]);

	useEffect(() => {
		loadCityOptions();
	}, [filter?.state]);

	return (
		<div>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Country</h1>
				<div className='w-full my-2 rounded-lg'>
					<div className='flex flex-wrap gap-x-2 gap-y-2 items-center px-2'>
						<div
							className={`rounded-full ${
								filter?.country && filter?.country?.length === 0
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => countryHandler('All')}
								className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'companyradionull'}
									type='radio'
									checked={filter?.country && filter?.country?.length === 0}
									defaultChecked
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
								/>
								All
							</h3>
						</div>
						<div
							className={`rounded-full ${
								filter?.country &&
								filter?.country?.length > 0 &&
								filter?.country[0] === 'USA'
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => countryHandler('USA')}
								className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'companyradio1'}
									type='radio'
									checked={
										filter?.country &&
										filter?.country?.length > 0 &&
										filter?.country[0] === 'USA'
									}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
								/>
								USA
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>Zipcode</h1>
				{filter?.zipcode?.length > 0 && (
					<FilterBadges
						options={filter.zipcode}
						field='zipcode'
						handleRemove={handleRemove}
						optionLength={4}
					/>
				)}
				<div className='w-full my-2 rounded-lg'>
					<h3 className='pt-2 text-start px-2 text-gray-900 font-medium text-2xs'>
						Write or Copy & Paste (with comma separated)
					</h3>
					<div className='px-2 pb-2 pt-1'>
						<input
							type='text'
							// value={filter?.zipcode.join(',')}
							onChange={(e) => {
								const inputValue = e.target.value.replace(/[^\d,]/g, '');
								const numbersArray = inputValue.split(',');
								handleWrite(numbersArray);
							}}
							placeholder='Try "10001"'
							className='w-full py-1point5 px-2 text-xs bg-white flex justify-between items-center border border-gray-300 hover:border-gray-300 rounded-lg text-gray-900 innersearch focus:outline-none outline-none disabled:pointer-events-none disabled:opacity-50'
						/>
					</div>
				</div>
			</div>
			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>State</h1>
				{filter?.state?.length > 0 && (
					<FilterBadges
						options={filter.state}
						field='state'
						handleRemove={handleRemove}
						optionLength={2}
					/>
				)}
				<div className='w-full my-2 rounded-lg'>
					<h3 className='pt-2 text-start px-2 text-gray-900 font-medium text-2xs'>
						Most Searched States
					</h3>
					<div className='px-2 pt-1 flex flex-wrap gap-1 items-center'>
						<div
							onClick={() =>
								handleSelect('state', {
									value: 'NY',
									label: 'New York - NY',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter.state.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'NY';
									} else {
										return e === 'NY';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							New York
						</div>
						<div
							onClick={() =>
								handleSelect('state', {
									value: 'CA',
									label: 'California - CA',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter.state.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'CA';
									} else {
										return e === 'CA';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							California
						</div>
						<div
							onClick={() =>
								handleSelect('state', {
									value: 'WA',
									label: 'Washington - WA',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter.state.findIndex((e) => {
									if (typeof e === 'string') {
										return e === 'WA';
									} else {
										return e === 'WA';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Washington
						</div>
					</div>
					<h3 className='pt-3 text-start px-2 text-gray-700 font-medium text-2xs'>
						Or choose from the list
					</h3>
					<div className='px-2 pb-2 pt-1'>
						<Dropdown
							options={stateOptions}
							defaultLabel={'Search State'}
							onSelect={handleSelect}
							field='state'
							disabled={false}
							title={'States:'}
							handleSearchOption={loadStateOptions}
							searchLoading={isStateSearch}
							filter={filter}
						/>
					</div>
				</div>
			</div>
			<div
				style={{ height: '1px' }}
				className='border-t border-slate-300 my-4'
			/>
			<div className='px-2'>
				<h1 className='text-start px-2 text-sm font-semibold'>City</h1>
				{filter?.city?.length > 0 && (
					<FilterBadges
						options={filter.city}
						field='city'
						handleRemove={handleRemove}
						optionLength={3}
					/>
				)}
				<div className='w-full my-2 rounded-lg'>
					<h3 className='pt-2 text-start px-2 text-gray-900 font-medium text-2xs'>
						Most Searched Cities
					</h3>
					<div className='px-2 pt-1 flex flex-wrap gap-1 items-center'>
						<div
							onClick={() =>
								handleSelect('city', {
									value: 'San Diego',
									label: 'San Diego',
									id: 'city',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter.city.findIndex((e) => {
									if (typeof e === 'string') {
										return toTitleCase(e) === 'San Diego';
									} else {
										return e.label === 'San Diego';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							San Diego
						</div>
						<div
							onClick={() =>
								handleSelect('city', {
									value: 'Los Angeles',
									label: 'Los Angeles',
									id: 'city',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter.city.findIndex((e) => {
									if (typeof e === 'string') {
										return toTitleCase(e) === 'Los Angeles';
									} else {
										return e.label === 'Los Angeles';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							Los Angeles
						</div>
						<div
							onClick={() =>
								handleSelect('city', {
									value: 'New York City',
									label: 'New York City',
									id: 'city',
								})
							}
							className={`cursor-pointer text-2xs font-medium rounded-lg py-1 px-2 ${
								filter.city.findIndex((e) => {
									if (typeof e === 'string') {
										return toTitleCase(e) === 'New York City';
									} else {
										return e.label === 'New York City';
									}
								}) !== -1
									? 'bg-blue-600 text-white hover:bg-blue-700'
									: 'text-gray-800 bg-gray-300 hover:bg-gray-400'
							}`}
						>
							New York City
						</div>
					</div>
					<h3 className='pt-3 text-start px-2 text-gray-700 font-medium text-2xs'>
						Or choose from the list
					</h3>
					<div className='px-2 pb-2 pt-1'>
						{filter?.state?.length > 0 ? (
							<Dropdown
								options={cityOptions}
								defaultLabel={'Search City'}
								onSelect={handleSelect}
								field='city'
								disabled={false}
								title={'Cities:'}
								handleSearchOption={loadCityOptions}
								searchLoading={isCitySearch}
								filter={filter}
								openAbove={true}
							/>
						) : (
							<p className='text-red-600 text-2xs font-medium text-start'>
								Select State First
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LocationFilter;
