/** @format */

import React, { useContext, useEffect, useState, useRef } from 'react';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import { Button, CircularProgress } from '@mui/material';
import { downloadCSV } from 'download-csv';
import moment from 'moment';
import {
	GET_DOWLOAD_LEADS,
	GET_ONE_DOWLOAD_LIST,
} from '../../../../componants/Context/Types';
import {
	EXECUTIVE_FILTER,
	PHYSICIAN_FILTER,
} from '../../filterCompo/filterTypes';
import {
	executiveHeadersDownload,
	physicianHeadersDownload,
} from '../../../../helper/schema';
import TableSkeltonLoader from '../../../../componants/comman/TableSkeltonLoader/TableSkeltonLoader';
import Pagination from '../../../../Elements/Misc/APagination';
import DynamicTable from '../../../../Elements/Misc/DTable';
import {
	getOneDownloadList,
	renameDownloadList,
} from '../../../../componants/Context/APIs';
import OverlayModal from '../../../../Elements/Misc/Modal';
import PaginationRow from '../../../../Elements/Misc/PaginationRow';

const leadColumns = [
	{
		title: 'Name',
		dataIndex: 'Name',
	},
	{
		title: 'Speciality General',
		dataIndex: 'Specialty_General',
	},
	{
		title: 'PhoneNumber',
		dataIndex: 'PhoneNumber',
	},
	{
		title: 'Email',
		dataIndex: 'EmailAddress',
	},
	{
		title: 'Address',
		dataIndex: 'Address1',
	},
];

const headers = [
	{ label: 'FirstName', key: 'FirstName' },
	{ label: 'LastName', key: 'LastName' },
	{ label: 'Specialty_General', key: 'Specialty_General' },
	{ label: 'PhoneNumber', key: 'PhoneNumber' },
	{ label: 'EmailAddress', key: 'EmailAddress' },
	{ label: 'Address1', key: 'Address1' },
	{ label: 'Clinic', key: 'Clinic' },
	{ label: 'City', key: 'City' },
	{ label: 'State', key: 'State' },
	{ label: 'Country', key: 'Country' },
];

export const physicianColumns = [
	{
		title: 'FullName',
		dataIndex: 'FullName',
	},
	//   {
	//     title: "FirstName",
	//     dataIndex: "FirstName",
	//   },
	//   {
	//     title: "MiddleName",
	//     dataIndex: "MiddleName",
	//   },
	//   {
	//     title: "LastName",
	//     dataIndex: "LastName",
	//   },
	{
		title: 'Specialty',
		dataIndex: 'Specialty',
	},
	{
		title: 'SpecialtyGroup1',
		dataIndex: 'SpecialtyGroup1',
	},
	//   {
	//     title: "SpecialtyGroup2",
	//     dataIndex: "SpecialtyGroup2",
	//   },
	{
		title: 'SpecialtyType',
		dataIndex: 'SpecialtyType',
	},
	{
		title: 'Hospital_Doctor',
		dataIndex: 'Hospital_Doctor',
	},
	{
		title: 'Email',
		dataIndex: 'EmailAddress',
	},
	{
		title: 'PhoneNumber',
		dataIndex: 'PhoneNumber',
	},
	//   {
	//     title: "Address1",
	//     dataIndex: "Address1",
	//   },
	//   {
	//     title: "Address2",
	//     dataIndex: "Address2",
	//   },

	{
		title: 'City',
		dataIndex: 'City',
	},
	{
		title: 'State',
		dataIndex: 'State',
	},
	//   {
	//     title: "Country",
	//     dataIndex: "Country",
	//   },

	//   {
	//     title: "ZIPCode",
	//     dataIndex: "ZIPCode",
	//   },
	//   {
	//     title: "WebAddress",
	//     dataIndex: "WebAddress",
	//   },

	//   {
	//     title: "FaxNumber",
	//     dataIndex: "FaxNumber",
	//   },
	//   {
	//     title: "MasterID",
	//     dataIndex: "MasterID",
	//   },
	//  {
	// title: "ISP",
	// dataIndex: "ISP",
	//  },

	{
		title: 'NPINumber',
		dataIndex: 'NPINumber',
	},
	// {
	//   title: "MasterID2",
	//   dataIndex: "MasterID2",
	// },
	//   {
	//     title: "EnumerationDate",
	//     dataIndex: "EnumerationDate",
	//   },
	//  {
	// title: "DeactivationReasonCode",
	// dataIndex: "DeactivationReasonCode",
	//  },
	//   {
	//     title: "Gender",
	//     dataIndex: "Gender",
	//   },
	{
		title: 'degree',
		dataIndex: 'degree',
	},
	//  {
	// title: "TaxonomyCode",
	// dataIndex: "TaxonomyCode",
	//  },
	//   {
	//     title: "LicenseNumber",
	//     dataIndex: "LicenseNumber",
	//   },
	//   {
	//     title: "LicenseIssueState",
	//     dataIndex: "LicenseIssueState",
	//   },
	//  {
	// title: "DeactivationDate",
	// dataIndex: "DeactivationDate",
	//  },
	//  {
	// title: "AMA_Description",
	// dataIndex: "AMA_Description",
	//  },
	//  {
	// title: "NUCCDescription",
	// dataIndex: "NUCCDescription",
	//  },
	// {
	//   dataIndex: "Action",
	//   dataIndex: "Action",
	// },
	{
		dataIndex: 'emailstatus',
		title: 'Status',
	},
	//   { title: "Valid", dataIndex: "emailValid" },
];

export const executiveColumns = [
	{
		title: 'FullName',
		dataIndex: 'FullName',
	},
	//   {
	//     title: "FirstName",
	//     dataIndex: "FirstName",
	//   },
	//   {
	//     title: "MiddleName",
	//     dataIndex: "MiddleName",
	//   },
	//   {
	//     title: "LastName",
	//     dataIndex: "LastName",
	//   },
	{
		title: 'Email',
		dataIndex: 'EmailAddress',
	},
	{
		title: 'PhoneNumber',
		dataIndex: 'PhoneNumber',
	},
	//   {
	//     title: "Address1",
	//     dataIndex: "Address1",
	//   },
	//   {
	//     title: "Address2",
	//     dataIndex: "Address2",
	//   },

	{
		title: 'City',
		dataIndex: 'City',
	},
	{
		title: 'State',
		dataIndex: 'State',
	},
	//   {
	//     title: "Country",
	//     dataIndex: "Country",
	//   },

	{
		title: 'ZIPCode',
		dataIndex: 'ZIPCode',
	},
	{
		title: 'WebAddress',
		dataIndex: 'WebAddress',
	},

	//  {
	// title: "FaxNumber",
	// dataIndex: "FaxNumber",
	//  },
	//   {
	//     title: "MasterID",
	//     dataIndex: "MasterID",
	//   },
	//  {
	// title: "ISP",
	// dataIndex: "ISP",
	//  },

	// {
	//   dataIndex: "NPINumber",
	//   dataIndex: "NPINumber",
	// },
	// {
	//   title: "MasterID2",
	//   dataIndex: "MasterID2",
	// },
	//  {
	// title: "EnumerationDate",
	// dataIndex: "EnumerationDate",
	//  },
	//  {
	// title: "DeactivationReasonCode",
	// dataIndex: "DeactivationReasonCode",
	//  },
	//  {
	// title: "Gender",
	// dataIndex: "Gender",
	//  },
	// {
	//   dataIndex: "degree",
	//   dataIndex: "degree",
	// },
	//  {
	// title: "TaxonomyCode",
	// dataIndex: "TaxonomyCode",
	//  },
	//  {
	// title: "LicenseNumber",
	// dataIndex: "LicenseNumber",
	//  },
	//  {
	// title: "LicenseIssueState",
	// dataIndex: "LicenseIssueState",
	//  },
	//  {
	// title: "DeactivationDate",
	// dataIndex: "DeactivationDate",
	//  },
	//  {
	// title: "AMA_Description",
	// dataIndex: "AMA_Description",
	//  },
	//  {
	// title: "NUCCDescription",
	// dataIndex: "NUCCDescription",
	//  },
	// {
	//   dataIndex: "Action",
	//   dataIndex: "Action",
	// },
	{
		title: 'Title',
		dataIndex: 'Title',
	},
	//   {
	//     title: "LevelID",
	//     dataIndex: "LevelID",
	//   },
	{
		title: 'Department',
		dataIndex: 'Department',
	},
	{
		title: 'FirmType',
		dataIndex: 'FirmType',
	},
	{
		title: 'HospitalType',
		dataIndex: 'HospitalType',
	},
	{
		title: 'BedsRange',
		dataIndex: 'BedsRange',
	},
	{
		title: 'Ownership',
		dataIndex: 'Ownership',
	},
	//   {
	//     title: "Market_Concentration_Index",
	//     dataIndex: "Market_Concentration_Index",
	//   },
	//   {
	//     title: "HospitalType",
	//     dataIndex: "HospitalType",
	//   },
	//   {
	//     title: "CompanyStatus",
	//     dataIndex: "CompanyStatus",
	//   },
	{
		dataIndex: 'emailstatus',
		title: 'Status',
	},
	//   { title: "Valid", dataIndex: "emailValid" },
];

const Downloads = () => {
	const [allData, setAllData] = useState([]);
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { dispatch } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [unlockLeads, setUnlockLeads] = useState([]);
	const [list, setList] = useState([]);
	const [listLoading, setListLoading] = useState(false);
	const [selectedDownload, setSelectedDownload] = useState(null);
	const [selectedName, setSelectedName] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [typesOfFilter, setTypesOfFilter] = useState(PHYSICIAN_FILTER);

	const downloadColumns = [
		{
			title: 'File Name',
			key: 'id',
			field: 'download_name',
		},
		// {
		// 	title: 'Downloaded By',
		// 	key: 'downloaded by',
		// 	render: (_, record) => (
		// 		<p>{'member' in record ? record.member.name : 'Admin'}</p>
		// 	),
		// },
		{
			title: 'Total Records',
			field: 'count',
			render: (_, record) => <p>{record.leads?.length}</p>,
		},
		{
			title: 'Verification Type',
			key: 'typeOfDownload',
			render: (_, record) => (
				<p>{record.verifyAll ? 'Valid Only' : 'Valid + Accept All'}</p>
			),
		},
		{
			title: 'Date',
			key: 'date',
			dataIndex: 'createdAt',
			sorter: (a, b) =>
				new Date(moment(a.createdAt, 'MMMM Do YYYY, h:mm:ss a').format('LLL')) -
				new Date(moment(b.createdAt, 'MMMM Do YYYY, h:mm:ss a').format('LLL')),
		},
		{
			title: 'Actions',
			key: 'view',
			render: (_, record) => (
				<Button
					style={{ boxShadow: 'none', backgroundColor: '#1891eb' }}
					size='small'
					variant={'contained'}
					onClick={() => {
						setSelectedDownload(record._id);
					}}
				>
					View
				</Button>
			),
		},
	];

	useEffect(() => {
		if (!typesOfFilter) return;
		let dataType;
		if (typesOfFilter === PHYSICIAN_FILTER) {
			dataType = 0;
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			dataType = 1;
		}
		if (selectedDownload) {
			dispatch({
				type: GET_ONE_DOWLOAD_LIST,
				id: selectedDownload,
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_DOWLOAD_LEADS,
				updateState: setRows,
				setIsLoading: setIsLoading,
				dataType: dataType,
			});
		}
	}, [selectedDownload, typesOfFilter]);

	const emailValidatedData = rows.map((item) => {
		return {
			...item,
			emailstatus: item?.debounceCode === '2' ? 'catchall' : 'valid',
		};
	});

	const handleDownload = async (row) => {
		const res = await getOneDownloadList(row?._id);
		const listData = res.data.leads.map((item) => {
			return {
				...item,
				emailstatus: item?.debounceCode === '2' ? 'catchall' : 'valid',
			};
		});
		if (typesOfFilter === EXECUTIVE_FILTER) {
			downloadCSV(listData, executiveHeadersDownload, row?.download_name);
		} else {
			downloadCSV(listData, physicianHeadersDownload, row?.download_name);
		}
	};

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'File Name') {
			return row?.download_name;
		} else if (col.title === 'Total Records') {
			return row?.leads?.length;
		} else if (col.title === 'Verification Type') {
			return row.verifyAll ? 'Valid Only' : 'Valid + Accept All';
		} else if (col.title === 'Date') {
			return row.createdAt;
		} else if (col.title === 'Actions') {
			return (
				<div className='flex items-center !gap-4'>
					<div
						onClick={() => {
							setSelectedDownload(row?._id);
							setSelectedName(row?.download_name);
						}}
						className='cursor-pointer flex items-center gap-1 text-emerald-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M5 12s2.545-5 7-5c4.454 0 7 5 7 5s-2.546 5-7 5c-4.455 0-7-5-7-5z' />
							<path d='M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' />
							<path d='M21 17v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2' />
							<path d='M21 7V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2' />
						</svg>
						View
					</div>
					<div
						onClick={() => openModal(row)}
						className='cursor-pointer flex items-center gap-1 text-orange-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z' />
							<path d='m15 5 4 4' />
						</svg>
						Rename
					</div>
					<div
						onClick={() => handleDownload(row)}
						className='cursor-pointer flex items-center gap-1 text-blue-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
							<polyline points='7 10 12 15 17 10' />
							<line x1='12' x2='12' y1='15' y2='3' />
						</svg>
						Export CSV
					</div>
				</div>
			);
		} else if (col.title === 'Email') {
			return row.EmailAddress;
		} else if (col.title === 'Status') {
			return row.debounceCode === '2' ? 'catchall' : 'valid';
		}
		return row[col.title];
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [renameLoading, setRenameLoading] = useState(false);
	const [newDownloadName, setnewDownloadName] = useState('');

	const openModal = (row) => {
		setModalOpen(true);
		setSelectedRow(row);
	};

	const closeModal = () => {
		setTimeout(() => {
			setModalOpen(false);
			setSelectedRow(null);
		}, 200);
	};

	useEffect(() => {
		setnewDownloadName(selectedRow?.download_name);
	}, [selectedRow]);

	const handleRenameList = async () => {
		setRenameLoading(true);
		await renameDownloadList({
			download_id: selectedRow?._id,
			download_name: newDownloadName,
		});
		setRenameLoading(false);
		closeModal();
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	};

	return (
		<div>
			{selectedDownload ? (
				<div className='flex justify-between items-center px-4 pt-3'>
					<div
						className='cursor-pointer text-sm text-gray-800 font-medium flex items-center gap-2 hover:text-blue-600'
						onClick={() => setSelectedDownload(null)}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4'
						>
							<path d='M6 8L2 12L6 16' />
							<path d='M2 12H22' />
						</svg>
						Back
					</div>
					<div>
						<button
							className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2'
							onClick={() => {
								if (typesOfFilter === EXECUTIVE_FILTER) {
									downloadCSV(
										emailValidatedData,
										executiveHeadersDownload,
										selectedName
									);
								} else {
									downloadCSV(
										emailValidatedData,
										physicianHeadersDownload,
										selectedName
									);
								}
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4 h-4'
							>
								<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
								<polyline points='7 10 12 15 17 10' />
								<line x1='12' x2='12' y1='15' y2='3' />
							</svg>
							Export CSV
						</button>
					</div>
				</div>
			) : (
				<div className='flex items-center gap-2 mx-4 pt-3'>
					<p className='text-xs font-semibold text-gray-800'>Choose Type:</p>
					<div className='flex flex-wrap gap-x-2 gap-y-2 items-center px-2'>
						<div
							className={`rounded-full ${
								typesOfFilter === PHYSICIAN_FILTER
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => setTypesOfFilter(PHYSICIAN_FILTER)}
								className='flex gap-1 cursor-pointer items-center py-1 pr-2 pl-1 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'companyradionull'}
									type='radio'
									checked={typesOfFilter === PHYSICIAN_FILTER}
									defaultChecked
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
								/>
								Physicians
							</h3>
						</div>
						<div
							className={`rounded-full ${
								typesOfFilter === EXECUTIVE_FILTER
									? 'border-check-active'
									: 'border-check-light'
							}`}
						>
							<h3
								onClick={() => setTypesOfFilter(EXECUTIVE_FILTER)}
								className='flex gap-1 cursor-pointer items-center py-1 pl-1 pr-2 text-start text-gray-900 font-semibold text-2xs'
							>
								<input
									name={'companyradio1'}
									type='radio'
									checked={typesOfFilter === EXECUTIVE_FILTER}
									class='border-gray-200 cursor-pointer rounded-full disabled:opacity-50'
								/>
								Executives
							</h3>
						</div>
					</div>
				</div>
			)}
			{selectedDownload ? (
				isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={8} />
					</div>
				) : (
					<DynamicTable
						columns={
							typesOfFilter === EXECUTIVE_FILTER
								? executiveColumns
								: typesOfFilter === PHYSICIAN_FILTER
								? physicianColumns
								: []
						}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)
			) : isLoading ? (
				<div
					className='relative'
					style={{ overflow: 'hidden', marginTop: '1rem' }}
				>
					<TableSkeltonLoader countRow={8} />
				</div>
			) : (
				<DynamicTable
					columns={downloadColumns}
					data={paginatedRows}
					renderCell={renderCell}
				/>
			)}
			{!isLoading && (
				<PaginationRow
					totalResults={rows?.length}
					page={page}
					setPage={setPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
				/>
			)}
			<OverlayModal isOpen={modalOpen} onClose={closeModal}>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Rename Download Record
						</h3>
						<div
							onClick={closeModal}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>

					<div className='mt-4'>
						<h2 class='block text-xs text-start font-semibold text-gray-800'>
							Give a new name:
						</h2>
						<input
							type='text'
							value={newDownloadName}
							onChange={(e) => setnewDownloadName(e.target.value)}
							placeholder='Rename...'
							className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
						/>
					</div>

					<div className='flex items-center justify-end gap-2 mt-3'>
						<button
							onClick={closeModal}
							className='py-2 px-3 rounded-lg bg-transparent text-gray-800 text-xs font-medium focus:outline-none border-none'
						>
							Cancel
						</button>
						<button
							onClick={() => {
								handleRenameList();
							}}
							disabled={!newDownloadName}
							className='py-2 px-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium focus:outline-none border-none disabled:pointer-events-none disabled:opacity-70'
						>
							{renameLoading ? (
								<>
									<CircularProgress sx={{ color: 'white' }} size={10} />{' '}
									Renaming
								</>
							) : (
								'Rename'
							)}
						</button>
					</div>
				</div>
			</OverlayModal>
		</div>
	);
};

export default Downloads;
