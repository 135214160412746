import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { usePapaParse } from "react-papaparse";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { MainContext } from "../../../../componants/filterContext/FilterContext";
import { NPI_NUMBER } from "../filterTypes";

const NpiNumber = (props) => {
  const {
    setFilter,
    filter,
    setFirstTimeFilter,
    setFirstTimeApiCall,
    setSortField,
  } = useContext(AuthContext);
  const { globalFilterKeyTofilterKey } = useContext(MainContext);
  const [csvFile, setCsvFile] = useState();
  const [filename, setFilename] = useState("");
  const fileReader = new FileReader();
  const { readString } = usePapaParse();

  const handleOnChange = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);
    setCsvFile(file);
  };

  useEffect(() => {
    if (csvFile) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        readString(csvOutput, {
          worker: true,
          complete: (results) => {
            let extractedData = [];
            results.data.forEach((item, i) => {
              if(item[0].length > 0) {
                extractedData.push(item[0]);
              }
            });
            setFilter((prev) => {
              return {
                ...prev,
                [globalFilterKeyTofilterKey[NPI_NUMBER]]: extractedData,
              };
            });
          },
        });
      };
      fileReader.readAsText(csvFile);
    }
  }, [csvFile]);

  useEffect(() => {
    return () => {
      setFilename("");
    };
  }, []);

  return (
    <div className="p-3 d-flex flex-column">
      <h3 className="f-14 mb-3 text-start f-14">
        Upload NPI numbers in single column CSV. No Headers
      </h3>
      <Button
        component="label"
        variant="outlined"
        className="btn-height"
        startIcon={<UploadFileIcon />}
        sx={{ marginRight: "0rem" }}
      >
        Upload CSV
        <input type="file" accept=".csv" hidden onChange={handleOnChange} />
      </Button>
      <span className="f-14">{filename ? filename : ""}</span>
    </div>
  );
};

export default NpiNumber;
