/** @format */

import React, { useState, useEffect, Fragment, useContext } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import UserDetail from './ProfileComponants/UserDetail';
import VerifyManagement from './ProfileComponants/Subscription';
import UserTAble from '../../../componants/comman/UserTAble';
import CreditRequestTable from '../../../componants/comman/CreditRequestTable';
import SubscripttionTable from '../../../componants/comman/SubscripttionTable';
import Transactions from '../../../componants/comman/Transactions';
import { useLocation } from 'react-router-dom';
import ChangePassword from '../../../componants/comman/ChangePassword';
import ActivityLog from '../ProfileTab/ProfileComponants/ActivityLog';
import Company from '../Admin/Companies/Company';
import Invoice from '../../../componants/comman/Invoice';
import Leads from '../Admin/Companies/Leads';
import Categories from '../Admin/Companies/Categories';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useAuth from '../../../hooks/useAuth';
import EditUser from '../Admin/Companies/EditUser';
import UserInfo from '../Admin/Companies/UserInfo';
import CreditRequest from '../Admin/Companies/CreditRequest';
import Filter from '../Filter';
import Customers from '../Admin/Companies/Customers';
import SearchCompany from '../Admin/Companies/SearchCompany';
import SubscriptionTableCompanyView from '../../../componants/comman/SubscriptionTableCompanyView';
import AddSubAdmin from '../Admin/Companies/AddSubAdmin';
import { AuthContext } from '../../../componants/Context/AuthContext';
import Internal from '../Admin/Companies/Internal';
import CompanyInternal from '../../Admin/CompanyInternal';
import CommonInternal from '../../CommonInternal/CommonInternal';
import EmailFinder from '../Admin/Companies/EmailFinder';
import CommonEmailFinder from '../../CommonInternal/CommonEmailFinder';

const ProfileManagement = () => {
	const { auth } = useAuth();
	const { subAdminDetails, profile } = useContext(AuthContext);
	const location = useLocation();
	const [title, setTitle] = useState(location.state?.title || 'My Profile');
	const [flag, setflag] = useState(auth.role);
	const [filterToggle, setfilterToggle] = useState(true);
	const [subAdmin, setSubAdmin] = useState([
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'My Profile' },
	]);
	const handleClickFilter = () => {
		return setfilterToggle(!filterToggle);
	};

	useEffect(() => {
		if (auth.role === 'subadmin') {
			if (subAdminDetails) {
				let subAdminTabs = subAdminDetails?.access_tabs?.map((item) => {
					return {
						icon: <PersonIcon className='ml-3 mr-2' />,
						title: item,
					};
				});
				subAdminTabs = [
					{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'My Profile' },
					...subAdminTabs,
				];
				setSubAdmin(subAdminTabs);
			}
		}
	}, [subAdminDetails]);

	// Flag for condition if Super Admin want to see particular company
	const [selectedCompanyByAdmin, setSelectedCompanyByAdmin] = useState(null);
	const [selectedCompanyUserName, setSelectedCompanyUserName] = useState('');

	// For Team Member
	const user = [
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'My Profile' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Credit Request' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Activity Log' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Change Password' },
	];

	// For Super Admin
	const admin = [
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'My Profile' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Subscription' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Companies' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Internal' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Companies Internal' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Email Finder' },
		{
			icon: <PersonIcon className='ml-3 mr-2' />,
			title: 'Companies Email Finder',
		},
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Categories' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Leads' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Search Company' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Add SubAdmin' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Email SignUps Only' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Activity Log' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Change Password' },
	];

	//For Company Admin
	const company = [
		{
			icon: <PersonIcon className='ml-3 mr-2' />,
			title: `${
				auth.role === 'admin' && flag === 'company' ? 'User info' : 'My Profile'
			}`,
		},
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Subscription' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Team Members' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Credit Request' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Transactions' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Invoice' },
		{ icon: <PersonIcon className='ml-3 mr-2' />, title: 'Activity Log' },
	];

	if (auth.role === 'company') {
		company.push({
			icon: <PersonIcon className='ml-3 mr-2' />,
			title: 'Change Password',
		});
		if (profile?.is_internal_user) {
			company.splice(2, 0, {
				icon: <PersonIcon className='ml-3 mr-2' />,
				title: 'Internal',
			});
		}
		if (profile?.is_file_enhancer_user) {
			company.splice(2, 0, {
				icon: <PersonIcon className='ml-3 mr-2' />,
				title: 'Email Finder',
			});
		}
	}
	if (auth.role === 'admin' || auth.role === 'subadmin') {
		company.splice(2, 0, {
			icon: <PersonIcon className='ml-3 mr-2' />,
			title: 'Company Internal',
		});
	}
	const dashboard = [
		{ title: 'My Profile', component: <UserDetail setTitle={setTitle} /> },
		{
			title: 'Subscription',
			component:
				flag === 'company' ? (
					selectedCompanyByAdmin ? (
						<SubscriptionTableCompanyView
							selectedCompanyByAdmin={selectedCompanyByAdmin}
						/>
					) : (
						<VerifyManagement selectedCompanyByAdmin={selectedCompanyByAdmin} />
					)
				) : (
					<SubscripttionTable />
				),
		},
		{
			title: 'Team Members',
			component: (
				<UserTAble
					setTitle={setTitle}
					selectedCompanyByAdmin={selectedCompanyByAdmin}
				/>
			),
		},
		{
			title: 'Credit Request',
			component:
				flag === 'company' ? (
					<CreditRequest selectedCompanyByAdmin={selectedCompanyByAdmin} />
				) : (
					flag === 'user' && <CreditRequestTable />
				),
		},
		{
			title: 'Transactions',
			component: (
				<Transactions selectedCompanyByAdmin={selectedCompanyByAdmin} />
			),
		},
		{
			title: 'Change Password',
			component: <ChangePassword />,
		},
		{
			title: 'Activity Log',
			component: (
				<ActivityLog selectedCompanyByAdmin={selectedCompanyByAdmin} />
			),
		},
		{
			title: 'Companies',
			component: (
				<Company
					setflag={setflag}
					setTitle={setTitle}
					setSelectedCompanyByAdmin={setSelectedCompanyByAdmin}
					setSelectedCompanyUserName={setSelectedCompanyUserName}
				/>
			),
		},
		{
			title: 'Invoice',
			component: <Invoice selectedCompanyByAdmin={selectedCompanyByAdmin} />,
		},
		{
			title: 'Leads',
			component: <Leads />,
		},
		{
			title: 'Categories',
			component: <Categories />,
		},
		{
			title: 'Edit User',
			component: <EditUser />,
		},
		{
			title: 'User info',
			component: <UserInfo selectedCompanyByAdmin={selectedCompanyByAdmin} />,
		},
		{
			title: 'Email SignUps Only',
			component: <Customers />,
		},
		{
			title: 'Search Company',
			component: <SearchCompany />,
		},
		{
			title: 'Add SubAdmin',
			component: <AddSubAdmin />,
		},
		{
			title: 'Internal',
			component: <Internal limit={profile?.upload_limit || 0} />,
		},
		{
			title: 'Email Finder',
			component: <EmailFinder />,
		},
		// {
		// 	title: 'Company Internal',
		// 	component: (
		// 		<CompanyInternal selectedCompanyByAdmin={selectedCompanyByAdmin} />
		// 	),
		// },
		{
			title: 'Companies Internal',
			component: <CommonInternal />,
		},
		{
			title: 'Companies Email Finder',
			component: <CommonEmailFinder />,
		},
	];

	const handleClick = (title) => {
		setTitle(title);
	};

	return (
		// <Filter hideShow={filterToggle} hideShowClick={handleClickFilter}>
		<div className='profile-mange home_wrapper_new'>
			<div className='profile-menu mb-2 mt-2 mx-4'>
				<span className='profile-redirect-link'>
					{auth.role === 'admin' || auth.role === 'subadmin'
						? 'Admin'
						: 'Company'}
				</span>
				<span>/</span>
				<span>
					{selectedCompanyByAdmin
						? ` Companies / ${selectedCompanyUserName} / ${title}`
						: ` ${title}`}
				</span>
			</div>

			<div className='user-detail px-4'>
				<div className='user-detail-menu'>
					<div className='d-flex flex-column justify-content-between h-100'>
						<div>
							{(flag === 'admin'
								? admin
								: flag === 'subadmin'
								? subAdmin
								: flag === 'company'
								? company
								: user
							).map((data, i) => {
								return (
									<p
										className={
											new RegExp('^' + title + '$').test(data.title)
												? 'selected-profile-tab user-menu-list'
												: 'user-menu-list'
										}
										onClick={() => {
											handleClick(data.title);
										}}
										key={i}
									>
										{data.icon}
										<span>{data.title}</span>
									</p>
								);
							})}
						</div>
						<div className='text w-100 text-start'>
							{(auth.role === 'admin' || auth.role === 'subadmin') &&
							flag === 'company' ? (
								<a
									className='text-primary pointer '
									onClick={() => {
										setflag(auth.role);
										setTitle('Companies');
										setSelectedCompanyByAdmin(null);
									}}
								>
									<ArrowBackIcon className='mx-2' />
									Back to Dashboard
								</a>
							) : (
								''
							)}
						</div>
					</div>
				</div>

				<div className='user-detail-dashboard'>
					{dashboard.map((value, i) => {
						return (
							<Fragment key={i}>
								{title === value?.title ? value.component : ''}
							</Fragment>
						);
					})}
				</div>
			</div>
		</div>
		// </Filter>
	);
};
export default ProfileManagement;
