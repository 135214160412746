/** @format */

import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from '@mui/material';
import { AuthContext } from '../Context/AuthContext';
import { SAVE_SEARCH_FILTER } from '../Context/Types';
import { PHYSICIAN_FILTER } from '../../pages/ContactSerch/filterCompo/filterTypes';

const AddPopUpModel = ({ setSaveSearch, closeModal }) => {
	const { dispatch, filter, typesOfFilter } = useContext(AuthContext);
	const [searchName, setSearchName] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSaveList = () => {
		let isEmpty = true;
		for (const [key, value] of Object.entries(filter)) {
			if (value) {
				isEmpty = false;
				break;
			}
		}
		if (isEmpty) {
			//("Atleast one filter should be applied!");
		} else {
			dispatch({
				type: SAVE_SEARCH_FILTER,
				values: {
					search_name: searchName,
					search_params: filter,
					type: typesOfFilter === PHYSICIAN_FILTER ? '0' : '1',
				},
				setIsLoading: setIsLoading,
				closeModal: closeModal,
			});
		}
	};

	return (
		<div>
			<div className='mt-4'>
				<h2 class='block text-xs text-start font-semibold text-gray-800 dark:text-gray-300'>
					Give a name to searched filter:
				</h2>
				<input
					type='text'
					value={searchName}
					onChange={(e) => setSearchName(e.target.value)}
					placeholder='Try "My Save Search"'
					className='border border-gray-200 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 py-2 px-2 text-xs'
				/>
			</div>

			<div className='flex items-center justify-end gap-2 mt-3'>
				<button
					onClick={() => setSaveSearch(false)}
					className='py-2 px-3 rounded-lg bg-transparent text-gray-800 text-xs font-medium focus:outline-none border-none'
				>
					Cancel
				</button>
				<button
					onClick={() => {
						handleSaveList();
					}}
					disabled={!searchName}
					className='py-2 px-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium focus:outline-none border-none disabled:pointer-events-none disabled:opacity-70'
				>
					{isLoading ? (
						<>
							<CircularProgress sx={{ color: 'white' }} size={10} /> Saving
						</>
					) : (
						'Save This Filter'
					)}
				</button>
			</div>
		</div>
	);
};

export default AddPopUpModel;
