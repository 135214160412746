/** @format */

import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const MultiRangeSlider = ({ min, max, currentValue, onChange }) => {
	const [minVal, setMinVal] = useState(min);
	const [maxVal, setMaxVal] = useState(max);
	const minValRef = useRef(min);
	const maxValRef = useRef(max);
	const range = useRef(null);

	// Convert to percentage
	const getPercent = useCallback(
		(value) => Math.round(((value - min) / (max - min)) * 100),
		[min, max]
	);

	// Set width of the range to decrease from the left side
	useEffect(() => {
		const minPercent = getPercent(minVal);
		const maxPercent = getPercent(maxValRef.current);

		if (range.current) {
			range.current.style.left = `${minPercent}%`;
			range.current.style.width = `${maxPercent - minPercent}%`;
		}
	}, [minVal, getPercent]);

	// Set width of the range to decrease from the right side
	useEffect(() => {
		const minPercent = getPercent(minValRef.current);
		const maxPercent = getPercent(maxVal);

		if (range.current) {
			range.current.style.width = `${maxPercent - minPercent}%`;
		}
	}, [maxVal, getPercent]);

	// Get min and max values when their state changes
	useEffect(() => {
		onChange({ min: minVal, max: maxVal });
	}, [minVal, maxVal]);

	useEffect(() => {
		if (currentValue?.length === 0) {
			setMinVal(0);
			setMaxVal(30);
		}
		// } else {
		// 	setMinVal(min);
		// 	setMaxVal(max);
		// }
	}, [currentValue]);

	return (
		<div className='flex justify-center items-center'>
			<input
				type='range'
				min={min}
				max={max}
				value={minVal}
				onChange={(event) => {
					const value = Math.min(Number(event.target.value), maxVal - 1);
					setMinVal(value);
					minValRef.current = value;
				}}
				className='thumb thumb--left w-full mx-2 px-2'
				style={{ zIndex: minVal > max - 100 && '5' }}
			/>
			<input
				type='range'
				min={min}
				max={max}
				value={maxVal}
				onChange={(event) => {
					const value = Math.max(Number(event.target.value), minVal + 1);
					setMaxVal(value);
					maxValRef.current = value;
				}}
				className='thumb thumb--right w-full mx-2 px-2'
			/>

			<div className='slider w-full mx-2'>
				<div className='slider__track' />
				<div ref={range} className='slider__range' />
				<div className='slider__left-value text-gray-800'>{minVal}</div>
				<div className='slider__right-value text-gray-800'>{maxVal}</div>
			</div>
		</div>
	);
};

MultiRangeSlider.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
