import React from "react";
import support from "../../assests/image/support.jpg";
import { Button } from "@mui/material";
import PopUpModel from "./PopUpModel";
import { useNavigate } from "react-router-dom";

const ContactSupport = (props) => {
  const navigate = useNavigate();
  return (
    <PopUpModel open={props.open} title={props.title} close={props.close}>
      <div>
        <div className="text-center">
          <img src={support} style={{ width: "40%" }} />
        </div>
        <div className="text-center mt-4">
          <div className="text-design">
            <div style={{ fontSize: "18px" }}>
              To buy Enterperise plan then consult with us.
            </div>
            <Button
              variant="outlined"
              className="button-wid"
              onClick={() =>
                window.open(
                  "https://mail.google.com/mail/?view=cm&fs=1&to=team@healthdbi.com&su=Want%20to%20buy%20Enterprise%20Plan&body=Hi%2C%0D%0AI%20am%20blah%20blah%20and%20I%20am%20want%20to%20buy%20enterprise%20plan%20",
                  "_blank"
                )
              }
              // onClick={() =>
              //   (window.location =
              //     "mailto:team@healthdbi.com?subject=Unable%20to%20receive%20Account%20Activation%20Mail&body=Hi%2C%0D%0AI%20am%20blah%20blah%20and%20I%20am%20want%20to%20buy%20enterprise%20plan%20")
              // }
            >
              Contact Support
            </Button>
          </div>
          {/* <div className='text-Upgrade'>Subscribe for $74/mth and Billed Anually</div> */}
        </div>
        {/* <div className="text-center mt-4">
          <Button
            variant="contained"
            className="text-Upgrade-button"
            onClick={() => {
              navigate("/profile", { state: { title: "Subscription" } });
              props.close();
            }}
          >
            Upgrade Now!
          </Button>
        </div> */}
      </div>
    </PopUpModel>
  );
};

export default ContactSupport;
