/** @format */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import debounce from 'debounce-promise';
import {
	LOCATION_CITY,
	LOCATION_COUNTRY,
	LOCATION_STATE,
	LOCATION_ZIP_CODE,
	SPECIALITY_SPECIALITY,
	SPECIALITY_SPECIALITY_GROUP1,
	SPECIALITY_SPECIALITY_GROUP2,
} from '../filterTypes';
import { MainContext } from '../../../../componants/filterContext/FilterContext';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../../../componants/Context/Types';
import CreatableSelect from 'react-select/creatable';

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Speciality() {
	const [expanded, setExpanded] = React.useState(false);
	const [specialityOptions, setSpecialityOptions] = React.useState([]);
	const [specialityGroupOptions, setSpecialityGroupOptions] = React.useState(
		[]
	);
	const [specialityGroupOptions1, setSpecialityGroupOptions1] = React.useState(
		[]
	);

	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		globalFilterKeyToApi,
		globalFilterKeyTofilterKey,
	} = useContext(MainContext);

	const {
		setFilter,
		filter,
		setFirstTimeFilter,
		setFirstTimeApiCall,
		setSortField,
		dispatch,
		anyFilterFlag,
		specialityInputValue,
		setSpecialityInputValue,
		specialityInputValue1,
		setSpecialityInputValue1,
		specialityInputValue2,
		setSpecialityInputValue2,
	} = useContext(AuthContext);

	const [isOpen, setIsOpen] = useState(false);
	const [isOpen2, setIsOpen2] = useState(false);

	const handleChangeAccordian = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
		if (panel === 'speciality speciality group') {
			setIsOpen(newExpanded);
		}
		if (panel === 'speciality speciality') {
			setIsOpen2(newExpanded);
		}

		console.log(panel);
		setSpecialityOptions(
			specialityOptions?.filter((item) => item?.label?.length !== 0)
		);
		setSpecialityGroupOptions(
			specialityGroupOptions?.filter((item) => item?.label?.length !== 0)
		);
		setSpecialityGroupOptions1(
			specialityGroupOptions1?.filter((item) => item?.label?.length !== 0)
		);
	};

	const loadSpecialityGroupOptions = debounce((searchValue) => {
		const getData = async () => {
			const data = await globalFilterKeyToApi[SPECIALITY_SPECIALITY_GROUP1](
				searchValue
			);
			const options = data.map((item) => {
				return {
					value: item,
					label: item,
				};
			});
			setSpecialityGroupOptions(options);
			return options;
		};
		return getData();
	}, 1000);
	const loadSpecialityGroupOptions1 = debounce((searchValue) => {
		const getData = async () => {
			const data2 = await globalFilterKeyToApi[SPECIALITY_SPECIALITY](
				[],
				searchValue
			);
			const data3 = data2?.map((item) => item?.Specialty);
			const options = data3?.map((item) => {
				return {
					value: item,
					label: item,
				};
			});
			setSpecialityGroupOptions1(options);
			return options;
		};

		return getData();
	}, 1000);

	const loadSpecialityOptions = debounce((searchValue) => {
		// const myPromise = new Promise((resolve, reject) => {
		const getData = async () => {
			const data = await globalFilterKeyToApi[SPECIALITY_SPECIALITY](
				filter[globalFilterKeyTofilterKey[SPECIALITY_SPECIALITY_GROUP1]],
				searchValue
			);
			const options = data.map((item) => {
				return {
					value: item.Specialty,
					label: item.Specialty,
				};
			});
			setSpecialityOptions(options);
			return options;
		};
		return getData();
	}, 1000);

	const handleChangeMultiSelect = (selectedOption) => {
		const formatedOption = selectedOption.map((item) => item.value);
		console.log(selectedOption);
		setGlobalSelectFilter((prev) => {
			return { ...prev, [expanded]: selectedOption };
		});

		setFilter((prev) => {
			return {
				...prev,
				[globalFilterKeyTofilterKey[expanded]]: formatedOption,
			};
		});
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setSortField({});
		setFirstTimeApiCall(false);
	};
	const handleChangeMultiSelect2 = (selectedOption) => {
		const formatedOption = selectedOption.map((item) => item.value);
		console.log(selectedOption);
		setGlobalSelectFilter((prev) => {
			return { ...prev, [SPECIALITY_SPECIALITY]: selectedOption };
		});
		setFilter((prev) => {
			return {
				...prev,
				[globalFilterKeyTofilterKey[SPECIALITY_SPECIALITY]]: formatedOption,
			};
		});
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setSortField({});
	};

	useEffect(() => {
		if (
			filter[globalFilterKeyTofilterKey[SPECIALITY_SPECIALITY_GROUP1]]
				.length === 0
		)
			return;
		loadSpecialityOptions('');
	}, [filter[globalFilterKeyTofilterKey[SPECIALITY_SPECIALITY_GROUP1]]]);

	const keyDownHandler = (e, type) => {
		setSpecialityGroupOptions1(specialityGroupOptions1);
		if (e.code === 'Enter') {
			if (type === 'two') {
				setExpanded(SPECIALITY_SPECIALITY);
			}
			setFirstTimeApiCall(false);
			setFirstTimeFilter({
				filterCount: 0,
				isFilterCall: false,
			});
			anyFilterFlag.current = true;
			dispatch({
				type: FILTER_CONTACTS,
			});
		}
	};

	useEffect(() => {
		loadSpecialityOptions('');
		loadSpecialityGroupOptions1('');
		loadSpecialityGroupOptions('');
	}, []);

	return (
		<div>
			<AccordionDetails className='f-16'>
				<Select
					isMulti
					placeholder='Search'
					closeMenuOnSelect={false}
					isSearchable={true}
					styles={{
						option: (provided, state) => ({
							...provided,
							textAlign: 'left',
							overflowX: 'none',
						}),
						placeholder: (provided, state) => ({
							...provided,
							textAlign: 'left',
						}),
					}}
					onChange={handleChangeMultiSelect2}
					onKeyDown={(e) => keyDownHandler(e, 'one')}
					value={globalSelectFilter[SPECIALITY_SPECIALITY]}
					// defaultOptions={specialityGroupOptions1}
					options={specialityGroupOptions1}
					onInputChange={(value, action) => {
						if (action.action === 'input-change') {
							loadSpecialityGroupOptions1(value);
						}
					}}
					// onInputChange={(value, action) => {
					//   if (action.action === "input-change") {
					//     setSpecialityInputValue(value);
					//     setSpecialityGroupOptions1(specialityGroupOptions1.filter((item) => item?.label?.toLowerCase()?.includes(value?.toLowerCase())))
					//   }
					// }}
					className='f-16'
					// inputValue={specialityInputValue}
					createOptionPosition='first'
					isClearable={false}
				/>
			</AccordionDetails>
			<Accordion
				expanded={expanded === SPECIALITY_SPECIALITY_GROUP1}
				onChange={handleChangeAccordian(SPECIALITY_SPECIALITY_GROUP1)}
			>
				<AccordionSummary>
					<Typography className='f-16'>Speciality Group</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Select
						isMulti
						closeMenuOnSelect={false}
						onMenuOpen={() => setIsOpen(true)}
						onMenuClose={() => setIsOpen(false)}
						menuIsOpen={isOpen}
						isSearchable={true}
						styles={{
							option: (provided, state) => ({
								...provided,
								textAlign: 'left',
								overflowX: 'none',
							}),
							placeholder: (provided, state) => ({
								...provided,
								textAlign: 'left',
							}),
						}}
						value={globalSelectFilter[SPECIALITY_SPECIALITY_GROUP1]}
						// loadOptions={loadSpecialityGroupOptions}
						onChange={handleChangeMultiSelect}
						onKeyDown={(e) => keyDownHandler(e, 'two')}
						// defaultOptions={specialityGroupOptions}
						options={specialityGroupOptions}
						onInputChange={(value, action) => {
							if (action.action === 'input-change') {
								loadSpecialityGroupOptions(value);
							}
						}}
						// options={specialityGroupOptions}
						// onInputChange={(value, action) => {
						//   if (action.action === "input-change")
						//     setSpecialityInputValue1(value);
						// }}
						className='f-16'
						// inputValue={specialityInputValue1}
						createOptionPosition='first'
						isClearable={false}
					/>
				</AccordionDetails>
			</Accordion>
			{filter[globalFilterKeyTofilterKey[SPECIALITY_SPECIALITY_GROUP1]]
				.length === 0 ? null : (
				<Accordion
					expanded={expanded === SPECIALITY_SPECIALITY}
					onChange={handleChangeAccordian(SPECIALITY_SPECIALITY)}
				>
					<AccordionSummary>
						<Typography className='f-16'>Speciality</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Select
							isMulti
							closeMenuOnSelect={false}
							onMenuOpen={() => setIsOpen2(true)}
							onMenuClose={() => setIsOpen2(false)}
							menuIsOpen={isOpen2}
							styles={{
								option: (provided, state) => ({
									...provided,
									textAlign: 'left',
									overflowX: 'none',
								}),
								placeholder: (provided, state) => ({
									...provided,
									textAlign: 'left',
								}),
							}}
							value={globalSelectFilter[SPECIALITY_SPECIALITY]}
							// options={specialityGroupOptions1}
							// loadOptions={loadSpecialityOptions}
							onChange={handleChangeMultiSelect}
							onKeyDown={(e) => keyDownHandler(e, 'three')}
							// defaultOptions={specialityOptions}
							options={specialityOptions}
							onInputChange={(value, action) => {
								if (action.action === 'input-change') {
									loadSpecialityOptions(value);
								}
							}}
							// options={specialityOptions}
							// onInputChange={(value, action) => {
							//   if (action.action === "input-change")
							//     setSpecialityInputValue2(value);
							// }}
							className='f-16'
							// inputValue={specialityInputValue2}
							createOptionPosition='first'
							isClearable={false}
						/>
					</AccordionDetails>
				</Accordion>
			)}
		</div>
	);
}
