/** @format */

import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assests/image/companylogo.png';
import { AuthContext } from '../../componants/Context/AuthContext';
import { RESEND_EMAIL } from '../../componants/Context/Types';

const Verify = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { dispatch } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<div class='max-w-[50rem] min-h-screen flex flex-col mx-auto size-full'>
			<header class='mb-auto flex justify-center z-50 w-full py-4'>
				<nav class='px-4 sm:px-6 lg:px-8'>
					<img
						src={logo}
						alt='HealthDBi'
						className='cursor-pointer h-12 w-auto'
					/>
				</nav>
			</header>
			<main id='content'>
				<div class='text-center py-10 px-4 sm:px-6 lg:px-8'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						stroke-width='2'
						stroke-linecap='round'
						stroke-linejoin='round'
						class='size-14 mb-3 mx-auto text-blue-600'
					>
						<path d='M22 13V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h8' />
						<path d='m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7' />
						<path d='m16 19 2 2 4-4' />
					</svg>
					<h3 className='text-xl font-semibold text-gray-800'>
						Verify Email Address
					</h3>
					<h3 className='px-10 mt-2 text-gray-700 font-medium text-sm'>
						Please verify your email to complete sign-up. We've sent a link to
						your email. If you don't see it in a few minutes, check your spam
						folder.
					</h3>
					<div className='mt-4 flex justify-center items-center gap-2'>
						<button
							onClick={() => {
								dispatch({
									type: RESEND_EMAIL,
									payload: location?.state?.email,
									setIsLoading: setIsLoading,
								});
							}}
							class='!py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none'
						>
							{isLoading ? (
								<div
									class='animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full'
									role='status'
									aria-label='loading'
								>
									<span class='sr-only'>Loading...</span>
								</div>
							) : (
								'Resend'
							)}
						</button>
						<button
							onClick={() =>
								window.open(
									'https://mail.google.com/mail/?view=cm&fs=1&to=team@healthdbi.com&su=Unable%20to%20receive%20Account%20Activation%20Mail&body=Hi%2C%0D%0AI%20am%20blah%20blah%20and%20I%20am%20unable%20to%20receive%20account%20activation%20mail',
									'_blank'
								)
							}
							class='!py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border-none bg-transparent text-blue-600 hover:text-blue-700 focus:outline-none disabled:opacity-50 disabled:pointer-events-none'
						>
							Contact
						</button>
					</div>
				</div>
			</main>
			<footer class='mt-auto text-center py-5'>
				<div class='max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8'>
					<p class='text-sm text-gray-600'>
						© All Rights Reserved. 2022-{new Date().getFullYear()}.
					</p>
				</div>
			</footer>
		</div>
	);
};

export default Verify;
