/** @format */

import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AuthContext } from '../../../../componants/Context/AuthContext';
import { Button } from '@mui/material';
import { downloadCSV } from 'download-csv';
import moment from 'moment';
import {
	GET_DOWLOAD_LEADS,
	GET_DOWNLOAD_QUEUE,
	GET_ONE_DOWLOAD_LIST,
} from '../../../../componants/Context/Types';
import {
	EXECUTIVE_FILTER,
	PHYSICIAN_FILTER,
} from '../../filterCompo/filterTypes';
import {
	executiveHeadersDownload,
	physicianHeadersDownload,
} from '../../../../helper/schema';

const DownloadQueues = () => {
	const [rows, setRows] = useState([]);
	const { dispatch } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedDownload, setSelectedDownload] = useState(false);
	const [fileName, setFileName] = useState('');
	const [data, setData] = useState([]);
	const downloadColumns = [
		{
			title: 'File Name',
			key: 'filename',
			dataIndex: 'file_name',
		},
		{
			title: 'File Id',
			key: 'fileid',
			dataIndex: 'file_id',
		},
		{
			title: 'Progress',
			key: 'progress',
			render: (_, record) => <p>{record.percent || 0}%</p>,
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
		},
		{
			title: 'Estimated Time',
			key: 'time',
			render: (_, record) => (
				<p>
					{(((record?.unique_emails - record?.verified) * 2) / 60).toFixed(2) +
						' min' || 'soon'}
				</p>
			),
		},
		// {
		// 	title: 'Count',
		// 	key: 'count',
		// 	render: (_, record) => <p>{record.leads[0]?.length || 0}</p>,
		// },
		// {
		// 	title: 'Type of Download',
		// 	key: 'typeOfDownload',
		// 	render: (_, record) => (
		// 		<p>{record.verifyAll ? 'Only Valid' : 'Accept All'}</p>
		// 	),
		// },
		{
			title: 'Download Date',
			key: 'date',
			dataIndex: 'createdate',
			sorter: (a, b) =>
				new Date(
					moment(a.createdate, 'MMMM Do YYYY, h:mm:ss a').format('LLL')
				) -
				new Date(moment(b.createdate, 'MMMM Do YYYY, h:mm:ss a').format('LLL')),
		},
		// {
		// 	title: 'View Downloads',
		// 	key: 'view',
		// 	render: (_, record) => (
		// 		<Button
		// 			style={{ boxShadow: 'none', backgroundColor: '#1891eb' }}
		// 			size='small'
		// 			variant={'contained'}
		// 			onClick={() => {
		// 				setSelectedDownload(true);
		// 				let formatData = [];
		// 				record?.leads[0]?.forEach((item) => {
		// 					formatData.push({
		// 						...item,
		// 						emailstatus:
		// 							item['Code'] === '1'
		// 								? 'valid'
		// 								: item['Code'] === '2'
		// 								? 'catchall'
		// 								: 'invalid',
		// 					});
		// 				});
		// 				setData(formatData || []);
		// 				setFileName(record?.download_name);
		// 			}}
		// 		>
		// 			View
		// 		</Button>
		// 	),
		// },
	];

	useEffect(() => {
		dispatch({
			type: GET_DOWNLOAD_QUEUE,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	}, []);

	return (
		<div>
			{isLoading ? (
				<div style={{ margin: '10rem auto' }}>Loading</div>
			) : rows === null || rows?.length === 0 ? (
				<div style={{ margin: '10rem auto' }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 24 24'
						fill='none'
						stroke='currentColor'
						stroke-width='2'
						stroke-linecap='round'
						stroke-linejoin='round'
						style={{ width: '40px', margin: '1rem auto' }}
					>
						<path d='M21 8L18.74 5.74A9.75 9.75 0 0 0 12 3C11 3 10.03 3.16 9.13 3.47' />
						<path d='M8 16H3v5' />
						<path d='M3 12C3 9.51 4 7.26 5.64 5.64' />
						<path d='m3 16 2.26 2.26A9.75 9.75 0 0 0 12 21c2.49 0 4.74-1 6.36-2.64' />
						<path d='M21 12c0 1-.16 1.97-.47 2.87' />
						<path d='M21 3v5h-5' />
						<path d='M22 22 2 2' />
					</svg>
					<h1 style={{ fontSize: '22px' }}>No Downloads in Progress</h1>
					<p>
						Currently, there are no items in the download queue, and no ongoing
						downloads are in process.
					</p>
				</div>
			) : (
				<Table
					columns={downloadColumns}
					dataSource={rows}
					loading={isLoading}
				/>
			)}
		</div>
	);
};

export default DownloadQueues;
