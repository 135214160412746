/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { downloadCSV } from 'download-csv';
import {
	BLOCK_UNBLOCK_COMPANY,
	GET_ALL_COMPANY,
	SUSPEND_UNSUSPEND_COMPANY,
} from '../../../componants/Context/Types';
import { AuthContext } from '../../../componants/Context/AuthContext';
import AddCompany from './AddCompany';
import PaginationRow from '../../../Elements/Misc/PaginationRow';
import DynamicTable from '../../../Elements/Misc/DTable';
import TableSkeltonLoader from '../../../componants/comman/TableSkeltonLoader/TableSkeltonLoader';
import OverlayModal from '../../../Elements/Misc/Modal';

const keys = [
	'name',
	'company_name',
	'email',
	'mobile',
	// "plan subscription_type",
	// "login_ip",
	'credits',
	// "plan subscription_end_date",
];

const headers = [
	{ label: 'Type', key: 'type' },
	{ label: 'Name', key: 'name' },
	{ label: 'Company Name', key: 'company_name' },
	{ label: 'Email', key: 'email' },
	{ label: 'Mobile', key: 'mobile' },
	{ label: 'SignUp Date', key: 'createdAt' },
	{ label: 'Subscription', key: 'plan.subscription_type' },
	{ label: 'Subscription Expiry', key: 'plan.subscription_end_date' },
];

const Companies = (props) => {
	const { dispatch } = useContext(AuthContext);
	const [rows, setRows] = useState([]);
	const [model, setmodel] = useState(false);
	const [currCompanyType, setCurrCompanyType] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [exportData, setExportData] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		dispatch({
			type: GET_ALL_COMPANY,
			updateState: setRows,
			setIsLoading: setIsLoading,
		});
	}, []);

	const search = (rows) => {
		return rows.filter((item) => {
			return keys.some((key) => {
				const splitArr = key.split(' ');
				if (splitArr[0] === 'plan')
					return item[splitArr[0]][splitArr[1]]
						?.toString()
						.toLowerCase()
						.includes(searchValue);
				else
					return item[splitArr[0]]
						?.toString()
						.toLowerCase()
						.includes(searchValue);
			});
		});
	};

	useEffect(() => {
		setExportData(search(rows));
	}, [searchValue, rows]);

	const handleBlockAndUnBlock = (id) => {
		dispatch({
			type: BLOCK_UNBLOCK_COMPANY,
			id: id,
			updateState: setRows,
		});
	};

	const handleSuspendAndUnSuspend = (id) => {
		dispatch({
			type: SUSPEND_UNSUSPEND_COMPANY,
			id: id,
			updateState: setRows,
		});
	};

	const columns = [
		{ title: 'Company Name' },
		{ title: 'Company Admin' },
		{ title: 'User Type' },
		{ title: 'Creation' },
		{ title: 'Credits' },
		{ title: 'Plan' },
		{ title: 'Suspend' },
		{ title: 'Block' },
		{ title: 'Action' },
	];

	const [paginatedRows, setPaginatedRows] = useState([]);

	useEffect(() => {
		const newrows = [...search(rows)];
		const paginated = newrows.slice(
			(page - 1) * rowsPerPage,
			page * rowsPerPage
		);
		setPaginatedRows(paginated);
	}, [rows, page, rowsPerPage, searchValue]);

	const renderCell = (row, col) => {
		if (col.title === 'Company Name') {
			return row?.company_name;
		} else if (col.title === 'Mobile') {
			return row?.mobile;
		} else if (col.title === 'Company Admin') {
			return (
				<>
					<p className='font-semibold'>{row?.name}</p>
					<p className='mt-1'>{row?.email}</p>
					<p>{row?.mobile}</p>
				</>
			);
		} else if (col.title === 'Credits') {
			return row?.credits;
		} else if (col.title === 'User Type') {
			return row?.plan
				? row?.plan?.subscription_type !== 'Free Trial' && !row?.plan?.isExpired
					? 'PREMIUM'
					: 'FREE'
				: 'FREE';
		} else if (col.title === 'Creation') {
			return new Date(row.createdAt).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});
		} else if (col.title === 'Plan') {
			return (
				<>
					<p className='font-semibold'>{row?.plan?.subscription_type}</p>
					<p className='mb-1'>
						{new Date(row?.plan?.subscription_end_date)?.toLocaleDateString(
							'en-US',
							{ year: 'numeric', month: 'short', day: 'numeric' }
						)}
					</p>
					{row?.plan?.isExpired === true ? (
						<div>
							<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-red-100 text-red-700 rounded-full'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-3 rotate-45'
								>
									<circle cx='12' cy='12' r='10' />
									<path d='M8 12h8' />
									<path d='M12 8v8' />
								</svg>
								Inactive
							</span>
						</div>
					) : (
						<div>
							<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-emerald-100 text-emerald-700 rounded-full'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									stroke='currentColor'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
									class='size-3'
								>
									<circle cx='12' cy='12' r='10' />
									<path d='m9 12 2 2 4-4' />
								</svg>
								Active
							</span>
						</div>
					)}
				</>
			);
		} else if (col.title === 'Suspend') {
			return row?.suspended ? 'Suspended' : '-';
		} else if (col.title === 'Block') {
			return row?.blocked ? 'Blocked' : '-';
		} else if (col.title === 'Action') {
			return (
				<div className='flex items-center !gap-4 whitespace-nowrap'>
					<div
						onClick={() => {
							props.setSelectedCompany(row);
							props.setSelectedCompanyByAdmin(row?._id);
						}}
						className='cursor-pointer flex items-center gap-1 text-blue-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M5 12s2.545-5 7-5c4.454 0 7 5 7 5s-2.546 5-7 5c-4.455 0-7-5-7-5z' />
							<path d='M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' />
							<path d='M21 17v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2' />
							<path d='M21 7V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2' />
						</svg>
						View
					</div>
					{row?.suspended === false ? (
						<div
							onClick={() => {
								handleSuspendAndUnSuspend(row._id);
							}}
							className='cursor-pointer flex items-center gap-1 text-orange-600 hover:underline'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4 rotate-45'
							>
								<circle cx='12' cy='12' r='10' />
								<path d='M8 12h8' />
								<path d='M12 8v8' />
							</svg>
							Suspend
						</div>
					) : (
						<div
							onClick={() => {
								handleSuspendAndUnSuspend(row._id);
							}}
							className='cursor-pointer flex items-center gap-1 text-green-600 hover:underline'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4 rotate-45'
							>
								<circle cx='12' cy='12' r='10' />
								<path d='M8 12h8' />
								<path d='M12 8v8' />
							</svg>
							Unsuspend
						</div>
					)}
					{row?.blocked === false && (
						<div
							onClick={() => {
								row?.blocked === false && handleBlockAndUnBlock(row._id);
							}}
							className='cursor-pointer flex items-center gap-1 text-red-600 hover:underline'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4'
							>
								<circle cx='12' cy='12' r='10' />
								<path d='m4.9 4.9 14.2 14.2' />
							</svg>
							Block Domain
						</div>
					)}
				</div>
			);
		}
		return row[col.title];
	};

	return (
		<>
			<div>
				<div className={`flex items-center px-4 pt-3 justify-between gap-2`}>
					<input
						type='text'
						placeholder='Search name...'
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
						className='focus:outline-none border border-slate-300 text-gray-800 text-xs font-medium !py-2.5 px-3 rounded-lg'
					/>
					<div className='flex items-center gap-2'>
						<button
							className='focus:outline-none border-none bg-emerald-600 hover:bg-emerald-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2 disabled:opacity-60 disabled:pointer-events-none'
							onClick={() => {
								downloadCSV(exportData, headers);
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4'
							>
								<path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' />
								<polyline points='7 10 12 15 17 10' />
								<line x1='12' x2='12' y1='15' y2='3' />
							</svg>
							Export CSV
						</button>
						<button
							className='focus:outline-none border-none bg-blue-600 hover:bg-blue-700 text-white text-xs font-medium !py-2.5 px-3 rounded-lg flex items-center gap-2 disabled:opacity-60 disabled:pointer-events-none'
							onClick={() => {
								setmodel(true);
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='size-4'
							>
								<circle cx='12' cy='12' r='10' />
								<path d='M8 12h8' />
								<path d='M12 8v8' />
							</svg>
							Add New Company
						</button>
					</div>
				</div>
				{isLoading ? (
					<div
						className='relative'
						style={{ overflow: 'hidden', marginTop: '1rem' }}
					>
						<TableSkeltonLoader countRow={6} />
					</div>
				) : (
					<DynamicTable
						columns={columns}
						data={paginatedRows}
						renderCell={renderCell}
					/>
				)}
				{!isLoading && (
					<PaginationRow
						totalResults={rows?.length}
						page={page}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
				)}
			</div>

			<OverlayModal
				isOpen={model}
				onClose={() => {
					setmodel(false);
				}}
			>
				<div className='w-96'>
					<div className='flex justify-between items-center gap-4'>
						<h3 className='text-sm font-semibold text-gray-800'>
							Add New Company
						</h3>
						<div
							onClick={() => {
								setmodel(false);
							}}
							className='cursor-pointer bg-gray-200 hover:bg-gray-300 rounded-md p-1 flex justify-center items-center'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-3 h-3'
							>
								<path d='M18 6 6 18' />
								<path d='m6 6 12 12' />
							</svg>
						</div>
					</div>
					<AddCompany
						open={model}
						close={() => {
							setmodel(false);
						}}
					/>
				</div>
			</OverlayModal>
		</>
	);
};

export default Companies;
