/** @format */

import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../../../componants/comman/DataTable';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Modal from '@mui/material/Modal';
import 'tippy.js/dist/tippy.css'; // optional
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import SaveSearchModel from '../../../componants/comman/SaveSearchModel';
import AddPopUpModel from '../../../componants/comman/AddPopUpModel';
import { AuthContext } from '../../../componants/Context/AuthContext';
import { FILTER_CONTACTS } from '../../../componants/Context/Types';
import { DownloadPopUpModel } from '../../../componants/comman/DownloadPopUpModel';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import Filter from '../Filter';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import { Dropdown, Menu, Space } from 'antd';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import PlaylistAddTwoToneIcon from '@mui/icons-material/PlaylistAddTwoTone';
import { MainContext } from '../../../componants/filterContext/FilterContext';
import SelectedBox from '../filterCompo/SelectedBox';
import { EXECUTIVE_FILTER, PHYSICIAN_FILTER } from '../filterCompo/filterTypes';
import { useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import filterIcon from '../../../assests/image/filter.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 700,
	bgcolor: 'background.paper',
	borderRadius: '5px',
	border: 'none',
	boxShadow: 'none',
	p: 0,
	overflow: 'hidden',
};
const executiveColumns = [
	{
		field: '_id',
		headerName: '_id',
		filterField: '_id',
		hide: false,
	},

	{
		field: 'FullName',
		headerName: 'Name',
		filterField: 'contact_name',
		hide: false,
	},

	{
		field: 'Title',
		headerName: 'Job Title',
		filterField: 'tabletitle',
		hide: false,
	},
	{
		field: 'Hospital_Doctor',
		headerName: 'Hospital/Clinic',
		filterField: 'tablehospitalName',
		hide: false,
	},

	{
		field: 'EmailAddress',
		headerName: 'Email',
		filterField: 'EmailAddress',
		hide: false,
	},
	{
		field: 'PhoneNumber',
		headerName: 'Phone',
		filterField: 'PhoneNumber',
		hide: false,
	},
	{
		field: 'Department',
		headerName: 'Department',
		filterField: 'tabledepartment',
		hide: false,
	},
	{
		field: 'HospitalType',
		headerName: 'Facility Type',
		filterField: 'tablehospitalType',
		hide: false,
	},

	{
		field: 'City',
		headerName: 'City',
		filterField: 'tablecity',
		hide: true,
	},
	{
		field: 'State',
		headerName: 'State',
		filterField: 'tablestate',
		hide: false,
	},
	{
		field: 'FirmType',
		headerName: 'Firm Type',
		filterField: 'tablefirmType',
		hide: true,
	},
	{
		field: 'WebAddress',
		headerName: 'Web Address',
		filterField: 'tablewebaddress',
		hide: true,
	},

	{
		field: 'Ownership',
		headerName: 'Ownership',
		filterField: 'tableownership',
		hide: true,
	},
	{
		field: 'BedsRange',
		headerName: 'Beds Range',
		filterField: 'tablebedrange',
		hide: true,
	},
	{
		field: 'Action',
		headerName: 'Action',
		filterField: 'Action',
		hide: false,
	},
];
const physicianColumns = [
	{
		field: '_id',
		headerName: '_id',
		filterField: '_id',
		hide: false,
	},
	{
		field: 'FullName',
		headerName: 'Name',
		filterField: 'contact_name',
		hide: false,
	},
	{
		field: 'Specialty',
		headerName: 'Specialty',
		filterField: 'tablespeciality',
		hide: false,
	},
	{
		field: 'SpecialtyGroup1',
		headerName: 'Specialty Group',
		filterField: 'tablespecialityGroup',
		hide: true,
	},
	{
		field: 'SpecialtyType',
		headerName: 'HCP Type',
		filterField: 'tablespecialityType',
		hide: true,
	},
	{
		field: 'Hospital_Doctor',
		headerName: 'Hospital/Clinic',
		filterField: 'tablehospitalName',
		hide: false,
	},
	{
		field: 'EmailAddress',
		headerName: 'Email',
		filterField: 'EmailAddress',
		hide: false,
	},
	{
		field: 'PhoneNumber',
		headerName: 'Phone',
		filterField: 'PhoneNumber',
		hide: false,
	},

	{
		field: 'City',
		headerName: 'City',
		filterField: 'tablecity',
		hide: true,
	},
	{
		field: 'State',
		headerName: 'State',
		filterField: 'tablestate',
		hide: false,
	},

	{
		field: 'NPINumber',
		headerName: 'NPI Number',
		filterField: 'tablenpi',
		hide: true,
	},
	{
		field: 'degree',
		headerName: 'Degree',
		filterField: 'tabledegree',
		hide: true,
	},
	{
		field: 'Action',
		headerName: 'Action',
		filterField: 'Action',
		hide: false,
	},
];

const ContactSearchManagement = (props) => {
	const {
		dispatch,
		setFilter,
		setAllContacts,
		setsearchTerm,
		setSelectedLeads,
		filter,
		numOfRecordsFound,
		page,
		rowsPerPage,
		anyFilterFlag,
		setSortField,
		typesOfFilter,
		setTypesOfFilter,
		numOfRecordsFoundLoading,
		setFirstTimeApiCall,
		setFirstTimeFilter,
		setPage,
		setValue,
		setSpecialityInputValue,
		setSpecialityInputValue1,
		setSpecialityInputValue2,
		setTypeInputValue,
		setCountryInput,
		setStateInput,
		setCityInput,
		setZipInput,
		setLicenseInput,
		setTitleInput,
		setLevelInput,
		setFacilityInput,
		setNetworkInput,
		setOwnerInput,
		setGeoInput,
		availableLoading,
		availableStats,
		setSelectedVal,
	} = useContext(AuthContext);
	const {
		globalSelectFilter,
		setGlobalSelectFilter,
		filterKeyToGlobalFilterKey,
	} = useContext(MainContext);
	var a = true;
	const [columns, setColumns] = useState([]);
	const [filterToggle, setfilterToggle] = useState(false);
	const [addlistmodel, setaddlistmodel] = useState(false);
	const [saveSearch, setSaveSearch] = useState(false);
	const [download, setdownload] = useState(false);
	const [moreOptions, setMoreOptions] = useState(false);
	const [showCols, setshowCols] = useState(false);
	const [filterShow, setFilterShow] = useState(true);
	const location = useLocation();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const handleClickFilter = () => {
		return setfilterToggle((prev) => !prev);
	};

	const allColums = (
		<Menu
			className='m-0 p-0 bg-light'
			items={[
				{
					key: '1',
					label: (
						<div className=' p-0 m-0 f-12 text-black '>
							{columns.map((d) => {
								if (d.field !== '_id' && d.field !== 'Action') {
									return (
										<div
											className='d-flex f-12 flex-row align-items-center'
											key={d.field}
										>
											<Checkbox
												size='small'
												sx={{
													color: 'rgb(177, 181, 192);',
												}}
												onClick={(e) => {
													const copyOfColumns = [...columns];
													const changedColumn = copyOfColumns.find(
														(item) => item.field === d.field
													);
													changedColumn.hide = !changedColumn.hide;
													setColumns(copyOfColumns);
												}}
												defaultChecked={!d.hide}
											/>
											{d.headerName}
										</div>
									);
								}
								return null;
							})}
						</div>
					),
				},
			]}
		/>
	);

	const innerMenu = (
		<Menu
			triggerSubMenuAction='click'
			items={columns.map((d, i) => {
				if (d.field !== '_id' && d.field !== 'Action') {
					return {
						key: i * 10,
						label: (
							<FormGroup>
								<FormControlLabel
									className=' p-0 m-0 text-black f-16'
									control={
										<Checkbox
											size='small'
											className='f-16'
											defaultChecked={!d.hide}
											sx={{
												color: 'rgb(177, 181, 192)',
											}}
											onClick={(e) => {
												const copyOfColumns = [...columns];
												const changedColumn = copyOfColumns.find(
													(item) => item.field === d.field
												);
												changedColumn.hide = !changedColumn.hide;
												setColumns(copyOfColumns);
											}}
										/>
									}
									label={d.headerName}
								/>
							</FormGroup>
						),
					};
				}
				return null;
			})}
		></Menu>
	);
	const moreOptionsMenu = (
		<Menu
			triggerSubMenuAction='click'
			onClick={(e) => {
				if (e.key == '2' || e.key == '1') {
					setMoreOptions(false);
				}
			}}
			items={[
				{
					key: '1',
					label: (
						<h6
							className='blue_text f-16'
							onClick={() => {
								setaddlistmodel(true);
							}}
						>
							<PlaylistAddTwoToneIcon fontSize='f-16' color='bg-primary' />
							<small>Create Lists</small>
						</h6>
					),
				},
				{
					key: '2',
					label: (
						<h6
							className='blue_text  f-16'
							onClick={() => {
								setSaveSearch(true);
							}}
						>
							<SaveTwoToneIcon fontSize='f-16' fill='blue' />
							<small>Save Search</small>
						</h6>
					),
				},
				{
					key: '3',
					label: (
						<Dropdown
							overlay={innerMenu}
							visible={showCols}
							trigger='click'
							onVisibleChange={(e) => {
								setshowCols(e);
							}}
							placement='left'
						>
							<h6 className='blue_text f-16'>
								<ArrowLeftIcon fontSize='f-16' color='bg-primary' />
								<small>Columns</small>
							</h6>
						</Dropdown>
					),
				},
			]}
		/>
	);
	const downloadMenu = (
		<Menu
			items={[
				{
					key: '1',
					label: <DownloadPopUpModel setDownload={setdownload} />,
				},
			]}
		/>
	);

	const handleClearSearch = () => {
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setSpecialityInputValue(''),
			setSpecialityInputValue1(''),
			setSpecialityInputValue2(''),
			setTypeInputValue(''),
			setCountryInput(''),
			setStateInput(''),
			setCityInput(''),
			setZipInput(''),
			setLicenseInput(''),
			setTitleInput(''),
			setLevelInput(''),
			setSelectedVal('');
		setFacilityInput(''),
			setNetworkInput(''),
			setOwnerInput(''),
			setGeoInput(''),
			setSortField({});
		setValue([0, 99]);
		setSortField({
			contact_name_sort: null,
			address_sort: null,
			city_sort: null,
			state_sort: null,
			country_sort: null,
			zipcode_sort: null,
			speciality_sort: null,
			specialityType_sort: null,
			specialityGroup_sort: null,
			gender_sort: null,
			title_sort: null,
			hospitalType_sort: null,
			firmType_sort: null,
			levelID_sort: null,
			ownership_sort: null,
			geographic_sort: null,
			experience_sort: null,
			licenseState_sort: null,
			npi_sort: null,
			degree_sort: null,
			hospitalName_sort: null,
			department_sort: null,
			webaddress_sort: null,
			bedrange_sort: null,
		});
		setFilter({
			contact_name: [],
			address: [],
			city: [],
			state: [],

			tabletitle: [],
			tablehospitalName: [],
			tablehospitalType: [],
			tabledepartment: [],
			tablecity: [],
			tablestate: [],
			tablespeciality: [],
			tablespecialityGroup: [],
			tablespecialityType: [],
			tablewebaddress: [],
			tablefirmType: [],
			tableownership: [],
			tablebedrange: [],
			tabledegree: [],
			tablenpi: [],

			country: [],
			zipcode: [],
			speciality: [],
			specialityType: [],
			specialityGroup: [],
			gender: [],
			title: [],
			hospitalType: [],
			firmType: [],
			levelID: [],
			ownership: [],
			geographic: [],
			experience: [],
			licenseState: [],
			npi: [],
			//new
			emailtype: [],
			degree: [],
			hospitalName: [],
			department: [],
			webaddress: [],
			bedrange: [],
		});
		let emptyGlobalSelectFilter = {};
		for (const [key, value] of Object.entries(globalSelectFilter)) {
			emptyGlobalSelectFilter[key] = [];
		}
		setGlobalSelectFilter(emptyGlobalSelectFilter);
		setAllContacts([]);
		setSelectedLeads([]);
		anyFilterFlag.current = false;
		// setTypesOfFilter(PHYSICIAN_FILTER);
		// dispatch({
		//   type: FILTER_CONTACTS,
		//   setAllContacts: setAllContacts,
		//   page: page,
		//   limit: rowsPerPage,
		// });
	};

	useEffect(() => {
		if (typesOfFilter === PHYSICIAN_FILTER) {
			setColumns(physicianColumns);
			if (location.state !== 'FROM_SAVE_SEARCH') handleClearSearch();
		} else if (typesOfFilter === EXECUTIVE_FILTER) {
			setColumns(executiveColumns);
			if (location.state !== 'FROM_SAVE_SEARCH') handleClearSearch();
		}
	}, [typesOfFilter]);

	useEffect(() => {
		return () => {
			handleClearSearch();
			setTypesOfFilter(PHYSICIAN_FILTER);
		};
	}, []);

	const handleFilterSubmit = () => {
		setFirstTimeApiCall(false);
		setFirstTimeFilter({
			filterCount: 0,
			isFilterCall: false,
		});
		setPage(1);
		dispatch({
			type: FILTER_CONTACTS,
		});
		anyFilterFlag.current = true;
	};

	function areAllArraysEmpty(obj) {
		for (let key in obj) {
			if (Array.isArray(obj[key]) && obj[key].length > 0) {
				return false;
			}
		}
		return true;
	}

	useEffect(() => {
		if (areAllArraysEmpty(filter)) {
			handleClose();
		}
	}, [filter]);

	const navigate = useNavigate();
	return (
		<Filter
			navigate={navigate}
			hideShow={filterToggle}
			hideShowClick={handleClickFilter}
		>
			{!anyFilterFlag.current ? (
				<div
					className={`home_wrapper_new ${
						filterToggle ? 'home_wrapper_new4' : 'home_wrappper_new2'
					} `}
				>
					<div className='f-16' style={{ marginTop: '80px' }}>
						Welcome To HealthDBI 👋
					</div>
					<Button
						variant='contained'
						sx={{
							m: 2,
							backgroundColor: '#1891EB',
						}}
						className='header_button shadow-none'
						onClick={() => handleFilterSubmit()}
					>
						Search
					</Button>
				</div>
			) : (
				<div
					className={`home_wrapper_new ${
						filterToggle ? 'home_wrapper_new4' : 'home_wrappper_new2'
					} `}
				>
					<div className='' style={{ marginTop: '80px', marginBottom: '20px' }}>
						<h6 className='text-start  mt-4 d-flex justify-content-between px-2 align-items-center'>
							<div className='f-16 d-flex align-items-center'>
								<span className='me-3 d-flex align-items-center'>
									<h6
										className='f-16'
										style={{
											color: '#707070',
											marginBottom: 0,
										}}
									>
										Contacts
									</h6>
									{numOfRecordsFoundLoading ? (
										<Skeleton
											variant='text'
											sx={{
												fontSize: '24px',
												margin: '0',
												marginLeft: '10px',
												width: '50px',
											}}
										/>
									) : (
										<h6
											className='m-0'
											style={{
												fontSize: '14px',
												color: '#1891EB',
												fontWeight: 'bold',
												// width: "15%",
											}}
										>
											&nbsp;{numOfRecordsFound?.toLocaleString('en-US')}
										</h6>
									)}
								</span>
								<h6
									className='f-16'
									style={{
										color: '#707070',
										marginBottom: 0,
										clear: 'both',
										display: 'inlineBlock',
										overflow: 'hidden',
										whiteSpace: 'nowrap',
									}}
								>
									Net New
								</h6>
								{availableLoading ? (
									<Skeleton
										variant='text'
										sx={{
											fontSize: '24px',
											margin: '0',
											marginLeft: '10px',
											width: '50px',
										}}
									/>
								) : (
									<h6
										className='m-0'
										style={{
											fontSize: '14px',
											color: '#1891EB',
											fontWeight: 'bold',
											// width: "15%",
										}}
									>
										&nbsp;{availableStats?.toLocaleString('en-US')}
									</h6>
								)}
							</div>
							<Modal
								open={open}
								onClose={handleClose}
								aria-labelledby='modal-modal-title'
								aria-describedby='modal-modal-description'
							>
								<Box sx={style}>
									<div
										style={{ backgroundColor: '#CAEFFF', padding: '10px 5px' }}
										className='d-flex justify-content-between align-items-center'
									>
										<h5>Filters</h5>
										<CloseIcon
											style={{ cursor: 'pointer' }}
											onClick={handleClose}
										/>
									</div>

									<div
										className='p-2'
										style={{ display: 'flex', flexWrap: 'wrap' }}
									>
										{typeof Object.keys(filter) !== 'string' &&
											Object?.keys(filter)?.map((item) => {
												if (!filter[item] || filter[item]?.length == 0)
													return '';
												return (
													<>
														{typeof filter[item] !== 'string' &&
															filter[item]?.map((val, i) => {
																console.log(filter);
																if (
																	(typeof val === 'string' &&
																		(val === '0' ||
																			val === '1' ||
																			val?.includes('@') ||
																			val?.includes('.in') ||
																			val?.includes('.com') ||
																			val?.includes('https') ||
																			val?.includes('http'))) ||
																	!val
																)
																	return '';
																return (
																	<>
																		<div className='filterBoxItem filter_box filter_box'>
																			<SelectedBox
																				key={i}
																				value={val?.label || val}
																				index={i}
																				filterKey={item}
																				globalFilterKey={
																					filterKeyToGlobalFilterKey[item]
																				}
																				setFilterShow={setFilterShow}
																			/>
																		</div>
																	</>
																);
															})}
													</>
												);
											})}
									</div>
								</Box>
							</Modal>
							<div
								className='d-flex align-items-center justify-content-between filterBoxItem3'
								style={{ width: '40%', height: '38px' }}
							>
								<img src={filterIcon} alt='' />
								<div className='filterBoxItem2 d-flex'>
									{typeof Object.keys(filter) !== 'string' &&
										Object?.keys(filter)?.map((item) => {
											if (!filter[item] || filter[item]?.length == 0) return '';
											return (
												<>
													{typeof filter[item] !== 'string' &&
														filter[item]?.map((val, i) => {
															if (
																(typeof val === 'string' &&
																	(val === '0' ||
																		val === '1' ||
																		val?.includes('@') ||
																		val?.includes('.in') ||
																		val?.includes('.com') ||
																		val?.includes('https') ||
																		val?.includes('http'))) ||
																!val
															)
																return '';

															return (
																<>
																	<div className='filterBoxItem filter_box filter_box'>
																		<SelectedBox
																			key={i}
																			value={val?.label || val}
																			index={i}
																			filterKey={item}
																			globalFilterKey={
																				filterKeyToGlobalFilterKey[item]
																			}
																			setFilterShow={setFilterShow}
																		/>
																	</div>
																</>
															);
														})}
												</>
											);
										})}
								</div>
								<KeyboardArrowDownIcon
									onClick={handleOpen}
									style={{ cursor: 'pointer' }}
									className='ms-2'
								/>
							</div>
							<div
								style={{
									display: 'inherit',
									fontSize: 'small',
									alignItems: 'center',
									width: 'fit-content',
								}}
							>
								<Button
									variant='outlined'
									color='error'
									size='small'
									onClick={handleClearSearch}
									style={{
										backgroundColor: '#FFF0F7',
										color: '#FF4953',
									}}
									className='shadow-none me-2 btn-height f-14'
									startIcon={<ReplayIcon fontSize='f-16' />}
								>
									Clear Search
								</Button>

								<Dropdown
									overlay={downloadMenu}
									placement='bottom'
									visible={download}
									// destroyPopupOnHide
									trigger={['click']}
									onVisibleChange={() => setdownload(!download)}
								>
									<Button
										variant='outlined'
										size='small'
										className='me-2 btn-height f-14'
										style={{ boxShadow: 'none' }}
										// onClick={() => setdownload((prev) => !prev)}
									>
										Download
									</Button>
								</Dropdown>

								<Dropdown
									overlay={moreOptionsMenu}
									visible={moreOptions}
									trigger='click'
									onVisibleChange={(e) => {
										setMoreOptions(e);
									}}
									placement='bottom'
									// visible={moreOptions}
									// destroyPopupOnHide
								>
									<Button
										variant='outlined'
										size='small'
										className='border-0 f-14 btn-height'
										onClick={(e) => e.stopPropagation()}
									>
										More Options
										{!moreOptions ? (
											<ExpandMoreTwoToneIcon fontSize='f-16' />
										) : (
											<ExpandLessTwoToneIcon fontSize='f-16' />
										)}
									</Button>
								</Dropdown>
							</div>
						</h6>
					</div>
					<div
						className='mt-2 px-2 customHeight'
						style={{ marginBottom: '30px !important' }}
					>
						<DataTable columns={columns} />
					</div>
					<AddPopUpModel
						open={addlistmodel}
						close={() => {
							setaddlistmodel(false);
						}}
						title={'Add contacts to List'}
						buttonname={'Save List'}
					/>
					<SaveSearchModel
						open={saveSearch}
						close={() => {
							setSaveSearch(false);
						}}
						title={'Save Search'}
						buttonname={<BookmarkAddedIcon />}
					/>
				</div>
			)}
		</Filter>
	);
};

export default ContactSearchManagement;
