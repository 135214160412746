import React, { useEffect, useContext, useState } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleIcon from "@mui/icons-material/People";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import moment from "moment";
import { GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN } from "../../../../componants/Context/Types";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import FacebookCircularProgress from "../../../../componants/comman/FacebookCircularProgress";

const UserInfoDetails = (props) => {
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: GET_COMPANY_INFO_ANOTHER_FORMAT_ADMIN,
      id: props.selectedCompanyByAdmin,
      updateState: props.setInfo,
      setIsLoading: setIsLoading,
    });
  }, []);
  return (
    <div className="p-3">
      {isLoading ? (
        <div className="d-flex justify-content-between flex-row">
          <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
            <FacebookCircularProgress />
          </div>
        </div>
      ) : (
        <>
          <div className="row px-3">
            <div className="col-md-6">
              <div className="blue_text user-name d-flex">
                Welcome Back&nbsp;
                <div className="user-name text-bold">{props.info?.name}!</div>
              </div>

              <div
                style={{ marginTop: "-5px" }}
                className="user-mail-color px-1"
              >
                {props.info?.email}
              </div>
              {props.info?.plan?.isCancelled ? (
                <h3 className="text-danger f-12 text-decoration-underline">
                  This company cancelled their subscription!*
                </h3>
              ) : null}
            </div>
            <div className="col-md-12 d-flex justify-content-between ">
              <div className="profile_card profile_card1_div1 p-3 ">
                <div className="d-flex">
                  <div className="profile_icon_div profile_card1_icon_div1 text-center pt-2 ">
                    <AttachMoneyIcon
                      fontSize="large"
                      style={{ color: "#00c67d" }}
                    />
                  </div>
                  <div className="heading">
                    <div className="profile_card_label blue_text">
                      Current Subscription
                    </div>
                    <div className="user-name text-bold blue_text">
                      {props.info?.plan?.subscription_type}
                    </div>
                  </div>
                </div>
                <div className="px-1">
                  <div className="profile_card_label mt-2  text-gray">
                    Subscription Type
                  </div>
                  <div className="blue_text text-bold  ">
                    <small className="text-bold">
                      {props.info?.plan?.isAnnual === true
                        ? "ANNUAL"
                        : "MONTHLY"}
                    </small>
                  </div>
                  <div className="profile_card_label mt-2  text-gray">
                    Subscription Cost
                  </div>
                  <div className="blue_text text-bold  ">
                    <small className="text-bold">
                      $ {props.info?.plan?.subscription_amount}
                    </small>
                  </div>
                  <div className="profile_card_label mt-2  text-gray">
                    Renewal Date
                  </div>
                  <div className="blue_text text-bold  ">
                    <small className="text-bold">
                      {props.info?.plan?.subscription_end_date &&
                        moment(props.info?.plan?.subscription_end_date).format(
                          "DD-MMM-YYYY"
                        )}
                    </small>
                  </div>
                  <div className="profile_card_label mt-2 d-flex align-items-center text-gray">
                    Location &nbsp;{" "}
                    <small className="blue_text f-14 text-bold">
                      {props?.info?.location}
                    </small>
                  </div>
                </div>
              </div>
              <div className="profile_card profile_card1_div2 p-3 ">
                <div className="d-flex">
                  <div className="profile_icon_div profile_card1_icon_div2 text-center pt-2 ">
                    <PeopleIcon fontSize="large" style={{ color: "#aeae33" }} />
                  </div>
                  <div className="heading">
                    <div className="profile_card_label blue_text">Licenses</div>
                    <div className="user-name text-bold blue_text">
                      Team Member
                    </div>
                  </div>
                </div>
                <div className="px-1">
                  <div className="profile_card_label mt-2  text-gray">
                    Licenses Member
                  </div>
                  <div className="blue_text text-bold  ">
                    {props.info?.plan?.max_members}
                  </div>
                  <div className="profile_card_label mt-2  text-gray">
                    Active Licenses
                  </div>
                  <div className="blue_text text-bold  ">
                    {props.info?.AssMem}
                  </div>
                  <div className="profile_card_label mt-2  text-gray">
                    Unasigned Licenses
                  </div>
                  <div className="blue_text text-bold  ">
                    {props.info?.UnassMem}
                  </div>
                  <div className="profile_card_label mt-2 d-flex align-items-center text-gray">
                    Browser &nbsp;{" "}
                    <small className="blue_text f-14 text-bold">
                      {props?.info?.browserType}
                    </small>
                  </div>
                </div>
              </div>
              <div className="profile_card profile_card1_div3 p-3 ">
                <div className="d-flex">
                  <div className="profile_icon_div profile_card1_icon_div3 text-center pt-2 ">
                    <CardGiftcardIcon
                      fontSize="large"
                      style={{ color: "#4d7cfe" }}
                    />
                  </div>
                  <div className="heading">
                    <div className="profile_card_label blue_text">
                      Based on Subscription
                    </div>
                    <div className="user-name text-bold blue_text">Credits</div>
                  </div>
                </div>
                <div className="px-1">
                  <div className="profile_card_label mt-2  text-gray">
                    Total Credits
                  </div>
                  <div className="blue_text text-bold  ">
                    {props.info?.totalCredits?.toLocaleString()}
                  </div>
                  <div className="profile_card_label mt-2  text-gray">
                    Consumed Credits
                  </div>
                  <div className="blue_text text-bold  ">
                    {(
                      props.info?.totalCredits - props.info?.availCredits
                    )?.toLocaleString()}
                  </div>
                  <div className="profile_card_label mt-2  text-gray">
                    Available Credits
                  </div>
                  <div className="blue_text text-bold  ">
                    {props.info?.availCredits?.toLocaleString()}
                  </div>
                  <div className="profile_card_label mt-2 d-flex align-items-center text-gray">
                    Login IP &nbsp;{" "}
                    <small className="blue_text f-14 text-bold">
                      {props.info?.login_ip}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserInfoDetails;
