/** @format */

import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assests/image/companylogo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { GET_MEMBER, TEAM_SIGNUP } from '../../componants/Context/Types';
import { AuthContext } from '../../componants/Context/AuthContext';
import Info from './Info';
import { NotificationManager } from 'react-notifications';

const TeamSignUp = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { dispatch } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [isMember, setIsMember] = useState(false);
	const id = new URLSearchParams(location.search).get('invite_id');

	const [memberId, setMemberId] = useState(id);
	const [initialValues, setInitialValues] = useState({
		name: '',
		email: '',
		password: '',
		companyName: '',
		acceptTerms: false,
	});

	useEffect(() => {
		dispatch({
			type: GET_MEMBER,
			payload: memberId,
			upDateState: setInitialValues,
			setIsMember: setIsMember,
		});
	}, [memberId]);

	const handleSubmit = (values, resetForm) => {
		if (initialValues?.acceptTerms === false) {
			NotificationManager.error(
				'Please read and accept the terms & conditions.'
			);
			return;
		}
		if (initialValues?.password?.length < 6) {
			NotificationManager.error('Password must be at least 6 characters long');
			return;
		}

		let signupdata = {
			password: initialValues?.password,
			invite_id: memberId,
		};

		dispatch({
			type: TEAM_SIGNUP,
			payload: signupdata,
			resetForm: resetForm,
			setIsLoading: setIsLoading,
		});
	};

	return (
		<>
			<div className='min-h-screen w-full grid grid-cols-2 lg:grid-cols-3 bg-white text-left'>
				<div
					class='max-h-screen max-w-[36rem] mx-auto flex flex-col size-full bg-white col-span-2 overflow-auto'
					style={{ scrollbarWidth: 'none' }}
				>
					<header class='mb-auto z-50 w-full py-4'>
						<nav class='px-4 sm:px-6 lg:px-8'>
							<img src={logo} alt='HealthDBi' className='h-12 w-auto' />
						</nav>
					</header>

					<main id='content'>
						<div class='py-6 px-4 sm:px-6 lg:px-8'>
							<h1 class='block text-xl font-bold text-gray-800'>
								Create your HealthDBi member account
							</h1>
							<p class='mt-0.5 text-gray-600 text-sm'>
								Get Physicians, HCPs, and Nurses Emails with 96% accuracy
							</p>

							<div className='mt-4'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Name:
								</h2>
								<input
									type='text'
									value={initialValues?.name}
									readOnly={true}
									placeholder='Enter your full name'
									required
									className='border border-slate-300 w-full focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Email:
								</h2>
								<input
									type='text'
									value={initialValues?.email}
									readOnly={true}
									placeholder='Enter your email'
									required
									className='border border-slate-300 w-full focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Company Name:
								</h2>
								<input
									type='text'
									value={initialValues?.companyName}
									readOnly={true}
									placeholder='Enter your company name'
									required
									className='border border-slate-300 w-full focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>
							<div className='mt-3 relative'>
								<h2 class='block text-sm text-start font-semibold text-gray-800'>
									Password:
								</h2>
								<input
									type='text'
									value={initialValues?.password}
									onChange={(e) =>
										setInitialValues({
											...initialValues,
											password: e.target.value,
										})
									}
									placeholder='Enter your password'
									required
									className='border border-slate-300 w-full focus:!border-blue-500 focus:outline-none rounded-lg mt-1 !py-3 px-3 text-sm'
								/>
							</div>

							<div className='mt-3 flex gap-2 items-center'>
								<div className='custom-checkbox'>
									<input
										type='checkbox'
										checked={initialValues?.acceptTerms}
										onChange={(e) => {
											setInitialValues({
												...initialValues,
												acceptTerms: e.target.checked,
											});
										}}
									/>
									<div class='check'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											class='w-3 h-3 mb-1'
											viewBox='0 0 20 20'
											fill='currentColor'
											stroke='currentColor'
											stroke-width='1'
										>
											<path
												fill-rule='evenodd'
												d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
												clip-rule='evenodd'
											></path>
										</svg>
									</div>
								</div>
								<p class='text-sm text-gray-700'>
									I agree to the{' '}
									<span
										onClick={() => {
											navigate('/terms-conditions');
										}}
										className='text-blue-600 cursor-pointer hover:underline hover:text-blue-700'
									>
										Terms & Conditions.
									</span>
								</p>
							</div>

							<button
								onClick={handleSubmit}
								class='mt-4 w-full !py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none'
							>
								{isLoading ? (
									<div
										class='animate-spin inline-block size-4 border-[2px] border-current border-t-transparent text-white rounded-full'
										role='status'
										aria-label='loading'
									>
										<span class='sr-only'>Loading...</span>
									</div>
								) : (
									'Signup'
								)}
							</button>
						</div>
					</main>
					<footer class='mt-auto text-left pb-5'>
						<div class='max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8'>
							<p class='text-sm text-gray-700'>
								Already have an account?{' '}
								<span
									onClick={() => {
										navigate('/login');
									}}
									className='text-blue-600 cursor-pointer hover:underline hover:text-blue-700'
								>
									Sign in here
								</span>
							</p>
						</div>
					</footer>
				</div>
				<Info />
			</div>
		</>
	);
};

export default TeamSignUp;
