import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../Context/AuthContext";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { NotificationManager } from "react-notifications";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PopUpModel from "./PopUpModel";
import { MARK_AS_PAID_INVOICE } from "../Context/Types";

function MarkAsPaidPopUp(props) {
  const { dispatch } = useContext(AuthContext);
  const [value, setValue] = React.useState("free");
  const [info, setInfo] = useState({
    amount: "",
    date: "",
    txnId: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    let formatedOptions = {
      amount: 0,
      txnId: "",
      date: "",
      offlinePayment: false,
    };
    if (value === "offline") {
      if (!info.amount || !info.txnId || !info.date) {
        //("Every field is required!");
        return;
      }
      if (+info.amount <= 0) {
        //("Enter valid Amount!");
        return;
      }
      formatedOptions = {
        amount: +info.amount,
        txnId: info.txnId,
        date: info.date,
        offlinePayment: true,
      };
    }
    dispatch({
      type: MARK_AS_PAID_INVOICE,
      values: {
        invoice_id: props.selectedInvoice._id,
        company_id: props.selectedCompanyByAdmin,
        ...formatedOptions,
      },
      setIsLoading: setIsLoading,
      updateState: props.setRows,
      setallData: props.setallData,
      closeModal: props.close,
    });
  };

  return (
    <PopUpModel open={props.open} title={props.title} close={props.close}>
      <div className="p-1">
        <div className=" mb-3">
          <div>
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                style={{ color: "#1976d2" }}
              >
                Select option for Invoice
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                defaultValue="free"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              >
                <FormControlLabel
                  value="free"
                  componentsProps={{ typography: { variant: "small" } }}
                  control={<Radio size="small" />}
                  label="Free"
                />
                <FormControlLabel
                  value="offline"
                  componentsProps={{ typography: { variant: "small" } }}
                  control={<Radio size="small" />}
                  label="Offline"
                />
                {value === "offline" ? (
                  <Stack spacing={2}>
                    <TextField
                      fullWidth
                      value={info?.amount}
                      required
                      type="number"
                      id="outlined-basic"
                      size="small"
                      className="user-input-fild w-100"
                      label="Amount"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      onChange={(e) =>
                        setInfo((prev) => {
                          return {
                            ...info,
                            amount: e.target.value,
                          };
                        })
                      }
                    />
                    <TextField
                      required
                      label="Date"
                      type="date"
                      value={info?.date}
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setInfo((prev) => {
                          return {
                            ...info,
                            date: e.target.value,
                          };
                        })
                      }
                    />
                    <TextField
                      fullWidth
                      required
                      value={info?.txnId}
                      onChange={(e) =>
                        setInfo((prev) => {
                          return {
                            ...info,
                            txnId: e.target.value,
                          };
                        })
                      }
                      type="text"
                      id="outlined-basic"
                      size="small"
                      className="user-input-fild w-100"
                      label="Transasction ID"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Stack>
                ) : (
                  ""
                )}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="m-0">
          <Button
            variant="contained"
            size="small"
            endIcon={<ArrowDownwardIcon size="small" />}
            className="d-flex justify-content-center m-auto "
            onClick={() => {
              handleClick();
            }}
          >
            {isLoading ? (
              <CircularProgress sx={{ style: "10px", color: "white" }} />
            ) : (
              <small>Submit</small>
            )}
          </Button>
        </div>
      </div>
    </PopUpModel>
  );
}
export default MarkAsPaidPopUp;
