import React, { useContext, useState } from "react";
import AsyncSelect from "react-select/async";
import { AuthContext } from "../../../componants/Context/AuthContext";
import { MainContext } from "../../../componants/filterContext/FilterContext";

const fetchData = async (searchValue, apiFunction) => {
  const data = await apiFunction(searchValue);
  const options = data.map((item) => {
    return {
      value: item.name,
      label: item.name,
    };
  });
  return options;
};

const MultiSelect = (props) => {
  const { setFilter, filter } = useContext(AuthContext);
  const {
    globalSelectFilter,
    setGlobalSelectFilter,
    globalFilterKeyTofilterKey,
    globalFilterKeyToApi,
  } = useContext(MainContext);

  const loadOptions = (searchValue, callback) => {
    const getData = async () => {
      let options = await fetchData(
        searchValue,
        globalFilterKeyToApi[props.title]
      );
      callback(options);
    };
    getData();
  };

  const handleChange = (selectedOption) => {
    const formatedOption = selectedOption.map((item) => item.value);
    setGlobalSelectFilter((prev) => {
      return { ...prev, [props.title]: selectedOption };
    });
    setFilter((prev) => {
      return {
        ...prev,
        [globalFilterKeyTofilterKey[props.title]]: formatedOption,
      };
    });
  };

  return (
    <div className="drop-class">
      <AsyncSelect
        result={10}
        isMulti
        defaultOptions
        isSearchable={true}
        value={globalSelectFilter[props.title]}
        loadOptions={loadOptions}
        onChange={handleChange}
      />
    </div>
  );
};

export default MultiSelect;
