import React, { useContext, useState } from "react";
import PopUpModel from "../../../../componants/comman/PopUpModel";
import TextField from "@mui/material/TextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Button, CircularProgress } from "@mui/material";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { REQUEST_CREDITS } from "../../../../componants/Context/Types";

const SendRequest = (props) => {
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    credits: "",
  };
  const validate = Yup.object().shape({
    credits: Yup.number()
      .typeError("Must be a number")
      .required("This is required"),
  });
  const handleSubmit = (values, resetForm) => {
    dispatch({
      type: REQUEST_CREDITS,
      payload: values,
      resetForm: resetForm,
      setIsLoading: setIsLoading,
      cb: props. getRequestLogs,
    });
  };

  return (
    <PopUpModel
      open={props.open}
      close={props.close}
      title="Send Credit Request"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
          // alert("Form is validated! Submitting the form...");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
        }) => (
          <Form>
            <div className="d-flex  addteam-wrapper flex-column">
              <div className="w-100 d-flex flex-column addteam-wrapper">
                <div>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    className="user-input-fild w-100"
                    label="Enter No. of Credits"
                    variant="outlined"
                    name="credits"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.credits && (
                  <div className="error-container">
                    <div className="error">
                      {errors.credits && touched.credits && errors.credits}
                    </div>
                  </div>
                )}
              </div>
              <div className="m-0 d-flex justify-content-start">
                <Button variant="contained" className="w-100 p-2" type="submit">
                  {isLoading ? (
                    <CircularProgress sx={{ color: "white" }} size={25} />
                  ) : (
                    "Send Request"
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PopUpModel>
  );
};
export default SendRequest;
