import {
  uploadExclusionLeadsCSV,
  getAllExclusionList,
  getExclusionLeads,
  deleteExclusion,
} from "../APIs";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";

export const getAllExclusionListReducer = async (updateState, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await getAllExclusionList();

    const formatedData = res.data.map((item) => {
      return {
        ...item,
        createdAt: moment(item.createdAt).format("MMMM Do YYYY"),
      };
    });
    updateState(formatedData);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};

export const uploadExclusionLeadsCSVReducer = async (
  values,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await uploadExclusionLeadsCSV(values);
    if (res.data === "Exclusion list added!") {
      NotificationManager.success(res.data);
      getAllExclusionListReducer(updateState, () => {});
    }
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const getExclusionLeadsReducer = async (
  id,
  updateState,
  setIsLoading
) => {
  setIsLoading(true);
  try {
    const res = await getExclusionLeads(id);
    const formatedData = res.data.leads.map((item) => {
      return {
        ...item,
        Name: item.FirstName + " " + item.LastName,
      };
    });
    updateState(formatedData);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
export const deleteExclusionReducer = async (id, updateState, setIsLoading) => {
  setIsLoading(true);
  try {
    const res = await deleteExclusion(id);
    NotificationManager.success(res.data);
    getAllExclusionListReducer(updateState, setIsLoading);
  } catch (e) {
    console.log(e);
    NotificationManager.error(e.response.data || "Something Went Wrong!");
  } finally {
    setIsLoading(false);
  }
};
