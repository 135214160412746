import { Button, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react';
import FileVerification from '../../../../componants/Internal/FileVerification';
import CompanyProfile from '../../../../componants/Internal/CompanyProfile';
import ProjectFile from '../../../../componants/Internal/ProjectFile';
import BounceRepository from '../../../../componants/Internal/BounceRepository';
import * as XLSX from 'xlsx';
import InsideFileVerification from '../../../../componants/Internal/InternalIndside/InsideFileVerification';
import InsideCompanyProfile from '../../../../componants/Internal/InternalIndside/InsideCompanyProfile';
import InsideProjectFile from '../../../../componants/Internal/InternalIndside/InsideProjectFile';
import InsideBounceRepository from '../../../../componants/Internal/InternalIndside/InsideBounceRepository';
import useAuth from '../../../../hooks/useAuth';
import { checkInternalStatus, getInternalDays, getInternalFileVerification } from '../../../../componants/Context/APIs';
import FacebookCircularProgress from '../../../../componants/comman/FacebookCircularProgress';
import PopUpModel from '../../../../componants/comman/PopUpModel';
import UpdateDaysModal from '../../../../componants/Internal/InternalComponent/UpdateDays';


const Internal = ({ limit }) => {
    const { auth } = useAuth();
    const [titlebtn, settitlebtn] = useState("File Verification");
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [screenOpen, setScreenOpen] = useState(null);
    const [fileName, setFileName] = useState("");
    const [loader, setLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [days, setDays] = useState(0);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [verData, setVerData] = useState([]);
    const [verLoader, setVerLoader] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState([]);

    const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        const columns = headers.map(c => ({
            label: c,
            value: c,
        }));

        setData(list);
        setColumns(columns);
        setLoader(false);
        if (titlebtn === "File Verification") {
            setScreenOpen(titlebtn);
        }
        if (titlebtn === "Company Profile") {
            setScreenOpen(titlebtn);
        }
        if (titlebtn === "Project File") {
            setScreenOpen(titlebtn);
        }
        if (titlebtn === "Bounce Repository") {
            setScreenOpen(titlebtn);
        }

    }
    const uploadButtonHandler = (e) => {
        setLoader(true);
        const file = e.target.files[0];
        setFileName(file?.name || "");
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        if (file) {
            reader.readAsBinaryString(file);
        }
    }

    const getDays = async () => {
        try {
            setPageLoader(true);
            const res = await getInternalDays();
            setDays(res?.data?.data[0][0]?.days || 0)
            setPageLoader(false);
        } catch (error) {
            setPageLoader(false);
            
        }
    }
    function secondsToMinutes(seconds) {
        return Number(seconds) / 60;
      }

    const runBounceAPi = async (data) => {
        const body = {
            sys_filename: data?.sys_filename,
            mvfileid: data?.mvfileid
        }
        try {
            const res = await checkInternalStatus(body);
            setVerData((prev) => [{ ...data, percent: res?.data?.data?.percent, timeLeft: secondsToMinutes(res?.data?.data?.estimated_time_sec) }, ...prev])
        } catch (error) {
            
        }
    }

    const getFileVerification = async (from, to) => {
        try {
            setVerLoader(true);
            const res = await getInternalFileVerification(from, to, page);
            res?.data?.final[0]?.forEach((item, index) => {
                setVerLoader(true);
                if(item?.status !== "Verified") {
                    runBounceAPi(item);
                }else {
                    let data = res?.data?.final[0];
                    data[index]["percent"] = 100;
                    data[index]["timeLeft"] = 0;
                    setVerData(data);
                }
            });
            setTotalCount(res?.data?.totalCount[0]);
            setPages(res?.data?.pages);
            setVerLoader(false);
        } catch (error) {
            setVerLoader(false);
        }
    }

    const onDateChange = (e) => {
        setFromDate(e.target.value)
    }
    const onDateChange2 = (e) => {
        setToDate(e.target.value)
    }

    useEffect(() => {
        if (auth.role === "admin") {
            getDays();
        }
    }, [])

    useEffect(() => {
        if (fromDate && toDate) {
            setVerData([]);
            getFileVerification(fromDate, toDate);
        }
    }, [fromDate, toDate, page])


    return (
        <div className='p-3'>
            <div className="d-flex justify-content-end  pb-0 pt-0  hadbar-btn header_internal mb-2 w-100" style={{ boxShadow: 'none' }}>
                <Button
                    variant={`${titlebtn == "File Verification" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight word-break f-16"
                    onClick={() => {
                        setScreenOpen(null);
                        settitlebtn("File Verification");
                    }}
                >
                    File Verification
                </Button>
                <Button
                    variant={`${titlebtn == "Company Profile" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        setScreenOpen(null);
                        settitlebtn("Company Profile");
                    }}
                >
                    Company Profile
                </Button>
                <Button
                    variant={`${titlebtn == "Project File" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        setScreenOpen(null);
                        settitlebtn("Project File");
                    }}
                >
                    Project File
                </Button>
                <Button
                    variant={`${titlebtn == "Bounce Repository" ? "contained" : "outlined"}`}
                    className="mx-0 flex-1 button-highlight f-16"
                    onClick={() => {
                        setScreenOpen(null);
                        settitlebtn("Bounce Repository");
                    }}
                >
                    Bounce Repository
                </Button>
            </div>

            {
                pageLoader ? (
                    <div className="d-flex justify-content-between flex-row">
                        <div className="mx-auto bg-white w-5/6 min-h-5 p-4">
                            <FacebookCircularProgress />
                        </div>
                    </div>
                ) : (
                    <>
                        {
                            !screenOpen && (
                                <div className='d-flex justify-content-between align-items-center'>

                                    {
                                        titlebtn !== "Company Profile" && titlebtn !== "Project File" &&
                                        <Button
                                            component="label"
                                            variant="contained"
                                            className="m-2 p-2 mr-0 f-16"
                                            style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
                                        >
                                            {
                                                loader ? <CircularProgress sx={{ color: "white" }} size={15} />
                                                    : "Upload File"
                                            }
                                            <input
                                                disabled={loader}
                                                type="file"
                                                accept='.csv'
                                                hidden
                                                onChange={(e) => uploadButtonHandler(e)}
                                            />
                                        </Button>
                                    }
                                    {
                                        !screenOpen && titlebtn === "File Verification" && (
                                            <div className='internalDateContainer'>
                                                <span className='me-2 f-16'>From:</span>
                                                <input className='internalDatePicker me-2' type="date" name="" id="" onChange={onDateChange} />
                                                <span className='me-2 f-16'>To:</span>
                                                <input className='internalDatePicker' type="date" name="" id="" onChange={onDateChange2} />
                                            </div>
                                        )
                                    }


                                    {
                                        auth.role === "admin" && (
                                            <Button
                                                onClick={() => setIsModal(true)}
                                                variant="contained"
                                                className="m-2 p-2 mr-0 f-16 ml-auto"
                                                style={{ boxShadow: "none", backgroundColor: "#1891eb", height: '38px' }}
                                            >
                                                Update Days
                                            </Button>
                                        )
                                    }
                                </div>
                            )
                        }


                        {
                            screenOpen && screenOpen.length > 0 ? (
                                <>
                                    {
                                        screenOpen === "File Verification" && <InsideFileVerification tableData={data} tableColumn={columns} fileName={fileName} setScreenOpen={setScreenOpen} limit={limit} />
                                    }
                                    {
                                        screenOpen === "Company Profile" && <InsideCompanyProfile tableColumn={columns} fileName={fileName} setScreenOpen={setScreenOpen} csvFile={csvFile} />
                                    }
                                    {
                                        screenOpen === "Project File" && <InsideProjectFile tableColumn={columns} fileName={fileName} setScreenOpen={setScreenOpen} csvFile={csvFile} />
                                    }
                                    {
                                        screenOpen === "Bounce Repository" && <InsideBounceRepository tableData={data} tableColumn={columns} fileName={fileName} setScreenOpen={setScreenOpen} />
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        titlebtn === "File Verification" && <FileVerification setVerData={setVerData} verData={verData} setVerLoader={setVerLoader} verLoader={verLoader} setPages={setPages} pages={pages} setPage={setPage} page={page} totalCount={totalCount} setTotalCount={setTotalCount} />
                                    }
                                    {
                                        titlebtn === "Company Profile" && <CompanyProfile setScreenOpen={setScreenOpen} setFileName={setFileName} setColumns={setColumns} setCsvFile={setCsvFile} />
                                    }
                                    {
                                        titlebtn === "Project File" && <ProjectFile setScreenOpen={setScreenOpen} setFileName={setFileName} setColumns={setColumns} setCsvFile={setCsvFile} />
                                    }
                                    {
                                        titlebtn === "Bounce Repository" && <BounceRepository />
                                    }
                                </>
                            )
                        }

                    </>
                )
            }
            <PopUpModel
                open={isModal}
                title={"Update Days"}
                close={() => {
                    setIsModal(false);
                }}
            >
                <UpdateDaysModal
                    days={days}
                    setDays={setDays}
                    setIsModal={setIsModal}
                />
            </PopUpModel>
        </div>
    )
}

export default Internal;