import React, { useState, useEffect, useContext } from "react";
import { Button } from "@mui/material";
import { Table } from "antd";
import Tippy from "@tippyjs/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextField from "@mui/material/TextField";
import PopUpModel from "../../../../componants/comman/PopUpModel";
import {
  DELETE_SUB_ADMIN,
  GET_ALL_SUB_ADMIN,
} from "../../../../componants/Context/Types";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import AddSubAdminModel from "../../../../componants/comman/AddSubAdminModel";

const AddSubAdmin = () => {
  const { dispatch } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: GET_ALL_SUB_ADMIN,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  }, []);

  const handleDelete = (id) => {
    dispatch({
      type: DELETE_SUB_ADMIN,
      id: id,
      updateState: setRows,
    });
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      render: (_, record) => <p>{record?.email}</p>,
    },
    {
      title: "Creation Date",
      render: (_, record) => <p>{record.createdAt}</p>,
    },
    {
      title: "Action",
      render: (_, record) => (
        <Tippy
          content={
            <div className="TippyAction bg-light pointer">
              <div
                className=" p-1 pointer hover-dropdown"
                onClick={() => {
                  setSelectedRow(record);
                  setIsModal(true);
                }}
              >
                Edit
              </div>
              <div
                className=" p-1 pointer hover-dropdown"
                onClick={() => {
                  handleDelete(record._id);
                }}
              >
                Delete
              </div>
            </div>
          }
          placement="bottom-end"
          arrow={false}
          offset={[15, 1]}
          trigger="focusin"
          interactive={true}
        >
          <MoreVertIcon></MoreVertIcon>
        </Tippy>
      ),
    },
  ];
  return (
    <div>
      <div className="btn-class">
        <h4 className="pr-3 d-flex justify-content-between align-items-center">
          <div className="p-2 profile-header f-14">SubAdmin</div>
          {/* <TextField
            id="outlined-basic"
            value={searchValue}
            size="small"
            name="Search"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearchValue(e.target.value)}
          /> */}
          <Button
            variant={"contained"}
            size="small"
            onClick={() => {
              setIsModal(true);
            }}
          >
            Add SubAdmin
          </Button>
        </h4>
      </div>
      <div>
        <div className="m-1">
          <Table
            columns={columns}
            // dataSource={search(rows)}
            dataSource={rows}
            loading={isLoading}
            size="medium"
            pagination={{ pageSize: 4 }}
            // onChange={(pagination, filters, sorter, extra) => {
            //   setExportData(extra.currentDataSource);
            // }}
          />
        </div>

        <PopUpModel
          open={isModal}
          title={"Add SubAdmin"}
          close={() => {
            setIsModal(false);
          }}
          bodyClass={"AddCompany"}
        >
          <AddSubAdminModel
            setIsModal={setIsModal}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            updateState={setRows}
          />
        </PopUpModel>
      </div>
    </div>
  );
};

export default AddSubAdmin;
