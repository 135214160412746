import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress } from "@mui/material";
import { updateInternalDays } from "../../Context/APIs";

const UpdateDaysModal = ({days, setDays, setIsModal}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async() => {

    try {
        setIsLoading(true);
        const res = await updateInternalDays({days: Number(days)});
        setIsModal(false);
        setIsLoading(false);
    } catch (error) {
        setIsLoading(false);
        
    }

  };
  return (
    <div>
      <div className=" w-100 d-flex flex-column mylist-Update">
        <div>
          <TextField
            id="outlined-basic"
            size="small"
            className="user-input-fild w-35 m-2"
            label="Days"
            name="Days"
            value={days}
            variant="outlined"
            onChange={(e) =>
              setDays(e.target.value)
            }
          />
        </div>
      </div>
      <div className="m-0 d-flex justify-content-end">
        <Button
          variant="contained"
          className="m-2 p-2 mr-0"
          onClick={handleSubmit}
          style={{ boxShadow: "none", backgroundColor: "#1891eb" }}
        >
          {isLoading ? (
            <CircularProgress sx={{ fontSize: "0.2rem", color: "white" }} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </div>
  );
};

export default UpdateDaysModal;
