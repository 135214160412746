import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../componants/Context/AuthContext";
import { Table } from "antd";
import TextField from "@mui/material/TextField";
import { GET_ALL_COMPANY } from "../../../../componants/Context/Types";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Company Name",
    render: (_, record) => <p>{record?.company_name}</p>,
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Login IP",
    render: (_, record) => <p>{record?.login_ip}</p>,
},
  {
    title: "Subscription",
    render: (_, record) => <p>{record?.plan?.subscription_type}</p>,
  },
  {
    title: "Subscription Expiry",
    render: (_, record) => <p>{record?.plan?.subscription_end_date}</p>,
  },
  {
    title: "Credits",
    render: (_, record) => <p>{record?.credits}</p>,
  },
];

const SearchCompany = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch({
      type: GET_ALL_COMPANY,
      updateState: setRows,
      setIsLoading: setIsLoading,
    });
  }, []);

  const keys = [
    "name",
    "company_name",
    "email",
    // "plan subscription_type",
    // "login_ip",
    // "credits",
    // "plan subscription_end_date",
  ];

  const search = (rows) => {
    if (searchValue?.length > 1) {
      return rows.filter((item) => {
        return keys.some((key) => {
          const splitArr = key.split(" ");
          if (splitArr[0] === "plan")
            return item[splitArr[0]][splitArr[1]]
              ?.toString()
              .toLowerCase()
              .includes(searchValue);
          else
            return item[splitArr[0]]
              ?.toString()
              .toLowerCase()
              .includes(searchValue);
        });
      });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="p-2 profile-header f-14">Search Company </h4>
        <TextField
          id="outlined-basic"
          value={searchValue}
          size="small"
          name="Search"
          label="Search"
          variant="outlined"
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className="m-2">
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={search(rows)}
          loading={isLoading}
          size="small"
          pagination={{ pageSize: 4 }}
        />
      </div>
    </div>
  );
};

export default SearchCompany;
