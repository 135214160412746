/** @format */

import React, { useContext, useEffect, useState, useRef } from 'react';
// import TableContainer from "@mui/material/TableContainer";
// import TableCell from "@mui/material/TableCell";
// import TableRow from "@mui/material/TableRow";
// import TableBody from "@mui/material/TableBody";
// import TableHead from "@mui/material/TableHead";
// import Table from "@mui/material/Table";
import { Dropdown, Menu } from 'antd';

import PopUpModel from './PopUpModel';
import FlageButton from './FlageButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InvoiceTable from './InvoiceTable';
import { AuthContext } from '../Context/AuthContext';
import { GET_INVOICES, GET_INVOICES_ADMIN } from '../Context/Types';
import MarkAsPaidPopUp from './MarkAsPaidPopUp';
import PayInvoiceModel from './PayInvoiceModel';
import moment from 'moment';
import TableSkeltonLoader from './TableSkeltonLoader/TableSkeltonLoader';
import DynamicTable from '../../Elements/Misc/DTable';
import PaginationRow from '../../Elements/Misc/PaginationRow';

const Invoice = (props) => {
	const [rows, setRows] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const { dispatch } = useContext(AuthContext);
	const [isInvoiceModal, setIsInvoiceModal] = useState(false);
	const [isMarkAsPaidModal, setIsMarkAsPaidModal] = useState(false);
	const [isPayInvoiceModal, setIsPayInvoiceModal] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [menuOptions, setMenuOptions] = useState(false);
	const [allData, setallData] = useState([]);
	const instanceRef = useRef();
	useEffect(() => {
		if (props.selectedCompanyByAdmin) {
			dispatch({
				type: GET_INVOICES_ADMIN,
				id: props.selectedCompanyByAdmin,
				setallData: setallData,
				updateState: setRows,
				setIsLoading: setIsLoading,
			});
		} else {
			dispatch({
				type: GET_INVOICES,
				updateState: setRows,
				setallData: setallData,
				setIsLoading: setIsLoading,
			});
		}
	}, []);

	const columns = [
		{
			title: 'Invoice ID',
			render: (_, record) => <p>{record?._id}</p>,
			width: '250px',
		},
		{
			title: 'Status',
			render: (_, record) => (
				<FlageButton
					color={record.status === true ? 'green' : 'red'}
					title={record.status === true ? 'Paid' : 'UnPaid'}
				/>
			),

			filters: [
				{
					text: 'Paid',
					value: true,
				},
				{
					text: 'UnPaid',
					value: false,
				},
			],
			onFilter: (value, record) => record?.status === value,
			width: '250px',
		},
		{
			title: 'Package Type',
			render: (_, record) => <p>{record?.name}</p>,
			sorter: (a, b) => a.name.localeCompare(b.name),
			width: '250px',
		},
		{
			title: 'Validity',
			render: (_, record) => <p>{record?.item?.subscription_validity} days</p>,
		},
		// {
		// 	title: 'Billing Type',
		// 	render: (_, record) => <p>{record?.item?.billingType}</p>,
		// },
		// {
		// 	title: 'Card Used',
		// 	render: (_, record) => <p>{record?.card_info}</p>,
		// },
		{
			title: 'Payment Method',
			render: (_, record) => <p>{record?.item?.paymentMode}</p>,
		},
		{
			title: 'Total Amount',
			render: (_, record) => <p>${record?.amount.toLocaleString()}</p>,
			sorter: (a, b) => a.amount - b.amount,
		},
		{
			title: 'Invoice Date',
			render: (_, record) => <p>{record.createdAt}</p>,
		},
		{
			title: 'Next Payment Date',
			render: (_, record) => (
				<p>
					{record.item.endDate
						? moment(record?.item?.endDate).format('MMMM Do YYYY')
						: 'N/A'}
				</p>
			),
		},
		{
			title: 'Action',
			render: (_, record) => (
				<Dropdown
					overlay={
						<Menu>
							{record.status === true ? (
								''
							) : props.selectedCompanyByAdmin ? (
								<Menu.Item
									className=' p-2 pointer hover-dropdown'
									onClick={() => {
										setIsMarkAsPaidModal(true);
										setSelectedInvoice(record);
									}}
								>
									Mark as paid
								</Menu.Item>
							) : (
								<Menu.Item
									className=' p-2 pointer hover-dropdown'
									onClick={() => {
										setIsPayInvoiceModal(true);
										setSelectedInvoice(record);
									}}
								>
									Pay Invoice
								</Menu.Item>
							)}
							<Menu.Item
								className=' p-2 pointer hover-dropdown'
								onClick={() => {
									setIsInvoiceModal(true);
									setSelectedInvoice(record);
								}}
							>
								View
							</Menu.Item>
						</Menu>
					}
					placement='bottom'
					// visible={menuOptions}
					// destroyPopupOnHide
					trigger={['click']}
					// onVisibleChange={(flag) => setMenuOptions(flag)}
				>
					<MoreHorizIcon className='pointer' />
				</Dropdown>

				// <Tippy
				//   content={
				//     <div className="TippyAction bg-light  ">
				//       {record.status === true ? (
				//         ""
				//       ) : props.selectedCompanyByAdmin ? (
				//         <div
				//           className=" p-2 pointer hover-dropdown"
				//           onClick={() => {
				//             setIsMarkAsPaidModal(true);
				//             setSelectedInvoice(record);
				//           }}
				//         >
				//           Mark as paid
				//         </div>
				//       ) : (
				//         <div
				//           className=" p-2 pointer hover-dropdown"
				//           onClick={() => {
				//             setIsPayInvoiceModal(true);
				//             setSelectedInvoice(record);
				//           }}
				//         >
				//           Pay Invoice
				//         </div>
				//       )}
				//       <div
				//         className=" p-2 pointer hover-dropdown"
				//         onClick={() => {
				//           setIsInvoiceModal(true);
				//           setSelectedInvoice(record);
				//         }}
				//       >
				//         View
				//       </div>
				//     </div>
				//   }
				//   placement="bottom-end"
				//   arrow={false}
				//   offset={[15, 1]}
				//   trigger={"click"}
				//   hideOnClick= {true}
				//   interactive={true}
				//   onMount={(instance) => (instanceRef.current = instance)}

				// >
				//   <MoreHorizIcon className="pointer" />
				// </Tippy>
			),
		},
	];

	let newrows = [...rows];
	const paginatedRows = newrows.slice(
		(page - 1) * rowsPerPage,
		page * rowsPerPage
	);

	const renderCell = (row, col) => {
		if (col.title === 'Invoice ID') {
			return row?._id;
		} else if (col.title === 'Status') {
			return row?.status === true ? (
				<div>
					<span class='py-1 px-2 inline-flex items-center gap-x-1.5 text-xs font-medium bg-teal-200 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500'>
						<svg
							class='shrink-0 size-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z'></path>
							<path d='m9 12 2 2 4-4'></path>
						</svg>
						Paid
					</span>
				</div>
			) : (
				<div>
					<span class='py-1 px-1.5 inline-flex items-center gap-x-1.5 text-xs font-medium bg-red-200 text-red-700 rounded-full dark:bg-red-500/10 dark:text-red-500'>
						<svg
							class='shrink-0 size-3'
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
						>
							<path d='m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z'></path>
							<path d='M12 9v4'></path>
							<path d='M12 17h.01'></path>
						</svg>
						Unpaid
					</span>
				</div>
			);
		} else if (col.title === 'Package Type') {
			return row?.name;
		} else if (col.title === 'Validity') {
			return row?.item?.subscription_validity > 0
				? `${row?.item?.subscription_validity} days`
				: '-';
		} else if (col.title === 'Action') {
			return (
				<div className=''>
					<div
						onClick={() => {
							setIsInvoiceModal(true);
							setSelectedInvoice(row);
						}}
						className='cursor-pointer flex items-center gap-1 text-blue-600 hover:underline'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							stroke='currentColor'
							stroke-width='2'
							stroke-linecap='round'
							stroke-linejoin='round'
							class='w-4 h-4'
						>
							<path d='M5 12s2.545-5 7-5c4.454 0 7 5 7 5s-2.546 5-7 5c-4.455 0-7-5-7-5z' />
							<path d='M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' />
							<path d='M21 17v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2' />
							<path d='M21 7V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2' />
						</svg>
						View
					</div>
					{props?.selectedCompanyByAdmin && row?.status === false && (
						<div
							onClick={() => {
								setIsMarkAsPaidModal(true);
								setSelectedInvoice(row);
							}}
							className='mt-2 whitespace-nowrap cursor-pointer flex items-center gap-1 text-emerald-600 hover:underline'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4 h-4'
							>
								<circle cx='12' cy='12' r='10' />
								<path d='M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8' />
								<path d='M12 18V6' />
							</svg>
							Mark As Paid
						</div>
					)}
					{!props?.selectedCompanyByAdmin && row?.status !== true && (
						<div
							onClick={() => {
								setIsPayInvoiceModal(true);
								setSelectedInvoice(row);
							}}
							className='mt-2 whitespace-nowrap cursor-pointer flex items-center gap-1 text-emerald-600 hover:underline'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
								stroke='currentColor'
								stroke-width='2'
								stroke-linecap='round'
								stroke-linejoin='round'
								class='w-4 h-4'
							>
								<path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1' />
								<path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4' />
							</svg>
							Pay Invoice
						</div>
					)}
				</div>
			);
		} else if (col.title === 'Billing Type') {
			return row?.item?.billingType ? row?.item?.billingType : '-';
		} else if (col.title === 'Card Used') {
			return row?.card_info;
		} else if (col.title === 'Payment Method') {
			return row?.item?.paymentMode ? row?.item?.paymentMode : '-';
		} else if (col.title === 'Total Amount') {
			return `$${row?.amount}`;
		} else if (col.title === 'Invoice Date') {
			return `${row?.createdAt}`;
		} else if (col.title === 'Next Payment Date') {
			return row?.item?.endDate
				? moment(row?.item?.endDate).format('MMMM Do YYYY')
				: 'N/A';
		}
		return row[col.title];
	};

	return (
		<div>
			{isLoading ? (
				<div
					className='relative'
					style={{ overflow: 'hidden', marginTop: '1rem' }}
				>
					<TableSkeltonLoader countRow={8} />
				</div>
			) : (
				// : rows?.length === 0 ? (
				// 	<p className='p-2' align='center'>
				// 		No Invoices
				// 	</p>
				// )
				// <Table
				// 	// scroll={{ x: true , y:true }}
				// 	columns={columns}
				// 	dataSource={rows}
				// 	loading={isLoading}
				// 	pagination={{
				// 		pageSize: 10,
				// 	}}
				// 	scroll={{
				// 		x: 2000,
				// 		y: 400,
				// 	}}
				// 	size='small'
				// />
				<DynamicTable
					columns={columns}
					data={paginatedRows}
					renderCell={renderCell}
				/>
			)}
			{!isLoading && (
				<PaginationRow
					totalResults={rows?.length}
					page={page}
					setPage={setPage}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
				/>
			)}
			<PopUpModel
				open={isInvoiceModal}
				title={'Invoice'}
				close={() => {
					setIsInvoiceModal(false);
				}}
				bodyClass={'Invoice-model'}
			>
				<InvoiceTable
					open={isInvoiceModal}
					selectedInvoice={selectedInvoice}
					close={() => {
						setIsInvoiceModal(false);
					}}
					bodyClass={'Invoice-model'}
				/>
			</PopUpModel>
			{/* <PopUpModel
        open={userDetail}
        title={"User Details"}
        close={() => {
          setUserDetail(false);
        }}
        bodyClass={"Invoice-model"}
      >
        <UserInfoDetails title={true} />
      </PopUpModel> */}
			<MarkAsPaidPopUp
				close={() => {
					setIsMarkAsPaidModal(false);
				}}
				open={isMarkAsPaidModal}
				title={'Invoice'}
				selectedCompanyByAdmin={props.selectedCompanyByAdmin}
				selectedInvoice={selectedInvoice}
				setRows={setRows}
				setallData={setallData}
			/>

			<PayInvoiceModel
				close={() => {
					setIsPayInvoiceModal(false);
				}}
				open={isPayInvoiceModal}
				title={'Payment Gateway'}
				selectedInvoice={selectedInvoice}
			/>
		</div>
	);
};

export default Invoice;
