/** @format */

import { NotificationManager } from 'react-notifications';
import CryptoJS from 'crypto-js';
import {
	EXECUTIVE_FILTER,
	PHYSICIAN_FILTER,
} from '../../../pages/ContactSerch/filterCompo/filterTypes';
import {
	filterContacts,
	filterContactsStats,
	filterContactsStatsAvailable,
	quickSearch,
} from '../APIs';

export const filterAllContacts = async (
	filter,
	typesOfFilter,
	page,
	limit,
	setAllContacts,
	setIsLoadingDataTable,
	sortColumn,
	setSortField,
	sortField
) => {
	let filterOptions = {};
	let sortFieldOptions = {};
	for (const [key, value] of Object.entries(filter)) {
		if (value) {
			if (typeof value === 'object' && value.length === 0) continue;
			filterOptions[key] = value;
		}
	}
	for (const [key, value] of Object.entries(sortField)) {
		if (value) {
			if (typeof value === 'object' && (value === 1 || value === -1)) continue;
			sortFieldOptions[key] = value;
		}
	}
	if (typesOfFilter === PHYSICIAN_FILTER) {
		filterOptions['type'] = ['0'];
	} else if (typesOfFilter === EXECUTIVE_FILTER) {
		filterOptions['type'] = ['1'];
	}

	setIsLoadingDataTable(true);
	const filterOptionsModified = Object.assign(filterOptions, sortFieldOptions);
	try {
		const res = await filterContacts(filterOptionsModified, page, limit);
		if (res.data) {
			const bytes = CryptoJS.AES.decrypt(
				res.data.encryptedData,
				'docdbisky987'
			);
			const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
			setAllContacts(JSON.parse(decryptedData));
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoadingDataTable(false);
	}
};

export const filterAllContactsStatsAvailable = async (
	filter,
	typesOfFilter,
	setNumOfRecordsFoundLoading,
	setAvailableStats,
	setFirstTimeApiCall,
	firstTimeApiCall,
	setFirstTimeFilter,
	firstTimeFilter
) => {
	setNumOfRecordsFoundLoading(true);
	let filterOptions = {};
	let count1 = 0;
	let count2 = 0;
	for (const [key, value] of Object.entries(filter)) {
		if (value) {
			if (typeof value === 'object' && value.length === 0) continue;
			filterOptions[key] = value;
		}
	}

	if (typesOfFilter === PHYSICIAN_FILTER) {
		filterOptions['type'] = ['0'];
	} else if (typesOfFilter === EXECUTIVE_FILTER) {
		filterOptions['type'] = ['1'];
	}
	if (Object.keys(filterOptions).length > 1) {
		if (firstTimeFilter?.filterCount < Object.keys(filterOptions).length) {
			setFirstTimeApiCall(false);
			setNumOfRecordsFoundLoading(true);
		}
		if (
			firstTimeFilter?.isFilterCall &&
			firstTimeFilter?.filterCount === Object.keys(filterOptions).length
		)
			return setNumOfRecordsFoundLoading(false);
		try {
			const res = await filterContactsStatsAvailable(filterOptions);
			if (res.data) {
				count2 = 1;
				count1 = 0;
				setAvailableStats(res.data.count);
				setFirstTimeApiCall(true);
				setFirstTimeFilter({
					filterCount: Object.keys(filterOptions).length,
					isFilterCall: true,
				});
				setNumOfRecordsFoundLoading(false);
			}
		} catch (e) {
			console.log(e);
			setAvailableStats(0);
			setNumOfRecordsFoundLoading(false);
			NotificationManager.error(e.response.data || 'Something Went Wrong!');
		} finally {
			setNumOfRecordsFoundLoading(false);
		}
	}
	if (firstTimeApiCall) return setNumOfRecordsFoundLoading(false);
	if (count2 === 1) return setNumOfRecordsFoundLoading(false);
	try {
		const res = await filterContactsStatsAvailable(filterOptions);
		if (res.data) {
			count1 = 1;
			count2 = 0;
			setAvailableStats(res.data.count);
			setFirstTimeApiCall(true);
			setNumOfRecordsFoundLoading(false);
		}
	} catch (e) {
		console.log(e);
		setNumOfRecordsFound(0);

		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setNumOfRecordsFoundLoading(false);
	}
};

export const filterAllContactsStatsAvailable2 = async (
	filter,
	typesOfFilter,
	setNumOfRecordsFoundLoading,
	setAvailableStats,
	setFirstTimeApiCall,
	setFirstTimeFilter
) => {
	setNumOfRecordsFoundLoading(true);
	let filterOptions = {};
	for (const [key, value] of Object.entries(filter)) {
		if (value) {
			if (typeof value === 'object' && value.length === 0) continue;
			filterOptions[key] = value;
		}
	}

	if (typesOfFilter === PHYSICIAN_FILTER) {
		filterOptions['type'] = ['0'];
	} else if (typesOfFilter === EXECUTIVE_FILTER) {
		filterOptions['type'] = ['1'];
	}
	try {
		const res = await filterContactsStatsAvailable(filterOptions);
		if (res.data) {
			setAvailableStats(res.data.count);
			setFirstTimeApiCall(true);
			setFirstTimeFilter({
				filterCount: Object.keys(filterOptions).length,
				isFilterCall: true,
			});
			setNumOfRecordsFoundLoading(false);
		}
	} catch (e) {
		console.log(e);
		setAvailableStats(0);
		setNumOfRecordsFoundLoading(false);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setNumOfRecordsFoundLoading(false);
	}
};
export const filterAllContactsStats = async (
	filter,
	typesOfFilter,
	setNumOfRecordsFoundLoading,
	setNumOfRecordsFound,
	setFirstTimeApiCall,
	firstTimeApiCall,
	setFirstTimeFilter,
	firstTimeFilter
) => {
	setNumOfRecordsFoundLoading(true);
	let filterOptions = {};
	let count1 = 0;
	let count2 = 0;
	for (const [key, value] of Object.entries(filter)) {
		if (value) {
			if (typeof value === 'object' && value.length === 0) continue;
			filterOptions[key] = value;
		}
	}

	if (typesOfFilter === PHYSICIAN_FILTER) {
		filterOptions['type'] = ['0'];
	} else if (typesOfFilter === EXECUTIVE_FILTER) {
		filterOptions['type'] = ['1'];
	}
	if (Object.keys(filterOptions).length > 1) {
		if (firstTimeFilter?.filterCount < Object.keys(filterOptions).length) {
			setFirstTimeApiCall(false);
			setNumOfRecordsFoundLoading(true);
		}
		if (
			firstTimeFilter?.isFilterCall &&
			firstTimeFilter?.filterCount === Object.keys(filterOptions).length
		)
			return setNumOfRecordsFoundLoading(false);
		try {
			const res = await filterContactsStats(filterOptions);
			if (res.data) {
				count2 = 1;
				count1 = 0;
				setNumOfRecordsFound(res.data.count);
				setFirstTimeApiCall(true);
				setFirstTimeFilter({
					filterCount: Object.keys(filterOptions).length,
					isFilterCall: true,
				});
				setNumOfRecordsFoundLoading(false);
			}
		} catch (e) {
			console.log(e);
			setNumOfRecordsFound(0);
			setNumOfRecordsFoundLoading(false);
			NotificationManager.error(e.response.data || 'Something Went Wrong!');
		} finally {
			setNumOfRecordsFoundLoading(false);
		}
	}
	if (firstTimeApiCall) return setNumOfRecordsFoundLoading(false);
	if (count2 === 1) return setNumOfRecordsFoundLoading(false);
	try {
		const res = await filterContactsStats(filterOptions);
		if (res.data) {
			count1 = 1;
			count2 = 0;
			setNumOfRecordsFound(res.data.count);
			setFirstTimeApiCall(true);
			setNumOfRecordsFoundLoading(false);
		}
	} catch (e) {
		console.log(e);
		setNumOfRecordsFound(0);

		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setNumOfRecordsFoundLoading(false);
	}
};

export const getQuickSearch = async (
	searchValue,
	page,
	limit,
	upDateState,
	setIsLoadingDataTable,
	setNumOfRecordsFound
) => {
	setIsLoadingDataTable(true);
	try {
		const res = await quickSearch(searchValue, page, limit);
		if (res.data) {
			upDateState(res.data.leads);
			setNumOfRecordsFound(res.data.count);
		}
	} catch (e) {
		console.log(e);
		NotificationManager.error(e.response.data || 'Something Went Wrong!');
	} finally {
		setIsLoadingDataTable(false);
	}
};
